import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Slider from "react-slick";
import React, { useState } from "react";
import RelatedBooksData from "./relatedbooksdata";
import { Rating } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  ExclusiveBookCard: {
    marginTop: "1rem",
    "& .MuiCard-root": {
      width: "25%",
      display: 'flex !important',
      margin: '1rem 0',
    flexDirection: 'column',
    justifyContent: 'space-between',
      "&:hover": {
        boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
        cursor: "pointer",
        "& .MuiCardActions-root": {
          background:
            "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        },
      },
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
    },
    '& .MuiCardContent-root': {
      padding: '0 16px',
      minHeight: '15rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
  },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "1.5rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.875rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      width: "50% !important",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down('sm')]: {
      "& .MuiTypography-h5": {
        fontSize: "1rem",
        marginBottom:'0',
      },
      "& .MuiCard-root": {
        height: '500px',
      },
  },
  },
  cardimgcont: {
    background: "#FFE8B5",
    margin: "1rem",
    "&:hover": {
      background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    },
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.5rem",
  },
  textgrey: {
    color: "#8B8B8B",
    fontSize: "0.9375rem",
    textDecoration: "line-through",
    marginLeft: "1rem",
  },
  offertext: {
    marginLeft: "1rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  couponbtntext: {
    fontFamily: "'Playfair Display', serif !important",
    color: "#000000",
    fontWeight: "600",
  },
}));
function RelatedBooksSection() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [value, setValue] = useState(2);
  const classes = useStyles();
  return (
    <>
      <div className={classes.ExclusiveBookCard}>
        <Slider {...settings}>
          {RelatedBooksData.map((data) => (
            <Card>
              <CardActionArea>
                <div className={classes.cardimgcont}>
                  <CardMedia
                    component="img"
                    height="239"
                    image={data.bookimage}
                    alt="green iguana"
                  />
                </div>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {data.bookname}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    {data.writername}
                  </Typography>
                  <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                    <Typography component="subtitle2">{value}</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    <span className={classes.textred}>{data.dropedprice}</span>
                    <span className={classes.textgrey}>
                      {data.orignalprice}
                    </span>
                    <span className={classes.offertext}>
                      {data.offerpercent}
                    </span>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={classes.bgorange}>
                <Button fullWidth>Buy Now</Button>
              </CardActions>
            </Card>
          ))}
        </Slider>
      </div>
    </>
  );
}
export default RelatedBooksSection;
