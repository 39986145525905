import testseriesGif from "../../Videos/TestSeries.webm";
import videocoursesGif from "../../Videos/VideoCourses.webm";
import studymaterialsGif from "../../Videos/StudyMaterials.webm";
import booksGif from "../../Videos/Books.webm";
import onlineliveclassesGif from "../../Videos/OnlineLiveClasses.webm";
import onlineliveclassesSvg from "../../image/preprationvector/OnlineLiveClasses.svg";
import testseriesSvg from "../../image/preprationvector/TestSeries.svg";
import videocoursesSvg from "../../image/preprationvector/VideoCourses.svg";
import studymaterialsSvg from "../../image/preprationvector/StudyMaterials.svg";
import booksSvg from "../../image/preprationvector/Books.svg";

const Data = [
  {
    id: 1,
    style: "bgimage1",
    cardimage: onlineliveclassesGif,
    cardsvg: onlineliveclassesSvg,
    cardheading: "Online Live Classes",
    carddiscription:
      "Ace Your Exams, Live Prep Class with doubt clearing sessions",
    btntext: "Explore Live Classes",
    to:"/liveclasses",
  },
  {
    id: 2,
    style: "bgimage2",
    cardimage: testseriesGif,
    cardsvg: testseriesSvg,
    cardheading: "Test Series",
    carddiscription:
      "Elevate Your Preparation, Dive into Realistic Exam Scenarios",
    btntext: "Explore Test Series",
    to:"/testseries",
  },
  {
    id: 3,
    style: "bgimage1",
    cardimage: videocoursesGif,
    cardsvg: videocoursesSvg,
    cardheading: "Video Courses",
    carddiscription:
      "Eyes on Expertise, enroll in Our Video Courses for Skill Mastery",
    btntext: "Explore Videos",
    to:"/onlinevideos",
  },
  {
    id: 4,
    style: "bgimage2",
    cardimage: studymaterialsGif,
    cardsvg: studymaterialsSvg,
    cardheading: "Study Materials",
    carddiscription: "Unlock Your Learning Potential with Our Study Materials",
    btntext: "Explore Notes",
    to:"/notes",
  },
  {
    id: 5,
    style: "bgimage1",
    cardimage: booksGif,
    cardsvg: booksSvg,
    cardheading: "Books",
    carddiscription:
      'Explore Our Diverse Collection of Books" and be exam ready',
    btntext: "Explore Books",
    to:"/books",
  },
  
  
];
export default Data;
