import React, { useState } from 'react'
import { FormControlLabel, Radio, RadioGroup, Typography, makeStyles } from "@material-ui/core";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  personaldetails: {
    width: "80%",
    marginTop: "1rem",
    margin: "1rem 5px",
    borderRadius: 5,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    marginBottom:"20rem",
  "& .MuiTypography-h6":{
    color:'blue',
    marginTop:'1rem',
  },
  "& .MuiTypography-h5":{
    color:'#808080',
    fontSize:'16px',
    fontWeight:"500",

  },
  },
  personalname:{
    width:"100%",
    marginTop:'1.5rem',
    display:'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiTextField-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'border-color: rgba(255,255,255,0) rgba(255,255,255,1) rgba(255,255,255,0) rgba(255,255,255,0)',
    },
  },
  TypeHere:{
    width:"100%",
    marginTop:'.5rem',
    display:'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiTextField-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'red',
    },
  },
  selectinput:{
    width:"100%",
    marginTop:'.5rem',
    display:'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiInputBase-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'red',
    },
  },
  securitypin:{
    display:'flex',
    marginTop:'1.5rem',
    justifyContent: 'center',
    alignItems:' baseline',
    "& .MuiFormLabel-root":{
      width:'20%',
    },
    "& .MuiOutlinedInput-root":{
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    }
  },
  submitbtn:{
    display:'flex',
    justifyContent: 'flex-end',
  },
  btn:{
    background:'#ff8c00',
    width:'10%',
    justifyContent:'space-around',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  Radiobtn:{
    width:"100%",
    marginTop:'.5rem',
    display:'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"44%",
    },
    '& .MuiInputBase-root':{
      width:"56%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'red',
    },
  }
}));

function AdharDetails() {
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const [selectedGender, setSelectedGender] = useState("");
  const handleChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const classes = useStyles();

  function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }
  

  return (
   <div className={classes.personaldetails}>
     <Typography variant='h6'>व्यक्तिगत विवरण (Personal Details)</Typography>
   <div className={classes.personalname}>
                <FormLabel>
                  
                Details
अभ्यर्थी का नाम (Candidate's Name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
               "Severe where percentage is not defined
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
             लिंग (Gender)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                अभ्यर्थी की जन्म विधि (Candidate's Date of Birth)
                </FormLabel>
                
                     <TextField
                  type="date"
                  variant="outlined"
                  required
                  placeholder=""
                />
                   

                </div>
                
              <div  className={classes.Radiobtn}>
              <FormLabel component="legend">  options below and proceed
further:</FormLabel>
      <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
        <FormControlLabel value="female" control={<StyledRadio />} label="tching my personal
iसाझा रिकॉर्ड" />
       
        
      </RadioGroup>
              </div>
               
              </div>
  
  )
}

export default AdharDetails;