import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import appContext from "../../context/AppContext";
import popupContext from "../../context/PopupContext/PopupContext";
import { getLearnerMenu } from "../../service/services";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import userimg from "../image/header/image.svg";
import ProfileAvatar from "../image/header/profile_avatar.png";
import HeadingTest from "./mobileheaderdatatest";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import IconComponent from "../dashboard/IconComponent";
import FetchProfileContext from "../../context/FetchUserProfile/FetchProfileContext";

const useStyles = makeStyles((theme) => ({
  list: {
    "& .MuiListItemIcon-root": {
      "& svg": {
        width: "35px",
        height: "35px",
        background: "white",
        borderRadius: "5px",
      },
    },
    "& .MuiPaper-root": {
      color: "white",
      backgroundColor: "transparent",
    },
    "& .MuiAppBar-colorPrimary": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%) !important",
    },
    "& .MuiToolbar-root": {
      justifyContent: "space-between",
    },
  },
  fullList: {
    width: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
  headbtnsignin: {
    color: "#233A54",
    fontSize: "0.625rem",
    background:
      "linear-gradient(135deg, #FFF 16.15%, rgba(255, 255, 255, 0.40) 100%)",
    backdropFilter: "blur(15px)",
    padding: "0.5rem 0rem",
    margin: "0 1rem",
  },
  headbtnlogin: {
    color: "#233A54",
    fontSize: "0.625rem",
    background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    padding: "0.5rem 1rem",
  },
  loginUser: {
    color: "#233A54",
    fontSize: "0.625rem",
    background:
      "linear-gradient(135deg, #FFF 16.15%, rgba(255, 255, 255, 0.40) 100%)",
    backdropFilter: "blur(15px)",
    padding: "0.2rem 0.5rem ",
    // margin: "0 1rem",
  },
  loginImg: {
    border: "2px solid #FF9939",
    padding: 1,
    width: 30,
    height: 30,
    borderRadius: 6,
    marginRight: 5,
  },
  Link: {
    color: "white",
    textDecoration: "none",
  },
}));

function MobileHeaderLink({ setDashBoardId }) {
  const classes = useStyles();
  const AppContext = useContext(appContext);
  const [learnerMenu, setLearnerMenu] = useState([]);
  const {
    toggleDrawerOpenClose,
    setDrawerState,
    handlesignupOpenclose,
    handleloginOpenclose,
  } = useContext(popupContext);

  useEffect(() => {
    if (AppContext.isLoggedIn) {
      getLearnerMenu(AppContext.token)
        .then((res) => {
          setLearnerMenu(res?.data?.menu);
        })
        .catch((e) => console.log("dalsfknlk", e));
    }
  }, [AppContext.isLoggedIn, AppContext.token]);

  const myAccountIndex = HeadingTest.findIndex(
    (item) => item.name === "My Account"
  );
  if (myAccountIndex !== -1) {
    learnerMenu.forEach((item) => {
      if (!HeadingTest[myAccountIndex].menu.some((menuItem) => menuItem.name === item.name)) {
        HeadingTest[myAccountIndex].menu.push(item);
      }
    });
  }
  const { profileState } = useContext(FetchProfileContext);
  return (
    <div className={classes.list} role="presentation">
      {!AppContext.isLoggedIn ? (
        <div className={classes.drawerHeader}>
          <div className={classes.headlogbtninner}>
            <Button
              onClick={handleloginOpenclose}
              color="primary"
              className={classes.headbtnlogin}
            >
              Log in
            </Button>

            <Button
              onClick={handlesignupOpenclose}
              color="primary"
              className={classes.headbtnsignin}
            >
              Sign Up
            </Button>
          </div>
          <IconButton onClick={toggleDrawerOpenClose}>
            <CloseIcon />
          </IconButton>
        </div>
      ) : (
        <div className={classes.drawerHeader}>
          <div className={classes.headlogbtninner}>
            <Button color="primary" className={classes.loginUser}>
              <img
                className={classes.loginImg}
                src={
                  profileState?.image
                    ? profileState?.image
                    : profileState?.user?.image_url
                    ? profileState?.user?.image_url
                    : ProfileAvatar
                }
                alt="img"
              />
              {profileState?.user?.full_name}
            </Button>
          </div>
          <IconButton onClick={toggleDrawerOpenClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <Divider />
      <List>
        {HeadingTest.map((data, index) => {
          if (
            data.status ||
            (data.isLoggedInRequired && AppContext.isLoggedIn)
          ) {
            if (data.type === "dropdown") {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ListItemIcon>
                      <IconComponent iconName={data?.icon} />
                    </ListItemIcon>
                    <ListItemText primary={data.name} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {data.menu.map((menu, index) => (
                        <ListItem button key={index}>
                          <ListItemIcon>
                            <IconComponent iconName={menu?.icon} />
                          </ListItemIcon>
                          {console.log("data123455", menu)}
                          <Link
                            to={
                              data?.name === "My Account"
                                ? "/dashboard"
                                : menu?.to
                            }
                            className={classes.Link}
                            onClick={() => {
                              setDrawerState(false);
                              AppContext.setTempSubcategory(null);
                              setDashBoardId(parseInt(menu?.index_id));
                            }}
                            target={menu?.target}
                          >
                            <ListItemText primary={menu.name} />
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              );
            } else {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    if (data?.name === "Logout") {
                      localStorage.clear();
                      window.location.reload();
                      setDrawerState(false);
                    }
                  }}
                >
                  <ListItemIcon>{data.icon}</ListItemIcon>
                  {console.log("data.name", data.name)}
                  <Link
                    onClick={() => {
                      if (data?.name === "Refer And Earn") {
                        setDashBoardId(14);
                      } else if (data?.name === "Help Desk") {
                        setDashBoardId(15);
                      } else if (data?.name === "Settings") {
                        setDashBoardId(16);
                      }
                      setDrawerState(false);
                      AppContext.setTempSubcategory(null);
                    }}
                    to={data.to}
                    className={classes.Link}
                  >
                    <ListItemText primary={data.name} />
                  </Link>
                </ListItem>
              );
            }
          }
          return null;
        })}
      </List>
    </div>
  );
}

export default MobileHeaderLink;
