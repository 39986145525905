import React, { useState } from "react";
import { Divider, IconButton, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as FolderVector } from "../../image/Card/foldervector.svg";
import { ReactComponent as WriteVector } from "../../image/Card/writevector.svg";
import { useNavigate } from "react-router-dom";
import FavouriteButton from "../../practicecomponent/FavouriteButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import BuyNowButton from "../../practicecomponent/BuyNowButton";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "397px",
    margin: "1rem 0",
    display: "flex",
    "& .MuiCardContent-root": {
      width: "95%",
      height: "114px",
      padding: "0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
  },
  root: {
    width: "265px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiCardActionArea-root": {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "& .MuiTypography-h3": {
      height: "55px",
      color: " #000",
      fontFamily: "Playfair Display",
      fontSize: "1rem",
      fontWeight: 600,
      width: "70%",
      whiteSpace: "wrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiTypography-h4": {
      color: " #252525",
      fontFamily: "Playfair Display",
      fontSize: "0.7rem",
      fontWeight: 600,
      width: "70%",
      whiteSpace: "wrap",
      height: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiDivider-vertical": {
      alignSelf: "center",
      height: "44px",
      width: "2px",
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.up('md')]: {
      "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
        opacity: "0.3",
      },
    },
    "& .MuiCardMedia-root": {
      background: "#FFE8B5",
      width: "100%",
      aspectRatio: "1",
      height: "230px",
    },
  },
  btn: {
    color: "var(--text-color, #212121)",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.9375rem",
    fontWeight: 400,
    textTransform: "uppercase",
    background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
    width: "100% ",
    padding: "0.8rem",
  },
  centercontend: {
    display: "flex",
    marginTop: "0.5rem",
    alignItems: "center",
    marginLeft: "0.5rem",
  },
  testseriesdiv: {
    display: "flex",
    alignItems: "center",
    color: "#252525",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: " 0.8rem",
    fontWeight: 500,
    padding: "0.1rem 0",
    "& svg": {
      marginRight: "0.3rem",
    },
  },
  redtext: {
    color: " #FF3939",
    fontFamily: "Jost",
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "1.4rem",
  },
  centercontend1: {
    marginTop: "0.4 rem",
    marginLeft: "0.8rem",
    marginBottom: "0px !important",
  },
  lighttext: {
    color: "#626262",
    fontFamily: "Jost",
    fontSize: "0.8rem",
    fontWeight: 400,
    lineHeight: " 1rem",
  },
  darktext: {
    color: " #252525",
    fontFamily: "Jost",
    fontSize: " 0.875rem",
    fontWeight: 400,
  },

  ssctextdiv1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 1rem",
  },
  namecontainer: {
    position: "relative",
  },
  newlaunch: {
    transform: "skew(10deg,0deg)",
    width: "85px",
    height: " 20px",
    background: "#FF3939",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.6rem",
    fontWeight: 700,
    position: "absolute",
    top: "5px",
    right: "-10px",
    boxShadow: "-5px 5px 5px 0px rgba(255, 177, 59, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "40% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
  },
  quikviewbtn: {
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
}));

const SscCard = ({ data }) => {
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <CardActionArea
            onMouseOver={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            onClick={() => navigate(`/testseries/${data?.id}`)}
          >
            {isHover && (
              <div className={classes.quikviewcontainer}>
                {/* <Link to={`/book/${data?.id}`}>*/}
                <IconButton className={classes.quikviewbtn}>
                  <InfoIcon />
                </IconButton>
                {/* </Link> */}
                <div className={classes.favoritebtn}>
                  <FavouriteButton
                    isCheckedHeart={isCheckedHeart}
                    data={data}
                    setIsCheckedHeart={setIsCheckedHeart}
                  />
                </div>
              </div>
            )}
            {console.log("data12334565", data)}
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              title="Contemplative Reptile"
              image={data?.image_url || require("../../image/notFoundData.png")}
            />
            <CardContent>
              <div className={classes.ssctextdiv}>
                {/* <Typography variant="h3">SSC Maha Pack ( SSC CGL) </Typography> */}
                <div className={classes.namecontainer}>
                  <Typography variant="h3">{data?.bundle_name}</Typography>
                  <div className={classes.newlaunch}>NEW LAUNCH</div>
                </div>
              </div>
              <div className={classes.ssctextdiv1}>
                <div>
                  <div className={classes.testseriesdiv}>
                    <FolderVector />
                    {data?.test_series_mock_count} Test Series
                  </div>
                  <div className={classes.testseriesdiv}>
                    <WriteVector />
                    Validity: {data?.validity}
                  </div>
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  <span className={classes.redtext}>
                    ₹ {data?.selling_cost}
                  </span>
                  <br />
                  <span className={classes.lighttext}>
                    MRP: <del>₹ {data?.mrp}</del>
                  </span>
                </div>
              </div>
            </CardContent>
          </CardActionArea>

          <CardActions className={classes.bgorange}>
            <BuyNowButton data={data} />
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default SscCard;
