import {
  FormControl,
  makeStyles,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InputForm from "./inputForm";
import HelpDeskCard from "./helpDeskCard";
import { ReactComponent as FilterIcon } from "../../image/dashboard/filtericon.svg";
import { getHelpDeskData } from "../../../service/services";

const useStyles = makeStyles((theme) => ({
  mainroot: {
    background: "#FFF",
    padding: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  heading: {
    color: "#212121",
    textAlign: "center",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: "700",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "1.5rem",
  },
  filtersection: {
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "0.375rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      margin: "1rem auto",
    },
  },
  filtericonandname: {
    background: "#FFF",
    display: "flex",
    boxShadow: "0px 6px 40px 0px rgba(0, 0, 0, 0.06)",
    padding: "0.5rem 1rem",
    borderRadius: "0.375rem",
    "& .MuiTypography-body1": {
      fontFamily: "Jost !important",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#444",
    },
  },
  filterinput: {
    width: "60%",
    "& .MuiInputBase-input": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#9A9A9A",
    },
    "& .MuiSelect-selectMenu": {
      padding: "0.8rem",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  filtercontainer: {
    width: "23%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const Help = () => {
  const classes = useStyles();
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  // const [helpData, setHelpData] = useState(null);
  const [filterOption, setFilterOption] = useState("");

 
  //   async function fetchData() {
  //     const token = localStorage.getItem("token");
  //     try {
  //       const response = await getHelpDeskData(token);
  //       console.log("API Response:", response.data);
  //       setHelpData(response.data?.helpdesk);
  //       console.log(helpData, "help");
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   useEffect(() => {
  //   fetchData();
  // }, [helpData]);
  
  const [helpData, setHelpData] = useState(null); // Or whatever initial value you prefer
  const [isDataFetched, setIsDataFetched] = useState(false);
  
  async function fetchData() {
    const token = localStorage.getItem("token");
    try {
      const response = await getHelpDeskData(token);
      console.log("API Response:", response.data);
      setHelpData(response.data?.helpdesk);
      console.log(helpData, "help");
      setIsDataFetched(true); // Set the flag to indicate that data has been fetched
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  useEffect(() => {
    if (!isDataFetched) {
      fetchData();
    }
  }, [isDataFetched]);
   
  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const filteredHelpData = filterOption
    ? helpData.filter(
        (data) => data.status.toLowerCase() === filterOption.toLowerCase()
      )
    : helpData;
  return (
    <>
      <div className={classes.mainroot}>
        <InputForm fetchData={fetchData}/>
        {helpData?.length !== 0 && 
        <div>
        <div className={classes.root}>
          <div className={classes.heading}> My Help Desk</div>
          <div className={classes.filtercontainer}>
            <div className={classes.filtersection}>
              <div className={classes.filtericonandname}>
                <span>
                  <FilterIcon style={{ margin: "0.1rem 0.6rem 0 0" }} />
                </span>
                <Typography component={"span"}>Filter</Typography>
              </div>
              <div className={classes.filterinput}>
                <FormControl className={classes.margin}>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    disableUnderline
                    MenuProps={menuProps}
                    value={filterOption}
                    onChange={(e) => handleFilterChange(e)}
                  >
                    <MenuItem value="">Select Here</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        
        <div className={classes.container}>
          <HelpDeskCard helpData={filteredHelpData} />
        </div>
        </div>
        }
      </div>
    </>
  );
};

export default Help;
