import { Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react'
import SSCLogo from "../../image/latestseriesvector/SSCCGLlogo.png";
import LatestTestSeriesCardFree from './LatestTestSeriesCardFree';
import LatestTestSeriesCardMock from './LatestTestSeriesCardMock';
import LatestTestSeriesCardMockNew from './LatestTestSeriesCardMockNew';

const useStyles = makeStyles((theme) => ({
}));

function LatestTestSeriesCard({data}) {

    const classes = useStyles();

    return (
        <>
            <LatestTestSeriesCardMockNew data={data}/>
        </>
    );
}
export default LatestTestSeriesCard;