import React from "react";
import { GridOverlay, DataGrid } from "@mui/x-data-grid";
import useStyles from "../../styles";
import OrdersErrorPage from "../dashboard/ErrorPage/OrdersErrorPage";
import BooksErrorPage from "../dashboard/ErrorPage/BooksErrorPage";
import EbooksErrorPage from "../dashboard/ErrorPage/EbooksErrorPage";
import NoDataFound from "../dashboard/ErrorPage/NoDataFound";

function CustomTableView({ columns, rows, Heading, section }) {
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay className={classes.gridtop}>
        {section === "myPurchaseTable" ? (
          <OrdersErrorPage />
        ) : section === "myBooksTable" ? (
          <BooksErrorPage />
        ) : (
          <NoDataFound />
        )}
      </GridOverlay>
    );
  }

  const classes = useStyles();
  return (
    <>
      <div
        className={
          section === "myTestSeriesTable"
            ? classes.myTestSeriesTable
            : section === "myPurchaseTable"
            ? classes.myTestSeriesTable
            : section === "myBooksTable"
            ? classes.myBooksTable
            : section === "productTable"
            ? classes.productTable
            : section === "ebooksTable"
            ? classes.ebooksTable
            : section === "notesTable"
            ? classes.notesTable
            : section === "transactionTable"
            ? classes.transactionTable
            : section === "timeTable"
            ? classes.timeTable
            : section === "feedbacktable"
            ? classes.feedbacktable
            : section === "myLiveClassesTable"
            ? classes.myLiveClassesTable
            : classes.dashboardPurchaseTable
        }
      >
        <DataGrid
          className={` ${classes.h100} `}
          rows={rows}
          columns={columns}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
          }
          rowHeight={100}
          autoHeight
          hideFooter
          // pagination
          // pageSize={100}
          disableSelectionOnClick
          rowCount={rows.length}
        />
      </div>
    </>
  );
}
export default CustomTableView;
