import React from "react";
import { makeStyles } from "@material-ui/core";
import SecHead from "../../Home/sectionHeading/sectionheading";
import StudentTestimonialCard from "./studenttestimonialcard";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
    paddingTop: "3rem",
    paddingBottom: "2rem",
    background: "#FFF7E7",
    // margin: "2.2rem 0 12rem 0",
    [theme.breakpoints.down("sm")]: {
      marginTop: '3.2rem',
      // marginBottom: '12rem',
    }
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    justifyContent: "space-between",
  },
  onlineeducationcontent: {
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  onlineeducationimgcontainer: {
    width: "45%",
  },  
  content:{
    marginTop: "2rem",
  },
  onlineeducationcontentinner: {
    width: "95%",
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
      fontSize: "2rem",
      fontWeight: "700",
    },
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#7E7E7E",
      fontSize: "1rem",
      fontWeight: "400",
    },
  },
}));
function StudentTestimonial() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Personalized Online Learning: Education Tailored Just for You",
      headingmain: "Student Testimonial",
    },
  ];
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.studenttestimonialmain}>
        <StudentTestimonialCard />
        </div>
      </div>
    </div>
  );
}
export default StudentTestimonial;
