import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import {useNavigate} from 'react-router-dom'

import PropTypes from 'prop-types';
import { getJobNotification } from "../../service/services";
import {useEffect} from 'react'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    justifyContent: "center",
    width: "100%",
    position: "absolute",
    // bottom: '-48px',
    top: "15rem",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
    },
    "& .MuiTab-root ": {
      minWidth: "120px !important",
      minHeight: "30px !important",
    },
    "& .MuiTabs-root": {
      alignItems: "center",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      background:
        "linear-gradient(90deg, rgba(255,128,8,0.6) 0%, rgba(255,200,55,0.6) 100%)",
      color: "black",
      padding: "1rem",
      lineHeight: "0",
      fontWeight: "600",
      fontSize: "0.8rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
        fontSize: 14,
        fontFamily: "Jost",
      },
    },
    "& .MuiAppBar-root": {
      background: "#FFFFFF",
      color: "#FFFFFF",
      boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.10)",
      [theme.breakpoints.down("sm")]: {
        boxShadow: "none",
        margin: ".6rem 0",
      },
    },
    "& .MuiTab-textColorPrimary": {
      color: "#212121",
      background: "#FFF8E8",
      margin: "5px",
      fontWeight: "600",
      fontSize: "0.8rem",
      lineHeight: "0 !important",
      borderRadius: "10rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0 1rem",
        fontSize: 14,
        fontFamily: "Jost",
      },
    },
    "& .MuiTabScrollButton-root": {
      width: "30px",
      height: "30px",
      padding: "10px",
      margin: "0.4rem",
      background: "white",
      color: "black",
      borderRadius: "50%",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "2.25rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 ",
    },
  },
}));

const ExamData = [
  {
    id: 1,
    subCategories: "Job Notifications",
  },
  {
    id: 2,
    subCategories: "Admit Card",
  },
  {
    id: 3,
    subCategories: "Results",
  },
  {
    id: 4,
    subCategories: "Answer Keys",
  },
  {
    id: 5,
    subCategories: "Syllabus",
  },
  {
    id: 6,
    subCategories: "UPSC",
  },
  {
    id: 7,
    subCategories: "Defence",
  },
  {
    id: 8,
    subCategories: "Agriculture",
  },
  {
    id: 9,
    subCategories: "",
  },
];



const SubCategoryNotification = ({ value, setValue, a11yProps,Heading }) => {
  const classes = useStyles();

 
  const [notData,setNotData]=useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getJobNotification(); 
        console.log("API Response:", response.data);
        setNotData(response.data.notify);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []); 
  const navigate=useNavigate();
  let handleChange;

  if (value !== undefined && setValue && a11yProps && Heading) {
    handleChange = (event, newValue) => {
      console.log('Updating URL parameter:', newValue);
      navigate(`/notification/${newValue}`);
      setValue(newValue);
    };
  }

  
  return (
    <>
     
      <div className={classes.root}>
  <div className={classes.container}>
    <AppBar position="static">
      <Tabs 
        value={value}
        onChange={handleChange} 
        indicatorColor="none"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {notData?.map((data,index)=> data.notifications.length > 0 && (
          <Tab key={index} value={data.id} label={data.category_name} {...a11yProps(data.id)} />
        ))}
      </Tabs>
    </AppBar>
  </div>
  
</div>

    </>
  );
};

export default SubCategoryNotification;
