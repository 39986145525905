import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// import { ReactComponent as FAQSvg } from "../image/affiliatesvector/faqiconsvg.svg";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getFAQ } from "../../../../service/services";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding: "2rem 0",
    "& .MuiTypography-h4": {
      color: "#243B55;",
      fontSize: "1.5rem",
      display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: "0.5rem"
    }
    },
    "& .MuiTypography-h6": {
      marginTop: "8px",
      fontSize: "1rem",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0"
    }
  },
  root: {
    // marginBottom: "13rem",
    width: "100%",
    // padding: "2rem",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",

    "& .MuiTypography-h3": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.25rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    "& .MuiTypography-h4": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
        fontWeight: "500",
      },
      fontSize: "1.25rem",
      fontWeight: "500",
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
    },
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      color: "#727272",
    },
    "& .MuiAccordion-root:before": {
      height: "0",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0 1rem",
      borderBottom: "1px solid #DBDBDB",
      [theme.breakpoints.down("xs")]: {
        padding: "0 1rem 1rem 1rem",
      },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      border: "none",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      background: "#FFF5DF",
      padding: "0 1rem",
      [theme.breakpoints.down("xs")]: {
        background: "#FAFAFA",
      },
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordion-root": {
      padding: "0 1rem",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        background: "#FFF5DF",
      },
    },
  },
  headingmain: {
    display: "flex",
    alignItems: "center",
    background:'white',
    justifyContent: "space-between",
    padding: "2rem 2rem 1rem 2rem",
    [theme.breakpoints.down("xs")]: {
      background: "#FFF5DF",
    },
  },
  headingmaininner: {
    display: "flex",
    alignItems: "center",
    "& a": {
      display: "flex",
      textTransform: "uppercase",
      textDecoration: "none",
      color: "#154FE5",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        color: "#252525",
        fontSize: "0.9rem",
      },
    },
  },
  arrowicon: {
    color: "#154FE5",
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      color: "#252525",
    },
  },
  faqsvg: {
    marginRight: "0.8rem",
  },
}));

export default function FAQSection({testseriesDetail}) {
  const classes = useStyles();
const id=testseriesDetail?.sub_category_id;
const [faq, setFaq] = useState([]);
const type="allby_examId";
useEffect(() => {
  async function fetchData(id) {
    try {
      const response = await getFAQ(id,type);
      console.log("API Response:", response.data);
      setFaq(response.data.faqs?.questions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  fetchData(id);
}, [id]);
if (faq.length <= 0) return null;
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.root}>
          <div className={classes.headingmain}>
            <div className={classes.headingmaininner}>
              {/* <FAQSvg className={classes.faqsvg}/> */}
              <Typography variant="h3"> FAQ</Typography>
            </div>
            <div className={classes.headingmaininner}>
            <Link to={`/faq/${type}/${id}`}>

                View all
                <ChevronRightIcon className={classes.arrowicon} />
              </Link>
            </div>
          </div>
          {faq?.map((data, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h4" className={classes.faqheading}>
                  Q.{index + 1}:- {" "} 
                  <span
                    dangerouslySetInnerHTML={{ __html: data?.question }}
                  ></span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{ __html: data?.answer }}
                  ></span>
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
        </div>
      </div>
    </div>
  );
}
