import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import nodleshairteacher from "../../image/dashboard/videocourse/nodleshairteacher.png";
import rupeesvector from "../../image/dashboard/videocourse/rupeesvector.png";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "1rem 0",
    "& .MuiCardContent-root":{
      paddingBottom:"0px !important",
      padding:"7px"
    }
  },
  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      color: "#234A63",
      fontFamily: "Playfair Display",
      fontSize: "0.5625rem",
      fontWeight: 700,
      lineHeight: "0.5625rem",
      textTransform: "capitalize",
      marginTop:"0.5rem",
    },
    "& .MuiTypography-h5": {
      color: "#234A63",
      fontFamily: "Playfair Display",
      fontSize: "0.25rem",
      fontWeight: 700,
      lineHeight: "0.375rem",
      textTransform: "capitalize",
      marginTop: "0.2rem",
    },
    "& .MuiTypography-h4": {
      color: "#000",
      fontFamily: "Jost",
      fontSize: "0.1875rem",
      fontWeight: 400,
      lineHeight: "1.025rem",
      textTransform: "capitalize",
      borderRadius: "0.125rem",
      background: " #FFF",
      boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.14)",
      textAlign: "center",
      marginTop:"0.1rem"
    },
  },
  imagecontainer: {
    width: "30%",
    display: "flex",
  },
  contentcontainer: {
    width: "62%",
    "& .MuiTypography-subtitle1": {
      color: "#000",
      fontFamily: " Playfair Display",
      fontSize: "0.8125rem",
      fontWeight: 400,
      lineHeight: "1.0625rem",
      textTransform: "capitalize",
    },
    "& .MuiTypography-subtitle2": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: "1.875rem",
    },
  },
  lecture: {
    color: "#777",

    fontFamily: "Jost",
    fontSize: "0.5625rem",
    fontWeight: 500,
    lineHeight: "1rem",
  },
  line: {
    background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
    marginTop: "0.1rem",
  },
  rate: {
    color: "#555",
    fontFamily: "Jost",
    fontSize: "0.55rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  custombtn: {
    width: "100%",
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "white",
    padding: "0.3rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif !important",
      fontWeight: "400",
      color: "#303030",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const VideoCoursesCardMobile = ({Data}) => {
  const classes = useStyles();
  console.log(Data,"gaurav")
  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.cardcontainer}>
            <div className={classes.imagecontainer}>
              <div>
                <Typography variant="h6">
                   {Data?.video_name}
                </Typography>
                <Typography variant="h5">
                  6+ years of experience in teaching General Awareness
                </Typography>
                <Typography variant="h4"> Validity: 6 Months </Typography>
              </div>
              <div>
                <img src={nodleshairteacher||Data?.image_url} alt="img" width="80px"/>
              </div>
            </div>
            <div className={classes.contentcontainer}>
              <Typography variant="subtitle1">
             {Data?. category?.category_name||  "Quantitative Aptitude Series for UPSC 2023"}
                <span className={classes.lecture}>&nbsp; 25 Lectures</span>
              </Typography>
              <Divider className={classes.line} />
              <Typography variant="subtitle2">
                <img src={rupeesvector} /> {Data?.selling_cost}.00&nbsp;&nbsp;
                <span className={classes.rate}>
                  MRP: &nbsp;&nbsp;<del>${Data?.mrp}.00</del>&nbsp;&nbsp;({Data?.discount_percent}%off){" "}
                </span>
              </Typography>
              <CardActions>
                <Button className={classes.custombtn}>ADD TO CART</Button>
              </CardActions>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default VideoCoursesCardMobile;
