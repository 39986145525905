import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHead from "../pageheading/pageheading";
import NoteContent from "./notecontent";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginBottom: "15rem",
  },
}));
function SinglePageNote() {
  const classes = useStyles();

  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Study",
      headingmain: "Reasoning Analogy pdf",
      breadcrumb: " Notes / Quizzes",
    },
  ];
  return (
    <div className={classes.mainContainer}>
      <PageHead Heading={Heading} />
      < NoteContent/>
    </div>
  );
}
export default SinglePageNote;
