import React from "react";
import {
    Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import captchaicon from "../image/applicationform/captchaicon.png"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20rem",
    background: "#f3f9fd",
    padding: "3rem 1rem",

    "& .MuiTypography-h1": {
      fontFamily: "jost",
      fontSize: "2rem",
      fontWeight: "400",
      color: "#318CE7",
    },
  },
  hrline: {
    marginTop: "1rem",
    borderColor: "#90ee90",
    height: 2,
  },
  innercontainer: {
    margin: "auto",
  },
  formcontainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.2rem",
  },
  label: {
    fontSize: "1.2rem",
    fontWeight: "700",
    marginRight: "1.8rem",
    width: "15%",
  },
  textfield1: {
    background: "#D9FEE5",
    boxShadow: "0px 4px 15px 0px #0000002E",
  },
  textfield2: {
    background: "#e2f3fd",
    boxShadow: "0px 4px 15px 0px #0000002E",
  },
  textfield: {
    boxShadow: "0px 4px 15px 0px #0000002E",
  },
  selectfield: {
    width: "100%",
    padding: "0rem 3rem",
    boxShadow: "0px 4px 15px 0px #0000002E",
  },
  captcha:{
    color:"green",
    background:"#E9FCE9",
    boxShadow: "0px 4px 15px 0px #C1E1C1",
    padding:"0.5rem"
  },
  btn:{
    marginLeft:"12rem",
    fontWeight: "800",
    fontSize: "12px",
    FontFamily: "Plus Jakarta Sans",
    height: "51px",
    borderRadius: "6px",
    backgroundColor: "#0039a6",
    color: "rgba(255, 255, 255, 1)",
  
  
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h1"> Forgot Password ?</Typography>
        <Divider className={classes.hrline} />
        <div className={classes.innercontainer}>
          <div className={classes.formcontainer}>
            <div className={classes.label}> Application Form</div>
            <div>
              <TextField
                className={classes.textfield1}
                id="outlined-basic "
                placeholder="NEET (UG) 2021"
                variant="outlined"
              />
            </div>
          </div>

          <div className={classes.formcontainer}>
            <div className={classes.label}> Application Number</div>
            <div>
              <TextField
                className={classes.textfield2}
                id="outlined-basic "
                placeholder="200210047777"
                variant="outlined"
              />
            </div>
          </div>
          <div className={classes.formcontainer}>
            <div className={classes.label}> Security Question</div>
            <div>
              <FormControl className={classes.margin}>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  variant="outlined"
                  className={classes.selectfield}
                  MenuProps={menuProps}
                  value={-1}
                >
                  <MenuItem value={-1}>Select Here</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.formcontainer}>
            <div className={classes.label}> Security Answer</div>
            <div>
              <TextField
                className={classes.textfield2}
                id="outlined-basic "
                placeholder="********"
                variant="outlined"
              />
            </div>
          </div>
          <div className={classes.formcontainer}>
            <div className={classes.label}> Security Pin (case sensitive)</div>
            <div>
              <TextField
                className={classes.textfield}
                id="outlined-basic "
                placeholder="NEET (UG) 2021"
                variant="outlined"
              />
            </div>
          </div>
          <div className={classes.formcontainer}>
            <div className={classes.label}> Security Pin</div>
            <div className={classes.captcha}> 36888E </div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={captchaicon} alt="img" width="2%"/>
          </div>
          <div className={classes.formcontainer}>
          <Button className={classes.btn}> Submit</Button>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
