import React from "react";
import {
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SSCBook from "../../image/dashboard/sscbookimage.png";
const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.10)",
    margin: "1rem 0",
    borderRadius: "4px",
    padding: "1rem",
    "& .MuiTypography-h5": {
      color: "#262626",
      fontFamily: "Jost",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      marginTop: "0.5rem",
      marginRight: "0.5rem",
    },
    "& .MuiTypography-h3": {
      color: "#262626",
      fontFamily: "Jost",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      marginTop: "0.5rem",
    },
    "& .MuiTypography-h6": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      marginTop: "0.5rem",
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      width: "63%",
    },
    "& .MuiTypography-h4": {
      color: "#262626",
      fontFamily: "Jost",
      fontSize: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  
  bookbox: {
    "& img": {
      marginTop: "0.5rem",
    },
  },
  viewbutton: {
    marginTop: "0.5rem",
    width: "40%",
    padding: "0.25rem",
    borderRadius: "6px",
    background: "#243B55",
    "&:hover": {
      background: "linear-gradient(270deg, #FFB36B 0%, #FFD57A 100%);",
    },
    "& .MuiButton-label": {
      color: "#fff",
      textTransform: "capitalize",
      "&:hover": {
        color: '#000'
      },
    }
  },
  innertitle: {
    display: "flex",
    alignItems: "baseline",
  }
}));
function FeedbackTableMobile({ setOnViewClicked, data }) {
  const classes = useStyles();
  console.log("dataOrder", data)
  return (
    <>
      <div className={classes.main}>
       
         
          <div className={classes.content}>
            <div className={classes.innertitle}>
              <Typography variant="h5">
                Subject
              </Typography>
              <Typography variant="h3">
               {data?.subject}
              </Typography>
            </div>
            <div className={classes.innertitle}>
            <Typography variant="h5">
                Teacher:
              </Typography>
              <Typography variant="h3">
              {data?.teacher}
              </Typography>
            </div>
            <div className={classes.innertitle}>
              <Typography variant="h5">
                Schedule Date:
              </Typography>
              <Typography variant="h3">
              {data?.schedule_date}
              </Typography>
            </div>
              <Button fullWidth onClick={() => { setOnViewClicked("feedbackinner", data) }} className={classes.viewbutton}>
                Start
              </Button>
          </div>
        </div>
    </>
  );
}
export default FeedbackTableMobile;
