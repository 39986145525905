import {
  Button,
  Typography,
  makeStyles,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { FeedBackAdd } from "../../../service/services";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0px !important",
    },
    "& .ql-toolbar": {
      borderRadius: "10px 10px 0 0",
    },
    "& .ql-container": {
      height: "120px",
      borderRadius: "0 0 10px 10px",

      [theme.breakpoints.down("xs")]: {
        height: "100px",
      },
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  container: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    background: "#FFFBF5",
    borderRadius: "10px",
    padding: "1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem",
      marginTop: "1rem",
    },
  },
  headercontent: {
    background: "#FFF",
    width: "30%",
    padding: "0.3rem 0.75rem",
    borderRadius: "6px",
    boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06)",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      width: "auto",
      background: "transparent",
      boxShadow: "none",
    },
    "& .MuiTypography-h5": {
      fontFamily: "Playfair Display !important",
      fontSize: "1rem",
      fontWeight: 700,
      color: "#262626",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9rem",
      },
    },
    "& .MuiTypography-h6": {
      fontFamily: "Jost !important",
      fontSize: "0.9rem",
      fontWeight: 400,
      color: "#262626",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9rem",
      },
    },
  },
  quillcontainer: {
    marginTop: "2rem",
    width: "100%",
    "& .MuiTypography-h3": {
      fontFamily: "Jost",
      fontSize: "0.87rem",
      fontWeight: 400,
      color: "red",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0rem",
      },
    },
  },

  question: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    "& .MuiTypography-h5": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.125rem",
      fontWeight: 600,
      color: "#262626",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTypography-h4": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 400,
      marginLeft: "1rem",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0rem",
      },
    },
  },
  placeholder: {
    color: "#A4A4A4",
    fontFamily: "Jost",
    fontSize: "1.375rem",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },

  viewbtn: {
    "&.MuiButton-root": {
      width: "15%",
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
      alignSelf: "end",
      marginTop: "1rem",
      [theme.breakpoints.down("xs")]: {
        width: "35%",
      },
    },
  },
  textfield: {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
      borderWidth: "1px",
    },
  },
}));

const FeedbackInner = ({ setOnViewClicked, data }) => {
  const classes = useStyles();
  console.log(data, "data");
  const [answers, setAnswers] = useState({});

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    });
  };
  const handleAnswerChange = (index, value) => {
    setAnswers({ ...answers, [index]: value });
  };
  const handleCheckboxChange = (fieldId, optionId, checked) => {
    const field = data.fields.find((field) => field.id === fieldId); // Find the field object
    const optionName = field.options.find(
      (option) => option.id === optionId
    )?.option; // Find the option name
    const fieldValue = checked ? optionName : ""; // Set to optionName if checked, otherwise empty string
    setAnswers({
      ...answers,
      [fieldId]: { ...answers[fieldId], [optionId]: fieldValue },
    });
  };
  console.log(answers, "answers");

  const handleSubmitAnswers = async () => {
    let error = false;

    data.fields.forEach((field, i) => {
      if (field.required && !answers[field.id]) {
        toast.warn(`Question No. ${i + 1} is required`);
        error = true;
      }
    });

    if (error) {
      return;
    }

    try {
      const token = localStorage.getItem("token");

      
      const responseFields = data.fields.map((field) => {
        let responseValue = answers[field.id];

        // If it's a checkbox field and response value is an object, convert it to comma-separated string
        if (field.type === "checkbox" && typeof responseValue === "object") {
      
          const selectedOptions = Object.values(responseValue).filter(Boolean);

          responseValue = selectedOptions.length > 0 ? selectedOptions.join(", ") : "";
        }
        return {
          id: field.id,
          response: responseValue || "",
        };
      });

      // Construct the feedback data
      const feedbackData = {
        response: {
          fields: responseFields,
        },
      };

      const response = await FeedBackAdd(feedbackData, token);
      toast.success("Answer submitted successfully!");
      setTimeout(() => {
        setOnViewClicked("");
      }, 1000);
      console.log("API Response:", response);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An answer has already been provided for this feedback question."
      );
    }
  };
  // Map between field types and corresponding input components
  const inputComponents = {
    radio: (field, index) => (
      <FormControl component="fieldset" key={index}>
        <RadioGroup
          row
          value={answers[field.id] || ""}
          onChange={(event) => handleAnswerChange(field.id, event.target.value)}
        >
          {field.options.map((option, optionIndex) => (
            <FormControlLabel
              key={optionIndex}
              value={option.option}
              control={<Radio />}
              label={option.option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    ),
    checkbox: (field, index) => (
      <FormControl component="fieldset" key={index}>
        {field.options.map((option, optionIndex) => (
          <FormControlLabel
            key={optionIndex}
            control={
              <Checkbox
                checked={Boolean(
                  answers[field.id] && answers[field.id][option.id]
                )}
                onChange={(event) =>
                  handleCheckboxChange(
                    field.id,
                    option.id,
                    event.target.checked
                  )
                }
                color="primary"
              />
            }
            label={option.option}
          />
        ))}
      </FormControl>
    ),
    textarea: (field, index) => (
      <TextField
        key={index}
        value={answers[field.id] || ""}
        onChange={(event) => handleAnswerChange(field.id, event.target.value)}
        minRows={4}
        multiline
        className={classes.textfield}
        placeholder="Enter Your Answer"
        variant="outlined"
      />
    ),
    text: (field, index) => (
      <TextField
        key={index}
        type="text"
        value={answers[field.id] || ""}
        onChange={(event) => handleAnswerChange(field.id, event.target.value)}
        placeholder="Enter Your Answer"
        variant="outlined"
        className={classes.textfield}
      />
    ),
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.root}>
        <div className={classes.headname}>
          <IconButton
            onClick={() => {
              setOnViewClicked("");
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h6">Feedback Form</Typography>
        </div>
        <div className={classes.container}>
          <div className={classes.header}>
            <div className={classes.headercontent}>
              <Typography variant="h5">Subject</Typography>
              <Typography variant="h6">
                {data?.subject?.subject_name}
              </Typography>
            </div>
            <div className={classes.headercontent}>
              <Typography variant="h5">Teacher</Typography>
              <Typography variant="h6">{data?.teacher?.full_name}</Typography>
            </div>
            <div className={classes.headercontent}>
              <Typography variant="h5">Schedule Date</Typography>
              <Typography variant="h6">
                {formatDate(data?.schedule_date)}
              </Typography>
            </div>
          </div>
          {data?.fields?.map((field, index) => (
            <div key={index} className={classes.quillcontainer}>
              {field.required && (
                <Typography variant="h3">Required*</Typography>
              )}
              <div className={classes.question}>
                <Typography variant="h5">Question {index + 1} :-</Typography>
                <Typography
                  variant="h4"
                  dangerouslySetInnerHTML={{ __html: field.label }}
                />
              </div>
              {/* Render input based on field type */}
              {inputComponents[field.type](field, index)}
            </div>
          ))}
          <Button onClick={handleSubmitAnswers} className={classes.viewbtn}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FeedbackInner;
