import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTableView from "../../CustomComponent/TableView";
import BooksTableMobile from "./BooksTableMobile";
import { getMydashboardMyBooks } from "../../../service/services";
import BooksErrorPage from "../ErrorPage/BooksErrorPage";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0rem 2rem 1rem 2rem ",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    minHeight: "84vh",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.8rem",
      textAlign: "justify",
      minHeight: "80vh",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headname: {
    display: "flex",
    alignItems: "center",
    // marginTop: "1rem",
    width: "47%",
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  attempted: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#323232",
    },
  },
  table: {
    minWidth: 700,
  },
  activebtn: {
    color: "#fff",
    background: "#313866",
    textTransform: "capitalize",
    padding: "0rem 1rem",
    width: "60%",
    borderRadius: "0.375rem",
    fontFamily: "Jost",
    fontWeight: "500",
    fontSize: "0.875rem",
  },
  disabledbtn: {
    border: "1px solid #EBEBEB",
    color: "#262626",
    background: "#FFF",
    textTransform: "capitalize",
    padding: "0rem 1rem",
    width: "60%",
    borderRadius: "0.375rem",
    fontFamily: "Jost",
    fontWeight: "400",
    fontSize: "0.875rem",
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "30%",
    marginTop: "0.2em",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

function MyBooks(props) {
  const classes = useStyles();

  const setOnViewClicked = props.setOnViewClicked;
  const [rows, setRows] = useState([]);
  let mql = window.matchMedia("(max-width: 650px)");
  const [data, setData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchData() {
      try {
        const response = await getMydashboardMyBooks(token);
        setData(response?.data?.mypurchases_books);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    if (data && data.length > 0) {
      const updatedRows = data.flatMap((book) => ({
        id: book?.id || null,
        image: book.product_data?.image_url || null,
        productname: book.product_data?.book_name || null,
        authorname: book.product_data?.author || null,
        amount: book.product_data?.selling_cost || null,
      }));
      setRows(updatedRows);
    } else {
      setRows([]);
    }
  }, [data]);

  const columns = [
    {
      field: "image",
      headerName: "#",
      headerClassName: "super-app-theme--header",
      cellClassName: "imagecell",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return <img src={cellValues.row.image} alt="bookimg" />;
      },
    },
    {
      field: "productname",
      headerName: "Product Name",
      headerClassName: "super-app-theme--header",
      cellClassName: "productnamecell",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "authorname",
      headerName: "Author Name",
      headerClassName: "super-app-theme--header",
      cellClassName: "productnamecell",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{cellValues.value}</div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      cellClassName: "totalcostcell",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <p>
            <span>₹</span>
            {cellValues.value}
          </p>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <Button
            onClick={() => {
              setOnViewClicked("orderdetails", cellValues.row.id);
            }}
            className={classes.disabledbtn}
          >
            View
          </Button>
        );
      },
    },
  ];
  const dummyRows = [];

  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <Typography gutterBottom className={classes.heading}>
            My Books
          </Typography>
        </div>
      </div>
      {!mql.matches ? (
        rows.length !== 0 ? (
          <CustomTableView
            columns={columns}
            rows={rows}
            section={"myBooksTable"}
          />
        ) : (
          <BooksErrorPage />
        )
      ) : rows.length !== 0 ? (
        rows.map((data, index) => (
          <BooksTableMobile setOnViewClicked={setOnViewClicked} data={data} />
        ))
      ) : (
        <BooksErrorPage />
      )}
    </div>
  );
}

export default MyBooks;
