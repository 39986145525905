import { Button, Divider, IconButton, Typography, makeStyles } from '@material-ui/core';
import React from 'react'
import SSCLogo from "../../image/latestseriesvector/SSCCGLlogomobie.png";
import { ReactComponent as AttemptIcon } from "../../image/latestseriesvector/attempticon.svg";
import { ReactComponent as QuestionsIcon } from "../../image/latestseriesvector/questionsicon.svg";
import { ReactComponent as TimerIcon } from "../../image/latestseriesvector/timericon.svg";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import user from '../../image/catergoryVectoe/Group291.svg'
import ques from '../../image/catergoryVectoe/Group277.svg'
import clock from '../../image/catergoryVectoe/Group276.svg'


const useStyles = makeStyles((theme) => ({
    card_container: {
        padding: "1rem 0.8rem",
        boxShadow: "0px 0px 20px rgba(58, 58, 58, 0.10)",
        maxWidth: "380px",
        borderRadius: "80px 6px 6px 80px",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        background: "#FFF",

        "& .MuiTypography-h5": {
            fontFamily: "'Jost', sans-serif",
            fontSize: "0.73rem",
            fontWeight: "600",
            textTransform: "uppercase",
            color: "#000",
        },
        "& .MuiTypography-caption": {
            fontFamily: "'Jost', sans-serif",
            fontSize: "0.5rem",
            fontWeight: "500",
            color: "#727272",
        },
    },
    examlogo: {
        width: "38%",
        padding: "0.62rem 0.4rem 0.58rem 0.4rem",
        borderRadius: "70px",
        background: "#FFEEC6",
        boxShadow: "0px 2px 35px rgba(255, 232, 192, 0.80)",
    },
    examcontent: {
        width: "59%",
    },
    examtitle: {
        marginLeft: "0.5rem",
    },
    buttonsection: {
        marginTop: "0.7rem",
        display: "flex",
        justifyContent: "space-evenly",
        width: "83%",
    },
    "& .slick-prev, .slick-next": {
        background: "black",
        padding: "15px",
        borderRadius: "40px",
        "&:hover": {
            color: "#FF8008",
        },
    },

    "& .slick-prev:before, .slick-next:before": {
        fontSize: "55px",
        borderRadius: "40px",
        transform: "translate(-50%, -50%)",
        content: "→",
        color: "white",
        opacity: "1",
        bottom: 0,
        boxShadow:
            "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
        display: "flex",
        justifyContent: "center",
        paddingTop: "0.5rem",
    },

    "& .slick-dots li button:before": {
        color: "#FF8008",
        fontSize: "15px",
    },
    bluecustombtn1: {
        background: "linear-gradient(270deg, rgba(255, 128, 8, 0.4) 0%, rgba(255, 200, 55, 0.4) 71.55%)",
        color: "black",
        padding: "0.1rem 1.2rem",
        borderRadius: "0.4rem",
        "&:hover": {
            background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
            color: "#252525",
        },
        "& .MuiButton-label": {
            fontSize: "0.5rem",
            fontFamily: "Jost",
            fontWeight: "400",
        },
    },
    bluecustombtn2: {
        background: "linear-gradient(270deg, rgba(255, 128, 8, 0.4) 0%, rgba(255, 200, 55, 0.4) 71.55%)",
        color: "black",
        padding: "0.1rem 0.5rem",
        borderRadius: "0.4rem",
        "&:hover": {
            background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
            color: "#252525",
        },
        "& .MuiIconButton-label": {
            fontFamily: "Jost",
            fontWeight: "400",
            "& .MuiSvgIcon-root": {
                width: "0.5em"
            },
        },
    },
    divider: {
        margin: "0.5rem 0",
        background: "#FFF7E4",
        height: "0.08rem",
    },
    examinfo: {
        display: "flex",
        justifyContent: "space-between",
    },
    infoinner: {
        display: "flex",
        alignItems: "stretch",
    },
    card: {
        background: '#fff',
        borderRadius: "12px",
    },
    heading: {
        fontFamily: "Jost",
        margin: "1rem 0",
        fontWeight: 600,


    },
    subHeading: {
        fontFamily: "Jost",
        marginBottom: "1.5rem",
        fontWeight: 400,
        color: "#727272", padding: "0px 1rem"
    },
    button: {
        background: "#FFD9B5",
        padding: "1rem",
        borderRadius: 6,
        fontSize: 16,
        fontFamily: "Jost",
        display: "flex", justifyContent: "center"
    },
    buttonsmall: {
        background: "#FFD9B5",
        padding: ".5rem",
        borderRadius: 6,
        fontSize: 16,
        fontFamily: "Jost",
        display: "flex", justifyContent: "center"
    },
    lightbtn: {
        background: "#FFFAEF",
        padding: ".3rem",
        borderRadius: 3,
        fontSize: 16,
        fontFamily: "Jost",
        display: "flex", justifyContent: "center",
        margin: "20px 0px"
    }
}));

function LatestTestSeriesCardMobile() {

    const classes = useStyles();

    return (
        // <div >
        //     <div className={classes.card}>
        //         <div style={{ padding: 30 }}>
        //             <div className={classes.buttonsmall} style={{ width: "fit-content" }}>
        //                 <img src={user} style={{ marginRight: 10 }} />Special Recognition</div>

        //             <Typography variant="h6" className={classes.heading}>All India Mock 9: SSC CGL Tier-I 12-Jul-2023</Typography>
        //             <div className={classes.buttonsmall} style={{ width: "fit-content" }}>
        //                 <img src={ques} style={{ marginRight: 10 }} />100 ques |   <img src={clock} style={{ margin: "0px 10px" }} />100 ques</div>
        //             <div className={classes.lightbtn} style={{ width: "fit-content" }}>
        //                 <img src={user} style={{ marginRight: 10 }} />Special Recognition</div>
        //         </div>
        //         <div className={classes.button}>VIEW RESULT</div>
        //     </div>
        // </div>
        <div className={classes.card_container}>
            <div className={classes.examlogo}>
                <img src={SSCLogo} alt="cardimg" />
            </div>
            <div className={classes.examcontent}>
                <div className={classes.examtitle}>
                    <Typography variant='h5'>
                        SSC: Direct & Indirect Speech
                    </Typography>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.examinfo}>
                    <div className={classes.infoinner}>
                        <AttemptIcon style={{ marginRight: 5 }} />
                        <Typography variant='caption'>500 attempts</Typography>
                    </div>
                    <div className={classes.infoinner}>
                        <QuestionsIcon style={{ marginRight: 5 }} />
                        <Typography variant='caption'>100 Ques</Typography>
                    </div>
                    <div className={classes.infoinner}>
                        <TimerIcon style={{ marginRight: 5 }} />
                        <Typography variant='caption'>90 min</Typography>
                    </div>
                </div>
                <div className={classes.buttonsection}>
                    <Button className={classes.bluecustombtn1}>
                        start Quizz
                    </Button>
                    <IconButton className={classes.bluecustombtn2}>
                        <ShareOutlinedIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default LatestTestSeriesCardMobile;