import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { ReactComponent as NotificationVector } from "../image/coachingsvector/notificationvector.svg";
import { ReactComponent as ListIcon } from "../image/coachingsvector/listicon.svg";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height:'500px',
  margin:'0 1rem',
    "& .MuiCardHeader-root": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
      color: "white",
    },
    "& .MuiCardHeader-title": {
      color: "#FFF",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.5625rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "2.5rem",
      textTransform: "capitalize",
      marginLeft: "1rem",
    },
    '& .MuiListSubheader-root':{
        color: '#303030',
        fontFamily: "'Jost', sans-serif",
        fontSize: '1.125rem',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '1.75rem',
        textTransform: 'capitalize',
    },
  },
}));

export default function FeatureBenefitsCard(props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <Card className={classes.root}>
      <CardHeader avatar={<NotificationVector />} title={data.cardheading}/>
      <CardContent>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {data.listheading}
            </ListSubheader>
          }
        >
         {data.listcontent.map((list, index) => (
          <ListItem>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary={list} />
          </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
