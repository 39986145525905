import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import SliderSquare from "./slidersqaure";
import MentorSliderData from "./mentorsliderdata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "0rem",
    padding: "4rem 0",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
  },
  container: {
    maxWidth: "85%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  onlineeducationmain: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5rem",
    "& .slick-dots": {
      bottom: "-60px",
    },
    "& .slick-dots li button:before": {
      color: "rgba(58, 53, 101, 1)",
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      "& .squareBlock": {
        width: "396px",
        height: "443px",
      },
    },
  },
  mainHeading: {
    marginTop: "2rem",
    width: "100%",
    textAlign: "center",
  },
}));

function MentorSlider() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Some of the Talented.",
      headingmain: "Mentors Connected With Us",
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <div className={classes.onlineeducationmain}>
          <Slider {...settings}>
            {MentorSliderData.map((block) => (
              <div key={block.id} className="squareBlock">
                <SliderSquare
                  iconName={block.iconName}
                  heading={block.heading}
                  subheading={block.subheading}
                  experienceText={block.experienceText}
                  className={classes.blockContent}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default MentorSlider;
