import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    fontFamily: "Jost-Regular",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
  },
  resposivesubheaderbackground: {
    background: "linear-gradient(270deg, #FFCD9C 50%, #FFD57A 50%);",
    top: "0",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  notice: {
    padding: "0.4rem 0px",
    color: "white",
    fontFamily: "'Jost', sans-serif",
    width: "9%",
    clipPath: "polygon(0 0, 0 100%, 100% 100%, 90% 50%, 100% 0)",
    background: "linear-gradient(270deg, #FFB36B 0%, #FFD57A 100%);",
    fontSize: "26px",
    marginRight: "20px",
textShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
  },
  SubHeaderMain: {
    width: "100%",
    zIndex: "1",
    backgroundColor: "rgb(255,231,173)",
    background: "linear-gradient(90deg, #FFD57A 0%, #FFE5AD 5%, #FFCD9C 100%)",
    display: "flex",
    alignItems: "center",
  },
  subheaderLink: {
    padding: "0.5rem 0.6rem",
    fontSize: "0.9rem",
    textDecoration: "none",
    textTransform: "uppercase",
    fontFamily: "'Jost', sans-serif",
    color: "black",
    "&:hover": {
      textDecoration: "none",
      borderRadius: "0.3125rem",
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      background:
        "radial-gradient(100.00% 100% at 50.00% 100.00%, rgba(255, 128, 8, 0.40) 0%, rgba(255, 169, 88, 0.00) 100%)",
    },
  },
}));
function SubHeader() {
  const location = useLocation();
  const classes = useStyles();

  const FotterCard = () => ( <div className={classes.SubHeaderMain}>
    <div className={classes.notice}>
      <span>LINKS</span>
    </div>
    <div className={classes.Subheadermenu}>
      <Link
        to="/biometric"
        variant="button"
        color="textPrimary"
        className={classes.subheaderLink}
      >
        Biometric
      </Link>
      <Link
        to="/onlineassessment"
        variant="button"
        color="textPrimary"
        className={classes.subheaderLink}
      >
        Online Assessment
      </Link>
      <Link
        variant="button"
        color="textPrimary"
        to="/digitalevaluation"
        className={classes.subheaderLink}
      >
        Digital Evaluation
      </Link>
      <Link
        variant="button"
        color="textPrimary"
        to="/rps"
        className={classes.subheaderLink}
      >
        RPS
      </Link>
      <Link
        variant="button"
        color="textPrimary"
        to="/rps"
        className={classes.subheaderLink}
      >
        LMS
      </Link>
      <Link
        variant="button"
        color="textPrimary"
        to="/rps"
        className={classes.subheaderLink}
      >
        ERP
      </Link>
      {/* <Link
        variant="button"
        color="textPrimary"
        to="/coachings"
        className={classes.subheaderLink}
      >
        Coachings
      </Link>
      <Link
        variant="button"
        color="textPrimary"
        to="/teachers"
        className={classes.subheaderLink}
      >
        Teachers
      </Link> */}
      {/* <Link
        variant="button"
        color="textPrimary"
        to="/liveclasses"
        className={classes.subheaderLink}
      >
        Live Classes
      </Link>
      <Link
        variant="button"
        color="textPrimary"
        to="/onlinevideos"
        className={classes.subheaderLink}
      >
        Online videos
      </Link> */}
    </div>
  </div>);

  return (
    <React.Fragment>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
        {location.pathname !== "/dashboard" && <FotterCard/>}
        </div>
        <div className={classes.resposivesubheaderbackground}></div>
      </div>
    </React.Fragment>
  );
}
export default SubHeader;
