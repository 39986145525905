import React, { useContext, useState } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import downloadicon from "../image/dashboard/downloadicon.png";
import CategoryContext from "../../context/CategoryContext/CategoryContext";
import { AddresellBook } from "../../service/services";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.5rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #CACACA",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  formanduploadcontainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 0",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  formcontainer: {
    width: "62%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  forminner: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    "& .MuiTypography-subtitle1": {
      fontSize: "0.875rem",
      fontWeight: "600",
      fontFamily: "Jost",
      color: "#1E80F4",
    },
  },
  inputcontainer: {
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display",
      fontSize: "0.875rem",
      fontWeight: "600",
    },
    "& .MuiTypography-caption": {
      fontFamily: "Jost",
      fontSize: "0.625remrem",
      fontWeight: "400",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #000",
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .MuiTypography-colorTextSecondary": {
      fontWeight: "600",
      color: "#16283F",
    },
  },
  w58: {
    width: "58%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  d_blockmobile: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  w38: {
    width: "38%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  btncontainer: {
    width: "25%",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      marginBottom: "1.5rem",
      marginTop: "0.5rem",
    },
  },
  bluecustombtn: {
    width: "100%",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "0.6rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "Jost",
      fontWeight: "500",
    },
  },
  bluecustombtn2: {
    background: "#2E5D96",
    width: "100%",
    color: "white",
    padding: "0.6rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "Jost",
      fontWeight: "500",
    },
  },

  side2: {
    width: "33%",
    margin: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1rem 0 0 0",
    },
  },
  div2first: {
    display: "flex",

    gap: "1rem",
  },
  div2second: {
    border: "1px solid #DADADA",
    borderRadius: "0.6rem",
    textAlign: "center",
    padding: "1.5rem 0",
    background: "#FFFBF0",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "15%",
      },
    },
  },
  head1: {
    color: "#2E2E2E",
    fontFamily: " Playfair Display",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  head2: {
    marginTop: "0.5rem",
  },
  div2three: {
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  head3: {
    color: "#353535",
    fontFamily: "Jost",
    fontSize: "16px",
    fontWeight: 500,
  },
  head4_inner1: {
    color: "#353535",
    fontFamily: "Jost",
    fontSize: "0.75rem",
    fontWeight: 600,
    textDecoration: "underline",
  },
  head4_inner2: {
    color: "#6A6A6A",
    fontFamily: "Jost",
    fontSize: "0.75rem",
    fontWeight: 400,
    textDecoration: "none",
  },
  uploadedimagecontainer: {
    marginTop: "1rem",
    position: "relative",
  },
  canclebtn: {
    position: "absolute",
    top: "-3px",
    right: "-7px",
    color: "#FFF",
  },
}));

function AddResellBooks({ setOnViewClicked }) {
  const allCategories = [];

  const data1 = useContext(CategoryContext);
  const megaMenu = data1?.megaMenu || [];
  megaMenu.forEach((menu) => {
    if (menu.categories) {
      menu.categories.forEach((category) => {
        const categoryData = {
          id: category.id,
          category_name: category.category_name,
          subCategories: [],
        };

        if (category.subCategories) {
          categoryData.subCategories = category.subCategories.map(
            (subCategory) => ({
              id: subCategory.id,
              sub_category_name: subCategory.sub_category_name,
              exams: subCategory.exams || [],
            })
          );
        }

        allCategories.push(categoryData);
      });
    }
  });
  const subCategories = allCategories.flatMap(
    (category) => category.subCategories
  );

  const [uploadedThumbnail, setUploadedThumbnail] = useState(null); // Store the file object
  const [uploadedThumbnailUrl, setUploadedThumbnailUrl] = useState(""); // Store the URL for displaying the thumbnail

  const [uploadedFrontImg, setUploadedFrontImg] = useState(null); // Store the file object
  const [uploadedFrontImgUrl, setUploadedFrontImgUrl] = useState(""); // Store the URL for displaying the image

  const [uploadedBackImg, setUploadedBackImg] = useState(null); // Store the file object
  const [uploadedBackImgUrl, setUploadedBackImgUrl] = useState(""); // Store the URL for displaying the image

  const [bookName, setBookName] = useState("");
  const [category, setCategory] = useState("");
  const [subcate, setSubCate] = useState("");
  const [examName, setExamName] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [price, setPrice] = useState("");
  const [finalprice, setFinalPrice] = useState(0);
  const [bookcondition, setBookCondition] = useState("");
  const [channel, setChannel] = useState("");
  const [formError,setFormErrors]=useState(false);
  const handleThumbnail = (e) => {
    const image = e.target.files[0];
    setUploadedThumbnail(image);

    const imageUrl = URL.createObjectURL(image);
    setUploadedThumbnailUrl(imageUrl);
  };

  const handleFrontImg = (e) => {
    const image = e.target.files[0];
    setUploadedFrontImg(image);

    const imageUrl = URL.createObjectURL(image);
    setUploadedFrontImgUrl(imageUrl);
  };

  const handleBackImg = (e) => {
    const image = e.target.files[0];
    setUploadedBackImg(image);
    const imageUrl = URL.createObjectURL(image);
    setUploadedBackImgUrl(imageUrl);
  };

  const classes = useStyles();
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const handleSubmit = async () => {
    try {
      const errors = {};
      if (!bookName) errors.bookName = true;
      if (!authorName) errors.authorName = true;
      if (!price) errors.price = true;
      if (!bookcondition) errors.bookcondition = true;
      if (!category) errors.category = true;
      if (!subcate) errors.subcate = true;
      if (!examName) errors.examName = true;

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      if(!bookName){
        toast.error("Please type your book name");
        return;
      }
      if(!authorName){
        toast.error("Please type your author name");
        return;
      }
      if(!price){
        toast.error("Please type your book price");
        return;
      }
      if(!bookcondition){
        toast.error("Please choose your book condition");
        return;
      }
      if(!category){
        toast.error("Please choose your book category");
        return;
      }
      if(!subcate){
        toast.error("Please choose your book subcategory");
        return;
      }
      if(!examName){
        toast.error("Please choose examname of book");
        return;
      }
      const token = localStorage.getItem("token");
      const formData = new FormData();
      const user = {
        book_name :bookName,
        author_name: authorName,
        purchase_cost:price,
        selling_cost:finalprice,
        book_condition:bookcondition,
        fulfillment_channel:channel,
        category_id:category,
        sub_category_id:subcate,
        exam_id:examName,
      };
      console.log(user, "new");
      formData.append("resell", JSON.stringify(user));
      formData.append("files", [uploadedThumbnail,uploadedFrontImg,uploadedBackImg]);
      console.log(formData,"data")
      const response = await AddresellBook(formData, token);
      console.log("API Response:", response);
      toast.success("Add book successfully");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }; 
  const handlePriceChange = (e) => {
    const enteredPrice = e.target.value;
    setPrice(enteredPrice);

    const calculatedFinalPrice = parseFloat(enteredPrice) * 1.2;
    setFinalPrice(calculatedFinalPrice.toFixed(2)); 
  };
 
 
  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <IconButton
            onClick={() => {
              setOnViewClicked("");
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h3">Book Details</Typography>
        </div>
      </div>
      <div className={classes.formanduploadcontainer}>
        <div className={classes.formcontainer}>
          <div className={classes.forminner}>
            <div className={`${classes.inputcontainer} ${classes.w58}`}>
              <Typography variant="h6">
                Book Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Advance Reasoning"
                name="bookname"
                onChange={(e) => setBookName(e.target.value)}
                value={bookName}
                error={!bookName && formError.bookName}
                variant="outlined"
              />
              {!bookName && formError.bookName && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please type your bookname</small>
              </span>
            )}
             
            </div>
            <div className={`${classes.inputcontainer} ${classes.w38}`}>
              <Typography variant="h6">
                Exam Category <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                labelId="exam-label"
                id="exam"
                value={category}
                name="examcategory"
                onChange={(e) => setCategory(e.target.value)}
                displayEmpty
                className={classes.selectEmpty}
                MenuProps={menuProps}
                
                error={!category && formError.category}
                variant="outlined"
                placeholder="Select Here"
              >
                <MenuItem disabled value="">
                  <em className={classes.defaultselect}>Select Here</em>
                </MenuItem>
                {allCategories?.map((data, index) => {
                  return (
                    <MenuItem value={data.id} key={index}>
                      {data?.category_name}
                    </MenuItem>
                  );
                })}
              </Select>
              {!category && formError.category && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please choose your category</small>
              </span>
            )}
              
            </div>
          </div>
          <div className={classes.forminner}>
            <div className={`${classes.inputcontainer} ${classes.w58}`}>
              <Typography variant="h6">
                Sub Category<span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                labelId="exam-label"
                id="exam"
                value={subcate}
                name="subcategory"
                onChange={(e) => setSubCate(e.target.value)}
                displayEmpty
                className={classes.selectEmpty}
                MenuProps={menuProps}
                error={!subcate && formError.subcate}
              variant="outlined"
              >
                <MenuItem disabled value="">
                  <em className={classes.defaultselect}>
                    Select Here <span style={{ color: "red" }}>*</span>
                  </em>
                </MenuItem>
                {subCategories.map((data, index) => (
                  <MenuItem value={data.id} key={index}>
                    {data.sub_category_name}
                  </MenuItem>
                ))}
              </Select>
              {!subcate && formError.subcate && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please choose book subcategory</small>
              </span>
            )}
            </div>
            <div className={`${classes.inputcontainer} ${classes.w38}`}>
              <Typography variant="h6">
                Exam Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                labelId="exam-label"
                id="exam"
                value={examName}
                name="examname"
                onChange={(e) => setExamName(e.target.value)}
                displayEmpty
                className={classes.selectEmpty}
                MenuProps={menuProps}
                error={!examName && formError.examName}
              variant="outlined"
                placeholder=""
              >
                <MenuItem disabled value="">
                  <em className={classes.defaultselect}>
                    Select Here <span style={{ color: "red" }}>*</span>
                  </em>
                </MenuItem>
                {subCategories
                  .find((data) => data.id === subcate)
                  ?.exams.flatMap((exam) => (
                    <MenuItem key={exam.id} value={exam.id}>
                      {exam.exam_name}
                    </MenuItem>
                  ))}
              </Select>
              {!examName && formError.examName && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please choose book exam name</small>
              </span>
            )}
             
            </div>
          </div>
          <div className={classes.forminner}>
            <div className={`${classes.inputcontainer} ${classes.w58}`}>
              <Typography variant="h6">Author Name</Typography>
              <TextField
                id="outlined-basic"
                placeholder="B-Krishna Reddy"
                variant="outlined"
                name="authorname"
                onChange={(e) => setAuthorName(e.target.value)}
                value={authorName}
              />
            </div>
          </div>
          <div className={`${classes.forminner} ${classes.d_blockmobile}`}>
            <div className={`${classes.inputcontainer} ${classes.w58}`}>
              <Typography variant="h6">
                Your Price <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="2500"
                error={!price && formError.price}
              variant="outlined"
                name="yourprice"
                value={price}
                onChange={handlePriceChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">&#8377;</InputAdornment>
                  ),
                }}
              />
              {!price && formError.price && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please type your price</small>
              </span>
            )}
            </div>
            <Typography variant="subtitle1">
              Add ParikshaDO Commision - 20%
            </Typography>
          </div>
          <div className={classes.forminner}>
            <div className={`${classes.inputcontainer} ${classes.w58}`}>
              <Typography variant="h6">
                Final Price<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="3000"
                error={!finalprice && formError.finalprice}
                variant="outlined"
                name="finalprice"
                value={price.length===0?0:finalprice}
                onChange={(e) => setFinalPrice(e.target.value)}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">&#8377;</InputAdornment>
                  ),
                }}
              />
              
            </div>
          </div>
          <div className={classes.forminner}>
            <div className={`${classes.inputcontainer} ${classes.w58}`}>
              <Typography variant="h6">Book Condition</Typography>
              <Select
                labelId="exam-label"
                id="exam"
                value={bookcondition}
                name="bookcondition"
                onChange={(e) => setBookCondition(e.target.value)}
                displayEmpty
                className={classes.selectEmpty}
                MenuProps={menuProps}
                error={!bookcondition && formError.bookcondition}
                variant="outlined"
                placeholder=""
              >
                <MenuItem disabled value="">
                  <em className={classes.defaultselect}>Select Here</em>
                </MenuItem>
                <MenuItem value="bad">Bad</MenuItem>
                <MenuItem value="good">Good</MenuItem>
                <MenuItem value="very good">Very Good</MenuItem>
              </Select>
              {!bookcondition && formError.bookcondition && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please choose bookcondition</small>
              </span>
            )}
            </div>
          </div>
          <div className={classes.forminner}>
            <div className={`${classes.inputcontainer} ${classes.w58}`}>
              <Typography variant="h6">Fulfillment Channel</Typography>
              <Select
                labelId="exam-label"
                id="exam"
                value={channel}
                name="fulfillmentchannel"
                onChange={(e) => setChannel(e.target.value)}
                displayEmpty
                className={classes.selectEmpty}
                MenuProps={menuProps}
                error={!channel && formError.channel}
                variant="outlined"
                placeholder=""
              >
                <MenuItem disabled value="">
                  <em className={classes.defaultselect}>Select Here</em>
                </MenuItem>
                <MenuItem value=" I will Ship This Item myself (merchant fulfillment)">
                  I will Ship This Item myself (merchant fulfillment)
                </MenuItem>
              </Select>
             
              <Typography variant="caption">
                You have choosen to have fulfill orders for this item.{" "}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.side2}>
          <div>
            {!uploadedThumbnailUrl ? (
              <label role="button" datacy="uploadImage">
                <div className={classes.div2second}>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={handleThumbnail}
                  />

                  <img src={downloadicon} alt="img" />
                  <div className={classes.div2three}>
                    <Typography>
                      <span className={classes.head4_inner1}>
                        Click to Upload Thumbnail
                      </span>{" "}
                      <span className={classes.head4_inner2}>
                        or drag and drop
                      </span>
                    </Typography>
                    <Typography className={classes.head4_inner2}>
                      {" "}
                      Maximum file size is 150 kb
                    </Typography>
                  </div>
                </div>
              </label>
            ) : (
              <div className={classes.uploadedimagecontainer}>
                <IconButton
                  onClick={() => {
                    setUploadedThumbnailUrl(null);
                  }}
                  className={classes.canclebtn}
                >
                  <CancelIcon />
                </IconButton>
                <img src={uploadedThumbnailUrl} alt="Uploaded"  />
              </div>
            )}
            {!uploadedFrontImgUrl ? (
              <label>
                <div className={classes.div2second}>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={handleFrontImg}
                  />

                  <img src={downloadicon} alt="img" />
                  <div className={classes.div2three}>
                    <Typography>
                      <span className={classes.head4_inner1}>
                        Click to Upload Front Image
                      </span>{" "}
                      <span className={classes.head4_inner2}>
                        or drag and drop
                      </span>
                    </Typography>
                    <Typography className={classes.head4_inner2}>
                      {" "}
                      Maximum file size is 150 kb
                    </Typography>
                  </div>
                </div>
              </label>
            ) : (
              <div className={classes.uploadedimagecontainer}>
                <IconButton
                  onClick={() => {
                    setUploadedFrontImgUrl(null);
                  }}
                  className={classes.canclebtn}
                >
                  <CancelIcon />
                </IconButton>
                <img src={uploadedFrontImgUrl} alt="Uploaded"  />
              </div>
            )}
            {!uploadedBackImgUrl ? (
              <label>
                <div className={classes.div2second}>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={handleBackImg}
                  />

                  <img src={downloadicon} alt="img" />
                  <div className={classes.div2three}>
                    <Typography>
                      <span className={classes.head4_inner1}>
                        Click to Upload Back Image
                      </span>{" "}
                      <span className={classes.head4_inner2}>
                        or drag and drop
                      </span>
                    </Typography>
                    <Typography className={classes.head4_inner2}>
                      {" "}
                      Maximum file size is 150 kb
                    </Typography>
                  </div>
                </div>
              </label>
            ) : (
              <div className={classes.uploadedimagecontainer}>
                <IconButton
                  onClick={() => {
                    setUploadedBackImgUrl(null);
                  }}
                  className={classes.canclebtn}
                >
                  <CancelIcon />
                </IconButton>
                <img src={uploadedBackImgUrl} alt="Uploaded"  />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.btncontainer}>
        <Button className={classes.bluecustombtn} onClick={handleSubmit}>
          save details
        </Button>
      </div>
    </div>
  );
}

export default AddResellBooks;
