import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardMedia,
  Box,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { Link, useNavigate } from "react-router-dom";
import testserieslogo from "../image/testseriesvector/testserieslogo.svg";
import booklogo from "../image/testseriesvector/booklogo.svg";
import withStyles from "@material-ui/core/styles/withStyles";
import Ebook from "../image/dashboard/bookz.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "35%",
    height: "15rem",
    fill: "var(--white-100, #FFF)",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.10)",
    marginBottom: "10rem",
    borderRadius: "4px",
    "& .MuiTypography-h5": {
      color: "#262626",
      fontFamily: "Jost",
      fontSize: "18px",
      marginTop:"2rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      marginLeft:'1rem',
    },
    "& .MuiTypography-h6": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "23px",
      fontStyle: "normal",
      fontWeight: 600,
      marginTop:'3rem',
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      marginLeft:'1rem',
    },
    "& .MuiTypography-h4": {
        color: "#262626",
        fontFamily: "Jost",
        fontSize: "8px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px" /* 133.333% */,
        textTransform: "capitalize",
        justifyContent:'center',
        textAlign:'center',
        
      
      },

      "& .MuiTypography-h3": {
        color: "var(--white-100, #FFF)",
        fontFamily: "Jost",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "33px" /* 133.333% */,
        textTransform: "capitalize",
        marginTop:'3rem',
        justifyContent:'center',
        textAlign:'center',
        marginBottom:'.5rem',
      },
  },

  bookpluscontent: {
    display: "flex",
    width:'70%',
  },
  sectionBoth: {
    display: "flex",
  },
  yellowbutton: {
    width: "var(--80, 80px)",
    height: "19px",
    // padding: "6px var(--24, 11px) 5px var(--24, 12px)",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"2rem",
    borderradius: "8px",
    background: "linear-gradient(90deg, #FFF0E1 0%, #FFE7AB 100%)",
  },
  viewbutton:{
    width: 'var(--80, 80px)',
    height: '30px',
    borderRadius: '6px',
    background: '#243B55',
    marginBottom:'.5rem',
  },
  bookbox:{
    width:"20%",
    height:'6rem',
    marginTop:'1.4rem',
    border: '5px solid var(--white-100, #FFF)',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
  }
}));

function MyPurchaseMobileViewData({ CardData, setOnViewClicked }) {
  const classes = useStyles();

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#FFEFCB",
      width: "84%",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#00A019",
    },
  }))(LinearProgress);

  return (
    <>
      <div className={classes.main}>
        <div className={classes.sectionBoth}>
          <div className={classes.bookpluscontent}>
            
                <div className={classes.bookbox}>
              <img src={Ebook} alt="image"/></div>
              <div className={classes.content}>
                <Typography variant="h5">
                  Quantitative Aptitude for SSC CGL 2023
                </Typography>
                <Typography variant="h6">₹150.00</Typography>
              </div>
            
          </div>
          <div className={classes.buttonsection}>
            <div className={classes.yellowbutton}>
               <Typography variant="h4"> hgfhdfhf</Typography>
                </div>
            <div className={classes.viewbutton}>  <Typography variant="h3"> VIEW</Typography></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MyPurchaseMobileViewData;
