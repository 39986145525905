import React from "react";
import {
  // Box,
  // Button,
  IconButton,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import ProductImage from "../image/mentorvector/mentor1.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacultyBioData from "./facultybiodata";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2.5rem 2rem",
    margin: "40px 2rem",
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      //background: "none",
      display: "block",
      padding: "1.5rem 1rem",
      margin: "1rem",
      background: "#FFFFFF",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
      borderRadius: "10px",
    },

    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "#FFFFFF",
    },
    "& .MuiToggleButton-root": {
      color: "#777777",
      background: "#FFFFFF",
      borderRadius: "40px",
      padding: "0.5rem",
      position: "absolute",
      right: "16px",
      top: "16px",
    },
    "& .MuiIconButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
    },
  },
  imagegallery: {
    width: "25%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
    },
  },
  currentimagecontainer: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 0",
      justifyContent: "space-evenly",
    },
    padding: "1.5rem 5rem",
    background: "#FFE8B5",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  imagewrapper: {
    [theme.breakpoints.down("xs")]: {},
  },
  image: {
    width: "290px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  productdetails: {
    width: "43%",
    marginLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
      width: "100%",
      marginTop: "2rem",
    },
    "& .MuiTypography-h3": {
      fontFamily: " Playfair Display",
      fontSize: "2rem",
      color: "rgba(37, 37, 37, 1)",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiTypography-h4": {
      fontFamily: " Playfair Display",
      fontSize: "1.25rem",
      fontWeight: "600",
      marginTop: "2rem",
      color: "rgba(37, 37, 37, 1)",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "22px",
      padding: "40px 0px",
      color: "rgba(37, 37, 37, 1)",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },

  buttonscontainer: {
    width: "80%",
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "rgba(48, 48, 48, 1)",
    padding: "13px 53px",
    fontSize: "1rem",
    fontWeight: "400",
    borderRadius: "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      width: "100%",
      padding: "12px 22px",
      //marginRight: "0px",
      fontSize: "0.875rem",
    },
  },
  divider: {
    border: "1.5 solid rgba(217, 217, 217, 1)",
    margin: "-2rem 4rem -2rem 2.5rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  socialMediaIcons: {
    justifyContent: "flex-end",
    marginTop: "-42px",
    display: "flex",
    alignItems: "center",
  },
  socialIconButton: {
    padding: "8px",
    background: "rgba(35, 57, 82, 1)",
    borderRadius: "50%",
    marginRight: "8px",
  },
  icon: {
    fontSize: "2rem",
    color: "rgba(255, 255, 255, 1)",
  },
  educationItem: {
    marginTop: "2rem",
  },
  educationUniversity: {
    fontFamily: "Quicksand",
    color: "rgba(37, 37, 37, 1)",
    fontWeight: "600",
    fontSize: "22px",
  },
  educationDegree: {
    fontFamily: "Quicksand",
    fontWeight: "600",
    color: "rgba(140, 140, 140, 1)",
    fontSize: "18px",
    lineHeight: "40px",
  },
  headingText: {
    fontFamily: " Playfair Display",
    fontSize: "26px",
    color: "rgba(37, 37, 37, 1)",
    fontWeight: "600",
  },
  followMeText: {},
}));

function FacultyBio() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imagegallery}>
        <div className={classes.currentimagecontainer}>
          <div className={classes.imagewrapper}>
            <img className={classes.image} src={ProductImage} alt="img" />
          </div>
        </div>
      </div>

      <div className={classes.productdetails}>
        <div className={classes.title}>
          <Typography variant="h3">
            Coaching : Force Defence Academy (Indore)
          </Typography>
          <Typography variant="h4">About me</Typography>
          <Typography variant="h5">
            Certain words are printed in bold to help you locate them while
            answering some of these.Boredom indicates a mind at peace with
            itself; a blank canvas that can spark creativity and contemplation.
            It is likely to lead to considered action or engaging activity that
            the subject finds meaningful ( as opposed to the meaningless
            activity used to “fill” empty time).Conversely, the intense
            restlessness mistaken for boredom in the social media age indicates
            a deficiency of mental wellness.
          </Typography>
        </div>

        <div className={classes.buttonscontainer}>
          <IconButton variant="contained" className={classes.bluecustombtn}>
            Send message
          </IconButton>
        </div>

        <div className={classes.socialMediaIcons}>
          {/* <Typography variant="h6" className={classes.followMeText}>
          Follow Me
        </Typography> */}
          <IconButton className={classes.socialIconButton}>
            <FacebookIcon className={classes.icon} />
          </IconButton>
          <IconButton className={classes.socialIconButton}>
            <TwitterIcon className={classes.icon} />
          </IconButton>
          <IconButton className={classes.socialIconButton}>
            <InstagramIcon className={classes.icon} />
          </IconButton>
        </div>
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={classes.facultyInfo}>
        <Typography variant="h5" className={classes.headingText}>
          Educational Background
        </Typography>

        <div>
          {FacultyBioData.map((item, index) => (
            <div key={index} className={classes.educationItem}>
              <Typography variant="h6" className={classes.educationUniversity}>
                {item.university}
              </Typography>
              <Typography variant="body1" className={classes.educationDegree}>
                {item.degree}
              </Typography>
              <Typography variant="body1" className={classes.educationDegree}>
                {item.date}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default FacultyBio;
