import { useEffect, useReducer } from "react";
import Reducer from "./reducer";
import Context from "./index";
import {
  SET_QUIZ_LIST,
  SET_PREVIOUS_YEAR_PAPER_LIST,
  SET_BOOK_LIST,
  SET_EBOOK_LIST,
  SET_NOTES_LIST,
  SET_TEST_SERIES_LIST,
  SET_ONLINE_VIDEO_LIST,
  SET_LIVE_CLASSES_LIST,
  SET_BOOK_DETAILS,
  SET_EBOOK_DETAILS,
  SET_NOTE_DETAILS,
  SET_TEST_SERIES_DETAILS,
  SET_ONLINE_VIDEO_DETAILS,
  SET_LIVE_CLASSES_DETAILS
} from "./type";
import {
  getPreviousYearPaperListAPI,
  getQuizListAPI,
  getEbookListAPI,
  getBookListAPI,
  getTestSeriesListAPI,
  getOnlineVideoListAPI,
  getLiveClassesListAPI,
  getNotesListAPI,
  getBookDetailsAPI,
  getEBookDetailsAPI,
  getNoteDetailsAPI,
  getTestSeriesDetailsAPI,
  getOnlineVideoDetailsAPI,
  getLiveClassesDetailsAPI,
} from "../../../service/services";

const State = (props) => {
  let initialState = {
    quizList: null,
    previousYearPapersList: null,
    ebookList: null,
    bookList: null,
    testseriesList: null,
    notesList: null,
    onlinevideoList: null,
    liveclassesList: null,
    eBookDetail: null,
    bookDetail: null,
    noteDetail: null,
    testseriesDetail: null,
    onlinevideoDetail: null,
    liveclassesDetail: null,
  };
  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    getQuizList();
    getPreviousYearPapersList();
    getEbookList();
    getBookList();
    getTestSeriesList();
    getNotesList();
    getOnlineVideoList();
    getLiveClassesList();
  }, []);
  const getQuizList = async () => {
    let resp = await getQuizListAPI();
    if (resp?.status === 200)
      dispatch({
        type: SET_QUIZ_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };

  const getPreviousYearPapersList = async () => {
    let resp = await getPreviousYearPaperListAPI();
    if (resp?.status === 200)
      dispatch({
        type: SET_PREVIOUS_YEAR_PAPER_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };

  const getEbookList = async () => {
    let resp = await getEbookListAPI();
    if (resp?.status === 200)
      dispatch({
        type: SET_EBOOK_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };

  const getBookList = async () => {
    let resp = await getBookListAPI();
    if (resp?.status === 200)
      dispatch({
        type: SET_BOOK_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };
  const getTestSeriesList = async () => {
    let resp = await getTestSeriesListAPI();
    if (resp?.status === 200)
      dispatch({
        type: SET_TEST_SERIES_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };

  const getOnlineVideoList = async () => {
    let resp = await getOnlineVideoListAPI();
    if (resp?.status === 200)
      dispatch({
        type: SET_ONLINE_VIDEO_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };

  const getLiveClassesList = async () => {
    let resp = await getLiveClassesListAPI();
    if (resp?.status === 200)
      dispatch({
        type:   SET_LIVE_CLASSES_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };

  const getNotesList = async () => {
    let resp = await getNotesListAPI();
    if (resp?.status === 200)
      dispatch({
        type: SET_NOTES_LIST,
        payload: [{ categories: resp?.data?.categories }],
      });
  };

  const getEBookDetails = async (id) => {
    let resp = await getEBookDetailsAPI(id);
    if (resp?.status === 200)
      dispatch({
        type: SET_EBOOK_DETAILS,
        payload: resp?.data?.ebook,
      });
  };

  const getBookDetails = async (id) => {
    let resp = await getBookDetailsAPI(id);
    if (resp?.status === 200)
      dispatch({
        type: SET_BOOK_DETAILS,
        payload: resp?.data?.book,
      });
  };

  const getNoteDetails = async (id) => {
    let resp = await getNoteDetailsAPI(id);
    if (resp?.status === 200)
      dispatch({
        type: SET_NOTE_DETAILS,
        payload: resp?.data?.note,
      });
  };

  const getTestSeriesDetails = async (id) => {
    let resp = await getTestSeriesDetailsAPI(id);
    if (resp?.status === 200)
      dispatch({
        type: SET_TEST_SERIES_DETAILS,
        payload: resp?.data?.test_series_bundle,
      });
  };

  const getOnlineVideoDetails = async (id) => {
    let resp = await getOnlineVideoDetailsAPI(id);
    if (resp?.status === 200)
      dispatch({
        type: SET_ONLINE_VIDEO_DETAILS,
        payload: resp?.data?.online_video,
      });
  };

  const getLiveClassesDetails = async (id) => {
    let resp = await getLiveClassesDetailsAPI(id);
    if (resp?.status === 200)
      dispatch({
        type: SET_LIVE_CLASSES_DETAILS,
        payload: resp?.data?.live_class
        ,
      });
  };

  return (
    <Context.Provider
      value={{
        quizList: state.quizList,
        previousYearPapersList: state.previousYearPapersList,
        ebookList: state.ebookList,
        bookList: state.bookList,
        testseriesList: state.testseriesList,
        onlinevideoList: state.onlinevideoList,
        liveclassesList: state.liveclassesList,
        notesList: state.notesList,
        bookDetail: state.bookDetail,
        noteDetail: state.noteDetail,
        eBookDetail: state.eBookDetail,
        testseriesDetail: state.testseriesDetail,
        onlinevideoDetail: state.onlinevideoDetail,
        liveclassesDetail: state.liveclassesDetail,
        getQuizList,
        getPreviousYearPapersList,
        getEbookList,
        getBookList,
        getTestSeriesList,
        getOnlineVideoList,
        getLiveClassesList,
        getNotesList,
        getBookDetails,
        getEBookDetails,
        getNoteDetails,
        getTestSeriesDetails,
        getOnlineVideoDetails,
        getLiveClassesDetails
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default State;
