import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, MenuItem, Select, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  secHead: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "baseline",
    },
  },
  secHeadmain: {},
  secheading: {
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "1.5rem",
    margin: "0",
    color: "#252525",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      margin: "0",
      color: "#252525",
      fontWeight: "400",
    },
  },
  secheadingmain: {
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "3rem",
    color: "#252525",
    fontWeight: "700",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      textAlign: "left",
      padding: "0px 0rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  secheadhr: {
    width: "175px",
    margin: "15px 0px 0px 0px",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
    [theme.breakpoints.down("xs")]: {
      width: "130px",
      marginTop: "0.6rem",
      marginBottom: "0rem",
    },
  },
  InfoButton: {
    background: " linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    color: "#fff",
    width: "150px",
    height: "40px",
    fontWeight: "bold",
    fontSize: "1rem",
    padding: "0.8rem 1.2rem",
    borderRadius: "5px",
    transition: "background 0.3s ease",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
  },
  btnandfilter: {
    display: "flex",
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 0px 10.5px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
    },
  },
  languageButton: {
    textTransform: "none",
      marginRight: "1rem",
    width: "60%",
    fontSize: "15px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 3px 11px 0px rgba(0, 0, 0, 0.17) inset",
    borderRadius: "8px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0rem",
      marginTop: "0.5rem",
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      background: "transparent",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      textTransform: "capitalize",
    },
    "& .MuiMenu-list": {
      textTransform: "capitalize",
    },
  },
}));
function QuizHeading(props) {
  const {
    Heading,
    media,
    selectedLanguage,
    handleLanguageChange,
 quizQuestionObj ,
  } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  return (
    <>
      {Heading.map((data) => (
        <div className={classes.secHead}>
          <div className={classes.secHeadmain}>
            {media ? (
              <>
                <h1 className={classes.secheadingmain}>{data.headingmain}</h1>
                <h2 className={classes.secheading}>{data.headingsmall}</h2>
              </>
            ) : (
              <>
                <h1 className={classes.secheadingmain}>{data.headingmain}</h1>
                <h2 className={classes.secheading}>{data.headingsmall}</h2>
              </>
            )}
            <hr className={classes.secheadhr} />
          </div>
          <div className={classes.btnandfilter}>
            <div className={classes.languageButton}>
              <Select
                labelId="category-label"
                id="state"
                displayEmpty
                MenuProps={menuProps}
                variant="outlined"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
              {quizQuestionObj?.language?.map((data, index) => (
                  <MenuItem key={index} value={data?.language}>
                    {data?.language}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button
              onClick={() => navigate("/testseries")}
              className={classes.InfoButton}
            >
              BACK
            </Button>
          </div>
        </div>
      ))}
    </>
  );
}
export default QuizHeading;
