import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import OnlineOptimizingImage from "../image/rpsvector/rps.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    marginTop:"4rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  onlineeducationcontent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 1rem",
      textAlign: "center",
    },
  },
  onlineeducationimgcontainer: {
    width: "56%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "2rem 1rem",
    },
  },
  onlineeducationcontentinner: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
  },
  InfoButton: {
    position: "flex",
    bottom: "2.5rem",
    left: "0.1rem",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "#fff",
    border: "3px",
    fontWeight: "bold",
    fontSize: "1rem",
    padding: "0.8rem 1.2rem",
    borderRadius: "5px",
    transition: "background 0.3s ease",
    display: "flex",
    marginTop: "75px",
    alignItems: "center",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "58px",
    },
  },
  arrowIcon: {
    marginLeft: "0.5rem",
  },
  // The following styles should be at the top level of useStyles
  MuiTypographybody2: {
    marginTop: "0.5rem",
    fontFamily: "'Jost', sans-serif",
    color: "#7E7E7E",
    fontSize: "1rem",
    fontWeight: "400",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
  MuiTypographybody1: {
    fontFamily: "'Playfair Display'",
    color: "rgba(34, 56, 82, 1)",
    fontSize: "36px",
    lineHeight: "47.99px",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
      color: " #223852",
    },
  },
  MuiTypographybody3: {
    fontFamily: "'Playfair Display'",
    color: "rgba(34, 56, 82, 1)",
    fontSize: "25px",
    lineHeight: "33.32px",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      fontFamily: "Playfair Display",
      fontWeight: "500",
      lineHeight: "23px",
      color: "rgba(34, 56, 82, 1)",
    },
  },
}));

function Optimizingresultsection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Simplified Result Processing for Better Decision-Making and Analysis",
      headingmain: "Optimizing Result Processing",
    },
  ];
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.MuiTypographybody1}
              >
                Streamlined Result Processing
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.MuiTypographybody3}
              >
                Enhancing Decision-Making & Analysis
              </Typography>
              <Typography
                variant="MuiTypographybody2"
                className={classes.MuiTypographybody2}
                gutterBottom
              >
                The Result Processing Module empowers administrators with a
                centralized interface for efficient management. Customize
                organization-specific norms for Pass and Grace with worry. eMLi
                comprehensively manages Unfair Means Cases. Post-exam,
                effortlessly access the list of top-performing students by
                subject or class.
              </Typography>
              <Button className={classes.InfoButton}>
                MORE INFO
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Button>
            </div>
          </div>

          <div className={classes.onlineeducationimgcontainer}>
            <img src={OnlineOptimizingImage} alt="img" width="70%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Optimizingresultsection;
