import UserLogo from "../../../image/singlepagedescriptionvector/useravtar.png";

const UserReviewData = [
    {
        id: 1,
        userlogo: UserLogo,
        name: "Jordan Peterson",
        rating: 4.5,
        comment: "Great Product",
        verified: true,
    },
    {
        id: 2,
        userlogo: UserLogo,
        name: "Jordan Peterson",
        rating: 4.5,
        comment: "Great Product",
        verified: false,
    },
    {
        id: 3,
        userlogo: UserLogo,
        name: "Jordan Peterson",
        rating: 4.5,
        comment: "Great Product",
        verified: true,
    },
];

export default UserReviewData;