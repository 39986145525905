import { Divider, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../../../styles";
import BillingAddress from "./BillingAddress";

function ProductDescription({ data }) {
  const classes = useStyles();
  return (
    <div
      className={`${classes.dflex} ${classes.justifyspacebetween} ${classes.dblockmobile}`}
    >
      {/* <div
        className={`${classes.mt2} ${classes.bordergrey} ${classes.w100mobile} ${classes.p1} ${classes.w69}`}
      >
        <Typography
          className={`${classes.fontsize4} ${classes.fontsize7mobile} ${classes.fw700} ${classes.fontfamilyPlayfairDisplay}`}
        >
          Products Descriptions
        </Typography>
        <Typography
          component="p"
          className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w90} ${classes.w100mobile} ${classes.mt1}`}
        >
          {data?.product_data?.description}
        </Typography>
      </div> */}
      <BillingAddress data={data} />
      <div
        className={`${classes.mt2} ${classes.bordergrey} ${classes.borderradius6px} ${classes.w29} ${classes.w100mobile}
        ${classes.dnonemobile}`}
      >
        <div
          className={`${classes.dflex} ${classes.justifyspaceevenly} ${classes.py1}`}
        >
          <div>
            <Typography
              className={`${classes.fontsize5} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
            >
              Order ID:
            </Typography>
            <Typography
              component="p"
              className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              #{data?.order_data?.order_id}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div>
            <Typography
              className={`${classes.fontsize5} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
            >
              Payment Method:
            </Typography>
            <Typography
              component="p"
              className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              {data?.order_data?.payment_mode}
            </Typography>
          </div>
        </div>
        <Divider orientation="horizontal" />
        <div className={`${classes.p1}`}>
          <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
            <Typography
              className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
            >
              Subtotal:
            </Typography>
            <Typography
              component="p"
              className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              ₹{data?.item_price}
            </Typography>
          </div>
          <Divider className={`${classes.my05}`} orientation="horizontal" />
          <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
            <Typography
              className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
            >
              Discount:
            </Typography>
            <Typography
              component="p"
              className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              -₹{data?.total_discount}
            </Typography>
          </div>
          <Divider className={`${classes.my05}`} orientation="horizontal" />
          <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
            <Typography
              className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
            >
              Shipping:
            </Typography>
            <Typography
              component="p"
              className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              -₹{data?.order_data?.shipping_cost}
            </Typography>
          </div>
          <Divider className={`${classes.my05}`} orientation="horizontal" />

          <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
            <Typography
              className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              Total Amount
            </Typography>
            <Typography
              className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              ₹{data?.total}
            </Typography>
          </div>
          <Divider className={`${classes.my05}`} orientation="horizontal" />
          <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
            <Typography
              className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
            >
              GST:
            </Typography>
            <Typography
              component="p"
              className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              ₹{data?.gst}
            </Typography>
          </div>
          <Divider className={`${classes.my05}`} orientation="horizontal" />
          <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
            <Typography
              className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost}`}
            >
              Total payment by customer
            </Typography>
            <Typography
              component="p"
              className={`${classes.fontsize3} ${classes.fw600} ${classes.fontfamiljost} ${classes.fcolorgreen}`}
            >
              ₹{data?.billing_amount}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDescription;
