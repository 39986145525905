import AdvantageImage1 from "../image/seller/advantage1.png";
import AdvantageImage2 from "../image/seller/advantage2.png";
import AdvantageImage3 from "../image/seller/advantage3.png";
import AdvantageImage4 from "../image/seller/advantage4.png";

const AdvantageSectionData = [
  {
    id: 1,
    iconName: AdvantageImage1,
    heading: "New customers",
    subheading: "ParikshaDo help you reach millions of potential customers. ",
  },
  {
    id: 2,
    iconName: AdvantageImage2,
    heading: "Sell Globally",
    subheading: "Sell around the world, connect with customers anywhere. ",
  },
  {
    id: 3,
    iconName: AdvantageImage3,
    heading: "Free Tools",
    subheading: "We provide a wide range of tools to help you sell. ",
  },
  {
    id: 4,
    iconName: AdvantageImage4,
    heading: "Seller Support",
    subheading:
      "Our customers support team is dedicated to making selling easy. ",
  },
];
export default AdvantageSectionData ;
