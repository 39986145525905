import React from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as SidebarHeadIcon } from "../image/quizzesvector/sidebarheadicon.svg";
import { ReactComponent as Arrow } from "../image/quizzesvector/arrow.svg";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import OtherLinksData from "./otherlinksdata";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  quiklinkscontainer: {
    width: "100%",
    height: "335px",
    marginTop: "5rem",
    marginBottom:"6rem"
  },
  quiklinkshead: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    padding: "0.4rem 1rem",
    borderRadius: "0.3rem",
    "& span": {
      display: "inline-block",
      marginTop: "0.4rem",
    },
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display",
      color: "#FFFFFF",
      fontSize: "24px",
      marginLeft: "0.5rem",
    },
  },
  quiklinkslist: {
    background: "#fff",
    display: "inline-block",
    width: "inherit",
    height: "inherit",
    overflow: "auto",
    borderRadius: "0.3rem",
    padding: "1rem 0",
    // lineHeight: "50px",
    "& .MuiListItemIcon-root": {
      // paddingLeft: "2.5rem",
      minWidth: "0",
    },
    "& .MuiTypography-body1": {
      textTransform: "capitalize",
    },
    "& .Mui-selected": {
      background: "rgba(255, 255, 255, 1)",
      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
    },
    "& .MuiList-padding": {
      padding: "0.5rem 1rem",
    },
    "& a": {
      color: "rgba(0, 0, 0, 0.87)",
      textDecoration: "none",
    },
  },
}));

function QuikLinks() {
  const location = useLocation();
  const classes = useStyles();

  return (
    <div className={classes.quiklinkscontainer}>
      <div className={classes.quiklinkshead}>
        <div>
          <SidebarHeadIcon />
        </div>
        <Typography variant="h6">Quik Links</Typography>
      </div>
      <div className={classes.quiklinkslist}>
        <List component="nav" aria-label="secondary mailbox folders">
          {OtherLinksData.map(
            (row) =>
              row.name === "Quik Links" &&
              row.links.map((data, index) => {
                return (
                  location.pathname !== `/${data?.link}` && (
                    <Link to={`/${data?.link}`}>
                      <ListItem key={index} button selected={""}>
                        <ListItemText primary={data.name} />
                        <ListItemIcon>
                          <Arrow />
                        </ListItemIcon>
                      </ListItem>
                    </Link>
                  )
                );
              })
          )}
        </List>
      </div>
    </div>
  );
}

export default QuikLinks;
