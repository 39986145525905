import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useContext } from "react";
import { GrFormClose } from "react-icons/gr";
import { green } from "@material-ui/core/colors";
// import { useForm } from "react-hook-form";
// import { authorizeMe, signIn, signInViaOTP } from "../../service/services";
// import appContext from "../../context/AppContext";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CheckoutPageContent from "./DeliveryPopup/CheckoutPageContentPopup";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "70%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  loginform: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  paper: {
    position: "absolute",
    display: "flex",
    top: "50px",
    backgroundColor: "#FFFAEF",
    zIndex: "8",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    height: "calc(100vh - 100px)",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: "center",
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "70px",
      padding: "1rem",
    },
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  facebookicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },
  googlefbiconmain: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      justifyContent: "space-evenly",
      margin: "0.5rem 0",
    },
  },
  googleicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },
  passwordheadcont: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1.5rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "300",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  formfootertext: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    color: "#5E5E5E",
    "& .MuiLink-root": {
      color: "#FF9213",
      cursor: "pointer",
    },
  },
  facebooksvg: {
    width: "18px",
    height: "30px",
  },
  googlesvg: {
    width: "30px",
    height: "30px",
  },
  closebtncontainer: {
    position: "absolute",
    top: "40px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0",
    },
    [theme.breakpoints.down("xs")]: {
      top: "60px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  inputhead: {
    marginTop: "1rem",
  },
  checkboxandforgotpass: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
      fontFamily: "Jost",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
      },
    },
  },
  loginwithotp: {
    color: "#154FE5 !important",
    fontSize: "0.9375rem",
    fontWeight: "400",
    textTransform: "capitalize",
    cursor: "pointer",
  },
}));

function DeliveryAddress(props) {
  const { handleDeliveryAddressclose , bookDetail } = props;
  const classes = useStyles();
console.log('bookDetailris' , bookDetail)
  return (
    <>
      <ToastContainer />

      <div className={classes.container}>
        <div className={classes.closebtncontainer}>
          <Button className={classes.closebtn} onClick={handleDeliveryAddressclose}>
            <GrFormClose />
          </Button>
        </div>
        <div className={classes.paper}>
          <CheckoutPageContent bookDetail={bookDetail} />
        </div>
      </div>
    </>
  );
}
export default DeliveryAddress;
