import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import OnlineEducationSection from "./onlinesducationsection";
import OurMissionSection from "./ourmissionsection";
import OurVision from "./ourvision";
import TeachersSection from "../Home/teachers/teacherssection";
import InstitutionalPartnersSection from "../Home/institutionalpartnerssection/institutionalpartnersection"
import StudentTestimonial from "./studenttestimonial/studenttestimonial";
import SupportSection from "./supportsection";
import { gettestimonialData } from "../../service/services";

const useStyles = makeStyles((theme) => ({
  aboutus:{
    marginBottom: '12rem',
  },
  
}));
function AboutUs() {
  const classes = useStyles();

const [testimonial, setTestimonial] = useState([]);
useEffect(() => {
  async function fetchData() {
    try {
      const response = await gettestimonialData();
      console.log("API Response:", response.data.testimonials);
      setTestimonial(response.data.testimonials);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  fetchData();
}, []);

console.log('gettestimonialData' , testimonial.length)


  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore About us",
      headingmain: "About us",
      breadcrumb:"About us"
    },
  ];
  return (
<div className={classes.aboutus}>
<PageHeading  Heading={Heading}/>
<OnlineEducationSection />
<SupportSection />
<OurMissionSection />
<OurVision />
{/* <TeachersSection /> */}
{testimonial.length !== 0 && <StudentTestimonial />}
{/* <InstitutionalPartnersSection /> */}
</div>
  );
};
export default AboutUs;
