import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import ComingSoon from "../CommingSoon/comingsoon";

const useStyles = makeStyles((theme) => ({
  header: {
   
  },
}));
function Invester() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,

      headingmain: "Coming Soon",
      breadcrumb: "Coming Soon",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <ComingSoon/>
    </div>
  );
}
export default Invester;
