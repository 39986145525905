import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ProgressCard from "./ProgressCard";
import ProgressData from "./ProgressCardData";
import StartOverSection from "./StartOverSection";
import MyPurchaseSection from "./MyPurchaseSection";
import AcheivmentSection from "./AcheivmentSection";
import MyWalletCard from "./MyWalletCard";
import DoubtSolved from "./DoubtSolved";
import Recommendations from "./Recommendations";
import { getDashBoardProgress } from "../../../service/services";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      // padding: "1rem 0.5rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  progress: {
    display: "flex",
    "& .MuiCircularProgress-colorPrimary": {
      color: "#21374F",
      transform: "rotate(90deg) !important",
      [theme.breakpoints.down("xs")]: {
        width: "18px !important",
        height: "18px !important",
      },
    },
    "& .MuiTypography-h4": {
      fontFamily: "Jost !important",
      fontSize: "1.25rem",
      fontWeight: 700,
      color: "#21374F",
      marginLeft: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
        marginLeft: "0.2rem",
      },
    },
  },
  progesscardcontainer: {
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  mypurchaseandachivement: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  mywalletanddoubt: {
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  
}));

function DashboardMain({setOnViewClicked , learnerMenu , handleChange}) {
  const classes = useStyles();
  const [value, setValue] = useState(50);
  const [data,setData]=useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getDashBoardProgress(token);
        console.log("API Response:", response.data);
        setData(response.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <Typography variant="h3">My Dashboard</Typography>
        </div>
        {/* <div className={classes.progress}>
          <CircularProgress
            size={25}
            thickness={7}
            variant="determinate"
            value={value}
          />
          <Typography variant="h4">Progress {value}%</Typography>
        </div> */}
      </div>
      <div className={classes.progesscardcontainer}>
        {data?.products?.map((data) => (
          <ProgressCard data={data} setOnViewClicked={setOnViewClicked}/>
        ))}
      </div>
      {/* { value !== 0  ?
        <StartOverSection />
        :
      <Recommendations /> }   */}
      {/* when progress value is 0 then hide startovercomponent and show reccomendation */}

      <div className={classes.mypurchaseandachivement}>
            <MyPurchaseSection Data={data} setOnViewClicked={setOnViewClicked} learnerMenu={learnerMenu} handleChange={handleChange}/>
            <AcheivmentSection Data={data?.achievements}/>
      </div>
      <div className={classes.mywalletanddoubt}>
          {/* <MyWalletCard Data={data}/> */}
          <DoubtSolved learnerMenu={learnerMenu} handleChange={handleChange}/>
      </div>

    </div>
  );
}

export default DashboardMain;
