import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardMedia,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import BookImg from "../../image/testseriesvector/testseriescardimage.png";
import { ReactComponent as StarIcon } from "../../image/ebooksvector/starsvg.svg";
import { ReactComponent as FavoriteChecked } from "../../image/ebooksvector/favoritechecked.svg";
import { ReactComponent as FavoriteUnchecked } from "../../image/ebooksvector/favoriteunchecked.svg";
import { ReactComponent as CartBagIcon } from "../../image/testseriesvector/carticon.svg";
import { Rating } from "@material-ui/lab";
import FavouriteButton from "../../practicecomponent/FavouriteButton";
import { Link, Navigate, useNavigate } from "react-router-dom";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import BuyNowButton from "../../practicecomponent/BuyNowButton";

const useStyles = makeStyles((theme) => ({
  EbooksCard: {
    justifyContent: "space-between",
    maxWidth: "23.5%",
    display: "flex !important",
    flexDirection: "column",
    borderRadius: 7,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    // "& .MuiButton-root:hover": {
    //   backgroundColor: "#B7B7B7",
    // },
    "& fieldset": {
      margin: "0 0 0 -3px",
      padding: 0,
      display: "flex",
    },
    "& .MuiBox-root": {
      border: "none",
      width: "70%",
    },
    "& .MuiCardContent-root": {
      padding: "6px 16px",
      minHeight: "5rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
    },
    "& .MuiIconButton-root": {
      padding: "0.5rem",
    },
    "& .MuiFormControlLabel-root": {
      margin: "0",
    },
    "& .MuiTypography-h5": {
      color: "#223852",
      fontFamily: "'Jost', sans-serif",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "500",
      margin: "0",
      lineHeight: "1.2rem",
      textTransform: "capitalize",
      whiteSpace: "wrap",
      height: "60px",
      overflow: "hidden",
      textOverflow: `"..."`,
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    "& .MuiTypography-h4": {
      fontFamily: "'Jost', sans-serif",
      color: "#FF3939",
      fontSize: "0.9375rem",
      fontWeight: "500",
    },
    "& .MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    "& .MuiTypography-body2": {
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      // width: "45% !important",
      borderRadius: "3%",
    },
    "& .MuiCardActionArea-root": {
      paddingTop: "10px",
    },
    "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
      opacity: "0.3",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h5": {
        fontSize: "1.2rem",
        marginBottom: 5,
      },
      "& .MuiCard-root": {
        "@media (min-width: 960px)": {
          width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
    },
  },
  cardimgcont: {
    width: "38%",
    position: "relative",
    background: "#FFE8B5",
    margin: "0.6rem 0.7rem",
    borderRadius: 8,
    "&:hover": {
      background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    },
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.5rem",
    fontFamily: "Jost",
    fontWeight: "600",
    marginRight: "0.5rem",
  },
  offertext: {
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  newlaunchtag: {
    width: "50%",
    marginBottom: "0.8rem",
    padding: "0.3rem",
    background: "#FF3939",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    borderRadius: "8px",
    "& .MuiTypography-h6": {
      color: "#FFF",
      fontSize: "0.4375rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    // transform: "translate(50%, 50%)",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "133% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
  },
  quikviewbtn: {
    "&:hover": {
      background: "white",
    },
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  testserandvaliditytagcont: {
    display: "flex",
    margin: "1rem 0 1rem 2rem",
    position: "relative",
    justifyContent: "space-evenly",

    "& .MuiTypography-h6": {
      color: "#252525",
      fontSize: "0.625rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "500",
    },
  },
  innertags: {
    // background: "#FFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    padding: "0.1rem 0.8rem",
    borderRadius: "0.4rem",
  },
  hexagoncontainer: {
    position: "absolute",
    left: "-31px",
    top: "-14px",
  },
  cardimgandtitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardtitle: {
    width: "52%",
  },
  carticon: {
    marginRight: "0.6rem",
  },
  tags: {
    margin: "1rem 0 0.5rem 0",
  },
  ebooktag: {
    background: "linear-gradient(127deg, #FFEEC3 35.82%, #FFD9B5 52.6%)",
    padding: "0.3rem 0.3rem",
    borderRadius: "6px",
    marginRight: "0.3rem",
    fontFamily: "'Jost', sans-serif",
    fontSize: "10px",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  latesttag: {
    background: "#66CB70",
    padding: "0.3rem 0.3rem",
    borderRadius: "6px",
    color: "white",
    fontFamily: "'Jost', sans-serif",
    fontSize: "10px",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  totalratingcont: {
    display: "flex",
    alignItems: "center",
    width: "29%",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: "600",
    },
  },
  favoritebtn: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
  },
  ratingandfavorite: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardroot: {
    flexBasis: "33.3333%",
    // maxWidth: 280,
    margin: "0 5px",
    // maxHeight: 291,
    marginBottom: "2rem",
  },
  carticon: {
    marginRight: "0.6rem",
  },
}));

function EBookCardDash({ Data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  return (
    <Card className={[classes.cardroot, classes.EbooksCard]}>
      <CardActionArea
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={()=>{
          navigate(`/ebook/${ Data?.id}`);
        }}
      >
        <div className={classes.cardimgandtitle}>
          <div className={classes.cardimgcont}>
            {isHover && (
              <div>
                <div className={classes.quikviewcontainer}>
                  {/* <Link to={`/ebook/${data?.id}`}> */}
                    <IconButton className={classes.quikviewbtn}>
                      <InfoIcon />
                    </IconButton>
                  {/* </Link> */}
                  <div className={classes.favoritebtn}>
                    <FavouriteButton
                      isCheckedHeart={isCheckedHeart}
                      data={ Data}
                      setIsCheckedHeart={setIsCheckedHeart}
                    />
                  </div>
                </div>
              </div>
            )}
            <CardMedia
              component="img"
              image={
               ( Data?.image_url)||
                require("../../image/notFoundData.png")
              }
              alt="green iguana"
            />
          </div>
          <div className={classes.cardtitle}>
            <div className={classes.tags}>
              <span className={classes.ebooktag}>E-books</span>
              <span className={classes.latesttag}>Latest</span>
            </div>
            <Typography gutterBottom variant="h5" component="div">
              {Data?.ebook_name} 
              {/* ({data?.sub_heading}) */}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              by {Data?.author}
            </Typography>
            {/* <Typography gutterBottom variant="h5" component="div">
            ( SSC CGL, CHSL, CPO, MTS, GD, JHT)
          </Typography> */}
          </div>
        </div>
        <CardContent>
          <div className={classes.ratingandfavorite}>
            <Box component="fieldset" mb={3} borderColor="transparent">
              <Rating name="read-only" value={Data?.rating} readOnly />
              <div className={classes.totalratingcont}>
                <Typography variant="h6">{Data?.rating}</Typography>
                <Typography variant="caption">
                  ({Data.review})
                </Typography>
              </div>
            </Box>
          </div>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ marginBottom: 0, marginTop: 7 }}
          >
            <span className={classes.textred}>
              <span style={{ fontSize: "1.5rem", verticalAlign: "top" }}>
                ₹
              </span>
              {Data?.selling_cost}
            </span>
            <Typography variant="caption">
              MRP : <del>{Data?.mrp}</del>{" "}
              <span className={classes.offertext}>({Data?.discount_percent}% off)</span>
            </Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.bgorange}>
        <BuyNowButton data={Data} />
      </CardActions>
    </Card>
  );
}
export default EBookCardDash;
