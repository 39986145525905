import React from "react";
import { Divider, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as LearnAnyWhereIcon } from "../image/aboutusvector/learnfromanywhere.svg";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
    paddingTop: "3rem",
    paddingBottom: "3rem",
    background: "#FFF7E7",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  supportsection: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  supportsectioncard: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 3rem",
    },
    "& .MuiTypography-h6": {
      color: "#223852",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.5rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.25rem",
        fontWeight: "500",
        marginTop: "1rem",
      },
    },
    "& .MuiTypography-body2": {
      color: "#7E7E7E",
      textAlign: "center",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.25rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.85rem",
      },
    },
  },
  verticalline: {
    backgroundColor: "#FF9213",
    margin: "2rem 0",
  },
}));
function SupportSection() {
  const classes = useStyles();

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.supportsection}>
          <div className={classes.supportsectioncard}>
            <LearnAnyWhereIcon />
            <Typography variant="h6" gutterBottom>
              Learn From Anywhere
            </Typography>
            <Typography variant="body2" gutterBottom>
              Competently unleash competitive initiatives for alternative
              interfaces. Enthusiastically supply resource eveling platforms
            </Typography>
          </div>
          <Divider
            className={classes.verticalline}
            orientation="vertical"
            flexItem
          />
          <div className={classes.supportsectioncard}>
            <LearnAnyWhereIcon />
            <Typography variant="h6" gutterBottom>
              Expert Instructor
            </Typography>
            <Typography variant="body2" gutterBottom>
              Competently unleash competitive initiatives for alternative
              interfaces. Enthusiastically supply resource eveling platforms
            </Typography>
          </div>
          <Divider
            className={classes.verticalline}
            orientation="vertical"
            flexItem
          />
          <div className={classes.supportsectioncard}>
            <LearnAnyWhereIcon />
            <Typography variant="h6" gutterBottom>
              24/7 Live Support
            </Typography>
            <Typography variant="body2" gutterBottom>
              Competently unleash competitive initiatives for alternative
              interfaces. Enthusiastically supply resource eveling platforms
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SupportSection;
