import {
  makeStyles,
} from "@material-ui/core";
import Slider from "react-slick";
import React from "react";
import ExclusiveNoteCard from "../../../Home/exclusivebooks/exclusivenotecard";
const useStyles = makeStyles((theme) => ({
  ExclusiveBookCard: {
    marginTop: "1rem",
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0.5rem",
    },

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
}));
function RelatedNotesSection({noteDetail}) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.ExclusiveBookCard}>
      <Slider {...settings}>
        {noteDetail?.related_items?.map((data, index) => (
          <ExclusiveNoteCard data={data}/>
        ))}
      </Slider>
    </div>
    </>
  );
}
export default RelatedNotesSection;
