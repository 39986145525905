import {
  SET_QUIZ_LIST,
  SET_PREVIOUS_YEAR_PAPER_LIST,
  SET_EBOOK_LIST,
  SET_BOOK_LIST,
  SET_TEST_SERIES_LIST,
  SET_NOTES_LIST,
  SET_ONLINE_VIDEO_LIST,
  SET_LIVE_CLASSES_LIST,
  SET_BOOK_DETAILS,
  SET_EBOOK_DETAILS,
  SET_NOTE_DETAILS,
  SET_TEST_SERIES_DETAILS,
  SET_ONLINE_VIDEO_DETAILS,
  SET_LIVE_CLASSES_DETAILS,
} from "./type";
const Reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_QUIZ_LIST:
      return {
        ...state,
        quizList: payload,
      };
    case SET_PREVIOUS_YEAR_PAPER_LIST:
      return {
        ...state,
        previousYearPapersList: payload,
      };

    case SET_EBOOK_LIST:
      return {
        ...state,
        ebookList: payload,
      };

    case SET_BOOK_LIST:
      return {
        ...state,
        bookList: payload,
      };
    case SET_TEST_SERIES_LIST:
      return {
        ...state,
        testseriesList: payload,
      };

    case SET_NOTES_LIST:
      return {
        ...state,
        notesList: payload,
      };

      case SET_ONLINE_VIDEO_LIST:
        return {
          ...state,
          onlinevideoList: payload,
        };

        case SET_LIVE_CLASSES_LIST:
          return {
            ...state,
            liveclassesList: payload,
          };

    case SET_EBOOK_DETAILS:
      return {
        ...state,
        eBookDetail: payload,
      };

    case SET_BOOK_DETAILS:
      return {
        ...state,
        bookDetail: payload,
      };

      case SET_NOTE_DETAILS:
        return {
          ...state,
          noteDetail: payload,
        };

    case SET_TEST_SERIES_DETAILS:
      return {
        ...state,
        testseriesDetail: payload,
      };
      case SET_ONLINE_VIDEO_DETAILS:
        return {
          ...state,
          onlinevideoDetail: payload,
        };
        case SET_LIVE_CLASSES_DETAILS:
          return {
            ...state,
            liveclassesDetail: payload,
          };
    default:
      return state;
  }
};

export default Reducer;
