import React, { useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  personaldetails: {
    width: "100%",
    marginTop: "1rem",
    margin: "1rem 5px",
    borderRadius: 5,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    marginBottom: "20rem",
    "& .MuiTypography-h6": {
      color: "red",
      marginTop: "1rem",
    },
    "& .MuiTypography-h5": {
      color: "blue",
      fontSize: "16px",
      fontWeight: "500",
    },
    "& .MuiTypography-h4": {
      color: "blue",
      fontSize: "16px",
      fontWeight: "500",
      marginTop:'2rem',
    },
  },
  personalname: {
    width: "100%",
    marginTop: "1.5rem",
    display: "flex",
    alignItems: "baseline",
    "& .MuiFormLabel-root": {
      width: "20%",
    },
    "& .MuiTextField-root": {
      width: "40%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:
        "border-color: rgba(255,255,255,0) rgba(255,255,255,1) rgba(255,255,255,0) rgba(255,255,255,0)",
    },
  },
 
  selectinput: {
    width: "90%",
    marginTop: ".5rem",
    display: "flex",
    flexDirection: 'column',
    "& .MuiFormLabel-root": {
     marginTop:'1rem',
    },
    "& .MuiInputBase-root": {
      marginTop:'1rem',
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor: "red",
    },
  },
  bothsection:{
    display:'flex',
    width:'100%',
  },
  selectinputsecond:{
    width: "90%",
    marginTop: ".5rem",
    display: "flex",
    flexDirection: 'column',
    justifyContent:'flex-end',
    "& .MuiFormLabel-root": {
     marginTop:'1rem',
    },
    "& .MuiInputBase-root": {
      marginTop:'1rem',
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor: "red",
    },
  },
  sectionone:{
    width:'100%',
  },
  sectiontwo:{
    width:'100%',
  },

  line:{
    marginTop:'3rem',
  },
  TypeHere: {
    width: "100%",
    marginTop: "1.5rem",
    display: "flex",
    flexDirection: 'column',
  
    "& .MuiFormLabel-root": {
    
    },
    "& .MuiTextField-root": {
      width: "90%",
      marginTop:'1rem',
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor: "red",
    },
  },
  
  
  
}));

function ApplyForExam() {
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const [selectedGender, setSelectedGender] = useState("");
  const handleChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const classes = useStyles();
  return (
    <div className={classes.personaldetails}>
      <Typography variant="h6">Apply for & Exam Centre Details:</Typography>
      <div className={classes.applyfor}>
      <Typography variant="h5">के लिए आवेदन करना (Apply For)</Typography>
      </div>
     
    <div className={classes.bothsection}>
      <div className={classes.sectionone}>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>

      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
      </div>
      </div>
      <div className={classes.sectiontwo}>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      </div>
      </div>

  <hr className={classes.line} />
      <div className={classes.applyfor}>
      <Typography variant="h4">के लिए आवेदन jhjhjghकरना (Apply For)</Typography>
      </div>
     
    <div className={classes.bothsection}>
      <div className={classes.sectionone}>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>

      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
       
      </div>
      </div>
      <div className={classes.sectiontwo}>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      </div>
</div>
<hr className={classes.line} />
      <div className={classes.applyfor}>
      <Typography variant="h4">के लिए आवेदन jhjhjghकरना (Apply For)</Typography>
      </div>
     
    <div className={classes.bothsection}>
      <div className={classes.sectionone}>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>

      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
        
      </div>
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
        
      </div>
      <div className={classes.TypeHere}>
                <FormLabel>
                  
            पिता का नाम (father's name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
            पिता का नाम (father's name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
      </div>
      <div className={classes.sectiontwo}>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      
      <div className={classes.selectinputsecond}>
      <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
     
      <div className={classes.TypeHere}>
                <FormLabel>
                  
            पिता का नाम (father's name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
      </div>
</div>







    </div>
  );
}

export default ApplyForExam;
