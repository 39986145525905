import React from "react";
import { ReactComponent as TrophyIcon } from "../../image/dashboard/trophyicon.svg";
import { ReactComponent as AcheivmentIcon } from "../../image/dashboard/acheivment1.svg";
import { ReactComponent as VideoCourseIcon } from "../../image/dashboard/videocourse1.svg";
import { ReactComponent as FileIcon } from "../../image/dashboard/fileicon.svg";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import BlankAchievement from "../../image/dashboard/blankachievement.png";
import { Button, Divider, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1.5rem",
    width: "33%",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  headcontainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  headicon: {
    marginRight: "0.8rem",
  },
  viewbtn: {
    "&.MuiButton-root": {
      color: "#303030",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
  secheadhr: {
    width: "124px",
    display: "inline-block",
    margin: "0.6rem 0 0 3rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "0.125rem",
    [theme.breakpoints.down("xs")]: {
      width: "130px",
      marginTop: "0.6rem",
      marginBottom: "0rem",
    },
  },
  root: {
    width: "100%",
    marginTop: "2.5rem",
    "& .MuiTimeline-root": {
      padding: "0",
    },
    "& .MuiTimelineItem-missingOppositeContent:before": {
      flex: 0,
      padding: "0",
    },
    "& .MuiTimelineContent-root": {
      marginLeft: "1rem",
      padding: "0",
      display: "flex",
    },
    "& .MuiTimelineDot-defaultGrey": {
      background: "#00A019",
      padding: "2px",
    },
    "& .MuiTimelineConnector-root": {
      background: "#00A019",
    },

    "& .MuiTimelineSeparator-root": {
      height: "6rem",
    },

    "& .MuiTypography-h3": {
      fontFamily: "Jost !important",
      fontSize: "1.375rem",
      fontWeight: 600,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiTypography-caption": {
      fontFamily: "Jost !important",
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#3D3D3D",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        textAlign: "left",
      },
    },
  },
  steppericon: {
    marginRight: "1rem",
  },
  viewbtn: {
    "&.MuiButton-root": {
        background: "#243B55",
        color: "#FFF",
        textTransform: "capitalize",
        padding: "0.3rem 1.2rem",
        borderRadius: "0.375rem",
        fontFamily: "Jost",
        fontWeight: "400",
        fontSize: "0.875rem",
    },
  },
  btncontainer: {
    width: "100%",
    margin: "0.5rem auto",
  },
  
  line: {
    width: "28%",
    marginLeft: "16%",
    borderRadius: "0.5625rem",
    background:
      "linear-gradient(270deg, rgba(255, 128, 8, 0.60) 44.89%, rgba(255, 200, 55, 0.60) 100%)",
  },
}));

function AcheivmentSection({Data}) {
  const classes = useStyles();
  console.log('DataDataData' , Data)
  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <TrophyIcon className={classes.headicon} />
          <Typography variant="h3">My Achievements</Typography>
        </div>
        <Divider className={classes.line} />
      </div>
      <div className={classes.root}>
        {/* <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <AcheivmentIcon className={classes.steppericon} />
              <div>
                <Typography variant="caption">
                  Certificates Completed
                </Typography>
                <Typography variant="h3">{Data?.certificates_completed||10}</Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <VideoCourseIcon className={classes.steppericon} />
              <div>
                <Typography variant="caption">
                Video Course, Live Classes
                </Typography>
                <Typography variant="h3">{Data?.videocourse_liveclasses||10}</Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
              <FileIcon className={classes.steppericon} />
              <div>
                <Typography variant="caption">
                Test Series Completed
                </Typography>
                <Typography variant="h3">{Data?.test_series_completed||10}</Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline> */}
        <img src={BlankAchievement} width="100%"/>
        {/* <div className={classes.btncontainer}>
        <Button className={classes.viewbtn}>View All</Button>
      </div> */}
      </div>
    </div>
  );
}

export default AcheivmentSection;
