/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { alpha, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { InputAdornment } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  grow: {
    display: "flex",
    alignItems: "center",
    width: "88%",
    padding:'0.3rem 1rem',
    background:
    "linear-gradient(90deg, rgba(255,128,8,0.3) 0%, rgba(255,200,55,0.3) 100%);",
  "&:hover": {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.5) 0%, rgba(255,200,55,0.5) 100%);",
  },
  [theme.breakpoints.down("xs")]: {
    background:
    "linear-gradient(270deg, rgba(255, 128, 8, 0.4) 0%, rgba(255, 200, 55, 0.4) 71.55%)",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(270deg, rgba(255,128,8,0.5) 0%, rgba(255,200,55,0.5) 100%);",
    },
  },
    "& .MuiSvgIcon-root": {
      width: "1.5em",
      height: "1.5em",
    },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.87) !important",
        borderWidth: "1px !important",
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
        {
          padding: "4.5px 4px",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":{
            border:'none',
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            border:'none',
        }
  },
}));

export default function SearchInput({ allExams , search , handleExamClick }) {
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      <SearchIcon />
      <Autocomplete
            id="combo-box-demo"
        options={allExams || []}
        value={search}
        getOptionLabel={(option) => option.exam_name || []}
        style={{ width: "100%" }}
        onChange={handleExamClick}
        popupIcon
        renderInput={(params) => (
          <>
            <TextField
            {...params}
            placeholder="Search Your Exams..."
            variant="outlined"
          />
          </>
        )}
      />
    </div>
  );
}
