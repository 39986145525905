import React, { useState } from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import Person from "../image/rpsvector/man.png";
import ReusableModal from "./reuseablemodal";
import OrderPlaced from "../allpopups/OrderPlaced";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "130px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "78px",
    },
  },
  container: {
    borderRadius: "8px",
    maxWidth: "1250px",
    margin: "0 auto",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "1.4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "235px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      width: "auto",
      height: "auto",
      padding: "2rem",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "90%",
    },
  },
  bannercardmain: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  bannercardimg: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    transform: "translate(0px, -17px)",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      transform: "none",
      marginBottom: "0",
      maxWidth: "88%",
      padding: "1.2rem",
      marginTop: "-89px",
    },
  },
  bannercardcontents: {
    width: "100%",
    textAlign: "center",
    marginTop: "5px",
    [theme.breakpoints.up("sm")]: {
      width: "45%",
      textAlign: "left",
      marginLeft: "7rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
      marginTop: "1rem",
    },
  },
  bannercardbutton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "1rem",
    },
  },
  orangecustombtn: {
    background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    color: "black",
    marginTop: "9px",
    padding: "13px 50px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  heading: {
    fontSize: "2rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      textAlign: "center",
      whiteSpace: "pre-line",
      marginTop: "33px",
    },
  },
  heading1: {
    marginTop: "18px",
    display: "block",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1.1rem",
    color: "rgba(206, 206, 206, 1)",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      fontSize: "1.1rem",
    },
  },
}));

function RpsCard() {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.bannercardmain}>
          <div className={classes.bannercardimg}>
            <img src={Person} alt="img" width="100%" />
          </div>
          <div className={classes.bannercardcontents}>
            <Typography variant="h4" className={classes.heading}>
              Streamlined Result Processing System{" "}
              <span style={{ color: "orange" }}>(RPS)</span>
            </Typography>
            <Typography variant="h7" className={classes.heading1}>
              Know more about RPS by our team and more about how to optimize
              Evaluate for better results.
            </Typography>
            <div className={classes.bannercardbutton}>
              <Button
                variant="contained"
                className={classes.orangecustombtn}
                onClick={handleModalOpen}
              >
                Get Started
              </Button>
              <ReusableModal open={isModalOpen} onClose={handleModalClose}>
                <OrderPlaced handleOpenclose={handleModalClose} />
              </ReusableModal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RpsCard;
