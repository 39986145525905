import React, { useEffect, useState } from "react";
import VideoCourseCard from "./videoCourseCard";
import { Typography, makeStyles } from "@material-ui/core";
import RecommendVideoCourse from "./recommendVideoCourse";
import { getOnlineVideoCourse } from "../../../service/services";
import OopsLiveTestSeries from "../ErrorPage/OopsLiveTestSeries";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: "1rem",
    // boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    // padding: "1.5rem",
    // background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  cardContainer: {
    marginTop: "1rem",
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "1rem",
    },
    // display: "flex",
    // marginTop: "1rem",
    // justifyContent: "space-between",
    // [theme.breakpoints.down("xs")]: {
    //   flexDirection: "column",
    // },
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "22%",
    marginTop: "0.2em",
    marginLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

const VideoCourse = (props) => {
  const classes = useStyles();
  const setOnViewClicked = props.setOnViewClicked;
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getOnlineVideoCourse(token);
        console.log("API Response:", response.data);
        setData(response.data.onlinevideos);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <>
      <div className={classes.root}>
        <Typography gutterBottom className={classes.heading}>
          {" "}
          My Video Course
        </Typography>
        <div className={classes.cardContainer}>
          {data.length === 0 ? (
            <OopsLiveTestSeries />
          ) : (
            data.map((data, index) => (
              <VideoCourseCard
                setOnViewClicked={setOnViewClicked}
                Data={data}
                key={index}
              />
            ))
          )}
        </div>
      </div>
      <RecommendVideoCourse />
    </>
  );
};

export default VideoCourse;
