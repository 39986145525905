import { makeStyles } from "@material-ui/core";
import React from "react";
import SecHead from "../sectionHeading/sectionheading";
import LatestNotificationContent from "./latestnotificationcontent";



const useStyles = makeStyles((theme) => ({
  latestNotificationmain: {
    margin: "2.2rem 0rem 4rem  0rem",
    // padding: '0.5rem 0rem',
    '& .MuiCard-root': {
      "&:hover": {
        boxShadow: '0px 0px 10px rgba(0,0,0, 0.6)',
        cursor: 'pointer',
        "& .MuiCardActions-root": {
          background: " linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%);",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: '3rem',
      marginBottom: '3rem',
    }
  },
  containerfluid: {
    width: "100%", 

    "& .slick-dots li button:before": {
      color: '#FF8008',
      fontSize: '15px',
    },

  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
}));
function LatestNotificationSection() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore More",
      headingmain: "Latest Notifications",
    },
  ];
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <>
      <div className={classes.latestNotificationmain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <SecHead Heading={Heading} media={mql.matches}
            />
            <LatestNotificationContent />
          </div>
        </div>
      </div>
    </>
  );
}
export default LatestNotificationSection;
