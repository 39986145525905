import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import HeaderNotification from "./headerNotification";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1.5rem",
  },
  card: {
    minHeight: "275",
    padding: "auto",
    marginBottom: "20rem",
  },

  tabhead: {
    background: "#243B55",
    color: "#FFFFFF",
  },
  tabsell: {
    color: "#FFFFFF",
  },
  icbutton: {
    background: "#FFD9B5",
    padding: "10px",
    fontSize: "12px",
    fontWeight: 400,
  },
  tablebutton: {
    [theme.breakpoints.down("xs")]: {
      width: "33%",
      textAlign: "center",
      padding: "0",
    },
  },
}));

function createData(AlltheLinks, Moreinfo) {
  return { AlltheLinks, Moreinfo };
}

const rows = [
  createData("Written marks (10/11/2023)", "Click here"),
  createData("PET/PST Admit card (29/06/2023)", "Click here"),
  createData("26/10/2023", "Click here"),
  createData("25/10/2023", "Click here"),
  createData("26/10/2023", "Click here"),
  createData("PET/PST Admit card (29/06/2023)", "Click here"),
  createData("26/10/2023", "Click here"),
  createData("25/10/2023", "Click here"),
  createData("26/10/2023", "Click here"),
  createData("25/10/2023", "Click here"),
  createData("26/10/2023", "Click here"),
];
const ImportantLinks = ({ Notification1, row }) => {
  const value = "Important Links";
  const classes = useStyles();

  //   var clickHereButton = (
  //     <Button className={classes.icbutton} onClick={console.log("delete")}>
  //       Click Here
  //       {/* <DeleteIcon color="secondary" /> */}
  //     </Button>
  //   );

  // const handleButtonClick = () => {

  // };
  let Navigate = useNavigate();
  return (
    <>
      <div className={classes.root}>
        <HeaderNotification data={value} />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tabhead}>
              <TableRow>
                <TableCell className={classes.tabsell}>All The Links</TableCell>
                <TableCell className={classes.tabsell} align="right">
                  More Info
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.map((row) => (
              <TableRow key={row.PostData}>
                <TableCell component="th" scope="row">
                  {row.AlltheLinks}
                </TableCell>
                <TableCell align="right">
                  <Button
                    className={classes.icbutton}
                    onClick={console.log("delete")}
                  >
                    Click Here
                  </Button>
                </TableCell>
              </TableRow>
            ))} */}
              {Notification1?.links?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tablebutton} align="right">
                    <Button
                      className={classes.icbutton}
                      onClick={() => {
                        if (row && row.link) {
                          // Use URL object to extract the external link
                          const url = new URL(row.link);
                          // const pathnameParts = url.pathname.split('/');
                          // const externalLink = pathnameParts[pathnameParts.length - 1];

                          // Open the external link in a new tab
                          window.open(url, "_blank");
                        } else {
                          console.error("row or row.link is undefined");
                        }
                      }}
                    >
                      Click Here
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ImportantLinks;
