import React from "react";
import { Typography, makeStyles, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import IdentificationImage from "../image/biometricvector/benefits.png";
import SupportsectionImpressive from "./supportsectionimpressive";
import ImpressiveSectionData from "./impressivesectiondata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    marginTop: "5rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      marginTop: "1rem",
    },
  },
  onlineeducationcontent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 1rem",
    },
  },
  onlineeducationimgcontainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "2rem 1rem",
    },
  },
  onlineeducationcontentinner: {
    width: "99%",
    [theme.breakpoints.down("xs")]: {
      width: "91%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
  },
  MuiTypographybody2: {
    marginTop: "0.5rem",
    fontFamily: "'Jost', sans-serif",
    color: "#7E7E7E",
    fontSize: "1rem",
    fontWeight: "400",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
  MuiTypographybody1: {
    fontFamily: "'Playfair Display'",
    color: "rgba(34, 56, 82, 1)",
    fontSize: "36px",
    lineHeight: "47.99px",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
      color: " #223852",
    },
  },
}));

const ImpressiveSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Elevate Your Security and Efficiency with Cutting-Edge Biometrics.",
      headingmain: "Impressive Benefits",
    },
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.MuiTypographybody1}
              >
                The Game Changing Benefits of Our Cutting-Edge Solution.
              </Typography>
              <Grid container spacing={2}>
                {ImpressiveSectionData.map((item, index) => (
                  <Grid item xs={isMobile ? 12 : 6} key={index}>
                    <SupportsectionImpressive
                      text={item.text}
                      icon={<img src={item.icon} alt={item.text} />}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          <div className={classes.onlineeducationimgcontainer}>
            <img src={IdentificationImage} alt="img" width="80%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpressiveSection;
