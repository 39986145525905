import { makeStyles } from "@material-ui/core";
import React from "react";
import SecHead from "../sectionHeading/sectionheading";
import Vector2 from "../../image/bodyvector/vector2.png";
import Vector3 from "../../image/bodyvector/vector3.png";
import ChooseUsSectionContent from "./chooseussectioncontent";

const useStyles = makeStyles((theme) => ({
  Banner: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    fontFamily: "'Playfair Display', serif !important",
  },
  containerfluid: {
    background: '#FFF7E4',
    paddingTop:'2rem',
    paddingBottom:'4rem',
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      paddingTop:'3rem',
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  secheadmain: {
    display: "flex",
    justifyContent: 'space-evenly',
  },
  headvector: {
    width: "20%",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
function ChooseUsSection() {
  const Heading = [
    {
      id: 1,
      headingsmall: "On the Journey to Success",
      headingmain: "Top Reasons to Partner With Us",
    },
  ];
  const HeadingMobile = [
    {
      id: 1,
      headingsmall: "Key Reasons to Partner with Us",
      headingmain: "Your Pathway To Success",
    },
  ];
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <>
      <div className={classes.VideoCoursesMain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <div className={classes.secheadmain}>
              <div className={classes.headvector}>
                {/* <img src={Vector3} alt="img" /> */}
              </div>
              {mql.matches ?
              <>
              <SecHead Heading={HeadingMobile} media={mql.matches} />
              </>
              :
              <>
              <SecHead Heading={Heading} media={mql.matches} />
              </>
            }
              
              <div className={classes.headvector}>
                {/* <img src={Vector2} alt="img" /> */}
              </div>
            </div>
            <ChooseUsSectionContent />
          </div>
        </div>
      </div>
    </>
  );
}
export default ChooseUsSection;
