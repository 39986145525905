import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import FavouriteButton from "../FavouriteButton";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import manICON from "../../image/dashboard/manICON.png";
import calendericon from "../../image/dashboard/calendericon.png";
import folderiCon from "../../image/dashboard/folderICon.png";

const useStyles = makeStyles((theme) => ({
  NotesCard: {
    justifyContent: "space-between",
    maxWidth: "23.5%",
    display: "flex !important",
    flexDirection: "column",
    borderRadius: 7,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    "& .MuiCardMedia-img": {
      objectFit: "contain",
    },
    "& .MuiTypography-body2": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiCardActionArea-root": {
      padding: "0px 10px 12px 10px",
      position: "relative",
    },
    "& .MuiCardContent-root": {
      padding: "0px",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
    "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
      opacity: "0.3",
    },
    "& .MuiTypography-h5": {
      color: "#223852",
      fontFamily: "'Jost', sans-serif",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "500",
      margin: "0",
      lineHeight: "1.2rem",
      textTransform: "capitalize",
      whiteSpace: "wrap",
      height: "25px",
      overflow: "hidden",
      textOverflow: `"..."`,
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    "& .MuiTypography-h4": {
      fontFamily: "'Jost', sans-serif",
      color: "#FF3939",
      fontSize: "0.9375rem",
      fontWeight: "500",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      // width: "45% !important",
      borderRadius: "3%",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h5": {
        fontSize: "1.2rem",
        marginBottom: 5,
      },
      "& .MuiCard-root": {
        "@media (min-width: 960px)": {
          width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
    },
  },
  cardimgcont: {
    width: "180px",
    height: "180px",
    background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    // margin: "0rem  0.7rem 0rem 0.7rem",
    borderRadius: 8,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.3rem",
    fontFamily: "Jost",
    fontWeight: "600",
    marginRight: "0.5rem",
  },
  offertext: {
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  authorandsectioncont: {
    display: "flex",
  },
  newlaunchtag: {
    width: "60%",
    marginBottom: "0.4rem",
    borderRadius: "0.125rem",
    border: "1px solid #FFA900",
    background: "#FFEEC3",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-h6": {
      color: "#000",
      fontSize: "0.6rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "50% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
  },
  quikviewbtn: {
    "&:hover": {
      background: "white",
    },
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  favoritebtn: {
    // width: "29%",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
  },
  tagscontainer: {
    display: "flex",
    position: "relative",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      color: "#252525",
      fontSize: "0.55rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "500",
    },
  },
  innertag: {
    width: "85%",
    padding: "0.3rem",
    display: "flex",
    marginBottom: "10px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "0.4rem",
  },
  innertags: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "0.4rem",
    marginBottom: "10px",
  },
  innertagicons: {
    margin: "0rem 0.2rem",
  },
  cardimgandtitle: {
    display: "flex",
    justifyContent: "center",
  },
  cardtitle: {
    width: "100%",
    paddingTop: "0.5rem",
  },
  carticon: {
    marginRight: "0.6rem",
  },
  cardroot: {
    flexBasis: "33.3333%",
    // maxWidth: 280,
    height: 291,
    margin: "0 5px",
    marginBottom: "2rem",
  },
  smalldiv: {
    display: "flex",
    fontSize: "0.625rem",
    fontFamily: "Jost",
    fontWeight: "500",
    width: "85%",
    background: "#FFFFFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    bottom: "-18px",
    left: "50%",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "center",
    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.14)",
  },
  ratingsandstudentscon: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0.2rem 0rem",
  },
  sdiv: {
    borderRadius: "6px",
    // margin:"auto",
    display: "flex",
    border: "1px solid #CFCFCF",
    width: "60%",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
  },
  head1: {
    fontSize: "0.6rem",
  },
  head2: {
    color: "#FF3939",
    fontWeight: "600",
    fontSize: "0.625rem",
    fontFamily: "Jost",
  },
  imgcont: {
    width: "20%",
  },
  tags: {
    position: "absolute",
    left: "0",
    top: "10px",
    zIndex: "1999",
  },
  ebooktag: {
    background: "linear-gradient(127deg, #FFEEC3 35.82%, #FFD9B5 52.6%)",
    borderRadius: "0px 6px 6px 0px",
    marginRight: "0.3rem",
    fontFamily: "'Jost', sans-serif",
    fontSize: "10px",
    fontWeight: "500",
    textTransform: "uppercase",
    padding: "0.2rem 0.8rem !important",
  },
}));

function LiveClasses({ data }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  const [isHover, setIsHover] = useState(false);
  console.log("object1234", data);

  return (
    <Card className={[classes.NotesCard, classes.cardroot]}>
      <CardActionArea
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={() => {
          navigate(`/liveclasses/${data?.id}`);
        }}
      >
        {data?.type === "webinar" && (
          <div className={classes.tags}>
            <span className={classes.ebooktag}>Webinar</span>
            {/* <span className={classes.latesttag}>Latest</span> */}
          </div>
        )}
        {isHover && (
          <div>
            <div className={classes.quikviewcontainer}>
              {/* <Link to={`/note/${data?.id}`}> */}
              <IconButton className={classes.quikviewbtn}>
                <InfoIcon />
              </IconButton>
              {/* </Link> */}
              <div className={classes.favoritebtn}>
                <FavouriteButton
                  isCheckedHeart={isCheckedHeart}
                  data={data}
                  setIsCheckedHeart={setIsCheckedHeart}
                />
              </div>
            </div>
          </div>
        )}
        <div className={classes.cardimgandtitle}>
          <div className={classes.cardimgcont}>
            <CardMedia
              component="img"
              image={data?.image_url || require("../../image/notFoundData.png")}
              alt="green iguana"
              width="170px"
              height="165px"
            />
            <div className={classes.smalldiv}>
              <img src={manICON} />{" "}
              <span>{data?.register_user}+ Users Have Registered</span>
            </div>
          </div>
        </div>
        <div className={classes.cardtitle}>
          <Typography gutterBottom variant="h5" component="div">
            {data?.live_class_name} {data?.webinar_name}
            {data?.sub_heading && <span>{data?.sub_heading}</span>}
          </Typography>

          <div className={classes.ratingsandstudentscon}>
            <div className={classes.sdiv}>
              <div className={classes.imgcont}>
                <img src={calendericon} alt="img" width="100%" />
              </div>
              <div>
                <Typography className={classes.head1}>
                  {data?.type === "webinar"
                    ? "Webinar Start time"
                    : "Lecture Start Date"}
                </Typography>
                {data?.type === "webinar" ? (
                  <Typography className={classes.head2}>
                    {data?.start_time}
                  </Typography>
                ) : (
                  <Typography className={classes.head2}>
                    {new Date(data?.start_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    })}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <CardContent>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ marginBottom: 0 }}
          >
            <span className={classes.textred}>
              <span style={{ fontSize: "1.3rem", verticalAlign: "top" }}>
                ₹
              </span>
              {data?.selling_cost}
            </span>
            <Typography variant="caption">
              MRP:₹<del>{data?.mrp}</del>
              <span className={classes.offertext}>
                ({data?.discount_percent}% off)
              </span>
            </Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
export default LiveClasses;
