import { Button, IconButton, Typography, makeStyles } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
// import Pdf from "./sample.pdf";
import { useContext, useState } from "react";
import AppContext from "../../../context/AppContext";
import {
  getEbookPdf,
  getNotesPdf,
  saveEbookLastPage,
  saveNotesLastPage,
} from "../../../service/services";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { AiOutlineFullscreen } from "react-icons/ai";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import CircularProgress from "@material-ui/core/CircularProgress";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    // padding: "1rem",
    background: "grey",
    position: "relative",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      background: "#fff",
      paddingBottom: "4rem",
    },
  },
  containerfluid_website: {
    width: "55%",
    position: "relative",
    height: "94vh",
    background: "#fff",
    borderRadius: "10px",
    paddingBottom: "4rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      height: "69vh",
    },
  },
  navigationbtn: {
    width: "20%",
    background: "#243B55",
    color: "white !important",
    marginLeft: "0.5rem",
    fontFamily: "Jost",
    "&:hover": {
      background: "#243B55",
    },
    [theme.breakpoints.down("xs")]: {
      width: "45%",
    },
  },
  exitbtn: {
    background: "#fff",
    color: "#000",
    fontFamily: "Jost",
    border: "1px solid black",
    borderRadius: "0.375rem",
    padding: "0.3rem",
    marginRight: "0.5rem",
    "&:hover": {
      background: "#243B55",
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.2rem",
    },
  },

  pdfcontent: {
    marginTop: "1rem",
    overflow: "scroll",
    maxHeight: "95vh",
    position: "relative",
    width: "50% !important",
    margin: "0 auto",
    "&::-webkit-scrollbar ": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      maxHeight: "none",
      marginTop: "0",
    },
    "& .react-pdf__Page__canvas": {
      width: "100% !important",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
        height: "88vh !important",
      },
    },
    "& .react-pdf__Page": {
      userSelect: "none",
      margin: "0 auto",
      [theme.breakpoints.down("xs")]: {
        padding: "0",
      },
      "& .react-pdf__Page__annotations": {
        height: "auto !important",
        display: "none",
      },
      "& .react-pdf__Page__textContent": {
        height: "auto !important",
        display: "none",
      },
      
    },
  },
  pdfcontent_website: {
    marginTop: "0",
    overflow: "scroll",
    maxHeight: "none",
    position: "relative",
    width: "100% !important",
    height: "100% !important",
    borderRadius: "10px",
    "& .react-pdf__Page__canvas": {
      width: "100% !important",
      height: "100% !important",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
        height: "86vh !important",
      },
    },
  },
  buttoncontainer: {
    position: "absolute",
    top: "12px",
    right: "20px",
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      right: "4px",
      top: "28px",
    },
  },
  w40: {
    width: "40%",

    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  w32: {
    width: "32%",

    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  w70: {
    width: "70% !important",

    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  pagenumbercontainer: {
    position: "absolute",
    bottom: "-200px",
    right: "20px",
    zIndex: 100,
  },
  navigationbtncontainer: {
    position: "absolute",
    bottom: "35px",
    right: "24px",
    zIndex: 100,
    display: "flex",
    justifyContent: "flex-end",
  },
  bottom_0: {
    bottom: "0px !important",
  },
  bottom_website: {
    bottom: "15px !important",
  },
  loader: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },

  // '@media print': {
  //   containerfluid: {
  //     display: 'none'
  //   }
  // }
}));
function PdfViewer({
  pdfUrl,
  handleOpenclose,
  selectedRowId,
  lastPage,
  ebookId,
  noteId,
  size,
  setSize,
  userType
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(lastPage ? lastPage : 1);
  const [zoom, setZoom] = useState(1);

  const { token } = useContext(AppContext);

  const path = window.location.pathname;

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    if (zoom > 1) {
      setZoom(zoom - 0.1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage);
      if (ebookId) {
        handleSaveEbookLastPage(nextPage);
      } else {
        handleSaveNotesLastPage(nextPage);
      }
    }
  };
  const handleSubmit = () => {
    if (ebookId) {
      handleSaveEbookLastPage(1, "completed");
    } else {
      handleSaveNotesLastPage(1, "completed");
    }
    handleOpenclose();
  };

  const prevPage = () => {
    // if (pageNumber > numPages) {
    setPageNumber(pageNumber - 1);
    // }
  };
  console.log("pageNumber", pageNumber + ", " + "numpages", numPages);
  const classes = useStyles();

  const handleSaveEbookLastPage = async (lastPage, status) => {
    const data = {
      ebook_upload_plan: {
        ebook_upload_plan_id: selectedRowId,
        page_no: lastPage,
        status: status ? status : null,
      },
    };
    const response = await saveEbookLastPage(data, token);
    getEbookPdf(token, ebookId, userType ? userType : "parikshado");
    console.log("response", response);
  };
  const handleSaveNotesLastPage = async (lastPage, status) => {
    const data = {
      note_content: {
        note_content_id: selectedRowId,
        type: "pdf",
        page_no: lastPage,
        status: status ? status : null,
      },
    };
    const response = await saveNotesLastPage(data, token);
    getNotesPdf(token, noteId, userType ? userType : "parikshado");
    console.log("response", response);
  };

  return (
    <>
      <div
        className={`${classes.containerfluid} ${
          size === "smallscreen" && classes.containerfluid_website
        }`}
        // onContextMenu={(e) => e.preventDefault()}
      >
        <div className={classes.buttoncontainer}>
          {size !== "smallscreen" && (
            <div className={classes.zoomButtonsContainer}>
              <IconButton className={classes.exitbtn} onClick={handleZoomIn}>
                <ZoomInIcon />
              </IconButton>
              <IconButton className={classes.exitbtn} onClick={handleZoomOut}>
                <ZoomOutIcon />
              </IconButton>
            </div>
          )}
          {path !== "/dashboard" && (
            <IconButton
              className={classes.exitbtn}
              onClick={() => {
                setSize(size === "smallscreen" ? "fullscreen" : "smallscreen");
              }}
            >
              {size === "smallscreen" ? (
                <AiOutlineFullscreen />
              ) : (
                <AiOutlineFullscreenExit />
              )}
            </IconButton>
          )}
          <IconButton className={classes.exitbtn} onClick={handleOpenclose}>
            <HighlightOffIcon />
          </IconButton>
        </div>
        <div
          className={`${classes.pdfcontent} ${
            size === "smallscreen" && classes.pdfcontent_website
          }`}
        >
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onContextMenu={(e) => e.preventDefault()}
            loading={<CircularProgress className={classes.loader} />}
          >
            <Page pageNumber={pageNumber} scale={zoom} />
          </Document>
        </div>
        <div
          className={`${size === "smallscreen" ? classes.w32 : classes.w40}  ${
            classes.navigationbtncontainer
          } ${size === "smallscreen" && classes.bottom_website}`}
        >
          {pageNumber > 1 && (
            <Button
              className={`${classes.navigationbtn} ${
                size === "smallscreen" && classes.w70
              }`}
              onClick={prevPage}
              disabled={pageNumber === 1}
            >
              &lt;&lt; Prev
            </Button>
          )}
          {pageNumber !== numPages && (
            <Button
              className={`${classes.navigationbtn} ${
                size === "smallscreen" && classes.w70
              }`}
              onClick={nextPage}
              disabled={pageNumber === numPages}
            >
              Next &gt;&gt;
            </Button>
          )}
          {pageNumber === numPages && (
            <Button
              className={`${classes.navigationbtn} ${
                size === "smallscreen" && classes.w70
              }`}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default PdfViewer;
