import React, { useContext, useState } from "react";
import {
  makeStyles,
  Button,
  Typography,
  useMediaQuery,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import QuizImage from "../../image/quizreult/quiz.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import QuestionAndOptions from "./questionandoptions";
import QuizContainer from "./quizcontainer";
import TimerIcon from "@material-ui/icons/Timer";
import practiceContext from "../context/PypPracticeContext";
import useCountdown from "./useCountdown";
import MobileQuizContainer from "./MobileQuizContainer";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1250px",
    background: "#FFFCF4",
    alignItems: "flex-start",
    padding: "0.5rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      margin: "0 auto",
    },
  },
  quizcontainermain: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "85vh",
  },
  verticalAndBtnContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "space-around",
    },
  },
  verticalSquare: {
    width: "100%",
    // minHeight: "80%",
    // marginTop: "8rem",
    boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0rem",
    },
  },
  questionheading: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  headingText: {
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: "600",
    },
  },
  topText: {
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(34, 56, 82, 1)",
    fontFamily: "'Playfair Display', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9375rem",
      width: "65%",
    },
  },

  rectangularStrip: {
    height: "45px",
    borderRadius: "10px 10px 0px 0px",
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 101.38%)",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      height: "49px",
      padding: "0",
    },
  },
  logo: {
    width: "22px",
    marginRight: "10px",
  },
  leftContent: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  headingContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  shareButton: {
    width: "132px",
    marginRight: "1rem",
    background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    [theme.breakpoints.down("sm")]: {
      background: "linear-gradient(269.83deg, #FFB36B 20.79%, #FFDE87 89.56%)",
      width: "25%",
      height: "32px",
      fontSize: "0.75rem",
      "& .MuiButton-iconSizeMedium > *:first-child": {
        fontSize: "16px",
      },
    },
  },
  languageButton: {
    textTransform: "none",
    width: "60%",
    fontSize: "15px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 3px 11px 0px rgba(0, 0, 0, 0.17) inset",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      background: "transparent",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      textTransform: "capitalize",
    },
    "& .MuiMenu-list": {
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  quizBoxContainer: {
    display: "flex",
    width: "27%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "35px",
    "& .MuiButton-root.Mui-disabled": {
      color: "grey",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0.6rem",
      marginLeft: "0rem",
    },
  },
  previousButton: {
    width: "140px",
    color: "rgba(91, 98, 109, 1)",
    height: "45px",
    margin: "0 0px",
    border: "2px solid rgba(20, 31, 49, 0.7)",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "'Jost', sans-serif",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem",
      width: "116px",
      height: "41px",
      border: "1px solid rgba(20, 31, 49, 0.7)",
      "& svg": {
        width: "0.7em",
      },
    },
  },
  submitButton: {
    display: "none",
    width: "200px",
    background: "linear-gradient(90deg, #141E30 -50%, #243B55 303.78%)",
    color: "rgba(255, 255, 255, 1)",
    height: "45px",
    margin: "0 10px",
    textTransform: "uppercase",
    fontSize: "1.125rem",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    [theme.breakpoints.down("xs")]: {
      background: "#233A54",
      fontSize: "0.75rem",
      width: "144px",
      height: "41px",
      "&:hover": {
        background: "#233A54",
      },
    },
  },

  nextButton: {
    width: "200px",
    background: "linear-gradient(90deg, #141E30 -50%, #243B55 303.78%)",
    color: "rgba(255, 255, 255, 1)",
    height: "45px",
    margin: "0 10px",
    textTransform: "uppercase",
    fontSize: "1.125rem",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    [theme.breakpoints.down("xs")]: {
      background: "#233A54",
      fontSize: "0.75rem",
      width: "144px",
      margin: "0",
      height: "41px",
      "&:hover": {
        background: "#233A54",
      },
      "& svg": {
        width: "0.7em",
      },
    },
  },
  hideBtn: {
    display: "none",
  },
  showBtn: {
    display: "block",
  },
  timerContainer: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "center",
      width: "20%",
    },
  },

  timerText: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "8px",
    },
  },
  shareandlanguagecont: {
    width: "40%",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

function QuestionBox() {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const {
    quizQuestionObj,
    currentSection,
    setCurrentSection,
    currentQuestion,
    setCurrentQuestion,
    setTimeTaken,
  } = useContext(practiceContext);

  const [selectedLanguage, setSelectedLanguage] = useState("english");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    // You can perform additional actions based on the selected language if needed
  };

  const [countDown, timerEnd] = useCountdown(
    quizQuestionObj?.duration * 60 * 1000
  );
  const min = Math.floor((countDown / 1000 / 60) << 0);
  const sec = Math.floor((countDown / 1000) % 60);
  const handleNextButtonClick = () => {
    // Check if there are more questions in the current section
    if (
      currentQuestion <
      quizQuestionObj?.sections[currentSection]?.questions?.length
    ) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // Check if there are more sections
      const totalSections = quizQuestionObj?.sections?.length;
      if (currentSection < totalSections) {
        // Move to the next section and reset the question index
        setCurrentSection(currentSection + 1);
        setCurrentQuestion(1); // or set it to the first question in the new section
      } else {
        // Handle end of quiz or any other logic
      }
    }
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headingContent}>
          <Typography variant="h5" className={classes.topText}>
            Previous Year Papers Exam :{" "}
            {quizQuestionObj?.previous_year_paper_name}
          </Typography>
          <div className={classes.shareandlanguagecont}>
            {/* <Button className={classes.shareButton} startIcon={<ShareIcon />}>
              Share
            </Button> */}
            <div className={classes.languageButton}>
              <Select
                labelId="category-label"
                id="state"
                displayEmpty
                MenuProps={menuProps}
                variant="outlined"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {quizQuestionObj?.language?.map((data, index) => (
                  <MenuItem key={index} value={data?.language}>
                    {data?.language}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className={classes.quizcontainermain}>
          <div className={classes.verticalAndBtnContainer}>
            <div className={classes.verticalSquare}>
              <div className={classes.rectangularStrip}>
                <div className={classes.leftContent}>
                  <img className={classes.logo} src={QuizImage} alt="Logo" />
                  <div className={classes.questionheading}>
                    <Typography variant="body1" className={classes.headingText}>
                      {quizQuestionObj?.sections[currentSection]?.section_name}{" "}
                      {currentSection + 1}/{quizQuestionObj?.sections?.length}
                    </Typography>
                    <Typography variant="body1" className={classes.headingText}>
                      Question {currentQuestion}/
                      {
                        quizQuestionObj?.sections[currentSection]?.questions
                          ?.length
                      }
                    </Typography>
                  </div>
                </div>
                <div className={classes.timerContainer}>
                  <TimerIcon />
                  <Typography variant="body1" className={classes.timerText}>
                    {min}:{sec}
                  </Typography>
                </div>
              </div>
              <QuestionAndOptions selectedLanguage={selectedLanguage} />
            </div>
            <div className={classes.buttonsContainer}>
              <Button
                className={classes.previousButton}
                disabled={currentQuestion === 1}
                onClick={() => setCurrentQuestion(currentQuestion - 1)}
              >
                <ArrowBackIcon /> PREVIOUS
              </Button>
              <Button
                className={`${classes.nextButton} ${
                  currentSection + 1 === quizQuestionObj?.sections?.length &&
                  currentQuestion ===
                    quizQuestionObj?.sections[currentSection]?.questions
                      ?.length &&
                  classes.hideBtn
                }`}
                onClick={() => handleNextButtonClick()}
              >
                Save and Next <ArrowForwardIcon />
              </Button>
              {/* <Button
             
                onClick={() => {
                let msUsed =
                  60000 * quizQuestionObj?.duration -
                  (60000 * min + 1000 * sec);
                let min2 = Math.floor((msUsed / 1000 / 60) << 0);
                let sec2 = Math.floor((msUsed / 1000) % 60);
                setTimeTaken(min2, sec2);
              }}
            >
              Submit
            </Button> */}
              <Button
                className={`${classes.submitButton} ${
                  currentSection + 1 === quizQuestionObj?.sections?.length &&
                  currentQuestion ===
                    quizQuestionObj?.sections[currentSection]?.questions
                      ?.length &&
                  classes.showBtn
                }`}
                onClick={() => {
                  let msUsed =
                    60000 * quizQuestionObj?.duration -
                    (60000 * min + 1000 * sec);
                  let min2 = Math.floor((msUsed / 1000 / 60) << 0);
                  let sec2 = Math.floor((msUsed / 1000) % 60);
                  setTimeTaken(min2, sec2);
                  navigate(`/previousyearpapers/${id}/result`);
                }}
              >
                Submit Paper
              </Button>
              {isMobile && (
                <MobileQuizContainer
                  min={min}
                  sec={sec}
                  timerEnd={timerEnd}
                  setTimeTaken={setTimeTaken}
                />
              )}
            </div>
          </div>
          <div className={classes.quizBoxContainer}>
            {quizQuestionObj && (
              <QuizContainer
                min={min}
                sec={sec}
                timerEnd={timerEnd}
                setTimeTaken={setTimeTaken}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionBox;
