import {
  Button,
  Divider,
  FormControl,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import OtpInput from "react18-input-otp";
import { authorizeMe, resendOtp, signInOTPVerify, verifyOtp } from "../../service/services";
import { toast } from "react-toastify";
import appContext from "../../context/AppContext"


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "70%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      fontWeight: "700",
      margin: '0 0 10px 0',
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: "0.88rem",
      fontFamily: "Jost",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
        marginTop: "1rem",
      },
    },
    "& .MuiDivider-root": {
      width: "11%",
      background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
      height: "0.125rem",
      marginTop: "0.4rem",
      [theme.breakpoints.down("xs")]: {
        width: "23%",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: '0.75rem',
      },
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiLink-root": {
        color: "#154FE5",
        fontSize: "0.9375rem",
        fontWeight: "400",
        textTransform: "capitalize",
        cursor: "pointer"
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline.Mui-focused:after": {
        borderBottom: "none",
      },
    },
  },

  paper: {
    position: "absolute",
    display: "flex",
    top: "68px",
    backgroundColor: "#FFFAEF",
    zIndex: "8",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    height: "calc(100vh - 80px)",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: 'center',
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "column",
    alignItems: "center",
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1.5rem",
    padding: "0.5rem 1rem",
    borderRadius: '0.4rem',
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "300",
    },
  },
  formfootertext: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    color: "#5E5E5E",
    fontWeight: "500",
    "& .MuiLink-root": {
      color: "#FF9213",
      cursor: "pointer"
    },
  },
  closebtncontainer: {
    position: "absolute",
    top: '60px',
    right: '-10px',
    '& .MuiButton-root': {
      minWidth: '0px',
    },
    [theme.breakpoints.down("xs")]: {
      top: "60px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: '30 ',
    height: "30 px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  otpinputcontainer: {
    margin: "3rem 1rem 2rem 0.2rem",
    [theme.breakpoints.down("xs")]: {
      margin: "3rem 0rem 2rem 0rem",
    },
  },
  marginleftright:{
    margin:'0 0.4rem'
  }
}));


const timerConst = 60;

function OtpModal(props) {
  const { handlesignupOpenclose, handleOtpOpenclose, otpverify, mobileNumber, forget, handleCreatepasswordOpenclose, success, forgot } = props;
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const AppContext = useContext(appContext);
  const [seconds, setSeconds] = useState(timerConst);
  const [code, setCode] = useState('');
  const classes = useStyles();
  const [otpAgain, setOtpAgain] = useState(false);


  useEffect(() => {
    if (seconds === 0) { setCode('') }
    const interval = setInterval(() => { if (seconds > 0) { setSeconds(seconds - 1); } }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, otpAgain, setSeconds]);

  const signInOTP = async () => {
    if (otp?.length === 4) {
      if (forget) {
        handleOtpOpenclose();
        handleCreatepasswordOpenclose();
        success(otp, mobileNumber);
      } else {
        try {
          var response = await signInOTPVerify({
            user: {
              phone: mobileNumber,
              otp: otp
            }
          });
          if (response.status === 200) {
            // Message is successful, no need to show toast
        } else {
            toast(response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                type: "error" // You might want to use a different type for unsuccessful messages
            });
        }
        
          AppContext.setUser(response?.data?.user);
          AppContext.setToken(response?.data?.token);
          await authorizeMe(response?.data?.token);
          localStorage.setItem('token', response?.data?.token);
          AppContext.setIsLoggedIn(true);
          handleOtpOpenclose();
        } catch (err) {
          toast(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            type: "error"
          });
        }
      }
      setOtpError(false);
    } else {
      setOtpError(true);
    }
  }

  const verify = async () => {
    if (otp?.length === 4) {
      try {
        var response = await verifyOtp({
          user: {
            phone: mobileNumber,
            otp: otp
          }
        });
        toast(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          type: "success"
        });
        AppContext.setUser(response?.data?.user);
        AppContext.setToken(response?.data?.token);
        await authorizeMe(response?.data?.token);
        localStorage.setItem('token', response?.data?.token);
        AppContext.setIsLoggedIn(true);
        handleOtpOpenclose();
      } catch (err) {
        toast(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          type: "error"
        });
      }

      setOtpError(false);
    } else {
      setOtpError(true);
    }
  }

  const reSendOtp = async () => {
    try {
      let response = await resendOtp({ user: { phone: mobileNumber } });
      setSeconds(timerConst);
      setOtpAgain(!otpAgain);
      setOtp('')
      toast('OTP Resend Successfully', {
        position: "top-right",
        autoClose: 5000,
        type: "success"
      });
    } catch (err) {
      toast(err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        type: "error"
      });
    }
  }
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <div className={classes.container}>
      <div className={classes.closebtncontainer}>
        <Button className={classes.closebtn} onClick={handleOtpOpenclose}>
          <GrFormClose />
        </Button>
      </div>
      <div className={classes.paper}>
        <Typography variant="h3" gutterBottom>
          <span className={classes.orangetext}>Enter</span> {otpverify ? 'Your OTP To Verify' : 'Your Passcode'}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          We’ve sent the code to the <b className={classes.marginleftright}>{ mobileNumber }</b> this mobile number
        </Typography>
        <Divider />

        <div className={classes.loginform}>
          <FormControl sx={{ m: 1 }}>
            <div className={classes.otpinputcontainer}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: !mql.matches ? "4rem" : "3.2rem",
                  height: !mql.matches ? "4rem" : "3.2rem",
                  margin: !mql.matches ? "0 0.8rem" : "0 0.4rem",
                  fontSize: "2rem",
                  borderRadius: 4,
                  border: "none",
                  fontFamily: "Jost",
                  fontWeight: "600",
                  boxShadow: "0px 3px 11px 0px rgba(0, 0, 0, 0.17) inset"

                }}
              />
            </div>
            {otpError && <p className='errorText ms-0 mt-2'> Please enter valid OTP</p>}

            <Typography variant="caption" display="block" gutterBottom>

              Code Expires in : <span id="count">  00:{seconds < 10 ? `0${seconds}` : seconds}</span>
            </Typography>


            <Button className={classes.bluecustombtn} fullWidth onClick={() => otpverify ? verify() : signInOTP()} style={seconds === 0 ? { marginBottom: 0 } : { marginBottom: 20 }}>
              Submit
            </Button>
          </FormControl>
          {seconds === 0 && <Typography
            className={classes.formfootertext}
            variant="caption"
            gutterBottom
          >
            Didn’t receive code?<Link onClick={() => { reSendOtp(); }}> Resend Code</Link>
          </Typography>}

        </div>
      </div>
    </div>
  );
}
export default OtpModal;
