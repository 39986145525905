import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import PageHead from "../pageheading/pageheading";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginBottom:"15rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding:'2rem 0',
    "& .MuiTypography-h4": {
      color: "#FA9D2B;",
    },
  },
  CancellationRefunds: {

    [theme.breakpoints.down("xs")]: {
      margin: "0 1rem",
    },
    "& .MuiTypography-body2":{
      color:'#000',
      fontWeight: "500",
      // fontFamily: 'normal',
      fontSize: "1rem",
    },

    "& .MuiTypography-h6":{
     marginTop:'2rem',
    },
    "& .MuiTypography-h5":{
      marginTop:'2rem',
     }

    
  
  },
}));
function ShippingPolicy() {
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore Cancellations & Refund Policy",
      headingmain: "Shipping Policy",
      breadcrumb:"Shipping Policy"
    },
  ];
  const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
       <PageHead Heading={Heading}/>
      <div className={classes.container}>
        <div className={classes.CancellationRefunds}>
        <Typography variant="h5" gutterBottom>
          <b>Shipping Policy</b>
         
        </Typography>
        <Typography variant="h6" gutterBottom>
        
          <b>Last updated: 15/12/2023</b>
        </Typography>
        <Typography variant="body2" gutterBottom>
        Thank you for shopping with us at ParikshaDo. We are committed to delivering an exceptional shopping experience, and this Shipping Policy outlines the terms and conditions governing the shipping of your orders.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>Shipping Locations</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
        We currently ship our products within India. Unfortunately, we do not offer international shipping at this time.
        </Typography>
        <Typography variant="h6" gutterBottom>
          <b>Shipping Methods and Timelines</b>
         
        </Typography>
        <Typography variant="h6" gutterBottom>
        
          <b>Standard Shipping</b></Typography>
          
            <ul>
              <li>We offer standard shipping services for all orders.</li>
              <li>Orders will typically be processed and dispatched within 2 business days.</li>
              <li>After dispatch, you can expect your order to arrive within 3 to 5 business days, depending on your location.</li>
            </ul>
            <Typography variant="body2" gutterBottom>
            Please note that shipping times may vary during peak seasons and public holidays. We will do our best to keep you informed of any delays.
        </Typography>
        
          <Typography variant="h6" gutterBottom>
        
        <b>Shipping Fees</b></Typography>
        
        <ul>
              <li>For all orders below Rs 1000, a flat shipping fee of Rs 30 will be applied.</li>
              <li>Orders with a total value of Rs 1000 or above will be eligible for FREE standard shipping.</li>
             
            </ul>
        
            <Typography variant="h6" gutterBottom>
        
        <b>Order Tracking</b></Typography>
        
        <ul>
              <li>Once your order has been dispatched, you will receive a tracking number via email.</li>
              <li>You can track the status of your order by visiting the "Track Order" page on our website and entering your tracking number.</li>
             
            </ul>
            <Typography variant="h6" gutterBottom>
        
        <b>Shipping Notifications</b></Typography>
        
          <ul>
            <li>We will send you email notifications to keep you informed about your order's status.</li>
            <li>You will receive an email confirmation when your order is placed.</li>
            <li>We will notify you when your order is shipped, along with the tracking information.</li>
          </ul>
          <Typography variant="h6" gutterBottom>
        
          <b>Address Information</b>
        </Typography>
        <Typography variant="body2" gutterBottom>
        Please ensure that you provide accurate and complete shipping information during the checkout process. Any inaccuracies or incomplete information may result in delays or non-delivery of your order. We will not be responsible for any such issues.
        </Typography>

        <Typography variant="h6" gutterBottom>
        
          <b>Returns and Exchanges</b>
        </Typography>
        <Typography variant="body2" gutterBottom>
        For information on our returns and exchanges policy, please refer to our <Link to="/cancellationrefunds"> Return Policy </Link> .
        </Typography>

        <Typography variant="h6" gutterBottom>
        
          <b>Contact Us</b>
        </Typography>
        <Typography variant="body2" gutterBottom>
        If you have any questions or concerns regarding our Shipping Policy, please do not hesitate to <Link to="/contactus"> contact us </Link> . Our customer support team will be happy to assist you.
        </Typography>
        </div>

      </div>
    </div>
  );
}
export default ShippingPolicy;
