import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import wallet from "../../image/dashboard/maindashboard/wallet.png";
// import rupeesvector from "../../image/dashboard/maindashboard/rupeesvector.png";
import walletcard from "../../image/dashboard/maindashboard/walletcard.png";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h6": {
      color: " #252525",
      fontFamily: "Playfair Display",
      fontSize: "1.375rem",
      fontWeight: 700,
      lineHeight: "2.625rem",
      display: "flex",
    },
    "& .MuiTypography-h5": {
      color: " #262626",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.375rem",
      display: "flex",
      justifyContent: "center",
      marginTop: "1.5rem",
    },
    "& .MuiTypography-h4": {
      color: "#353535",
      fontFamily: "Jost",
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
      display: "flex",
      justifyContent: "center",
      marginTop: "1rem",
    },
  },
  cardShadow: {
    boxShadow: '0px 6px 40px rgba(0, 0, 0, 0.10) !important',
  },
  line: {
    width: "27%",
    marginLeft: "16%",
    borderRadius: "0.5625rem",
    background:
      "linear-gradient(270deg, rgba(255, 128, 8, 0.60) 44.89%, rgba(255, 200, 55, 0.60) 100%)",
  },
  imagecontainer: {
    display:"flex",
    justifyContent:"center",
    marginTop:"1.5rem"
   
  },
}));

const MyWalletCard = ({Data}) => {
  const classes = useStyles();

  return (
    <>
      <Card className={`${classes.card} ${classes.cardShadow}`}>
        <CardContent>
          <Typography variant="h6">
            <img src={wallet} alt="img" /> &nbsp;&nbsp;&nbsp;&nbsp;My Wallet
          </Typography>
          <Divider className={classes.line} />
          <Typography variant="h5">Current Balance</Typography>
          <Typography variant="h4">
            ₹{Data?.my_wallet},000
          </Typography>
          <div className={classes.imagecontainer}>
            <img src={walletcard} alt="img" />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default MyWalletCard;
