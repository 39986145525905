import React from "react";
import { ReactComponent as MyPurchaseIcon } from "../../image/dashboard/MyPurchase.svg";
import girlteacher from "../../image/dashboard/girlteacher.png";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SSCBook from "../../image/dashboard/sscbookimage.png";
import AptitudeBook from "../../image/dashboard/aptitudebookimage.png";
import {
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CustomTableView from "../../CustomComponent/TableView";
import PurchaseTableMobile from "../MyPurchase/PurchaseTableMobile";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1.5rem",
    width: "65%",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "1rem 0.5rem",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "59%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  headicon: {
    marginRight: "0.8rem",
  },
  filtersection: {
    width: "25%",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "0.375rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filtericonandname: {
    background: "#FFF",
    display: "flex",
    boxShadow: "0px 6px 40px 0px rgba(0, 0, 0, 0.06)",
    padding: "0.5rem 1rem",
    borderRadius: "0.375rem",
    "& .MuiTypography-body1": {
      fontFamily: "Jost !important",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#444",
    },
  },
  filterinput: {
    width: "58%",
    "& .MuiInputBase-input": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#9A9A9A",
    },
    "& .MuiSelect-selectMenu": {
      padding: "0.8rem",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  cardcontainer: {
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  viewbtn: {
    "&.MuiButton-root": {
      color: "#303030",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
  activebtn: {
    "&.MuiButton-root": {
      color: "#fff",
      background: "#313866",
      textTransform: "capitalize",
      padding: "0rem 1rem",
      width: "60%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      border: "1px solid #EBEBEB",
      color: "#262626",
      background: "#FFF",
      textTransform: "capitalize",
      padding: "0rem 1rem",
      width: "60%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
  line: {
    width: "27%",
    marginLeft: "16%",
    borderRadius: "0.5625rem",
    background:
      "linear-gradient(270deg, rgba(255, 128, 8, 0.60) 44.89%, rgba(255, 200, 55, 0.60) 100%)",
  },
}));

function MyPurchaseSection({ setOnViewClicked, Data , learnerMenu , handleChange}) {
  let mql = window.matchMedia("(max-width: 650px)");
  console.log(Data, "datadata");
  const columns = [
    {
      field: "srno",
      headerName: "S.No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "orderid",
      headerName: "Order Id",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "billingamount",
      headerName: "Billing Amount",
      headerClassName: "super-app-theme--header",
      cellClassName: "totalcostcell",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <p>
            <span>₹</span>
            {cellValues.value}
          </p>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{cellValues.value}</div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <Button
            onClick={() => {
              setOnViewClicked("ordersummary", cellValues.row.id);
            }}
            className={classes.disabledbtn}
          >
            View
          </Button>
        );
      },
    },
  ];
  let rows;
  if (Data && Data.mypurchases && Data.mypurchases.length > 0) {
    rows = Data.mypurchases.flatMap((purchase , index) => ({
      id: purchase.id || null,
      srno: index + 1,
      image: purchase.product_data?.image_url || null,
      productname: purchase.product_data?.product_name || null,
      code: purchase.product_data?.product_code || null,
      billingamount: purchase?.grand_total_cost || null,
      // date: purchase.delivery_date,
      date: purchase?.created_at
        ? new Date(purchase?.created_at).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          })
        : "",
      orderid: purchase?.order_id || null,
      status: purchase?.razorpay_payment_status || null,
      type: "active",
    }));
  } else {
    // Handle the case where Data is undefined or empty
    rows = [];
  }

  const classes = useStyles();

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <MyPurchaseIcon className={classes.headicon} />
          <Typography variant="h3">My Orders</Typography>
        </div>
        <div className={classes.btncontainer}>
          <Button className={classes.viewbtn}  onClick={() => {handleChange(8)}}>
            View All <KeyboardArrowRightIcon style={{ fontSize: "medium" }} />
          </Button>
        </div>
      </div>
      <Divider className={classes.line} />
      <div>
        {!mql.matches ? (
          
          <CustomTableView
            Data={Data}
            columns={columns}
          rows={rows}
          section={"myPurchaseTable"}
          />
        ) : (
          rows.map((data, index) => (
            <PurchaseTableMobile
              setOnViewClicked={setOnViewClicked}
              data={data}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default MyPurchaseSection;
