import { makeStyles } from "@material-ui/core";
import React from "react";
import TeacherSectionContent from "./teacherscontent";
import SecHead from "../sectionHeading/sectionheading";
import Vector1 from "../../image/bodyvector/vector1.png";


const useStyles = makeStyles((theme) => ({
  TeachersMain: {
    fontFamily: "'Playfair Display', serif !important",
  },
  containerfluid: {
    width: "100%",
    // marginTop: '3rem',
    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },

    "& .slick-prev:before, .slick-next:before": {
      position: "absolute",
      fontSize: "55px",
      borderRadius: "40px",
      top: "20px",
      left: "15px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0.5rem",

    },

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },

  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    paddingBottom: '4.5rem',
    [theme.breakpoints.down("xs")]: {
      paddingBottom: '2.8rem',
      },
  },
  secheadmain: {
    display: "flex",
    justifyContent: 'space-evenly',
  },
  headvector: {
    width: "20%",
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
function TeacherSectionMain() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Unleashing Excellence",
      headingmain: "The Best Teaching Experts",
    },
  ];
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <>
      <div className={classes.TeachersMain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
          <div className={classes.secheadmain}>
              <div className={classes.headvector}>
                {/* <img src={Vector3} alt="img" /> */}
              </div>
              <SecHead Heading={Heading} media={mql.matches} />
              <div className={classes.headvector}>
                {/* <img src={Vector2} alt="img" /> */}
              </div>
            </div>
            <TeacherSectionContent />
          </div>
        </div>
      </div>
    </>
  );
}
export default TeacherSectionMain;
