import { useReducer } from "react";
import Reducer from "./reducer";
import Context from "./index";
import {
  getQuizQuestionsByQuizId,
  postAttemptQuiz,
} from "../../../../service/services";
import {
  GET_QUIZ_QUESTIONS,
  SET_CURRENT_QUESTION,
  SET_QUESTION_ANSWER,
  SET_TIME_TAKEN
} from "./type";



const State = (props) => {
  let initialState = {
    quizQuestionObj: null,
    currentQuestion: 1,
    timeTaken: { minute: 0, second: 0 },
    countdown : 0
  };
  const [state, dispatch] = useReducer(Reducer, initialState);

  const getQuizQuestion = async (id) => {
    const res = await getQuizQuestionsByQuizId(id);
    dispatch({ type: GET_QUIZ_QUESTIONS, payload: res?.data?.quiz });
  };

  const setCurrentQuestion = async (questionNumber) => {
    dispatch({ type: SET_CURRENT_QUESTION, payload: questionNumber });
  };

  const setAnswer = async (answerNumber) => {
    dispatch({ type: SET_QUESTION_ANSWER, payload: answerNumber });
  };

  const setTimeTaken = async (minute, second) => {
    dispatch({ type: SET_TIME_TAKEN, payload: { minute, second } });
  };

 
  return (
    <Context.Provider
      value={{
        quizQuestionObj: state.quizQuestionObj,
        currentQuestion: state?.currentQuestion,
        timeTaken: state?.timeTaken,
        getQuizQuestion,
        setCurrentQuestion,
        setAnswer,
        setTimeTaken
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default State;
