import BiometricImage1 from "../image/biometricvector/tap.png";
import BiometricImage3 from "../image/biometricvector/face.png";

const TrustSectionData = [
    {
      id: 1,
      iconName: BiometricImage1,
      heading: "Tap",
      subheading: "Fingerprint-based biometric solutions ",
    },
    {
      id: 2,
      iconName: BiometricImage1,
      heading: "Look",
      subheading: "IRIS-based biometric solution ",
    },
    {
      id: 3,
      iconName: BiometricImage3,
      heading: "Face",
      subheading: "Facial recognition based biometric solutions ",
    },
  ];

  export default TrustSectionData ;