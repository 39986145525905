import React from "react";
import { Button, IconButton, Typography, makeStyles } from "@material-ui/core";
import Logo from "../image/wishlistvector/wishlistimage.png";
import StarIcon from "@material-ui/icons/Star";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { ToggleButton } from "@material-ui/lab";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import WishlistCard from "./wishlistCard";
import { useContext } from "react";
import wishlistContext from "../../context/WishlistContext/WishlistContext";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    background: "#FFFBF1",
    marginBottom: '10rem',
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem",
      '& .MuiTypography-body1':{
        fontSize: '0.9rem',
        textAlign: 'center',
      },
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    padding: "2rem 4rem",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0rem",
    },
    "& .MuiToggleButton-root.Mui-selected": {
      background: "#FFEEE8",
      color: "#FF3939",
    },
    "& .MuiToggleButton-root": {
      background: "#FFEEE8",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
  head: {
    // margin: "2rem 4rem",
    color: "#4E5566",
    padding: "0.9rem 1.5rem",
    background: "#F9F9F9",
    border: "1px solid var(--gray-100, #E9EAF0)",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      border: "0",
      padding: "0.9rem 0.5rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.8rem",
      textTransform: "uppercase",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9rem",
        fontWeight: "600",
        justifyContent: "space-between",
      },
    },
  },
  head_course: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "33.33%",
      textAlign: "left",
    },
  },
  head_price: {
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  head_action: {
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      width: "33.33%",
      textAlign: "right",
    },
  },

  backbtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    margin: "2rem 0 2rem 0.5rem",
    padding: "0.8rem 6rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 3rem",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
  },
  notFoundData: {
    display: "flex",
    justifyContent: "center",
    flexDirection:"column",
    alignItems:"center",gap:"10px",marginTop:"10px",
    "& img":{
      width : "30%"
    }
  },
  btn:{
    display: 'flex',
    justifyContent: 'center',
  },
}));
function WishListContent() {
  const classes = useStyles();
  const { wishList, removefromWishListAPI } = useContext(wishlistContext);
  const renderDataAgain = (id) => removefromWishListAPI(id);
  const location = useLocation();
  useEffect(() => {}, [wishList]);

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>

        {wishList?.length > 0 ? (
          <div>
          <div className={classes.head}>
          <div className={classes.head_course}>
            <Typography variant="caption">Course</Typography>
          </div>
          <div className={classes.head_price}>
            <Typography variant="caption">Prices</Typography>
          </div>
          <div className={classes.head_action}>
            <Typography variant="caption">Action</Typography>
          </div>
        </div>
        { wishList?.map((data) => (
           <>
       
            <WishlistCard data={data} renderDataAgain={renderDataAgain} />
           </>
           
          ))}
        </div>

        ) : (
          <div className={classes.notFoundData}>
            <img src={require("../image/notFoundWishlist.png")} />
            <Typography variant="h5">Add Product to your Wishlist</Typography>
            <Typography variant="body1">
              Browse to the books page to get more products on your wishlist
            </Typography>
            <Link to={wishList?.length > 0 ? location?.state?.prevPath : '/'}>
            <Button className={classes.backbtn}>Explore</Button>
          </Link>
          </div>
        )}
      </div>
    </div>
  );
}
export default WishListContent;
