import React from "react";
import { makeStyles } from "@material-ui/core";
import ProductDetails from "./productdetails";
import SimpleTabs from "./tabpannel";
import FAQSection from "./FAQSection";
import CoveredExamSection from "./CoveredExamSection";
import FreeMocksSection from "./FreeMocksSection";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    width: "100%",
    background: "#FFFCF4",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto 12rem",
    "& .MuiAppBar-colorPrimary": {
      background: "none",
      color: "#000000",
    },
  },
}));
function SinglePageContent({ testseriesDetail }) {
  const classes = useStyles();
  console.log("testseriesDetail", testseriesDetail);
  if (!testseriesDetail) return null;
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div>
          <ProductDetails testseriesDetail={testseriesDetail} />
        </div>
        <CoveredExamSection
          testseriesDetail={testseriesDetail?.covered_exams}
        />
        {testseriesDetail?.free_mocks.length > 0 && (
          <FreeMocksSection
            testseriesDetail={testseriesDetail?.free_mocks}
            bundleId={testseriesDetail?.id}
          />
        )}

        <SimpleTabs testseriesDetail={testseriesDetail} />
        <FAQSection testseriesDetail={testseriesDetail}/>
      </div>
    </div>
  );
}
export default SinglePageContent;
