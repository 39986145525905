import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Slider from "react-slick";
import React from "react";
import Data from "./prepractionsectioncdata";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 347,
    margin: "0 auto",
  },
  media: {
    width: "210px",
    height: "210px",
  },
  preprationsectionCard: {
    justifyContent: "space-evenly",
    marginTop: "3rem",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "& .MuiCardActions-root": {
      padding: "0",
      width: "100%",
      background:
        " linear-gradient(90deg, rgba(255,128,8, 0.3199999928474426) 0%, rgba(255,200,55, 0.3199999928474426) 100%)",
    },
    "& .MuiCard-root": {
      width: "100%",
      margin: "1rem 0",
      alignItems: "center",
      display: "flex !important",
      height: "380px",
      flexDirection: "column",
      justifyContent: "space-between",
      background: "#FFF7E4",
      "&:hover": {
        boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
        cursor: "pointer",
        background: "linear-gradient(270deg, #FFF4D7 0%, #FEE5CD 100%)",
        "& .MuiCardActions-root": {
          background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%);",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  explorebtn: {
    width: "100%",
    padding: "10px",
  },
}));
function PreprationSectionContent() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.preprationsectionCard}>
        <Slider {...settings}>
          {Data.map((data) => (
            <Card id={data.id} className={classes.root} align="center">
              <CardMedia
                className={classes.media}
                image={data.cardimage}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography variant="h5" component="h2">
                  {data.cardheading}
                </Typography>

                <Typography variant="body2" component="p">
                  {data.carddiscription}
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={classes.explorebtn}>Explore Courses</Button>
              </CardActions>
            </Card>
          ))}
        </Slider>
      </div>
    </>
  );
}
export default PreprationSectionContent;
