import React from "react";
import { Avatar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
const useStyles = makeStyles((theme) => ({
  main: {
    width: "75%",
    padding: "1rem",
    borderRadius: "10px",
    border: "1px solid #B8B8B8",
    marginLeft: "2rem",
    "& .MuiTypography-h3": {
      color: "#000 !important",
      fontFamily: "Jost !important",
      fontSize: "15px !important",
      fontWeight: "600 !important",
    },
    "& .MuiTypography-h4": {
      color: "#000 !important",
      fontFamily: "Jost !important",
      fontSize: "20px !important",
      fontWeight: "700 !important",
    },
    "& .MuiTypography-h5": {
      color: "#545454",
      fontFamily: "Jost",
      fontSize: "15px",
      fontWeight: 400,
    },
    "& .MuiTypography-h6": {
      color: "#545454 !important",
      fontFamily: "Jost !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      marginLeft: "0.3rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0 !important",
    },
  },
  imgplusname: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  image: {
    padding: "3px",
    border: "1px solid #C9CEEE",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  deliverycharge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  address: {
    display: "flex",
    marginTop: ".5rem",
  },
  email: {
    display: "flex",
    marginTop: ".5rem",
  },
  contact: {
    display: "flex",
    marginTop: ".5rem",
  },
  shippingaddress: {
    display: "flex",
    flexDirection: "column",
    marginTop: ".5rem",
    "& .MuiTypography-h4": {
      color: "#000",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 600,
      textAlign: "left !important",
    },
    "& .MuiTypography-h6": {
      color: "#545454",
      fontFamily: "Jost",
      fontSize: "13px",
      fontWeight: 300,
      marginTop: ".5rem",
      textAlign: "left !important",
    },
  },
  Fedeximgplustex: {
    display: "flex",
    marginTop: ".5rem",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  fedeximg: {
    width: "17%",
  },
}));

function CustomerDetail({ orders }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.main}>
        <div className={classes.imgplusname}>
          <div className={classes.image}>
            {/* <img src={orders?.user?.image_url} alt="img" /> */}
            {orders?.user?.image_url ? (
              <Avatar src={orders?.user?.image_url} alt="img" />
            ) : (
              <PersonIcon />
            )}
          </div>
          <Typography variant="h4">{orders?.user?.full_name}</Typography>
        </div>
        <div className={classes.email}>
          <Typography variant="h5">Email: </Typography>
          <Typography variant="h6">{orders?.user?.email}</Typography>
        </div>

        <div className={classes.contact}>
          <Typography variant="h5">Contact:</Typography>{" "}
          <Typography variant="h6"> {orders?.user?.phone}</Typography>
        </div>
        {orders?.user?.delivery_address && (
          <div className={classes.shippingaddress}>
            <Typography variant="h3"> Shipping Address</Typography>

            <Typography variant="h6">
              {orders?.user?.delivery_address}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomerDetail;
