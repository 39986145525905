import { Button, Card, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BannerLogo from "../image/testseriesvector/bannerlogo.png";
import { ReactComponent as CartIcon } from "../image/testseriesvector/carticon.svg";
import SecHead from "../Home/sectionHeading/sectionheading";
import RecommendedTestSeriesCard from "./RecommendedTestSeriesCard";
import TestSeriesCardMobile from "./TestSeriesCardMobile";
import ExclusiveTestseriesCard from "../Home/exclusivebooks/ExclusiveTestseriescard";
import Slider from "react-slick";
import BookImg from "../image/testseriesvector/testseriescardimage.png";
import { getRecomndadTestseries } from "../../service/services";
import RecommendedTestSeriesCardDash from "./MyTestSeriesDash/RecommendedTestSeriesCardDash";
import TestSeriesCardMobileDash from "./MyTestSeriesDash/TestSeriesCardMobileDash";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3rem",
    "& .slick-slide": {
      display: "flex",
      // width: "16.5% !important",
      height: '420px',
      justifyContent: "center",
      paddingTop: "0.5rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      '& div':{
        width:'100%',
      },
      [theme.breakpoints.down("xs")]: {
        padding: '2rem 1.5rem 2rem 1.5rem !important',
      height: '506px',
    },
    },
    '& .slick-dots': {
      [theme.breakpoints.down("xs")]: {
        bottom: '17px',
    },
   
  },
    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
  cardContainer: {
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

function RecommendedTestSeries() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Mock Test as per your Preference",
      headingmain: "Recommended Test Series",
    },
  ];

  const CardData = [
    {
      id: 1,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
    {
      id: 2,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
    {
      id: 3,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
    {
      id: 4,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
    {
      id: 5,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
    {
      id: 6,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
    {
      id: 7,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
    {
      id: 8,
      bookname: "SSC Maha Pack",
      subtitle: "SSC CGL, CHSL, CPO",
      dropedprice: "50.00",
      orignalprice: "₹80.00",
      totaltestseries: "2k",
      validity: "6months",
      bookimage: BookImg,
      offerpercent: "30% off",
      tag: "NEW LAUNCH",
    },
  ];

  let mql = window.matchMedia("(max-width: 650px)");
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getRecomndadTestseries(token);
        setData(response.data?.testSeriesBundle);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const uniqueData = Array.from(new Set(data.map((item) => item.id))).map(
    (id) => {
      return data.find((item) => item.id === id);
    }
  );

  const initialSlidesToShow = window.innerWidth < 480 ? 1 : 4;
  
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: initialSlidesToShow,
    slidesToScroll: initialSlidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow: newSlidesToShow,
        slidesToScroll: newSlidesToShow,
      }));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={classes.container}>
      <SecHead Heading={Heading} className={classes.mainHeading} />
      <div className={classes.cardContainer}>
        {/* {!mql.matches ? (
          <>
          {uniqueData.map((item, index)=>(
            <ExclusiveTestseriesCard data={item} key={index}/>
          ))}
            
          </>
        ) : (
          <>
            <TestSeriesCardMobileDash Data={data}/>
          </>
        )} */}
        <Slider {...sliderSettings} className={classes.sliderstyle}>
          {uniqueData.map((item, index) => (
            <ExclusiveTestseriesCard data={item} key={index} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default RecommendedTestSeries;
