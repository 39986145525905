// import React from "react";
// import { Button, makeStyles, Typography } from "@material-ui/core";
// import PopupBackgroundImage from "../image/allpopups/popupcard.png";
// import { GrFormClose } from "react-icons/gr";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     minHeight: "100vh",
//     padding: "10px",
//   },
//   backgroundCard: {
//     background: `url(${PopupBackgroundImage})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     width: "auto",
//     height: "auto",
//     borderRadius: "6px",
//     padding: "3rem 3rem",
//     textAlign: "center",
//     position: "relative",
//     [theme.breakpoints.down("sm")]: {
//       padding: "2rem 1rem",
//     },
//   },

//   closeButton: {
//     position: "absolute",
//     top: "-8px",
//     right: "-8px",
//     backgroundColor: "white",
//     borderRadius: "50%",
//     width: "40px",
//     height: "40px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   [theme.breakpoints.down("sm")]: {
//     backgroundCard: {
//       height: "auto",
//     },
//   },
//   heading: {
//     color: "#243B55",

//     fontFamily: " Playfair Display",
//     fontSize: " 1.25rem",
//     fontWeight: "700",
//     textAlign: "left",
//   },
//   heading1: {
//     color: "#243B55",

//     fontfamily: "Playfair Display",
//     fontsize: "0.9375rem",
//     fontweight: "600",
//     textAlign: "left",
//     marginTop: "1.5rem",
//   },
//   link: {
//     borderRadius: " 0.375rem",
//     border: "2px solid #E5E5E5",
//     color: "#585858",

//     fontFamily: "Jost",
//     fontSize: "1rem",
//     fontWeight: "400",
//     padding: "0.5rem",
//   },
//   btn: {
//     color: "#FFF",
//     background: "#29A71A",

//     fontFamily: "Jost",
//     fontSize: " 0.875rem",
//     fontWeight: "500",
//     marginTop: "1rem",
//     float: "left",
//   },
// }));

// const Studentreferlink = (props) => {
//   const { handleOpenclose } = props;
//   const classes = useStyles();
//   const shareLink = async () => {
//     try {
//       await navigator.share({
//         title: "Share Link",
//         text: "Check out this link!",
//         url: "https://www.parikshado.com/s/50 8/lukefe25",
//       });
//     } catch (error) {
//       console.error("Error sharing link:", error);
//     }
//   };
//   return (
//     <>
//       <div className={classes.container}>
//         <div className={classes.backgroundCard}>
//           <div className={classes.closeButton}>
//             <Button onClick={handleOpenclose}>
//               <GrFormClose />
//             </Button>
//           </div>

//           <Typography className={classes.heading}>
//             Refer this link to your friends
//           </Typography>
//           <Typography className={classes.heading1}>Share Link</Typography>
//           <div className={classes.link}>
//             {" "}
//             https://www.parikshado.com/s/50 8/lukefe25
//           </div>
//           <Button className={classes.btn}onClick={shareLink}>Share link</Button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Studentreferlink;

import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import PopupBackgroundImage from "../image/allpopups/popupcard.png";
import { GrFormClose } from "react-icons/gr";
import { WhatsApp, Facebook, Telegram, Instagram, Email, Twitter } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "10px",
  },
  backgroundCard: {
    background: `url(${PopupBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "auto",
    height: "auto",
    borderRadius: "6px",
    padding: "3rem 3rem",
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 1rem",
    },
  },
  closeButton: {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    backgroundColor: "white",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    backgroundCard: {
      height: "auto",
    },
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.25rem",
    fontWeight: "700",
    textAlign: "left",
  },
  heading1: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "0.9375rem",
    fontWeight: "600",
    textAlign: "left",
    marginTop: "1.5rem",
  },
  link: {
    borderRadius: "0.375rem",
    border: "2px solid #E5E5E5",
    color: "#585858",
    fontFamily: "Jost",
    fontSize: "1rem",
    fontWeight: "400",
    padding: "0.5rem",
  },
  // btn: {
  //   color: "#FFF",
  //   background: "#29A71A",
  //   fontFamily: "Jost",
  //   fontSize: "0.875rem",
  //   fontWeight: "500",
  //   marginTop: "1rem",
  //   marginRight: "0.5rem",
  // },
  btn: {
    color: "#FFF",
    background: "#29A71A",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginTop: "1rem",
    marginRight: "0.5rem",
    "&:hover": {
      background: "#1e7e34", // Change this color to the desired hover color
    },
  },
  icon: {
    fontSize: "1.5rem",
    marginRight: "0.5rem",
  },
}));

const Studentreferlink = (props) => {
  const { handleOpenclose } = props;
  const classes = useStyles();

  const shareToWhatsApp = () => {
    const url = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.open(`https://api.whatsapp.com/send?text=${url}`);
  };

  const shareToFacebook = () => {
    const url = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
  };

  const shareToTelegram = () => {
    const url = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.open(`https://t.me/share/url?url=${url}`);
  };

  const shareToInstagram = () => {
    const url = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.open(`https://www.instagram.com/?url=${url}`);
  };

  const shareToEmail = () => {
    const subject = encodeURIComponent("Check out this link!");
    const body = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const shareToTwitter = () => {
    const url = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.open(`https://twitter.com/intent/tweet?url=${url}`);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.backgroundCard}>
          <div className={classes.closeButton}>
            <Button onClick={handleOpenclose}>
              <GrFormClose />
            </Button>
          </div>

          <Typography className={classes.heading}>
            Refer this link to your friends
          </Typography>
          <Typography className={classes.heading1}>Share Link</Typography>
          <div className={classes.link}>
            {" "}
            https://www.parikshado.com/s/50 8/lukefe25
          </div>
          <Button className={classes.btn} onClick={shareToWhatsApp}>
            <WhatsApp className={classes.icon} />
          </Button>
          <Button className={classes.btn} onClick={shareToFacebook}>
            <Facebook className={classes.icon} />
          </Button>
          <Button className={classes.btn} onClick={shareToTelegram}>
            <Telegram className={classes.icon} />
          </Button>
          <Button className={classes.btn} onClick={shareToInstagram}>
            <Instagram className={classes.icon} />
          </Button>
          <Button className={classes.btn} onClick={shareToEmail}>
            <Email className={classes.icon} />
          </Button>
          <Button className={classes.btn} onClick={shareToTwitter}>
            <Twitter className={classes.icon} />
          </Button>
          {/* Add more buttons and corresponding functions for other platforms */}
        </div>
      </div>
    </>
  );
};

export default Studentreferlink;

