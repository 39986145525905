
import React from 'react'
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from "@material-ui/core";
import DateRangeIcon from '@material-ui/icons/DateRange';
import notificationdatalogo from "../../component/image/notification/notificationdatalogo.png"


const useStyles = makeStyles((theme) => ({
    notitool:{
        background:"white",
        color:"black",
        fontSize:"25px",
        fontWeight:600 ,
        [theme.breakpoints.down("xs")]: {
          fontSize: '18px',
        },
        
    },
    notibox:{
  
    }
  
   
  }));
  

const HeaderNotification = (props) => {
    const classes = useStyles();
  return (
   <>
    <Box className={classes.notibox}>
          <AppBar position="static">
            <Toolbar className={classes.notitool}>
              <img src={notificationdatalogo} alt="logo"/>
               &nbsp;&nbsp;
              {props.data}
            </Toolbar>
          </AppBar>
        </Box>

   </>
  )
}

export default HeaderNotification
