import React, { useContext, useEffect, useState } from "react";

import { ReactComponent as FavoriteChecked } from "../image/ebooksvector/favoritechecked.svg";
import { ReactComponent as FavoriteUnchecked } from "../image/ebooksvector/favoriteunchecked.svg";

import wishlistContext from "../../context/WishlistContext/WishlistContext";
import appContext from "../../context/AppContext";
import popupContext from "../../context/PopupContext/PopupContext";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";

function FavouriteButton({ isCheckedHeart, data, setIsCheckedHeart }) {
  const {
    wishList,
    wishlistState,
    clearWishlistError,
    addToWishListAPI,
    removefromWishListAPI,
  } = useContext(wishlistContext);
  const { handleloginOpenclose } = useContext(popupContext);
  const { isLoggedIn, token } = useContext(appContext);

  const wishListedProduct =
    wishList?.find((el) => Number(el?.product_id) === data?.id) || {};

    // console.log('wishlistState', wishlistState)
    // useEffect(() => {
    //   if (wishlistState.cart) {
    //     toast.success(wishlistState.cart.successMessages || "Added");
    //   }
    // }, [wishlistState.cart?.success]);
    
    // useEffect(() => {
    //   if (wishlistState.hasError && wishlistState.currentProductId === data.id) {
    //     toast.error(wishlistState.errorMessage || "Error");
    //   }
    // }, [wishlistState.hasError]);
    

  return (
    <>
      {wishListedProduct?.id ? (
        <span
          onClick={(e) => {
            e.stopPropagation();
            if (isLoggedIn && token) {
              removefromWishListAPI(wishListedProduct?.id);
              setIsCheckedHeart(false);
            } else {
              handleloginOpenclose();
            }
          }}
        >
          <FavoriteChecked />
        </span>
      ) : (
        <span
          onClick={(e) => {
            e.stopPropagation();
            if (isLoggedIn && token) {
              addToWishListAPI(data);
              setIsCheckedHeart(true);
            } else {
              handleloginOpenclose();
            }
          }}
        >
          <FavoriteUnchecked />
        </span>
      )}

      {/* Display alert for errors */}
      {/* {wishlistState.hasError && wishlistState.currentProductId === data.id && (
        <Snackbar
          open={wishlistState.hasError}
          autoHideDuration={2000}
          onClose={() => clearWishlistError()}
        >
          <Alert onClose={() => clearWishlistError()} severity="error">
            {wishlistState.errorMessage}
          </Alert>
        </Snackbar>
      )} */}
    </>
  );
}

export default FavouriteButton;
