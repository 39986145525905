import React, { useEffect, useState } from "react";
import { ReactComponent as SidebarHeadIcon } from "../image/quizzesvector/sidebarheadicon.svg";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ReactComponent as Arrow } from "../image/quizzesvector/arrow.svg";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { getJobNotification } from "../../service/services";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  examscontainer: {
    width: "100%",
    // height: "421px",
    height: "auto",
  },
  examshead: {
    display: "flex",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    padding: "0.4rem 1rem",
    borderRadius: "0.3rem",
    color: "white",
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display",
      color: "#FFFFFF",
      fontSize: "1.125rem",
      marginLeft: "0.5rem",
    },
  },
  examslist: {
    background: "linear-gradient(270deg, #FFF4D7 0%, #FEE5CD 100%)",
    display: "inline-block",
    width: "inherit",
    height: "inherit",
    overflow: "auto",
    borderRadius: "0.3rem",
    padding: "0.5rem 0",
    // lineHeight: "50px",
    "& .MuiListItemIcon-root": {
      // paddingLeft: "2.5rem",
      minWidth: "0",
    },
    "& .MuiTypography-body1": {
      textTransform: "capitalize",
      fontSize: "0.875rem",
    },
    "& .Mui-selected": {
      background: "rgba(255, 255, 255, 1)",
      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
    },
    "& .MuiList-padding": {
      padding: "0rem 1rem",
    },
    "& .MuiListItem-root": {},
  },
}));

const JobNotification = ({
  name,
  Notification,
  value,
  setValue,
  a11yProps,
}) => {
  const classes = useStyles();
  const [notData, setNotData] = useState([]);

  useEffect(() => {
    setNotData(Notification);
    console.log(Notification);
    console.log(name);
    console.log(value,"value")
  }, [Notification,value]);
const [id,setId]=useState();
useEffect(()=>{
setId(value)
},[id])
  const navigate = useNavigate();

  let handleChange = (event, newValue) => {
    console.log("Updating URL parameter:", newValue);
    navigate(`/notification/${newValue}`);

    setValue(newValue);
  };

  console.log(notData);
  const handleListItemClick = (itemId) => {
    console.log(`Item clicked with ID: ${itemId}`);
    navigate(`/notificationsinglepage/${itemId}/${value}`);
  };

  return (
    <>
      <div className={classes.examscontainer}>
        <div className={classes.examshead}>
          <div>
            <SidebarHeadIcon />
          </div>
          <Typography variant="h6">
            {name ? name : "Notification Category"}
          </Typography>
          { console.log('nodata' , notData)}
        </div>
        <div className={classes.examslist}>
          <List component="nav" aria-label="secondary mailbox folders">
            {notData?.slice(0, 10).map((data, index) =>
                data?.notifications?.length > 0 &&
                data.category_name?.length > 0 ? (
                  // <ListItem key={data.id} button >
                  //   <ListItemText
                  //   value={value}
                  //   selected={value === data.id}
                  //     primary={data.category_name}
                  //     onClick={(event) =>
                  //       handleChange && handleChange(event, data.id)
                  //     }
                  //   />
                  //   <ListItemIcon>
                  //     <Arrow />
                  //   </ListItemIcon>
                  // </ListItem>
                  <ListItem 
  key={index} 
  button 
  selected={data.id == id}
  onClick={(event) => handleChange && handleChange(event, data.id)}
>
  <ListItemText primary={data.category_name} />
  <ListItemIcon>
    <Arrow />
  </ListItemIcon>
</ListItem>

                ) : (
                  <ListItem
                    key={index}
                    button
                    selected={data.category_name ? value === data.id : false}
                    style={{ padding: 0, margin: 0 }}
                  >
                    {data?.name && (
                      <>
                        <ListItemText
                          primary={data.name}
                          onClick={() => handleListItemClick(data.id)}
                        />
                        <ListItemIcon>
                          <Arrow />
                        </ListItemIcon>
                      </>
                    )}
                  </ListItem>
                )
              )}
          </List>
        </div>
      </div>
    </>
  );
};

export default JobNotification;
