import React from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import OurMission from "../image/mentorvector/teacher.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    marginTop: "4rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  onlineeducationcontent: {
    width: "60%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "block",
      padding: "0rem 1rem",
      marginTop: "4.5rem",
    },
  },
  onlineeducationimgcontainer: {
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "1rem 3rem",
    },
  },
  InfoButton: {
    position: "flex",
    bottom: "2.5rem",
    width: "232px",
    height: "50px",
    left: "0.1rem",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "#fff",
    border: "3px",
    fontWeight: "400",
    fontSize: "16px",
    padding: "0.8rem 1.2rem",
    fontFamily: "'Jost', sans-serif",
    borderRadius: "5px",
    transition: "background 0.3s ease",
    display: "flex",
    marginTop: "75px",
    alignItems: "center",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "58px",
      width: "100%",
    },
  },
  content: {
    marginTop: "2rem",
  },
  onlineeducationcontentinner: {
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
      fontSize: "2rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
        marginTop: "-3rem",
      },
    },
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#7E7E7E",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        textAlign: "justify",
      },
    },
  },
}));
function WelcomeSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "join us and make a difference.",
      headingmain: "Welcome To Pariksha DO",
    },
  ];
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationimgcontainer}>
            <img src={OurMission} alt="img" width="100%" />
          </div>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <Typography variant="h5" gutterBottom>
                "Online Teaching Excellence Starts Here - Join Today! "
              </Typography>
              <div className={classes.content}>
                <Typography variant="body2" gutterBottom>
                  When We Are Dedicated To Manage And support You To Be The Best
                  Coaching Institute. Coaching Like Yours have A Very Good
                  Potential And A very hardworking And Dedicated Students. To
                  Test There Skills And Their Knowledge Better. Join Us To
                  Become test Ready. Like Yours Have A very Good Potential And A
                  Very Hardworking And Dedicated Student. To Test There Skills
                  And Their Knowledge Better Join Us To Become Test Ready.
                </Typography>
                <Button className={classes.InfoButton}>GET CONNECTED</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WelcomeSection;
