import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import MentorCard from "../image/mentorvector/mentorcard.png";
import Divider from '@material-ui/core/Divider';
import { Button, FormControl, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop:"4rem",
    backgroundColor: "#FFF5DF",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
  },
  container: {
    margin: "0 auto",
    padding: "4rem 0",
    maxWidth: "1250px",
  },
  innerContainer: {
    display: "flex",
    backgroundColor: "#FFF",
    marginTop: "3rem",
    padding: "0 4rem",
    boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "0.1rem 1rem",
      margin: "2rem",
      borderRadius: "6px",
    },
    "& .MuiTypography-h2": {
      color: "#16283F",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.875rem",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "2.25rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#333",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "1.5rem",
      marginLeft: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      transition: "none",
      border: "none",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline": {
      border: "2px solid #E5E5E5",
      borderRadius: "1.875rem",
      padding: "0.6rem 2rem",
      fontFamily: "'Jost', sans-serif",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiButton-fullWidth": {
      padding: "0.6rem",
      borderRadius: "5rem",
      boxShadow: "none",
      background: "#FFB360",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
    },
  },
  form: {
    width: "50%",
    margin: "4rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "2rem 0",
    },
  },
  firstandlastname: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  input_sm: {
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "1.5rem",
    },
  },
  email: {
    margin: "2.5rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0 3rem 0",
    },
  },
  divider: {
    margin: "5rem 4rem 5rem 3rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  imagesection: {
    width: "48%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& img": {
      width: "78%",
      scale: "1.5",
      marginBottom:"56px",
    },
  }
  
}));

function RegistrationSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "join us and make a difference.",
      headingmain: "Register As a Mentor",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <div className={classes.innerContainer}>
          <div className={classes.form}>
            <FormControl sx={{ m: 1, width: "25ch" }}>
              <Typography variant="h2" display="block" gutterBottom>
                * Your Basic Information
              </Typography>
              <div className={classes.firstandlastname}>
                <div className={classes.input_sm}>
                  <Typography variant="h6" gutterBottom>
                    Full Name
                  </Typography>
                  <TextField
                    id="fullname"
                    type="text"
                    placeholder="Andrew"
                  />
                </div>
                <div className={classes.input_sm}>
                  <Typography variant="h6" gutterBottom>
                    Phone Number 
                  </Typography>
                  <TextField
                    id="phonenumber"
                    type="text"
                    placeholder="(+91)  8985-550- 114"
                  />
                </div>
              </div>
              <div className={classes.email}>
                <Typography variant="h6" gutterBottom>
                  E-Mail Address 
                </Typography>
                <TextField
                  id="email"
                  type="email"
                  placeholder="Andrewfate00231@gmail.com"
                />
              </div>
              <div className={classes.submitbtn}>
                <Button variant="contained" size="medium" fullWidth >Submit</Button>
              </div>
            </FormControl>
          </div>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <div className={classes.imagesection}>
            <img src={MentorCard} alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationSection;
