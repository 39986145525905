import React, { useContext, useEffect, useState } from "react";
import QuestionBox from "./questionbox";
import { useParams } from "react-router-dom";
import practiceContext from "../context/FreeMockPracticeContext";
import appContext from "../../../context/AppContext";
import { postAttemptQuiz } from "../../../service/services";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  quizzescontainer: {
    height: "100vh",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      height: "92vh",
    },
  },
}));

function Quizexam() {
  const classes = useStyles();
  const { getQuizQuestion } = useContext(practiceContext);
  const { id } = useParams();
  const AppContext = useContext(appContext);
  const [quizrunning, setquizrunning] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getQuizData = async () => {
    if (id) {
      let res = await postAttemptQuiz({
        attempts: {
          user_id: AppContext?.user?.id,
          quiz_id: id,
        },
      });
      getQuizQuestion(id);
    }
  };

  useEffect(() => {
    if (id && AppContext?.user) {
      getQuizData();
    }
  }, [id, AppContext?.user]);

  return <div className={classes.quizzescontainer}>{<QuestionBox />}</div>;
}
export default Quizexam;
