import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import changepassword from "../../image/dashboard/setting/changepassword.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from "react-toastify";
import { Change_Password } from "../../../service/services";
import {useNavigate} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      width: "100%",
      padding: "0.1rem 0.5rem",
    },
  },
  label: {
    color: "#252525",
    fontFamily: "Jost",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "1.5rem",
  },
  textfiled: {
    "& .MuiInputBase-input": {
      width: "400px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  btn: {
    lineHeight: "1.5rem",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "1.125rem",
    fontWeight: "600",
    width: "100%",
    background: "#243B55",
    color: " var(--white-100, #FFF)",
    padding: "0.5rem 0",
    borderRadius: "0.375rem",
    marginTop: "2rem",
    "&:hover": {
      background: "#243B55",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif !important",
      fontWeight: "400",
      color: "#FFF",
    },
  },
  field: {
    marginTop: "1.5rem",
  },
  imagecontainer: {
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "100%",
      },
    },
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formError,setFormErrors]=useState(false);
  const navigate=useNavigate();
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordsMatch(e.target.value === repassword);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordRe, setShowPasswordRe] = useState(false);

  const handleRePasswordChange = (e) => {
    setRePassword(e.target.value);
    setPasswordsMatch(e.target.value === newpassword);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordVisibilityNewPass = () => {
    setShowPasswordNew(!showPasswordNew);
  };
  const handleTogglePasswordVisibilityRe = () => {
    setShowPasswordRe(!showPasswordRe);
  };

  const handleSubmit = async () => {
    try {
      console.log(newpassword, oldpassword, repassword);
      const errors = {};
      if (!oldpassword) errors.oldpassword = true;
      if (!newpassword) errors.newpassword = true;
      if (!repassword) errors.repassword = true;
      
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
     
      const token = localStorage.getItem("token");
      const user = {
        current_password: oldpassword,
        password: newpassword,
        password_confirmation: repassword,
      };
      console.log(user, "new");
  
      const response = await Change_Password(user, token);
      console.log("API Response:", response);
  
      toast.success("change password successfully");
      localStorage.clear();
       window.location.reload();
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("Current password is wrong")
    }
  };
  
  
  return (
    <>
      <div className={classes.container}>
        <div>
          <div>
            <label className={classes.label}>
              {" "}
              Old Password <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <div style={{ position: "relative" }}>
              <TextField
                variant="outlined"
                className={classes.textfiled}
                type={showPassword ? "text" : "password"}
                placeholder="********"
                value={oldpassword}
                onChange={(e) => setOldPassword(e.target.value)}
                error={!oldpassword && formError.oldpassword}
              />
              {showPassword ? (
                <VisibilityOff
                  onClick={handleTogglePasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              ) : (
                <Visibility
                  onClick={handleTogglePasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              )}
            </div>
            {!oldpassword && formError.oldpassword && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please type your old password</small>
              </span>
            )}
          </div>
          <div className={classes.field}>
            <label className={classes.label}>
              {" "}
              Type New Password <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <div style={{ position: "relative" }}>
              <TextField
                variant="outlined"
                className={classes.textfiled}
                type={showPasswordNew ? "text" : "password"}
                placeholder="********"
                value={newpassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={!newpassword && formError.newpassword}
              />
              {showPasswordNew ? (
                <VisibilityOff
                  onClick={handleTogglePasswordVisibilityNewPass}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              ) : (
                <Visibility
                  onClick={handleTogglePasswordVisibilityNewPass}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              )}
            </div>
            {!newpassword && formError.newpassword && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please type your new password</small>
              </span>
            )}
            {newpassword.length > 0 && newpassword.length < 6 && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Password must be at least 6 characters long</small>
              </span>
            )}
          </div>
          <div className={classes.field}>
            <label className={classes.label}>
              {" "}
              Retype Password<span style={{ color: "red" }}>*</span>{" "}
            </label>
            <br />
            <div style={{ position: "relative" }}>
              <TextField
                variant="outlined"
                className={classes.textfiled}
                type={showPasswordRe ? "text" : "password"}
                placeholder="********"
                value={repassword}
                onChange={handleRePasswordChange}
                error={!repassword && formError.repassword}
              />
              {showPasswordRe ? (
                <VisibilityOff
                  onClick={handleTogglePasswordVisibilityRe}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              ) : (
                <Visibility
                  onClick={handleTogglePasswordVisibilityRe}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              )}
            </div>
            {repassword !== newpassword && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Repassword is not matching</small>
              </span>
            )}
            {!repassword && formError.repassword && (
              <span style={{ color: "red", marginTop: "5px" }}>
                <small>* Please type your repassword</small>
              </span>
            )}
          </div>
          <Button className={classes.btn} onClick={handleSubmit}>
            {" "}
            SAVE Password
          </Button>
        </div>

        <div className={classes.imagecontainer}>
          <img src={changepassword} alt="img" />
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
