import React from "react";
import { Button, TextField, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20rem",
    padding: "2rem",
  },
  main: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
  },
  sidepannalheader: {
    color: "#0076CE",
    fontSize: "1.5rem",
    fontWeight: "500",
    background: "#E6FFE6",
    padding: "0rem 1.2rem",
  },
  inputpannalheader: {
    color: "#0076CE",
    fontSize: "1.5rem",
    fontWeight: "500",

    padding: "0rem 1.2rem",
  },
  sidepannal: {
    border: "1px solid #E0FFE0 ",
    width: "38%",
    "& .MuiTypography-h2": {
      fontSize: "1rem",
      fontWeight: "500",
      border: "1px solid #E0FFE0 ",
      padding: "1rem",
      boxShadow: "0px 4px 15px 0px #0000002E",
      background: "#FFF",
    },
  },
  inputfield: {
    width: "58%",
    border: "1px solid #E0FFE0 ",
    "& .MuiTypography-h2": {
      fontSize: "1rem",
      fontWeight: "500",
      border: "1px solid #E0FFE0 ",
      padding: "1rem",
      boxShadow: "0px 4px 15px 0px #0000002E",
      background: "#FFF",
      width: "50%",
    },
    "& .MuiTypography-h3": {
      fontSize: "1rem",
      fontWeight: "500",
      border: "1px solid #E0FFE0 ",
      padding: "1rem",
      boxShadow: "0px 4px 15px 0px #0000002E",
      background: "#FFF",
      width: "50%",
      color: "#15c615",
    },
    "& .MuiTypography-h4": {
      fontSize: "1rem",
      fontWeight: "500",
      border: "1px solid #E0FFE0 ",
      padding: "1rem",
      boxShadow: "0px 4px 15px 0px #0000002E",
      background: "#FFF",
      width: "50%",
      color: "#ff0000",
    },
  },
  innersidepannal: {
    margin: "1rem 1rem",
  },
  inputcontainer: {
    display: "flex",
    width: "100%",
  },
  inputcontainer1: {
    width: "100%",
    "& .MuiTypography-h5": {
      fontSize: "1rem",
      fontWeight: "500",
      border: "1px solid #E0FFE0 ",
      padding: "1.5rem",
      boxShadow: "0px 4px 15px 0px #0000002E",
      background: "#FFF",
      width: "100%",
      textAlign: "center",
    },
  },
  btn: {
    fontSize: "0.8rem",
    fontWeight: "500",
    background: "#0076CE",

    textAlign: "center",
    color:"#FFF",
    marginTop:"1rem",
  },
}));

const RegistrationPage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.sidepannal}>
            <Typography className={classes.sidepannalheader}>
              Activities
            </Typography>
            <div className={classes.innersidepannal}>
              <Typography variant="h2">Registration Details</Typography>
              <Typography variant="h2">Applicaton Rom</Typography>
              <Typography variant="h2">Fee Payment</Typography>
            </div>
            <Typography className={classes.sidepannalheader}>
              Verify Mobile No. & Email id
            </Typography>
            <div className={classes.innersidepannal}>
              <Typography variant="h2">Mobile No. Verified</Typography>
              <Typography variant="h2">Verify Email Id</Typography>
            </div>
          </div>
          <div className={classes.inputfield}>
            <Typography className={classes.inputpannalheader}>
              Current Status
            </Typography>
            <div className={classes.inputcontainer}>
              <Typography variant="h2">Registration Details</Typography>
              <Typography variant="h3"> Completed</Typography>
            </div>
            <div className={classes.inputcontainer}>
              <Typography variant="h2">Appication Form</Typography>
              <Typography variant="h4"> Incomplete</Typography>
            </div>
            <div className={classes.inputcontainer}>
              <Typography variant="h2">Fee Payment</Typography>
              <Typography variant="h4"> Incomplete</Typography>
            </div>
            <div className={classes.inputcontainer1}>
              <Typography variant="h5">
                Your registration for National Eligibility Cum Entrance Test
                (UG)- 2022 is complete. Tour application fares will remain
                incomplete till you fill all the fields of application form and
                pey the fee. Please note down the Application No fur future
                references
                <br />
                <br />
                Application Number:
                <TextField />
                <br />
                <br />
                Kindly fill application form by clicking on the button below<br/>
                <Button className={classes.btn}>
                  Complete Application Form
                </Button>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationPage;
