import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { Rating } from "@material-ui/lab";
import BookImg from "../image/ebooksvector/bookimage.png";
import {ReactComponent as StarIcon} from "../image/ebooksvector/starsvg.svg";
import {ReactComponent as FavoriteChecked} from "../image/ebooksvector/favoritechecked.svg";
import {ReactComponent as FavoriteUnchecked} from "../image/ebooksvector/favoriteunchecked.svg";
import {ReactComponent as CartBagIcon} from "../image/ebooksvector/cartbagicon.svg";

const useStyles = makeStyles((theme) => ({
  ExclusiveBookCard: {
    marginTop: "1rem",
    "& .MuiCard-root": {
      width: "22%",
      display: "flex !important",
      margin: "1rem 5px",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: 482,
      borderRadius: 7,
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      "&:hover": {
        boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
        cursor: "pointer",
        "& .MuiCardActions-root": {
          background:
            "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
          borderRadius: 6,
          "& .MuiButton-label": {
            fontFamily: "Jost",
          },
        },
      },
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
        },
      },
      "& fieldset": {
        margin: "0 0 20px -3px",
        padding: 0,
      },
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "65%",
    },
    "& .MuiCardContent-root": {
      padding: "0 16px",
      minHeight: "5rem",

      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "1.125rem",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "1.125rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.875rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      width: "50% !important",
      // padding: "5px",
      borderRadius: "3%",
      // background: "white",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h5": {
        fontSize: "1.2rem",
        marginBottom: 5,
      },
      "& .MuiCard-root": {
        "@media (min-width: 960px)": {
          width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
    },
    "& .MuiFormControlLabel-root": {
      margin: "0",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  cardimgcont: {
    background: "#FFE8B5",
    margin: ".5rem",
    padding: "0.8rem",
    position: "relative",
    borderRadius: 8,
    "&:hover": {
      background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    },
  },
  ratingtag: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "0",
    padding: "0.2rem 0.8rem",
    background: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    borderRadius: "0 8px 8px 0", 
  },
  tagsandfavcontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0.5rem 0",
  },
  tags: {
    width: "85%",
  },
  favoritebtn: {
    width: "14%",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939"
    },
  },
  ebooktag: {
    background: "linear-gradient(127deg, #FFEEC3 35.82%, #FFD9B5 52.6%)",
    padding: "0.3rem 0.8rem",
    borderRadius: "6px",
    marginRight: "1rem",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.75rem",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  latesttag: {
    background: "#66CB70",
    padding: "0.3rem 0.8rem",
    borderRadius: "6px",
    marginRight: "1rem",
    color: "white",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.75rem",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "2rem",
    fontFamily: "Jost",
    fontWeight: "600",
    marginRight: "0.5rem",
  },
  offertext: {
    marginLeft: "1rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  couponbtntext: {
    fontFamily: "'Playfair Display', serif !important",
    color: "#000000",
    fontWeight: "600",
  },
  quikviewbtn: {
    position: "absolute",
    left: "65px",
    top: "76px",
    background: "white",
    padding: "0.6rem 1.5rem",
    opacity: "0.95",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: "white",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "1rem",
      fontWeight: "500",
    },
  },
  cartbagicon: {
    marginRight: "0.6rem",
  },
}));
function EbooksCard() {
  const classes = useStyles();

  const [isHover, setIsHover] = useState(false);

  const handleCardHover = () => {
    setIsHover(!isHover);
  };

  return (
    <>
      <div className={classes.ExclusiveBookCard}>
        <Card>
          <CardActionArea onMouseOver={()=>{setIsHover(true)}} onMouseLeave={()=>{setIsHover(false)}}>
            <div className={classes.cardimgcont}>
              <div className={classes.ratingtag}>
                <StarIcon />
                <Typography variant="h6">4.3</Typography>
              </div>
              {isHover && (
                <div>
                  <Button className={classes.quikviewbtn}>Quick View</Button>
                </div>
              )}
              <CardMedia
                component="img"
                height="180"
                image={BookImg}
                alt="green iguana"
              />
            </div>
            <CardContent>
              <div className={classes.tagsandfavcontainer}>
                <div className={classes.tags}>
                  <span className={classes.ebooktag}>E-books</span>
                  <span className={classes.latesttag}>Latest</span>
                </div>
                <div className={classes.favoritebtn}>
                  <FormControlLabel
                    control={<Checkbox icon={<FavoriteUnchecked />} checkedIcon={<FavoriteChecked />} name="checkedH" />}
                  />
                </div>
              </div>
              <Typography gutterBottom variant="h5" component="div">
                All In One General Studies 2023 All Competitive Exams (English
                Edition)
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                by B-Krishna Reddy
              </Typography>
              <Box component="fieldset" mb={3} borderColor="transparent">
                <Rating
                  name="read-only"
                  value={4}
                  readOnly
                />
                <Typography variant="h6">4.3</Typography>
                <Typography variant="caption">(634)</Typography>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginBottom: 20 }}
              >
                <span className={classes.textred}><span style={{ fontSize: "1.2rem", verticalAlign: "top" }}>₹</span>10.00</span>
                <Typography variant="caption">
                  MRP : <del>80.00</del>
                </Typography>
                <span className={classes.offertext}>(30% off)</span>
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions className={classes.bgorange}>
            <Button fullWidth><CartBagIcon  className={classes.cartbagicon}/>Add to cart</Button>
          </CardActions>
        </Card>
      </div>
    </>
  );
}
export default EbooksCard;
