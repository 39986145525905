import * as React from "react";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useMediaQuery } from "@material-ui/core";

const steps = [
  {
    label: "Select campaign settings",
  },
  {
    label: "Create an ad group",
  },
  {
    label: "Create an ad",
  },
  {
    label: "Create an ad",
  },
  {
    label: "Create an ad",
  },
  {
    label: "Create an ad",
  },
  {
    label: "Create an ad",
  },
];

export default function MobileStepper() {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box sx={{ maxWidth: 400 }}>
      {isMobile ? (
        <Stepper activeStep={0} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : null}
    </Box>
  );
}
