import {
  Backdrop,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import React from "react";
import { TfiEmail } from "react-icons/tfi";
import { ReactComponent as FacebookIcon } from "../image/loginsignupvector/facebookicon.svg";
import { ReactComponent as GoogleIcon } from "../image/loginsignupvector/googleicon.svg";
import { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import { ReactComponent as PhoneIcon } from "../image/loginsignupvector/phoneicon.svg";
import { GrFormClose } from "react-icons/gr";
import { useForm, SubmitHandler } from "react-hook-form";
import { signUp } from "../../service/services";
import { toast } from "react-toastify";
import OtpModal from "./otp";
import FullNameModal from "./FullName";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})(({ setIsAgree, isAgree }) => {
  return (
    <Checkbox
      checked={isAgree}
      color="default"
      onChange={(e) => setIsAgree(e.target.checked)}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "70%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      margin: "0",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.58rem",
      },
    },
    "& .MuiTypography-body2": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.69rem",
      },
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
        marginTop: "0rem",
      },
    },
    "& .MuiDivider-root": {
      width: "11%",
      background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
      height: "0.125rem",
      marginTop: "1rem",
      [theme.breakpoints.down("xs")]: {
        width: "23%",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiFormControlLabel-label": {
        fontFamily: "Jost",
        fontSize: "0.9rem",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.65rem",
        },
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& input:-internal-autofill-selected": {
        backgroundColor: "#fff",
      },
    },
  },
  loginform: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  paper: {
    position: "absolute",
    display: "flex",
    top: "25px",
    backgroundColor: "#FFFAEF",
    zIndex: "8",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    height: "calc(100vh - 50px)",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: "center",
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Jost",
      color: "#676767",
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "100px",
      padding: "1rem",
    },
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  googlefbiconmain: {
    display: "flex",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      justifyContent: "space-evenly",
      margin: "0.5rem 0",
    },
  },
  facebookicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },

  googleicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },
  passwordheadcont: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "0.5rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  formfootertext: {
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#5E5E5E",
    "& .MuiLink-root": {
      color: "#FF9213",
      cursor: "pointer",
    },
  },
  inputhead: {
    marginTop: ".5rem",
  },
  closebtncontainer: {
    position: "absolute",
    top: "15px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px ",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  facebooksvg: {
    width: "18px",
    height: "30px",
  },
  googlesvg: {
    width: "30px",
    height: "30px",
  },
}));
function SignUPModal(props) {
  const {
    handlesignupOpenclose,
    handleloginOpenclose,
    success,
    handleOtpOpenclose,
    setOtpVerify,
  } = props;

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.value });
  // };

  // const handleSubmit = () => {
  //   console.log(state);
  //   // axios.post("http://httpbin.org/post", state).then((res)=>{

  //   //   console.log(res.data);

  //   // }).catch((err)=>{
  //   //   console.log(err);
  //   // })
  // };

  const [showPassword, setShowPassword] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const [showFullNameModal, setShowFullNameModal] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const classes = useStyles();
  const initialValues = {
    phone: "",
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    setValue, // Add setValue from useForm
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  const onSubmit = async (data) => {
    if (!isAgree) {
      toast("Please  Agree to all Privacy policy, terms & conditions ", {
        position: "top-right",
        autoClose: 5000,
        type: "error",
      });
    } else {
      try {
        var res = await signUp({ user: data });
        if (res?.data?.success) {
          toast("Otp Sent to your registered mobile number!", {
            position: "top-right",
            autoClose: 5000,
            type: "success",
          });
          handleOtpOpenclose();
          success(data?.phone);
          setOtpVerify(true);
          setShowFullNameModal(true);
        }
      } catch (err) {
        toast(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          type: "error",
        });
      }
    }
  };

  const [mobileError, setMobileError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // Update handleMobileChange function
  const handleMobileChange = (event) => {
    const value = event.target.value.slice(0, 10); // Limit input to 10 digits
    const validStartPattern = /^[9876]/; // Check if starts with 9, 8, 7, or 6

    if (
      value === "" ||
      (validStartPattern.test(value.charAt(0)) && value.length <= 10)
    ) {
      setPhoneNumber(value); // Update phone number state
      setValue('phone', value); // Update form value for phone
    }

    // Error Handling
    if (value.length === 0) {
      setMobileError("Phone number is required");
    } else if (!validStartPattern.test(value.charAt(0))) {
      setMobileError("Phone number must start with 9, 8, 7, or 6");
    } else if (value.length < 10) {
      setMobileError("Phone number must be 10 digits long");
    } else {
      setMobileError("");
    }
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.closebtncontainer}>
          <Button className={classes.closebtn} onClick={handlesignupOpenclose}>
            <GrFormClose />
          </Button>
        </div>
        <div className={classes.paper}>
          <Typography variant="h3" gutterBottom>
            <span className={classes.orangetext}>Hello ! </span>Create Account
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            Sign up with your data that you entered during your registration
          </Typography>
          <Divider />
          <div className={classes.googlefbiconmain}>
            <div className={classes.googleicon}>
              <GoogleIcon className={classes.googlesvg} />
            </div>

            <div className={classes.facebookicon}>
              <FacebookIcon className={classes.facebooksvg} />
            </div>
          </div>
          <div className={classes.loginform}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ m: 1, width: "25ch" }}>
                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Mobile No.
                </Typography>
                <TextField
          value={phoneNumber}
          onChange={handleMobileChange}
          error={!!mobileError}
          helperText={mobileError}
          placeholder="9876543210"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
                />
                {errors?.phone && (
                  <p className="errorText ms-0 ">{errors.phone?.message}</p>
                )}

                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Email Address
                </Typography>
                <TextField
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  type="email"
                  placeholder="Johndeere43545@gmail.com"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TfiEmail />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.email && errors.email?.type === "pattern" && (
                  <p className="errorText ms-0 ">{errors.email?.message}</p>
                )}
                {errors?.email && errors.email?.type === "required" && (
                  <p className="errorText ms-0 ">Email is required</p>
                )}

                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Create Password
                </Typography>
                <TextField
                  {...register("password", { required: true })}
                  type={showPassword ? "text" : "password"}
                  placeholder="**********"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <LockOpenOutlinedIcon />
                          ) : (
                            <LockOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && errors.password.type === "required" && (
                  <p className="errorText ms-0 ">Password is required</p>
                )}

                <FormControlLabel
                  control={
                    <GreenCheckbox setIsAgree={setIsAgree} isAgree={isAgree} />
                  }
                  label="I Agree to all Privacy policy, terms & conditions"
                />
                <Button
                  className={classes.bluecustombtn}
                  fullWidth
                  type="submit"
                >
                  Create Account
                </Button>
              </FormControl>
              <Typography
                className={classes.formfootertext}
                variant="body2"
                gutterBottom
              >
                Already Have An Account?{" "}
                <Link onClick={handleloginOpenclose}>Sign In</Link>
              </Typography>
            </form>
          </div>
        </div>
      </div>

      {showFullNameModal && <FullNameModal signUpSuccess={true} />}
    </>
  );
}
export default SignUPModal;
