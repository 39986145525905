import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rectangle: {
    width: "calc(25% - 20px)",
    height: "125px",
    backgroundColor: "white",
    borderRadius: "5px",
    border: "2px solid #E1D9D1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    fontSize: "1.2rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    margin: "10px",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
  },

  [theme.breakpoints.down("sm")]: {
    rectangle: {
      width: "19rem",
      height: "104px",
      fontSize: "1rem",
    },
  },
  icon: {
    marginRight: "10px", 
    
  },
}));

export { useStyles };

function Supportsection({ text, icon }) {
  const classes = useStyles();

  return (
    <div className={classes.rectangle}>
      <div className={classes.icon}>{icon}</div>
      {text}
    </div>
  );
}

export default Supportsection;
