import { makeStyles } from '@material-ui/core';
import React from 'react';
import ExpertTeachersCard from './ExpertTeachersCard';

const useStyles = makeStyles((theme) => ({
    containerfluid: {
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundSize: "cover",
      },
      container: {
        maxWidth: "1250px",
        margin: "0 auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
      },
      
  }));

function ExpertTeachersContent({filteredDataByTeachers, selectedExam, selectedCity}) {
  // console.log(">>", filteredDataByTeachers);
    const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
          
        {selectedExam!==-1 ? 
          filteredDataByTeachers?.map((data)=>
            selectedExam === data.exam && ( 
              selectedCity!==-1 ? selectedCity === data.city && ( 
              <ExpertTeachersCard key={data.id} cardData={data}/>
              ) : <ExpertTeachersCard key={data.id} cardData={data}/>
          ))
          :
          ( selectedCity!==-1 ? 
            filteredDataByTeachers?.map((data)=>
              selectedCity === data.city && ( 
              <ExpertTeachersCard key={data.id} cardData={data}/>
            ))
          :
            filteredDataByTeachers?.map((data)=>( 
              <ExpertTeachersCard key={data.id} cardData={data}/>
            ))
          )
        }
        
      </div>
    </div>
  )
}

export default ExpertTeachersContent;