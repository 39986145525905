import React from "react";
import { Divider, Typography } from "@material-ui/core";
import useStyles from "../../../styles";

const TotalValue = ({ data }) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.mt1} ${classes.bordergrey} ${classes.w29} ${classes.w100mobile}
     ${classes.displaynonefordesktop}   ${classes.dblockmobile}`}
    >
      <div
        className={`${classes.dflex} ${classes.justifyspaceevenly} ${classes.py1}`}
      >
        <div>
          <Typography
            className={`${classes.fontsize5} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
          >
            Order ID:
          </Typography>
          <Typography
            component="p"
            className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            #{data?.order_data?.order_id}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div>
          <Typography
            className={`${classes.fontsize5} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
          >
            Payment Method:
          </Typography>
          <Typography
            component="p"
            className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            {data?.order_data?.payment_mode}
          </Typography>
        </div>
      </div>
      <Divider orientation="horizontal" />
      <div className={`${classes.p1}`}>
        <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
          <Typography
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
          >
            Subtotal:
          </Typography>
          <Typography
            component="p"
            className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            ₹{data?.item_price}
          </Typography>
        </div>
        <Divider className={`${classes.my05}`} orientation="horizontal" />
        <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
          <Typography
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
          >
            Discount:
          </Typography>
          <Typography
            component="p"
            className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            -₹{data?.total_discount}
          </Typography>
        </div>
        <Divider className={`${classes.my05}`} orientation="horizontal" />
        <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
          <Typography
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
          >
            Shipping:
          </Typography>
          <Typography
            component="p"
            className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            -₹{data?.order_data?.shipping_cost}
          </Typography>
        </div>
        <Divider className={`${classes.my05}`} orientation="horizontal" />
        <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
          <Typography
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            Total Amount
          </Typography>
          <Typography
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            ₹{data?.total}
          </Typography>
        </div>
        <Divider className={`${classes.my05}`} orientation="horizontal" />
        <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
          <Typography
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorgrey1}`}
          >
            GST:
          </Typography>
          <Typography
            component="p"
            className={`${classes.fontsize1} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            ₹{data?.gst}
          </Typography>
        </div>
        <Divider className={`${classes.my05}`} orientation="horizontal" />
        <div className={`${classes.dflex} ${classes.justifyspacebetween}`}>
          <Typography
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost}`}
          >
            Total payment by customer
          </Typography>
          <Typography
            component="p"
            className={`${classes.fontsize3} ${classes.fw600} ${classes.fontfamiljost} ${classes.fcolorgreen}`}
          >
            ₹{data?.billing_amount}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TotalValue;
