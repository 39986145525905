import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as TimerIcon } from "../../image/previousyearpapers/stopwatchicon.svg";
import { ReactComponent as AttemptIcon } from "../../image/previousyearpapers/attempticon.svg";
import { ReactComponent as QuestionIcon } from "../../image/previousyearpapers/questionicon.svg";
import appContext from "../../../context/AppContext";
import popupContext from "../../../context/PopupContext/PopupContext";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  previousyearpapercardmain: {
    maxWidth: "23.5%",
    "& .MuiTypography-h5": {
      color: "#223852",
      fontFamily: "'Jost', sans-serif",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1.8rem",
      textTransform: "capitalize",
      whiteSpace: "wrap",
      height: "90px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiTypography-caption": {
      color: "#727272",
      fontFamily: "'Jost', sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      marginLeft: "0.3rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },
  },
  orangecustombtn: {
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "black",
    padding: "0.4rem 1.6rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
  },
  freeanddownloadcont: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    margin: "1rem 0",
  },
  freetag: {
    background: "#66CB70",
    padding: "0rem 0.7rem",
    borderRadius: "5px",
    "& .MuiTypography-caption": {
      color: "#FFF",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "2rem",
      textTransform: "uppercase",
    },
  },
  timeandquescont: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  timeandquesinner: {
    display: "flex",
    alignItems: "center",
  },
  attemps: {
    marginTop: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  quizzecontainer: {
    width: "880px",
    marginTop: "1rem",
    paddingLeft: "0.5rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  cardroot: {
    flexBasis: "33.3333%",
    // maxWidth: 280,
    margin: "0 5px",
    maxHeight: 291,
    marginBottom: "2rem",
  },
}));

function PreviousYearPaperCard({ data }) {
  const classes = useStyles();

  const { handleloginOpenclose } = useContext(popupContext);
  const { isLoggedIn, token } = useContext(appContext);

  return (
    <Card className={[classes.cardroot, classes.previousyearpapercardmain]}>
      <CardContent className={classes.cardcontent}>
        <Typography variant="h5" gutterBottom>
          {data?.previous_year_paper_name}
        </Typography>
        <div className={classes.freeanddownloadcont}>
          <div className={classes.freetag}>
            <Typography variant="caption">Free</Typography>
          </div>
          {/* <DownloadIcon /> */}
        </div>
        <div className={classes.timeandquescont}>
          <div className={classes.timeandquesinner}>
            <TimerIcon />
            <Typography variant="caption">{data?.duration} Min</Typography>
          </div>
          <div className={classes.timeandquesinner}>
            <QuestionIcon />
            <Typography variant="caption">
              {data?.no_of_question} ques
            </Typography>
          </div>
        </div>
        <div className={classes.attemps}>
          <AttemptIcon />
          <Typography variant="caption">{data?.attempts} attempts</Typography>
        </div>
      </CardContent>
      <CardActions className={classes.orangecustombtn}>
        <Button
          onClick={() => {
            if (isLoggedIn && token) {
              window.open(`/previousyearpapers/${data.id}`, "_blank");
            } else {
              handleloginOpenclose();
            }
          }}
          fullWidth
        >
          Start Paper
        </Button>
      </CardActions>
    </Card>
  );
}
export default PreviousYearPaperCard;
