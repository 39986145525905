const OtherLinksData = [
  {
    id: 1,
    name: "Important Links",
    links: [
      {
        id: 1,
        name: "overview",
      },
      {
        id: 2,
        name: "answer key",
      },
      {
        id: 3,
        name: "latest updates",
      },
      {
        id: 4,
        name: "admit card",
      },
      {
        id: 5,
        name: "result",
      },
      {
        id: 6,
        name: "cut off",
      },
      {
        id: 7,
        name: "exam date",
      },
      {
        id: 8,
        name: "application status",
      },
    ],
  },
  {
    id: 2,
    name: "Quik Links",
    links: [
      {
        id: 1,
        name: "Quizzes",
        link: "quizzes",
      },
      {
        id: 2,
        name: "previous years papers",
        link: "previousyearpapers",
      },
      // {
      //   id: 3,
      //   name: "Current Affairs",
      //   link: "currentaffairs",
      // },
      {
        id: 4,
        name: "test series",
        link: "testseries",
      },
      {
        id: 5,
        name: "E-Books",
        link: "ebooks",
      },
      {
        id: 6,
        name: "Notes",
        link: "notes",
      },
      {
        id: 7,
        name: "Books",
        link: "books",
      },
      {
        id: 8,
        name: "Online Videos",
        link: "onlinevideos",
      },
    ],
  },
];

export default OtherLinksData;
