import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import DontWorrySection from "./dontworrysection";
const useStyles = makeStyles((theme) => ({}));
function OnlineSupport() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Online Support",
      headingmain: "Online Support",
      breadcrumb: "Online Support",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <DontWorrySection />
    </div>
  );
}
export default OnlineSupport;
