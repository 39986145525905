import React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import repeesvector from "./../../image/dashboard/mywallet/rupeesvector.png";
import gift from "../../image/dashboard/mywallet/gift.png";
import back from "../../image/dashboard/mywallet/back.png";
import redvector from "../../image/dashboard/mywallet/redvector.png";
import MyWalletCard from "./myWalletCard";
import sideimage from "../../image/dashboard/mywallet/sideimage.png";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1rem",
    display:"flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem",
    },
    "& .MuiTypography-h6": {
      color: " #353535",
      fontFamily: "Playfair Display",
      fontSize: "1.75rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
        margin: "0rem",
      },
    },
    "& .MuiTypography-h5": {
      color: " #2077C7",
      fontFamily: "Jost",
      fontSize: "2.25rem",
      fontWeight: 700,
    },
    "& .MuiTypography-h4": {
      color: "#252525",
      fontFamily: "Playfair Display",
      fontSize: "1.375rem",
      fontWeight: 700,
    },
    "& .MuiTypography-caption": {
      color: " #4B4B4B",

      fontFamily: "Jost",

      fontSize: "0.75rem",
      fontWeight: 400,
    },
    "& .MuiTypography-subtitle1": {
      maxWidth: "70%",
      backgroundImage: `url(${back})`,
      color: "#FFF",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left bottom",
      fontFamily: "Jost",
      fontSize: "0.625rem",
      fontWeight: 700,
      textTransform: "uppercase",
      padding: "0.5rem",
      marginTop: "0.7rem",
    },
    "& .MuiTypography-subtitle2": {
      color: " #252525",
      fontFamily: "Jost",
      fontSize: "0.6rem",
      fontWeight: 500,
      textTransform: "capitalize",
      marginTop: "0.3rem",
    },
  },
  headercontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.5rem",
    },
  },
  main: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  secondsidediv: {
    width: "28%",
    background: `url(${sideimage}) center/cover no-repeat`,
    [theme.breakpoints.down("xs")]: {
     display:"none",
    },
  },
  btn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "white",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem",
    },
  },
  divider: {
    marginTop: "15px",
    background: "#FFA712;",
    borderRadius: "0.375rem",
    border: "0",
    height: "6px",
    [theme.breakpoints.down("xs")]: {
      width: "130px",
      marginTop: "0.6rem",
      marginBottom: "0rem",
    },
  },
  rewardscontainer: {
    marginTop: "2rem",
    background: "linear-gradient(306deg, #FFE6CE 48.03%, #FFF4D7 53.6%)",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  rupees: {
    color: " #FF3939",
    fontFamily: "Jost",
    fontSize: "2rem",
    fontWeight: 600,
    textTransform: "capitalize",
    background: "#FFFFFF",
    padding: "0.7rem",
  },
  cardcontainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      margin: "0.3rem",
    },
  },
  rewardscontainer1: {
    width: "30%",
    paddingLeft: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const MyWallefirst = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.headercontainer}>
            <Typography variant="h6">My Balance</Typography>
            <Button className={classes.btn}>
              <AddIcon /> &nbsp; Add Amount
            </Button>
          </div>
          <Typography variant="h5">
            <img src={repeesvector} alt="img" width="3%" /> 36,000
          </Typography>
          <hr className={classes.divider} />
          <Typography variant="caption">
            you can Add Amount through debit, credit card, bank and from UPI to
            your wallet.
          </Typography>
          <div className={classes.rewardscontainer}>
            <div className={classes.rewardscontainer1}>
              <img src={gift} alt="img" />
              <Typography variant="h4"> Your Rewards</Typography>
              <Typography variant="subtitle1">Congratulations</Typography>
              <div className={classes.rupees}>
                <img src={redvector} alt="img" width="5%" /> 500{" "}
              </div>
              <Typography variant="subtitle2">
                Amount will be Added to your wallet
              </Typography>
            </div>
            <div className={classes.cardcontainer}>
              <MyWalletCard />
              <MyWalletCard />
              <MyWalletCard />
              <MyWalletCard />
            </div>
          </div>
        </div>
        <div className={classes.secondsidediv}>
          
        </div>
      </div>
    </>
  );
};

export default MyWallefirst;
