import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import PageHead from "../../../pageheading/pageheading";
import practicecomponentcontext from "../../context";
import { useParams } from "react-router-dom";
import SinglePageContent from "./singlepagecontent";
  
const useStyles = makeStyles((theme) => ({}));

function NotesSinglePageDescription() {
  const classes = useStyles();
  const {id} = useParams()
  const {getLiveClassesDetails , liveclassesDetail} = useContext(practicecomponentcontext);
  
  const Heading = [
    {
      id: 1,
      // headingsmall: "Explore Sakshi Agrawal’s 245 Lectures on SBI PO",
      // headingmain: "SBI PO 2023 Ignite Quant with Sakshi",
      breadcrumb:"Live CLasses / Product",
    },
  ];
  useEffect(()=>{
    getLiveClassesDetails(id)
  },[id])
  console.log('liveclassesoDetail' , id, liveclassesDetail)
  return (
    <div>
      <PageHead Heading={Heading} liveclassesDetail={liveclassesDetail}/>
      <SinglePageContent  liveclassesDetail={liveclassesDetail}/>
    </div>
  );
}
export default NotesSinglePageDescription;
