import React from "react";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { HiArrowSmRight } from "react-icons/hi";
import { ReactComponent as Arrow } from "../../image/catergoryVectoe/arrow.svg";
import QuizCardMobile from "./quizCardMobile";
import PreviousYearPaperCardMobile from "./previousYearPaperCardMobile";
import EBookCardMobile from "./eBookCardMobile";
import BookCardMobile from "./bookCardMobile";
import ConstantPracticeData from "../constant";
import QuizesCard from "./quizCard";
import PreviousYearPaperCard from "./previousyearpapercard";
import EBookCard from "./eBookCard";
import BookCard from "./bookCard";
import TestSeriesCard from "./testSeriesCard";
import NotesCard from "./notesCard";
import TestseriesCardMobile from "./testseriesCardMobile";
import NotesCardMobile from "./notesCardMobile";
import OnlineVideo from "./onlineVideo";
import LiveClasses from "./liveClasses";
import OnlineVideoMobile from "./onlineVideoMobile";
import LiveClassesMobile from "./liveClassesMobile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    "& .slick-slide": {
      padding: "0 1rem !important",
    },
    "& .MuiAppBar-colorPrimary": {
      width: "55rem",
      backgroundColor: "#FFDBAB",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    "& .MuiToolbar-regular": {
      display: "flex",
      minHeight: "50px",
      justifyContent: "space-between",
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
      borderRadius: "0.3rem",
    },
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom:'0.5rem',
    [theme.breakpoints.down("sm")]: {
      padding: "0 1rem",
    },
    "& .MuiTypography-h3": {
      fontSize: "1.5625rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
      color: "rgba(34, 56, 82, 1)",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h6": {
      fontSize: "0.875rem",
      fontFamily: "Jost",
      fontWeight: "400",
      color: "rgba(114, 114, 114, 1)",
    },
  },
  link: {
    textDecoration: "none",
    color: "rgba(21, 79, 229, 1)",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "0.625rem",
  },
  quizzecontainer: {
    width: "100%",
    marginTop: "0rem",
    paddingLeft: "0.5rem",
    display: "flex",
    flexWrap: "wrap",
  },
  subtitle: {
    marginBottom: 10,
    fontSize: "0.9rem",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  headingQuiz: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem !important",
    },
  },
  pageheadhr: {
    margin: "0",
    marginTop: "10px",
    width: "10.9375rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  notFoundData: {
    display: "flex",
    justifyContent: "center",
  },
  w80xs: {
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

function CardsSection(props) {
  const classes = useStyles();
  const { ExamData, quiz, setQuiz, media, pageLocation, practicepathname } =
    props;
  const getArrowOrViewAll = (categorie, subCategorie, exam = null) => {
    const setVa = () => {
      if (exam) {
        
        setQuiz({
          ...quiz,
          categoryName: categorie?.category_name,
          categories: categorie?.id,
          subCategories: subCategorie?.id,
          examName: exam?.name,
          exams: exam?.id,
        });
      } else {
        setQuiz({
          ...quiz,
          categoryName: categorie?.category_name,
          categories: categorie?.id,
          subCategories: subCategorie?.id,
        });
      }
      window.scroll(0, 0);
    };

    return (
      <>
        {media.matches ? (
          <div
            className={classes.Arrow}
            onClick={() => {
              setVa();
            }}
          >
            <Arrow />
          </div>
        ) : (
          <div>
            <Button
              className={classes.link}
              onClick={() => {
                setVa();
              }}
            >
              view all
              <HiArrowSmRight />
            </Button>
          </div>
        )}
      </>
    );
  };

  const webRender = (data) => {
    console.log(data, "data web");
    switch (pageLocation) {
      case ConstantPracticeData.quizzes.pathname:
        return <QuizesCard data={data} />;

      case ConstantPracticeData.previousyearpapers.pathname:
        return <PreviousYearPaperCard data={data} />;

      case ConstantPracticeData.ebooks.pathname:
        return <EBookCard data={data} />;

      case ConstantPracticeData.books.pathname:
        return <BookCard data={data} />;

      case ConstantPracticeData.testseries.pathname:
        return <TestSeriesCard data={data} />;

      case ConstantPracticeData.notes.pathname:
        return <NotesCard data={data} />;

      case ConstantPracticeData.onlinevideos.pathname:
        return <OnlineVideo data={data} />;

      case ConstantPracticeData.liveclasses.pathname:
        return <LiveClasses data={data} />;

      default:
        break;
    }
  };

  const mobileRender = (data) => {
    switch (pageLocation) {
      case ConstantPracticeData.quizzes.pathname:
        return <QuizCardMobile data={data} />;

      case ConstantPracticeData.previousyearpapers.pathname:
        return <PreviousYearPaperCardMobile data={data} />;

      case ConstantPracticeData.ebooks.pathname:
        return <EBookCardMobile data={data} />;

      case ConstantPracticeData.books.pathname:
        return <BookCardMobile data={data} />;

      case ConstantPracticeData.testseries.pathname:
        return <TestseriesCardMobile data={data} />;

      case ConstantPracticeData.notes.pathname:
        return <NotesCardMobile data={data} />;

      case ConstantPracticeData.onlinevideos.pathname:
        return <OnlineVideoMobile data={data} />;

        
      case ConstantPracticeData.liveclasses.pathname:
        return <LiveClassesMobile data={data} />;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {/* When category not selected. */}
        {(quiz?.examName === null || quiz?.examName === "") &&
          quiz?.categories === -1 &&
          ExamData?.map((row, index) => {
            return row?.categories?.map((categorie) =>
              categorie?.subCategories?.map((subCategorie) => {
                let list = [];
                subCategorie?.[
                  ConstantPracticeData?.[practicepathname]?.level3
                ]?.forEach((exam) => {
                  exam?.[ConstantPracticeData?.[pageLocation]?.key]?.forEach(
                    (quizTemp) => {
                      list.push(quizTemp);
                    }
                  );
                });
                return (
                  list?.length > 0 && (
                    <div>
                      <div className={classes.heading} key={index}>
                        <div>
                          <Typography
                            variant="h3"
                            className={classes.headingQuiz}
                          >
                            {subCategorie.sub_category_name } { ConstantPracticeData?.[pageLocation]?.subtitle}
                          </Typography>
                          {media.matches && (
                            <hr className={classes.pageheadhr} />
                          )}
                        </div>
                        {getArrowOrViewAll(categorie, subCategorie)}
                      </div>
                      <div
                        className={!media.matches && classes.quizzecontainer}
                      >
                        {/* {list
                          ?.slice(0, 4)
                          ?.map((data) =>
                            media.matches ? mobileRender(data) : webRender(data)
                          )} */}
                        {list
                          ?.filter(
                            (data, index, self) =>
                              self.findIndex((d) => d.id === data.id) === index
                          )
                          ?.slice(0, 4)
                          ?.map((data) =>
                            media.matches ? mobileRender(data) : webRender(data)
                          )}
                      </div>
                    </div>
                  )
                );
              })
            );
          })}

        {/* When category is selected. */}
        {(quiz?.examName === null || quiz?.examName === "") &&
          quiz?.categories !== -1 &&
          ExamData?.map((row, index) => {
            let dataLength = 0;
            return (
              <>
                {row?.categories?.map(
                  (categorie) =>
                    quiz?.categories === categorie?.id &&
                    categorie?.subCategories?.map((subCategorie) => {
                      return (
                        <>
                          {(quiz?.subCategories === null ||
                            quiz?.subCategories === subCategorie?.id) &&
                            subCategorie?.[
                              ConstantPracticeData?.[practicepathname]?.level3
                            ]?.map((exam) => {
                              let list = [];
                              exam?.[
                                ConstantPracticeData?.[pageLocation]?.key
                              ]?.forEach((quizTemp) => {
                                list.push(quizTemp);
                              });
                              if (list?.length !== 0) dataLength = list?.length;
                              return (
                                list?.length > 0 && (
                                  <div>
                                    <div
                                      className={classes.heading}
                                      key={index}
                                    >
                                      <div>
                                        <Typography
                                          variant="h3"
                                          className={classes.headingQuiz}
                                        >
                                          {
                                            exam?.[
                                              ConstantPracticeData?.[
                                                practicepathname
                                              ]?.level3Name
                                            ]
                                          }{" "}
                                          {
                                            ConstantPracticeData?.[pageLocation]
                                              ?.subtitle
                                          }
                                        </Typography>
                                        {media.matches && (
                                          <hr className={classes.pageheadhr} />
                                        )}
                                      </div>
                                      {getArrowOrViewAll(
                                        categorie,
                                        subCategorie,
                                        {
                                          id: exam?.id,
                                          name: exam?.[
                                            ConstantPracticeData?.[
                                              practicepathname
                                            ]?.level3Name
                                          ],
                                        }
                                      )}
                                    </div>
                                    <div
                                      className={
                                        !media.matches &&
                                        classes.quizzecontainer
                                      }
                                    >
                                      {list
                                        ?.slice(0, 4)
                                        ?.map((data) =>
                                          media.matches
                                            ? mobileRender(data)
                                            : webRender(data)
                                        )}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                        </>
                      );
                    })
                )}
                {dataLength === 0 && (
                  <div className={classes.notFoundData}>
                    <img
                      src={require("../../image/notFoundData.png")}
                      className={classes.w80xs}
                      alt="img"
                    />
                  </div>
                )}
              </>
            );
          })}

        {/* When exam is selected. */}
        {quiz?.examName &&
          ExamData?.map((row, index) => {
            let dataLength = 0;
            return (
              <>
                {row?.categories?.map(
                  (categorie) =>
                    quiz?.categories === categorie?.id &&
                    categorie?.subCategories?.map((subCategorie) => {
                      return (
                        <>
                          {(quiz?.subCategories === null ||
                            quiz?.subCategories === subCategorie?.id) &&
                            subCategorie?.[
                              ConstantPracticeData?.[practicepathname]?.level3
                            ]?.map((exam) => {
                              let list = [];

                              if (quiz.exams === exam.id)
                                list =
                                  exam?.[
                                    ConstantPracticeData?.[pageLocation]?.key
                                  ];

                              console.log("list", list.length);
                              if (list?.length !== 0) dataLength = list?.length;

                              return (
                                list?.length > 0 &&
                                quiz.exams === exam.id && (
                                  <div>
                                    <div
                                      className={classes.heading}
                                      key={index}
                                    >
                                      <div>
                                        <Typography
                                          variant="h3"
                                          className={classes.headingQuiz}
                                        >
                                          {
                                            exam?.[
                                              ConstantPracticeData?.[
                                                practicepathname
                                              ]?.level3Name
                                            ]
                                          }{" "}
                                          {
                                            ConstantPracticeData?.[pageLocation]
                                              ?.subtitle
                                          }
                                        </Typography>
                                        {media.matches && (
                                          <hr className={classes.pageheadhr} />
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        !media.matches &&
                                        classes.quizzecontainer
                                      }
                                    >
                                      {list?.map((data) =>
                                        media.matches
                                          ? mobileRender(data)
                                          : webRender(data)
                                      )}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                        </>
                      );
                    })
                )}
                {dataLength === 0 && (
                  <div className={classes.notFoundData}>
                    <img
                      src={require("../../image/notFoundData.png")}
                      className={classes.w80xs}
                      alt="img"
                    />
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
}

export default CardsSection;
