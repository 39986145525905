import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CourseSectionTabPanel from "./coursetabpanel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  containerfluid: {
    width: "100%",
    background: "#FFFCF4",
  },
  container: {
    maxWidth: "1250px",
    margin: "1rem auto",
    padding: "3rem 0",
    [theme.breakpoints.down("xs")]: { 
      padding: "1rem" 
    },

    "& .MuiTypography-h3": {
      fontSize: "2.1875rem",
      fontWeight: "600",
      color: "rgba(37, 37, 37, 1)",
      fontFamily: "Playfair Display",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      fontWeight: "400",
      color: "#727272",
      fontFamily: "Jost",
      textAlign: "center",
    },
    "& .MuiTab-wrapper": {
      fontSize: "1.25rem",
      fontWeight: "700",
      color: "rgba(124, 124, 124, 1)",
      fontFamily: "Quicksand",
      textTransform: "none",
    },
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "rgba(255, 255, 255, 1)",
      height: "70px",
      display: "flex",
      justifyContent: "center",
      boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      borderRadius: "8px",
    },
  },

  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      //width: "100%",
      flexWrap: "wrap",
      padding: "0.4rem",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`course-tabpanel-${index}`}
      aria-labelledby={`course-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CourseSection = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <Typography variant="h3" gutterBottom>
          My Courses
        </Typography>
        <div className={classes.cardcontainer}>
          <CourseSectionTabPanel value={value} handleChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export default CourseSection;
