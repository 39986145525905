import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// import { ReactComponent as FAQSvg } from "../image/affiliatesvector/faqiconsvg.svg";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
    },
  },
  root: {
    width: "100%",
    // padding: "2rem",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
    marginBottom: "13rem",

    "& .MuiTypography-h3": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.25rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    "& .MuiTypography-h4": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
        fontWeight: "500",
      },
      fontSize: "1.25rem",
      fontWeight: "500",
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
    },
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      color: "#727272",
    },
    "& .MuiAccordion-root:before": {
      height: "0",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0 1rem",
      borderBottom: "1px solid #DBDBDB",
      [theme.breakpoints.down("xs")]: {
        padding: "0 1rem 1rem 1rem",
      },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      border: "none",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      background: "#FFF5DF",
      padding: "0 1rem",
      [theme.breakpoints.down("xs")]: {
        background: "#FAFAFA",
      },
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordion-root": {
      padding: "0 1rem",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        background: "#FFF5DF",
      },
    },
  },
  headingmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2rem 2rem 1rem 2rem",
    [theme.breakpoints.down("xs")]: {
      background: "#FFF5DF",
    },
  },
  headingmaininner: {
    display: "flex",
    alignItems: "center",
    "& a": {
      display: "flex",
      textTransform: "uppercase",
      textDecoration: "none",
      color: "#154FE5",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        color: "#252525",
        fontSize: "0.9rem",
      },
    },
  },
  arrowicon: {
    color: "#154FE5",
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      color: "#252525",
    },
  },
  faqsvg: {
    marginRight: "0.8rem",
  },
}));

export default function FAQSection() {
  const classes = useStyles();

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.root}>
          <div className={classes.headingmain}>
            <div className={classes.headingmaininner}>
              {/* <FAQSvg className={classes.faqsvg}/> */}
              <Typography variant="h3"> FAQ</Typography>
            </div>
            <div className={classes.headingmaininner}>
              <Link to={""}>
                View all
                <ChevronRightIcon className={classes.arrowicon} />
              </Link>
            </div>
          </div>
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h4" className={classes.faqheading}>
                Q.1 ) Who Conduct SSC exam?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                SSC exam 2022 is conducted by the Staff Selection Commission to
                recruit eligible and qualified candidates in various Group B and
                C level posts in government departments. Different departments
                fill their vacancies through SSC exams. It is open for both 12th
                pass students and graduates. Eligibility and salary are
                different for different job profiles, that's why aspirants are
                advised to check the detailed post on each SSC exam in orderto
                be well aware of the schedule, eligibility, vacancy, salary,
                etc. Eligibility and salary are different for different job
                profiles, that's why aspirants are advised to check the detailed
                post on each SSC exam in orderto be well aware of the schedule,
                eligibility, vacancy, salary, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h4" className={classes.faqheading}>
                Q.2 ) Is the SSC CHSL exam online or offline?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                SSC exam 2022 is conducted by the Staff Selection Commission to
                recruit eligible and qualified candidates in various Group B and
                C level posts in government departments. Different departments
                fill their vacancies through SSC exams. It is open for both 12th
                pass students and graduates. Eligibility and salary are
                different for different job profiles, that's why aspirants are
                advised to check the detailed post on each SSC exam in orderto
                be well aware of the schedule, eligibility, vacancy, salary,
                etc. Eligibility and salary are different for different job
                profiles, that's why aspirants are advised to check the detailed
                post on each SSC exam in orderto be well aware of the schedule,
                eligibility, vacancy, salary, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="h4" className={classes.faqheading}>
                Q.3 ) How many vacancies are there in the SSC CHSL 2023 exam?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                SSC exam 2022 is conducted by the Staff Selection Commission to
                recruit eligible and qualified candidates in various Group B and
                C level posts in government departments. Different departments
                fill their vacancies through SSC exams. It is open for both 12th
                pass students and graduates. Eligibility and salary are
                different for different job profiles, that's why aspirants are
                advised to check the detailed post on each SSC exam in orderto
                be well aware of the schedule, eligibility, vacancy, salary,
                etc. Eligibility and salary are different for different job
                profiles, that's why aspirants are advised to check the detailed
                post on each SSC exam in orderto be well aware of the schedule,
                eligibility, vacancy, salary, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h4" className={classes.faqheading}>
                Q.4 ) When will the SSC CHSL Tier I exam take place for SSC CHSL
                2023?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                SSC exam 2022 is conducted by the Staff Selection Commission to
                recruit eligible and qualified candidates in various Group B and
                C level posts in government departments. Different departments
                fill their vacancies through SSC exams. It is open for both 12th
                pass students and graduates. Eligibility and salary are
                different for different job profiles, that's why aspirants are
                advised to check the detailed post on each SSC exam in orderto
                be well aware of the schedule, eligibility, vacancy, salary,
                etc. Eligibility and salary are different for different job
                profiles, that's why aspirants are advised to check the detailed
                post on each SSC exam in orderto be well aware of the schedule,
                eligibility, vacancy, salary, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography variant="h4" className={classes.faqheading}>
                Q.5 ) Does the SSC exam have negative markings?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                SSC exam 2022 is conducted by the Staff Selection Commission to
                recruit eligible and qualified candidates in various Group B and
                C level posts in government departments. Different departments
                fill their vacancies through SSC exams. It is open for both 12th
                pass students and graduates. Eligibility and salary are
                different for different job profiles, that's why aspirants are
                advised to check the detailed post on each SSC exam in orderto
                be well aware of the schedule, eligibility, vacancy, salary,
                etc. Eligibility and salary are different for different job
                profiles, that's why aspirants are advised to check the detailed
                post on each SSC exam in orderto be well aware of the schedule,
                eligibility, vacancy, salary, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography variant="h4" className={classes.faqheading}>
                Q.6 ) What is the cut-off of SSC CHSL for a General category?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                SSC exam 2022 is conducted by the Staff Selection Commission to
                recruit eligible and qualified candidates in various Group B and
                C level posts in government departments. Different departments
                fill their vacancies through SSC exams. It is open for both 12th
                pass students and graduates. Eligibility and salary are
                different for different job profiles, that's why aspirants are
                advised to check the detailed post on each SSC exam in orderto
                be well aware of the schedule, eligibility, vacancy, salary,
                etc. Eligibility and salary are different for different job
                profiles, that's why aspirants are advised to check the detailed
                post on each SSC exam in orderto be well aware of the schedule,
                eligibility, vacancy, salary, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
