import { InputBase, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../image/bannerimages/serachicon.svg";
import BannerImage from "../../image/bannerimages/bannerimage.png";
import BannerVector from "../../image/bannerimages/bannervector.png";
import BannerVectorGif from "../../Videos/bannervideo.webm";
import BannerCard from "../bannercard/bannercardmain";
import { useNavigate } from "react-router-dom";
import SearchInput from "./SearchInput";
import categoryContext from "../../../context/CategoryContext/CategoryContext";
import { useContext } from "react";
import appContext from "../../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  Banner: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    fontFamily: "'Playfair Display', serif !important",
    "& .MuiListItemIcon-root": {
      minWidth: "20px !important",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  containerfluid: {
    backgroundImage: `url(${BannerImage})`,
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {},
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },

  bannerinner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
    position: "relative",
    paddingBottom: "152px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "1rem 1rem 16rem 1rem",
    },
  },
  bannerimg: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.5rem",
      width: "60%",
    },
    "& video::-webkit-media-controls": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-enclosure": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-panel": {
      display: "none !important",
    },
  },
  bannercontent: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  bannerheading: {
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "3.75rem",
    marginBottom: "20px",
    width: "80%",
    lineHeight: "70px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      fontSize: "1.8rem",
      lineHeight: " 40px",
      textAlign: "center",
      marginBottom: "0rem",
      marginTop: "0rem",
    },
  },
  bannerheadingbox: {
    backgroundImage:
      "linear-gradient(to right, rgba(225,128,8,0.12999999523162842), rgba(255,200,55,0.12999999523162842))",
    padding: "10px",
    paddingRight: "50px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      padding: "0px",
      paddingRight: "0px",
    },
  },
  bannerheadingcolor: {
    color: "#FA9D2B",
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchmobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  searchIcon: {
    padding: "10px",
    height: "100%",
    width: "7%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "12%",
    },
  },
  MuiSvgIcon_root: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: "0",
    userSelect: "none",
  },
  inputRoot: {
    color: "#212121",
    width: "100%",
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontFamily: "Jost",
    [theme.breakpoints.down("sm")]: {
      width: "20ch",
    },
  },
  bannerbuttons: {
    marginTop: "2rem",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  bannerbuttonsmobile: {
    marginTop: "2rem",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
      justifyContent: "center",
      "& .MuiButton-root": {
        width: "100%",
        fontSize: "1rem !important",
      },
    },
  },

  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "15px 40px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "13px 20px",
      marginRight: "0px",
    },
  },
  circelbtn: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.00)",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  circelbtntext: {
    marginLeft: "3rem",
  },
  bannertextcontdesktop: {
    fontSize: "1.7rem",
    margin: "1rem 0",
    fontFamily: "'Jost', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
      margin: "0",
    },
  },
  bannervectoranimation: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
}));
function Banner() {
  const navigate = useNavigate();
  const classes = useStyles();
  const videoRef = useRef(null);
  const AppContext = useContext(appContext);
  const [selectedExam, setSelectedExam] = useState(null);
  const [search, setSearch] = useState({});

  const handleExamClick = (e, value) => {
    if (value && value.id) {
      navigate(`/singleexamproducts/${value?.id}`);
      setSelectedExam(value.id);
      setSearch(value);
      AppContext.setCourseData({
        category: value.category_id,
        categoryName: value.category_name,
        subcategoryName: value.subCategory_name,
        subcategory: value.subCategory_id,
        exam: value.id,
        exam_name: value.exam_name,
      });
    } else {
      setSearch({});
      setSelectedExam("");
    }
  };

  const [iphoneDetect, setIphoneDetect] = useState(false);

  const CategoryContext = useContext(categoryContext);

  const allExams = CategoryContext?.megaMenu?.[0]?.["categories"]?.reduce(
    (exams, category) => {
      category.subCategories.forEach((subCategory) => {
        subCategory.exams.forEach((exam) => {
          exams.push({
            category_id: category.id,
            category_name: category.category_name,
            subCategory_id: subCategory.id,
            subCategory_name: subCategory.sub_category_name,
            ...exam,
          });
        });
      });
      return exams;
    },
    []
  );

  useEffect(() => {
    var userAgent = navigator.userAgent;

    if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setIphoneDetect(true);
    }
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    };

    const playVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.play().catch((error) => {
          console.error("Error playing video:", error.message);
        });
      }
    };

    const pauseVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.pause();
      }
    };

    const videoObserver = new IntersectionObserver(handleIntersection, options);

    const video = videoRef.current;
    if (video) {
      videoObserver.observe(video);
    }

    return () => {
      if (video) {
        videoObserver.unobserve(video);
      }
    };
  }, []);
  return (
    <>
      <div className={classes.banner}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <div className={classes.bannerinner}>
              <div className={classes.bannercontent}>
                <h1 className={classes.bannerheading}>
                  Boost Your{" "}
                  <span className={classes.bannerheadingbox}>Exam</span>{" "}
                  <span className={classes.bannerheadingcolor}>Readiness</span>
                </h1>
                <p className={classes.bannertextcontdesktop}>
                  Mock Test Websites You Can Trust
                </p>
                {/* <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search Your Exams..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                 
                </div> */}
                <div className={classes.search}>
                  <SearchInput
                    allExams={allExams}
                    search={search}
                    handleExamClick={handleExamClick}
                  />
                </div>
                <div className={classes.bannerbuttons}>
                  <Button
                    onClick={() => navigate("/testseries")}
                    variant="contained"
                    className={classes.bluecustombtn}
                  >
                    Getting Started
                  </Button>
                </div>
              </div>
              <div className={classes.bannerimg}>
                {iphoneDetect ? (
                  <img src={BannerVector} alt="img" width="100%" />
                ) : (
                  <video
                    ref={videoRef}
                    className={classes.bannervectoranimation}
                    autoPlay={true}
                    loop
                    muted
                    playsInline
                  >
                    <source src={BannerVectorGif} type="video/webm" />
                  </video>
                )}
              </div>
              <div className={classes.searchmobile}>
                {/* <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search Your Exams..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                /> */}
                <SearchInput
                    allExams={allExams}
                    search={search}
                    handleExamClick={handleExamClick}
                  />
              </div>
              <div className={classes.bannerbuttonsmobile}>
                <Button
                  variant="contained"
                  onClick={() => navigate("/testseries")}
                  className={classes.bluecustombtn}
                  fullWidth
                >
                  Getting Started
                </Button>
              </div>
              <BannerCard className={classes.position} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Banner;
