import { makeStyles } from "@material-ui/core";
import React from "react";
import SecHead from "../sectionHeading/sectionheading";
import LatestSeriesSectionContent from "./latestseriescontent";

const useStyles = makeStyles((theme) => ({
  latesttestseriesmain: {
    background: "#FFF7E4",
    margin: "0rem  0rem 2.2rem 0rem",
    padding: "2.2rem 0rem 4rem 0rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem",
      marginBottom: "3rem",
      paddingTop: "3rem",
      paddingBottom: "3rem",
    },
  },

  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
}));
function LatestTeatSeriesSection() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Get Ahead",
      headingmain: "Latest Test Series",
    },
  ];
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <>
      <div className={classes.latesttestseriesmain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <SecHead Heading={Heading} media={mql.matches} />
            <LatestSeriesSectionContent />
          </div>
        </div>
      </div>
    </>
  );
}
export default LatestTeatSeriesSection;
