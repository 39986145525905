import RectangleImage1 from "../image/rpsvector/rectangle1.png";
import RectangleImage2 from "../image/rpsvector/rectangle2.png";
import RectangleImage3 from "../image/rpsvector/rectangle3.png";
 
 const RectangleShapeData = [
    {
      text: "ACCURATE",
      image: RectangleImage1,
    },
    {
      text: "FAST PROCESSING",
      image: RectangleImage2,
    },
    {
      text: "SECURE",
      image: RectangleImage3,
    },
  ];

  export default RectangleShapeData;