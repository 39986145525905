import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import Person from "../image/seller/offer.png";
import BannerBackground from "../image/seller/bannerbackground.png";
import MobileBannerBackground from "../image/coachingsvector/MobileBannerBackground.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "6rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  bannercardmain: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    marginTop: "8rem",
    padding: "14px 2rem",
    backgroundImage: `url(${BannerBackground})`,
    color: "white",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      backgroundImage: `url(${MobileBannerBackground})`,
      backgroundSize: "contain",
      // margin: "0 1rem",
      padding: "1px 2rem",
    },
    "& .MuiTypography-h4": {
      color: "#FFF",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2rem",
      fontStyle: "normal",
      whiteSpace: "nowrap",
      fontWeight: "700",
      lineHeight: "3.75rem",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        lineHeight: "1.875rem",
        fontSize: "1rem",
      },
    },
    "& .MuiTypography-body2": {
      color: "#9E9E9E",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.1rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "1.625rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        textAlign: "center",
        lineHeight: "1.25rem",
      },
    },
  },
  bannercardimg: {
    // scale: "1.5",
    display: "flex",
    bottom: "-5px",
    position: "relative",
    justifyContent: "center",
    transform: "translate(0px, -17px)",
    "& video::-webkit-media-controls": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-enclosure": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-panel": {
      display: "none !important",
    },
  },
  bannercardcontent: {
    width: "50%",
    margin: "1.5rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0px !important",
    },
  },

  orangecustombtn: {
    background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    color: "black",
    padding: "13px 30px",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    textTransform: "uppercase",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#252525",
    },
  },
  bannerimg: {
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  bannercardcontentExamReady: {
    color: "#FFC539",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.25rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "3.75rem",
    marginRight: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
      textAlign: "center",
      marginRight: "0px !important",
    },
  },
  orangeStrip: {
    backgroundColor: "rgba(255, 186, 106, 1)",
    display: "inline-block",
    padding: "3px 8px",
    color: "white",
  },
  orangeStripMobile: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "transparent",
    },
  },
}));
function BannerCard() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div className={classes.bannercardmain}>
            <div className={classes.bannercardcontent}>
              <Typography variant="h4">
                <span
                  className={`${classes.orangeStrip} ${classes.orangeStripMobile}`}
                >
                  Sign Up Today
                </span>
                and Get Your First 2 Months Free
              </Typography>

              <Typography
                className={classes.bannercardtextfordesktop}
                variant="body2"
              >
                ParikshaDo is a global online website for books. We facilitate
                the sale of books by connecting sellers with buyers all around
                the world. What are you waiting for? Sign up today and take the
                first steps to selling on ParikshaDo !
              </Typography>
            </div>
            <div className={classes.bannercardimg}>
              <img className={classes.bannerimg} src={Person} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BannerCard;
