import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

const useStyles = makeStyles((theme) => ({
  cardroot: {
    marginTop: "1.5rem",

    "& .MuiCard-root .MuiPaper-elevation1": {
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.10)",
    },
    "& .MuiCardContent-root": {
      padding: "0 1rem",
    },
  },
  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0.9rem 0",
    "& .MuiTypography-h5": {
      borderRadius: "0.375rem",
      border: "1px solid #00A019",
      background: " var(--white-100, #FFF)",
      color: "#00A019",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.625rem",
      fontWeight: 600,
      textTransform: "capitalize",
      padding: "0.3rem",
    },
    "& .MuiTypography-h6": {
      color: "#262626",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontWeight: "400",
      textTransform: "capitalize",
    },
  },
  durationandques: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    color: "#262626",
    fontFamily: "Jost",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  button: {
    color: "#000",
    fontFamily: "Jost",
    fontSize: "0.55rem",
    fontWeight: 400,
    width: "23%",
    borderRadius: "var(--4, 0.25rem)",
    background: "linear-gradient(90deg, #FFF4EA 0%, #FFEEC3 100%)",
    alignItems: "center",
    padding: "0.2rem 0.4rem",
    textAlign: "center",
  },
  startbtn: {
    "&.MuiButton-root": {
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.625rem",
    },
    "&.MuiIconButton-root": {
      background: "#243B55",
      color: "#FFF",
      padding: "0.3rem",
      marginLeft: "0.5rem",
      borderRadius: "0.375rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      color: "#000",
      textTransform: "capitalize",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.625rem",
      background: "#EEEEEE",
      borderRadius: "0.375rem",
      padding: "0.3rem 1.2rem",
    },
  },
  justifyend: {
    justifyContent: "flex-end",
  }
}));

const CardForTable = ({ data, handleTestseriesLogin, section }) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.cardroot}>
        <CardContent>
          <div className={classes.cardcontainer}>
            <div className={classes.heading}>{data.name}</div>
            {/* <div className={classes.button}>{data.total_score}</div> */}
            {/* {section === "testseriesinner" && <div className={classes.button}>{data.format}</div>} */}
          </div>
          <div className={classes.cardcontainer}>
            <div className={classes.durationandques}>
              <Typography variant="h6">{data.questions}</Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="h6">Total Marks: {data.total_marks}</Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="h6">Duration: {data.duration}</Typography>
            </div>
          </div>
          <div className={`${classes.cardcontainer} ${classes.justifyend}`}>
          
            {data.status === "completed" ? (
              <div>
                <Typography variant="h5" component="span">Completed</Typography>
                <IconButton
                  className={classes.startbtn}
                  onClick={() => handleTestseriesLogin(data?.id, data?.bundleId)}
                >
                  <Tooltip id="button-info" title="Start Over">
                    <ReplayIcon style={{ fontSize: "large" }} />
                  </Tooltip>
                </IconButton>
              </div>
            ) : data.status === "start" ? (
              <Button
                className={classes.startbtn}
                onClick={() => handleTestseriesLogin(data?.id, data?.bundleId)}
              >
                Start Test
              </Button>
            ) : (
              <Button
                onClick={() => handleTestseriesLogin(data?.id, data?.bundleId)}
                className={classes.startbtn}
              >
                Resume
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default CardForTable;
