import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import Optimizingresultsection from "./optimizingresultsection";
import Rectangleshape from "./rectangleshape";
import RpsCard from "./rpscard";
import KeyFunctionalities from "./keyfunctionsection";
import ContactSection from "../biometric/contactsection";

const useStyles = makeStyles((theme) => ({
  // Your styles here
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  image: {
    marginTop: "2rem",
    maxWidth: "100%",
    height: "auto",
  },
}));

function RPS() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore About RPS",
      headingmain: "Result Processing System",
      breadcrumb: "RPS",
    },
  ];

  return (
    <div>
      <PageHeading Heading={Heading} />
      <Optimizingresultsection />
      <Rectangleshape/>
      <RpsCard />
      <KeyFunctionalities/>
      <ContactSection/>
    </div>
  );
}

export default RPS;
