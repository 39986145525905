import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardMedia,
  Link,
  IconButton,
} from "@material-ui/core";
import { ReactComponent as HexagoneTag } from "../image/testseriesvector/hexagonimage.svg";
import { ReactComponent as CartBagIcon } from "../image/ebooksvector/cartbagicon.svg";
import BookImg from "../image/testseriesvector/testseriescardimage.png";
import FavouriteButton from "../practicecomponent/FavouriteButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  TestSeriesCard: {
    justifyContent: "space-between",
    maxWidth: "20.5%",
    display: "flex !important",
    flexDirection: "column",
    borderRadius: 7,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      justifyContent: "center",
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    "& .MuiTypography-body2": {
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiCardContent-root": {
      margin: "0 10px 0 20px",
      minHeight: "5rem",
      padding: "2px 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
    "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
      opacity: "0.3",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "15px",
      height: "16px",
      overflow: "hidden",
      whiteSpace: "wrap",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.75rem",
      fontWeight: "400",
      height: "35px",
      overflow: "hidden",
      whiteSpace: "wrap",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h4": {
      fontFamily: "'Jost', sans-serif",
      color: "#FF3939",
      fontSize: "0.9375rem",
      fontWeight: "500",
    },
    "& .MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      // width: "45% !important",
      borderRadius: "3%",
    },
    "& .MuiCardActionArea-root": {
      paddingTop: "10px",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h5": {
        fontSize: "1.2rem",
        marginBottom: 5,
      },
      "& .MuiCard-root": {
        "@media (min-width: 960px)": {
          width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
    },
  },
  cardimgcont: {
    width: "38%",
    position: "relative",
    background: "#FFE8B5",
    margin: ".6rem",
    borderRadius: 8,
    "&:hover": {
      background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    },
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.5rem",
    fontFamily: "Jost",
    fontWeight: "600",
    marginRight: "0.5rem",
  },
  offertext: {
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  newlaunchtag: {
    width: "51%",
    marginBottom: "0.8rem",
    padding: "0.3rem",
    background: "#FF3939",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    borderRadius: "8px",
    "& .MuiTypography-h6": {
      color: "#FFF",
      fontSize: "0.4375rem !important",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      textAlign: "center",
    },
  },
   quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "133% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
  },
  quikviewbtn: {
    "&:hover": {
      background: "white",
    },
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  favoritebtn: {
    // width: "29%",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
  },
  testserandvaliditytagcont: {
    display: "flex",
    margin: "1rem 0 1rem 0",
    position: "relative",
    justifyContent: "space-evenly",

    "& .MuiTypography-h6": {
      color: "#252525",
      fontSize: "0.625rem !important",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "500",
    },
  },
  innertags: {
    // background: "#FFF",
    width: "46%",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    padding: "0.1rem 0.4rem",
    borderRadius: "0.4rem",
    textAlign: "center",
  },
  hexagoncontainer: {
    position: "absolute",
    left: "-25px",
    bottom: "-21px",
  },
  cardimgandtitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardtitle: {
    width: "52%",
  },
  carticon: {
    marginRight: "0.6rem",
  },
  cardroot: {
    flexBasis: "33.3333%",
    // maxWidth: 280,
    maxHeight: 291,
    margin: "0 5px",
    marginBottom: "2rem",
  },
}));

function RecommendedTestSeriesCard({ data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);

  const handleCardHover = () => {
    setIsHover(!isHover);
  };
  return (
    <Card className={[classes.TestSeriesCard, classes.cardroot]}>
      <CardActionArea
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={()=>{
          navigate(`/testseries/${data?.id}`);
        }}
      >
        <div className={classes.cardimgandtitle}>
          <div className={classes.cardimgcont}>
            {isHover && (
              <div>
              <div className={classes.quikviewcontainer}>
                  <IconButton onClick={()=>navigate(`/testseries/${data?.id}`)} className={classes.quikviewbtn}>
                    <InfoIcon />
                  </IconButton>
                <div className={classes.favoritebtn}>
                  <FavouriteButton
                    isCheckedHeart={isCheckedHeart}
                    data={data}
                    setIsCheckedHeart={setIsCheckedHeart}
                  />
                </div>
              </div>
            </div>
            )}
            <CardMedia
              component="img"
              image={data?.image_url||BookImg}
              alt="green iguana"
            />
          </div>
          <div className={classes.cardtitle}>
            <div className={classes.newlaunchtag}>
              <Typography variant="h6">{data?.tag}</Typography>
            </div>
            <Typography gutterBottom variant="h5" component="div">
              {data?.bookname}
            </Typography>
            <Typography gutterBottom variant="caption" component="div">
              ( {data?.subtitle})
            </Typography>
          </div>
        </div>
        <CardContent>
          <div className={classes.testserandvaliditytagcont}>
            <div className={classes.innertags}>
              {/* <div className={classes.hexagoncontainer}>
                <HexagoneTag />
              </div> */}
              <Typography variant="h6">
                {" "}
                {data?.totaltestseries} testseries
              </Typography>
            </div>
            <div className={classes.innertags}>
              <Typography variant="h6">Valid: {data?.validity}</Typography>
            </div>
          </div>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ marginBottom: 0, marginTop: 0 }}
          >
            <span className={classes.textred}>
              <span style={{ fontSize: "1.5rem", verticalAlign: "top" }}>
                ₹
              </span>
              {data?.dropedprice}
            </span>
            <Typography variant="caption">
              MRP : <del>{data?.orignalprice}</del>{" "}
              <span className={classes.offertext}>({data?.offerpercent})</span>
            </Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.bgorange}>
              Buy Now
      </CardActions>
    </Card>
  );
}
export default RecommendedTestSeriesCard;
  