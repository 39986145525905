import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import EvaluationWorkImage from "../image/digitalevaluation/evaluationwork.png";
import StartImage from "../image/digitalevaluation/start.png";
import FinishImage from "../image/digitalevaluation/finish.png";
import DigitalEvaluationWorkImage from "../image/digitalevaluation/digitalevaluationwork.png";
import CustomTypography from "./typography";
import { leftParagraphs, rightParagraphs } from "./paragraph";
import MobileStepper from "./mobilestepper";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    margin:'2rem 0',
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  onlineeducationcontent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  EvaluationImg: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  // StartImg: {
  //   [theme.breakpoints.down("xs")]: {
  //     marginBottom: "10rem",
  //   },
  // },

  onlineeducationimgcontainer: {
    width: "50%",
    marginRight: "-194px",
    marginTop: "9rem",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "2rem 1rem",
    },
  },
  onlineeducationcontentinner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "10rem",
    marginRight: "5rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 0,
      marginRight: 0,
    },
  },
  onlineeducationcontentinner1: {
    marginTop: "8rem",
    marginLeft: "6rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  verticalLine: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "2px",
    backgroundColor: "#000",
    content: '""',
  },
}));

function WorkSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Enhancing Assessment Efficiency and Impact Through Innovative Solutions  ",
      headingmain: "Digital Evaluation Work",
    },
  ];

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        {isMobile ? <MobileStepper /> : null}
        <div className={classes.onlineeducationmain}>
        <img
              src={DigitalEvaluationWorkImage}
              alt="Start"
              className={classes.StartImg}
            />
          {/* <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              {leftParagraphs.map((paragraph, index) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                  <CustomTypography
                    variant="h5"
                    gutterBottom
                    className={classes.MuiTypographybody1}
                  >
                    {paragraph.heading}
                  </CustomTypography>

                  <CustomTypography
                    variant="MuiTypographybody2"
                    className={classes.MuiTypographybody2}
                    gutterBottom
                  >
                    {paragraph.content}
                  </CustomTypography>
                </div>
              ))}
            </div>
          </div>

          <div className={classes.onlineeducationimgcontainer}>
            <img
              src={StartImage}
              alt="Start"
              style={{
                height: "7rem",
                marginLeft: "4rem",
                marginTop: "-4rem",
              }}
              className={classes.StartImg}
            />
            <img
              src={EvaluationWorkImage}
              alt="Evaluation Work"
              style={{ width: "65%" }}
              className={classes.EvaluationImg}
            />
            <img
              src={FinishImage}
              alt="Finish"
              style={{ marginTop: "-2rem", marginLeft: "7.5rem" }}
              className={classes.FinishImg}
            />
          </div>

          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner1}>
              {rightParagraphs.map((paragraph, index) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                  <CustomTypography
                    variant="h5"
                    gutterBottom
                    className={classes.MuiTypographybody1}
                  >
                    {paragraph.heading}
                  </CustomTypography>

                  <CustomTypography
                    variant="MuiTypographybody2"
                    className={classes.MuiTypographybody2}
                    gutterBottom
                  >
                    {paragraph.content}
                  </CustomTypography>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default WorkSection;
