import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import SecHead from "../../Home/sectionHeading/sectionheading";
import teacher1 from "../../image/mytestseries/notes/teacher1.png";
import VideoCoursesCardMobile from "./VideoCoursesCardMobile";
import NotesCardVideoDash from "./NotesCardVideoDash";
import { getRecomndadOnlineVideo } from "../../../service/services";
import VideoCourse from "./videoCourse";
import VideoCoursesCardDash from "./VideoCoursesCardDash";
import OnlineVideo from "../../practicecomponent/cards/onlineVideo";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3rem",
    "& .slick-slide": {
      display: "flex",
      // width: "20% !important",
      height: '430px',
      justifyContent: "center",
      paddingTop: "0.5rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      '& div':{
        width:'100%',
      },
      [theme.breakpoints.down("xs")]: {
        padding: '2rem 1.5rem 2rem 1.5rem !important',
      height: '506px',
    },
    },
    '& .slick-dots': {
      [theme.breakpoints.down("xs")]: {
        bottom: '17px',
    },
   
  },
    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
  cardContainer: {
    // display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

const Data = [
  {
    id: 1,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "50.00",
    mrp: "20",
  },
  {
    id: 2,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "10",
    mrp: "20",
  },
  {
    id: 3,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "10",
    mrp: "20",
  },
  {
    id: 4,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "10",
    mrp: "20",
  },
  {
    id: 5,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "10",
    mrp: "20",
  },
  {
    id: 6,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "10",
    mrp: "20",
  },
  {
    id: 7,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "10",
    mrp: "20",
  },
  {
    id: 8,
    image_url: teacher1,
    note_name: "indian",
    sub_heading: "History Notes",
    section_name: "indian history",
    total_cost: "10",
    mrp: "20",
  },
];

const RecommendVideoCourse = () => {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore More",
      headingmain: "Recommended Video Courses",
    },
  ];

  let mql = window.matchMedia("(max-width: 650px)");
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getRecomndadOnlineVideo(token);
        console.log("API Response:", response.data.onlineVideos);
        setData(response.data.onlineVideos);
        console.log(data, "data");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const initialSlidesToShow = window.innerWidth < 480 ? 1 : 4;
  
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: initialSlidesToShow,
    slidesToScroll: initialSlidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow: newSlidesToShow,
        slidesToScroll: newSlidesToShow,
      }));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.cardContainer}>
          {/* {data.map((data) => (
            !mql.matches ? 
            // <OnlineVideo/>
            <VideoCoursesCardDash data={data}/>
             : <VideoCoursesCardMobile Data={data} />
          ))} */}
          <Slider {...sliderSettings} className={classes.sliderstyle}>
            {data.map((data) => (
              <VideoCoursesCardDash data={data} />
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RecommendVideoCourse;
