import {
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { ReactComponent as TableHeadIcon } from "../image/dashboard/tableheadvector.svg";
import CustomTableView from "../CustomComponent/TableView";
import CardForTable from "./CardForTable";
import { getSingaltestSeries, testSeriesLogin } from "../../service/services";
import axios from "axios";
import ReplayIcon from "@material-ui/icons/Replay";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.5rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  attempted: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.5rem",
      border: "0",
      background: "#243B55",
      borderRadius: "0",
      padding: "0.6rem 1.5rem",
    },
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#323232",
      [theme.breakpoints.down("xs")]: {
        color: "#FFF",
        fontSize: "0.9375rem",
        fontWeight: 600,
      },
    },
  },
  table: {
    minWidth: 700,
  },
  completedbtn: {
    "&.MuiButton-root": {
      border: "1px solid #00A019",
      color: "#00A019",
      textTransform: "capitalize",
      padding: "0.2rem 0.6rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "600",
      fontSize: "0.875rem",
    },
  },
  startbtn: {
    "&.MuiButton-root": {
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
    "&.MuiIconButton-root": {
      background: "#243B55",
      color: "#FFF",
      padding: "0.4rem",
      marginLeft: "0.5rem",
      borderRadius: "0.375rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      color: "#000",
      textTransform: "capitalize",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
}));

function TestSeriesInner(props) {
  const classes = useStyles();

  let mql = window.matchMedia("(max-width: 650px)");

  const setOnViewClicked = props.setOnViewClicked;
  const id = props.data.id;

  const [data, setData] = useState({});
  const [row, setRow] = useState([]);
  const [totalCompleted, setTotalCompleted] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getSingaltestSeries(id, token);
        console.log("API Response:", response.data?.test_series_bundle);
        setData(response.data?.test_series_bundle);
        setRow(response?.data?.test_series_bundle?.test_SeriesBundle_Relations);
        setTotalCompleted(
          response.data?.test_series_bundle?.completed_testseries_mock
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const rows = row?.map((data, index) => ({
    id: data?.test_series_id,
    bundleId: data?.test_series_bundle_id,
    name: data?.testSeries_Mock?.test_series_name || "N/A",
    total_marks: data?.testSeries_Mock?.total_score || "N/A",
    questions: `Ques: ${data?.testSeries_Mock?.no_of_question || "N/A"}`,
    duration: `${data?.testSeries_Mock?.duration || "N/A"} Min`,
    status: data?.testSeries_Mock?.status,
    content_path: data?.testSeries_Mock?.testSeries_url || "N/A",
    "sr.no": index + 1,
  }));

  const handleTestseriesLogin = async (rowId, bundleId, startOver) => {
    try {
      const token = localStorage.getItem("token");

      const data = {
        exam: {
          bundle_id: bundleId,
          test_series_id: rowId,
        },
      };

      // Call testSeriesLogin function
      const response = await testSeriesLogin(data, token);
      if (response.status === 200) {
        const responseData = response.data;
        const token = responseData.token;
        const userName = responseData.user.full_name;

        localStorage.setItem("userName", userName);
        localStorage.setItem("examToken", token);
        localStorage.setItem("test_series_id", rowId);

        const newTab = window.open(
          `${process.env.REACT_APP_API_TESTSERIES_URL}/?token=${token}&userName=${userName}`,
          "_blank"
        );

        if (newTab) {
          setTimeout(() => {
            newTab.location.href = `${process.env.REACT_APP_API_TESTSERIES_URL}/?token=${token}&userName=${userName}&test_series_id=${rowId}`;
          }, 1000);
        }

        // const newTab = window.open(
        //   `http://localhost:3002/?token=${token}&userName=${userName}`,
        //   "_blank"
        // );

        // if (newTab) {
        //   setTimeout(() => {
        //     newTab.location.href = `http://localhost:3002/?token=${token}&userName=${userName}&test_series_id=${rowId}`;
        //   }, 1000);
        // }
      }
    } catch (error) {
      // toast.error("Seller is Inactive so cannot login");
      console.error("Error in login: ", error);
    }
  };
  const MultiLineTextCellRenderer = ({ value }) => (
    <div style={{ whiteSpace: 'pre-line', lineHeight: "normal" }}>{value}</div>
  );
  const columns = [
    {
      field: "sr.no",
      headerName: "Sr. No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderHeader: (params) => (
        <div>
          <TableHeadIcon style={{ position: "relative", top: "0.2rem" }} />
          &nbsp;&nbsp;Test Series Name
        </div>
      ),
      renderCell: MultiLineTextCellRenderer
    },
    {
      field: "questions",
      headerName: "Questions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "total_marks",
      headerName: "Total Marks",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "duration",
      headerName: "Duration",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,

      renderCell: (cellValues) => {
        return cellValues.row.status === "completed" ? (
          <div>
            <Button disabled className={classes.completedbtn}>
              Completed
            </Button>
            <IconButton
              className={classes.startbtn}
              onClick={() =>
                handleTestseriesLogin(
                  cellValues.row.id,
                  cellValues.row.bundleId,
                  "startOver"
                )
              }
            >
              <Tooltip id="button-info" title="Start Over">
                <ReplayIcon style={{ fontSize: "large" }} />
              </Tooltip>
            </IconButton>
          </div>
        ) : cellValues.row.status === "start" ? (
          <Button
            className={classes.startbtn}
            onClick={() =>
              handleTestseriesLogin(cellValues.row.id, cellValues.row.bundleId)
            }
          >
            Start Test
          </Button>
        ) : (
          <Button
            onClick={() =>
              handleTestseriesLogin(cellValues.row.id, cellValues.row.bundleId)
            }
            className={classes.startbtn}
          >
            Resume
          </Button>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <IconButton
            onClick={() => {
              setOnViewClicked("");
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h3">{data?.bundle_name}</Typography>
        </div>
        <div className={classes.attempted}>
          <Typography variant="h6">
            Attempted : ({totalCompleted} / {rows?.length})
          </Typography>
        </div>
      </div>

      {!mql.matches ? (
        rows ? (
          <CustomTableView
            columns={columns}
            rows={rows}
            section={"myTestSeriesTable"}
          />
        ) : (
          <div>Loading...</div>
        )
      ) : (
        rows.map((data, index) => (
          <CardForTable
            data={data}
            handleTestseriesLogin={handleTestseriesLogin}
            key={index}
          />
        ))
      )}
    </div>
  );
}

export default TestSeriesInner;
