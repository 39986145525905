import React from 'react'
import MyWallefirst from './myWallefirst'
import TransactionHistory from './transactionHistory'

const MyWallet = () => {
  return (
    <>
    <MyWallefirst/>
    <TransactionHistory/>
  
    </>
    
  )
}

export default MyWallet

