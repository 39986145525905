import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardMedia,
  Box,
  IconButton,
  LinearProgress,
  Avatar,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { Link, useNavigate } from "react-router-dom";
import videoicon from "../../image/dashboard/videocourse/videoicon.png";
import booklogo from "../../image/testseriesvector/booklogo.svg";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme) => ({
  series: {
    background: "#FFF",
    width: "30%",
    height: "auto",
    margin: "0 1rem 1rem 1rem",
    padding: "0.5rem",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: 7,
    "& .MuiTypography-h6": {
      fontFamily: " Playfair Display !important",
      fontSize: "0.9375rem",
      fontWeight: "500",
      whiteSpace: "wrap",
      height: "67px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginBottom: "1rem",
      margin: "0 auto",
    },
  },
  text: {
    fontFamily: "Playfair Display !important",
    fontSize: "0.9375rem !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    textAlign: "left",
    marginLeft: "1rem",
  },
  clipandseriesbox: {
    minHeight: "161px",
    display: "flex",
    alignItems: "center",
    background: "#FFEFCB",
    padding: "0.2rem 0.5rem",
    borderRadius: "3px",
  },
  seriesbox: {
    width: "50%",
  },
  clips: {
    display: "flex",
    padding: "0.5rem",
    background: "#FFF",
    borderRadius: "100%",
    width: "50%",
    "& .MuiAvatar-root": {
      height: "100%",
      width: "100%",
    },
  },
  secondseries: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.8rem",
    background: "white",
  },
  innersection: {
    width: "48%",
    border: "1px solid #EEE",
    borderRadius: "5px",
    background: " var(--white-100, #FFF)",
    padding: "0.5rem",
  },
  logoandnumber: {
    display: "flex",
    alignItems: "center",
  },
  theory: {
    color: "red",
    fontFamily: "Jost !important",
    fontSize: "1.25rem",
    fontWeight: "600",
    marginLeft: "0.2rem",
  },
  totalseries: {
    fontFamily: "Jost",
    fontSize: "0.625rem",
    fontStyle: "normal",
    color: "#262626",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
      textAlign: "center",
    },
  },
  fulllength: {
    margin: "0 auto",
    fontFamily: "Jost",
    fontSize: "0.7rem",
    color: "#000",
    fontWeight: "400",
  },
  length: {
    display: "flex",
    marginTop: "0.8rem",
  },
  progessbar: {
    marginTop: "0.8rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  percent: {
    width: "13% !important",
    fontFamily: "Jost",
    fontSize: "0.7rem",
    fontStyle: "normal",
    color: "#000",
    fontWeight: "500",
  },
  seemorebox: {
    marginTop: "1rem",
    background: "#243B55",
    padding: "0.3rem 0",
    borderRadius: "6px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "black",
      textDecoration: "none",
    },
    "& .MuiButton-label": {
      fontSize: "0.875rem",
      fontFamily: "Jost",
      color: "#FFF",
      fontWeight: "400",
    },
  },
}));

function VideoCourseCard({ CardData, setOnViewClicked, Data }) {
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);
  const handleCardHover = () => {
    setIsHover(!isHover);
  };

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#FFEFCB",
      width: "84%",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#00A019",
    },
  }))(LinearProgress);
  return (
    <>
      <div className={classes.series}>
        <div className={classes.clipandseriesbox}>
          <div className={classes.clips}>
            <Avatar src={Data?.image_url || videoicon} alt="videoicon" />
          </div>

          <div className={classes.seriesbox}>
            <Typography gutterBottom variant="h6" className={classes.text}>
              {/* Test series for {Data?.sub_heading}/{Data?.product_name} Exams */}
              {Data?.video_name}
            </Typography>
          </div>
        </div>

        <div className={classes.secondseries}>
          <div className={classes.innersection}>
            <div className={classes.logoandnumber}>
              <img src={booklogo} alt="logo" width="20px" height="20px" />
              <Typography variant="h5" className={classes.theory}>
                {Data?.online_video_lectures}
              </Typography>
            </div>
            <Typography variant="h5" className={classes.totalseries}>
              Total Online Videos
            </Typography>
          </div>
          <div className={classes.innersection}>
            <div className={classes.logoandnumber}>
              <img src={booklogo} alt="logo" width="20px" height="20px" />
              <Typography variant="h5" className={classes.theory}>
                {Data?.completed_lectures}
              </Typography>
            </div>
            <Typography variant="h5" className={classes.totalseries}>
              Completed Online Videos
            </Typography>
          </div>
        </div>

        {/* <div className={classes.length}>
          <Typography variant="caption" className={classes.fulllength}>
            Full Length | Topic-wise | Sectional
          </Typography>
        </div> */}
        <div className={classes.progessbar}>
          <BorderLinearProgress
            variant="determinate"
            value={Data?.completed_percent}
          />
          <Typography variant="h5" className={classes.percent}>
            {parseFloat(Data?.completed_percent).toFixed(2)}%
            {/* {Data?.completed_percent}% */}
          </Typography>
        </div>

        <div>
          <Button
            onClick={() => {
              setOnViewClicked("testseriesinner", Data);
            }}
            fullWidth
            className={classes.seemorebox}
          >
            SEE MORE
          </Button>
        </div>
      </div>
    </>
  );
}
export default VideoCourseCard;
