import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CustomTableView from "../../CustomComponent/TableView";
import historyicon from "../../image/dashboard/mywallet/historyicon.png";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    border: "1px solid #ADADAD",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0rem",
    },
    "& .MuiTypography-h3": {
      color: "#393939",
      fontFamily: " Playfair Display",
      fontSize: "1.375rem",
      fontWeight: 700,
      lineHeight: " 1.25rem",
    },
  },
  headercontainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  field1: {
    margin: "0rem 2rem",

    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0rem",
      width: "48%",
    },
  },
  textfiled: {
    "& .MuiInputBase-input": {
      width: "80px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px",
    },
    borderRadius: " 0.375rem",
    border: "1px solid #C8C8C8",
  },
  table: {
    minWidth: 700,
  },
  completedbtn: {
    "&.MuiButton-root": {
      border: "1px solid #238CEC",
      color: "#238CEC",
      textTransform: "capitalize",
      padding: "0.2rem 0.6rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "600",
      fontSize: "0.875rem",
    },
  },
  success: {
    color: "#0EA136",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
    letterSpacing: "-0.0175rem",
  },
  fail: {
    color: "#FF3939",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
    letterSpacing: "-0.0175rem",
  },
  filtercontainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const TransactionHistory = (props) => {
  const classes = useStyles();
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  let mql = window.matchMedia("(max-width: 650px)");

  const columns = [
    {
      field: "id",
      headerName: "Sr. No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "name",
      headerName: "Study Materials",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 700,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 700,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "transactiondate",
      headerName: " Transaction date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 700,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },

    {
      field: "transactiontype",
      headerName: "Transaction Type",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 700,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "transactiondetail",
      headerName: "Transaction detail",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 700,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return cellValues.row.transactiondetail === "Sucessfull" ? (
          <span className={classes.success}>Sucessfull</span>
        ) : (
          <span className={classes.fail}>Failed</span>
        );
      },
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   width: 110,
    //   flex: 1,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   autoPageSize: false,
    //   renderCell: (cellValues) => {
    //     return cellValues.row.type === "view" ? (
    //       <Button disabled className={classes.completedbtn}>
    //         View
    //       </Button>
    //     ) : cellValues.row.type === "start" ? (
    //       <Button className={classes.startbtn}>Start Test</Button>
    //     ) : (
    //       <Button disabled className={classes.disabledbtn}>
    //         Start Test
    //       </Button>
    //     );
    //   },
    // },
  ];

  const columnsmobile = [
    {
      field: "id",
      headerName: "Sr. No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 65,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "name",
      headerName: "Study Materials",
      headerClassName: "super-app-theme--header",
      cellClassName: "productnamecell",
      headerAlign: "center",
      width: 100,
      // flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 71,
      // flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "transactiondetail",
      headerName: "Transaction detail",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 100,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return cellValues.row.transactiondetail === "Sucessfull" ? (
          <span className={classes.success}>Sucessfull</span>
        ) : (
          <span className={classes.fail}>Failed</span>
        );
      },
    },
  ];
  const rows = [
    {
      id: "01",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "02",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "03",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Failed",
      type: "view",
    },
    {
      id: "04",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "05",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "06",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Failed",
      type: "view",
    },
    {
      id: "07",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "08",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "09",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "10",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Failed",
      type: "view",
    },
    {
      id: "11",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "12",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "13",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Failed",
      type: "view",
    },
    {
      id: "14",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
    {
      id: "15",
      name: "Advance Reasoning Books",
      amount: "2000",
      transactiondate: "18 Apr 2021",
      transactiontype: "UPI",
      transactiondetail: "Sucessfull",
      type: "view",
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headercontainer}>
          <div>
            <Typography variant="h3">
              <img src={historyicon} alt="img" />
              &nbsp;&nbsp; Transaction History
            </Typography>
          </div>
          <div className={classes.filtercontainer}>
            <FormControl variant="outlined" className={classes.field1}>
              <Select
                className={classes.textfiled}
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                disableUnderline
                MenuProps={menuProps}
                value={-1}
              >
                <MenuItem value={-1}>Month</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.field1}>
              <Select
                className={classes.textfiled}
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                disableUnderline
                MenuProps={menuProps}
                value={-1}
              >
                <MenuItem value={-1}>Filters</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <CustomTableView
          columns={!mql.matches ? columns : columnsmobile}
          rows={rows}
          section={"transactionTable"}
        />
      </div>
    </>
  );
};

export default TransactionHistory;
