import React, { useState } from "react";
import useStyles from "../../../styles";
import {
  Typography,
  Step,
  StepLabel,
  Stepper,
  makeStyles,
  StepConnector,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import { PiNotebook } from "react-icons/pi";
import { FiPackage } from "react-icons/fi";
import { PiTruckLight } from "react-icons/pi";
import { PiHandshakeLight } from "react-icons/pi";

const useQontoStepIconStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiStepper-root": {
        padding: " 1rem 0.1rem !important",
      },
    },
  },
  root: {
    color: "#fff",
    border: "1px solid #AEAEAE",
    borderRadius: "100%",
    display: "flex",
    // height: 22,
    alignItems: "center",
  },
  active: {
    color: "#23D317",
  },
  circle: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    [theme.breakpoints.down("xs")]: {
      width: 15,
      height: 15,
    },
  },
  completed: {
    color: "#FFF",
    zIndex: 1,
    fontSize: 18,
    padding: "0.2rem",
    background: "#23D317",
    borderRadius: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  stepcustom: {
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      color: "#2DB224 !important",
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      color: "#FAAA32",
    },
    "& .MuiTypography-h6": {
      fontSize: "0.875rem",
      fontFamily: "Jost",
      fontWeight: "500",
      color: "#191C1F",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.61rem",
      },
    },
  },
  orderstatusicon: {
    fontSize: "1.7rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
      },
  },
}));

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#23D317",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#23D317",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 6,
    borderRadius: 1,
    position: "relative",
    top: "-2px",
    [theme.breakpoints.down("xs")]: {
      borderTopWidth: 5,
      top: "-3px",
    },
  },
}))(StepConnector);

function OrderStatus({date}) {
  const classes = useQontoStepIconStyles();
  function QontoStepIcon(props) {
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <Check className={classes.completed} />
        ) : (
          <div className={classes.circle} />
        )}
      </div>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
  };
  const classesCustom = useStyles();

  function getSteps() {
    return [
      <div>
        <PiNotebook className={classes.orderstatusicon} />
        <Typography variant="h6">Order Placed</Typography>
      </div>,
      <div>
        <FiPackage className={classes.orderstatusicon} />
        <Typography variant="h6">Packaging</Typography>
      </div>,
      <div>
        <PiTruckLight className={classes.orderstatusicon} />
        <Typography variant="h6">On The Road</Typography>
      </div>,
      <div>
        <PiHandshakeLight className={classes.orderstatusicon} />
        <Typography variant="h6">Delivered</Typography>
      </div>,
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return "Order Placed";
      case 1:
        return "Packaging";
      case 2:
        return "On The Road";
      default:
        return "Delivered";
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();
  return (
    <div className={classes.container}>
      <Typography
        variant="caption"
        className={`${classesCustom.fontsize1} ${classesCustom.fw400} ${classesCustom.fontfamiljost} ${classesCustom.textcolorgrey}`}
      >
        Order expected arrival{" "}
        <span
          className={`${classesCustom.fw500} ${classesCustom.textcolorlowblack}`}
        >
          {date}
        </span>
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={`${classes.stepper} ${classes.stepcustom}`}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default OrderStatus;
