import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import SinglePageContent from "./singlepagecontent";
import PageHead from "../../../pageheading/pageheading";
import practicecomponentcontext from "../../context";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

function NotesSinglePageDescription() {
  const classes = useStyles();
  const {id} = useParams()
  const {getOnlineVideoDetails , onlinevideoDetail} = useContext(practicecomponentcontext);
  
  const Heading = [
    {
      id: 1,
      // headingsmall: "Explore Sakshi Agrawal’s 245 Lectures on SBI PO",
      // headingmain: "SBI PO 2023 Ignite Quant with Sakshi",
      breadcrumb:"Online Videos / Product",
    },
  ];
  console.log("getOnlineVideoDetails",onlinevideoDetail)

  useEffect(()=>{
    getOnlineVideoDetails(id)
  },[id])
  console.log('onlinevideoDetail' , onlinevideoDetail)
  return (
    <div>
      <PageHead Heading={Heading} onlinevideoDetail={onlinevideoDetail}/>
      <SinglePageContent  onlinevideoDetail={onlinevideoDetail}/>
    </div>
  );
}
export default NotesSinglePageDescription;
