import { makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import React from "react";
import LatestTestSeriesCardFree from "../../../Home/latesttestseriessection/LatestTestSeriesCardFree";
import SecHead from "../../../Home/sectionHeading/sectionheading";
const useStyles = makeStyles((theme) => ({
  TestSeriesCard: {
    marginTop: "1rem",
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0.5rem",
    },

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
}));
function FreeMocksSection({ testseriesDetail, bundleId }) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Heading = [
    {
      id: 1,
      headingsmall: "Get Ahead",
      headingmain: "Explore our Free Mock Series",
    },
  ];
  const classes = useStyles();
  console.log("FreeMockSeries12", testseriesDetail);
  return (
    <>
      <div className={classes.TestSeriesCard}>
        <SecHead Heading={Heading} />
        <Slider {...settings}>
          {testseriesDetail?.map((data, index) => (
            <LatestTestSeriesCardFree
              data={data}
              key={index}
              bundleId={bundleId}
            />
          ))}
        </Slider>
      </div>
    </>
  );
}
export default FreeMocksSection;
