import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppState from "./context/AppState";
import QuizState from "./component/practicecomponent/context/state";
import PracticeState from "./component/practicecomponent/context/PracticeContext/state";
import PypPracticeState from "./component/practicecomponent/context/PypPracticeContext/state";
import FreeMockPracticeState from "./component/practicecomponent/context/FreeMockPracticeContext/state";
import CategoryState from "./context/CategoryContext/CategoryState";
import AddCartState from "./context/AddCartContext/AddCartState";
import WishlistState from "./context/WishlistContext/WishlistState";
import PopupState from "./context/PopupContext/PopupState";
import { BrowserRouter } from "react-router-dom";
import FetchProfileState from "./context/FetchUserProfile/FetchProfileState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppState>
    <CategoryState>
      <QuizState>
        <PypPracticeState>
          <FreeMockPracticeState>
            <AddCartState>
              <WishlistState>
                <PopupState>
                  <FetchProfileState>
                    <PracticeState>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </PracticeState>
                  </FetchProfileState>
                </PopupState>
              </WishlistState>
            </AddCartState>
          </FreeMockPracticeState>
        </PypPracticeState>
      </QuizState>
    </CategoryState>
  </AppState>
);
