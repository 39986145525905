import React from "react";
import { makeStyles } from "@material-ui/core";
import ProductDetails from "./productdetails";
import SimpleTabs from "./tabpannel";
import FAQSection from "./FAQSection";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    width: "100%",
    background: "#FFFCF4",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto 12rem",
    "& .MuiAppBar-colorPrimary": {
      background: "none",
      color: "#000000",
    },
  },
}));
function SinglePageContent({eBookDetail}) {
  const classes = useStyles();
  if (!eBookDetail) return null;
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div>
            <ProductDetails eBookDetail={eBookDetail} />
        </div>
            <SimpleTabs eBookDetail={eBookDetail} />
            <FAQSection eBookDetail={eBookDetail}/>
      </div>
    </div>
  );
}
export default SinglePageContent;
