import React from "react";
import { Breadcrumbs, Link, Typography, makeStyles } from "@material-ui/core";
import PageHeadingBg from "../image/pageheadbg/pageheadingbg.jpg";
import SearchInput from "./SearchInput";

const useStyles = makeStyles((theme) => ({
  pageheadingmain: {
    backgroundImage: `url(${PageHeadingBg})`,
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "left center",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding: "1rem 0rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h2": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1.5625rem",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 6,
      },
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontWeight: "500",
      fontSize: "1rem",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    "& .MuiTypography-colorInherit": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.9375rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
    "& .MuiTypography-body1": {
      marginLeft: "0.3rem",
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "#FF8F12",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.9375rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem !important",
    },
  },
  pageheading: {
    padding: "1rem 0",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0",
    },
  },
  pageheadhr: {
    margin: "0",
    width: "6rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  breadcrumb: {
    display: "flex",
    alignItems: "center",
  },
  SearchInput: {
    width: "25%",
  },
}));
function PageHead(props) {
  const classes = useStyles();
  const {
    Heading,
    bookDetail,
    eBookDetail,
    onlinevideoDetail,
    liveclassesDetail,
    noteDetail,
    testseriesDetail,
    notData,
  } = props;

  console.log("liveclassesoDetail123", liveclassesDetail);
  return (
    <div className={classes.pageheadingmain}>
      <div className={classes.container}>
        {Heading.map((data) => (
          <div className={classes.pageheading}>
            <Typography variant="h2" component="h2" gutterBottom>
              {bookDetail?.book_name} {eBookDetail?.ebook_name}
              {onlinevideoDetail?.video_name}
              {liveclassesDetail?.live_class_name}
              {noteDetail?.note_name}
              {testseriesDetail?.bundle_name}
              {notData?.name} {data?.headingmain}
              <hr className={classes.pageheadhr} />
            </Typography>
            <div className={classes.breadcrumb}>
              <Link color="inherit" href="/">
                Home /
              </Link>
              <Typography color="inherit"> {data.breadcrumb}</Typography>
            </div>
          </div>
        ))}
        {Heading.map((item, index) => (
          <div key={index} className={classes.SearchInput}>
            {item.search === "yes" && <SearchInput />}
          </div>
        ))}
      </div>
    </div>
  );
}
export default PageHead;
