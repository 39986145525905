import { ReactComponent as Practicelogo } from "../image/mobilehambergervector/practice.svg";
import { ReactComponent as BooksLogo } from "../image/mobilehambergervector/books.svg";
import { ReactComponent as LiveClasseslogo } from "../image/mobilehambergervector/MyLiveClasses.svg";
import { ReactComponent as OnlineVideologo } from "../image/mobilehambergervector/MyVideoCourses.svg";
import { ReactComponent as StudyMateriallogo } from "../image/mobilehambergervector/MyStudyMaterials.svg";
import { ReactComponent as TestSerieslogo } from "../image/mobilehambergervector/MyTestSeries.svg";
import { ReactComponent as PreviousYearPaperlogo } from "../image/mobilehambergervector/Previousyearpaper.svg";
import { ReactComponent as Ebookslogo } from "../image/mobilehambergervector/E-books.svg";
import { ReactComponent as Quizzlogo } from "../image/mobilehambergervector/Quizz.svg";
import { ReactComponent as NotesLogo } from "../image/mobilehambergervector/notes.svg";
import { ReactComponent as QuickLinksLogo } from "../image/mobilehambergervector/quicklinks.svg";
import { ReactComponent as HelpDesk } from "../image/mobilehambergervector/helpdesk.svg";
import { ReactComponent as Settings } from "../image/mobilehambergervector/setting.svg";
import { ReactComponent as Logout } from "../image/mobilehambergervector/logout.svg";
import { ReactComponent as ReferandEarn } from "../image/mobilehambergervector/referandearn.svg";
import { ReactComponent as BiometricIcon } from "../image/mobilehambergervector/biometricicon.svg";
import { ReactComponent as DigitalEvaluationIcon } from "../image/mobilehambergervector/digitalevaluationicon.svg";
import { ReactComponent as LmsIcon } from "../image/mobilehambergervector/lmsicon.svg";
import { ReactComponent as OnlineAssessmentIcon } from "../image/mobilehambergervector/onlineassessmenticon.svg";
import { ReactComponent as RpsIcon } from "../image/mobilehambergervector/rpsicon.svg";

const storedObject = localStorage.getItem("token");

const Heading = [
  {
    id: 0,
    name: "My Account",
    type: "dropdown",
    icon: "MyDashboard",
    style: "link",
    to: "/testseries",
    isLoggedInRequired: true,
    menu: [
      {
        id: 1,
        index_id: 1,
        name: "My Dashboard",
        icon: "MyDashboard",
        path: "/admin/dashboard",
      },
      {
        id: 2,
        index_id: 2,
        name: "My Test Series",
        icon: "MyTestSeries",
        path: "/admin/testseries",
      },
      {
        id: 3,
        index_id: 3,
        name: "My Study Materials",
        icon: "MyStudyMaterials",
        path: "/admin/mystudymaterials",
      },
      {
        id: 4,
        index_id: 4,
        name: "My Books",
        icon: "MyBooks",
        path: "/admin/mybooks",
      },
      {
        id: 5,
        index_id: 5,
        name: "My Video Courses",
        icon: "MyVideoCourses",
        path: "/admin/myvideocourses",
      },
      {
        id: 6,
        index_id: 6,
        name: "My Live Classes",
        icon: "MyLiveClasses",
        path: "/admin/myliveclasses",
      },
      {
        id: 8,
        index_id: 8,
        name: "My Orders",
        icon: "MyPurchase",
        path: "/admin/myorder",
      },
      {
        id: 9,
        index_id: 9,
        name: "My Achievements",
        icon: "MyAchievements",
        path: "/admin/myachievements",
      },
    ],
  },
  {
    id: 1,
    name: "Practice",
    type: "dropdown",
    icon: "practice",
    style: "link",
    to: "/testseries",
    status: true,
    menu: [
      {
        name: "Quizzes",
        to: "/quizzes",
        icon: "Quizz",
      },
      {
        name: "Previous Year Papers",
        to: "/previousyearpapers",
        icon: "Previousyearpaper",
      },
    ],
  },
  {
    id: 2,
    name: "Test Series",
    type: "link",
    icon: <TestSerieslogo />,
    style: "link",
    to: "/testseries",
    status: true,
  },
  {
    id: 3,
    name: "Study Materials",
    type: "dropdown",
    icon: "MyStudyMaterials",
    style: "link",
    to: "/studymaterial",
    status: true,
    menu: [
      {
        name: "Notes",
        to: "/notes",
        icon: "notes",
      },
      {
        name: "E-Books",
        to: "/ebooks",
        icon: "E-books",
      },
    ],
  },
  {
    id: 4,
    name: "Books",
    type: "link",
    icon: <BooksLogo />,
    style: "link",
    to: "/books",
    status: true,
  },
  {
    id: 5,
    name: "Live Classes",
    type: "link",
    icon: <LiveClasseslogo />,
    style: "link",
    to: "/liveclasses",
    status: true,
  },
  {
    id: 6,
    name: "Online Videos",
    type: "link",
    icon: <OnlineVideologo />,
    style: "link",
    to: "/onlinevideos",
    status: true,
  },
  {
    id: 7,
    name: "Quick Links",
    type: "dropdown",
    icon: "quicklinks",
    style: "link",
    to: "/testseries",
    status: true,
    menu: [
      {
        name: "Biometric",
        to: "/biometric",
        icon: "biometricicon",
      },
      {
        name: "Online Assessment",
        to: "/onlineassessment",
        icon: "onlineassessmenticon",
      },
      {
        name: "Digital Evaluation",
        to: "/digitalevaluation",
        icon: "digitalevaluationicon",
      },
      {
        name: "RPS",
        to: "/rps",
        icon: "rpsicon",
      },
      {
        name: "LMS",
        to: "/lms",
        icon: "lmsicon",
      },
      // {
      //   name: "Coachings",
      //   to: "/coachings",
      //   icon: <Ebookslogo />,
      // },
      // {
      //   name: "Teachers",
      //   to: "/teachers",
      //   icon: <Ebookslogo />,
      // },
    ],
  },
  // {
  //   id: 13,
  //   name: "Refer And Earn",
  //   type: "dashboardlink",
  //   icon: <ReferandEarn />,
  //   style: "link",
  //   to: "/dashboard",
  //   isLoggedInRequired: true,
  // },
  {
    id: 14,
    name: "Help Desk",
    type: "dashboardlink",
    icon: <HelpDesk />,
    style: "link",
    to: "/dashboard",
    isLoggedInRequired: true,
  },
  {
    id: 15,
    name: "Settings",
    type: "dashboardlink",
    icon: <Settings />,
    style: "link",
    to: "/dashboard",
    isLoggedInRequired: true,
  },
  {
    id: 13,
    name: "Logout",
    type: "link",
    icon: <Logout />,
    style: "link",
    to: "/",
    isLoggedInRequired: true,
  },
];
export default Heading;
