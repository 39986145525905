import { makeStyles } from "@material-ui/core";
import React from "react";
import SecHead from "../sectionHeading/sectionheading";
import InstitutionalPartnersContent from "./institutionalpartnerscontent";


const useStyles = makeStyles((theme) => ({
  InstitutionalPartnersMain: {
    margin: "2.2rem 0 15rem 0",
    [theme.breakpoints.down("sm")]: {
      marginTop: '3.2rem',
      marginBottom: '12rem',
    }
  },
  containerfluid: {
    width: "100%",
    "& .slick-track": {
      height: '150px'
    },
    "& .slick-prev, .slick-next": {
      background: 'black',
      padding: '15px',
      borderRadius: '40px',

    },
    "& .slick-arrow": {
      opacity: '0.45',
      "&:hover": {
        opacity: '1',
      },
    },
    "& .slick-prev:before, .slick-next:before": {
      position: 'absolute',
      fontSize: '55px',
      borderRadius: '40px',
      top: '20px',
      left: '15px',
      transform: 'translate(-50%, -50%)',
      content: "→",
      color: 'white',
      opacity: '1',
      boxShadow: '0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    "& .slick-slide": {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0rem 0rem !important',
      "&div": {
        width: '435px',
      }
    },

    "& .slick-dots li button:before": {
      color: '#FF8008',
      fontSize: '15px',
    },
    '& .slick-dots': {
      bottom: "-5px",
  },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
}));
function InstitutionalPartnersSection() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Get Ahead",
      headingmain: "Our Institutional Partners",
    },
  ];
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <>
      <div className={classes.InstitutionalPartnersMain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <SecHead Heading={Heading} media={mql.matches}
            />
            <InstitutionalPartnersContent />
          </div>
        </div>
      </div>
    </>
  );
}
export default InstitutionalPartnersSection;
