import React, { useState, memo } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FavouriteButton from "../FavouriteButton";
import { useNavigate } from "react-router-dom";
import BuyNowButton from "../BuyNowButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.6rem",
    display: "flex",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "0",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.725rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.6rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body2": {
      margin: "0.2rem 0",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.6rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.5rem",
      textTransform: "uppercase",
      background: "#00A911",
      opacity: "0.6",
      color: "white",
      padding: "0.2rem 1rem",
      borderRadius: "15px",
      // marginLeft: "0.6rem",
    },
    "& .MuiRating-root": {
      fontSize: "1rem",
    },
    "& .MuiCardActions-root": {
      borderRadius: 6,
      padding: "0.2rem",
      "& .MuiButton-label": {
        fontFamily: "Jost",
        fontSize: "0.7rem",
        alignItems: "flex-start",
      },
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "92%",
      padding: "0",
      margin: "0",
    },
    "& .MuiCardContent-root": {
      padding: "6px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0.6rem",
      // padding: '1rem 1rem',
    },
  },
  cardimagecontainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    position: "relative",
  },
  cardcontent: {
    width: "65%",
    padding: "0rem 0rem 0rem 0.5rem !important",
  },
  favoritebtn: {
    // width: "14%",
    position: "absolute",
    top: "3px",
    left: "3px",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
    "& .MuiIconButton-root": {
      width: "70%",
      padding: "0 2px",
    },
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.125rem",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "600",
    marginRight: "0.3rem",
    // marginTop: "0.8rem",
  },
  offertext: {
    marginLeft: "0.5rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.6rem",
    fontWeight: "400",
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  cartbagicon: {
    marginRight: "0.4rem",
  },
}));
const EBookCardMobile = memo((props) => {
  const { data } = props;
  const classes = useStyles();
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  const navigate = useNavigate();
  return (
    <Card
      className={classes.root}
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/ebook/${data?.id}`);
      }}
    >
      <div className={classes.cardimagecontainer}>
        <div className={classes.favoritebtn}>
          <FavouriteButton
            isCheckedHeart={isCheckedHeart}
            data={data}
            setIsCheckedHeart={setIsCheckedHeart}
          />
        </div>
        <img
          src={data?.images[0] || require("../../image/notFoundData.png")}
          alt="img"
          width="120px"
          height="120px"
        />
      </div>
      <CardContent className={classes.cardcontent}>
        <Typography gutterBottom variant="h5" component="div">
          {data?.ebook_name}{" "}
          {data?.sub_heading && <span> {data?.sub_heading}</span>}
        </Typography>
        <Typography variant="caption" display="block">
          by {data?.author}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span className={classes.textred}>₹{data?.selling_cost}</span>
          <Typography variant="caption">
            MRP : <del>{data?.mrp}</del>
          </Typography>
          <span className={classes.offertext}>
            ({data?.discount_percent}% off)
          </span>
        </Typography>
        <CardActions className={classes.bgorange}>
          <BuyNowButton data={data} />
        </CardActions>
      </CardContent>
    </Card>
  );
});
export default EBookCardMobile;
