import {
  Button,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import ErrorImg from "../../image/dashboard/MyAchivmentErrorpage.png";
import ErrorImgMobile from "../../image/dashboard/MyAchivmentErrorpagemobile.png";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    width: "100%",
    height: "auto",
    background: "#FFFFFF",
    padding: "10px",
    marginTop: "2rem",
  },
  head1: {
    color: "#243B55",
    textAlign: "center",
    fontFamily: " Playfair Display",
    fontSize: "64px",
    fontWeight: "700",
  },
  head2: {
    color: "#243B55",
    textAlign: "center",
    fontFamily: "Playfair Display",
    fontSize: "22px",
    fontWeight: "600",
    marginTop: "1rem",
  },
  head3: {
    color: "#243B55",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "0rem",
  },
  btn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    textAlign: "center",

    fontFamily: "Jost",
    fontSize: "15px",
    fontWeight: "500",
    color: "white",
    padding: "0.6rem",
    borderRadius: "0.4rem",
    margin: "3rem",
    marginLeft: "8rem",
  },
  maindiv: {
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "80%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        content: `url(${ErrorImgMobile})`,
      },
    },
  },

}));

const EbooksErrorPage = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.maindiv}>
            <img src={ErrorImg} alt="img" />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default EbooksErrorPage;
