import {
  GET_QUIZ_QUESTIONS,
  SET_CURRENT_SECTION,
  SET_CURRENT_QUESTION,
  SET_QUESTION_ANSWER,
  SET_TIME_TAKEN
} from "./type";

const Reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_QUIZ_QUESTIONS: {
      return {
        ...state,
        quizQuestionObj: payload,
      };
    }

    case SET_CURRENT_SECTION: {
      return {
        ...state,
        currentSection: payload
      };
    }

    case SET_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: payload
      };
    }

    case SET_QUESTION_ANSWER: {
      const { currentSection, currentQuestion } = state;
    
      const updatedSections = state.quizQuestionObj.sections.map((section, sIndex) => {
        if (sIndex === currentSection) {
          const updatedQuestions = section.questions.map((question, qIndex) => {
            if (qIndex === currentQuestion - 1) {
              const updatedOptions = question.options.map((option, oIndex) => {
                return {
                  ...option,
                  user_answer: oIndex === payload
                };
              });
              return { ...question, options: updatedOptions };
            }
            return question;
          });
    
          return { ...section, questions: updatedQuestions };
        }
        return section;
      });
    
      return {
        ...state,
        quizQuestionObj: { ...state.quizQuestionObj, sections: updatedSections }
      };
    }
    

    case SET_TIME_TAKEN: {
      return {
        ...state,
        timeTaken: payload
      };
    }

    default:
      return state;
  }
};

export default Reducer;
