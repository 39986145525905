import {
  Button,
  Card,
  CardActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import TeachersData from "./teachersdata";
import Slider from "react-slick";
import CardBg from "../../image/catergoryVectoe/Cwgf.gif";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 380,
    height: "397px",
    paddingTop: "3.4rem",
    margin: "0 auto",
    background: "#FFF7E4",
    boxShadow: "none",
    "&:hover": {
      backgroundImage: `url(${CardBg})`,
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      backgroundPosition: "center",
      backgroundSize: "cover",
      "& .MuiCardActions-root": {
        background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
      },
    },
    "& .MuiCardActions-root": {
      padding: "0",
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%);",
      borderRadius: 7,
    },
    "& .MuiButton-root": {
      "& .MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      },
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#252525",
      fontSize: "1.3rem",
      fontWeight: "700",
      marginBottom: "0",
      textTransform: "capitalize",
    },
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9rem",
      fontWeight: "400",
      color: "#252525",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  },

  "& .slick-prev, .slick-next": {
    background: "black",
    padding: "15px",
    borderRadius: "40px",
    "&:hover": {
      color: "#FF8008",
    },
  },

  "& .slick-prev:before, .slick-next:before": {
    fontSize: "55px",
    borderRadius: "40px",
    transform: "translate(-50%, -50%)",
    content: "→",
    color: "white",
    opacity: "1",
    bottom: 0,
    boxShadow:
      "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  "& .slick-slide": {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0.5rem",
  },

  "& .slick-dots li button:before": {
    color: "#FF8008",
    fontSize: "15px",
  },
  TeachersContentWrapper: {
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.7rem",
    },
  },
}));
function TeacherSectionContent() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const classes = useStyles();
  return (
    <>
      <Slider {...settings} className={classes.TeachersContentWrapper}>
        {TeachersData.map((data, index) => (
          <Card id={data.id} className={classes.root} align="center">
            <div>
              <img src={data.courseimage} alt="img" width="80%" />
            </div>
            <CardActions>
              <Button className={classes.explorebtn} fullWidth>
                <Typography variant="h6" gutterBottom>
                  {data.teachername}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data.teacherssub}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        ))}
      </Slider>
    </>
  );
}
export default TeacherSectionContent;
