import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { IconButton, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
    containerfluid: {
        width: "100%",
        
      },
      container: {
        maxWidth: "1250px",
        margin: "0 auto",
        background: "#FFF",boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.10)',
      },
      pagination: {
        display: "flex",
        padding: "0.8rem 1rem",
        "& li": {
            listStyleType: "none",
            marginRight: "0.8rem",
        },
        "& .MuiIconButton-root": {
          padding: "0.6rem 1rem",
          background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
          borderRadius: "6px",
        },
        "& .MuiIconButton-label": {
          color: "#252525",
        },
        "& .MuiSvgIcon-root": {
          width: "0.6em",
        },
        "& .pagecounts": {
          padding: "0.6rem 1rem",
          borderRadius: "6px",
          border: "1px solid #E0E0E0",
          fontFamily: "'Jost', sans-serif",
          fontWeight: "600",
          fontSize: "1rem",
          cursor: "pointer",
        },
        "& .break": {
          fontFamily: "'Jost', sans-serif",
          fontWeight: "600",
          fontSize: "1.2rem",
          cursor: "pointer",
          marginTop: "1rem",
        },
      },
      
  }));

function Pagination({data, handleCurrentItems}) {

    const classes = useStyles();
    

    // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  let itemsPerPage = 16;

//   const data = TeachersData.map((data)=>(data))

  // Simulate fetching data from another resources.
  // (This could be data from props; or data loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading data from ${itemOffset} to ${endOffset}`);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
};
useEffect(()=>{
    handleCurrentItems(currentItems);
},[itemOffset])


  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <ReactPaginate
            className={classes.pagination}
            breakLabel={'....'}
            nextLabel= <IconButton><ArrowForwardIosIcon/></IconButton>
            onPageChange={handlePageClick}
            pageRangeDisplayed={6}
            pageCount={pageCount}
            previousLabel= <IconButton><ArrowBackIosIcon/></IconButton>
            renderOnZeroPageCount={null}
            pageClassName={'pagecounts'}
            breakClassName={'break'}
        />
      </div>
    </div>
  )
}

export default Pagination