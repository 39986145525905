import React, { useContext, useEffect, useState } from "react";
import QuestionBox from "./questionbox";
import { useParams } from "react-router-dom";
import practiceContext from "../context/PracticeContext";
import appContext from "../../../context/AppContext";
import { postAttemptQuiz } from "../../../service/services";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core";
import InspectModal from "./inspectModal";

const useStyles = makeStyles((theme) => ({
  quizzescontainer: {
    height: "100vh",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      height: "92vh",
    },
  },
}));

function Quizexam() {
  const classes = useStyles();
  const { getQuizQuestion } = useContext(practiceContext);
  const { id } = useParams();
  const AppContext = useContext(appContext);
  const [quizrunning, setquizrunning] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  const getQuizData = async () => {
    if (id) {
      let res = await postAttemptQuiz({
        attempts: {
          user_id: AppContext?.user?.id,
          quiz_id: id,
        },
      });
      getQuizQuestion(id);
    }
  };

  useEffect(() => {
    if (id && AppContext?.user) {
      getQuizData();
    }
  }, [id, AppContext?.user]);

  const [inspect, setInspect] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (
        window.outerWidth - window.innerWidth > 100 ||
        window.outerHeight - window.innerHeight > 100
      ) {
        setInspect(true);
        // Take action when inspect tool is opened
        console.log("Inspect tool opened!");
      } else {
        setInspect(false);
        // Take action when inspect tool is closed
        console.log("Inspect tool closed!");
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div onContextMenu={handleContextMenu} className={classes.quizzescontainer}>
      {<QuestionBox inspect={inspect}/>}
      <InspectModal/>
    </div>
  );
}
export default Quizexam;
