import CoachingImage1 from "../image/coachingsvector(Main)/maatutorial.png";
import CoachingImage2 from "../image/coachingsvector(Main)/forcedefenceacademy.png";
import CoachingImage3 from "../image/coachingsvector(Main)/niksacademy.png";
import CoachingImage4 from "../image/coachingsvector(Main)/forceacademy.png";
import CoachingImage5 from "../image/coachingsvector(Main)/saheedbhagatsingh.png";
import CoachingImage6 from "../image/coachingsvector(Main)/ardasclasses.png";
import CoachingImage7 from "../image/coachingsvector(Main)/iilmteducom.png";
import CoachingImage8 from "../image/coachingsvector(Main)/eduncle.png";
import CoachingImage9 from "../image/coachingsvector(Main)/ugcplugin.png";
import CoachingImage10 from "../image/coachingsvector(Main)/cmccoaching.png";
import CoachingImage11 from "../image/coachingsvector(Main)/tarunias.png";
import CoachingImage12 from "../image/coachingsvector(Main)/chemistrygurukul.png";
import CoachingImage13 from "../image/coachingsvector(Main)/omega.png";

const CoachingData = [
    {
        id: 1,
        image: CoachingImage1,
        name: "Maa Tutorial",
        exam : "Economics",
        ratings: "5.0",
        city: "indore",
    },
    {
        id: 2,
        image: CoachingImage2,
        name: "Force Defence Academy",
        exam : "Economics",
        ratings: "4.9",
        city: "bhopal",
    },
    {
        id: 3,
        image: CoachingImage3,
        name: "Niks Academy",
        exam : "Maths",
        ratings: "4.8",
        city: "ujjain",
    },
    {
        id: 4,
        image: CoachingImage4,
        name: "Force Academy Indore",
        exam : "Reasoning",
        ratings: "4.5",
        city: "indore",
    },
    {
        id: 5,
        image: CoachingImage5,
        name: "Shaheed Bhagat Singh Civil Services Academy",
        exam : "Economics",
        ratings: "5.0",
        city: "sehore",
    },
    {
        id: 6,
        image: CoachingImage6,
        name: "Ardas Classes OPC Pvt Ltd",
        exam : "Economics",
        ratings: "4.9",
        city: "indore",
    },
    {
        id: 7,
        image: CoachingImage7,
        name: "IILMT Educom Private Limited",
        exam : "Maths",
        ratings: "4.8",
        city: "bhopal",
    },
    {
        id: 8,
        image: CoachingImage8,
        name: "Eduncle India Pvt. Ltd.",
        exam : "Reasoning",
        ratings: "4.5",
        city: "indore",
    },
    {
        id: 9,
        image: CoachingImage9,
        name: "UGC PLUG IN",
        exam : "Economics",
        ratings: "5.0",
        city: "sagar",
    },
    {
        id: 10,
        image: CoachingImage10,
        name: "CMC Coaching Classes 2",
        exam : "Economics",
        ratings: "4.9",
        city: "rewa",
    },
    {
        id: 11,
        image: CoachingImage11,
        name: "Tarun IAS",
        exam : "Maths",
        ratings: "4.8",
        city: "harda",
    },
    {
        id: 12,
        image: CoachingImage4,
        name: "Force Academy Indore",
        exam : "Reasoning",
        ratings: "4.5",
        city: "indore",
    },
    {
        id: 13,
        image: CoachingImage4,
        name: "Force Academy Indore",
        exam : "Reasoning",
        ratings: "4.5",
        city: "indore",
    },
    {
        id: 14,
        image: CoachingImage8,
        name: "Zenith Education Jaipur",
        exam : "Economics",
        ratings: "4.9",
        city: "sagar",
    },
    {
        id: 15,
        image: CoachingImage12,
        name: "Chemistry Gurukul Classes",
        exam : "Maths",
        ratings: "4.8",
        city: "ratlam",
    },
    {
        id: 16,
        image: CoachingImage13,
        name: "Omega Classes",
        exam : "Reasoning",
        ratings: "4.5",
        city: "harda",
    },
    {
        id: 17,
        image: CoachingImage8,
        name: "Eduncle India Pvt. Ltd.",
        exam : "Reasoning",
        ratings: "4.5",
        city: "indore",
    },
    {
        id: 18,
        image: CoachingImage1,
        name: "Maa Tutorial",
        exam : "Economics",
        ratings: "5.0",
        city: "indore",
    },
    {
        id: 19,
        image: CoachingImage6,
        name: "Ardas Classes OPC Pvt Ltd",
        exam : "Economics",
        ratings: "4.9",
        city: "indore",
    },
    {
        id: 20,
        image: CoachingImage10,
        name: "CMC Coaching Classes 2",
        exam : "Economics",
        ratings: "4.9",
        city: "rewa",
    },
];

export default CoachingData;