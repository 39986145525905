import React, { useEffect, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Badge,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@material-ui/core";
import SignUPModal from "../allpopups/signup";
import ForgotPasswordModal from "../allpopups/forgotpassword";
import SignInModal from "../allpopups/signin";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import categoryContext from "../../context/CategoryContext/CategoryContext";
import wishlistContext from "../../context/WishlistContext/WishlistContext";
import addCartContext from "../../context/AddCartContext/AddCartContext";
import CreatePasswordModal from "../allpopups/createpassword";
import OtpModal from "../allpopups/otp";
import appContext from "../../context/AppContext";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Link, useLocation } from "react-router-dom";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "3.5rem",
    "& .MuiAppBar-colorPrimary": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%) !important",
    },
    "& .MuiIconButton-root": {
      fontSize: "0rem",
    },
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  title: {
    flexGrow: 1,
  },
  allcoursebtn: {
    padding: "1.20rem 1.25rem",
    background: "rgb(255, 255, 255, 0.15)",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    borderRadius: "0",
    "&:hover": {
      background: "rgb(255, 255, 255, 0.15)",
    },
  },
  headnameallcourse: {
    paddingRight: "10px",
    fontSize: "0.6875rem",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: "300px",
    top: "57px",
    background: "#FFFAEF",
    padding: "0.7rem 1rem",
  },
  allcoursedrawer: {
    background: "white",
    borderRadius: "6px",
    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.06)",
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.25rem",
      color: "white",
    },
  },
  drawerHeader: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem",
    borderRadius: "6px",
    "& .MuiIconButton-root": {
      padding: "0",
    },
  },
  draweraccordion: {
    "& .MuiSvgIcon-root": {
      fill: "#212121 !important",
    },
    "& .MuiTypography-root": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "0.875rem",
      fontWeight: "700",
    },
  },
  headbtnlogin: {
    color: "#233A54",
    fontSize: "0.625rem",
    background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    padding: "0.5rem 1rem",
  },
  listItemContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  listItem: {
    borderRadius: 5,
    width: "auto !important",
    padding: "3px 8px",
    margin: " 4px",
  },
  badge: {
    fontSize: 8,
    height: 12,
    minWidth: 6,
    width: 2,
    top: 6,
    right: 5,
  },
  listItemText: {
    "& .MuiListItemText-primary": {
      fontSize: 13,
      fontFamily: "Jost !important",
      fontWeight: 400,
    },
  },
}));

function MobileSubHeader() {
  const AppContext = useContext(appContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [login, setlogin] = React.useState(false);
  const [signup, setsignup] = useState(false);
  const [forgotpassword, setforgotpassword] = useState(false);
  const [createpassword, setcreatepassword] = useState(false);
  const [otp, setotp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [onetimepassword, setOneTimePassword] = useState(null);
  const [otpVerify, setOtpVerify] = useState(false);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  // const storedJsonString = sessionStorage.getItem('course');
  // const storedObject = JSON.parse(storedJsonString);
  const [selectedTabId, setSelectedTabId] = useState(
    AppContext?.coursesData?.category ? AppContext?.coursesData?.category : null
  );
  const { wishList } = useContext(wishlistContext);
  const { cartState } = useContext(addCartContext);
  const [selectedTabName, setSelectedTabName] = useState(
    AppContext?.coursesData?.categoryName
      ? AppContext?.coursesData?.categoryName
      : null
  );

  const CategoryContext = useContext(categoryContext);
  const [expanded, setExpanded] = React.useState(
    AppContext?.coursesData?.category ? AppContext?.coursesData?.category : null
  );
  const [forgot, setforgot] = useState(false);

  useEffect(() => {
    selectedTabId && getSubCategories();
  }, [selectedTabId]);
  

  const getSubCategories = async () => {
    let subCategories = CategoryContext?.megaMenu?.[0]?.["categories"]?.find(
      (subCategories) => subCategories?.id === selectedTabId
    )?.["subCategories"];
    setSubCategoriesData(subCategories);
  };
  const handlesignupOpenclose = (e) => {
    setsignup(!signup);
    setlogin(false);
    setOpen(false);
    setforgotpassword(false);
    e.preventDefault();
  };
  const handleForgotpasswordOpenclose = (e) => {
    setforgotpassword(!forgotpassword);
    setsignup(false);
    setlogin(false);
    setOpen(false);
    e.preventDefault();
  };

  const handleCreatepasswordOpenclose = (e) => {
    setcreatepassword(!createpassword);
    setforgotpassword(false);
    setsignup(false);
    setlogin(false);
    setOpen(false);
  };

  const handleOtpOpenclose = (e) => {
    setotp(!otp);
    setcreatepassword(false);
    setforgotpassword(false);
    setsignup(false);
    setlogin(false);
    setOpen(false);
  };

  const handleloginOpenclose = () => {
    setlogin(!login);
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    console.log(panel, isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  const location = useLocation();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title}>
            <Button
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
              type="button"
            >
              <span className={classes.headnameallcourse}>
                {AppContext?.coursesData?.exam_name
                  ? AppContext?.coursesData?.exam_name
                  : AppContext?.coursesData?.subcategoryName
                  ? AppContext?.coursesData?.subcategoryName
                  : AppContext?.coursesData?.categoryName
                  ? AppContext?.coursesData?.categoryName
                  : "All Courses"}

                {/* {(AppContext?.tempSubcategoryName)
                  ? AppContext?.tempSubcategoryName
                  : AppContext?.coursesData?.exam_name
                    ? AppContext?.coursesData?.exam_name
                    : 'All Courses'} */}
              </span>
              <RxHamburgerMenu />
            </Button>
          </Typography>
          {!AppContext.isLoggedIn ? (
            <div className={classes.headlogbtninner}>
              <Button
                onClick={handleloginOpenclose}
                color="primary"
                className={classes.headbtnlogin}
              >
                Log in
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                open={login}
                onClose={handleloginOpenclose}
              >
                <SignInModal
                  handleloginOpenclose={handleloginOpenclose}
                  handlesignupOpenclose={handlesignupOpenclose}
                  handleForgotpasswordOpenclose={handleForgotpasswordOpenclose}
                  handleOtpOpenclose={handleOtpOpenclose}
                  success={(num) => {
                    setMobileNumber(num);
                    setOtpVerify(false);
                  }}
                />
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                open={signup}
                onClose={handlesignupOpenclose}
              >
                <SignUPModal
                  handlesignupOpenclose={handlesignupOpenclose}
                  handleloginOpenclose={handleloginOpenclose}
                  handleOtpOpenclose={handleOtpOpenclose}
                  success={(num) => {
                    setotp(true);
                    setMobileNumber(num);
                    setOtpVerify(true);
                  }}
                />
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.Loginsigninmodal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                open={forgotpassword}
                onClose={handleForgotpasswordOpenclose}
              >
                <ForgotPasswordModal
                  handleloginOpenclose={handleloginOpenclose}
                  handlesignupOpenclose={handlesignupOpenclose}
                  handleOtpOpenclose={handleOtpOpenclose}
                  handleForgotpasswordOpenclose={handleForgotpasswordOpenclose}
                  success={(num) => {
                    setMobileNumber(num);
                    setOtpVerify(false);
                  }}
                  setforgot={() => setforgot(true)}
                />
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.Loginsigninmodal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                open={createpassword}
                onClose={handleCreatepasswordOpenclose}
              >
                <CreatePasswordModal
                  onetimepassword={onetimepassword}
                  mobileNumber={mobileNumber}
                  handleCreatepasswordOpenclose={handleCreatepasswordOpenclose}
                  forgot={forgot}
                />
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.Loginsigninmodal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                open={otp}
                onClose={handleOtpOpenclose}
              >
                <OtpModal
                  handlesignupOpenclose={handlesignupOpenclose}
                  mobileNumber={mobileNumber}
                  handleOtpOpenclose={handleOtpOpenclose}
                  handleCreatepasswordOpenclose={handleCreatepasswordOpenclose}
                  otpverify={otpVerify}
                  forget={forgot}
                  success={(OTP, NUM) => {
                    setOneTimePassword(OTP);
                    setMobileNumber(NUM);
                  }}
                />
              </Modal>
            </div>
          ) : (
            <>
              <IconButton aria-label="notification" onClick={() => navigate("/notification")}>
                <Badge
                  // badgeContent={4}
                  color="error"
                  classes={{ badge: classes.badge }}
                >
                  <NotificationsIcon style={{ color: "#FFB36B" }} />
                </Badge>
              </IconButton>
              <IconButton aria-label="delete">
                <Badge
                 badgeContent={cartState?.cart?.product_count}
                  color="error"
                  classes={{ badge: classes.badge }}
                >
                  <Link
                    to={"/shoppingcart"}
                    state={{ prevPath: location.pathname }}
                  >
                    <ShoppingCartIcon style={{ color: "#ffb36b" }} />
                  </Link>
                </Badge>
              </IconButton>
              <IconButton aria-label="delete">
                <Badge
                  badgeContent={wishList?.length}
                  color="error"
                  classes={{ badge: classes.badge }}
                >
                  <Link
                    to={"/wishlist"}
                    state={{ prevPath: location.pathname }}
                  >
                    {" "}
                    <FavoriteIcon style={{ color: "#FFB36B" }} />
                  </Link>
                </Badge>
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerClose}
      >
        <div className={classes.allcoursedrawer}>
          <div className={classes.drawerHeader}>
            <div className={classes.drawerheading}>
              <Typography variant="h5">Categories :-</Typography>
            </div>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.draweraccordion}>
            {CategoryContext?.megaMenu &&
              CategoryContext?.megaMenu[0]?.categories?.map((data, index) => (
                <Accordion
                  expanded={expanded === data?.id}
                  onChange={handleChange(data?.id)}
                >
                  <AccordionSummary
                    onClick={() => {
                      setSelectedTabId(data?.id);
                      // AppContext.setCourseData({
                      //   category: data?.id,
                      //   categoryName: data?.category_name,
                      //   subcategory: "",
                      //   exam: "",
                      //   exam_name: "",
                      // });
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={data?.id}
                    id={data?.id}
                  >
                    <ListItemText primary={data.category_name} />
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <List className={classes.listItemContainer}>
                      {subCategoriesData?.length === 0 ? (
                        <p
                          style={{
                            fontSize: 14,
                            fontFamily: "Jost",
                            fontWeight: 500,
                            margin: 0,
                          }}
                        >
                          No Sub Categories for this categories...
                        </p>
                      ) : (
                        subCategoriesData?.map((data, index) => (
                          <ListItem
                            button
                            key={index}
                            style={
                              AppContext?.coursesData?.subcategory ===
                                data?.id ||
                              data?.sub_category_name ===
                                AppContext?.tempSubcategoryName
                                ? { border: "1px solid #FFB36B" }
                                : { border: "none" }
                            }
                            className={classes.listItem}
                            onClick={() => {
                              AppContext.setCourseData({
                                category: selectedTabId,
                                categoryName: selectedTabName,
                                subcategoryName: data?.sub_category_name,
                                subcategory: data?.id,
                                exam: null,
                                exam_name: null,
                              });
                              AppContext.setTempSubcategory(
                                data?.sub_category_name
                              );
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText
                              primary={data.sub_category_name}
                              className={classes.listItemText}
                            />
                          </ListItem>
                        ))
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
export default MobileSubHeader;
