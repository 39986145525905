import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import NotificationsListIcon from "../../image/latestnotificationvector/notificationlisticon.svg";
import CardData from "./latestnotificationdata";
import { getJobNotification } from "../../../service/services";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "1rem 0",
    height: "397px",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },
    "& .slick-prev:before, .slick-next:before": {
      fontSize: "55px",
      borderRadius: "40px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      bottom: 0,
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },

    "& .MuiCardHeader-root": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
      color: "#FFFFFF",
    },
    "& .MuiList-root": {
      padding: "0",
    },
    "& .MuiCardContent-root": {
      height: "350px",
      [theme.breakpoints.down("sm")]: {
        height: "400px",
      },
    },
    "& .MuiCardActions-root": {
      background:
        " linear-gradient(90deg, rgba(255,128,8, 0.3199999928474426) 0%, rgba(255,200,55, 0.3199999928474426) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
        fontWeight: 400,
      },
    },
    "& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
      fontFamily: "Jost",
      fontWeight: 500,
      color: "#154FE5",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
        fontFamily: "Jost",
        fontWeight: 500,
        color: "#154FE5",
      },
    },
  },
  LatestNotificationCard: {
    justifyContent: "space-evenly",
    marginTop: "1rem",
    "& .MuiTypography-body2": {
      fontSize: "1.5rem",
      fontFamily: "'Playfair Display', serif !important",
    },
    "& .slick-slide": {
      display: "flex",
      height: "450px",
      justifyContent: "center",
      padding: "1rem 1.5rem!important",
      [theme.breakpoints.down("xs")]: {
        padding: "2rem 1.5rem 2rem 1.5rem !important",
        height: "470px",
      },
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0rem 1rem 0 1rem",
    },
  },

  avatar: {
    background: "linear-gradient(265deg, #FF8008 0%, #FFC837 100%)",
  },
  viewbtn: {
    width: "100%",
    padding: "10px",
  },
}));

function LatestNotificationContent() {
  const classes = useStyles();
  const [notData, setNotData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getJobNotification();
        console.log("API Response:", response.data);
        setNotData(response.data.notify);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const initialSlidesToShow = window.innerWidth < 480 ? 1 : 3;

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: initialSlidesToShow,
    slidesToScroll: initialSlidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow: newSlidesToShow,
        slidesToScroll: newSlidesToShow,
      }));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={classes.LatestNotificationCard}>
        <Slider {...sliderSettings}>
          {notData.map((data, index) =>
            data.notifications.length > 0 ? (
              <div className={classes.LatestNotificationmain} key={index}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        <img
                          src={CardData[index].cardimage}
                          alt="img"
                          height="50%"
                        />
                      </Avatar>
                    }
                    title={data.category_name}
                  />
                  <CardContent>
                    <List className={classes.footerCoachingsectionlist}>
                      {data.notifications.map((a, idx) =>
                        a.name ? (
                          <Link className={classes.link} key={idx}>
                            <ListItem>
                              <ListItemIcon>
                                <img
                                  src={NotificationsListIcon}
                                  color="black"
                                  alt="img"
                                />
                              </ListItemIcon>
                              <ListItemText
                                textColor="inherit"
                                primary={a.name}
                                onClick={() =>
                                  navigate(
                                    `/notificationsinglepage/${a.id}/${data.id}`
                                  )
                                }
                              />
                            </ListItem>
                          </Link>
                        ) : null
                      )}
                    </List>
                    {data.notifications.length === 0 && (
                      <p>No notifications available</p>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      className={classes.viewbtn}
                      onClick={() => navigate(`/notification/${data.id}`)}
                    >
                      View
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ) : null
          )}
        </Slider>
      </div>
    </>
  );
}

export default LatestNotificationContent;
