import React from "react";
import { Button, IconButton, Typography, makeStyles } from "@material-ui/core";
import ProductImage from "../../image/singlepagedescriptionvector/productimage.png";
import { ToggleButton } from "@material-ui/lab";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import {ReactComponent as CheckIcon} from "../../image/singlepagedescriptionvector/checkicon1.svg";
import {ReactComponent as HexagonShape} from "../../image/singlepagedescriptionvector/hexagonalblankshape.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2.5rem 2rem",
    // margin: "0 2rem",
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "1.5rem 1rem",
      margin: "1rem",
      background: "#FFFFFF",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
      borderRadius: "10px",
    },

    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "#FFFFFF",
    },
    "& .MuiToggleButton-root": {
      color: "#777777",
      background: "#FFFFFF",
      borderRadius: "40px",
      padding: "0.5rem",
      position: "absolute",
      right: "16px",
      top: "16px",
    },
    "& .MuiIconButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        
        fontSize: "0.875rem",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
    },
  },
  imagegallery: {
    width: "43%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      // fontSize: "0.875rem",
      width: "100%",
      margin: "0 auto",
    },
  },
  currentimagecontainer: {
    // width:"100%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 0",
      justifyContent: "space-evenly",
    },
    padding: "1.5rem 5rem",
    background: "#FFE8B5",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tag: {
    background: "#FF3939",
    position: "absolute",
    top: "2.9rem",
    left: "0rem",
    padding: "0 1.8rem",
    [theme.breakpoints.down("xs")]: {
      top: "2rem",
      left: "0rem",
      padding: "0 1rem",
    },
    "& .MuiTypography-caption": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
      color: "#FFFFFF",
      fontFamily: " Playfair Display",
      fontSize: "1.3rem",
    },
  },
  imagewrapper: {
    // marginLeft: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      // marginLeft: "2.4rem",
    },
  },
  image: {
    width: "290px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  productdetails: {
    width: "52%",
    marginLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
      width: "100%",
      marginTop: "2rem",
    },
    "& .MuiTypography-h3": {
      fontFamily: " Playfair Display",
      fontSize: "2rem",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiTypography-h4": {
      fontFamily: " Playfair Display",
      fontSize: "1.25rem",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  testandvaliditycontainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "74%",
    margin: "0.5rem 0", 
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  hexagonshape: {
    position: "absolute",
    left: "-18px",
    [theme.breakpoints.down("xs")]: {
      left: "-15px",
    },
  },
  testseriestag: {
    position: "relative",
    margin: "1rem 0",
    display: "flex",
    width: "37%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0rem 1rem 0rem 0.3rem",
    [theme.breakpoints.down("xs")]: {
      width: "38%",
      padding: "0.2rem",
      marginRight: "1rem",
      "& svg": {
        width: "56px",
      }
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
      cursor: "default",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#313866",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.25rem",
      fontWeight: "500",
      zIndex: "10",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  validitycontainer: {
    margin: "1rem 0",
    display: "flex",
    width: "47%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0rem 0.5rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.2rem",
      "& svg": {
        width: "12px",
      }
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.375rem",
      fontWeight: "600",
      cursor: "default",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
        fontWeight: "700",
      },
    },
    "& .MuiTypography-caption": {
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.3125rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
  },
  languagecont: {
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      color: "#000",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#206FE5",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      marginLeft: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
        marginLeft: "0.5rem",
      },
    },
  },
  price: {
    margin: "0.8rem 0",
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    "& .MuiTypography-h3": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.6rem",
      fontWeight: "500",
      color: "#252525",
      width: "31%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
        width: "40%",
      },
    },
    "& .MuiTypography-caption": {
      color: "#626262",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      marginRight: "0.2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
  },
  mrpcontainer: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.6rem",
    },
  },
  mrp: {
    display: "flex",
    "& .MuiTypography-h3": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "2rem",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
    "& span": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        marginRight: "0.2rem",
      },
    },
  },
  offers: {
    display: "flex",
  },
  tax: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      alignItems: "baseline",
    },
    "& .MuiTypography-caption": {
      color: "#000000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
  },
  link: {
    textDecoration: "none",
    "& .MuiTypography-h6": {
      color: "#206FE5",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      marginLeft: "0.2rem",
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.675rem",
      },
    },
  },
  mrpandoffer: {
    width: "65%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  buttonscontainer: {
    width: "74%",
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "13px 53px",
    fontSize: "1rem",
      fontWeight: "400",
    "borderRadius": "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      width: "100%",
      padding: "11px 22px",
      fontSize: "0.875rem",
    },
  },
  yellowcustombtn: {
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "#20324A",
    fontFamily: "'Jost', sans-serif",
    padding: "11px 63px",
    fontSize: "1rem",
    "borderRadius": "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      fontSize: "0.875rem",
      width: "100%",
      padding: "10px 34px",
    },
  },
  bundleheights: {
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.25rem",
      fontWeight: "500",
      color: "#000",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.125rem",
    },
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      color: "#525252",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.825rem",
    },
    },
    "& ul": {
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0.8rem",
       paddingLeft: "1.5rem",
      },
    }
  },
}));
function ProductDetails() {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(false);

  
  return (
    <div className={classes.container}>
      <div className={classes.imagegallery}>
        <div className={classes.currentimagecontainer}>
          <div className={classes.tag}>
            <Typography variant="caption">Featured</Typography>
          </div>
          <div className={classes.imagewrapper}>
            <img className={classes.image} src={ProductImage} alt="img" />
          </div>
          <ToggleButton
            value="check"
            selected={selected}
            onChange={() => {
              setSelected(!selected);
            }}
          >
            <FavoriteIcon />
          </ToggleButton>
        </div>
      </div>
      <div className={classes.productdetails}>
        <div className={classes.title}>
          <Typography variant="h3">SSC Maha Pack</Typography>
          <Typography variant="h4">
          ( SSC CGL, CHSL CPO, MTS, GD, JHT)
          </Typography>
        </div>
        <div className={classes.testandvaliditycontainer}>
          <div className={classes.testseriestag}>
            <HexagonShape className={classes.hexagonshape}/>
            <Typography variant="caption">241</Typography>
            <Typography variant="h6">Test Series</Typography>
          </div>
          <div className={classes.validitycontainer}>
            <CheckIcon />
            <Typography variant="h6">Validity : </Typography>
            <Typography variant="caption">6 Months</Typography>
          </div>
        </div>
        <div className={classes.languagecont}>
            <Typography variant="h6" component="span">Language:</Typography>
            <Typography variant="caption" component="span">English</Typography>
        </div>
        <div className={classes.price}>
          <Typography variant="h3">Special Price :</Typography>
          <div className={classes.mrpcontainer}>
            <div className={classes.mrpandoffer}>
              <div className={classes.mrp}>
                <span>&#8377;</span>
                <Typography variant="h3">50.00</Typography>
              </div>
              <div className={classes.offerssection}>
                <div className={classes.offers}>
                  <Typography variant="caption">
                    MRP: <del>&#8377;80.00</del>
                  </Typography>
                  <Typography variant="h6">(30% off)</Typography>
                </div>
              </div>
            </div>
            <div className={classes.tax}>
              <Typography variant="caption">Inclusive of all taxes.</Typography>
              <Link to={"#"} className={classes.link}>
                <Typography variant="h6">Details</Typography>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.bundleheights}>
            <Typography variant="h6">Bundle Heights</Typography>
            <ul>
              <li><Typography variant="caption">250+ Total Tests with Detailed Solutions</Typography></li>
              <li><Typography variant="caption">Based on the Latest Pattern</Typography></li>
            </ul>  
        </div>
        <div className={classes.buttonscontainer}>
          <div>
            <IconButton variant="contained" className={classes.bluecustombtn}>
              <ShoppingCartOutlinedIcon/> Add to cart
            </IconButton>
          </div>
          <div>
            <Button variant="contained" className={classes.yellowcustombtn}>
              Buy Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductDetails;
