import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST, FETCH_ALL, WISHLIST_ERROR_MESSAGE } from "./WishlistType";

const Reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_TO_WISHLIST:
      return {
        ...state,
        wishList: [...state.wishList, payload],
      };

    case REMOVE_FROM_WISHLIST:
      return {
        ...state,
        wishList: payload,
      };

      case FETCH_ALL:
        return {
          ...state,
          wishList: payload,
        };
  
  
        case WISHLIST_ERROR_MESSAGE:
        return {
          ...state,
          hasError: payload.hasError,
          errorMessage: payload.errorMessage,
          currentProductId: payload.currentProductId,
        };

    default:
      return state;
  }
};

export default Reducer;
