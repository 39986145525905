import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  squareBlock: {
    width: "270px",
    height: "300px",
    backgroundColor: "#fff",
    margin: "10px",
    borderRadius: "5px",
    border: "2px solid #E1D9D1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: "20px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "11rem",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    width: "50px",
    height: "50px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
      marginBottom: "5px",
    },
  },
  heading: {
    fontSize: "19px",
    lineHeight: "18px",
    fontFamily: "jost",
    textAlign: "center",
    fontWeight: "500",
   
  },
  subheading: {
    fontSize: "1rem",
    fontFamily: "Jost, sans-serif",
    color: "#7E7E7E",
    fontWeight: "400",
    lineHeight: "1.5",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
}));

function SquareBlock({ iconName, heading, subheading }) {
  const classes = useStyles();

  return (
    <Card className={classes.squareBlock}>
      <div>
        <img src={iconName} alt="" className={classes.icon} />
      </div>
      <div className={classes.content}>
        <h3 className={classes.heading}>{heading}</h3>
        <Hidden xsDown>
          <p className={classes.subheading}>{subheading}</p>
        </Hidden>
      </div>
    </Card>
  );
}

export default SquareBlock;
