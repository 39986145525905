import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import PageHead from "../pageheading/pageheading";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginBottom:"15rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding:'2rem 0',
    "& .MuiTypography-h4": {
      color: "#FA9D2B;",
    },
    "& .MuiTypography-h6": {
      marginTop: "8px",
    },
  },
  privacypolicy: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 1rem",
    },
  },
  ol: {
    listStyleType: "lower-alpha",
  },
  "&.a": {
    elementStyleColor: "#FA9D2B",
  },
  list: {
    padding: "0 1rem",
  },
}));
function PrivacyPolicy() {
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore Privacy Policy",
      headingmain: "Privacy Policy",
      breadcrumb:"Privacy Policy"
    },
  ];
  const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
       <PageHead Heading={Heading}/>
      <div className={classes.container}>
        <div className={classes.privacypolicy}>
       
          <Typography variant="body2" gutterBottom>
            Given below is the privacy policy published in accordance with the
            provisions of the Information Technology Act, 2000 <b>("IT ACT")</b>
            , Information Technology (Intermediary Guidelines) Rules,2011,
            Information Technology (Reasonable Security Practices and Procedures
            and Sensitive Personal Data or Information) Rules, 2011.
          </Typography>

          <Typography variant="h5" gutterBottom>
            <b>INTRODUCTION</b>
          </Typography>
          <ul className={classes.list}>
            <li>
              This Privacy Policy ("Policy") explains our policy regarding the
              collection, use, dis- closure and transfer of your information by
              Walrus Solutions Private limited and / or its subsidiary(ies) and
              / or affiliate(s) and / or business partners (collectively
              referred to as the "Company") , which powers and manages the
              website <Link to="/"> www.parikshado.com </Link> and its other
              domain
              <b>.Org , .Info , .Online , .Co.in , .Co </b>(“Site”) and all
              platforms (web, apps, emails), or mobile / internet connected
              devices ("Services"). This Policy forms part and parcel of the
              Terms of Use. Capitalized terms which have been used here but are
              unde- fined shall have the same meaning as attributed to them in
              the Terms of Use.
            </li>
            <li>
              This Privacy Policy is applicable to persons who views, browses or
              uses the Ser- vices ("User"). For the purpose of this Policy,
              wherever the context so requires "you" or "your" shall mean User
              and the term "we", "us", "our" shall mean Company.
            </li>
            <li>
              As we update, improve and expand the Services, this Policy may
              change, so please refer back to it periodically. By using the Site
              or the Services, you consent to collec- tion, storage, and use of
              the personal information you provide (including any changes
              thereto as provided by you) for any of the services that we offer.
            </li>
            <li>
              The Company respects the privacy of its Users and is committed to
              protect it in all respects.
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            <b>INFORMATION COLLECTED FROM YOU</b>
          </Typography>
          <ol>
            <li>
              When you use the Platform by way of registration, log in, creation
              of a user ac- count, purchase of a test series, video course,
              attempting a mock test, etc. or by way of interaction via third
              party websites and/or mobile applications or by any other way of
              communication with the Platform,<b>ParikshaDo</b> may collect your
              personal identifiable information including name, date of birth,
              gender, demographic informa- tion, photos, e-mail address,
              telephone number, mobile phone number, credit card or debit card
              details, geographic location, mailing address, social media
              account details including list of contacts/friends and examination
              preference.
            </li>

            <li>
              ParikshaDo will use this information to validate you as a user
              when using the Platform, to provide the Services to you, including
              administration of your user ac- count, to notify you of changes to
              the Service or about any changes to our terms and conditions or
              privacy policy, to manage business, including financial reporting
              and billing of its Service, for the development of new products
              and services, to send you newsletters, offers and promotion
              coupons to market and advertise its products and services by
              email, to comply with applicable laws, court orders and government
              enforcement agency requests, for research and analytic purposes
              including to im- prove the quality of the Service and to ensure
              that the Service is presented in the most effective manner for you
              and your device.
            </li>

            <li>
              By registering on the ParikshaDo Platform, you agree and confirm
              your consent to providing ParikshaDo your aforementioned personal
              information, which is lawful, necessary and permissible. You at
              all times have the right to discontinue the use of the Platform.
              ParikshaDo is not liable to provide you its Services in the
              absence or your refusal to provide the aforesaid information.
              Additionally, ParikshaDo is not li- able to ensure or maintain the
              same quality of its Services to you, as it may for a user who
              provides all aforementioned information.
            </li>
          </ol>

          <Typography variant="h5" gutterBottom>
            <b>INFORMATION COLLECTED AUTOMATICALLY</b>
          </Typography>
          <ol>
            <li>
              When you visit or interact with the Platform, apart from
              ParikshaDo certain third party advertisers and/or service
              providers may use technologies that automatically collect
              information about you for both transactional (e.g., confirmation
              of registra- tion, notification of purchase made, etc.) and
              promotional (e.g., promotions, emails, etc.) purposes. Your
              information may be collected by ParikshaDo or such third party
              advertisers and/or service providers in the following ways:-
            </li>

            <li>
              <b>Log Files:</b> Every time you visit the Platform, ParikshaDo
              servers automatically receive log information from your browser
              and device that was used to access the Platform (such as IP
              address, device ID, details of your network operator and type,
              your operating system, browser type and version, CPU speed, and
              connection speed). This enables us to validate you as a User, to
              understand your usage of the Platform and helps us to make changes
              and updates most suited to your needs and interests.
            </li>

            <li>
              <b>Mobile Device:</b> When you register your mobile device or use
              the mobile device to access the App, In addition to the aforesaid,
              ParikshaDo will also collect device information such as mobile
              device ID, model and manufacturer details, operating system etc.
              for the purpose of improving the App’s overall functionality and
              display- ing content according to your preferences.
            </li>

            <li>
              <b>Cookies:</b> Cookies are data files placed on your device, used
              to keep track of in- formation such as your interaction with
              social media websites, the content you click on, download, upload
              or share and other activity on the Platform etc. in order to im-
              prove your experience of the Platform by personalizing it to your
              preferences and usage trends.
            </li>

            <li>
              <b>Web Beacons:</b> Web beacons are transparent graphic images
              used in our email communication to you, in order to understand
              customer behavior and improve the overall quality, functionality
              and interactivity of the Platform.
            </li>

            <li>
              <b>Mobile Analytics:</b> Mobile analytics software is used by
              ParikshaDo to better un- derstand and customize the functionality
              of the App’s software on your phone. This is done by collecting
              information such as your frequency of the App’s usage, the
              features you prefer to use on the App, where the App was
              downloaded from, De- vice Id, name of other mobile applications on
              your device, etc.
            </li>

            <li>
              <b> Payment /Purchase Information:</b> In order to access certain
              paid features and services on the Platform, you may be required to
              create or log into a separate ac- count on a payment gateway or
              website such as “PayU” or “PayTM”. Once such an account is
              created, in order to process your payments/ purchases on the
              Platform, such payment gateway provider may require and collect
              your details such as name, address, phone number, email address
              and credit or debit card information, net- banking information or
              details of any web wallets maintained by you. Any and all payments
              made/processed or details provided to or shared with such
              authorized payment gateway providers shall be stored directly by
              such payment gateway providers without any information passing
              through or relayed to ParikshaDo. Plat- form assumes no liability
              in respect of such payments and/or information shared with or
              provided to such authorized payment gateway providers. It is
              further clarified that the aforementioned information is only used
              in accordance with the provisions of the applicable law and in
              strict adherence to this Privacy Policy.
            </li>

            <li>
              <b>Public Forums:</b> Any information that is disclosed by you in
              the comments sec- tion becomes published information and
              ParikshaDo shall not be held liable for the security of the same
              or any persona information that you disclose herein. You agree to
              exercise caution when disclosing any personal information or
              personally identifi- able information in this regard.
            </li>

            <li>
              Please note that we only use the aforesaid information to
              communicate with and/or improve the Service and to better
              understand our users’ operating systems, for system administration
              and to audit the use of the Service. We do not use any of the
              aforesaid data to identify the name, address or other personal
              details of any in- dividual.
            </li>

            <li>
              For the purpose of this Privacy Policy, any passwords, financial
              information such as credit or debit card details or other payment
              instrument details and any other information prescribed by law to
              be sensitive that may be collected by Parik- shaDo during your use
              of the Platform and the Services provided thereon, shall be
              referred to as "Sensitive Personal Data or Information".
            </li>
          </ol>

          <Typography variant="h5" gutterBottom>
            <b>LINK TO THIRD PARTIES</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            The Platform may include links that redirect you to other websites.
            These third party websites are not covered by this Privacy Policy.
            You agree that once you leave our servers, any third party websites
            that you go to or interact with are at your own risk. ParikshaDo
            shall not be held liable for any default, loss of function or any
            risk that your personal sensitive information may be exposed to as a
            result of the same.
          </Typography>

          <Typography variant="h5" gutterBottom>
            <b>SHARING OR DISCLOSURE OF INFORMATION COLLECTED</b>
          </Typography>
          <ol>
            <li>
              As a strict policy we will not disclose, share or exploit your
              information with any- one without your permission.
            </li>
            <li>
              However, we may mandate under law or under contracts to make
              certain limited disclosures under the following circumstances:
            </li>
          </ol>
          <ul>
            <li>
              <b>Legal Necessity:</b> ParikshaDo may share any of the aforesaid
              information, includ- ing your personally identifiable information
              or Sensitive Personal Data or Informa- tion, without obtaining a
              separate consent from you, if and when such information is
              requested or required by law or by any court or governmental
              agency or authority to disclose, for the purpose of verification
              of identity, or for the prevention, detection, investigation of
              any criminal activity, or for prosecution and punishment of
              offence.
            </li>
            <li>
              <b>Limited Disclosure to Service Providers:</b> we may disclose
              your information (but not Sensitive Personal Data or Information)
              to our service providers and business partners (“Service
              Providers”) for the purposes of betterment and improvement of our
              services including but not limited to hosting the Platform,
              payment processing, analyzing data, providing customer service,
              etc., for the purpose of making various features, services and
              products of ParikshaDo available to you and investigating or
              redressing grievances. This will be in the form of aggregated
              anonymous data and will be under strict contractual arrangements
              that preserve the confidentiality and security of your personal
              information in accordance with this Privacy Policy;
            </li>
            <li>
              <b>Limited Disclosures for improvement of services:</b> ParikshaDo
              may share the aforesaid information including your personally
              identifiable information (but not Sen- sitive Personal Data or
              Information) when it is required to be shared with sponsors,
              partners, advertisers, analytics companies or third parties for
              the purpose of mar- keting, advertising promotional offers,
              offering product information and market re- search, in connection
              with the Service. This will be in the form of aggregated
              anonymized data and will be under strict contractual arrangements
              that preserve the confidentiality and security of your personal
              information in accordance with this Privacy Policy;
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            <b>ACCESSING AND UPDATING PERSONAL INFORMATION</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            When you use the Site or Services, we make good faith efforts to
            provide you, as and when requested by you, with access to your
            personal information and shall fur- ther ensure that any personal
            information or sensitive personal data or information found to be
            inaccurate or deficient shall be corrected or amended as feasible,
            sub- ject to any requirement for such personal information or
            sensitive personal data or information to be retained by law or for
            legitimate business purposes. We ask indi- vidual users to identify
            themselves and the information requested to be accessed, corrected
            or removed before processing such requests, and we may decline to
            process requests that are unreasonably repetitive or systematic,
            require dispropor- tionate technical effort, jeopardize the privacy
            of others, or would be extremely im- practical (for instance,
            requests concerning information residing on backup tapes), or for
            which access is not otherwise required. In any case where we provide
            infor- mation access and correction, we perform this service free of
            charge, except if do- ing so would require a disproportionate
            effort. Because of the way we maintain cer- tain services and
            storage requirements under applicable law, after you delete your
            information, residual copies may take a period of time before they
            are deleted from our active servers and may remain in our backup
            systems. Furthermore, you can request deletion of your personal info
            by sending an email to For Support:
            <Link to="/onlinesupport">Click here</Link>.
          </Typography>

          <Typography variant="h5" gutterBottom>
            <b>SECURITY OF INFORMATION COLLECTED</b>
          </Typography>
          <ol>
            <li>
              We take the security of your personal information seriously and
              use appropriate technical and organizational measures to protect
              your personal information against unauthorized or unlawful
              processing and against accidental loss, destruction or damage.
              These include internal reviews of our data collection, storage and
              process- ing practices and security measures, including
              appropriate encryption and physical security measures to guard
              against unauthorized access to systems where we store personal
              data.
            </li>
            <li>
              We take appropriate security measures to protect against
              unauthorized access to or unauthorized alteration, disclosure or
              destruction of data. All information gath- ered by the Company is
              securely stored within the Company controlled database. The
              database is stored on servers secured behind a firewall; access to
              the servers is password-protected and is strictly limited.
              However, as effective as our security measures are, no security
              system is impenetrable. We cannot guarantee the secu- rity of our
              database, nor can we guarantee that information you supply will
              not be intercepted while being transmitted to us over the
              Internet. And, of course, any in- formation you include in a
              posting to the discussion areas is available to anyone with
              Internet access.
            </li>
            <li>
              We keep your personal information for no longer than is necessary
              for our busi- ness purposes or for legal requirements.
            </li>
          </ol>

          <Typography variant="h5" gutterBottom>
            <b>UPDATES AND CHANGES</b>
          </Typography>
          <ul className={classes.list}>
            <li>
              We reserve the right to change (at any point of time) the terms of
              this Policy or the Terms of Use. Any changes we make will be
              effective immediately on notice, which we may give by posting the
              new policy on the Sites . Your use of the Sites or Ser- vices
              after such notice will be deemed acceptance of such changes. We
              may also make reasonable efforts to inform you via electronic
              mail. In any case, you are ad- vised to review this Policy
              periodically on the Site to ensure that you are aware of the
              latest version.
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            <b>GRIEVANCE REDRESSAL MECHANISM</b>
          </Typography>
          <ul className={classes.list}>
            <li>
              Any complaints or request or concerns with regards to use,
              processing or disclo- sure of information provided by you or
              breach of these terms may be taken up with the designated
              grievance redressal officer as mentioned below via post or by
              send- ing an email to{" "}
              <Link
                to="#"
                onClick={(e) => {
                  window.location.href = "mailto:no-reply@example.com";
                  e.preventDefault();
                }}
              >
                chirag@ParikshaDo.com
              </Link>
            </li>
            <li>
              Grievance Redressal Officer: Any complaints, abuse or concerns
              with regards to content and or comment or breach of these terms
              shall be immediately informed to the designated Grievance Officer
              as mentioned below via in writing or through email signed with the
              electronic signature to.
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            <b>Grievance Redressal Officer</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Name: Chirag Agrawal <br />
            Email:{" "}
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "mailto:no-reply@example.com";
                e.preventDefault();
              }}
            >
              chirag@ParikshaDo.com
            </Link>
            , For Support: <Link to="/onlinesupport">Click here</Link> <br />
            303, Krishna Tower, <br />
            2/1 New Palasia, Infront of Curewell Hospital, <br />
            Janjeerwala Square, <br />
            Indore, Madhya Pradesh, 452001 <br />
          </Typography>

          <Typography variant="h5" gutterBottom>
            <b>
              We request you to please provide the following information in your
              com-plaint:
            </b>
          </Typography>
          <ol className={classes.ol}>
            <li>Identification of the information provided by you.</li>
            <li>
              Clear statement as to whether the information is personal
              information or sensitive personal information.
            </li>
            <li>our address, telephone number or e-mail address.</li>
            <li>
              A statement that you have a good-faith belief that use of the
              information has been processed incorrectly or disclosed without
              authorization, as the case may be.
            </li>
            <li>
              A statement, under penalty of perjury, that the information in the
              notice is accu- rate, and that you are authorized to act on behalf
              of the owner of the right that is al- legedly infringed.
            </li>
          </ol>
          <Typography variant="body2" gutterBottom>
            The Company may reach out to you to confirm or discuss certain
            details about your complaint and issues raised
          </Typography>
          <Typography variant="body2" gutterBottom>
            The Company shall not be responsible for any communication, if
            addressed, to any non-designated person in this regard.
          </Typography>
          <Typography variant="body2" gutterBottom>
            You may also contact us in case you have any questions / suggestions
            about the Policy using the contact information mentioned above.
          </Typography>
        </div>
      </div>
    </div>
  );
}
export default PrivacyPolicy;
