import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardroot: {
    marginTop: "1rem",

    "& .MuiCard-root .MuiPaper-elevation1": {
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.10)",
    },
    "& .MuiCardContent-root": {
      padding: "0 1rem",
    },
  },
  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0.5rem 0",
    "& .MuiTypography-h5": {
      borderRadius: "0.375rem",
      border: "1px solid #00A019",
      background: " var(--white-100, #FFF)",
      color: "#00A019 !important",
      textAlign: "center",
      fontFamily: "Jost !important",
      fontSize: "0.625rem !important",
      fontWeight: "600 !important",
      textTransform: "capitalize",
      padding: "0.4rem",
    },
    "& .MuiTypography-h6": {
      color: "#262626",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontWeight: "400",
      textTransform: "capitalize",
    },
  },
  durationandques: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "70%",
  },
  heading: {
    color: "#262626",
    fontFamily: "Jost",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "uppercase",
    width: "67%",
  },
  w25: {
    width: "25%",
  },
  button: {
    color: "#000",
    fontFamily: "Jost",
    fontSize: "0.55rem",
    fontWeight: 400,
    borderRadius: "var(--4, 0.25rem)",
    background: "linear-gradient(90deg, #FFF4EA 0%, #FFEEC3 100%)",
    alignItems: "center",
    padding: "0.2rem 0.4rem",
    textAlign: "center",
  },
  startbtn: {
    "&.MuiButton-root": {
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.625rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      color: "#000",
      textTransform: "capitalize",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.625rem",
      background: "#EEEEEE",
      borderRadius: "0.375rem",
      padding: "0.3rem 1.2rem",
    },
  },
  image: {
    margin: "1rem auto",
  },
}));

const ProductDescripMobile = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.cardroot}>
        <CardContent>
          <div className={classes.cardcontainer}>
            <div className={classes.w25}>
              <div className={classes.button}>
                {data?.product_type === "books"
                  ? "Book"
                  : data?.product_type === "ebooks"
                  ? "E-Book"
                  : data?.product_type === "onlinevideos"
                  ? "Online Video"
                  : data?.product_type === "testseriesbundle"
                  ? "Test Series"
                  : data?.product_type}
              </div>
              <Avatar
                className={classes.image}
                width="100%"
                src={data?.image_url}
              />
            </div>
            <div className={classes.heading}>
              {data?.product_name} {data?.sub_heading}
            </div>
          </div>
          <div className={classes.cardcontainer}>
            <div className={classes.durationandques}>
              <Typography variant="h6">Quantity: {data?.quantity}</Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="h6">Price: ₹{data?.selling_cost}</Typography>
            </div>

            <Typography variant="h5">
              {data?.product_type === "books"
                ? data?.delivery_status
                : "success"}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ProductDescripMobile;
