import { ADD_TO_CART, FETCH_ALL_PRODUCTS, REMOVE_FROM_CART, CART_ERROR_MESSAGE } from "./AddCartType";

const Reducer = (state, action) => {
  const { payload, type } = action;
  console.log("🚀 ~ file: AddCartReducer.js:5 ~ Reducer ~ payload, type:", payload, type)

  switch (type) {
    case ADD_TO_CART:
      return {
        ...state,
    cart: payload,
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: payload,
      };

      case FETCH_ALL_PRODUCTS:
      return {
        ...state,
        cart: payload,
      };

      case CART_ERROR_MESSAGE:
      return {
        ...state,
        hasError: payload.hasError,
        errorMessage: payload.errorMessage,
        currentProductId: payload.currentProductId,
      };

    default:
      return state;
  }
};

export default Reducer;
