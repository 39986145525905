import React, { useEffect } from "react";
import { Button, IconButton, Typography, makeStyles } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { ToggleButton } from "@material-ui/lab";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { useContext } from "react";
import addCartContext from "../../context/AddCartContext/AddCartContext";
import { toast } from "react-toastify";
import wishlistContext from "../../context/WishlistContext/WishlistContext";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "1.5rem 0.5rem",
    display: "flex",
    alignItems: "center",
  },
  content_course: {
    display: "flex",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  description: {
    marginLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
      justifyContent: "flex-end",
    },
  },
  image: {
    background: "#FFE8B5",
    padding: "1.3rem 2rem 0.3rem 2rem",
    borderRadius: "0.375rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.2rem 0.8rem 0.2rem 0.8rem",
    },
    "& img": {
      maxWidth: "100px",
      objectFit: "cover",
      [theme.breakpoints.down("xs")]: {
        width: "62px",
      },
    },
  },
  ratings: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "55%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& .MuiTypography-h6": {
      fontSize: "1.2rem",
      fontWeight: "500",
      fontFamily: "'Jost', sans-serif",
    },
    "& .MuiTypography-caption": {
      fontSize: "1.1rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "var(--gray-500, #8C94A3)",
    },
  },
  title: {
    "& .MuiTypography-h3": {
      fontSize: "1.38rem",
      fontWeight: "600",
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        marginTop: "0rem",
        whiteSpace: "wrap",
        height: "45px",
        overflow: "hidden",
        textOverflow: `"..."`,
      },
    },
  },

  courseby: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    marginTop: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0.5rem",
    },
    "& .MuiTypography-caption": {
      fontSize: "1.1rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "var(--gray-500, #8C94A3)",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        color: "#A1A5B3",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "var(--gray-500, #4E5566)",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        color: "#4E5566",
      },
    },
  },
  content_price: {
    display: "flex",
    alignItems: "center",
    width: "24%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& .MuiTypography-h3": {
      color: "#FF3939",
      fontSize: "1.625rem",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
    },
  },
  content_price_mobile: {
    // display: "flex",
    // alignItems: "center",
    width: "24%",
    display: "none",
    marginTop: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    "& .MuiTypography-h4": {
      color: "#FF3939",
      fontSize: "1rem",
      fontWeight: "700",
      fontFamily: "'Jost', sans-serif",
    },
  },
  content_action: {
    width: "20%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "20%",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  btn: {
    width: "100%",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    margin: "2rem 0 2rem 0.5rem",
    padding: "0.8rem 2rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  iconbutton: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    display: "none",
    // margin: "2rem 0 2rem 0.5rem",
    padding: "0.5rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
  },
  hr: {
    border: "1px solid #E9EAF0",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

function WishlistCard({ data, renderDataAgain }) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(true);
  const { addToCartListAPI, cartState } = useContext(addCartContext);
  const { removefromWishListAPI } = useContext(wishlistContext);

  useEffect(() => {
    const hasAddedProduct =
      cartState?.cart?.products?.find(
        (el) => Number(el.product_id) === Number(data.product_id)
      ) || {};
    if (hasAddedProduct?.id > -1) {
      removefromWishListAPI(data?.id);
    }
  }, [cartState]);
  return (
    <div>
      <div className={classes.content}>
        <div className={classes.content_course}>
          <div className={classes.image}>
            <img src={data?.product?.image_url} alt="logo" />
          </div>
          <div className={classes.description}>
            <div className={classes.title}>
              <Typography variant="h3">
                {data?.product?.ebook_name ||
                  data?.product?.book_name ||
                  data?.product?.product_name}
              </Typography>
              <div className={classes.content_price_mobile}>
                <Typography variant="h4">
                  ₹{data?.product?.selling_cost}
                </Typography>
              </div>
            </div>
            {data?.product?.author && (
              <div className={classes.courseby}>
                <Typography variant="caption">Course by: </Typography>
                <Typography>{data?.product?.author}</Typography>
              </div>
            )}
          </div>
        </div>
        <div className={classes.content_price}>
          <Typography variant="h3">₹{data?.product?.selling_cost}</Typography>
        </div>
        <div className={classes.content_action}>
          <div className={classes.btn1}>
            <Button
              onClick={() => {
                const check = cartState?.cart?.products?.find(
                  (el) => el.id === data.id
                );

                if (check) {
                  toast.error("already added in cart");
                } else {
                  addToCartListAPI(data);
                  //  toast.success("add data in cart successfully");
                }
              }}
              className={classes.bluecustombtn}
            >
              Add To Cart
            </Button>
            <IconButton
              fontSize="small"
              onClick={() => {
                const check = cartState?.cart?.products?.find(
                  (el) => el.id === data.id
                );

                if (check) {
                  toast.error("already added in cart");
                } else {
                  addToCartListAPI(data);
                  //  toast.success("add data in cart successfully");
                }
              }}
              className={classes.iconbutton}
            >
              <ShoppingCartOutlinedIcon />
            </IconButton>
          </div>
          <div className={classes.btn2}>
            <ToggleButton
              value="check"
              selected={selected}
              onChange={() => {
                renderDataAgain(data.id);
                setSelected(false);
              }}
            >
              <FavoriteIcon />
            </ToggleButton>
          </div>
        </div>
      </div>
      <hr className={classes.hr} />
    </div>
  );
}

export default WishlistCard;
