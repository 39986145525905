import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { GrFormClose } from "react-icons/gr";
import { useForm, SubmitHandler } from "react-hook-form";
import { signUp } from "../../service/services";
import { toast } from "react-toastify";
import { fillOtherInfoOfLearners } from "../../service/services";
import AppContext from "../../context/AppContext";
import CategoryContext from "../../context/CategoryContext/CategoryContext";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "50%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      margin: "0",
      fontWeight: "700",
      marginTop: "2rem",
      marginLeft: "3rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-h4 ": {
      color: "#243B55",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",

      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.58rem",
      },
    },
    "& .MuiTypography-body2": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.69rem",
      },
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
        marginTop: "0rem",
      },
    },
    "& .MuiDivider-root": {
      width: "27%",
      background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
      height: "0.125rem",
      marginTop: "0.2rem",
      marginLeft: "3rem",
      [theme.breakpoints.down("xs")]: {
        width: "23%",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiFormControl-root": {
      width: "164%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiFormControlLabel-label": {
        fontFamily: "Jost",
        fontSize: "0.9rem",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.65rem",
        },
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& input:-internal-autofill-selected": {
        backgroundColor: "#fff",
      },
    },
  },
  loginform: {
    width: "40%",
    marginTop: "4rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  paperinner: {
    margin: "1rem",
  },
  paper: {
    backgroundColor: "#FFFAEF",
    zIndex: "8",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    height: "calc(100vh - 40vh)",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",

    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: "3rem",
      fontFamily: "Jost",
      color: "#676767",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "100px",
      padding: "1rem",
    },
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    marginBottom: "5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  googlefbiconmain: {
    display: "flex",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      justifyContent: "space-evenly",
      margin: "0.5rem 0",
    },
  },
  facebookicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },

  googleicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },
  passwordheadcont: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1.5rem",
    //   padding: "0.5rem 1rem",
    //   marginLeft:'4rem',
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  formfootertext: {
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#5E5E5E",
    "& .MuiLink-root": {
      color: "#FF9213",
      cursor: "pointer",
    },
  },
  inputhead: {
    marginTop: ".5rem",
  },
  closebtncontainer: {
    position: "absolute",
    top: "-11px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    },
  },

  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px ",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  facebooksvg: {
    width: "18px",
    height: "30px",
  },
  googlesvg: {
    width: "30px",
    height: "30px",
  },
  imgbox: {
    marginRight: "3rem",
  },
  btncontainer: {
    width: "50%",
    padding: " 0.3rem ",
  },
  jobcustombtn: {
    height: "5rem",
    border: "1px solid #EEE",
    borderRadius: "5px",
    background: " #FFF",
    boxShadow: "0px 1px 11px 0px rgba(0, 0, 0, 0.17)",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
  },
  listbox: {
    display: "flex",
    listStylePosition: "inside",
    flexWrap: "wrap",
    fontSize: ".5rem",
    flexDirection: "row",
    padding: "0",
    "& li": {
      marginLeft: "0.5rem",
    },
  },

  allbutton: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "2rem",
  },
  backbtn: {
    height: "43px",
    width: "120px",
    border: "1px solid #A2A2A2",
    borderRadius: "6px",
    background: "#FFF9EC",
  },
  submitbtn: {
    height: "43px",
    width: "120px",
    border: "1px solid #A2A2A2",
    borderRadius: "6px",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "#FFF",
  },
  buttons: {
    // gap:'2rem',
    // marginLeft:'45rem',
    // marginTop:'10rem',
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    gap: "1.5rem",
    marginRight: "4rem",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
function PickCategoryModal(props) {
  const { learnerName, isCategoryModalOpen, closeModal } = props;

  const location = useLocation();
  console.log("location", location);

  const [selectedCategory, setSelectedCategory] = useState(""); // State to track the selected category

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };
  const data1 = useContext(CategoryContext);
  const data = useContext(AppContext);
  console.log(data, "data");
  const allCategories = [];
  const megaMenu = data1?.megaMenu || [];
  console.log(megaMenu, "mea");
  console.log(data1, "data1");

  console.log(data1?.coursesData?.subcategory, "new data");
  megaMenu.forEach((menu) => {
    if (menu.categories) {
      menu.categories.forEach((category) => {
        const categoryData = {
          id: category.id,
          category_name: category.category_name,
          subCategories: [],
        };

        if (category.subCategories) {
          categoryData.subCategories = category.subCategories.map(
            (subCategory) => ({
              id: subCategory.id,
              sub_category_name: subCategory.sub_category_name,
              exams: subCategory.exams || [],
            })
          );
        }

        allCategories.push(categoryData);
        console.log(allCategories, "1");
      });
    }
  });

  const subCategories = allCategories.flatMap(
    (category) => category.subCategories
  );

  const handleSubmit = () => {
    if (!selectedCategory) {
      toast.warning("Please select a category.");
      return;
    }

    const categoriesData = allCategories.flatMap((category) => category);
    const category = Array.isArray(categoriesData)
      ? categoriesData.find(
          (data) => String(data?.id) === String(selectedCategory)
        )
      : null;
      
    const requestData = {
      learner: {
        full_name: learnerName,
        category: category?.category_name,
      },
    };

    fillOtherInfoOfLearners(requestData)
      .then((response) => {
        console.log("API Response:", response);
        localStorage.setItem("selectedCategory", selectedCategory);
        closeModal();
      })
      .catch((error) => {
        console.error("API request failed:", error);
      });
  };

  const classes = useStyles();

  return (
    <>
      <Modal
        open={isCategoryModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={location.pathname === "/dashboard" && closeModal}
        className={classes.modal}
      >
        <div className={classes.container}>
          {location.pathname === "/dashboard" && (
            <div className={classes.closebtncontainer}>
              <Button className={classes.closebtn} onClick={closeModal}>
                <GrFormClose />
              </Button>
            </div>
          )}
          <div className={classes.paper}>
            <div className={classes.paperinner}>
              <Typography
                variant="h3"
                gutterBottom
                className={classes.orangetext}
              >
                Pick Your Category..
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                You can switch between Category later as well
              </Typography>
              <Divider />
              <div className={classes.allbutton}>
                {allCategories?.map((data) => (
                  <div className={classes.btncontainer}>
                    <Button
                      className={classes.jobcustombtn}
                      fullWidth
                      type="Government job Exams"
                      onClick={() => handleCategorySelect(data.id)}
                      style={{
                        background:
                          selectedCategory === data.id
                            ? "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)"
                            : "#FFF",
                        color:
                          selectedCategory === data.id ? "#252525" : "#000",
                      }}
                    >
                      <Typography variant="h4" display="block" gutterBottom>
                        {data?.category_name}
                      </Typography>
                      <div>
                        <ul className={classes.listbox}>
                          {data?.subCategories?.map((data) => (
                            <li>{data?.sub_category_name}</li>
                          ))}
                        </ul>
                      </div>
                    </Button>
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.buttons}>
              {/* <Button className={classes.backbtn} fullWidth type="submit">
                BACK
              </Button> */}

              <Button
                className={classes.submitbtn}
                onClick={handleSubmit}
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default PickCategoryModal;
