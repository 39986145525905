import React, { useState, memo } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.5rem",
    margin: "0.6rem 0",
    display: "flex",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "0",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.725rem",
      whiteSpace: "wrap",
      height: "32px",
      overflow: "hidden",
      textOverflow: `"..."`,
      textAlign: "left",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.6rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body2": {
      margin: "0.2rem 0",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.6rem",
    },
    "& .MuiRating-root": {
      fontSize: "1rem",
    },
    "& .MuiCardActions-root": {
      borderRadius: 6,
      padding: "0.2rem",
      "& .MuiButton-label": {
        fontFamily: "Jost",
        fontSize: "0.7rem",
        alignItems: "flex-start",
      },
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "63%",
      padding: "0",
      margin: "0",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0.6rem",
      // padding: '1rem 1rem',
    },
  },
  cardimagecontainer: {
    borderRadius: "6px",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  cardcontent: {
    width: "65%",
    padding: "0rem 0rem 0rem 0.5rem !important",
  },
  favoritebtn: {
    // width: "14%",
    position: "absolute",
    top: "3px",
    left: "3px",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
    "& .MuiIconButton-root": {
      width: "70%",
      padding: "0 2px",
    },
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.125rem",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "600",
    marginRight: "0.3rem",
    // marginTop: "0.8rem",
  },
  offertext: {
    marginLeft: "0.5rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.6rem",
    fontWeight: "400",
  },
  cartbagicon: {
    marginRight: "0.4rem",
  },
  viewbutton: {
    marginTop: "0.5rem",
    width: "100%",
    padding: "0.25rem",
    borderRadius: "6px",
    background: "#243B55",
    "&:hover": {
      background: "linear-gradient(270deg, #FFB36B 0%, #FFD57A 100%);",
    },
    "& .MuiButton-label": {
      color: "#fff",
      textTransform: "capitalize",
      "&:hover": {
        color: '#000'
      },
    }
  },
}));
const BooksTableMobile = memo(({ setOnViewClicked, data }) => {

  const classes = useStyles();

  console.log("object23423423", data)
  return (
    <Card
      className={classes.root}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={classes.cardimagecontainer}>
        
        <img
          src={data?.image || require("../../image/notFoundData.png")}
          alt="img"
          width="120px"
          height="120px"
        />
      </div>
      <CardContent className={classes.cardcontent}>
        <Typography gutterBottom variant="h5" component="div">
          {data?.productname}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          by {data?.authorname}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span className={classes.textred}>₹{data?.amount}</span>
         
        </Typography>
        <CardActions className={classes.bgorange}>
          <Button onClick={() => { setOnViewClicked("orderdetails", data?.id) }} className={classes.viewbutton} >View</Button>
        </CardActions>
      </CardContent>
    </Card>
  );
});

export default BooksTableMobile;
