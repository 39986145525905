import React from "react";
import Banner from "./bannersection/banner";
import PreprationSectionMain from "./preparationsection/preprationsection";
import PopularExamSection from "./popularexamssection/popularexamssection";
import LatestTeatSeriesSection from "./latesttestseriessection/latesttestseriessection";
import ChooseUsSection from "./chooseussection/chooseussection";
import DownloadAppSection from "./downloadourappsection/downloadourappsection";
import InstitutionalPartnersSection from "./institutionalpartnerssection/institutionalpartnersection";
import LatestNotificationSection from "./latestnotificationsection/latestnotificationsection";
import ExclusiveBooksSection from "./exclusivebooks/exclusivebooksection";
import TeachersSection from "./teachers/teacherssection";
import OrderPlaced from "../allpopups/OrderPlaced";

function Home() {
  return (
    <>
      <Banner />
      <PopularExamSection />
      <LatestTeatSeriesSection />
      <LatestNotificationSection />
      <ExclusiveBooksSection />
      <PreprationSectionMain />
      <ChooseUsSection />
      <OrderPlaced />
      {/* <TeachersSection /> */}
      <DownloadAppSection /> 
      {/* <InstitutionalPartnersSection /> */}
    </>
  );
};
export default Home;
