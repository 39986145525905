import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Card,
  Box,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import WrongAnswer from "./wronganswer";
import RightAnswer from "./rightanswer";

const useStyles = makeStyles((theme) => ({
  questionOptionsContainer: {
    padding: "20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(225, 225, 225, 1)",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(225, 225, 225, 1)",
    },
    "& .MuiCard-root": {
      [theme.breakpoints.down("xs")]: {
        boxShadow: "none",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
      background: "#FFFBF1",
    },
  },
  question: {
    marginBottom: "20px",
    fontWeight: "700",
    lineHeight: "30px",
    fontFamily: "'Jost', sans-serif",
    fontSize: "20px",
  },
  paragraph: {
    fontSize: "14px",
    lineHeight: "1.5",
  },
  options: {
    fontSize: "20px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    marginTop: "-28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
    },
  },
  optionHeading: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "20px",
    color: "rgba(114, 114, 114, 1)",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    flex: 1,
  },
  inputField: {
    marginBottom: "10px",
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.06)",
    padding: "0.1rem 0.5rem",
    borderRadius: "0.375rem",

    [theme.breakpoints.down("xs")]: {
      width:'96%',
      background: "#FFF",
    },
  },
  placeholder: {
    width: "20%",
    color: "black",
    fontSize: "20px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
  },
  icon: {
    width: "20px",
    height: "20px",
    backgroundColor: "lightgray",
    borderRadius: "4px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  horizontalLine: {
    borderTop: "1px solid rgba(234, 234, 234, 1)",
    margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
      borderTop: "4px solid rgba(255, 204, 0, 1)",
    },
  },
  squareBox: {
    width: "28px",
    height: "28px",
    backgroundColor: "rgba(241, 241, 241, 1)",
    fontWeight: "500",
    color: "rgba(33, 33, 33, 1)",
    fontSize: "18px",
    borderRadius: "4px",
    fontFamily: "'Jost', sans-serif",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorOutline: {
    borderColor: "red",
  },
  clearIcon: {
    color: "rgba(255, 252, 244, 1)",
    width: "28px",
    height: "28px",
    backgroundColor: "rgba(255, 57, 57, 1)",
    borderRadius: "6px",
  },

  checkIcon: {
    color: "rgba(255, 255, 255, 1)",
    width: "28px",
    height: "28px",
    backgroundColor: "rgba(0, 169, 17, 1)",
    borderRadius: "6px",
  },
  customFocus: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "3px solid  rgba(255, 193, 193, 1)",
    },
  },

  greenFocus: {
    background: "#EEFFF0",
    border: "2px solid #AAFFB2",
  },
  redFocus: {
    background: "#FFF2F2",
    border: "2px solid #FFC1C1",
  },
  squareBoxRed: {
    backgroundColor: "rgba(255, 57, 57, 1)",
    color: "white",
  },
  greenSquareBox: {
    backgroundColor: "rgba(0, 169, 17, 1)",
    color: "white",
  },
  rectangleBox: {
    width: "100%",
    margin: "35px 0px",
    backgroundColor: "white",
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
  },
  paraHeading: {
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    fontSize: "22px",
  },
  answerText: {
    width: "70%",
    fontFamily: "Jost",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9375rem",
    },
  },
}));

// ... (your imports and styles)

function QuestionAndOptions({ question, selectedLanguage }) {
  const classes = useStyles();
  const [rightAnswer, setRightAnswer] = useState(false);
  const [optionCount, setOptionCount] = useState(0);

  useEffect(() => {
    let count = 0;
    question?.options?.forEach((option) => {
      if (option.option_en) count++;
      if (option.correct_option && option.user_answer) setRightAnswer(true);
    });
    setOptionCount(count);
  }, [question]);
  const optionArray = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
  ];
  const optionArrayHindi = [
    "अ",
    "ब",
    "सी",
    "डी",
    "इ",
    "ए",
    "एफ",
    "जी",
    "एच",
    "आई",
    "जे",
    "के",
  ];

  return (
    <Card>
      <div className={classes.questionOptionsContainer}>
        <div
          className={classes.options}
          dangerouslySetInnerHTML={{
            __html:
              selectedLanguage === "hindi"
                ? question?.question_hi
                : question?.question_en,
          }}
        ></div>
        <div className={classes.inputContainer}>
          {optionArray.slice(0, optionCount).map((option, index) => (
            <TextField
              key={option}
              className={`${classes.inputField} ${
                question?.options?.[index]?.correct_option &&
                (question?.options?.[index]?.user_answer || !rightAnswer)
                  ? classes.greenFocus
                  : question?.options?.[index]?.user_answer && classes.redFocus
              }`}
              fullWidth
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <div
                        className={`${classes.squareBox} ${
                          question?.options?.[index]?.correct_option &&
                          (question?.options?.[index]?.user_answer ||
                            !rightAnswer)
                            ? classes.greenSquareBox
                            : question?.options?.[index]?.user_answer &&
                              classes.squareBoxRed
                        }`}
                      >
                        {option}
                      </div>
                    </InputAdornment>
                    <div
                      className={classes.answerText}
                      dangerouslySetInnerHTML={{
                        __html:
                          selectedLanguage === "hindi"
                            ? question?.options?.[index]?.option_hi
                            : question?.options?.[index]?.option_en,
                      }}
                    />
                  </>
                ),
                endAdornment: (
                  <>
                    {question?.options?.[index]?.correct_option &&
                    (question?.options?.[index]?.user_answer ||
                      !rightAnswer) ? (
                      <InputAdornment position="end">
                        <IconButton>
                          <CheckIcon className={classes.checkIcon} />
                        </IconButton>
                      </InputAdornment>
                    ) : question?.options?.[index]?.user_answer ? (
                      <InputAdornment position="end">
                        <IconButton>
                          <ClearIcon className={classes.clearIcon} />
                        </IconButton>
                      </InputAdornment>
                    ) : null}
                  </>
                ),
                classes: { input: classes.placeholder },
                disableUnderline: "true",
              }}
              onClick={() => {
                // setAnswer(index);
              }}
              inputProps={{
                readOnly: true,
                onFocus: (event) => event.target.blur(),
              }}
            />
          ))}
        </div>
        {!rightAnswer && (
          <Box className={classes.rectangleBox}>
            <WrongAnswer
              question={question}
              selectedLanguage={selectedLanguage}
            />
          </Box>
        )}
        {rightAnswer && (
          <Box className={classes.rectangleBox}>
            <RightAnswer
              question={question}
              selectedLanguage={selectedLanguage}
            />
          </Box>
        )}
      </div>
    </Card>
  );
}

export default QuestionAndOptions;
