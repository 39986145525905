import {
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import Data from "./prepractionsectioncdata";
import PreprationSectionNewCardMobile from "./PreprationSectionNewCardMobile";
import Slider from "react-slick";
import user from '../../image/catergoryVectoe/Group1413.svg'

const useStyles = makeStyles((theme) => ({
  preprationsectionmain: {
    padding: '0 1.5rem',
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
      [theme.breakpoints.up('xs')]: {
        marginTop: "2.6rem",
      },
  },
  card: {
    background: '#FFFBF0',
    borderRadius: "12px",
    textAlign: "center",
  },
  heading: {
    fontFamily: "Playfair Display",
    marginBottom: 10,
    fontWeight: 600,

  },
  subHeading: {
    fontFamily: "Jost",
    marginBottom: "1.5rem",
    fontWeight: 400,
    color: "#727272", padding: "0px 1rem"
  },
  button: {
    background: "#FFD9B5",
    padding: "1rem",
    borderRadius: 6,
    fontSize: 16,
    fontFamily: "Jost",
  }
}));
function PreprationSectionNewMobile() {

  const classes = useStyles();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    // <Slider {...settings} className={classes.preprationsectionmain}>
    //   {[...Array(7)].map((i) => {
    //     return <div >
    //       <div className={classes.card}>
    //         <div>
    //           <img src={user} className={classes.img} />
    //         </div>
    //         <Typography variant="h5" className={classes.heading}>Online Live Classes</Typography>
    //         <Typography variant="body2" className={classes.subHeading}>when an unknown printer took a galley of type and scrambled it to make a type .</Typography>
    //         <div className={classes.button}>Explore LIVE LECTURES</div>
    //       </div>
    //     </div>

    //   })}
    // </Slider >
    <>
      <div className={classes.preprationsectionmain}>

        {Data.map((data) => (
          <PreprationSectionNewCardMobile data={data} />
        ))}

      </div>
    </>
  );
}
export default PreprationSectionNewMobile;
