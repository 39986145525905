import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // background css starts

  // background css ends

  // width css start
  w69: {
    width: "69%",
  },
  w65: {
    width: "65%",
  },
  w29: {
    width: "29%",
  },
  w90: {
    width: "90%",
  },
  w95: {
    width: "95%",
  },
  displaynonefordesktop: {
    display: "none",
  },
  w100mobile: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  // width css ends

  // height css start

  // height css start
  h100: {
    height: "100%",
  },
  // flex properties css start
  dblockmobile: {
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
    },
  },
  dflex: {
    display: "flex ",
  },
  dnonemobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  justifyspacebetween: {
    justifyContent: "space-between",
  },
  alignitemscenter: {
    alignItems: "center",
  },
  alignitemsend: {
    alignItems: "flex-end",
  },
  flexdirectioncolumn: {
    flexDirection: "column",
  },
  justifyspaceevenly: {
    justifyContent: "space-evenly",
  },
  justifycenter: {
    justifyContent: "center",
  },
  justifyflexend: {
    justifyContent: "flex-end",
  },

  // flex properties css ends

  // shadow properties css start

  // shadow properties css ends

  // text properties css start

  textcolorwhite: {
    color: "#fff",
  },
  textcolorlink: {
    color: "#008ECA",
  },
  textcolorgrey: {
    color: "#475156",
  },
  textcolorlowblack: {
    color: "#191C1F !important",
  },
  textcolorformlabel: {
    color: "#333 !important",
  },

  fcolorgrey1: {
    color: "#999",
  },
  fcolorblack1: {
    color: "#191C1F",
  },
  fcolorgreen: {
    color: "#14B509",
  },

  fontfamiljost: {
    fontFamily: "Jost !important",
  },
  fontfamilyPlayfairDisplay: {
    fontFamily: "Playfair Display",
  },
  fontsize: {
    fontSize: "1.375rem",
  },
  fontsize1: {
    fontSize: "0.8rem !important",
  },
  fontsize2: {
    fontSize: "0.625rem",
  },
  fontsize3: {
    fontSize: "0.875rem",
  },
  fontsize4: {
    fontSize: "1.625rem",
  },
  fontsize5: {
    fontSize: "0.75rem",
  },
  fontsize6: {
    fontSize: "1.125rem",
  },
  fontsize7mobile: {
    fontSize: "1.3rem",
  },

  fontstylenormal: {
    fontStyle: "normal !important",
  },
  fw400: {
    fontWeight: "400 !important",
  },
  fw500: {
    fontWeight: "500 !important",
  },
  fw600: {
    fontWeight: "600 !important",
  },
  fw700: {
    fontWeight: "700 !important",
  },
  textuppercase: {
    textTransform: "uppercase !important",
  },
  lineheight: {
    lineHeight: "1.5rem !important",
  },
  lineheight1: {
    lineHeight: "1rem !important",
  },
  lineheight2_25: {
    lineHeight: "2.25rem !important",
  },
  textdecorationnone: {
    textDecoration: "none",
  },

  // text properties css ends

  // padding properties css start
  p1: {
    padding: "1rem !important",
  },
  px1: {
    padding: "0rem 1rem !important",
  },
  py1: {
    padding: "1rem 0 !important",
  },
  py2: {
    padding: "2rem 0 !important",
  },
  // padding properties css ends

  // margin properties css start
  mt1: {
    marginTop: "1rem",
  },
  mt2: {
    marginTop: "2rem",
  },
  my05: {
    margin: "0.5rem 0",
  },
  // margin properties css ends

  // borderradius properties css start

  borderradius6px: {
    borderRadius: "6px  !important",
  },

  // borderradius properties css ends

  // borderradius properties css start

  bordergrey: {
    border: "1px solid #DFDFDF",
  },
  // borderradius properties css ends

  // button properties css start

  bluebtn: {
    background: "#00577B  !important",
    color: "white  !important",
    fontSize: "0.9rem  !important",
    fontFamily: "Outfit  !important",
    fontWeight: "500  !important",
    textTransform: "capitalize  !important",
    padding: "0.4rem 1.5rem  !important",
    "& svg": {
      marginRight: "10px",
    },
    "&:hover": {
      background: "#00577B",
      color: "white",
    },
  },
  transparentbtn: {
    background: "white !important",
    border: "1px solid #353535 !important",
    color: "#252525 !important",
    fontSize: "0.9rem !important",
    fontFamily: "Outfit important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    padding: "0.4rem 2rem !important",
    "& svg": {
      marginRight: "10px !important",
      fill: "#252525",
    },
    "&:hover": {
      background: "white !important",
      color: "#252525 !important",
    },
  },

  // button properties css ends

  pagescroll: {
    overflow: "scroll",
    "&::-webkit-scrollbar ": {
      display: "none",
    },
  },

  // Side Pannel properties css starts

  cursorpointer: {
    cursor: "pointer",
  },

  tab1: {
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: "0 !important",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      flexDirection: "row",
      justifyContent: "flex-start",
      "& svg": {
        margin: 0,
        marginRight: "10px",
      },
    },
  },

  link2: {
    "&:hover": {
      backgroundColor: "#EDFAFF !important",
      color: "#3E75E0 !important",
      "& svg": {
        fill: "#3E75E0",
      },
    },
  },
  link: {
    color: "#3784F8",
    "& svg": {
      fill: "#000",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  activelink: {
    backgroundColor: "#EDFAFF !important",
    color: "#3E75E0 !important",
    "& svg": {
      fill: "#3E75E0",
    },
  },
  activedropdown: {
    background: "#146A8E !important",
    color: "white !important",
    "& svg": {
      fill: "#fff",
    },
  },

  // Side Pannel properties css ends

  custombtnblue: {
    background: "#00577B",
    color: "white",
    fontSize: "1rem",
    fontFamily: "Outfit",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "0.4rem 2rem",
    "&:hover": {
      background: "#00577B",
      color: "white",
    },
  },
  custombtnoutline: {
    marginRight: "1.5rem",
    background: "white",
    border: "1px solid #353535",
    color: "#252525",
    fontSize: "1rem",
    fontFamily: "Outfit",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "0.4rem 2rem",
    "&:hover": {
      background: "white",
      color: "#252525",
    },
  },

  dropdowncard: {
    width: "200px",
    // top: "1px !important",
    // left: "15px !important",
    zIndex: "1500",
    "& .MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiPaper-rounded ": {
      borderRadius: "0px",
    },
  },

  dropdownlink: {
    textDecoration: "none",
    color: "black",
    fontSize: " 0.4rem !important",
  },

  headname: {
    paddingRight: "2px",
    textAlign: "left",
  },

  // View Table properties css start

  myTestSeriesTable: {
    marginTop: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
    },
    "& .MuiDataGrid-overlay": {
      top: "180px !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "flex-end",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
  },
  myLiveClassesTable: {
    marginTop: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
    },
    "& .MuiDataGrid-overlay": {
      top: "180px !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
  },
  feedbacktable: {
    marginTop: "1rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
    },
    "& .MuiDataGrid-overlay": {
      top: "180px !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "1rem !important",
    },
  },
  timeTable: {
    marginTop: "1rem",
    "& .MuiDataGrid-window": {
      top: "0 !important",
    },
    "& .super-app-theme--header": {
      display: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-viewport": {
      width: "fit-content !important",
      minWidth: "auto !important",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnsContainer": {
      borderBottom: "none",
      display: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "left",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "flex-start",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "1px solid grey !important",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
  },
  myBooksTable: {
    marginTop: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
      textTransform: "uppercase",
    },
    "& .totalcostcell": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "0.8rem",
      fontWeight: 600,
    },
    "& .imagecell": {
      // minHeight: "6rem !important",
      "& img": {
        width: "37%",
      },
    },
    "& .MuiDataGrid-root .MuiDataGrid-overlay": {
      top: '56px !important',
    },
    "& .productnamecell": {
      fontFamily: "Jost",
      fontSize: "0.8rem",
      fontWeight: 600,
    },
    "& .deliverydatecell": {
      fontFamily: "Jost",
      fontSize: "0.85rem",
      fontWeight: 500,
      minHeight: "6rem !important",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },

    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
    "& .MuiDataGrid-row": {
      // minHeight: "5.5rem !important",
    },
  },
  gridtop:{
    top: '100px !important',
  },
  myPurchaseTable: {
    marginTop: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
      textTransform: "uppercase",
    },
    "& .totalcostcell": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "0.8rem",
      fontWeight: 600,
    },
    "& .imagecell": {
      // minHeight: "6rem !important",
      "& img": {
        width: "37%",
      },
    },
    "& .productnamecell": {
      fontFamily: "Jost",
      fontSize: "0.8rem",
      fontWeight: 600,
    },
    "& .deliverydatecell": {
      fontFamily: "Jost",
      fontSize: "0.85rem",
      fontWeight: 500,
      minHeight: "6rem !important",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
    "& .MuiDataGrid-row": {
      // minHeight: "5.5rem !important",
    },
  },

  dashboardPurchaseTable: {
    marginTop: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
      textTransform: "uppercase",
    },
    "& .totalcostcell": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 600,
    },
    "& .imagecell": {
      // minHeight: "6rem !important",
      "& img": {
        width: "37%",
      },
    },
    "& .productnamecell": {
      fontFamily: "Jost",
      fontSize: "0.8rem",
      fontWeight: 600,
      minHeight: "6rem !important",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
    },
    "& .deliverydatecell": {
      fontFamily: "Jost",
      fontSize: "0.85rem",
      fontWeight: 500,
      minHeight: "6rem !important",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
      display: "none",
    },

    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
      borderBottom: "0",
    },
    "& .MuiDataGrid-row": {
      // minHeight: "5.5rem !important",
    },
  },
  productTable: {
    marginBottom: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
      textTransform: "uppercase",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },

    "& .totalcostcell": {
      // color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    "& .imagecell": {
      minHeight: "6rem !important",
      // minWidth: "135.198px !important",
      "& img": {
        width: "30%",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
    },
    "& .productnamecell": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 600,
      // minHeight: "4rem !important",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        lineHeight: "1rem !important",
        paddingBottom: "4rem",
      },
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      alignItems: "center",
      borderBottom: "1px solid #DFDFDF",
      height: "5rem",
    },

    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
    "& .MuiDataGrid-row": {
      minHeight: "5.5rem !important",
    },
  },
  ebooksTable: {
    marginTop: "2rem",
    "& .MuiDataGrid-overlay": {
      top: "180px !important",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
      textTransform: "uppercase",
    },
    "& .totalcostcell": {
      // color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    "& .imagecell": {
      minHeight: "6rem !important",
      // minWidth: "135.198px !important",
      "& img": {
        width: "30%",
      },
    },
    "& .productnamecell": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 600,
      // minHeight: "4rem !important",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },

    "& .MuiDataGrid-root .MuiDataGrid-row": {
      alignItems: "center",
      borderBottom: "1px solid #DFDFDF",
    },

    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
    "& .MuiDataGrid-row": {
      minHeight: "2.5rem !important",
    },
    // [theme.breakpoints.down("xs")]: {
    //   "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
    //     fontSize: "0.65rem"
    //   },
    // },
  },
  transactionTable: {
    // marginTop: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2.5rem !important",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        textTransform: "capitalize",
      },
    },
    "& .totalcostcell": {
      // color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    "& .imagecell": {
      minHeight: "6rem !important",
      "& img": {
        width: "30%",
      },
    },
    "& .productnamecell": {
      fontFamily: "Jost",
      fontSize: "1rem",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      alignItems: "center",
    },

    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
      textOverflow: "clip",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
    "& .MuiDataGrid-row": {
      // minHeight: "5.5rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
        fontSize: "0.65rem",
        textOverflow: "clip",
      },
    },
  },
  notesTable: {
    marginTop: "2rem",
    "& .super-app-theme--header": {
      backgroundColor: "#243B55",
      color: "#FFFFFF",
      height: "2rem !important",
      textTransform: "uppercase",
    },
    "& .totalcostcell": {
      // color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    "& .imagecell": {
      minHeight: "6rem !important",
      // minWidth: "135.198px !important",
      "& img": {
        width: "30%",
      },
    },
    "& .productnamecell": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 600,
      // minHeight: "4rem !important",
      whiteSpace: "normal",
      lineHeight: "1.625rem !important",
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": {
      alignItems: "center",
      borderBottom: "1px solid #DFDFDF",
    },

    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      textAlign: "center",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
      {
        justifyContent: "center",
      },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#FFF",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFBF5",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "2rem !important",
    },
    "& .MuiDataGrid-row": {
      minHeight: "5.5rem !important",
    },
  },

  // View Table properties css ends
}));

export default useStyles;
