import React from "react";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "32%",
    margin: "1rem 0",
    padding: "0 1rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    "& .MuiCardContent-root": {
      padding: "1rem 0",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        alignItems: 'center',
      },
    },
    "& .MuiTypography-h6": {
      color: "#263238",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.5625rem",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "1.25rem",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTypography-subtitle1": {
      color: "#696969",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "1.5625rem",
      textTransform: "lowercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  vector: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-h2": {
      color: "#F8F8F8",
      fontFamily: "'Jost', sans-serif",
      fontSize: "7rem",
      fontStyle: "normal",
      fontWeight: "700",
      margin: "0",
    },
    "& svg": {
      width: "100px",
      height: "100px",
      marginBottom:'1rem',
      marginLeft:'1rem',
      [theme.breakpoints.down("xs")]: {
        width: "80px",
        height: "80px",
        marginLeft:'0rem',
      },
    },
  },
  cardcontent: {
    marginLeft:'0.5rem',
  },
}));
function FollowProcessCard(props) {
  const classes = useStyles();
  const { data } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.vector}>{data.vector}</div>
        <div className={classes.cardcontent}>
          <Typography variant="h6" gutterBottom>
            {data.heading}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {data.content}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
export default FollowProcessCard;
