import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import WelcomeSellerImage from "../image/seller/seller.png";
import { ReactComponent as PhoneCallVector } from "../image/coachingsvector/PhoneCall.svg";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "2rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  welcomesection: {
    margin: "0 1rem",
  },
  welcomemain: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  welcomecontent: {
    width: "55%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      padding: "0rem 1rem",
      justifyContent: "center",
    },
  },
  welcomeimgcontainer: {
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  content: {
    marginTop: "2rem",
  },
  welcomecontentinner: {
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
      fontSize: "2rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
        textAlign: "center",
      },
    },
    "& .MuiTypography-h6": {
      color: "#000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: "500",
      marginTop: "1rem",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        textAlign: "center",
      },
    },
    "& .MuiTypography-subtitle2 ": {
      color: "#132238",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "0.875rem",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        textAlign: "center",
      },
    },
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      color: "#2F2F30",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#7E7E7E",
      fontSize: "1rem",
      fontWeight: "400",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        textAlign: "center",
      },
    },
  },
  contactinfo: {
    width: "80%",
    padding: "1rem 2rem",
    display: "flex",
    borderRadius: "0.25rem",
    background: "#FFF3DA",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  contactinfoinner: {
    display: "flex",
    marginLeft: "1rem",
    flexDirection: "column",
  },
}));
function WelcomeSeller() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "join us and make a difference",
      headingmain: "Welcome To Pariksha DO",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.welcomesection}>
          <SecHead Heading={Heading} />
          <div className={classes.welcomemain}>
            <div className={classes.welcomeimgcontainer}>
              <img src={WelcomeSellerImage} alt="img" width="100%" />
            </div>
            <div className={classes.welcomecontent}>
              <div className={classes.welcomecontentinner}>
                <Typography variant="h5" gutterBottom>
                  "Transforming Education, One Click at a Time - Join In!"
                </Typography>
                <div className={classes.content}>
                  <Typography variant="body2" gutterBottom>
                    where we are dedicated to manage and support you to be the
                    best Mentor. Mentors like you have a very good potential and
                    a very hardworking and dedicated students. To test there
                    skills and their knowledge better. Join us to become test
                    ready.
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    For further details and information
                  </Typography>
                  <div className={classes.contactinfo}>
                    <PhoneCallVector />
                    <div className={classes.contactinfoinner}>
                      <Typography variant="subtitle2">
                        Give Us A Call
                      </Typography>
                      <Typography variant="body1">+1 202 555 0190</Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WelcomeSeller;
