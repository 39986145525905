import React from "react";
import { makeStyles } from "@material-ui/core";
import SinglePageContent from "./singlepagecontent";
import PageHead from "../../../pageheading/pageheading";
import { useParams } from "react-router-dom";
import practicecomponentcontext from "../../context";
import { useContext } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({}));

function TestSeriesSinglePageDescription() {
  const classes = useStyles();
  const {id} = useParams();

  const {getTestSeriesDetails,testseriesDetail} = useContext(practicecomponentcontext);
  const Heading = [
    {
      id: 1,
      // headingsmall: "Explore Your Study",
      // headingmain: "All In One General Studies 2023 ",
      breadcrumb:"Test Series / Product"
    },
  ];
  useEffect(()=>{
    getTestSeriesDetails(id);
    // console.log(id,"testseries");
  },[id])
  console.log("getTestSeriesDetails",testseriesDetail)
  return (
    <div>
      <PageHead Heading={Heading}testseriesDetail={testseriesDetail}/>
      <SinglePageContent testseriesDetail={testseriesDetail} />
    </div>
  );
}
export default TestSeriesSinglePageDescription;
