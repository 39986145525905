import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import StarRoundedIcon from "@material-ui/icons/StarRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 328,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    marginBottom: "2rem",
    "& .MuiCardContent-root": {
      padding: "16px",
      textAlign: "center",
      "& .MuiTypography-h4": {
        fontFamily: "'Playfair Display', serif !important",
        fontSize: "1.5rem",
        fontWeight: "500",
        textTransform: "capitalize",
      },
      "& .MuiTypography-colorTextSecondary": {
        fontFamily: "'Jost', sans-serif !important",
        fontSize: "0.9375rem",
        fontWeight: "500",
      },
      "& .MuiTypography-body2": {
        marginLeft: "0.2rem",
        marginRight: "0.3rem",
        fontFamily: "'Jost', sans-serif !important",
        fontSize: "1rem",
        fontWeight: "500",
        color: "#4E5566",
      },
      "& .MuiTypography-caption": {
        marginRight: "0.3rem",
        fontFamily: "'Jost', sans-serif !important",
        fontSize: "0.8rem",
        fontWeight: "500",
      },
    },
  },
  cardimage: {
    display: "flex",
    justifyContent: "center",
    background: "linear-gradient(306deg, #FFE6CE 48.03%, #FFF4D7 53.6%)",
  },
  divider: {
    margin: "1rem 0",
  },
  ratingsandstudentscon: {
    display: "flex",
    justifyContent: "space-between",
  },
  ratings: {
    display: "flex",
    alignItems: "flex-end",
  },
  staricon: {
    color: "#FDB11F",
  },
  students: {
    display: "flex",
  },
  custombtn: {
    width: "100%",
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "white",
    padding: "0.6rem 1rem",
    borderRadius: "0.4rem",
    margin: "0.3rem 1rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif !important",
      fontWeight: "400",
      color: "#303030",
    },
  },
}));

function ExpertTeachersCard(props) {
  const { cardData } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card className={classes.root}>
      <div className={classes.cardimage}>
        <img src={cardData.image} width="280" alt="img" />
      </div>
      <CardContent>
        <Typography variant="h4">{cardData.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {cardData.exam}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          ({cardData.city})
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.ratingsandstudentscon}>
          <div className={classes.ratings}>
            <StarRoundedIcon className={classes.staricon} />
            <Typography variant="body2">{cardData.ratings}</Typography>
          </div>
          <div className={classes.students}>
            <Typography variant="body2">{cardData.students}</Typography>
            <Typography variant="caption" color="textSecondary">
              students
            </Typography>
          </div>
        </div>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          onClick={() => navigate("/facultyprofile")}
          className={classes.custombtn}
        >
          View Courses
        </Button>
      </CardActions>
    </Card>
  );
}

export default ExpertTeachersCard;
