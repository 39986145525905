import React, { useContext } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,

} from "@material-ui/core";
import QuizImage from "../../image/quizreult/quiz.png";
import QuestionAndOptions from "./questionandoptions";
import practiceContext from "../context/PracticeContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "80%",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem",
    },
  },
  verticalSquare: {
    backgroundColor: "white",
    borderRadius: "20px solid 0 0 10px rgba(255, 255, 255, 0.7)",
    margin: "2rem auto",
    boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "1rem auto",
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
      },
    },
  },
  rectangularStrip: {
    height: "60px",
    borderRadius: "10px 10px 0 0",
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 101.38%)",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0",
    },

  },
  logo: {
    marginRight: "10px",
  },
  leftContent: {
    display: "flex",
    alignItems: "center",
    '& .MuiTypography-body2' :{
      marginRight:'1rem',
      },
  },
  headingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "6px 0",
  },
  QNAContainer: {
    width:'65%',
    '&::-webkit-scrollbar ':{
      display: 'none',
    },
    [theme.breakpoints.down("sm")]: {
      width:'90%'
    },
  },
  mainText: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "500",
    fontSize: "22px",
    color: "rgba(34, 56, 82, 1)",
  },
  headingText: {
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  horizontalLine: {
    width: "100px",
    height: "3px",
    background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    margin: "10px 0",
  },
  
}));

function QuizResult({selectedLanguage}) {
  const classes = useStyles();
  const { quizQuestionObj } = useContext(practiceContext);

  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <Typography variant="h2" className={classes.mainText}>
          Your Answers
        </Typography>
        {/* <div className={classes.languageButton}>
              <Select
                labelId="category-label"
                id="state"
                displayEmpty
                MenuProps={menuProps}
                variant="outlined"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Hindi">Hindi</MenuItem>
              </Select>
        </div> */}
        <Divider className={classes.horizontalLine} />
      </div>
      <div className={classes.QNAContainer}>
      {quizQuestionObj?.questions?.map((question, index) => (
        <Box className={classes.verticalSquare}>
          <Box className={classes.rectangularStrip}>
            <div className={classes.leftContent}>
              <img className={classes.logo} src={QuizImage} alt="Logo" />
              <Typography variant="body1" className={classes.headingText}>
                Question {index + 1}/{quizQuestionObj?.questions?.length}
              </Typography>
            </div>
          </Box>
          <QuestionAndOptions question={question} selectedLanguage={selectedLanguage}/>
        </Box>
      ))}
      </div>
    </div>
  );
}

export default QuizResult;
