import {
  Button,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import bgImage1 from "../../image/preprationvector/bgmobile1.jpg";
import bgImage2 from "../../image/preprationvector/bgmobile2.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1rem 0",
    padding: "1rem",
    display: "flex",
    boxShadow: "none",
    borderRadius: 8,
    justifyContent: "space-between",
    "& .MuiTypography-h5": {
      color: "#404040",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.1875rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "1.5rem",
      textTransform: "capitalize",
    },
    "& .MuiCardContent-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      width: "50%",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    textDecoration:'none',
    textAlign:'center',
    color: "white",
    padding: "10px 30px",
    fontSize: "0.475rem",
    fontFamily: "'Jost', sans-serif",
    textTransform: "uppercase",
    fontWeight: "400", boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 10px",
      marginRight: "0px",
    },
  },
  bgimage1: {
    backgroundImage: `url(${bgImage1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  bgimage2: {
    backgroundImage: `url(${bgImage2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flexDirection: "row-reverse",
  },
}));
function PreprationSectionNewCardMobile(props) {
  const { data } = props;
  const classes = useStyles();
  return (
    <>
      <Card className={`${classes.root} ${classes[data.style]}`}>
        <img src={data.cardsvg} alt="img" width="121px" />
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {data.cardheading}
          </Typography>
          <Link to={data.to} className={classes.bluecustombtn}>
            {data.btntext}  
          </Link>
        </CardContent>
      </Card>
    </>
  );
}
export default PreprationSectionNewCardMobile;
