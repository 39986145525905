import React from "react";
import { makeStyles, Card, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rectangle: {
    width: "calc(25% - 20px)",
    height: "125px",
    backgroundColor: "white",
    borderRadius: "5px",
    border: "2px solid #E1D9D1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    margin: "10px",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)", 
      height: "138px", 
    },
  },
  icon: {
    marginRight: "10px",
    color: "red",
  },
  text: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "black",
  },
}));

export { useStyles };

function RectangleSection({ text, icon }) {
  const classes = useStyles();

  return (
    <Card className={classes.rectangle}>
      <div className={classes.icon}>{icon}</div>
      <Typography className={classes.text}>{text}</Typography>
    </Card>
  );
}

export default RectangleSection;
