import React, { useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as RupeesVector } from "../../image/Card/rupeesvector.svg";
import BuyNowButton from "../../practicecomponent/BuyNowButton";
import FavouriteButton from "../../practicecomponent/FavouriteButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "245px",
    height: "397px",
    margin: "1rem 0",
    display: "flex !important",
    flexDirection: "column",
  },
  root: {
    "& .MuiTypography-h3": {
      color: " #000",
      fontFamily: "Playfair Display",
      fontSize: "1rem !important",
      fontWeight: 600,
      whiteSpace: "wrap",
      height: "40px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiDivider-vertical": {
      alignSelf: "center",
      height: "50px",
      width: "2px",
    },
    "& .MuiCardContent-root": {
      padding: "5px 5px 0 5px",
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.up('md')]: {
      "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
        opacity: "0.3",
      },
    },
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  redtext: {
    color: " #FF3939",
    fontFamily: "Jost",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1rem",
  },
  lighttext: {
    color: "#626262",
    fontFamily: "Jost",
    fontSize: "0.7rem",
    fontWeight: 400,
    lineHeight: "1",
    marginLeft: "0.5rem",
  },
  latesttag: {
    width: "60%",
    borderRadius: "0.125rem",
    border: "1px solid #FFA900",
    background: "#FFEEC3",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  ssctextdiv: {
    padding: "0 0.3rem 0 0.3rem",
    position: "relative",
  },
  ssctextdiv1: {
    display: "flex",
    alignItems: "center",
    padding: "0.8rem 0.5rem",
    width: "100% !important",
  },
  quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "40% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
  },
  quikviewbtn: {
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  favoritebtn: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
  },
  flexcontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  offertext: {
    marginLeft: "0.67rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.7rem",
    fontWeight: "400",
  },
  cardimgandtitle: {
    display: "flex",
    justifyContent: "center",
  },
  cardimgcont: {
    width: "230px",
    height: "230px",
    background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    borderRadius: 8,
    display: "flex",
    alignItems: "flex-end",
    '& .MuiCardMedia-root':{
    objectFit: 'contain',
    }
  },
}));

const ExclusiveNoteCard = (props) => {
  const classes = useStyles();
  const { data } = props;
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <CardActionArea
            onMouseOver={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            onClick={() => {
              navigate(`/onlinevideos/${data?.id}`);
            }}
          >
            {isHover && (
              <div className={classes.quikviewcontainer}>
                {/* <Link to={`/book/${data?.id}`}>*/}
                <IconButton className={classes.quikviewbtn}>
                  <InfoIcon />
                </IconButton>
                {/* </Link> */}
                <div className={classes.favoritebtn}>
                  <FavouriteButton
                    isCheckedHeart={isCheckedHeart}
                    data={data }
                    setIsCheckedHeart={setIsCheckedHeart}
                  />
                </div>
              </div>
            )}
            <div className={classes.cardimgandtitle}>
          <div className={classes.cardimgcont}>
            <CardMedia
              component="img"
              image={data?.image_url || require("../../image/notFoundData.png")}
              alt="green iguana"
              width="220px"
              height="220px"
            />
          </div>
        </div>
            <CardContent>
              <div className={classes.ssctextdiv}>
                <Typography variant="h3">
                  { data?.video_name}
                </Typography>
                <div className={classes.flexcontainer}>
                  {data?.seller && (
                    <Typography className={classes.lighttext}>
                      {data?.seller}
                    </Typography>
                  )}
                  <Typography component="span" className={classes.latesttag}>
                  {data?.section_name}
                  </Typography>
                </div>
              </div>
              <div className={classes.ssctextdiv1}>
                <span className={classes.redtext}>
                  ₹ {data?.selling_cost }
                </span>
                <span className={classes.lighttext}>
                  MRP: <RupeesVector /> <del>{data?.mrp }</del>
                </span>
                <span className={classes.offertext}>
                  ({data?.discount_percent }% off)
                </span>
              </div>
            </CardContent>
          </CardActionArea>

          <CardActions className={classes.bgorange}>
            <BuyNowButton data={data} />
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default ExclusiveNoteCard;
