import AssessmentsImage1 from "../image/onlineassessments/assessments1.png";
import AssessmentsImage2 from "../image/onlineassessments/assessments2.png";
import AssessmentsImage3 from "../image/onlineassessments/assessments3.png";
import AssessmentsImage4 from "../image/onlineassessments/assessments4.png";
import AssessmentsImage5 from "../image/onlineassessments/assessments5.png";
import AssessmentsImage6 from "../image/onlineassessments/assessments6.png";
import AssessmentsImage7 from "../image/onlineassessments/assessments7.png";
import AssessmentsImage8 from "../image/onlineassessments/assessments8.png";
//import AssessmentsImage9 from "../image/onlineassessments/assessments9.png";

const ManageSectionData = [
  {
    id: 1,
    text: "Inventory Management",
    image: AssessmentsImage1,
  },
  {
    id: 2,
    text: "Manpower Management",
    image: AssessmentsImage2,
  },
  {
    id: 3,
    text: "Center Booking's Management",
    image: AssessmentsImage3,
  },
  {
    id: 4,
    text: "Travel Management",
    image: AssessmentsImage4,
  },
  {
    id: 5,
    text: "Data Management",
    image: AssessmentsImage5,
  },
  {
    id: 6,
    text: "Payment Management",
    image: AssessmentsImage6,
  },
  {
    id: 7,
    text: "Training Management",
    image: AssessmentsImage6,
  },
  {
    id: 8,
    text: "Audit Management",
    image: AssessmentsImage7,
  },
  {
    id: 9,
    text: "Ticket Management",
    image: AssessmentsImage8,
  },
];

export default ManageSectionData;
