import React from "react";
import { makeStyles } from "@material-ui/core";
import ProductDetails from "./productdetails";
import SimpleTabs from "./tabpannel";
import FAQSection from "./FAQSection";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    width: "100%",
    background: "#FFFCF4",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiAppBar-colorPrimary": {
      background: "none",
      color: "#000000",
    },
  },
}));
function SinglePageContent() {
  const classes = useStyles();
  
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div>
            <ProductDetails />
        </div>
            <SimpleTabs />
            <FAQSection />
      </div>
    </div>
  );
}
export default SinglePageContent;
