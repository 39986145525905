import { Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import Person from "../image/coachingsvector/coachingbannerpersonvector.png";
import BannerBackground from "../image/coachingsvector/coachingbannerbackgroundvector.png";
import { ReactComponent as BannerArrowVector } from "../image/coachingsvector/bannerarrowvector.svg";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "2rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  bannercardmain: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    marginTop: "8rem",
    padding: "0 2rem",
    backgroundImage: `url(${BannerBackground})`,
    color: "white",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "0 1rem",
    },
    "& .MuiTypography-h4": {
      color: "#FFF",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "3.75rem",
    },
    "& .MuiTypography-body2": {
      color: "#9E9E9E",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.1rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "1.625rem",
    },
  },
  bannercardimg: {
    // scale: "1.5",
    display: "flex",
    bottom: "-5px",
    position: "relative",
    justifyContent: "center",
    transform: "translate(0px, -17px)",
    "& video::-webkit-media-controls": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-enclosure": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-panel": {
      display: "none !important",
    },
  },
  bannercardcontent: {
    width: "50%",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      textAlign: "center",
      marginTop: "1rem",
    },
  },
  bannercardbutton: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "80%",
      marginTop: "2rem",
      marginBottom: "2rem",
    },
  },
  orangecustombtn: {
    background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    color: "black",
    padding: "13px 30px",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    textTransform: "uppercase",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  bannercardtextfordesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  bannercardtextformobile: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  bannerimg: {
    width: "65%",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  bannercardcontentExamReady: {
    color: "#FFC539",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.25rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "3.75rem",
    marginRight: "0.5rem",
  },
}));
function AffiliatesBannerCard() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div className={classes.bannercardmain}>
            <div className={classes.bannercardcontent}>
              <Typography variant="h4">
                Make Your Students{" "}
                <span className={classes.bannercardcontentExamReady}>
                  Exam Ready
                </span>
                <BannerArrowVector />
              </Typography>

              <Typography
                className={classes.bannercardtextfordesktop}
                variant="body2"
              >
                Welcome to our state-of-the-art Learn and test your self platform,
                where education meets innovation. Parikshado is designed to empower
                learners and educators alike, providing a seamless and intuitive
                online environment for teaching, learning, and professional
                development.
              </Typography>
            </div>
            <div className={classes.bannercardimg}>
              <img className={classes.bannerimg} src={Person} alt="img" />
            </div>
            <div className={classes.bannercardbutton}>
              <Button variant="contained" className={classes.orangecustombtn}>
                Get connected
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AffiliatesBannerCard;
