import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as AttemptsIcon } from "../../image/quizzesvector/attemps_icon.svg";
import { ReactComponent as QuestionMark } from "../../image/quizzesvector/question_icon.svg";
import { ReactComponent as ClockIcon } from "../../image/quizzesvector/clockicon.svg";
import appContext from "../../../context/AppContext";
import popupContext from "../../../context/PopupContext/PopupContext";
import { testSeriesLogin } from "../../../service/services";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "265px",
    height: "397px",
    margin: "1rem 0",
    display: "flex",
  },
  root: {
    position: "relative",
    "& .MuiTypography-h3": {
      color: " #000",
      fontFamily: "Playfair Display",
      fontSize: "1.375rem",
      fontWeight: 600,
      lineHeight: " 1.875rem",
    },
    "& .MuiCardContent-root": {
      padding: "0px !important",
    },
    "& .MuiTypography-body2": {
      background: "#FF3939",
      color: "#FFF",
      fontFamily: "Jost",
      fontSize: "0.55rem",
      borderRadius: "0px 10px 10px 0px",
      fontWeight: 500,
      position: "absolute",
      textAlign: "center",
      textTransform: "uppercase",
      dropShadow: "(0px 4px 15px rgba(0, 0, 0, 0.15))",
      padding: "0.2rem 1rem",
      zIndex: 1,
      top: "0.8rem",
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
    },
  },
  attemptbtn: {
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.9375rem",
    fontWeight: 400,
    textTransform: "uppercase",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 92.06%)",

    width: "100% ",
    padding: "0.8rem",
    color: " #FFF",
  },

  imagediv: {
    width: "264px",
    height: "254px",
    background:
      "linear-gradient(317deg, #FFD9B5 29.26%, rgba(255, 238, 195, 0.28) 97.42%)",
    borderRadius: "0rem 0rem 0.375rem 0.375rem",
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    "& .MuiTypography-h1": {
      color: " #000",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "1.2rem",
      height: "98px",
      fontWeight: 500,
      lineHeight: "1.3rem",
      textTransform: "uppercase",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiTypography-h2": {
      color: "#FF3939",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "1.125rem",
      fontWeight: 600,
      textTransform: "uppercase",
    },
  },
  imagecontainer: {
    background: "#FFF",
    height: "130px",
    width: "130px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: "1.5rem",
    marginTop: "0.7rem",
  },

  bottomcontainer: {
    textAlign: "center",
    background: "#FFF",

    "& .MuiTypography-h6": {
      color: " #000",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    "& .MuiTypography-h5": {
      color: "#FF3939",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "uppercase",
      lineHeight: "normal",
      marginTop: "0px !important",
    },
    "& .MuiTypography-h4": {
      color: "#000",
      margin: "0.3rem 0",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "1.125rem",
      fontWeight: 500,
      textTransform: "capitalize",
      lineHeight: "1.5rem",
      padding: "0rem 1.5rem",
    },
  },
  heading: {
    color: " #243B55",
    fontFamily: "Jost",
    fontSize: "1.5rem",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  timestyle: {
    color: " #BD4949",
    textAlign: "right",
    fontFamily: "Jost",
    fontSize: " 0.5rem",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  lighttextstyle: {
    color: "#243B55",
    fontFamily: "Jost",
    fontWeight: 400,
    textTransform: "capitalize",
  },
}));

const Card2 = ({ data, bundleId }) => {
  const classes = useStyles();
  const { handleloginOpenclose } = useContext(popupContext);
  const { isLoggedIn, token } = useContext(appContext);

  const handleTestseriesLogin = async (rowId, bundleId) => {
    try {
      const token = localStorage.getItem("token");

      const data = {
        exam: {
          bundle_id: bundleId,
          test_series_id: rowId,
        },
      };

      // Call testSeriesLogin function
      const response = await testSeriesLogin(data, token);
      if (response.status === 200) {
        const responseData = response.data;
        const token = responseData.token;
        const userName = responseData.user.full_name;

        localStorage.setItem("userName", userName);
        localStorage.setItem("examToken", token);
        localStorage.setItem("test_series_id", rowId);

        const newTab = window.open(
          `${process.env.REACT_APP_API_TESTSERIES_URL}/?token=${token}&userName=${userName}`,
          "_blank"
        );

        if (newTab) {
          setTimeout(() => {
            newTab.location.href = `${process.env.REACT_APP_API_TESTSERIES_URL}/?token=${token}&userName=${userName}&test_series_id=${rowId}`;
          }, 1000);
        }

        // const newTab = window.open(
        //   `http://localhost:3001/?token=${token}&userName=${userName}`,
        //   "_blank"
        // );

        // if (newTab) {
        //   setTimeout(() => {
        //     newTab.location.href = `http://localhost:3001/?token=${token}&userName=${userName}&test_series_id=${rowId}`;
        //   }, 1000);
        // }


      }
    } catch (error) {
      // toast.error("Seller is Inactive so cannot login");
      console.error("Error in login: ", error);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <Typography variant="body2">Free Test Series</Typography>
          <CardActionArea>
            <CardContent>
              <div className={classes.imagediv}>
                <div className={classes.imagecontainer}>
                  <img src={data?.image_url} alt="img" width="100%" />
                </div>
                <Typography variant="h1">{data?.test_series_name}</Typography>
                {/* <Typography variant="h2">BILINGUAL</Typography> */}
              </div>
              <div className={classes.bottomcontainer}>
                {/* <Typography variant="h6">clerk prelims</Typography>
                <Typography variant="h5">
                  {data?.question_count}+ total test
                </Typography> */}
                {data?.question_count && (
                  <Typography variant="h4">
                    <QuestionMark /> {data?.question_count} Questions
                  </Typography>
                )}
                {data?.no_of_question && (
                  <Typography variant="h4">
                    <QuestionMark /> {data?.no_of_question} Questions
                  </Typography>
                )}
                <Typography variant="h4">
                  <AttemptsIcon /> {data?.total_score} Marks
                </Typography>
                <Typography variant="h4">
                  {" "}
                  <ClockIcon /> {data?.duration} Minute
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>

          <Button
            onClick={() => {
              if (isLoggedIn && token) {
                // window.open(`/freemock/${data.id}`);
                handleTestseriesLogin(data?.id, bundleId);
              } else {
                handleloginOpenclose();
              }
            }}
            className={classes.attemptbtn}
          >
            Start now
          </Button>
        </Card>
      </div>
    </>
  );
};

export default Card2;
