import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    marginRight: "10px",
    fontSize: "0.8rem",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
    "&:hover": {
      color: "#FFC736 !important",
    },
  },
  headname: {
    fontFamily: "'Jost', sans-serif",
    paddingRight: "2px",
    fontSize: "0.8rem",
    textTransform: "uppercase",
    fontWeight: "400",
  },
  dropdowncard: {
    width: "210px",
    top: "14px !important",
    zIndex: "1500",
    "& .MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiPaper-rounded ": {
      borderRadius: "0px",
    },
  },
  dropdownlink: {
    textDecoration: "none",
    color: "black",
    fontSize: " 0.4rem !important",
    "& .MuiMenuItem-root":{
paddingTop:'10px',
paddingBottom:'10px',
    },
  },

  cardactivegif: {
    width: '25px',
    opacity: '0', /* Initially hide the active gif */
    transition: 'opacity 0.3s ease-in-out', /* Add a transition for smooth animation */
  },
  gifcontainer: {
    '&:hover': {
      cardactivegif: {
        opacity: 1, /* Show the active gif on hover */
      },
    },
  }
  // 'menu-item:hover .cardactivegif': {
  //   opacity: 1; /* Show the active gif on hover */
  // }


}));

export default function MenuListComposition(props) {
  const { dropdownitem, setOpens } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleOptionHover = (option) => {
    setIsHovered(true);
    setSelectedOption(option);
  };

  const handleOptionLeave = () => {
    setIsHovered(false);
    setSelectedOption(null);
  };

  return (
    <div className={classes.root}>
      <div>
        <Button
          className={classes[dropdownitem.style]}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <span className={classes.headname}>{dropdownitem.name}</span>
          {dropdownitem.icon}
        </Button>
        <Popper style={{ maxWidth: 230, width: "100%" }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          className={classes.dropdowncard}
          placement="bottom-start"
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transform:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {dropdownitem.menu.map((head) => (
                      <Link to={head.to} className={classes.dropdownlink} onClick={() => { setOpens(false); window.scroll(0, 0) }}
                        onMouseEnter={() => handleOptionHover(head)}
                        onMouseLeave={handleOptionLeave}>
                        <MenuItem className={classes.gifcontainer} onClick={handleClose}>
                          <img
                            className={classes.cardstaticgif}
                            src={selectedOption === head ? head?.icon : head?.staticicon}
                            alt="img"
                            width="25px"
                            style={{ marginRight: 10 }}
                          />
                          {/* <img
                            className={`${classes.cardactivegif} classes.cardactivegif`}
                            src={head.icon}
                            alt="img"
                            width="25px"
                          /> */}
                          <span className={classes.headname}>{head.name}</span>
                        </MenuItem>
                        <Divider />
                      </Link>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
