import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import downloadicon from "../../image/mytestseries/notes/downloadicon.png";
import downloadwithbooks from "../../image/mytestseries/notes/downloadwithbooks.png";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    width: "100%",
    marginTop: "2rem",
    alignItems: "center",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiTypography-h6": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .MuiTypography-h5": {
      color: "#353535",
      fontFamily: "Jost",
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .MuiTypography-h4": {
      color: "#444",
      fontFamily: "Jost",
      fontSize: "10px",
      fontWeight: "400",
      marginTop: "0.1rem",
    },
    "& .MuiTypography-caption": {
      color: "#2E2E2E",
      fontFamily: " Playfair Display",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: "700",
      textDecoration: "underline",
      marginTop: "1rem",
    },
  },
  container: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "1rem",
      textAlign: "center",
    },
  },
  container2: {
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  container1: {
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "1rem",
      textAlign: "center",
    },
  },
  div2second: {
    border: "1px solid #FFC855",
    borderRadius: " 0.375rem",
    textAlign: "center",
    padding: "0.5rem",
    background: "#FFFAEF",
  },
  dragdrop: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
}));

const UploadFile = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.main}>
        <div className={classes.container}>
          <Typography variant="caption">Upload Files</Typography>
          <Typography variant="h4">
            Supports : Pdf, JPEG, Png, Word Document
          </Typography>
        </div>
        <div className={classes.container1}>
          <img src={downloadwithbooks} alt="img" />
        </div>
        <div className={classes.container2}>
          <label role="button" datacy="uploadImage">
            <div className={classes.div2second}>
              <input type="file" accept="image/png, image/jpeg" hidden />
              <img src={downloadicon} alt="img" />
              <div className={classes.dragdrop}>
                <Typography variant="h6">Drag & Drop</Typography>
                <Typography variant="h5">Your Files Here</Typography>
              </div>
              <Typography variant="h4">
                Supports : Pdf, JPEG, Png, Word Document,
              </Typography>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default UploadFile;
