import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import PageHead from "../pageheading/pageheading";
import { useParams } from "react-router-dom";
import { getAllFAQ, getAllFAQSub, getFAQ, getFAQExamAll } from "../../service/services";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginBottom: "15rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding: "2rem 0",
    "& .MuiTypography-h4": {
      color: "#243B55;",
      fontSize: "1.5rem",
      display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: "0.5rem"
    }
    },
    "& .MuiTypography-h6": {
      marginTop: "8px",
      fontSize: "1rem",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0"
    }
  },
  privacypolicy: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 1rem",
    },
  },
  ol: {
    listStyleType: "lower-alpha",
  },
  "&.a": {
    elementStyleColor: "#FA9D2B",
  },
  list: {
    padding: "0 1rem",
  },
}));
function FAQ() {
  const { type, id } = useParams(); 
  
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Privacy Policy",
      headingmain: "FAQ",
      breadcrumb: "FAQ",
    },
  ];
  const classes = useStyles();
  const [faq, setFaq] = useState([]);
  
  useEffect(() => {
    async function fetchData() {
      try {
       
          const response = await getFAQ(id,type);
          console.log("API Response for exam:", response.data);
          setFaq(response.data.faqs);
       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  }, [id, type]);
  
  
  return (
    <div className={classes.containerfluid}>
      <PageHead Heading={Heading} />
      <div className={classes.container}>
        {faq?.questions?.map((data, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h4" className={classes.faqheading}>
                  Q.{index + 1}:- {" "} 
                  <span
                    dangerouslySetInnerHTML={{ __html: data?.question }}
                  ></span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{ __html: data?.answer }}
                  ></span>
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}
export default FAQ;
