import TeacherImage1 from "../image/expertteachersvector/teacherimage1.png";
import TeacherImage2 from "../image/expertteachersvector/teacherimage2.png";
import TeacherImage3 from "../image/expertteachersvector/teacherimage3.png";
import TeacherImage4 from "../image/expertteachersvector/teacherimage4.png";
import TeacherImage5 from "../image/expertteachersvector/teacherimage5.png";
import TeacherImage6 from "../image/expertteachersvector/teacherimage6.png";
import TeacherImage7 from "../image/expertteachersvector/teacherimage7.png";
import TeacherImage8 from "../image/expertteachersvector/teacherimage8.png";

const TeachersData = [
    {
        id: 1,
        image: TeacherImage1,
        name: "Wade Warren",
        exam : "Economics",
        ratings: "5.0",
        city: "indore",
        students: "36,568",
    },
    {
        id: 2,
        image: TeacherImage2,
        name: "Bessie Cooper",
        exam : "Economics",
        ratings: "4.9",
        city: "bhopal",
        students: "11,434",
    },
    {
        id: 3,
        image: TeacherImage3,
        name: "Floyd Miles",
        exam : "Maths",
        ratings: "4.8",
        city: "ujjain",
        students: "35,671",
    },
    {
        id: 4,
        image: TeacherImage4,
        name: "Ronald Richards",
        exam : "Reasoning",
        ratings: "4.5",
        city: "indore",
        students: "56,236",
    },
    {
        id: 5,
        image: TeacherImage5,
        name: "Wade Warren",
        exam : "Economics",
        ratings: "5.0",
        city: "sehore",
        students: "36,568",
    },
    {
        id: 6,
        image: TeacherImage6,
        name: "Bassie Cooper",
        exam : "Economics",
        ratings: "4.9",
        city: "indore",
        students: "1,434",
    },
    {
        id: 7,
        image: TeacherImage7,
        name: "Floyd Miles",
        exam : "Maths",
        ratings: "4.8",
        city: "bhopal",
        students: "671",
    },
    {
        id: 8,
        image: TeacherImage8,
        name: "Ronald Richards",
        exam : "Reasoning",
        ratings: "4.5",
        city: "indore",
        students: "36",
    },
    {
        id: 9,
        image: TeacherImage1,
        name: "Wade Warren",
        exam : "Economics",
        ratings: "5.0",
        city: "sagar",
        students: "6,568",
    },
    {
        id: 10,
        image: TeacherImage2,
        name: "Bassie Cooper",
        exam : "Economics",
        ratings: "4.9",
        city: "rewa",
        students: "4",
    },
    {
        id: 11,
        image: TeacherImage3,
        name: "Floyd Miles",
        exam : "Maths",
        ratings: "4.8",
        city: "harda",
        students: "5,671",
    },
    {
        id: 12,
        image: TeacherImage4,
        name: "Ronald Richards",
        exam : "Reasoning",
        ratings: "4.5",
        city: "sehore",
        students: "6,236",
    },
    {
        id: 13,
        image: TeacherImage5,
        name: "Wade Warren",
        exam : "Economics",
        ratings: "5.0",
        city: "rewa",
        students: "36,568",
    },
    {
        id: 14,
        image: TeacherImage6,
        name: "Bessie Cooper",
        exam : "Economics",
        ratings: "4.9",
        city: "sagar",
        students: "1,434",
    },
    {
        id: 15,
        image: TeacherImage7,
        name: "Floyd Miles",
        exam : "Maths",
        ratings: "4.8",
        city: "ratlam",
        students: "5,671",
    },
    {
        id: 16,
        image: TeacherImage8,
        name: "Ronald Richards",
        exam : "Reasoning",
        ratings: "4.5",
        city: "harda",
        students: "6,236",
    },
    // {
    //     id: 17,
    //     image: TeacherImage1,
    //     name: "Wade Warren",
    //     exam : "Economics",
    //     ratings: "5.0",
    //     city: "indore fdfd",
    //     students: "236,568",
    // },
    // {
    //     id: 18,
    //     image: TeacherImage2,
    //     name: "Bessie Cooper",
    //     exam : "Economics",
    //     ratings: "4.9",
    //     city: "bhopal fvd",
    //     students: "211,434",
    // },
    // {
    //     id: 19,
    //     image: TeacherImage3,
    //     name: "Floyd Miles",
    //     exam : "Maths",
    //     ratings: "4.8",
    //     city: "ujjain ff",
    //     students: "435,671",
    // },
    // {
    //     id: 20,
    //     image: TeacherImage4,
    //     name: "Ronald Richards",
    //     exam : "Reasoning",
    //     ratings: "4.5",
    //     city: "indore dfd",
    //     students: "1,356,236",
    // },
    // {
    //     id: 21,
    //     image: TeacherImage5,
    //     name: "Wade Warren",
    //     exam : "Economics",
    //     ratings: "5.0",
    //     city: "sehore dfddf",
    //     students: "236,568",
    // },
    // {
    //     id: 22,
    //     image: TeacherImage6,
    //     name: "Bassie Cooper",
    //     exam : "Economics",
    //     ratings: "4.9",
    //     city: "indore dfdf",
    //     students: "211,434",
    // },
    // {
    //     id: 23,
    //     image: TeacherImage7,
    //     name: "Floyd Miles",
    //     exam : "Maths",
    //     ratings: "4.8",
    //     city: "bhopal dff",
    //     students: "435,671",
    // },
    // {
    //     id: 24,
    //     image: TeacherImage8,
    //     name: "Ronald Richards",
    //     exam : "Reasoning",
    //     ratings: "4.5",
    //     city: "indore df",
    //     students: "1,356,236",
    // },
    // {
    //     id: 25,
    //     image: TeacherImage1,
    //     name: "Wade Warren",
    //     exam : "Economics",
    //     ratings: "5.0",
    //     city: "sagar fdfd",
    //     students: "236,568",
    // },
    // {
    //     id: 26,
    //     image: TeacherImage2,
    //     name: "Bassie Cooper",
    //     exam : "Economics",
    //     ratings: "4.9",
    //     city: "rewa df",
    //     students: "211,434",
    // },
    // {
    //     id: 27,
    //     image: TeacherImage3,
    //     name: "Floyd Miles",
    //     exam : "Maths",
    //     ratings: "4.8",
    //     city: "harda eee",
    //     students: "435,671",
    // },
    // {
    //     id: 28,
    //     image: TeacherImage4,
    //     name: "Ronald Richards",
    //     exam : "Reasoning",
    //     ratings: "4.5",
    //     city: "sehore rgrg",
    //     students: "1,356,236",
    // },
    // {
    //     id: 29,
    //     image: TeacherImage5,
    //     name: "Wade Warren",
    //     exam : "Economics",
    //     ratings: "5.0",
    //     city: "rewa rgrg",
    //     students: "236,568",
    // },
    // {
    //     id: 30,
    //     image: TeacherImage6,
    //     name: "Bessie Cooper",
    //     exam : "Economics",
    //     ratings: "4.9",
    //     city: "sagar rg",
    //     students: "211,434",
    // },
    // {
    //     id: 31,
    //     image: TeacherImage7,
    //     name: "Floyd Miles",
    //     exam : "Maths",
    //     ratings: "4.8",
    //     city: "ratlam rgg",
    //     students: "435,671",
    // },
    // {
    //     id: 32,
    //     image: TeacherImage8,
    //     name: "Ronald Richards",
    //     exam : "Reasoning",
    //     ratings: "4.5",
    //     city: "harda rgrg",
    //     students: "1,356,236",
    // },
];

export default TeachersData;