import {makeStyles} from "@material-ui/core";
  import Slider from "react-slick";
  import React, { useEffect, useState } from "react";

import ExclusiveTestseriesCard from "../../../Home/exclusivebooks/ExclusiveTestseriescard";
  
  const useStyles = makeStyles((theme) => ({
    ExclusiveBookCardContainer: {
      "& .slick-slide": {
        display: "flex",
        height: '450px',
        justifyContent: "center",
        paddingTop: "0.5rem",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        // '& div':{
        //   width:'100%',
        // },
        [theme.breakpoints.down("xs")]: {
          padding: '2rem 1.5rem 2rem 1.5rem !important',
        height: '506px',
      },
      },
      '& .slick-dots': {
        [theme.breakpoints.down("xs")]: {
          bottom: '17px',
      },
     
    },
      "& .slick-dots li button:before": {
        color: "#FF8008",
        fontSize: "15px",
      },
    },
  
  
  
  }));
  function ExclusiveTestseriescontent({noteDetail,data}) {
    const initialSlidesToShow = window.innerWidth < 480 ? 1 : 4;
  
    const [sliderSettings, setSliderSettings] = useState({
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: initialSlidesToShow,
      slidesToScroll: initialSlidesToShow,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  
    useEffect(() => {
      const handleResize = () => {
        const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
        setSliderSettings((prevSettings) => ({
          ...prevSettings,
          slidesToShow: newSlidesToShow,
          slidesToScroll: newSlidesToShow,
        }));
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    const classes = useStyles();
  
    const MyDot = ({ isActive }) => (
      <span
        style={{
          display: "inline-block",
          height: isActive ? "15px" : "15px",
          width: isActive ? "15px" : "15px",
          background: isActive ? "#FF8008" : "#EEE7D3",
          borderRadius: "100%",
        }}
      ></span>
    );
    
    return (
      <>
        <div className={classes.ExclusiveBookCardContainer}>
          <Slider {...sliderSettings} className={classes.sliderstyle}>
            
             { data?.map((data, indx) => (
                  <ExclusiveTestseriesCard data={data}key={indx} />
                ))}
          </Slider>
        </div>
      </>
    );
  }
  export default ExclusiveTestseriescontent;
  