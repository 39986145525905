import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import DescriptionBg from "../image/affiliatesvector/decriptionsectionbg.png";
import DescriptionImage from "../image/affiliatesvector/descriptionimage.png";
import BackgroundVector from "../image/affiliatesvector/backgroundvectormobile.png";  

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    background: "linear-gradient(306deg, #FFE6CE 48.03%, #FFF4D7 53.6%)",
    padding: "4rem 0rem 6rem 0rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    background: `url(${DescriptionBg})`,
    padding: "5rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      background: "#FFFFFF",
      margin: "0 1.5rem",
      padding: "2rem 1rem",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display",
      fontFize: "3.125rem",
      fontWeight: "600",
      color: "#000",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontFize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        textAlign: "justify",
      },
    },
  },
  descriptionimage: {
    "& img": {
      width: "437px",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
  //for mobile view
  descriptionimage1: {
    display: "none",
    backgroundImage: `url(${BackgroundVector})`,

    [theme.breakpoints.down("xs")]: {
      display: "block",
      margin: "2rem 1rem",
    },
    "& img": {
      width: "286px",
    },
  },
  heading: {
    marginBottom: "1.5rem",
  },
}));

function DescriptionSection() {
  const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.description}>
          <div className={classes.heading}>
            <Typography variant="h3">Recommend Products.</Typography>
            <Typography variant="h3">Earn Commissions.</Typography>
          </div>
          
          <div className={classes.descriptionimage1}>      {/* for mobile view */}
            <img src={DescriptionImage} alt="img" />
          </div>
          <div>
            <Typography>
              Welcome to one of the largest affiliate marketing programs in the
              world. The Amazon Associates Program helps content creators,
              publishers and bloggers monetize their traffic. With millions of
              products and programs available on Amazon, associates use easy
              link-building tools to direct their audience to their
              recommendations, and earn from qualifying purchases and programs.
            </Typography>
          </div>
        </div>
        <div className={classes.descriptionimage}>
          <img src={DescriptionImage} alt="img" />
        </div>
      </div>
    </div>
  );
}

export default DescriptionSection;
