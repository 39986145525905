import { useContext, useReducer } from "react";
import WishlistReducer from "./WishlistReducer";
import WishlistContext from "./WishlistContext";
import { ADD_TO_WISHLIST, FETCH_ALL, REMOVE_FROM_WISHLIST, WISHLIST_ERROR_MESSAGE } from "./WishlistType";
import { useEffect } from "react";
import { addToWishlist, getAllWishlistProducts, removeFromWishlist } from "../../service/services";
import AppContext from "../AppContext";
import { toast } from "react-toastify";

const initialState = {
  wishList : [],
  hasError: false,
  errorMessage: '',
  currentProductId: 0,
};

const WishlistState = (props) => {
  const userContext = useContext(AppContext);

  const [state, dispatch] = useReducer(WishlistReducer, initialState);

  const clearWishlistError = () => {
    dispatch({ type: WISHLIST_ERROR_MESSAGE, payload: {...state, hasError: false, errorMessage: ''} });
  }

  const fetchWishlistAPI = () => {
    clearWishlistError();
    getAllWishlistProducts().then((res) => {

      const {data : { count, success, wishlist }} = res || {data: {}};
      // leaving count here incase require to show count, add directly to state
      if (success && wishlist.length > 0) {
        dispatch({ type: FETCH_ALL, payload: wishlist });
        // localStorage.setItem("wishList",JSON.stringify(wishlist))
      }
    }).catch(e => dispatch({ type: WISHLIST_ERROR_MESSAGE, payload: {hasError: !e?.response?.data?.success, errorMessage: e?.response?.data?.message?.error || e?.response?.data?.message} }))
  }

  useEffect(()=>{
    if (userContext.isLoggedIn) {
      fetchWishlistAPI();
    }
  },[userContext.isLoggedIn])

  const addToWishListAPI = (data) => {
    if (!data?.id) return null;
    let book_type = data.product_type;
    if (!book_type) book_type = data.hasOwnProperty('book_name') ? 'books' : 'ebooks';

    const body = {
      wishlist: {
          product_id: `${data.id}`,
          product_type: book_type
        }
      }
    addToWishlist(body).then((res) => {
      const {data: {wishlist}} = res || data;
      getAllWishlistProducts();
      console.log('wishlistState1', res)
        toast.success(res.data.message || "Added", { autoClose: 1000 });

      return dispatch({ type: ADD_TO_WISHLIST, payload: {...wishlist, success: res.data.success,} });
    	}).catch(e => {
        toast.error(e?.response?.data?.message?.error || e?.response?.data?.message || "Error", { autoClose: 1000 });
        dispatch({ type: WISHLIST_ERROR_MESSAGE, payload: {hasError: !e?.response?.data?.success, errorMessage: e?.response?.data?.message?.error || e?.response?.data?.message, currentProductId: data.id} })
      })
  };
  // success: res.data.success, successMessages: res.data.message,
  const removefromWishListAPI = (id) => {
    removeFromWishlist(id).then(res => {
      const data = state.wishList?.filter((el)=>el.id !== id)
      getAllWishlistProducts();
      console.log('data', data)
      // toast.success(res.data.message || "Added", { autoClose: 1000 });
      return dispatch({type: REMOVE_FROM_WISHLIST, payload: data})
    }).catch(e => {
      toast.error(e?.response?.data?.message?.error || e?.response?.data?.message || "Error", { autoClose: 1000 });
      dispatch({ type: WISHLIST_ERROR_MESSAGE, payload: {hasError: !e?.response?.data?.success, errorMessage: e?.response?.data?.message?.error || e?.response?.data?.message, currentProductId: id} })
    })
  };


  return (
    <WishlistContext.Provider
      value={{
        wishList : state.wishList,
        wishlistState: state,
        addToWishListAPI,
        removefromWishListAPI,
        clearWishlistError,
      }}
    >
      {props.children}
    </WishlistContext.Provider>
  );
};
export default WishlistState;
