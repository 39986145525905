import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import OurVisionVector from "../image/aboutusvector/ourvision.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
    paddingTop: "3rem",
    paddingBottom: "2rem",
    background: "#FFF7E7",
  },
  container: {
    maxWidth: "1600px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  onlineeducationcontent: {
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: 'block',
      padding: "0 1rem"
    },
  },
  onlineeducationimgcontainer: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "3rem 1rem"
    },
  },
  content: {
    marginTop: "2rem",
  },
  onlineeducationcontentinner: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
      fontSize: "2rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.25rem",
      },
    },
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#7E7E7E",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        textAlign: "justify",
      },
    },
  },
}));
function OurVision() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Empowering Dreams, Inspiring Growth, and Building Bright Futures.",
      headingmain: "Our Vision",
    },
  ];
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <Typography variant="h5" gutterBottom>
                Building brands with 360° solutions & digital approach to grow
                your business online.
              </Typography>
              <div className={classes.content}>
                <Typography variant="body2" gutterBottom>
                  Our vision for an online education platform is to revolutionize
                  learning by providing accessible, high-quality education to
                  people globally. We aim to create an engaging & interactive
                  digital ecosystem that fosters a love for lifelong learning. Our
                  platform will prioritize personalized learning, encourage
                  collaboration, and adapt to individual learning styles. Through
                  cutting-edge technology and a commitment to inclusivity, we
                  aspire to break down educational barriers, making knowledge
                  accessible to all, & empowering individuals to reach their full
                  potential regardless of their background or circumstances.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Our vision for an online education platform is to revolutionize
                  learning by providing accessible, high-quality education to
                  people globally. We aim to create an engaging & interactive
                  digital ecosystem that fosters a love for lifelong learning.
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.onlineeducationimgcontainer}>
            <img src={OurVisionVector} alt="img" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default OurVision;
