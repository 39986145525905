import { FormControl, Select, MenuItem, makeStyles } from "@material-ui/core";
import { useContext, useState } from "react";
import appContext from "../../context/AppContext"

const useStyles = makeStyles(() => ({
  formControl: {
    // width: "460px"
    // marginRight: "1rem",
    // boxShadow: "0 3 6 #000000"
  },
  selectInput: {
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.9rem",
    color: "#727272",
    background: "white",
    border: "1px solid white",
    borderRadius: "2px",
    width: "280px",
    height: "40px",
    padding: "1rem"
  },
}));

function Categories(props) {
  const classes = useStyles();
  const { ExamData, quiz, setQuiz, } = props;
  const AppContext = useContext(appContext);

  return (
    <FormControl className={classes.formControl}>
      <Select value={quiz?.categories} className={classes.selectInput} disableUnderline >
        <MenuItem value={-1} onClick={() => {
          setQuiz({ categoryName: "", categories: -1, subCategories: null, exams: null, examName: "" });

        }}>All Exam Categories</MenuItem>
        {
          ExamData?.map((row) => (
            row?.categories?.map((v, i) =>
              <MenuItem key={i} value={v.id} onClick={() => {
                setQuiz({ ...quiz, categoryName: v?.category_name, categories: v?.id, subCategories: v?.["subCategories"]?.[0]?.["id"], examName: "" });
                // AppContext.setCourseData({
                //   category: v?.id,
                //   categoryName: v?.category_name,
                //   subcategory: v?.["subCategories"]?.[0]?.["id"],
                //   exam: "",
                //   exam_name: "",
                // })
              }}>{v.category_name}</MenuItem>
            )
          ))
        }
      </Select>
    </FormControl>

  )
}

export default Categories;