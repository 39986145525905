import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TestSeriesCard from "./TestSeries";
import TestSeriesBanner from "./TestSeriesBanner";
import RecommendedTestSeries from "./RecommendedTestSeries";
import RecommendedLiveTestSeries from "./RecommendedLiveTestSeries";
import UpcommingLiveTestCard from "./UpcommingLiveTestCard";
import AvailableLiveTestCard from "./AvailableLiveTestCard";
import CompletedLiveTestCard from "./CompletedLiveTestCard";
import OopsLiveTestSeries from "./ErrorPage/OopsLiveTestSeries";
import { getTestSeriesDashBundle } from "../../service/services";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    // background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },

    // marginTop: "3rem",
    // flexGrow: 1,
    // "& .MuiTab-root": {
    //   padding: "10px 18px",
    // },
    // "& .MuiPaper-elevation4": {
    //   boxShadow: "none",
    //   background: "#FFF",
    //   width: "34%",
    //   [theme.breakpoints.down("xs")]: {
    //     padding: "0rem",
    //     width: "100%",
    //   },
    // },
    // "& .MuiTab-textColorInherit.Mui-selected": {
    //   background: "#FFEABE",
    //   color: "#262626",
    //   borderRadius: "5px",
    //   minHeight: "40px",
    //   fontSize: "1rem",
    //   fontWeight: "700",
    //   [theme.breakpoints.down("xs")]: {
    //     width: "50%",
    //     fontSize: "0.8rem",
    //   },
    // },
    // "& .MuiTabs-flexContainer": {
    //   borderBottom: "none",
    // },
    // "& .MuiTab-textColorInherit": {
    //   fontFamily: "Playfair Display",
    //   fontWeight: "700",
    //   fontSize: "1rem",
    //   color: "#262626",
    //   background: "#FFF",
    //   borderRadius: "5px",
    //   [theme.breakpoints.down("xs")]: {
    //     width: "50%",
    //     fontSize: "0.8rem",
    //   },
    // },
    // [theme.breakpoints.down("xs")]: {
    //   "& .MuiTabs-root": {
    //     overflow: "scroll",
    //   },
    //   "& .MuiTabs-fixed": {
    //     overflow: "scroll",
    //   },
    //   "& .MuiTabs-scroller": {
    //     overflow: "scroll",
    //   },
    //   margin: "0rem",
    // },
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "22%",
    marginTop: "0.2em",
    marginLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  bookdetailscontent: {
    marginTop: "2rem",
  },
  bookdetailscontentinner: {
    display: "flex",
    margin: "0.3rem 0",
    alignItems: "baseline",
  },
  cardcontainer1: {
    marginTop: "1rem",
    display: "flex",
    flexWrap: "wrap",
    padding: "2rem",
    background: "#FFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    padding: "1rem",

    },
  },
  cardcontainer2: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  tabroot: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
}));

export default function MyTestSeries(props) {
  const classes = useStyles();
  // const Value = props.setValue;
  const setOnViewClicked = props.setOnViewClicked;
  const bookDetail = props.bookDetail;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Cards = [
    {
      name: "upcoming",
      card: <UpcommingLiveTestCard />,
    },
    {
      name: "available",
      card: <AvailableLiveTestCard />,
    },
    {
      name: "completed",
      card: <CompletedLiveTestCard />,
    },
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getTestSeriesDashBundle(token);
        setData(response.data?.testseriesbundles);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.heading}>
        {" "}
        Test Series
      </Typography>
      <div className={classes.cardcontainer1}>
        {data.length === 0 ? (
          <OopsLiveTestSeries />
        ) : (
          data.map((data, index) => (
            <TestSeriesCard
              setOnViewClicked={setOnViewClicked}
              Data={data}
              key={index}
            />
          ))
        )}
      </div>
      {/* <Tabs
        className={classes.tabroot}
        value={value}
        onChange={handleChange}
        indicatorColor="none"
        aria-label="simple tabs example"
      >
        <Tab label="Test Series" {...a11yProps(0)} />
        <Tab label="Live Test Series" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabpannel}>
        <div className={classes.cardcontainer1}>
          {data.length === 0 ? (
            <OopsLiveTestSeries />
          ) : (
            data.map((data, index) => (
              <TestSeriesCard
                setOnViewClicked={setOnViewClicked}
                Data={data}
                key={index}
              />
            ))
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabpannel}>
        <div className={classes.cardcontainer2}>
          {/* <OopsLiveTestSeries/> */}
      {/* <UpcommingLiveTestCard />
          <AvailableLiveTestCard />
          <CompletedLiveTestCard /> */}

      {/* OOPs Card if content is not available */}
      {/* <OopsLiveTestSeries /> */}
      {/* </div>
      </TabPanel>  */}

      <TestSeriesBanner />
      <RecommendedTestSeries />
      {/* <RecommendedLiveTestSeries /> */}
    </div>
  );
}
