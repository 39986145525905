import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardMedia,
  IconButton,
  Divider,
} from "@material-ui/core";
import FavouriteButton from "../FavouriteButton";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  EbooksCard: {
    justifyContent: "space-between",
    maxWidth: "23.5%",
    display: "flex !important",
    flexDirection: "column",
    borderRadius: 7,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    // "& .MuiButton-root:hover": {
    //   backgroundColor: "#B7B7B7",
    // },
    "& fieldset": {
      margin: "0 0 0 -3px",
      padding: 0,
      display: "flex",
    },
    "& .MuiBox-root": {
      border: "none",
      width: "70%",
    },
    "& .MuiCardContent-root": {
      padding: "0px",
    },
    "& .MuiIconButton-root": {
      padding: "0.5rem",
    },
    "& .MuiFormControlLabel-root": {
      margin: "0",
    },
    "& .MuiTypography-h5": {
      color: "#223852",
      fontFamily: "'Jost', sans-serif",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "500",
      margin: "0",
      lineHeight: "1.2rem",
      textTransform: "capitalize",
      whiteSpace: "wrap",
      height: "37px",
      overflow: "hidden",
      textOverflow: `"..."`,
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    "& .MuiTypography-h4": {
      fontFamily: "'Jost', sans-serif",
      color: "#FF3939",
      fontSize: "0.9375rem",
      fontWeight: "500",
    },
    "& .MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    "& .MuiTypography-body2": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      // width: "45% !important",
      borderRadius: "3%",
    },
    "& .MuiCardActionArea-root": {
      padding: "0px 10px 12px 10px",
      position: "relative",
    },
    "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
      opacity: "0.3",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h5": {
        fontSize: "1.2rem",
        marginBottom: 5,
      },
      "& .MuiCard-root": {
        "@media (min-width: 960px)": {
          width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
    },
  },
  cardimgcont: {
    width: "180px",
    height: "180px",
    background: "#FFE8B5",
    // margin: "0.6rem 0.7rem",
    borderRadius: 8,
    "&:hover": {
      background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    },
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.3rem",
    fontFamily: "Jost",
    fontWeight: "600",
    marginRight: "0.5rem",
  },
  offertext: {
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  newlaunchtag: {
    width: "50%",
    marginBottom: "0.8rem",
    padding: "0.3rem",
    background: "#FF3939",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    borderRadius: "8px",
    "& .MuiTypography-h6": {
      color: "#FFF",
      fontSize: "0.4375rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "50% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
  },
  quikviewbtn: {
    "&:hover": {
      background: "white",
    },
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  testserandvaliditytagcont: {
    display: "flex",
    margin: "1rem 0 1rem 2rem",
    position: "relative",
    justifyContent: "space-evenly",

    "& .MuiTypography-h6": {
      color: "#252525",
      fontSize: "0.625rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "500",
    },
  },
  innertags: {
    // background: "#FFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    padding: "0.1rem 0.8rem",
    borderRadius: "0.4rem",
  },
  hexagoncontainer: {
    position: "absolute",
    left: "-31px",
    top: "-14px",
  },
  cardimgandtitle: {
    display: "flex",
    justifyContent: "center",
  },
  cardtitle: {
    width: "100%",
    paddingTop: "0.5rem",
  },
  carticon: {
    marginRight: "0.6rem",
  },
  tags: {
    position: "absolute",
    left: "0",
    top: "10px",
  },
  ebooktag: {
    background: "linear-gradient(127deg, #FFEEC3 35.82%, #FFD9B5 52.6%)",
    borderRadius: "0px 6px 6px 0px",
    marginRight: "0.3rem",
    fontFamily: "'Jost', sans-serif",
    fontSize: "10px",
    fontWeight: "500",
    textTransform: "uppercase",
    padding: "0.2rem 0.8rem !important",
  },
  latesttag: {
    background: "#66CB70",
    padding: "0.3rem 0.3rem",
    borderRadius: "6px",
    color: "white",
    fontFamily: "'Jost', sans-serif",
    fontSize: "10px",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  totalratingcont: {
    display: "flex",
    alignItems: "center",
    width: "29%",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: "600",
    },
  },
  favoritebtn: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
  },
  ratingandfavorite: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardroot: {
    flexBasis: "33.3333%",
    // maxWidth: 280,
    margin: "0 5px",
    height: 291,
    marginBottom: "2rem",
  },
  carticon: {
    marginRight: "0.6rem",
  },
}));

function EBookCard({ data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  console.log(data, "data");
  return (
    <Card className={[classes.cardroot, classes.EbooksCard]}>
      <CardActionArea
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={() => {
          navigate(`/ebook/${data?.id}`);
        }}
      >
        <div className={classes.tags}>
          <span className={classes.ebooktag}>E-books</span>
          {/* <span className={classes.latesttag}>Latest</span> */}
        </div>
        {isHover && (
          <div className={classes.quikviewcontainer}>
            <IconButton className={classes.quikviewbtn}>
              <InfoIcon />
            </IconButton>
            <div className={classes.favoritebtn}>
              <FavouriteButton
                isCheckedHeart={isCheckedHeart}
                data={data}
                setIsCheckedHeart={setIsCheckedHeart}
              />
            </div>
          </div>
        )}
        <div className={classes.cardimgandtitle}>
          <div className={classes.cardimgcont}>
            <CardMedia
              component="img"
              image={data?.images || require("../../image/notFoundData.png")}
              alt="green iguana"
              width="180px"
              height="180px"
            />
          </div>
        </div>
        <div className={classes.cardtitle}>
          <Typography gutterBottom variant="h5" component="div">
            {data?.ebook_name}
            {data?.sub_heading && <span>{(data?.sub_heading)}</span>}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            by {data?.author}
          </Typography>
          {/* <Typography gutterBottom variant="h5" component="div">
            ( SSC CGL, CHSL, CPO, MTS, GD, JHT)
          </Typography> */}
        </div>
        <Divider />
        <CardContent>
          {/* <div className={classes.ratingandfavorite}>
            <Box component="fieldset" mb={3} borderColor="transparent">
              <Rating name="read-only" value={data?.rating} readOnly />
              <div className={classes.totalratingcont}>
                <Typography variant="h6">{data?.rating}</Typography>
                <Typography variant="caption">
                  ({data?.review_count})
                </Typography>
              </div>
            </Box>
          </div> */}
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ marginBottom: 0 }}
          >
            <span className={classes.textred}>
              <span style={{ fontSize: "1.3rem", verticalAlign: "top" }}>
                ₹
              </span>
              {data?.selling_cost}
            </span>
            <Typography variant="caption">
              MRP:<del>{data?.mrp}</del>
              <span className={classes.offertext}>
                ({data?.discount_percent}% off)
              </span>
            </Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions className={classes.bgorange}>
        <BuyNowButton data={data} />
      </CardActions> */}
    </Card>
  );
}
export default EBookCard;
