

const Reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    default:
      return state;
  }
};

export default Reducer;
