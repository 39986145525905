import DigitalImage from "../image/digitalevaluation/digital1.png";
import DigitalImage1 from "../image/digitalevaluation/digital2.png";
import DigitalImage2 from "../image/digitalevaluation/digital3.png";
import DigitalImage3 from "../image/rpsvector/block1.png";

const OnScreenMarkingData = [
    {
      id: 1,
      iconName: DigitalImage,
      heading: "Auto Calculation Of Marks",
      subheading:
        "You are not required to keep track of the students' attempts at optional questions as the examiner. ",
    },
    {
      id: 2,
      iconName: DigitalImage3,
      heading: "Generation Of Results",
      subheading:
        "With automation, the system can rapidly generate mark sheets and calculate the outcome directly. ",
    },
    {
      id: 3,
      iconName: DigitalImage1,
      heading: "Customization And Branding",
      subheading:
        "This can make it easier to create assessments or exams that are tailored to specific learning goals or needs. ",
    },
    {
      id: 4,
      iconName: DigitalImage2,
      heading: "Integration With Other Systems",
      subheading:
        "This integration streamlines processes, improves decision-making, and ensures a cohesive digital ecosystem. ",
    },
  ];
  export default OnScreenMarkingData