import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import AssessmentsSection from "./assessmentssection";
import ManageSection from "./managesection";
import SolutionSection from "./solutionsection";
import CardSection from "./cardsection";
import TestCenter from "./testcenter";
import ContactUsSection from "../biometric/contactsection";

const useStyles = makeStyles((theme) => ({}));
function OnlineAssessment() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Online Assessments",
      headingmain: "Online Assessments",
      breadcrumb: "Online Assessments",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <AssessmentsSection />
      <ManageSection />
      <SolutionSection />
      <CardSection />
      <TestCenter />
      <ContactUsSection />
    </div>
  );
}
export default OnlineAssessment;
