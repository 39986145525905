import React, { useEffect, useState } from "react";
import PageHeading from "../pageheading/pageheading";
import { makeStyles } from "@material-ui/core";
import SingleProductPageMain from "./SingleProductPageMain";
import { getSingleExamProductAPI } from "../../service/services";
import { useParams } from "react-router-dom";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  pageheadingmain: {
    width: "100%",
    marginBottom: "15rem",
    marginTop: "2rem",

    [theme.breakpoints.down("sm")]: {
      position: "unset",
      padding: 0,
    },
  },
  pageheadingmaininner: {
    margin: "0 auto",
    paddingBottom: "1.5rem",
    paddingTop: "1rem",
    // backgroundImage: `url(${PageHeadingBg})`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem 1.5rem 1rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1.5625rem",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 6,
      },
    },
    "& .MuiBreadcrumbs-ol": {
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorInherit": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "#FF8F12",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
    },
  },
  pageheading: {
    padding: "1rem 0 2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
    },
  },
  pageheadhr: {
    margin: "0",
    width: "6rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  quizzcontentcontainer: {
    maxWidth: "1250px",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "15rem",
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "3.8rem",
    fontFamily: "'Playfair Display', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      fontFamily: "'Playfair Display', serif !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  w25: {
    width: "24%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  w74: {
    width: "74%",
  },
}));

const ExamSingleProductPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [singleExamProduct, setSingleExamProduct] = useState();
  const [checkedInitial, setCheckedInitial] = useState();

  let mql = window.matchMedia("(max-width: 650px)");

  useEffect(() => {
        getSingleExamProductAPI(id)
      .then((res) => {
        console.log("ressingle", res);
        setSingleExamProduct(res.data);
        setCheckedInitial(res.data?.product_list)
      })
      .catch((e) => {
        if (
          e?.response?.data?.message ===
          "Invalid or expired token. Please refresh your session."
        ) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      });
  }, [id]);
  // const checkedinitial = singleExamProduct?.product_list || []; 

  const Heading = [
    {
      id: 1,
      headingsmall: singleExamProduct?.exam,
      headingmain: singleExamProduct?.exam,
      breadcrumb: singleExamProduct?.exam,
      search:'yes',
    },
  ];
  return (
    <>
      <PageHeading Heading={Heading} />
      <div className={!mql.matches && classes.pageheadingmain}>
        {checkedInitial?.length > 0 && <SingleProductPageMain singleExamProduct={singleExamProduct} checkedinitial={checkedInitial}/>}
      </div>
    </>
  );
};
export default ExamSingleProductPage;
