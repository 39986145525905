import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import MainImage from "../image/careersvector/careersmainpng.png";
import { ReactComponent as AchivementTag } from "../image/careersvector/achivementtagvector.svg";
import { ReactComponent as BulbVector } from "../image/careersvector/bulbvector.svg";
import { ReactComponent as CustomerSupportVector } from "../image/careersvector/customersupportvector.svg";
import { ReactComponent as TimerVector } from "../image/careersvector/timervector.svg";
import { ReactComponent as RecordedVideoVector } from "../image/careersvector/recordedvideosvector.svg";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    marginBottom:"12rem",
    background: '#fff',
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "14rem",
    },
  },
  container: {
    paddingTop: "2.5rem",
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiListItemAvatar-root": {
        marginRight: "2rem",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          justifyContent: "center",
          margin: "1rem",
      },
    },
    "& .MuiAvatar-colorDefault": {
        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.1)",
        padding: "2rem,",
        width: "65px",
        height: "65px"
    },
    "& .MuiTypography-body1": {
        fontFamily: "Playfair Display",
        color: "#252525",
        fontSize: "1.2rem",
        fontWeight: "600",
    },
    "& .MuiTypography-body2": {
        fontFamily: "Jost",
        fontSize: "1rem",
        fontWeight: "500",
    },

  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
  },
  },
  mainimage: {
    "& img": {
      width: "536px",
      [theme.breakpoints.down("xs")]: {
        width: "50%",
    },
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex", 
      justifyContent: "center",
    },
  },
  listcontainer: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      margin: "auto",
  },
    "& .MuiListItem-root": {
      [theme.breakpoints.down("xs")]: {
        display: "block",
        margin: "auto",
      },
    },
    "& .MuiListItemText-multiline": {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        textAlign: "center",
    },
    },
  },
  achivementavtar: {
    background: "linear-gradient(135deg, #7A66FF 0%, rgba(122, 102, 255, 0.5) 81.82%),linear-gradient(0deg, #A79BF8, #A79BF8)",
  },
  bulbvector: {
    background: "linear-gradient(135deg, #F57064 0%, rgba(245, 112, 100, 0.5) 100%),linear-gradient(0deg, #FEA79F, #FEA79F)",
  },
  customersupportvector: {
    background: "linear-gradient(135deg, #45C8C2 0%, rgba(69, 200, 194, 0.5) 92.57%),linear-gradient(0deg, #7FDBD7, #7FDBD7)",
  },
  timervector: {
    background: "linear-gradient(135deg, #FFA41F 0%, rgba(255, 164, 31, 0.5) 100%),linear-gradient(0deg, #FFD08B, #FFD08B)",
  },
  recordedvideovector: {
    background: "linear-gradient(0deg, #F480D4, #F480D4),linear-gradient(135deg, #F480D4 0%, rgba(244, 128, 212, 0.5) 100%)",
  }
}));

function WhyYouChooseSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Your Success, Our Commitment: Choose Us with Confidence.",
      headingmain: "Why Choose us ?",
    },
  ];
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.content}>
          <div className={classes.mainimage}>
            <img src={MainImage} alt="img"/>
          </div>
          <div className={classes.listcontainer}>
            <List className={classes.root}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.achivementavtar}>
                    <AchivementTag />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Highly Experienced" secondary="Choose our highly experienced team for exceptional expertise & proven results." />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.bulbvector}>
                    <BulbVector />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Quiz & Course Materials" secondary="Access our comprehensive quiz and course materials for a tailored learning experience." />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.customersupportvector}>
                    <CustomerSupportVector />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Dedicated Support" secondary="Count on our dedicated support team for prompt and personalized assistance, anytime." />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.timervector}>
                    <TimerVector />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Lifetime Access" secondary="Enjoy lifetime access to our resources, ensuring continuous growth and learning." />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.recordedvideovector}>
                    <RecordedVideoVector />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Recorded Videos" secondary="Benefit from our library of recorded videos for convenient on-demand learning." />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyYouChooseSection;
