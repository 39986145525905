import React from "react";import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ExclusiveBooksSection from "../../Home/exclusivebooks/exclusivebooksection";
// import {ReactComponent as MessageIcon} from "../../image/singlepagedescriptionvector/msgicon.svg";
// import {ReactComponent as NotepadeIcon} from "../../image/singlepagedescriptionvector/notepadicon.svg";
import MessageIcon from "../../image/singlepagedescriptionvector/msgicon.png";
import NotepadeIcon from "../../image/singlepagedescriptionvector/notepadicon.png";
import UnlockIcon from "../../image/singlepagedescriptionvector/unlockvector.png";
import LockIcon from "../../image/singlepagedescriptionvector/lockvector.png";
import PdfIcon from "../../image/singlepagedescriptionvector/pdfvector.png";
import {
  Button,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3rem 2rem",
  },
    
  table: {
    "& .MuiTypography-h3": {
      fontSize: "1.375rem",
      fontWeight: "500",
      fontFamily: "Playfair Display",
    },
    "& .MuiTableHead-root": {
      background: "#FFFBF1",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    },
    "& .MuiTableCell-head": {
      fontSize: "1rem",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
      borderBottom: "0",
      padding: "8px 20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem !important",
      },
    },
    "& .MuiTableCell-root": {
      padding: "16px 20px",
      fontSize: "1rem",
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        padding: "16px",
        fontSize: "0.5rem !important",
      },
    },

    "& .MuiTooltip-tooltip": {
      backgroundColor: "rgba(255,255,0,0.4)",
    },
  },
  tableheadicon: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "60px",
    },
  },
  tablerowcontainer: {
    display: "flex",
    alignItems: "center",
    width: "78%",
  },
  unlockedrow: {
    display: "flex",
    alignItems: "center",
    width: "94%",
    "& .MuiTypography-h6": {
      color: "#154FE5 !important",
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Jost",
    },
  },
  lockedrow: {
    "& .MuiTypography-h6": {
      color: "#B1B1B1 !important",
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Jost",
      "& .MuiTooltip-tooltip": {
        backgroundColor: "rgba(255,255,0,0.4)",
      },
    },
  },
  tablerowicon: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "0.8rem",
      // width: "50px"
    },
  },
  borderRight: {
    borderRight: "1px solid #E0E0E0",
  },
}));

export default function NotesInSiteSection() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.borderRight}>
                    <div className={classes.tableheadicon}>
                      <img src={MessageIcon} alt="img" />
                      <Typography variant="h3">Topics</Typography>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.tableheadicon}>
                      <img src={NotepadeIcon} alt="img" />
                      <Typography variant="h3">Study Notes</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    className={classes.borderRight}
                  >
                    <Typography variant="h3">Reasoning Analogy</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.unlockedrow}>
                      <div className={classes.tablerowicon}>
                        <img src={UnlockIcon} alt="img" />
                        <Typography variant="h6">
                          Explanation, Instructions and Questions on Reasoning
                          Analogy
                        </Typography>
                      </div>
                      <IconButton>
                        <img src={PdfIcon} alt="img" />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    className={classes.borderRight}
                  >
                    <Typography variant="h3">Mirror and Water Image</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.lockedrow}>
                      <Tooltip title="Add" arrow placement="top">
                        <div className={classes.tablerowicon}>
                          <img src={LockIcon} alt="img" />
                          <Typography variant="h6">
                            Question and Explanations on Mirror and Water Image
                          </Typography>
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      
  );
}
