import React, { useContext, memo } from "react";
import {
  Button,
  CardActions,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as AttemptIcon } from "../../image/latestseriesvector/attempticon.svg";
import { ReactComponent as QuestionsIcon } from "../../image/latestseriesvector/questionsicon.svg";
import { ReactComponent as TimerIcon } from "../../image/latestseriesvector/timericon.svg";
import appContext from "../../../context/AppContext";
import popupContext from "../../../context/PopupContext/PopupContext";

const useStyles = makeStyles((theme) => ({
  card_container: {
    padding: "1.5rem 1rem",
    boxShadow: "0px 0px 20px rgba(58, 58, 58, 0.10)",
    borderRadius: " 6px ",
    margin: "20px 0px",
    display: "flex",
    justifyContent: "space-between",
    background: "#FFF",
    "& .MuiTypography-h5": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.73rem",
      fontWeight: "600",
      textTransform: "uppercase",
      color: "#000",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.5rem",
      fontWeight: "500",
      color: "#727272",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0.6rem",
      padding: "0.5rem",
    },
  },
  "& .MuiTypography-caption": {
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.5rem",
    fontWeight: "500",
    color: "#727272",
  },
  examlogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "120px",
    padding: "0.62rem 0.4rem 0.58rem 0.4rem",
    borderRadius: "70px",
    background: "#FFEEC6",
    boxShadow: "0px 2px 35px rgba(255, 232, 192, 0.80)",
    "& img": {
      borderRadius: "50%",
    },
  },
  examcontent: {
    width: "62%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  bgorange: {
    marginTop: "0rem",
    padding: "0.2rem",
    borderRadius: "6px",
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
    "& .MuiCardActions-root": {
      borderRadius: 6,
      padding: "0.2rem",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.7rem !important",
      alignItems: "flex-start",
    },
  },
  divider: {
    margin: "0.5rem 0",
    background: "#FFF7E4",
    height: "0.08rem",
  },
  examinfo: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
  },
  infoinner: {
    display: "flex",
    alignItems: "stretch",
  },
  card: {
    background: "#fff",
    borderRadius: "12px",
  },
  heading: {
    fontFamily: "Jost",
    margin: "1rem 0",
    fontWeight: 600,
  },
  subHeading: {
    fontFamily: "Jost",
    marginBottom: "1.5rem",
    fontWeight: 400,
    color: "#727272",
    padding: "0px 1rem",
  },
  button: {
    background: "#FFD9B5",
    padding: "1rem",
    borderRadius: 6,
    fontSize: 16,
    fontFamily: "Jost",
    display: "flex",
    justifyContent: "center",
  },
  buttonsmall: {
    background: "#FFD9B5",
    padding: ".5rem",
    borderRadius: 6,
    fontSize: 16,
    fontFamily: "Jost",
    display: "flex",
    justifyContent: "center",
  },
  lightbtn: {
    background: "#FFFAEF",
    padding: ".3rem",
    borderRadius: 3,
    fontSize: 16,
    fontFamily: "Jost",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
}));
const QuizCardMobile = memo((props) => {
  const { data } = props;
  const classes = useStyles();
  const { handleloginOpenclose } = useContext(popupContext);
  const { isLoggedIn, token } = useContext(appContext);

  return (
    <div className={classes.card_container}>
      <div className={classes.examlogo}>
        <img
          src={data?.image_url || require("../../image/notFoundData.png")}
          alt="cardimg"
          width="100px"
          height="100px"
        />
      </div>
      <div className={classes.examcontent}>
        <div className={classes.examtitle}>
          <Typography variant="h5">{data?.quiz_name}</Typography>
          <Divider className={classes.divider} />
        </div>

        <div>
          <div className={classes.examinfo}>
            <div className={classes.infoinner}>
              <AttemptIcon style={{ marginRight: 5 }} />
              <Typography variant="caption">
                {data?.attempts} attempts
              </Typography>
            </div>
            <div className={classes.infoinner}>
              <QuestionsIcon style={{ marginRight: 5 }} />
              <Typography variant="caption">
                {data?.no_of_question} Ques
              </Typography>
            </div>
            <div className={classes.infoinner}>
              <TimerIcon style={{ marginRight: 5 }} />
              <Typography variant="caption">{data?.duration} min</Typography>
            </div>
          </div>
          <CardActions className={classes.bgorange}>
            <Button
              onClick={() => {
                if (isLoggedIn && token) {
                  window.open(`/quizzes/${data.id}`, "_blank");
                } else {
                  handleloginOpenclose();
                }
              }}
              fullWidth
            >
              start Quizz
            </Button>
          </CardActions>
        </div>
      </div>
    </div>
  );
});

export default QuizCardMobile;
