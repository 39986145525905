import MentorImage1 from "../image/mentorvector/mentor1.png";
import MentorImage2 from "../image/mentorvector/mentor2.png";
import MentorImage3 from "../image/mentorvector/mentor3.png";

const MentorSliderData = [
    {
      id: 1,
      iconName: MentorImage1,
      heading: "Andrena Tate",
      subheading: "Economics Faculty",
      experienceText: "15 YEARS EXP.",
    },
    {
      id: 2,
      iconName: MentorImage2,
      heading: "Jason Jhonson",
      subheading: "Physics Faculty",
      experienceText: "15 YEARS EXP.",
    },
    {
      id: 3,
      iconName: MentorImage3,
      heading: "Samuel",
      subheading: "Chemistry Faculty",
      experienceText: "15 YEARS EXP.",
    },
    {
      id: 3,
      iconName: MentorImage3,
      heading: "Samuel",
      subheading: "Chemistry Faculty",
      experienceText: "15 YEARS EXP.",
    },
    {
      id: 3,
      iconName: MentorImage3,
      heading: "Samuel",
      subheading: "Chemistry Faculty",
      experienceText: "15 YEARS EXP.",
    },
    {
      id: 3,
      iconName: MentorImage3,
      heading: "Samuel",
      subheading: "Chemistry Faculty",
      experienceText: "15 YEARS EXP.",
    },
  ];
export default MentorSliderData;