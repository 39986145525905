import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({


  summary:{
    width: "75%",
    padding:'1rem',
    borderRadius: "10px",
    border: "1px solid #B8B8B8",
    marginTop:'1rem',
    marginLeft:'2rem',
    "& .MuiTypography-h5": {
      color: "#707070",
      fontFamily: "Jost",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 500,
      marginTop: ".5rem",
      display:'flex',
     
      
    },
    "& .MuiTypography-h6": {
      color: "#737373",
      fontFamily: "Jost",
      fontSize: "1.05rem",
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: " 0.5625rem",
      
      
    },
    "& .MuiTypography-h3": {
      color: "#707070",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: " 0.4625rem",
      
      
    },
    "& .MuiTypography-h4": {
      color: "#ACACAC",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: " 0.4625rem",
      
      
    },
    "& .MuiTypography-h2": {
      color: " #FF3939",
      fontFamily: "Jost",
      fontSize: "0.7625rem",
      fontStyle: "normal",
      fontWeight: 500,
      marginTop: " 0.4625rem",
      
      
    },
    "& .MuiTypography-h1": {
      color: " #707070",
      fontFamily: "Jost",
      fontSize: "0.780rem",
      fontStyle: "normal",
      fontWeight: 500,
      marginTop: " 0.4625rem",
      
      
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0 !important",
    },
  },
  items:{
    display:'flex',
    flexwrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'baseline',
},
shoppingcost:{
  display:'flex',
  flexwrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'baseline',
},
totalamount:{
  width: '100%',
  height: '2.0625rem',
  display:'flex',
  flexwrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'baseline',
},
  
}));


function BillingDetails({orders}) {

    const classes = useStyles();

  return (
    <> 
<div className={classes.summary}>
<Typography variant="h5">Order Summary</Typography>
<div className={classes.items}>
  <Typography variant="h5">Item Cost</Typography>
  <Typography variant="h6">₹{orders?.sub_total_item_cost}</Typography>

</div>
<div className={classes.shoppingcost}>
  <Typography variant="h3">Discount</Typography>
  <Typography variant="h4">- ₹ {orders?.sub_total_discount}</Typography> 
</div>
<div className={classes.shoppingcost}>
  <Typography variant="h3">Shipping Cost</Typography>
  <Typography variant="h4">₹{orders?.shipping_cost}.00</Typography> 
</div>
<div className={classes.shoppingcost}>
  <Typography variant="h3">Delivery Cost</Typography>
  <Typography variant="h4">- ₹ {orders?.delivery_cost}</Typography> 
</div>

<div className={classes.shoppingcost}>
  <Typography variant="h3">Wallet Amount</Typography>
  <Typography variant="h4">- ₹ {orders?.wallet_amount}</Typography> 
</div>
<div className={classes.shoppingcost}>
  <Typography variant="h3">Cashback Amount</Typography>
  <Typography variant="h4">- ₹{orders?.cashback}</Typography> 
</div>
<div className={classes.items}>
  <Typography variant="h5">Total Amount</Typography>
  <Typography variant="h6">₹{orders?.total}</Typography> 
</div>
<div className={classes.shoppingcost}>
  <Typography variant="h3">GST</Typography>
  <Typography variant="h4">₹{Math.floor((orders?.gst))} </Typography> 
</div>

<div className={classes.totalamount}>
  <Typography variant="h1"> Total payment by customer</Typography>
<Typography variant="h2">₹{orders?.grand_total_cost}</Typography>
</div>
</div>
    </>
  );
}

export default BillingDetails;