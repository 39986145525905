import React, { useState } from "react";
import ShoppingCartContentMobile from "../shoppingcart/ShoppingCartContentMobile";
import PageHeading from "../pageheading/pageheading";
import ShoppingCartContent from "../shoppingcart/ShoppingCartContent";
import Subcategory from "../practicecomponent/subcategory";

import appContext from "../../context/AppContext";
import { useContext } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import SubCategoryNotification from "./subCategoryNotification";
import JobNotification from "./jobNotification";
import DownloadApp from "./downloadApp";
import QuiklinksNotification from "./quiklinksNotification";
import CardsectionNotification from "./cardsectionNotification";
import HeaderNotification from "./headerNotification";
import ImportantDates from "./importantDates";
import ApplicationFee from "./applicationFee";
import AgeLimit from "./ageLimit";
import Qualification from "./qualification";
import VacancyDetails from "./vacancyDetails";
import ImportantLinks from "./importantLinks";

const useStyles = makeStyles((theme) => ({
  
}));

const AdmitCardInner = () => {
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 650px)");

  return (
    <>
      <CardsectionNotification />
      <ImportantDates />
      <ApplicationFee />
      <AgeLimit />
      <Qualification />
      <VacancyDetails />
      <ImportantLinks />
    </>
  );
};
export default AdmitCardInner;
