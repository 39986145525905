import { useContext, useEffect, useReducer } from "react";
import FetchProfileReducer from "./FetchProfileReducer";
import FetchProfileContext from "./FetchProfileContext";
import {
  FETCH_PROFILE_DATA,
  FETCH_PROFILE_ERROR_MESSAGE,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_PICTURE,
} from "./FetchProfileType";
import { UpdateProfile, UpdateProfilePicture, getProfileData } from "../../service/services";
import AppContext from "../AppContext";
import { toast } from "react-toastify";

const FetchProfileState = (props) => {
  const userContext = useContext(AppContext);
  let initialState = {
    user: {},
    hasError: false,
    errorMessage: "",
  };
  const [state, dispatch] = useReducer(FetchProfileReducer, initialState);

  const fetchProfileDataAPI = (token) => {
    getProfileData(token)
      .then((res) => {
        const data = res.data;

        if (data.success && data.user) {
          dispatch({ type: FETCH_PROFILE_DATA, payload: data?.user });
        }
      })
      .catch((e) =>
        dispatch({
          type: FETCH_PROFILE_ERROR_MESSAGE,
          payload: {
            hasError: !e?.response?.data?.success,
            errorMessage:
              e?.response?.data?.message?.error || e?.response?.data?.message,
          },
        })
      );
  };

  useEffect(() => {
    if (userContext.isLoggedIn) {
      fetchProfileDataAPI(userContext.token);
    }
  }, [userContext.isLoggedIn]);

  // //get image file for initial update
  // const getProfileImageData = (data) => {
  //   setImageData(data);
  // }



  const updateProfilePictureAPI = (formData, token) => {
    UpdateProfilePicture(formData, token)
      .then((res) => {
        return dispatch({
          type: UPDATE_PROFILE_PICTURE,
          payload: res?.image_url,
        });
      })
      .catch((e) =>
        dispatch({
          type: UPDATE_PROFILE_PICTURE,
          payload: {
            hasError: !e?.response?.data?.success,
            errorMessage:
              e?.response?.data?.message?.error || e?.response?.data?.message,
          },
        })
      );
  };


  const updateFormDataAPI = (formattedUser, token) => {
    UpdateProfile(formattedUser, token)
      .then((res) => {
        console.log("API Response:", res);
      toast.success("Profile Updated Successfully");
        return dispatch({
          type: UPDATE_PROFILE_DATA,
          payload: res.user,
        });
        
      })
      .catch((e) =>{
        console.error("An Error Occurred:", e?.res);
      toast.error("Failed to Updated Profile");
        dispatch({
          type: UPDATE_PROFILE_DATA,
          payload: {
            hasError: !e?.response?.data?.success,
            errorMessage:
              e?.response?.data?.message?.error || e?.response?.data?.message,
          },
        });
        
      }
      );
  };
  //  useEffect(()=>{
  //   updateProfilePictureAPI (formData, token)
  //   },[userContext.isLoggedIn])

  return (
    <FetchProfileContext.Provider
      value={{
        profileState: state,
        fetchProfileDataAPI,
        updateProfilePictureAPI,
        updateFormDataAPI
      }}
    >
      {props.children}
    </FetchProfileContext.Provider>
  );
};
export default FetchProfileState;
