import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

const useStyles = makeStyles((theme) => ({
  cardroot: {
    marginTop: "1.5rem",

    "& .MuiCard-root .MuiPaper-elevation1": {
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.10)",
    },
    "& .MuiCardContent-root": {
      padding: "0 1rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  cardcontainer: {
    width: "63%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1rem 0",
    "& .MuiTypography-h6": {
      color: "#262626",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontWeight: "400",
      textTransform: "capitalize",
    },
  },
  heading: {
    color: "#262626",
    fontFamily: "Jost",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "uppercase",
    textAlign: "left",
  },
  buttoncontainer: {
    width: "39%",
    "& .MuiTypography-h5": {
      borderRadius: "0.375rem",
      border: "1px solid #00A019",
      background: " var(--white-100, #FFF)",
      color: "#00A019",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.625rem",
      fontWeight: 600,
      textTransform: "capitalize",
      padding: "0.4rem",
    },
  },
  button: {
    color: "#000",
    fontFamily: "Jost",
    fontSize: "0.55rem",
    fontWeight: 400,
    width: "20%",
    borderRadius: "var(--4, 0.25rem)",
    background: "linear-gradient(90deg, #FFF4EA 0%, #FFEEC3 100%)",
    alignItems: "center",
    padding: "0.2rem 0.4rem",
    textAlign: "center",
  },
  startbtn: {
    "&.MuiButton-root": {
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.625rem",
    },
    "&.MuiIconButton-root": {
      background: "#243B55",
      color: "#FFF",
      padding: "0.3rem",
      marginLeft: "0.5rem",
      borderRadius: "0.375rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      color: "#000",
      textTransform: "capitalize",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.625rem",
      background: "#EEEEEE",
      borderRadius: "0.375rem",
      padding: "0.3rem 1.2rem",
    },
  },
}));

const CardForStudyTable = ({ data, handleOpenclose }) => {
  const classes = useStyles();

  console.log("data>>>", data);

  return (
    <>
      <Card className={classes.cardroot}>
        <CardContent>
          <div className={classes.cardcontainer}>
            <div className={classes.heading}>{data.name}</div>
          </div>
          <div className={classes.buttoncontainer}>
            {data.status === "completed" ? (
              <div>
                <Typography variant="h5" component="span">
                  Completed
                </Typography>
                <IconButton
                  className={classes.startbtn}
                  onClick={() => {
                    handleOpenclose(
                      data?.content_path,
                      data?.id,
                      data?.lastPage
                    );
                  }}
                >
                  <Tooltip id="button-info" title="Start Over">
                    <ReplayIcon style={{ fontSize: "large" }} />
                  </Tooltip>
                </IconButton>
              </div>
            ) : data.status === "resume" ? (
              <Button
                className={classes.startbtn}
                onClick={() => {
                  handleOpenclose(data?.content_path, data?.id, data?.lastPage);
                }}
              >
                Resume
              </Button>
            ) : data?.file_format && data?.file_format !== "pdf" ? (
              <Button
                className={classes.startbtn}
                component="a"
                
              >
                Start
              </Button>
            ) : (
              <Button
                className={classes.startbtn}
                onClick={() => {
                  handleOpenclose(data?.content_path, data?.id, data?.lastPage);
                }}
              >
                Start
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default CardForStudyTable;
