import {
  Button,
  Divider,
  FormControl,
  InputAdornment,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { GrFormClose } from "react-icons/gr";
import { ImArrowLeft2 } from "react-icons/im";
import { ReactComponent as PhoneIcon } from "../image/loginsignupvector/phoneicon.svg";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../service/services";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "70%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: "0.88rem",
      fontFamily: "Jost",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.76rem !important",
        marginTop: "0rem",
        textAlign: "center",
      },
    },
    "& .MuiDivider-root": {
      width: "11%",
      background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
      height: "0.125rem",
      marginTop: "0.4rem",
      marginBottom: '1rem',
      [theme.breakpoints.down("xs")]: {
        width: "23%",
        marginBottom: "1rem",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiLink-root": {
        color: "#154FE5",
        fontSize: "0.9375rem",
        fontWeight: "400",
        textTransform: "capitalize",
        cursor: "pointer",
      },
    },
  },
  loginform: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  inputhead: {
    marginTop: '1rem',
  },
  paper: {
    position: "absolute",
    display: "flex",
    top: "90px",
    backgroundColor: "#FFFAEF",
    zIndex: "8",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    height: "calc(100vh - 150px)",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: "center",
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "100px",
      padding: "1rem",
    },
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  passwordheadcont: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1.5rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "300",
    },
  },
  whitecustombtn: {
    background: "#FFFFFF",
    color: "black",
    border: "1px solid #D6D6D6",
    borderRadius: "0.4rem",
    marginTop: "2rem",
    padding: "0.5rem 1rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
  },
  arrowicon: {
    width: "2rem",
    height: "0.8rem",
  },
  formfootertext: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    color: "#5E5E5E",
    fontWeight: "500",
    "& .MuiLink-root": {
      color: "#FF9213",
      cursor: "pointer",
    },
  },
  closebtncontainer: {
    position: "absolute",
    top: "80px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
}));

function ForgotPasswordModal(props) {
  const {
    handleloginOpenclose,
    handlesignupOpenclose,
    handleForgotpasswordOpenclose,
    success,
    setforgot,
    handleOtpOpenclose
  } = props;
  const classes = useStyles();

  const initialValues = {
    phone: "",
  }

  const { register, handleSubmit, formState: { errors }, formState, getValues } = useForm({ defaultValues: initialValues });

  const onSubmit = async (data) => {
    try {
      var res = await forgotPassword({
        user: data
      });
      toast('Otp Sent to your registered mobile number!', {
        position: "top-right",
        autoClose: 5000,
        type: "success"
      });
      handleOtpOpenclose()
      success(data?.phone);
      setforgot(true)
    } catch (err) {
      toast(err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        type: "error"
      });
    }
  }


  return (
    <div className={classes.container}>
      <div className={classes.closebtncontainer}>
        <Button
          className={classes.closebtn}
          onClick={handleForgotpasswordOpenclose}
        >
          <GrFormClose />
        </Button>
      </div>
      <div className={classes.paper}>
        <Typography variant="h3" gutterBottom>
          <span className={classes.orangetext}>Forgot</span> Your Password?
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Enter your mobile number Below to receive your password reset
          Instruction
        </Typography>
        <Divider />
        <div className={classes.loginform}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ m: 1, width: "25ch" }}>
              <Typography className={classes.inputhead} variant="h6" gutterBottom>
                Enter Mobile Number
              </Typography>
              <TextField {...register("phone", {
                required: true, pattern: {
                  value: /^\d{10}$/,
                  message: 'Invalid phone number (must be 10 digits)',
                },
              })}
                placeholder="9876543210"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors?.phone && errors.phone?.type === "pattern" && <p className='errorText ms-0 '>{errors.phone?.message}</p>}
              {errors?.phone && errors.phone?.type === "required" && <p className='errorText ms-0 '>  Mobile Number is required</p>}

              <Button type="submit" className={classes.bluecustombtn} fullWidth>
                Reset Password
              </Button>
              <Button className={classes.whitecustombtn} fullWidth>
                <Link
                  className={classes.whitebtnlik}
                  onClick={handleloginOpenclose}
                >
                  <ImArrowLeft2 className={classes.arrowicon} />
                  Back to Login
                </Link>
              </Button>
            </FormControl>
            <Typography
              className={classes.formfootertext}
              variant="caption"
              gutterBottom
            >
              Don’t Have An Account?{" "}
              <Link onClick={handlesignupOpenclose}>Sign Up</Link>
            </Typography>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ForgotPasswordModal;
