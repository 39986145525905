import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BookCard from "./BookCard";
import CardData from "./BookCardData";
import ErrorImage from "../image/dashboard/resellerrorpng.png";
import BookCardMobile from "./BookCardMobile";
import { getResellbookList } from "../../service/services";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
      background: "transparent",
      boxShadow: "none",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-gutterBottom": {
      fontFamily: "Playfair Display",
      fontSize: "1.625rem",
      fontWeight: "700",
      color: "#243B55",
      borderBottom: "1px solid #CACACA",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.25rem",
        color: "#303030",
        borderBottom: "0",
        marginBottom: "0",
      },
    },
    "& .MuiButton-root": {
      background: "#3F3F3F",
      color: "#FFF",
      fontFamily: "Jost",
      fontSize: "0.9375rem",
      fontWeight: "400",
      borderRadius: "6px",
      padding: "0.5rem 2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        color: "#303030",
        padding: "0.5rem 1rem",
        background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
        fontWeight: "500",
      },
    },
  },
  cardscontainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
}));

function MyBookList({ setOnViewClicked }) {
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 650px)");
  const [bookList, setBookList]=useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getResellbookList(token);
        setBookList(response?.data?.resell);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  }, []);
  
  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <Typography gutterBottom variant="h6">
          My Book List
        </Typography>
        <Button
          onClick={() => {
            setOnViewClicked("addbook");
          }}
        >
          + Add Books
        </Button>
      </div>
      {bookList.length === 0 ? (
        <div
          style={{ textAlign: "center", position: "relative", left: "-36px" }}
        >
          <img src={ErrorImage} alt="error" />
        </div>
      ) : (
        <div className={classes.cardscontainer}>
          {bookList.map((data, index) =>
            !mql.matches ? (
              <BookCard key={index} data={data} />
            ) : (
              <BookCardMobile key={index} Data={data} />
            )
          )}
        </div>
      )}
    </div>
  );
}

export default MyBookList;
