import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import MobileHeaderLink from "./mobileheaderlink";
import {
  AppBar,
  Backdrop,
  Divider,
  IconButton,
  Modal,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import parikshaDoLlogo from "../image/pariksha_Do_logo.svg";
import appContext from "../../context/AppContext";
import popupContext from "../../context/PopupContext/PopupContext";
import SignInModal from "../allpopups/signin";
import OtpModal from "../allpopups/otp";
import CreatePasswordModal from "../allpopups/createpassword";
import ForgotPasswordModal from "../allpopups/forgotpassword";
import SignUPModal from "../allpopups/signup";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%) !important",
    "& .MuiToolbar-root": {
      justifyContent: "space-between",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0,
    // display:'none',
    background:
      " linear-gradient(90deg, #141E30 0%, rgba(36, 59, 85, 0.60) 100%)",
    backdropFilter: "blur(20px)",
    "& .MuiDivider-root": {
      backgroundColor: "rgba(255, 255, 255, 0.60)",
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.875rem",
      textTransform: "uppercase",
      fontFamily: "'Jost', sans-serif",
    },
    "& .MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important",
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0 2rem",
      "& .MuiTypography-body1": {
        fontSize: "0.8125rem",
      },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px 0",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
  },
  [theme.breakpoints.down("xs")]: {
    display:'block',
  },
}));

export default function SwipeableTemporaryDrawer({ setDashBoardId }) {
  const classes = useStyles();
  const AppContext = useContext(appContext);
  const {
    drawerstate,
    toggleDrawerOpenClose,
    login,
    handleloginOpenclose,
    signup,
    handlesignupOpenclose,
    forgotpassword,
    setforgot,
    handleForgotpasswordOpenclose,
    handleOtpOpenclose,
    setMobileNumber,
    setOtpVerify,
    createpassword,
    handleCreatepasswordOpenclose,
    onetimepassword,
    otp,
    mobileNumber,
    otpVerify,
    forgot,
    setOneTimePassword,
  } = useContext(popupContext);

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar>
        <div className={classes.imageContainer}>
          <Link
            to="/"
            className={classes.logo}
            onClick={() => {
              AppContext.setCourseData({});
              AppContext.setTempSubcategory(null);
            }}
          >
            <img src={parikshaDoLlogo} alt="img" width="200px" />
          </Link>
        </div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={toggleDrawerOpenClose}
          className={clsx(drawerstate && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={login}
        onClose={handleloginOpenclose}
      >
        <SignInModal
          handleloginOpenclose={handleloginOpenclose}
          handlesignupOpenclose={handlesignupOpenclose}
          handleForgotpasswordOpenclose={handleForgotpasswordOpenclose}
          handleOtpOpenclose={handleOtpOpenclose}
          success={(num) => {
            setMobileNumber(num);
            setOtpVerify(false);
          }}
        />
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={signup}
        onClose={handlesignupOpenclose}
      >
        <SignUPModal
          handlesignupOpenclose={handlesignupOpenclose}
          handleOtpOpenclose={handleOtpOpenclose}
          handleloginOpenclose={handleloginOpenclose}
          success={(num) => {
            setMobileNumber(num);
            setOtpVerify(true);
          }}
        />
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.Loginsigninmodal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={forgotpassword}
        onClose={handleForgotpasswordOpenclose}
      >
        <ForgotPasswordModal
          handleloginOpenclose={handleloginOpenclose}
          handlesignupOpenclose={handlesignupOpenclose}
          handleOtpOpenclose={handleOtpOpenclose}
          handleForgotpasswordOpenclose={handleForgotpasswordOpenclose}
          success={(num) => {
            setMobileNumber(num);
            setOtpVerify(false);
          }}
          setforgot={() => setforgot(true)}
        />
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.Loginsigninmodal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={createpassword}
        onClose={handleCreatepasswordOpenclose}
      >
        <CreatePasswordModal
          onetimepassword={onetimepassword}
          mobileNumber={mobileNumber}
          handleCreatepasswordOpenclose={handleCreatepasswordOpenclose}
          forgot={forgot}
        />
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.Loginsigninmodal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={otp}
        onClose={handleOtpOpenclose}
      >
        <OtpModal
          mobileNumber={mobileNumber}
          handleOtpOpenclose={handleOtpOpenclose}
          handleCreatepasswordOpenclose={handleCreatepasswordOpenclose}
          otpverify={otpVerify}
          forget={forgot}
          success={(OTP, NUM) => {
            setOneTimePassword(OTP);
            setMobileNumber(NUM);
          }}
        />
      </Modal>
      <SwipeableDrawer
        anchor="right"
        open={drawerstate}
        onClose={toggleDrawerOpenClose}
        onOpen={toggleDrawerOpenClose}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <MobileHeaderLink
          anchor="right"
          toggleDrawerOpenClose={toggleDrawerOpenClose}
          setDashBoardId={setDashBoardId}
        />
        {/* {list('right')} */}
      </SwipeableDrawer>
    </AppBar>
  );
}
