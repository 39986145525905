import React, { useEffect, useState } from "react";
import ShoppingCartContentMobile from "../shoppingcart/ShoppingCartContentMobile";
import PageHeading from "../pageheading/pageheading";
import ShoppingCartContent from "../shoppingcart/ShoppingCartContent";
import Subcategory from "../practicecomponent/subcategory";

import appContext from "../../context/AppContext";
import { useContext } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import SubCategoryNotification from "./subCategoryNotification";
import JobNotification from "./jobNotification";
import DownloadApp from "./downloadApp";
import QuiklinksNotification from "./quiklinksNotification";
import CardsectionNotification from "./cardsectionNotification";
import HeaderNotification from "./headerNotification";
import ImportantDates from "./importantDates";
import ApplicationFee from "./applicationFee";
import AgeLimit from "./ageLimit";
import Qualification from "./qualification";
import VacancyDetails from "./vacancyDetails";
import ImportantLinks from "./importantLinks";
import PropTypes from "prop-types";
import AdmitCardInner from "./AdmitCardInner";
import AdmitCardMain from "./AdmitCardMain";
import {
  getAllNotifications,
  getJobNotification,
  getJobNotificationCategory,
} from "../../service/services";
import { useParams } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageheadingmain: {
    width: "100%",
    marginBottom: "15rem",
    marginTop: "2rem",

    [theme.breakpoints.down("sm")]: {
      position: "unset",
      padding: 0,
    },
  },
  pageheadingmaininner: {
    margin: "0 auto",
    paddingBottom: "1.5rem",
    paddingTop: "1rem",
    // backgroundImage: `url(${PageHeadingBg})`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem 1.5rem 1rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1.5625rem",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 6,
      },
    },
    "& .MuiBreadcrumbs-ol": {
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorInherit": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "#FF8F12",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
    },
  },
  pageheading: {
    padding: "1rem 0 2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
    },
  },
  pageheadhr: {
    margin: "0",
    width: "6rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  quizzcontentcontainer: {
    maxWidth: "1250px",
    margin: "0 auto",
    marginTop: "4rem",
    marginBottom: "15rem",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiBox-root": {
      padding: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem",
    },
  },
  heading: {
    fontSize: "3.8rem",
    fontFamily: "'Playfair Display', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      fontFamily: "'Playfair Display', serif !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  w24: {
    width: "24%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  w74: {
    width: "74%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Notification = () => {
  const classes = useStyles();
  const param = useParams();

  
  const [value, setValue] = useState(param.a);
  useEffect(() => {
    setValue(param.a);
  }, [param.a]);
  

  const [innerClicked, setInnerClicked] = useState("");

  const getInnerClicked = (innerValue) => {
    setInnerClicked(innerValue);
    setValue(innerValue);
  };

  const [notification1, setNotification1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllNotifications();
        setNotification1(response.data.notify);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchData();
  }, [value]);
  const [notData, setNotData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getJobNotificationCategory();
        setNotData(response.data.notifications);
        // setNotData(response.data);

        console.log(response.data);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    console.log("Updating URL parameter:", newValue);
    navigate(`/your-route/${newValue}`);
    setValue(newValue);
    setInnerClicked(newValue.toString());
  };
  const [name, setName] = useState("LatestNotification");
  console.log("Current URL parameter:", param.a);
  console.log("Current value state:", value);
  let [name1, setName1] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getJobNotification();
        const category = response.data.notify.find((item) => item.id == value);
        if (category) {
          console.log(category.category_name);
          setName1(category.category_name);
        } else {
          console.log("Category not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [name1,value,param.a]);

  const Heading = [
    {
      id: 1,
      headingsmall: `All ${name1} here`,
      headingmain: `All ${name1} here`,
      breadcrumb: `All ${name1}`,
    },
  ];

  return (
    <>
      <PageHeading Heading={Heading} />
      <SubCategoryNotification
        Heading={Heading}
        value={parseInt(param.a, 10) || value}
        setValue={setValue}
        a11yProps={a11yProps}
      />

      <div className={classes.quizzcontentcontainer}>
        <div className={classes.w24}>
          <JobNotification
            Notification={notData}
            value={parseInt(param.a, 10) || value}
            setValue={setValue}
            a11yProps={a11yProps}
            name={name}
          />
          <DownloadApp />
        </div>
        <div className={classes.w74}>
          {notification1
            .filter((data) => data.id.toString() === param.a)
            .map((data, index) => {
              return (
                <TabPanel
                  onChange={handleChange}
                  {...a11yProps(value)}
                  value={value}
                  index={value}
                  key={index}
                >
                  <AdmitCardMain
                    value={param.a}
                    setValue={setValue}
                    data={data}
                    getInnerClicked={getInnerClicked}
                    index={value}
                  />
                </TabPanel>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default Notification;
