import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import YourImage from "../image/biometricvector/features.png"; // Replace with your image path

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    height: "50rem",
    marginTop: "1rem",
    padding: "4rem 0",
    backgroundColor: "rgba(255, 249, 236, 1)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-3.5rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    position: "relative",
  },
  onlineeducationmain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4rem",
  },
  mainHeading: {
    marginTop: "2rem",
    width: "100%",
    textAlign: "center",
    zIndex: 1,
    position: "relative",
  },
  backgroundImage: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: "215%",
    left: "50%",
    width: "75%",
    transform: "translate(-50%, -50%)",
    zIndex: 0,
  },
}));

function FeatureSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Your Trustworthy Biometric Solution for the Future.",
      headingmain: "Impressive Features",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <img
          src={YourImage}
          alt="Background"
          className={classes.backgroundImage}
        />
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <div className={classes.onlineeducationmain}></div>
      </div>
    </div>
  );
}

export default FeatureSection;
