import { makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import React, { useState } from "react";
import ExclusiveBookCard from "../../../Home/exclusivebooks/exclusivebookcard";
const useStyles = makeStyles((theme) => ({
  ExclusiveBookCard: {
    marginTop: "1rem",
    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },

    "& .slick-prev:before, .slick-next:before": {
      fontSize: "55px",
      borderRadius: "40px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      bottom: 0,
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0.5rem",
    },

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
}));
function RelatedBooksSection({ bookData }) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const classes = useStyles();
  return (
    <div className={classes.ExclusiveBookCard}>
      <Slider {...settings}>
        {bookData.map((data, index) => (
          <ExclusiveBookCard data={data} />
        ))}
      </Slider>
    </div>
  );
}
export default RelatedBooksSection;
