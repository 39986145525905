import { Avatar, Typography, makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import React from "react";
import Card from "./studentcarddata";
import { ReactComponent as StringIcon } from "../../image/aboutusvector/stringicon.svg";
import { useEffect } from "react";
import { gettestimonialData } from "../../../service/services";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  StudentTestimonialCardslider: {
    marginTop: "1rem",
    marginBottom: "1rem",

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
  StudentTestimonialCardmainWhite: {
    background: "#FFFFFF",
    width: "95% !important",
    padding: "1rem",
    border: "1px solid #EEE",
    borderRadius: "6px",
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#676767",
      fontSize: "1rem",
      fontWeight: "400",
      marginBottom: "0",
    },
  },Description :{
    maxwidth: "100%",/* or specify a specific width */
    overflow: "hidden",
    whitespace: "normal",
    paddingLeft:"5px"
  },
  
  StudentTestimonialCardmainTrans: {
    background: "#FFF9EC",
    width: "95% !important",
    padding: "1rem",
    border: "1px solid #eee",
    borderRadius: "6px",
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#676767",
      fontSize: "1rem",
      fontWeight: "400",
      marginBottom: "0",
    },
  },
  StudentTestimonialavtarname: {
    display: "flex",
    marginTop: "1rem",
    alignItems: "center",
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#223852",
      fontSize: "1.125rem",
      fontWeight: "600",
      marginBottom: "0",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      color: "#676767",
      fontSize: "1rem",
      fontWeight: "600",
      marginBottom: "0",
    },
  },
  StudentTestimonialname: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
  },
  StringIconvector: {
    marginBottom: "1rem",
  },
}));
function StudentTestimonialCard() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const classes = useStyles();
  const [testimonial, setTestimonial] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await gettestimonialData();
        console.log("API Response:", response.data.testimonials);
        setTestimonial(response.data.testimonials);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <>
      {/* <div className={classes.StudentTestimonialCardslider}>
        <Slider {...settings}>
          {testimonial.map((data, index) => (
            <div
              key={index}
              className={
                index % 2 === 0
                  ? classes.StudentTestimonialCardmainWhite
                  : classes.StudentTestimonialCardmainTrans
              }
              style={{ display: 'grid', gridTemplateRows: 'auto auto auto' }}
            >
              <StringIcon className={classes.StringIconvector} />
              {/* <Typography variant="body2" gutterBottom>
                {data.description}
              </Typography> */}
              {/* <Typography
                variant="body2"
                gutterBottom
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {data.description}
              </Typography>

              <div className={classes.StudentTestimonialavtarname}>
                <Avatar
                  alt={data.full_name}
                  src={data.image_url}
                  className={classes.large}
                />
                <div className={classes.StudentTestimonialname}>
                  <Typography variant="h5" gutterBottom>
                    {data.full_name}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {data.state}
                  </Typography>
                </div> */}
              {/* </div>
            </div>
          ))}
        </Slider>
      </div> */} 
      <div className={classes.StudentTestimonialCardslider}>
  <Slider {...settings}>
    {testimonial.map((data, index) => (
      <div
        key={index}
        className={
          index % 2 === 0
            ? classes.StudentTestimonialCardmainWhite
            : classes.StudentTestimonialCardmainTrans
        }
      >
        <StringIcon className={classes.StringIconvector} />
        <Typography variant="body2" gutterBottom className={classes.Description}>
          {data.description}
        </Typography>
        <div className={classes.StudentTestimonialavtarname}>
          <Avatar
            alt={data.full_name}
            src={data.image_url}
            className={classes.large}
          />
          <div className={classes.StudentTestimonialname}>
            <Typography variant="h5" gutterBottom>
              {data.full_name}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {data.state}
            </Typography>
          </div>
        </div>
      </div>
    ))}
  </Slider>
</div>

    </>
  );
}
export default StudentTestimonialCard;
