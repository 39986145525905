import {
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import FooterImage from "../image/footerimages/footerimage.png";
import parikshaDoLlogo from "../image/pariksha_Do_logo.svg";
import AppleStore from "../image/footerimages/Apple_Store.svg";
import GooglePlay from "../image/footerimages/Google_Play.svg";
import instagram from "../image/footerimages/instagram.png";
import Youtube from "../image/footerimages/Youtube.png";
import linkedin from "../image/footerimages/linkedin.png";
import facebook from "../image/footerimages/facebook.png";
import FooterListSection from "./footerlistsection";
import FooterListSectionMobile from "./footerlistsectionmobile";
import FooterCard from "../footercard/footercardmain";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    fontFamily: "'Playfair Display', serif",
    "& .MuiListItemIcon-root": {
      minWidth: "20px !important",
    },
    "& .MuiListItem-gutters": {
      padding: "0.3rem 0",
    },
  },
  containerfluid: {
    backgroundImage: `url(${FooterImage})`,
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding: "0",
  },
  footerMain: {
    color: "#fff",
    paddingTop: "150px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingBottom: "0",
    },
  },
  footerMaininner: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      "& h2": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  },
  imageContainer: {
    width: "100%",
    marginBottom: "45px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  appBtnContainer: {
    marginBottom: "45px",
    width: "100%",
    display: "flex",
  },
  footeraddressContainer: {
    width: "85%",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  appBtn: {
    width: "50%",
    background: "none",
    borderWidth: "0px",
    opacity: "0.8",
    cursor: "pointer",
    "&:hover": {
      opacity: "2",
    },
  },
  footeraddress: {
    color: "#d3d3d3",
    letterSpacing: "2px",
  },
  footerLogo: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  link: {
    color: "white",
  },
  copyrightsection: {
    textAlign: "end",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center  ",
      paddingBottom: "2rem",
    },
  },
  footerHrline: {
    height: "1px",
    backgroundColor: "#9C9C9C",
    border: "none",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  footerheadhr: {
    margin: "0",
    marginTop: "10px",
    width: "38px",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "2px",
  },

  footerlinkcontainer: {
    display: "flex",
    cursor: "pointer",
    "& :hover": {
      color: "#FFC736",
      textDecoration: "none",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  footerlink: {
    color: "white",
    display: "flex",
    textDecoration: "none",
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
    },
  },
  socialiconsMain: {
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiDivider-root": {
      backgroundColor: "#fff",
      margin: "0 30px",
      border: "1px solid white",
    },
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  socialiconsmainmobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    "& .MuiListItem-root": {
      textAlign: "center",
    },
    "& h3": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  footergetappsection: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  footeraddresssection: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  socialicon: {
    width: "40px",
    height: "40px",
    background: "#ffffff",
    margin: "0 10px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& :hover": {
      // background: "#FFC736",
    },
  },
  socialiconsmaininner: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  socialiconssection: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& :hover": {
      background: "#FFC736",
    },
  },
  socialiconsmobile: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    // "& :hover": {
    //   background: "#FFC736",
    // },
  },
  copyrighthr: {
    width: "70%",
    border: "0",
    height: "2px",
    margin: "0 auto",
    marginTop: "1rem",
    marginBottom: "2rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
function Footer() {
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      <div className={classes.footer}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <div className={classes.footerMain}>
              {!location.pathname?.includes("/drmvideo") && !location.pathname?.includes("/dashboard") && <FooterCard />}

              <div className={classes.footerMaininner}>
                <div className={classes.footerLogo}>
                  <div className={classes.imageContainer}>
                    <img src={parikshaDoLlogo} alt="img" width="100%" />
                  </div>
                  <div className={classes.footergetappsection}>
                    <h2>
                      Get An App :- <hr className={classes.footerheadhr} />
                    </h2>
                    <div className={classes.appBtnContainer}>
                      <button className={classes.appBtn}>
                        <img src={AppleStore} alt="img" width="100%" />
                      </button>
                      <button className={classes.appBtn}>
                        <img src={GooglePlay} alt="img" width="100%" />
                      </button>
                    </div>
                  </div>
                  <div className={classes.footeraddresssection}>
                    <h2>
                      Head Office :- <hr className={classes.footerheadhr} />
                    </h2>
                    <div className={classes.footeraddressContainer}>
                      <p className={classes.footeraddress}>
                        303, Krishna Tower, 2/1 New Palasia, Infront of Curewell
                        Hospital, Janjeerwala Square Indore 452001
                      </p>
                    </div>
                  </div>
                </div>
                <FooterListSection />
                <FooterListSectionMobile />
              </div>
              <div className={classes.socialiconsmainmobile}>
                <div className={classes.socialiconsmaininner}>
                  <div>
                    <h3>
                      FOLLOW US :-
                      <hr className={classes.footerheadhr} />
                    </h3>
                  </div>
                  <div className={classes.socialiconsmobile}>
                    <Link
                      to="https://www.instagram.com/pariksha_do/?igshid=MmU2YjMzNjRlOQ%3D%3D"
                      target="_blank"
                      className={classes.socialicon}
                    >
                      <img src={instagram} alt="img" />
                    </Link>
                    <Link
                      to="https://www.facebook.com/profile.php?id=61551389254800"
                      target="_blank"
                      className={classes.socialicon}
                    >
                      <img src={facebook} alt="img" />
                    </Link>
                    <Link
                      to="https://www.youtube.com/channel/UCcUclO9vPgUkfUR38MmQ1cA"
                      target="_blank"
                      className={classes.socialicon}
                    >
                      <img src={Youtube} alt="img" />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/showcase/pariksha-do/"
                      target="_blank"
                      className={classes.socialicon}
                    >
                      <img src={linkedin} alt="img" />
                    </Link>
                  </div>
                </div>
                <List className={classes.footerlinkcontainer}>
                  <Link
                    to="/cancellationrefunds"
                    className={classes.footerlink}
                  >
                    <ListItem>
                      <ListItemText primary="Cancellation & Refunds" />
                    </ListItem>
                  </Link>
                  <Link to="/termsconditions" className={classes.footerlink}>
                    <ListItem>
                      <ListItemText primary="Terms & Conditions" />
                    </ListItem>
                  </Link>
                  <Link to="/privacypolicy" className={classes.footerlink}>
                    <ListItem>
                      <ListItemText primary="Privacy Policy" />
                    </ListItem>
                  </Link>
                </List>
              </div>
              <hr className={classes.copyrighthr} />
              <div className={classes.copyrightsection}>
                © Copyright 2023 All Right Reserved by Pariksha Do.
              </div>
            </div>
          </div>
          <hr className={classes.footerHrline} />
          <div className={classes.container}>
            <div className={classes.socialiconsMain}>
              <List className={classes.footerlinkcontainer}>
                <Link
                  to="/cancellationrefunds"
                  className={classes.footerlink}
                  preventScrollReset={true}
                >
                  <ListItem>
                    <ListItemText primary="Cancellation & Refunds" />
                  </ListItem>
                  <Divider orientation="vertical" />
                </Link>
                <Link
                  to="/shippingpolicy"
                  className={classes.footerlink}
                  preventScrollReset={true}
                >
                  <ListItem>
                    <ListItemText primary="Shipping Policy" />
                  </ListItem>
                  <Divider orientation="vertical" />
                </Link>
                <Link
                  to="/termsconditions"
                  className={classes.footerlink}
                  preventScrollReset={true}
                >
                  <ListItem>
                    <ListItemText primary="Terms & Conditions" />
                  </ListItem>
                  <Divider orientation="vertical" />
                </Link>

                <Link
                  to="/privacypolicy"
                  className={classes.footerlink}
                  preventScrollReset={true}
                >
                  <ListItem>
                    <ListItemText primary="Privacy Policy" />
                  </ListItem>
                </Link>
              </List>
              <div className={classes.socialiconsmaininner}>
                <div>
                  <h3>FOLLOW US :-</h3>
                </div>
                <div className={classes.socialiconssection}>
                  <Link
                    to="https://www.instagram.com/pariksha_do/?igshid=MmU2YjMzNjRlOQ%3D%3D"
                    target="_blank"
                    className={classes.socialicon}
                  >
                    <img src={instagram} alt="img" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/profile.php?id=61551389254800"
                    target="_blank"
                    className={classes.socialicon}
                  >
                    <img src={facebook} alt="img" />
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UCcUclO9vPgUkfUR38MmQ1cA"
                    target="_blank"
                    className={classes.socialicon}
                  >
                    <img src={Youtube} alt="img" />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/showcase/pariksha-do/"
                    target="_blank"
                    className={classes.socialicon}
                  >
                    <img src={linkedin} alt="img" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
