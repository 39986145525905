import React from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import EarthImage from "../image/onlineassessments/earth.png";
import StarIcon from "@material-ui/icons/Star";
import { locations, locationsRight } from "./testcenterdata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "5rem",
    padding: "2rem 0",
    backgroundColor: "rgba(255, 249, 236, 1)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "3rem",
      padding: "0rem 0",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0",
    },
  },
  onlineeducationmain: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4rem",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  onlineeducationcontent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 1rem",
      textAlign: "center",
    },
  },
  onlineeducationimgcontainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      order: -1,
      marginTop: "-22px",
    },
  },
  onlineeducationcontentinner: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
  },
  verticalLinesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "37px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  verticalLine: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  verticalLinesContainerRight: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "19rem",
    marginTop: "-46.3rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
      marginTop: "0",
      display: "none",
    },
  },
  verticalLineRight: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.5rem",
    whiteSpace: "nowrap",
  },
  star: {
    fontSize: "1rem",
    marginRight: "0.2rem",
    color: "rgba(255, 179, 45, 1)",
  },
  word: {
    fontFamily: "jost",
    fontSize: "22px",
    lineHeight: "50px",
    fontWeight: "400",
    color: "rgba(0,0,1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "23.12px",
    },
  },
  MuiTypographybody2: {
    marginTop: "0.5rem",
    fontFamily: "'Jost', sans-serif",
    color: "#7E7E7E",
    fontSize: "1rem",
    fontWeight: "400",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
  MuiTypographybody1: {
    fontFamily: "'Playfair Display', serif !important",
    color: "#000",
    fontSize: "2rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
      color: " #223852",
    },
  },
  MuiTypographybody3: {
    fontFamily: "'Playfair Display', serif !important",
    color: "#000",
    fontSize: "1.5rem",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      fontFamily: "Playfair Display",
      fontWeight: "500",
      lineHeight: "23px",
      color: "rgba(34, 56, 82, 1)",
    },
  },
}));

function TestCenter() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Accessible Test Centers NationWide: Bringing Convenience to Testing Across India ",
      headingmain: "Availability Of Test Center in Pan India",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <Container className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <div className={classes.verticalLinesContainer}>
                {locations.map((location, index) => (
                  <Typography
                    key={index}
                    variant="h5"
                    component="div"
                    className={classes.verticalLine}
                  >
                    <StarIcon className={classes.star} />
                    <span className={classes.word}>{location}</span>
                  </Typography>
                ))}
              </div>
              <div className={classes.verticalLinesContainerRight}>
                {locationsRight.map((location, index) => (
                  <Typography
                    key={index}
                    variant="h5"
                    component="div"
                    className={classes.verticalLineRight}
                  >
                    <StarIcon className={classes.star} />
                    <span className={classes.word}>{location}</span>
                  </Typography>
                ))}
              </div>
            </div>
          </div>
          <div className={classes.onlineeducationimgcontainer}>
            <img src={EarthImage} alt="img" width="100%" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TestCenter;
