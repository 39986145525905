import { SET_CATEGORY_LIST, SET_MEGA_MENU } from "./CategoryType";
const Reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: payload,
      };

    case SET_MEGA_MENU:
      return {
        ...state,
        megaMenu: payload,
      };

    default:
      return state;
  }
};

export default Reducer;
