import React, { useState } from "react";
import { ReactComponent as StartOverIcon } from "../../image/dashboard/startovericon.svg";
import { ReactComponent as FilterIcon } from "../../image/dashboard/filtericon.svg";
import girlteacher from "../../image/dashboard/girlteacher.png";
import StartOverCard from "./StartOverCard";
import {
    Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem",
      display: "block",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "55%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  startoversection: {
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  startovericon: {
    marginRight: "0.8rem",
  },
  filtersection: {
    width: "25%",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "0.375rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1.5rem",
    },
  },
  filtericonandname: {
    background: "#FFF",
    display: "flex",
    boxShadow: "0px 6px 40px 0px rgba(0, 0, 0, 0.06)",
    padding: "0.5rem 1rem",
    borderRadius: "0.375rem",
    "& .MuiTypography-body1": {
      fontFamily: "Jost !important",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#444",
    },
  },
  filterinput: {
    width: "58%",
    "& .MuiInputBase-input": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#9A9A9A",
    },
    "& .MuiSelect-selectMenu": {
      padding: "0.8rem",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  cardcontainer: {
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  viewbtn: {
    "&.MuiButton-root": {
        background: "#243B55",
        color: "#FFF",
        textTransform: "capitalize",
        padding: "0.3rem 1.2rem",
        borderRadius: "0.375rem",
        fontFamily: "Jost",
        fontWeight: "400",
        fontSize: "0.875rem",
    },
  },
  btncontainer: {
    width: "100%",
    margin: "1rem auto",
    textAlign: "center",
  }
}));

function StartOverSection() {
  const CardData = [
    {
      id: 1,
      title: "Quantitative Aptitude Series for UPSC 2023",
      by: "Shivani Sen",
      tag: "cOMPLETED",
      lectures: "25",
      image: girlteacher,
    },
    {
      id: 2,
      title: "Quantitative Aptitude Series for UPSC 2023",
      by: "Shivani Sen",
      tag: "cOMPLETED",
      lectures: "25",
      image: girlteacher,
    },
    {
      id: 3,
      title: "Quantitative Aptitude Series for UPSC 2023",
      by: "Shivani Sen",
      tag: "cOMPLETED",
      lectures: "25",
      image: girlteacher,
    },
    {
      id: 4,
      title: "Quantitative Aptitude Series for UPSC 2023",
      by: "Shivani Sen",
      tag: "cOMPLETED",
      lectures: "25",
      image: girlteacher,
    },
    {
      id: 5,
      title: "Quantitative Aptitude Series for UPSC 2023",
      by: "Shivani Sen",
      tag: "cOMPLETED",
      lectures: "25",
      image: girlteacher,
    },
    {
      id: 6,
      title: "Quantitative Aptitude Series for UPSC 2023",
      by: "Shivani Sen",
      tag: "cOMPLETED",
      lectures: "25",
      image: girlteacher,
    },
  ];
  const classes = useStyles();

  const [value, setValue] = useState(-1);

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  return (
    <div className={classes.startoversection}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <StartOverIcon className={classes.startovericon} />
          <Typography variant="h3">Start Over</Typography>
        </div>
        <div className={classes.filtersection}>
          <div className={classes.filtericonandname}>
            <span>
              <FilterIcon style={{ margin: "0.1rem 0.6rem 0 0" }} />
            </span>
            <Typography component={"span"}>Filter</Typography>
          </div>
          <div className={classes.filterinput}>
            <FormControl className={classes.margin}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                disableUnderline
                MenuProps={menuProps}
                onChange={(e)=>{setValue(e.target.value)}}
                value={value}
                // onChange={handleChange}
                //   input={<BootstrapInput />}
              >
                <MenuItem value={-1}>Select Here</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className={classes.cardcontainer}>
        {CardData.map((data) => (
          <StartOverCard data={data} />
        ))}
      </div>
      <div className={classes.btncontainer}>
        <Button className={classes.viewbtn}>View All</Button>
      </div>
    </div>
  );
}

export default StartOverSection;
