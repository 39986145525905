import React, { useEffect, useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import CustomTableView from "../../CustomComponent/TableView";
import { getTimeTableData } from "../../../service/services";
import NoDataFoundTimeTable from "../ErrorPage/NoDataFoundTimeTable";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "80vh",
    padding: "0rem 2rem 1rem 2rem ",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.5rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headname: {
    display: "flex",
    alignItems: "center",
    // marginTop: "1rem",
    width: "47%",
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  contentheading: {
    fontFamily: "Jost",
    fontSize: "0.85rem",
    fontWeight: "600",
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "30%",
    marginTop: "0.2em",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

function Timetable(props) {
  const classes = useStyles();

  const dummyData = [
    {
      id: 1,
      date: "2024-03-22T05:10:45.720Z",
      columns: [
        {
          title: "Title1",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title2",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title3",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
      ],
    },
    {
      id: 2,
      date: "2024-03-22T05:10:45.720Z",
      columns: [
        {
          title: "Title1",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title2",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title3",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title4",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title5",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
      ],
    },
    {
      id: 3,
      date: "2024-03-22T05:10:45.720Z",
      columns: [
        {
          title: "Title1",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title2",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title3",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
      ],
    },
    {
      id: 4,
      date: "2024-03-22T05:10:45.720Z",
      columns: [
        {
          title: "Title1",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title2",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title3",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title4",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title5",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
        {
          title: "Title6",
          start_time: "10:42:00",
          end_time: "10:42:00",
          teacher: "Teacher Name",
          status: "active",
        },
      ],
    },
  ];

  function formatTime(time) {
    const [hours, minutes] = time?.split(":");

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    const formattedTime = date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  }

  const [timetableData, settimetableData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getTimeTableData(token);
        console.log("API Response:", response.data?.timetables[0]?.schedules);
        settimetableData(response.data?.timetables[0]?.schedules);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  const createRows = () => {
      if (!timetableData || timetableData.length === 0) return [];
    return timetableData.map((data, index) => ({
      id: Math.random(),
      date: data?.select_date,
      srno: index + 1,
      content: data.columns?.map((col, colIndex) => ({
        ...col,
        id: col.id,
      })),
    }));
  };
  const maxRow = createRows()
  const createColumns = () => {
    if (!timetableData || timetableData.length === 0) return [];

    // Find the maximum number of columns among all rows
    const maxColumns = Math.max(
      ...timetableData.map((data) => data.columns?.length)
    );
 
    // Create dynamic columns based on the maximum number of columns
    const dynamicColumns = [];
    for (let i = 0; i < maxColumns; i++) {
      dynamicColumns.push({
        field: `content${i + 1}`,
        headerClassName: "super-app-theme--header",
        cellClassName: 'dynamic-columns',
        headerAlign: "center",
        width: 250,
        // flex: maxColumns < 4 ? 1 : "",
        sortable: false,
        disableColumnMenu: true,
        autoPageSize: false,
        renderHeader: () => <div>{`Content ${i + 1}`}</div>,
        renderCell: (params) => {
          const colIndex = i;
          const rowData = params.row.content[colIndex];

          return rowData ? (
            <div>
              <Typography className={classes.contentheading}>
                Title: {rowData.tittle}
              </Typography>
              <Typography className={classes.contentheading}>
                Start: {formatTime(rowData.start_time)}
              </Typography>
              <Typography className={classes.contentheading}>
                End: {formatTime(rowData.end_time)}
              </Typography>
              <Typography className={classes.contentheading}>
                Teacher: {rowData.teacher_name}
              </Typography>
            </div>
          ) : (
            <div>N/A</div>
          );
        },
      });
    }
  

    const staticColumns = [
      {
        field: "srno",
        headerName: "Sr.No.",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 90,
        sortable: false,
        disableColumnMenu: true,
        autoPageSize: false,
      },
      {
        field: "date",
        headerName: "Date",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 110,
        // flex: 1,
        sortable: false,
        disableColumnMenu: true,
        autoPageSize: false,
        renderCell: (cellValues) => (
          <div>
            {new Date(cellValues.row.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            })}
          </div>
        ),
      },
    ];

    return [...staticColumns, ...dynamicColumns];
  };

  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <Typography  gutterBottom className={classes.heading}>Time Table</Typography>
        </div>
      </div>
      {maxRow.length !== 0 ? (
      <CustomTableView
        columns={createColumns()}
        rows={createRows()}
        section={"timeTable"}
      />
       ) : (
          <NoDataFoundTimeTable />
        )}
    </div>
  );
}

export default Timetable;
