import React, { useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  personaldetails: {
    width: "100%",
    marginTop: "1rem",
    margin: "1rem 5px",
    borderRadius: 5,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    marginBottom: "20rem",
    "& .MuiTypography-h6": {
      color: "red",
      marginTop: "1rem",
    },
    "& .MuiTypography-h5": {
      color: "blue",
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  personalname: {
    width: "100%",
    marginTop: "1.5rem",
    display: "flex",
    alignItems: "baseline",
    "& .MuiFormLabel-root": {
      width: "20%",
    },
    "& .MuiTextField-root": {
      width: "40%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:
        "border-color: rgba(255,255,255,0) rgba(255,255,255,1) rgba(255,255,255,0) rgba(255,255,255,0)",
    },
  },
  TypeHere: {
    width: "100%",
    marginTop: ".5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    "& .MuiFormLabel-root": {
      width: "25%",
    },
    "& .MuiTextField-root": {
      width: "40%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor: "red",
    },
  },
  selectinput: {
    width: "100%",
    marginTop: ".5rem",
    display: "flex",
    alignItems: "baseline",
    "& .MuiFormLabel-root": {
      width: "20%",
    },
    "& .MuiInputBase-root": {
      width: "40%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor: "red",
    },
  },
  securitypin: {
    display: "flex",
    marginTop: "1.5rem",
    justifyContent: "center",
    alignItems: " baseline",
    "& .MuiFormLabel-root": {
      width: "20%",
    },
    "& .MuiOutlinedInput-root": {
      height: "3rem",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
  submitbtn: {
    display: "flex",
    justifyContent: "space-around",
    marginTop:"2rem",
    width:'50%',
    
  },
  btn: {
    background: "#243B55",
    color:'#fff',
    width: "20%",
    justifyContent: "space-around",
  },
  applyfor:{
    borderRadius: 4,
    height: "2rem",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    width:'90%',
    display:'flex',
    alignItems:'center',
  },
  twoselectinput:{
    display:'flex',
    width:'100%',
    margin:'0 auto'
  },
  selectstate:{
    width: "19.5%",
    marginTop: ".5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    "& .MuiFormLabel-root": {

      },
      "& .MuiInputBase-root": {
        width: "70%",

      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 7,
        height: "3rem",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      
      },
  },
  selectcity:{
    width: "40%",
    marginTop: ".5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
   
      "& .MuiInputBase-root": {
        width: "95%",

      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 7,
        height: "3rem",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        borderColor: "red",
      },
  },
  stateoption:{
    width: "43.5%",
    marginTop: ".5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    "& .MuiFormLabel-root": {

      },
      "& .MuiInputBase-root": {
        width: "95%",

      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 7,
        height: "3rem",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      
      },
  },
  securitypin:{
    borderRadius: 4,
    height: "2rem",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    width:'90%',
    marginTop:'3rem',
    display:'flex',
    alignItems:'center',
  },
  securitypinbtn:{
    display:'flex',
    marginTop:'1.5rem',
    alignItems:' baseline',
    "& .MuiFormLabel-root":{
      width:'20%',
    },
    "& .MuiOutlinedInput-root":{
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    }
  },
  casesensitive:{
    color:'#9B97CD',
  },
  optionalcolor:{
    color:'red',
  }
}));

function ApplyForExam() {
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const [selectedGender, setSelectedGender] = useState("");
  const handleChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const classes = useStyles();
  return (
    <div className={classes.personaldetails}>
      <Typography variant="h6">Apply for & Exam Centre Details:</Typography>
      <div className={classes.applyfor}>
      <Typography variant="h5">के लिए आवेदन करना (Apply For)</Typography>
      </div>
      <div className={classes.personalname}>
        <FormLabel>के लिए आवेदन करना (Apply For)</FormLabel>
        <TextField type="text" variant="outlined" required placeholder="National Eligibility Cum Entrance Test(UG)" />
      </div>
    
      <div className={classes.selectinput}>
        <FormLabel>प्रश्न पत्र माध्यम (Question Paper Medium)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.twoselectinput}>
      <div className={classes.selectstate}>
        <FormLabel>परीक्षा राज्य / शहर-पहला विकल्प (Exam State/City-1st
choice)</FormLabel>
        
      
      </div>
      <div className={classes.stateoption}><Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select an Option</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select></div>
      <div className={classes.selectcity}>
       
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select Exam City</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      </div>
      <div className={classes.twoselectinput}>
      <div className={classes.selectstate}>
        <FormLabel>परीक्षा राज्य/शहर दूसरा विकल्प (Exam State/City-2nd)</FormLabel>
        
      
      </div>
      <div className={classes.stateoption}><Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select an Option</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select></div>
      <div className={classes.selectcity}>
       
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select Exam City</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      </div>
      <div className={classes.twoselectinput}>
      <div className={classes.selectstate}>
        <FormLabel>परीक्षा राज्य / शहर तीसरा विकल्प (Exam State/ City-3rd
choice)</FormLabel>
        
      
      </div>
      <div className={classes.stateoption}><Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select an Option</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select></div>
      <div className={classes.selectcity}>
       
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select Exam City</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      </div>
      <div className={classes.twoselectinput}>
      <div className={classes.selectstate}>
        <FormLabel>परीक्षा राज्य / शहर- चौथा विकल्प (Exam State/City-4th
choice)</FormLabel>
        
      
      </div>
      <div className={classes.stateoption}><Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select an Option</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select></div>
      <div className={classes.selectcity}>
       
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>Select Exam City</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>पिता/अभिभावक व्यवसाय (Father / Guardian Occupation) <span className={classes.optionalcolor}>#Optional</span></FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>पिता/अभिभावक योग्यता (Father / Guardian Qualification)<span className={classes.optionalcolor}>#Optional</span></FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>माता/अभिभावक व्यवसाय (Mother / Guardian Occupation)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.selectinput}>
        <FormLabel>माता/अभिभावक पौग्यता (Mother / Guardian
Qualification)</FormLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={selectedGender}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          MenuProps={menuProps}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em className={classes.defaultselect}>--Select--</em>
          </MenuItem>
          <MenuItem value={"male"}>india</MenuItem>
          <MenuItem value={"female"}>japan</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </div>
      <div className={classes.securitypin}>
      <Typography variant="h5">Security Pin</Typography>
      </div>
      <div className={classes.personalname}>
        <FormLabel>Enter security Pin <span className={classes.casesensitive}>(case sensitive)</span></FormLabel>
        <TextField type="text" variant="outlined" required placeholder="" />
      </div>
      <div className={classes.securitypinbtn}>
                <FormLabel>
                  
                Security Pin
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div> 
      <div className={classes.submitbtn}>
        {" "}
        <Button className={classes.btn}>Save & Next</Button>
      </div>
    </div>
  );
}

export default ApplyForExam;
