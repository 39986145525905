
import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CategorySection from "./CategorySection";
import ExamSection from "./ExamSection";
import Heading from "./categorysectiondata";
import appContext from "../../context/AppContext"
import categoryContext from "../../context/CategoryContext/CategoryContext";
import PopularSectionContent from "./../Home/popularexamssection/popularexamcontent";



const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1250px',
    margin: '0px auto',
    position: 'relative',
  },
  paper: {
    clipPath: "polygon(21% 3%, 23% 0, 25% 3%, 100% 3%, 100% 100%, 0 100%, 0 3%)",
    position: 'absolute',
    display: "flex",
    top: '68px',
    backgroundColor: "#FFFAEF",
    zIndex: '8',
    width: '100%',
    boxShadow: theme.shadows[5],
    borderRadius: '0px 0px 10px 10px',
    height: 'calc(100vh - 100px)',
    overflow: 'hidden',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: theme.spacing(4),
  },
  categorysection: {
    margin: "0 10px",
    width: "30%",
    background: "white",
    borderRadius: "10px",
  },
  examsection: {
    width: "70%",
    // background: "white",
    borderRadius: "10px",
  },
}));
function AllModal({ handleOpenclose }) {
  const classes = useStyles();
  const AppContext = useContext(appContext);
  console.log(AppContext,"****************")
  const [selectedTabId, setSelectedTabId] = useState(AppContext?.coursesData?.category ? AppContext?.coursesData?.category : "");
  const [selectedTabName, setSelectedTabName] = useState(AppContext?.coursesData?.categoryName ? AppContext?.coursesData?.categoryName : "");
  const CategoryContext = useContext(categoryContext);

  useEffect(() => {
    var subCategories = CategoryContext?.megaMenu?.[0]?.["categories"]?.find(
      (subCategories) => subCategories?.id === selectedTabId
    )?.["subCategories"];
    selectedTabId && AppContext.setTempSubcategory(
      AppContext?.coursesData?.subcategory
        ? AppContext?.coursesData?.subcategoryName
        : subCategories[0]?.sub_category_name)
  }, [selectedTabId])
  console.log(selectedTabId,"selectedtabid");

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <div className={classes.categorysection}>
          <CategorySection setSelectedTabId={setSelectedTabId} selectedTabId={selectedTabId} setSelectedTabName={setSelectedTabName} />
        </div>
        <div className={classes.examsection}>
          <ExamSection handleOpenclose={handleOpenclose} selectedTabId={selectedTabId} selectedTabName={selectedTabName} />
        </div>
      </div>
    </div>
  );
}
export default AllModal;




