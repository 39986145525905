import { makeStyles } from "@material-ui/core";
import React from "react";
import PageHead from "../pageheading/pageheading";
import FilterSection from "./FilterSection";
import ExpertTeachersContent from "./ExpertTeachersContent.js";
import TeachersData from "./ExpertTeachersData";
import { useState } from "react";
import Pagination from "./Pagination";

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    background: "#FFFBF1",
    paddingBottom: "1rem",
    marginBottom: "15rem",
  },
}));

function ExpertTeachers() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Study",
      headingmain: "Expert Faculties",
      breadcrumb: "Links / Faculties",
    },
  ];

  // const [paginationCurrentItems, setPaginationCurrentItems] = useState();

  const [filteredDataByTeachers, setFilteredDataByTeachers] =
    useState(TeachersData);
  const [selectedExam, setSelectedExam] = useState(-1);
  const [selectedCity, setSelectedCity] = useState(-1);
  console.log("selectedCity >>>", selectedCity);
  // console.log("current >>>", paginationCurrentItems);
  console.log("filter by teacher >>", filteredDataByTeachers);

  // const handleCurrentItems = (currentItems) => {
  //   setPaginationCurrentItems(currentItems);
  // };

  const handleSearchTeacher = (searchTerm) => {
    //serchTerm----->data of search input
    const filtered = TeachersData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDataByTeachers(filtered);
  };

  const handleSelectExamAndCity = (exam, city) => {
    setSelectedExam(exam);
    setSelectedCity(city);
  };

  return (
    <div className={classes.maincontainer}>
      <PageHead Heading={Heading} />
      <FilterSection
        onSearchTeachers={handleSearchTeacher}
        handleSelectExamAndCity={handleSelectExamAndCity}
        data={TeachersData}
      />
      <ExpertTeachersContent
        filteredDataByTeachers={filteredDataByTeachers}
        selectedExam={selectedExam}
        selectedCity={selectedCity}
      />
      {/* <Pagination handleCurrentItems={handleCurrentItems} data={TeachersData}/> */}
    </div>
  );
}

export default ExpertTeachers;
