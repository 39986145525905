import React, { useContext, useState } from "react";
import {
  makeStyles,
  Button,
  Typography,
  Box,
  Drawer,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import practiceContext from "../context/PypPracticeContext";
import { Link,  useParams } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { HiMenuAlt3 } from "react-icons/hi";

const useStyles = makeStyles((theme) => ({
  drawerroot:{
    '& .MuiDrawer-paper':{
      background: "#FFFAEF",
    }
  },
  quizBox: {
    width: "370px",
    // height: "100%",
    textTransform: "none",
    borderRadius: "6px",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    ZIndex: "10000",
  },
  buttonOnTop: {
    width: "400px",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    height: "75px",
    margin: "6px 0px 0px 0px",
    color: "white",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "'Jost', sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-15%",
  },
  whiteBox: {
    width: "91%",
    height: "30vh",
    margin: "1rem auto 0",
    borderRadius: "6px",
    backgroundColor: "white",
  },
  extraRectangle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    borderRadius: "6px",
    margin: "0rem auto",
    marginTop: "1rem",
    width: "91%",
    height: "50px",
    backgroundColor: "blue",
  },
  text: {
    fontSize: "18px",
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    margin: "11px 22px",
    color: "rgba(33, 33, 33, 1)",
  },
  square: {
    width: "37px",
    height: "37px",
    fontSize: "25px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "700",
    backgroundColor: "rgba(235, 235, 235, 1)",
    margin: "15px",
    display: "inline-flex",
    borderRadius: "6px",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(255, 255, 255, 1)",
  },

  horizontalRectangles: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-43rem",
  },
  buttonBelowWhiteBox: {
    width: "150px",
    background: "#233A54",
    color: "white",
    height: "35px",
    margin: "0rem 0px 0px 21px",
    "&:hover": {
      background: "#233A54",
    },
  },
  closebutton: {
    width: "50px",
    background: "#233A54",
    color: "white",
    height: "35px",
    margin: "0rem 21px 0px 0px",
    "&:hover": {
      background: "#233A54",
    },
  },
  belowbuttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom:'1rem',
    marginTop:'1rem',
  },
  drawerOpenButton:{
    width: "25px",
      height: "41px",
    background: "#233A54",
    color: "white",
    margin: "0rem 21px 0px 0px",
    "&:hover": {
      background: "#233A54",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0rem",
    },
  },

  smallSquare: {
    width: "10px",
    height: "10px",
    margin: "15px 0px 0px 4px",
  },
  redSquare: {
    backgroundColor: "rgba(255, 189, 100, 1)",
  },
  blueSquare: {
    backgroundColor: "rgba(20, 30, 48, 1)",
  },
}));

export default function MobileQuizContainer({
  setTimeTaken,
  min,
  sec,
  timerEnd,
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { id } = useParams();
  const { quizQuestionObj,
    currentQuestion,
    setCurrentQuestion,
    currentSection,
    setCurrentSection,} =
    useContext(practiceContext);
  return (
    <>
      <Button onClick={() => setOpen(true)} className={classes.drawerOpenButton}>
        <HiMenuAlt3 />
      </Button>
      <Drawer className={classes.drawerroot} anchor={"right"} open={open} onClose={() => setOpen(false)}>
      {quizQuestionObj?.sections?.map((section, sectionIndex) => (
        <Box className={classes.quizBox}>
          <Button className={classes.buttonOnTop}>
            <AccessTimeIcon style={{ marginRight: "8px" }} />
            Time Left - {min}:{sec} mins
          </Button>
          {/* Title */}
          <div className={classes.extraRectangle}>
              <Typography className={classes.text}>
              {section?.section_name}
              </Typography>
            </div>
          <Box className={classes.whiteBox}>
          {section?.questions?.map((question, questionIndex) => (
              <div
                    onClick={() => {
                      setCurrentSection(sectionIndex);
                      setCurrentQuestion(questionIndex + 1);
                    }}
                    key={questionIndex}
                    className={`${classes.square} ${
                      currentSection === sectionIndex &&
                      currentQuestion === questionIndex + 1
                        ? classes.blueSquare
                        : question?.options?.find((i) => i.user_answer)
                        ? classes.redSquare
                        : ""
                    }`}
              >
                  {questionIndex + 1}
              </div>
              ))}
            
          </Box>
        </Box>
        ))}
        <Box className={classes.belowbuttonGroup}>
            <Link to={`/previousyearpapers/${id}/result`}>
              <Button
                className={classes.buttonBelowWhiteBox}
                onClick={() => {
                  let msUsed =
                    60000 * quizQuestionObj?.duration -
                    (60000 * min + 1000 * sec);
                  let min2 = Math.floor((msUsed / 1000 / 60) << 0);
                  let sec2 = Math.floor((msUsed / 1000) % 60);
                  setTimeTaken(min2, sec2);
                }}
              >
                Submit Paper
              </Button>
            </Link>
            
            <Button
              onClick={() => setOpen(false)}
              className={classes.closebutton}
            >
              <CloseIcon />
            </Button>
          </Box>
      </Drawer>
    </>
  );
}
