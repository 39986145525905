const ConstantPracticeData = {
  quizzes: {
    title: "Quizzes",
    subtitle: "Quiz Practices",
    pathname: "quizzes",
    key: "quizzes",
    list: "quizList",
    level3: "sections",
    level3Name: "section_name",
    breadcrumbs: [{ title: "Home", path: "/" }, { title: "Practice", path: "/" }, { title: "Quizzes", path: "/" }]
  },
  previousyearpapers: {
    title: "Previous Years Papers",
    subtitle: "Previous Years Papers",
    pathname: "previousyearpapers",
    key: "previous_year_papers",
    list: "previousYearPapersList",
    level3: "exams",
    level3Name: "exam_name",
    breadcrumbs: [{ title: "Home", path: "/" }, { title: "Practice", path: "/" }, { title: "Previous Year Paper", path: "/" }]
  },
  ebooks: {
    title: "E-Books",
    subtitle: "E-Books",
    pathname: "ebooks",
    key: "ebooks",
    list: "ebookList",
    level3: "exams",
    level3Name: "exam_name",
    breadcrumbs: [{ title: "Home", path: "/" },  { title: "E-Books", path: "/" }]
  },
  books: {
    title: "Books",
    subtitle: "Books",
    pathname: "books", // url of web
    key: "books", // from api key
    list: "bookList", // context name
    level3: "exams",
    level3Name: "exam_name",
    breadcrumbs: [{ title: "Home", path: "/" },  { title: "Books", path: "/" }]
  },
  singlepagedescription: {
    title: "Single page description",
    subtitle: "singlepagedescription",
    pathname: "books/singlepagedescription", // url of web
    key: "singlepagedescription", // from api key
    list: "singlepagedescriptionList", // context name
    level3: "exams",
    level3Name: "exam_name",
    breadcrumbs: [{ title: "Home", path: "/" }, { title: "Product Discription", path: "/" }]
  },
  testseries: {
    title: "Test Series",
    subtitle: "Tests Series",
    pathname: "testseries", // url of web
    key: "bundles", // from api key
    list: "testseriesList", // context name
    level3: "exams",
    level3Name: "exam_name",
    breadcrumbs: [{ title: "Home", path: "/" }, { title: "testseries", path: "/" }]
  },
  notes: {
    title: "Notes",
    subtitle: "Notes Study Material",
    pathname: "notes", // url of web
    key: "notes", // from api key
    list: "notesList", // context name
    level3: "sections",
    level3Name: "section_name",
    breadcrumbs: [{ title: "Home", path: "/" }, { title: "Notes", path: "/" }]
  },

  onlinevideos: {
    title: "Online Videos",
    subtitle: "Online Video",
    pathname: "onlinevideos", // url of web
    key: "online_videos", // from api key
    list: "onlinevideoList", // context name
    level3: "exams",
    level3Name: "exam_name",
    breadcrumbs: [{ title: "Home", path: "/" },  { title: "Books", path: "/" }]
  },
  liveclasses: {
    title: "Live Classes",
    subtitle: "Live Classes",
    pathname: "liveclasses", // url of web
    key: "live_classes", // from api key
    list: "liveclassesList", // context name
    level3: "exams",
    level3Name: "exam_name",
    breadcrumbs: [{ title: "Home", path: "/" },  { title: "Books", path: "/" }]
  },
};
export default ConstantPracticeData;
