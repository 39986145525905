import React from 'react'
import PageHeading from "../pageheading/pageheading";
import { makeStyles } from "@material-ui/core";
import SeacherBar from './seacherBar';
import CardNotification from './cardNotification';
import MultipleCard from './multipleCard';





const useStyles = makeStyles((theme) => ({


    quizzcontentcontainer: {
        maxWidth: "1250px",
        margin: "0 auto",
        marginTop: "20px",
        marginBottom: "15rem",
        justifyContent: "space-between",
        background:"#EAEAEA"
    },


}));


const NotificationEmpy = () => {
    const classes = useStyles();

    const Heading = [
        {
            id: 1,
            headingsmall: "Find the all Notification here",
            headingmain: "Notification",
            breadcrumb: " Notification",
        },
    ];
    return (
        <>
            <PageHeading Heading={Heading} />
            <div className={classes.quizzcontentcontainer}>
                <SeacherBar />
                <CardNotification/>
                <MultipleCard/>
            </div>
        </>

    )
}

export default NotificationEmpy
