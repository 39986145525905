import React, { useContext, useEffect, useState } from "react";
import { Button, makeStyles, Modal, Typography } from "@material-ui/core";
import { GrFormClose } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";

import PopupBackgroundImage from "../image/allpopups/popupcard.png";
import PopupImage from "../image/allpopups/popupimage.png";
import AddCartContext from "../../context/AddCartContext/AddCartContext";
import Dialog from '@material-ui/core/Dialog';
import popupContext from "../../context/PopupContext/PopupContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "20px",
  },
  backgroundCard: {
    background: `url(${PopupBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "auto",
    height: "auto",
    borderRadius: "6px",
    padding: "7rem 6rem",
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "11rem 1rem",
    },
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popupImage: {
    width: "auto",
    height: "auto",
    objectFit: "cover",
   
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "6rem",
    },
  },
  closeButton: {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    backgroundColor: "white",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    backgroundCard: {
      height: "200px",
    },
    popupImage: {
      width: "auto",
      height: "auto",
    },
  },
  heading: {
    fontWeight: "700",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "36px",
    color: "rgba(37, 37, 37, 1)",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      marginTop: "2rem",
    },
  },
  heading1: {
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    fontSize: "18px",
    color: "rgba(48, 48, 48, 1)",
    textAlign: "center",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
}));

function OrderPlaced(props) {
  const {
    handleDeliveryAddressclose
  } = useContext(popupContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);;
  const {search, pathname} = useLocation();
  const navigate = useNavigate();
  const { fetchCartAPI } = useContext(AddCartContext);
  const urlParams = new URLSearchParams(search);
  const reference = urlParams.get('reference');
  const paymentSuccess = urlParams.get('payment_success');

  useEffect(() => {
    if (reference && paymentSuccess) {
      setOpen(true);
      navigate(pathname)
      fetchCartAPI();
    }
  }, [pathname, reference, paymentSuccess])

  
  const handleOrderPopupopenClose = (e) => {
    setOpen(!open);
  };

  return (
    <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
    <div className={classes.container}>
      <div className={classes.backgroundCard}>
        <div className={classes.closeButton}>
        <Button className={classes.closebtn} onClick={handleOrderPopupopenClose}>
            <GrFormClose />
          </Button>
        </div>
        <div className={classes.imageContainer}>
          <img src={PopupImage} alt="Popup" className={classes.popupImage} />
        </div>
        <Typography variant="h4" className={classes.heading}>
          <span style={{ color: "rgba(255, 187, 62, 1)" }}>Thank You</span> For
          Your Order !
        </Typography>
        {/* <Typography variant="h7" className={classes.heading1}>
          You’ll receive an email
          once your order is confirmed
        </Typography> */}
      </div>
    </div>
    </Modal>
  );
}

export default OrderPlaced;
