import { useEffect, useReducer } from "react";
import Reducer from "./reducer";
import Context from "./index";
import { getPreviousYearPaperQuestionsByQuizId } from "../../../../service/services";
import {
  GET_QUIZ_QUESTIONS,
  SET_CURRENT_SECTION,
  SET_CURRENT_QUESTION,
  SET_QUESTION_ANSWER,
  SET_TIME_TAKEN
} from "./type";

const State = (props) => {
  let initialState = {
    quizQuestionObj: null,
    currentSection: 0, // Added for section-wise quiz
    currentQuestion: 1,
    timeTaken: { minute: 0, second: 0 },
    countdown: 0
  };

  const [state, dispatch] = useReducer(Reducer, initialState);

  const getQuizQuestion = async (id) => {
    const res = await getPreviousYearPaperQuestionsByQuizId(id);
    if (res?.data?.success) {
      dispatch({ type: GET_QUIZ_QUESTIONS, payload: res.data.previous_year_paper });
    }
  };

  const setCurrentSection = (sectionIndex) => {
    dispatch({ type: SET_CURRENT_SECTION, payload: sectionIndex });
  };

  const setCurrentQuestion = (questionNumber) => {
    dispatch({ type: SET_CURRENT_QUESTION, payload: questionNumber });
  };

  const setAnswer = (answerNumber) => {
    dispatch({ type: SET_QUESTION_ANSWER, payload: answerNumber });
  };

  const setTimeTaken = (minute, second) => {
    dispatch({ type: SET_TIME_TAKEN, payload: { minute, second } });
  };

  return (
    <Context.Provider
      value={{
        quizQuestionObj: state.quizQuestionObj,
        currentSection: state.currentSection, // Provide currentSection in context
        currentQuestion: state.currentQuestion,
        timeTaken: state.timeTaken,
        getQuizQuestion,
        setCurrentSection, // Provide setCurrentSection function in context
        setCurrentQuestion,
        setAnswer,
        setTimeTaken
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default State;
