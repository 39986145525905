import React from "react";
import {
  AppBar,
  Button,
  InputBase,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "99%",
    backgroundSize: "cover",
    background: "#FFFBF1",

  },
  container: {
    padding: "3rem 0",
    maxWidth: "1250px",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
        padding: "2rem 0",
        maxWidth: "95%",
      },
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "#FFF",
    },
    "& .MuiToolbar-regular": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.10)",
      borderRadius: "0.3rem",
    },
    "& .MuiIconButton-root": {
      padding: "10px 12px",
    },
    "& .MuiMenuItem-root": {
      fontSize: "0.9rem",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "500",
    },
  },
  appbar: {
    position: "absolute",
    top: "-20px",
  },
  btncontainer: {
    width: "10%",
    [theme.breakpoints.down("xs")]: {
      width: "25%",
    },
  },
  bluecustombtn: {
    width: "100%",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "0.6rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "Jost",
      fontWeight: "300",
    },
  },
  inputRoot: {
    width: "100%",
    background: "#F9F9F9",
    borderRadius: "0.3rem",
    border: "1px solid #DBDBDB",
  },
  inputInput: {
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "500",
    fontSize: "1rem",
    color: "#646464",
    padding: "0.8rem",
    width: "100%",
    borderRadius: "0.3rem",
  },
  search: {
    width: "89%",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  searchIcon: {
    position: "absolute",
    top: "0.8rem",
    right: "0.8rem",
    color: "#252525",
    borderRadius: "5px",
  },
}));

const SeacherBar = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>
              <div className={classes.search}>
                <InputBase
                  placeholder="Search"
                  // onChange={handleSearchByTeacher}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
              </div>

              <div className={classes.btncontainer}>
                <Button
                  className={classes.bluecustombtn}
                  // onClick={handleSearchBtn}
                >
                  Search
                </Button>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    </>
  );
};

export default SeacherBar;
