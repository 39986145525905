import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import SquareBlock from "./squareblock";
import { useMediaQuery } from "@material-ui/core";
import KeyFunctionSectionData from "./keyfunctionsectiondata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "4.5rem",
    padding: "4rem 0",
    backgroundColor: "rgba(255, 249, 236, 1)",
  },
  container: {
    maxWidth: "1150px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      display: "flex",
      flexWrap: "wrap",
      marginTop: "4rem",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  onlineeducationcontent: {
    width: "50%",
    display: "flex",
    marginTop: "4rem",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "block",
      marginTop: "0rem",
    },
  },
  onlineeducationcontentinner: {
    width: "100%",
    display: "grid",
    flex: "wrap",
    gridTemplateColumns: "repeat(4, 1fr)", 
    gridTemplateRows: "repeat(3, 1fr)", 

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(6, 1fr)",
    },
  },
  mainHeading: {
    marginTop: "2rem",
  },
}));

function KeyFunctionSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Streamlined Online Exam Result Processing for Enhanced Educational Outcomes",
      headingmain: "Key Functionalities",
    },
  ];
  
  const isMobileView = useMediaQuery("(max-width: 768px)");

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              {KeyFunctionSectionData.map((block) => (
                <SquareBlock
                  key={block.id}
                  iconName={block.iconName}
                  heading={block.heading}
                  subheading={isMobileView ? "" : block.subheading}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeyFunctionSection;
