import React from "react";
import HeaderNotification from "./headerNotification";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "275",
    padding: "auto",
  },
  icon: {
    color: "orange",
    width: "20%",
  },
}));

const FeeApplication = [
  {
    id: 1,
    subCategories: "Application Fee: Rs.100/",
  },
  {
    id: 2,
    subCategories: "For Women, SC, ST,PWD& Esm: Nil",
  },
  {
    id: 3,
    subCategories:
      "Payment Mode (Online/ Offline):SBI Challan/Net Banking By Using visa Master Card,MAstro,Rupay credit/debit card",
  },
];

const ApplicationFee = ({ Notification1 }) => {
  const classes = useStyles();
  const value = "Application Fee";
  console.log(Notification1, "notification1App");
//   const heading=Notification1.requirements[0].heading
//   const description=Notification1.requirements[0].description;
  const description = Notification1?.requirements?.[0]?.description;
const heading = Notification1?.requirements?.[0]?.heading;
  return (
    <>
      <Card classname={classes.card}>
        <HeaderNotification data={heading} />
        <CardContent>
          <div className={classes.examslist}>
            <List component="nav" aria-label="secondary mailbox folders">
              {

                                
                                        <ListItem  button selected={""}>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon className={classes.icon} />

                                            </ListItemIcon>
                                            <ListItemText  >
                                          <div dangerouslySetInnerHTML={{ __html: description }} />
           </ListItemText>
                                        </ListItem>
                                  
                            }
              {/* {Notification1?.requirements?.map((data, index) => {
                return (
                //   <ListItem key={index} button selected={""}>
                //     <ListItemIcon>
                //       <FiberManualRecordIcon className={classes.icon} />
                //     </ListItemIcon>
                //     <ListItemText   <div dangerouslySetInnerHTML={{ __html: primary={data.description} }} />
                //                 />
                //   </ListItem>
                <ListItem>
              <ListItemIcon>
                   <FiberManualRecordIcon className={classes.icon} />
            </ListItemIcon>
               <ListItemText>
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
           </ListItemText>
               </ListItem>
                );
              })} */}
            </List>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ApplicationFee;
