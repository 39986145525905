import React from "react";
import { Box, makeStyles, CardActions, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardSectionData from "./cardsectiondata";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  card: {
    width: "25%",
    "& .MuiCardActions-root": {
      borderRadius: 6,
      padding: "0.2rem",
      "& .MuiButton-label": {
        fontFamily: "Jost",
        fontSize: "0.7rem",
        alignItems: "flex-start",
      },
    },
  },
  rectangleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rectangle: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "6px",
    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.15)",
    alignItems: "center",
    backgroundColor: "rgba(255, 57, 57, 1)",
    height: "30px",
    width: "112px",
  },
  liveText: {
    fontFamily: "Quicksand",
    fontWeight: "700",
    fontSize: "14px",
    color: "rgba(255, 255, 255, 1)",
  },
  textBelowRectangle: {
    marginTop: "8px",
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    fontSize: "20px",
    color: "rgba(243, 61, 61, 1)",
  },
  textBelowMock: {
    fontFamily: "Quicksand",
    fontWeight: "600",
    fontSize: "17px",
    color: "rgba(37, 37, 37, 1)",
  },
  BankingTextRectangle: {
    display: "flex",
    marginTop: "8px",
    justifyContent: "center",
    borderRadius: "6px",
    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.15)",
    alignItems: "center",
    backgroundColor: "rgba(0, 169, 17, 1)",
    height: "22px",
    width: "67px",
  },
  textBelowBank: {
    fontFamily: "Quicksand",
    fontWeight: "600",
    fontSize: "17px",
    marginTop: "8px",
    color: "rgba(37, 37, 37, 1)",
  },
  squareBoxContainer: {
    display: "flex",
    justifyContent: "center",
  },
  squareBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
    border: "1px solid rgba(255, 67, 67, 1)",
    width: "60px",
    height: "60px",
    borderRadius: "6px",
    margin: "10px 11px",
  },
  squareBoxNumber: {
    fontFamily: "Quicksand",
    fontWeight: "700",
    color: "rgba(77, 77, 77, 1)",
    fontSize: "26px",
  },
  timeText: {
    fontFamily: "Quicksand",
    fontWeight: "600",
    fontSize: "12px",
    color: "rgba(255, 255, 255, 1)",
  },
  button: {
    background:
      "linear-gradient(90deg, rgba(255, 128, 8, 0.3) 0%, rgba(255, 200, 55, 0.3) 100%)",
    marginTop: "20px",
    color: "#212121 !important",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 120px",
    borderRadius: "6px",
  },
}));

const CardSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          {CardSectionData.map((data, index) => (
            <div className={classes.rectangleContainer} key={index}>
              <div className={classes.rectangle}>
                <Typography className={classes.liveText}>
                  {data.type}
                </Typography>
              </div>
              <Typography className={classes.textBelowRectangle}>
                {data.title}
              </Typography>
              <Typography className={classes.textBelowMock}>
                {data.subtitle}
              </Typography>
              <div className={classes.BankingTextRectangle}>
                <Typography className={classes.liveText}>
                  {data.category}
                </Typography>
              </div>
              <Typography className={classes.textBelowBank}>
                {data.endsIn}
              </Typography>
              <div className={classes.squareBoxContainer}>
                {data.timer.map((time, i) => (
                  <Box className={classes.squareBox} key={i}>
                    <Typography className={classes.squareBoxNumber}>
                      {time}
                    </Typography>
                  </Box>
                ))}
              </div>
              <CardActions>
                <Button className={classes.button} fullWidth>
                  Attempt Now
                </Button>
              </CardActions>
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default CardSection;
