import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHead from "../pageheading/pageheading";
import WelcomeSection from "../coachingspartner/WecomeSection";
import WhatWeOffer from "../coachingspartner/WhatWeOffer";
import CoachingBannerCard from "../coachingspartner/CoachingBannerCard";
import RegisterCoachingSection from "../coachingspartner/RegisterCoachingSection";
import FeaturesBenefits from "../coachingspartner/FeaturesBenefits";
import FollowProcess from "../coachingspartner/FollowProcess";


const useStyles = makeStyles((theme) => ({}));
function CoachingsPartner() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Create Your Own Space",
      headingmain: "Coaching",
      breadcrumb: "Collaborate / Coaching",
    },
  ];
  return (
    <>
    <div className={classes.coachingsmain}>
          <PageHead Heading={Heading} />
          <WelcomeSection />
          <CoachingBannerCard />
          <WhatWeOffer />
          <RegisterCoachingSection />
          <FeaturesBenefits />
          <FollowProcess />
          </div>
    </>
  );
}
export default CoachingsPartner;
