import React from "react";
import Supportsection from "./supportsection"; // Make sure the import path is correct
import SecHead from "../Home/sectionHeading/sectionheading";
import { makeStyles, Box, Typography } from "@material-ui/core";
import RectangleShapeData from "./rectangleshapedata";

const useStyles = makeStyles((theme) => ({
  headings: {
    display: "none",
    textAlign: "center",
    marginBottom: "2rem",
    fontFamily: "'Playfair Display', serif",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "3rem",
    },
  },
  subheadings: {
    fontFamily: "'Playfair Display', serif",
    fontWeight: "600",
  },
  trianglesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "7.5rem",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "2.5rem",
    },
  },
  rectangle: {
    width: "calc(50% - 20px)",
    height: "140px",
    backgroundColor: "#E1D9D1",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    fontSize: "1.3rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    margin: "10px",
    "&:hover": {
      backgroundColor: "orange",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "calc(25% - 20px)",
      height: "110px",
      marginBottom: "10px",
    },
  },
}));

function Rectangleshape() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Empowering Growth Through cutting-Edge Digital Assessment Solutions",
      headingmain: "Features Of Onscreen Marking System",
    },
  ];

  return (
    <div>
      <div className={classes.headings}>
        <SecHead Heading={Heading} />
      </div>

      <Box className={classes.trianglesContainer}>
        {RectangleShapeData.map((item, index) => (
          <Supportsection
            key={index}
            text={<Typography variant="h6">{item.text}</Typography>}
            icon={<img src={item.image} alt={`Rectangle ${index + 1}`} />}
          />
        ))}
      </Box>
    </div>
  );
}

export default Rectangleshape;
