import axios from "axios";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const axios_instance = axios.create({
  baseURL:baseURL
});

export async function authorizeMe(token) {
  axios_instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export async function me(data) {
  var response = await axios_instance.post("auth/me", data);
  return response;
}

export async function signIn(data) {
  var response = await axios_instance.post("users/learner/signin", data);
  return response;
}

export async function LogOut(data) {
  var response = await axios_instance.post("users/learner/logout", data);
  return response;
}

export async function signInViaOTP(data) {
  var response = await axios_instance.post(
    "users/learner/signin_with_otp",
    data
  );
  return response;
}

export async function signInOTPVerify(data) {
  var response = await axios_instance.put(
    "users/learner/otp_verify_signin",
    data
  );
  return response;
}

export async function signUp(data) {
  var response = await axios_instance.post("users/learner/signup", data);
  return response;
}

export async function verifyOtp(data) {
  var response = await axios_instance.put("users/learner/otp_verify", data);
  return response;
}

export async function resendOtp(data) {
  var response = await axios_instance.post("users/learner/resend", data);
  return response;
}

export async function forgotPassword(data) {
  var response = await axios_instance.put(
    "users/learner/forgot_password",
    data
  );
  return response;
}
export async function updatePassword(data) {
  var response = await axios_instance.put(
    "users/learner/update_password",
    data
  );
  return response;
}

export async function getMegaMenuData() {
  try {
    const response = await axios_instance.get("exam/mega_menu");
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getSingleExamProductAPI(id) {
  try {
    const response = await axios_instance.get(`exam/products/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

// getEbookListAPI
export async function getEbookListAPI() {
  try {
    const response = await axios_instance.get(`ebook`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

// getOnlineVideoListAPI
export async function getOnlineVideoListAPI() {
  try {
    const response = await axios_instance.get(`online_video`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

// getLiveClassesListAPI
export async function getLiveClassesListAPI() {
  try {
    const response = await axios_instance.get(`live_classes`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

// getbookListAPI
export async function getBookListAPI() {
  try {
    const response = await axios_instance.get(`book`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getTestSeriesListAPI() {
  try {
    const response = await axios_instance.get(`test_series`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getNotesListAPI() {
  try {
    const response = await axios_instance.get(`note`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getEBookDetailsAPI(id) {
  try {
    const response = await axios_instance.get(`/ebook/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getCategoriesList() {
  try {
    const response = await axios_instance.get("category");
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getSubCategoriesList(id) {
  try {
    const response = await axios_instance.get(`sub_category/list/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getExamBySubCategories(id) {
  try {
    const response = await axios_instance.get(`exam/list/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function gettestimonialData() {
  try {
    const response = await axios_instance.get(`testimonials`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getFAQ(id , type) {
  var response = await axios_instance.get(`faqs/${type}/${id}`);
  return response;
}

// export async function getAllFAQ(id , type) {
//   var response = await axios_instance.get(`faqs/${type}/${id}`);
//   return response;
// }

// Quiz
export async function getQuizListAPI() {
  try {
    const response = await axios_instance.get(`quiz/newapi`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getFeedBack(token) {
  try {
    const response = await axios_instance.get(`mydashboard/feedback`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getQuizQuestionsByQuizId(id) {
  try {
    const response = await axios_instance.get(`quiz/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getJobNotification() {
  try {
    const response = await axios_instance.get(`notification/limit/4`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getJobNotificationCategory() {
  try {
    const response = await axios_instance.get(`notification/latest/list`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getSingalPageNotifications(id) {
  try {
    const response = await axios_instance.get(`notification/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getAllNotifications() {
  try {
    const response = await axios_instance.get(`notification`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function PostOnlineSupport(data) {
  try {
    const response = await axios_instance.post(`online_supports/add`, data);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function UpdateProfile(data, token) {
  try {
    const response = await axios_instance.put(
      "users/learner/update_profile",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return the response data
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function UpdateProfilePicture(data, token) {
  try {
    const response = await axios_instance.put(
      "mydashboard/setting/image",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return the response data
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getProfileData(token) {
  try {
    const response = await axios_instance.get(`mydashboard/setting`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "jwt expired"
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function Change_Password(data, token) {
  try {
    var response = await axios_instance.put(
      `users/learner/change_password`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function Help_desk(data, token) {
  try {
    var response = await axios_instance.post(`mydashboard/helpdesk/add`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function testSeriesLogin(data, token) {
  try {
    var response = await axios_instance.post(`users/learner/exam_login`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err.message);
    }
  }
}
export async function DoubtForumAnswer(data, token) {
  try {
    var response = await axios_instance.post(
      `mydashboard/doubtforumanswer/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function DoubtForme(data, token) {
  try {
    var response = await axios_instance.post(
      `mydashboard/doubtforum/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function AddresellBook(data, token) {
  try {
    var response = await axios_instance.post(`mydashboard/resell/add`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getExclusiveBook() {
  try {
    const response = await axios_instance.get(`book/latest/random`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getMypurchase() {
  try {
    const response = await axios_instance.get(`mydashboard/mypurchase/`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getResellbookList(token) {
  try {
    const response = await axios_instance.get(`mydashboard/resell`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    }
  }
}
export async function getTestSeriesAchivement(token) {
  try {
    const response = await axios_instance.get(
      `mydashboard/myachivements/testseries`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}
export async function getMypurchaseOrderDetails(token, id) {
  try {
    const response = await axios_instance.get(
      `mydashboard/mypurchase/product/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}
export async function getNotesPdf(token, id , type) {
  try {
    const response = await axios_instance.get(`mydashboard/notes/${id}?type=${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getEbookPdf(token, id , type) {
  try {
    const response = await axios_instance.get(`mydashboard/ebooks/${id}?type=${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function saveEbookLastPage(data, token) {
  try {
    var response = await axios_instance.post(
      `mydashboard/ebooks/last_page`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function saveNotesLastPage(data, token) {
  try {
    var response = await axios_instance.post(
      `mydashboard/notes/last_page`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function DeleteDoubtForumQuestions(token, id) {
  try {
    const response = await axios_instance.delete(
      `mydashboard/doubtforum/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function DeleteDoubtForumAnswer(token, id) {
  try {
    const response = await axios_instance.delete(
      `mydashboard/doubtforumanswer/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getDoubtForumQuestions(token) {
  try {
    const response = await axios_instance.get(`mydashboard/doubtforum`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getOnlineVideoCourse(token) {
  try {
    const response = await axios_instance.get(`mydashboard/videocourse`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getMydashboardMyPurchase(token) {
  try {
    const response = await axios_instance.get(`mydashboard/mypurchase/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getMydashboardMySingalPurchase(token, id) {
  try {
    const response = await axios_instance.get(`mydashboard/mypurchase/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getMydashboardMyBooks(token) {
  try {
    const response = await axios_instance.get(
      `/mydashboard/mypurchase/orders/books/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getMydashboardMySingleBook(token, id) {
  try {
    const response = await axios_instance.get(
      `mydashboard/mypurchase/product/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getDoubtForumAnswer(token, id) {
  try {
    const response = await axios_instance.get(
      `mydashboard/doubtforumanswer/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getTimeTableData(token) {
  try {
    const response = await axios_instance.get(
      `mydashboard/timetable`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getOnlineVideoSingalCourse(token, id , type) {
  try {
    const response = await axios_instance.get(`mydashboard/videocourse/${id}?type=${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getDashBoardProgress(token) {
  try {
    const response = await axios_instance.get(`mydashboard/mydashboard/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSingaltestSeries(id, token) {
  try {
    const response = await axios_instance.get(
      `mydashboard/testseriesbundles/livetestseries/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getRecomndadEbooks(token) {
  try {
    const response = await axios_instance.get(
      `mydashboard/ebooks/recommended/ebooks`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getRecomndadNotes(token) {
  try {
    const response = await axios_instance.get(
      `mydashboard/notes/recommended/notes`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getEbookDashBoard(token) {
  try {
    const response = await axios_instance.get(`mydashboard/ebooks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getNoteCardDash(token) {
  try {
    const response = await axios_instance.get(`mydashboard/notes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getRecomndadTestseries(token) {
  try {
    const response = await axios_instance.get(
      `mydashboard/testseriesbundles/recommendedtestseries`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getRecomndadOnlineVideo(token) {
  try {
    const response = await axios_instance.get(
      `mydashboard/videocourse/recommended/videocourse`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function getTestSeriesDashBundle(token) {
  try {
    const response = await axios_instance.get(`mydashboard/testseriesbundles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getTestSeriesData(id) {
  try {
    const response = await axios_instance.get(
      `test_series/latest/random?examid=${id}`
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getMockTestseries(id) {
  try {
    const response = await axios_instance.get(
      `/test_series/latest/mocks/free?examid=${id}`
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function postAttemptQuiz(body) {
  try {
    var response = await axios_instance.post(`/attempts/quiz_attempt`, body);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

// previous year paper
export async function getPreviousYearPaperListAPI() {
  try {
    var response = await axios_instance.get(`previous_year_paper`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getPreviousYearPaperQuestionsByQuizId(id) {
  try {
    var response = await axios_instance.get(
      `previous_year_paper/questions/sections/${id}`
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function postAttemptPyp(body) {
  try {
    var response = await axios_instance.post(`/attempts/pyp_attempt`, body);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getFreeTestSeriesQuestionsId(id) {
  var response = await axios_instance.get(`test_series/questions/${id}`);
  return response;
}
// getFreeTestSeriesQuestionsId(67)
export async function getHelpDeskData(token) {
  try {
    const response = await axios_instance.get(`mydashboard/helpdesk/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getBookDetailsAPI(id) {
  try {
    var response = await axios_instance.get(`/book/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getTestSeriesDetailsAPI(id) {;
  try {
    var response = await axios_instance.get(`/test_series/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getNoteDetailsAPI(id) {
  try {
    var response = await axios_instance.get(`/note/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getOnlineVideoDetailsAPI(id) {
  try {
    var response = await axios_instance.get(`/online_video/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getLiveClassesDetailsAPI(id) {
  try {
    var response = await axios_instance.get(`/live_classes/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getRZPKey() {
  try {
    var response = await axios_instance.get(`/api/razorpay/key`); 
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function createOrder(payload) {
  try {
    var response = await axios_instance.post(`/api/razorpay/order_create`, payload);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getAllWishlistProducts() {
  try {
    var response = await axios_instance.get(`/wishlist`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function addToWishlist(payload) {
  try {
    var response = await axios_instance.post(`/wishlist/add`, payload);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function removeFromWishlist(id) {
  try {
    var response = await axios_instance.delete(`/wishlist/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function getAllCartProducts() {
  try {
    var response = await axios_instance.get(`/cart`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function addToCart(payload) {
  try {
    var response = await axios_instance.post(`/cart/add`, payload);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function updateCart(payload) {
  try {
    var response = await axios_instance.put(`/cart`, payload);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function removeFromCart(id) {
  try {
    var response = await axios_instance.delete(`/cart/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function getSingleBookOrder(id, type, quantity) {
  try {
    var response = await axios_instance.get(
      `/cart/checkout/single?product_type=${type}&product_id=${id}&quantity=${quantity}`
    );
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}

export async function getAllAddress() {
  try {
    var response = await axios_instance.get(`/delivery_address`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function saveAddress(payload) {
  try {
    var response = await axios_instance.post(`/delivery_address/add`, payload);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function deleteAddress(id) {
  try {
    var response = await axios_instance.delete(`/delivery_address/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getCities(id) {
  try {
    var response = await axios_instance.get(`/country/state/city/${id}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getStates() {
  try {
    var response = await axios_instance.get(`/country/state`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function postEnquiry(data) {
  try {
    var response = await axios_instance.post(`/enquiry/add`, data);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function cochingCreate(data) {;
  try {
    var response = await axios_instance.post(`/users/institute/signup`, data);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function getDistricts(selectedState) {
  try {
    var response = await axios_instance.get(`/admin/state/${selectedState}`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function checkLearnerData() {
  try {
    var response = await axios_instance.get(`/users/learner/check/details`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function fillOtherInfoOfLearners(data) {
  try {
    var response = await axios_instance.post(`/users/learner/details/add`, data);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function UpdateOtherInfoOfLearners(data) {
  try {
    var response = await axios_instance.put(`/users/learner/details/add`, data);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

export async function careersApplicationSubmit(data) {
  try {
    const response = await axios_instance.post(`/job_seekers/add`, data);
    return response.data;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function Subscriber(data) {
  try {
    const response = await axios_instance.post(`subscribe`, data);
    return response.data;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}

// Careers Fet API

export async function careersGetData() {
  try {
    const response = await axios_instance.get(`careers`);
    return response.data;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  }
}
export async function FeedBackAdd(data, token) {
  try {
    var response = await axios_instance.post(
      `mydashboard/feedback/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to send issue: " + error.message);
  }
}

export async function getLearnerMenu() {
  try {
    const response = await axios_instance.get(`users/learner/main_menu`);
    return response;
  } catch (err) {
    if (
      err?.response?.data?.message ===
      "Invalid or expired token. Please refresh your session."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "You are logged out. Please log in again."
    ) {
      window.location.reload();
      localStorage.clear();
    } else if (
      err?.response?.data?.message ===
      "jwt expired"
    ) {
      window.location.reload();
      localStorage.clear();
    } 
  }
}
