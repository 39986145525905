import { Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import ChooseUsSectionVector from "../../image/chooseusvector/chooseussectionvector.png";
import Vectorplay from "../../image/bannerimages/Vector.png";
import btnvector from "../../image/chooseusvector/btnvector.png";
import btnvector2 from "../../image/chooseusvector/btnvector2.png";
import btnvector3 from "../../image/chooseusvector/btnvector3.png";
import btnvector4 from "../../image/chooseusvector/btnvector4.png";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    justifyContent: "center",
    width: "100%",
    fontFamily: "'Playfair Display', serif !important",
  },
  chooseusmain: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      padding: "0 1rem",
    },
  },
  chooseusimgcont: {
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  chooseuscontent: {
    fontFamily: "'Playfair Display', serif !important",
    maxWidth: "50%",
    padding: "3rem",
    "& .MuiTypography-h4": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.5rem",
      fontWeight: "500",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
      marginTop: "1rem",
      padding: "0rem",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTypography-h4": {
        fontSize: "1.25rem",
      },
    },
  },
  chooseusbuttons: {
    marginTop: "2rem",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  chooseusbuttonsmobile: {
    marginTop: "2rem",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  chooseusp: {
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  chooseuspred: {
    color: "#FF7C44",
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  chooseuspmobile: {
    color: "#636363",
    fontFamily: "'Jost', sans-serif",
    marginTop: "1.5rem",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "20px 50px",
    marginRight: "70px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "14px 50px",
    },
  },
  circelbtn: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(225,128,8,0.0)",
    },
  },
  circelbtntext: {
    marginLeft: "3rem",
  },
  desktoph4: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobileh4: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  chooseusbtnsection: {
    display: "flex",
    justifyContent: "space-evenly",
    "& Button": {
      width: "22%",
      display: "flex",
      justifyContent: "flex-start",
      background: "#FFF7E4",
      color: "black",
      borderRadius: "10px",
      padding: "10px",
      "&:hover": {
        background:
          "linear-gradient(to right, rgba(225,128,8,0.3400000035762787), rgba(255,200,55,0.3400000035762787))",
        color: "black",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  chooseusbtnsectionimg: {
    background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    width: "56px",
    height: "56px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "1rem",
  },
  chooseuscontentinner: {
    "& .MuiTypography-h3": {
      color: "#303030",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "1.4rem",
      fontStyle: "normal",
      fontWeight: "700",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h5": {
      color: "#303030",
      fontFamily: "Playfair Display",
      fontSize: "1.1rem",
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "capitalize",
    },
  },
}));
function ChooseUsSectionContent() {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.chooseusmain}>
          <div className={classes.chooseusimgcont}>
            <img
              src={ChooseUsSectionVector}
              alt="img"
              width="100%"
              height="100%"
            />
          </div>
          <div className={classes.chooseuscontent}>
            <div className={classes.chooseuscontentinner}>
              <Typography
                className={classes.desktoph4}
                variant="h4"
                gutterBottom
              >
                We innovate learning process
              </Typography>
              <Typography
                className={classes.desktoph4}
                variant="h4"
                gutterBottom
              >
                Streamline learning. Elevate performance.
              </Typography>
              <Typography
                className={classes.mobileh4}
                variant="h3"
                gutterBottom
              >
                Innovate, Streamline, Elevate
              </Typography>
              <Typography
                className={classes.mobileh4}
                variant="h5"
                gutterBottom
              >
                Transforming the Learning Journe
              </Typography>
              <Typography
                className={classes.chooseusp}
                variant="p"
                display="block"
                gutterBottom
              >
                "Introducing Parikshado – Your Complete Learning Companion! With
                Parikshado, embrace a comprehensive Learning Management System
                (LMS) offering a lot of tools tailored for your success. Whether
                you’re an individual learner or a coaching institute
                facilitating to a small group or a large classroom, our platform
                is designed to meet your unique needs. What sets us apart?
              </Typography>
              <Typography
                variant="p"
                className={classes.chooseusp}
                display="block"
                gutterBottom
              >
                Empower your learning journey with Parikshado. Unleash your
                potential, achieve your goals, and excel in your academic
                pursuits effortlessly.
              </Typography>
              <Typography
                variant="p"
                className={classes.chooseuspmobile}
                display="block"
                gutterBottom
              >
                Empower your learning journey with Parikshado. Unleash your
                potential, achieve your goals, and excel in your academic
                pursuits effortlessly.
              </Typography>
              <Typography
                variant="p"
                className={classes.chooseuspred}
                display="block"
                gutterBottom
              >
                Join us and revolutionize the way you learn today!"
              </Typography>
            </div>
            <div className={classes.chooseusbuttons}>
              <Button
                onClick={() => navigate("/aboutus")}
                variant="contained"
                className={classes.bluecustombtn}
              >
                Getting Started
              </Button>
            </div>
            <div className={classes.chooseusbuttonsmobile}>
              <Button
                onClick={() => navigate("/aboutus")}
                variant="contained"
                className={classes.bluecustombtn}
                fullWidth
              >
                KNOW MORE
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.chooseusbtnsection}>
          <Button>
            <div className={classes.chooseusbtnsectionimg}>
              <img src={btnvector2} alt="img" height="50%" />
            </div>
            80k+ Online Courses
          </Button>
          <Button>
            <div className={classes.chooseusbtnsectionimg}>
              <img src={btnvector4} alt="img" height="50%" />
            </div>
            Recorded Videos
          </Button>
          <Button>
            <div className={classes.chooseusbtnsectionimg}>
              <img src={btnvector3} alt="img" height="50%" />
            </div>
            Expert Mentors
          </Button>
          <Button>
            <div className={classes.chooseusbtnsectionimg}>
              <img src={btnvector} alt="img" height="50%" />
            </div>
            Lifetime Access
          </Button>
        </div>
      </div>
    </>
  );
}
export default ChooseUsSectionContent;
