import { Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import BannerLogo from "../image/testseriesvector/bannerlogo.png";
import { ReactComponent as CartIcon } from "../image/testseriesvector/carticon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    background: "#FFEFCB",
    margin: "1rem 0",
    padding: "0rem 1rem",
    "& .MuiTypography-h6": {
      fontSize: "2rem !important",
      fontFamily: "Playfair Display !important",
      fontWeight: 700,
      lineHeight: "2.5rem",
      marginTop: "2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem !important",
        lineHeight: "2rem",
        fontWeight: 600,
        textAlign: "center",
        marginTop: "1rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  textandbuttoncontainer: {
    width: "56%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  button: {
    background: "#FFF",
    marginTop: "1rem",
    boxShadow: "0px 6px 25px rgba(0, 0, 0, 0.06)",
    width: "26%",
    "& .MuiButton-label": {
      fontSize: "0.9375rem !important",
      fontFamily: "Jost !important",
      fontWeight: 500,
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "black",
      textDecoration: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "45%",
      margin: "1rem 0 1rem 5rem",
    },
  },
  logo: {
    marginTop: "0.6rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      "& img": {
        marginTop: "1rem",
        width: "180px",
      },
    },
  },
}));

function TestSeriesBanner() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={BannerLogo} alt="img" width="250px" />
      </div>
      <div className={classes.textandbuttoncontainer}>
        <Typography variant="h6">
          Test Series With 30k+ Mock Test For Competitive Exams
        </Typography>
        <Button className={classes.button}>
          <CartIcon style={{ marginRight: "0.4rem" }} /> Buy More
        </Button>
      </div>
    </div>
  );
}

export default TestSeriesBanner;
