import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  savedaddress: {
    "& .MuiTypography-h4": {
      color: "#1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9rem",
      },
    },
    "& .MuiTypography-h5": {
      color: "#797979",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
  },
}));
function CheckoutPageContent({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.savedaddress}>
      <Typography variant="h4" gutterBottom> {data.address2}, {data.address3}</Typography>
      <Typography variant="h5"> {data.city}, {data.state}</Typography>
      <Typography variant="h5"> {data.pin_code}</Typography>
    </div>
  );
}

export default CheckoutPageContent;
