import React, { useState } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import speakerwithman from "../../image/dashboard/referAndEarn/speakerwithman.png";
import staricon from "../../image/dashboard/referAndEarn/staricon.png";
import mobilewithman from "../../image/dashboard/referAndEarn/mobilewithman.png";
import speakerwithmobile from "../../image/dashboard/referAndEarn/speakerwithmobile.png";
import ReusableModal from "../../rps/reuseablemodal";
import Studentreferlink from "../../allpopups/studentreferlink";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    background:"#FFF",
    padding:"1rem 1.5rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    "& .MuiTypography-h6": {
      color: "#243B55",
      fontFamily: "Playfair Display",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: " 2.625rem",
    },
    "& .MuiTypography-h5": {
      color: "#454545",
      fontFamily: "Jost",
      fontSize: "0.9375rem",
      fontWeight: "400",
      marginTop: "0.5rem",
    },
    "& .MuiTypography-h4": {
      marginTop: "1rem",
      color: "#454545",

      fontFamily: "Jost",
      fontSize: "0.9375rem",
      fontWeight: "400",
    },
    "& .MuiTypography-caption": {
      color: "#243B55",

      fontFamily: "Playfair Display",
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    "& .MuiTypography-body1": {
      color: "#243B55",

      fontFamily: "Playfair Display",
      fontSize: "1.5rem",
      fontWeight: 700,
    },
  },

  custombtn: {
    textAlign: "center",
    marginTop: "2rem",
    fontFamily: "Jost",
    fontSize: "14px",
    fontWeight: "400",
    width: "30%",
    background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
    color: "#252525",
    padding: "0.6rem 1rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif !important",
      fontWeight: "400",
      color: "#303030",
    },
  },
  root2: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  headTwo: {
    color: "#4F4F4F",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: 400,
    marginTop: "1rem",
    marginLeft: "2.5rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.1rem",
    },
  },
  root3: {
    marginTop: "3rem",
  },
  mainroot: {
    marginTop:"1rem",
    padding: "1.5rem",
    background:"#FFF"
  },
  root3One: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  rootside1: {
    width: "30%",
  },
  rootside2: {
    width: "70%",
  },
  step: {
    color: "#454545",

    fontFamily: "Jost",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "600",
  },
  heading: {
    color: "#4F4F4F",
    fontSize: "0.875rem",
    fontWeight: 400,
    marginTop: "0.5rem",
  },
}));

const ReferAndEarnCard1 = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <div>
          <img src={speakerwithman} alt="img" />
        </div>
        <div>
          <Typography variant="h6">
            Refer a Friend - Earn Referral Rewards
          </Typography>
          <Typography variant="h5">
            Receive up to ₹10,000 on Each Friend You Successfully Refer
          </Typography>
          <Typography variant="h4">
            <img src={staricon} alt="img" width="2%" />
            &nbsp;&nbsp; Earn 50% reward in advance once referee registers for a
            course *
          </Typography>
          <Typography variant="h4">
            <img src={staricon} alt="img" width="2%" />
            &nbsp;&nbsp;Not ParikshaDo student, we’ve got you covered with easy
            voucher payout *
          </Typography>

          <Button className={classes.custombtn} onClick={handleModalOpen}>
            Refer Now
          </Button>
          <ReusableModal open={isModalOpen} onClose={handleModalClose}>
            <Studentreferlink handleOpenclose={handleModalClose} />
          </ReusableModal>
        </div>
      </div>
      <div className={classes.mainroot}>
        <div className={classes.root2}>
          <div>
            <Typography variant="h6">
              Q.1) What Exactly is “Refer a Friend” by ParikshaDo?
            </Typography>
            <Typography className={classes.headTwo}>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries,
            </Typography>
          </div>
          <div>
            <img src={mobilewithman} alt="img" />
          </div>
        </div>
        <div className={classes.root3}>
          <Typography variant="h6">
            Q.2 ) How Does 'Refer a Friend' Work?
          </Typography>
          <Typography className={classes.headTwo}>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley
          </Typography>
          <div className={classes.root3One}>
            <div className={classes.rootside1}>
              <img src={speakerwithmobile} alt="img" />
            </div>
            <div className={classes.rootside2}>
              <Typography className={classes.heading}>
                <span className={classes.step}>STEP 1:</span> Log in to your
                Amazon Seller Central account to access the Refer a Friend page
                under Growth tab on Seller Central Home Page.
              </Typography>
              <Typography className={classes.heading}>
                <span className={classes.step}>STEP 2:</span> Log in to your
                Amazon Seller Central account to access the Refer a Friend page
                under Growth tab on Seller Central Home Page.
              </Typography>
              <Typography className={classes.heading}>
                <span className={classes.step}>STEP 3:</span> Log in to your
                Amazon Seller Central account to access the Refer a Friend page
                under Growth tab on Seller Central Home Page.
              </Typography>
              <Typography className={classes.heading}>
                <span className={classes.step}>STEP 4:</span> Log in to your
                Amazon Seller Central account to access the Refer a Friend page
                under Growth tab on Seller Central Home Page.
              </Typography>
              <Typography className={classes.heading}>
                <span className={classes.step}>STEP 5:</span> Log in to your
                Amazon Seller Central account to access the Refer a Friend page
                under Growth tab on Seller Central Home Page.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.root3}>
          <Typography variant="h6">
            Q.3 ) What Rewards can you Expect from the “Refer a Friend”
            Promotion?
          </Typography>
          <Typography className={classes.headTwo}>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley of
            type and scrambled it to make a type specimen book. It has survived
            not only five centuries,Lorem Ipsum has been the industry's standard
            dummy text ever since the 1500s, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries,
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ReferAndEarnCard1;
