import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import TechEnhanced from "./techenhanced";
import OnscreenMarking from "./onscreenmarking";
import EvaluationCard from "./evaluationcard";
 import WorkSection from "./worksection";
 import ContactSection from "../biometric/contactsection";


const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
function DigitalEvaluation() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Digital Evaluation ",
      headingmain: "Digital Evaluation",
      breadcrumb: "Digital Evaluation",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <TechEnhanced/>
      <OnscreenMarking/>
      <EvaluationCard/>
      <WorkSection/>
      <ContactSection  />
    </div>
  );
}
export default DigitalEvaluation;
