
import { useContext, useEffect, useState, useRef } from "react";
import practiceContext from "../context/PypPracticeContext";

const useCountdown = (millisecond) => {
  const [countDown, setCountDown] = useState(millisecond);
  const [timerEnd, setTimerEnd] = useState(false);
  const { quizQuestionObj } = useContext(practiceContext);
  const initialDurationRef = useRef(millisecond);

  useEffect(() => {
    if (!initialDurationRef.current) {
      // Initial setup
      initialDurationRef.current = millisecond;
      setCountDown(initialDurationRef.current);
    }

    if (countDown > 0) {
      const interval = setInterval(() => {
        setCountDown((prevCountDown) => {
          if (prevCountDown > 0) {
            return prevCountDown - 1000;
          } else {
            clearInterval(interval);
            setTimerEnd(true);
            return 0;
          }
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [countDown, millisecond]);

  return [countDown, timerEnd, setTimerEnd];
};

export default useCountdown;
