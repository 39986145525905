import { makeStyles } from "@material-ui/core";
import React from "react";
import PageHead from "../pageheading/pageheading";
import FilterSection from "./FilterSection";
import CoachingContent from "./CoachingContent.js";
import CoachingsData from "./CoachingsData";
import { useState } from "react";
import Pagination from "./Pagination";

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    
    background: "#FFFBF1",
    paddingBottom: "1rem",
    marginBottom:"15rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "14rem",
    },
  },
}));

function Coachings() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Study",
      headingmain: "Coachings",
      breadcrumb: "Links / Coachings",
    },
  ];
  
  // const [paginationCurrentItems, setPaginationCurrentItems] = useState();
  
  const [filteredDataByCoachings, setFilteredDataByCoachings] = useState(CoachingsData);
  const [selectedExam, setSelectedExam] = useState(-1);
  const [selectedCity, setSelectedCity] = useState(-1);
  console.log("selectedCity >>>", selectedCity)
  // console.log("current >>>", paginationCurrentItems);  
  console.log("filter by teacher >>", filteredDataByCoachings);

  // const handleCurrentItems = (currentItems) => {
  //   setPaginationCurrentItems(currentItems);
  // };

  const handleSearchCoaching = (searchTerm) => {       //serchTerm----->data of search input
    const filtered = CoachingsData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDataByCoachings(filtered);
  }; 
  
  const handleSelectExamAndCity = (exam,city) => {
    setSelectedExam(exam);
    setSelectedCity(city);
  }

  return (
    <div className={classes.maincontainer}>
      <PageHead Heading={Heading} />
      <FilterSection onSearchCoaching={handleSearchCoaching} handleSelectExamAndCity={handleSelectExamAndCity} data={CoachingsData}/>
      <CoachingContent filteredDataByCoachings={filteredDataByCoachings} selectedExam={selectedExam} selectedCity={selectedCity} />
      {/* <Pagination handleCurrentItems={handleCurrentItems} data={CoachingsData}/> */}
    </div>
  );
}

export default Coachings;
