import React from 'react'
import HeaderNotification from './headerNotification';
import { Card, CardContent, makeStyles } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: "275",
        padding: "auto",
        marginBottom: "20rem",
    },
    icon: {
        color: "orange",
        width: "20%"
    }

}));

const Eligible = [
    {
        id: 1,
        subCategories: "Candiate Should Passes 10+2"
    },



];

const Qualification = ({Notification1}) => {
    const classes = useStyles();
    const value = "Qualification"
    // const description = Notification1?.requirements?.[2]?.description;
    // const heading = Notification1?.requirements?.[2]?.heading;
    
    return (
        <>
            <Card classname={classes.card}>
                <HeaderNotification data={value} />
                <CardContent>
                    <div className={classes.examslist}>
                        <List component="nav" aria-label="secondary mailbox folders">
                            {

                                Eligible.map((data, index) => {

                                    return (
                                        <ListItem key={index} button selected={""}>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon className={classes.icon} />

                                            </ListItemIcon>
                                            <ListItemText primary={data.subCategories} />

                                        </ListItem>
                                    );
                                })
                            }

                        </List>
                    </div>


                </CardContent>

            </Card>


        </>
    )
}

export default Qualification
