const verticalLines = [
    "Advertisement",
    "Design And Publication",
    "Application Scrutiny",
    "Roll Number Generation",
    "Question Bank",
    "Test Center",
  ];

  const verticalLinesRight = [
    "Printing Of Question Paper",
    "Invigilation / Proctoring Services",
    "Result Processing",
    "Conduct Of Online Examination",
    "Mock Trial & Penetration",
    "RTI Support",
  ];
  export {verticalLines,verticalLinesRight}