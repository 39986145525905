import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as SidebarHeadIcon } from "../image/quizzesvector/sidebarheadicon.svg";
import { ReactComponent as Arrow } from "../image/quizzesvector/arrow.svg";
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import appContext from "../../context/AppContext"
import ConstantPracticeData from "./constant";

const useStyles = makeStyles((theme) => ({
    examscontainer: {
        width: "100%",
        height: "421px",
    },
    examshead: {
        display: "flex",
        background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
        padding: "0.4rem 1rem",
        borderRadius: '0.3rem',
        "& .MuiTypography-h6": {
            fontFamily: "Playfair Display",
            color: "#FFFFFF",
            fontSize: "1.125rem",
            marginLeft: "0.5rem",
        },
    },
    examslist: {
        background: "linear-gradient(270deg, #FFF4D7 0%, #FEE5CD 100%)",
        display: "inline-block",
        width: "inherit",
        height: "inherit",
        overflow: 'auto',
        borderRadius: '0.3rem',
        padding: '0.5rem 0',
        // lineHeight: "50px",
        "& .MuiListItemIcon-root": {
            // paddingLeft: "2.5rem",
            minWidth: '0',
        },
        "& .MuiTypography-body1": {
            textTransform: "capitalize",
            fontSize: '0.875rem',
        },
        "& .Mui-selected": {
            background: "rgba(255, 255, 255, 1)",
            boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
        },
        "& .MuiList-padding": {
            padding: '0rem 1rem'
        },
        '& .MuiListItem-root': {

        },
    },
}));

function Exams(props) {
  const classes = useStyles();
  const { ExamData, quiz, setQuiz, practicepathname } = props;
  const AppContext = useContext(appContext);
  console.log('getCategoryExam' , ExamData )
  return (
    
    <div className={classes.examscontainer}>
      <div className={classes.examshead}>
        <div>
          <SidebarHeadIcon />
        </div>
        <Typography variant="h6">
          {practicepathname === "quizzes" || practicepathname === "notes"
            ? "Sections"
            : "Exams"}
        </Typography>
      </div>
      <div className={classes.examslist}>
        <List component="nav" aria-label="secondary mailbox folders">
          {ExamData?.map((row) => {
            return row.categories?.map((categories) =>
              categories?.subCategories?.map((subCategories) => {
                return (
                  <>
                    {(quiz?.categories === -1 ||
                      (quiz?.categories === categories?.id &&
                        quiz?.subCategories === subCategories?.id)) &&
                      subCategories?.[
                        ConstantPracticeData?.[practicepathname]?.level3
                      ]?.map((exams, i) => {
                        
                        if (
                          exams?.[
                            ConstantPracticeData?.[practicepathname]?.level3Name
                          ] === "section_name"
                        ) {
                          return (
                            <ListItem
                              button
                              selected={
                                quiz?.examName ===
                                exams?.[
                                  ConstantPracticeData?.[practicepathname]
                                    ?.level3Name
                                ]
                              }
                              onClick={() => {
                                console.log("Clicked! (No setCourseData)");

                                setQuiz({
                                  ...quiz,
                                  categoryName: categories?.category_name,
                                  categories: categories?.id,
                                  subCategories: subCategories?.id,
                                  exams: exams?.id,
                                  examName:
                                    exams?.[
                                      ConstantPracticeData?.[practicepathname]
                                        ?.level3Name
                                    ],
                                });

                                console.log("Updated quiz:", quiz);
                              }}
                            >
                              <ListItemText
                                primary={
                                  exams?.[
                                    ConstantPracticeData?.[practicepathname]
                                      ?.level3Name
                                  ]
                                }
                              />
                              <ListItemIcon>
                                <Arrow />
                              </ListItemIcon>
                            </ListItem>
                          );
                        }

                        return (
                          <ListItem
                            button
                            selected={
                              quiz?.examName ===
                              exams?.[
                                ConstantPracticeData?.[practicepathname]
                                  ?.level3Name
                              ]
                            }
                            onClick={() => {
                              console.log("Clicked! (With setCourseData)");

                              setQuiz({
                                ...quiz,
                                categoryName: categories?.category_name,
                                categories: categories?.id,
                                subCategories: subCategories?.id,
                                exams: exams?.id,
                                examName:
                                  exams?.[
                                    ConstantPracticeData?.[practicepathname]
                                      ?.level3Name
                                  ],
                              });

                              if (
                                ConstantPracticeData?.[practicepathname]
                                  ?.level3Name !== "section_name"
                              ) {
                                AppContext.setCourseData({
                                  category: categories?.id,
                                  categoryName: categories?.category_name,
                                  subcategory: subCategories?.id,
                                  exam: exams?.id,
                                  exam_name:
                                    exams?.[
                                      ConstantPracticeData?.[practicepathname]
                                        ?.level3Name
                                    ],
                                });

                                // Log the updated AppContext data
                                console.log(
                                  "Updated AppContext data:",
                                  AppContext
                                );
                              }
                            }}
                          >
                            <ListItemText
                              primary={
                                exams?.[
                                  ConstantPracticeData?.[practicepathname]
                                    ?.level3Name
                                ]
                              }
                            />
                            <ListItemIcon>
                              <Arrow />
                            </ListItemIcon>
                          </ListItem>
                        );
                      })}
                  </>
                );
              })
            );
          })}
        </List>
      </div>
    </div>
  );
}

export default Exams;