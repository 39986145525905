import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  InputAdornment,
  Card,
} from "@material-ui/core";
import practiceContext from "../context/PypPracticeContext";

const useStyles = makeStyles((theme) => ({
  questionOptionsContainer: {
    padding: "0.5rem 1rem",
    boxSizing: "border-box",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    height: "67vh",
    overflow: "scroll",
    "&::-webkit-scrollbar ": {
      display: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(225, 225, 225, 1)",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(225, 225, 225, 1)",
    },
  },
  question: {
    marginBottom: "20px",
    fontWeight: "700",
    lineHeight: "30px",
    fontFamily: "'Jost', sans-serif",
    fontSize: "20px",
  },
  paragraph: {
    fontSize: "14px",
    lineHeight: "1.5",
  },
  options: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    "& p": {
      margin: "0",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  optionHeading: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "20px",
    color: "rgba(114, 114, 114, 1)",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    flex: 1,
  },
  inputField: {
    marginBottom: "10px",
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.06)",
    padding: "0.1rem 0.5rem",
    borderRadius: "0.375rem",
  },
  placeholder: {
    width: "10%",
    color: "rgba(0, 0, 0, 1)",
    fontSize: "22px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
  },
  icon: {
    width: "20px",
    height: "20px",
    backgroundColor: "lightgray",
    borderRadius: "4px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  horizontalLine: {
    borderTop: "1px solid rgba(234, 234, 234, 1)",
    margin: "20px 0",
  },
  squareBox: {
    padding: "0.5rem 0.8rem",
    backgroundColor: "rgba(241, 241, 241, 1)",
    fontWeight: "500",
    color: "rgba(33, 33, 33, 1)",
    fontSize: "18px",
    borderRadius: "4px",
    fontFamily: "'Jost', sans-serif",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9375rem",
      padding: "0.3rem 0.6rem",
    },
  },
  errorOutline: {
    border: "1px solid #FFE064",
    color: "#FFE064",
  },

  squareBoxRed: {
    background: "linear-gradient(135deg, #FFCC00 0%, #FF8008 100%)",
  },
  paraHeading: {
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    fontSize: "22px",
  },
  optionText: {
    fontSize: "16px",
    fontFamily: "Jost",
    fontWeight: "400",
    '& p':{
      margin:'0',
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9375rem",
    },
  },
  orangeText: {
    color: "#FF8008",
  },
}));

function QuestionAndOptions({ selectedLanguage }) {
  const classes = useStyles();
  const { quizQuestionObj, currentQuestion, currentSection, setAnswer } =
    useContext(practiceContext);
  const optionArray = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
  ];

  // Choose the appropriate question text based on the selected language
  const questionText =
    selectedLanguage === "english"
      ? quizQuestionObj?.sections[currentSection]?.questions[
          currentQuestion - 1
        ]?.question_en
      : quizQuestionObj?.sections[currentSection]?.questions[
          currentQuestion - 1
        ]?.question_hi;

  // Choose the appropriate options based on the selected language
  const optionsArray =
    selectedLanguage === "english"
      ? quizQuestionObj?.sections[currentSection].questions[currentQuestion - 1]?.options
      : quizQuestionObj?.sections[currentSection].questions[currentQuestion - 1]?.options

  // Count the options that have a description
  const optionCount =
  optionsArray?.filter((option) => optionsArray).length || 0;

  return (
    <Card>
      <div className={classes.questionOptionsContainer}>
        <div
          className={classes.options}
          dangerouslySetInnerHTML={{ __html: questionText }}
        />
        <div className={classes.inputContainer}>
          {optionArray.slice(0, optionCount).map((option, index) => (
            <TextField
              key={option}
              className={`${classes.inputField} ${
                quizQuestionObj?.sections[currentSection]?.questions[
                  currentQuestion - 1
                ]?.options?.[index]?.user_answer && classes.errorOutline
              }`}
              fullWidth
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <div
                        className={`${classes.squareBox} ${
                          quizQuestionObj?.sections[currentSection]?.questions[
                            currentQuestion - 1
                          ]?.options?.[index]?.user_answer &&
                          classes.squareBoxRed
                        }`}
                      >
                        {option}
                      </div>
                    </InputAdornment>
                    <div
                      className={`${classes.optionText} ${
                        quizQuestionObj?.sections[currentSection]?.questions[
                          currentQuestion - 1
                        ]?.options?.[index]?.user_answer && classes.orangeText
                      }`}
                      dangerouslySetInnerHTML={{
                        __html:  selectedLanguage === "hindi"
                            ?  quizQuestionObj?.sections[currentSection].questions[currentQuestion - 1].options?.[index]?.option_hi
                            :  quizQuestionObj?.sections[currentSection].questions[currentQuestion - 1].options?.[index]?.option_en,
                      }}
                    />
                  </>
                ),
                classes: { input: classes.placeholder },
                disableUnderline: true,
              }}
              onClick={() => {
                setAnswer(index);
              }}
              inputProps={{
                readOnly: true,
                onFocus: (event) => event.target.blur(),
              }}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}

export default QuestionAndOptions;
