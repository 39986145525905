import { Box, Button, Card, CardActions, CardContent, Checkbox, Divider, FormControlLabel, IconButton, Typography, makeStyles } from '@material-ui/core';
import React from 'react'
import SSCLogo from "../../image/latestseriesvector/SSCCGLlogomobie.png";
import { ReactComponent as StarIcon } from "../../image/ebooksvector/starmobilesvg.svg";
import { ReactComponent as FavoriteChecked } from "../../image/ebooksvector/favoritechecked.svg";
import { ReactComponent as FavoriteUnchecked } from "../../image/ebooksvector/favoriteunchecked.svg";
import { ReactComponent as CartBagIcon } from "../../image/ebooksvector/cartbagicon.svg";
import { Rating } from "@material-ui/lab";
import BookImg from "../../image/ebooksvector/bookimagemobile.png";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import Downloadicon from "../../image/practice/downloadicon.png";
import QuestionIcon from "../../image/practice/question.png";
import { useState } from 'react';
import FavouriteButton from '../../practicecomponent/FavouriteButton';
import { useNavigate } from 'react-router-dom';
import BuyNowButton from '../../practicecomponent/BuyNowButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.6rem",
    display: "flex",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "0",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.725rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.6rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body2": {
      margin: "0.2rem 0",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.6rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.5rem",
      textTransform: "uppercase",
      background: "#00A911",
      opacity: "0.6",
      color: "white",
      padding: "0.2rem 1rem",
      borderRadius: "15px",
      // marginLeft: "0.6rem",
    },
    "& .MuiRating-root": {
      fontSize: "1rem",
    },
    "& .MuiCardActions-root": {
      borderRadius: 6,
      padding: "0.2rem",
      "& .MuiButton-label": {
        fontFamily: "Jost",
        fontSize: "0.7rem",
        alignItems: "flex-start",
      },
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "92%",
      padding: "0",
      margin: "0",
    },
    "& .MuiCardContent-root": {
      padding: "6px",
    },
    [theme.breakpoints.down("xs")]: {
      margin:'20px 0.6rem',
      // padding: '1rem 1rem',
    },
  },
  cardimagecontainer: {
    background: "#FFE8B5",
    padding: "0.5rem",
    borderRadius: "6px",
    border: "1px solid #E0E0E0",
    position: "relative",
    "& img": {
      marginTop: "0.5rem",
    },
  },
  ratingtag: {
    display: "flex",
    position: "absolute",
    left: "0",
    top: "4px",
    padding: "0.2rem 0.6rem",
    background: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    borderRadius: "0 8px 8px 0",
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.5625rem",
      marginLeft: "0.3rem",
    },
  },
  favoritebtn: {
    // width: "14%",
    position: "absolute",
    top: "0px",
    left: "69px",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
    "& svg": {
      width: "25px",
      height: "25px",
    },
    "& .MuiIconButton-root": {
      width: "60%",
      padding: "0 2px",
    },
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.125rem",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "600",
    marginRight: "0.3rem",
    // marginTop: "0.8rem",
  },
  offertext: {
    marginLeft: "0.5rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.6rem",
    fontWeight: "400",
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  cartbagicon: {
    marginRight: "0.4rem",
  },
}));

function EBookCardMobileDash(props) {
  const { Data } = props;
  const classes = useStyles();
  const [isCheckedHeart,setIsCheckedHeart] = useState(false)
  const navigate = useNavigate()

  return (
    <Card className={classes.root} onClick={(e)=>{e.stopPropagation();navigate(`/ebook/${Data?.id}`)}}>
      <div className={classes.cardimagecontainer}>
        <div className={classes.ratingtag}>
          <StarIcon />
          <Typography variant="h6">{Data?.rating}</Typography>
        </div>
        <div className={classes.favoritebtn}>
        <FavouriteButton isCheckedHeart={isCheckedHeart} data={Data} setIsCheckedHeart={setIsCheckedHeart} />
        </div>
        <div>
        <img src={ Data?.image_url || require("../../image/notFoundData.png")} alt='image' width={"80px"} />
        </div>
      </div>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {Data?.ebook_name}
         {/* ({data?.sub_heading}) */}
        </Typography>
        <Typography variant="caption" display="block">
        by {Data?.author}
        </Typography>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Rating name="read-only" value={4} readOnly />
          <Typography variant="h6">4.3</Typography>
          <Typography variant="caption">(634)</Typography>
          <Typography variant="h2">Latest</Typography>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
        >
          <span className={classes.textred}>
            <span style={{ fontSize: "0.7rem", verticalAlign: "top" }}>
              ₹
            </span>
            {Data?.selling_cost}
          </span>
          <Typography variant="caption">
            MRP : <del>{Data?.mrp}</del>
          </Typography>
          <span className={classes.offertext}>({Data?.discount_percent}% off)</span>
        </Typography>
        <CardActions className={classes.bgorange}>
          <BuyNowButton data={Data} />
        </CardActions>
      </CardContent>
    </Card>
  )
}

export default EBookCardMobileDash;