import {
  Backdrop,
  Button,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { ReactComponent as TableHeadIcon } from "../../image/dashboard/tableheadvector.svg";
import { ReactComponent as ResumeIcon } from "../../image/dashboard/resumeicon.svg";
import CustomTableView from "../../CustomComponent/TableView";
import CardForTable from "../CardForTable";
import CardForStudyTable from "./CardForStudyTable";
import { getNotesPdf } from "../../../service/services";
import PdfViewer from "./PdfViewer";
import NoDataFound from "../ErrorPage/NoDataFound";
import ReplayIcon from "@material-ui/icons/Replay";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "84vh",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.5rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",

    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  btn: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.5rem",
      border: "0",
      background: "#243B55",
      borderRadius: "0",
      padding: "0.6rem 1.5rem",
      "& .MuiButton-root": {
        borderRadius: "0.4375rem",
        border: "1px solid #fff",
      },
    },
    "& .MuiButton-label": {
      fontFamily: "Playfair Display !important",
      fontSize: "0.875rem",
      fontWeight: 600,
      color: "#252525",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        color: "#FFF",
        fontSize: "0.9375rem",
        fontWeight: 600,
      },
    },
  },
  table: {
    minWidth: 700,
  },
  completedbtn: {
    "&.MuiButton-root": {
      border: "1px solid #00A019",
      color: "#00A019",
      textTransform: "capitalize",
      padding: "0.2rem 0.6rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "600",
      fontSize: "0.875rem",
    },
  },
  startbtn: {
    "&.MuiButton-root": {
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
    "&.MuiIconButton-root": {
      background: "#243B55",
      color: "#FFF",
      padding: "0.4rem",
      marginLeft: "0.5rem",
      borderRadius: "0.375rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      color: "#000",
      textTransform: "capitalize",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function NotesInner({setOnViewClicked , data}) {
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 650px)");

  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [lastPage, setLastPage] = useState("");

  const handleOpenclose = (url, rowId, lastPageNo) => {
    setOpen(!open);
    setPdfUrl(url);
    setSelectedRowId(rowId);
    setLastPage(lastPageNo);
  };


  console.log("data1fdvv" , data);
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getNotesPdf(token, data.id , data.type);
        console.log("API Response:", response.data);
        setNotes(response.data?.note);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [data, open]);
  const rows = Array.isArray(notes?.note_contents)
    ? notes?.note_contents?.map((data, index) => ({
        id: data?.id,
        srno: index + 1,
        name: data?.content_heading,
        file_format: data?.file_format,
        content_path: data?.content_path,
        lastPage: data?.last_page,
        status: data?.status,
      }))
    : [];

  const columns = [
    {
      field: "srno",
      headerName: "Sr. No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 100,
      // flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 700,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderHeader: (params) => (
        <div>
          <TableHeadIcon style={{ position: "relative", top: "0.2rem" }} />
          &nbsp;&nbsp;Notes Type
        </div>
      ),
    },

    {
      field: "action",
      headerName: `Completed : (0 / ${rows.length})`,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,

      renderCell: (cellValues) => {
        return cellValues.row.status === "completed" ? (
          <div>
            <Button disabled className={classes.completedbtn}>
              Completed
            </Button>
            <IconButton
              className={classes.startbtn}
              onClick={() =>
                handleOpenclose(
                  cellValues.row.content_path,
                  cellValues.row.id,
                  cellValues.row.lastPage
                )
              }
            >
              <Tooltip id="button-info" title="Start Over">
                <ReplayIcon style={{ fontSize: "large" }} />
              </Tooltip>
            </IconButton>
          </div>
        ) : cellValues.row.lastPage > 1 ? (
          <Button
            className={classes.startbtn}
            onClick={() => {
              handleOpenclose(
                cellValues.row.content_path,
                cellValues.row.id,
                cellValues.row.lastPage
              );
            }}
          >
            Resume
          </Button>
        ) : cellValues.row.file_format === "pdf" ? (
          <Button
            className={classes.startbtn}
            onClick={() => {
              handleOpenclose(
                cellValues.row.content_path,
                cellValues.row.id,
                cellValues.row.lastPage
              );
            }}
          >
            Start
          </Button>
        ) : (
          <Button
            className={classes.startbtn}
            component="a"
            href={cellValues.row.content_path}
            target="_blank"
            download={false}
          >
            Start
          </Button>
        );
      },
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <IconButton
            onClick={() => {
              setOnViewClicked("");
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h3">
            {data?.note_name}
            {notes?.note_name}
          </Typography>
        </div>
      </div>
      {!mql.matches ? (
        <CustomTableView
          columns={columns}
          rows={rows || []}
          section={"ebooksTable"}
        />
      ) : rows.length !== 0 ? (
        rows.map((data, index) => (
          <CardForStudyTable data={data} handleOpenclose={handleOpenclose} />
        ))
      ) : (
        <NoDataFound />
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleOpenclose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <PdfViewer
          selectedRowId={selectedRowId}
          pdfUrl={pdfUrl}
          handleOpenclose={handleOpenclose}
          lastPage={lastPage}
          noteId={data?.id}
          userType={data.type}
        />
      </Modal>
    </div>
  );
}

export default NotesInner;
