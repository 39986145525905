import React from "react";
import { Box, Button, IconButton, Typography, makeStyles } from "@material-ui/core";
import TeacherImage from "../../image/singlepagedescriptionvector/teacher.png";
import ProductThumnail from "../../image/singlepagedescriptionvector/productthumnail.png";
import { Rating, ToggleButton } from "@material-ui/lab";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import {ReactComponent as CheckIcon} from "../../image/singlepagedescriptionvector/checkicon1.svg";
import {ReactComponent as HexagonShape} from "../../image/singlepagedescriptionvector/hexagonalblankshape.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2.5rem 2rem",
    margin: "0 2rem",
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      background: "none",
      display: "block",
      padding: "1.5rem 1rem",
      margin: "1rem",
      background: "#FFFFFF",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
      borderRadius: "10px",
    },

    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "#FFFFFF",
    },
    "& .MuiToggleButton-root": {
      color: "#777777",
      background: "#FFFFFF",
      borderRadius: "40px",
      padding: "0.5rem",
      position: "absolute",
      right: "16px",
      top: "16px",
    },
    "& .MuiIconButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        
        fontSize: "0.875rem",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
    },
  },
  imagegallery: {
    width: "43%",
    borderRadius: "6px",
    position: "relative",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 126.1%)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
      padding: "0.1rem 0",
    },
  },
  currentimagecontainer: {
    // width:"100%",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-evenly",
      margin: "1rem 5rem",
    },
    border: "8px solid white",
    borderRadius: "8px",
    margin: "2rem 5rem",
    background: "linear-gradient(306deg, #FFE6CE 48.03%, #FFF4D7 53.6%)",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tag: {
    background: "#FF3939",
    position: "absolute",
    top: "2.9rem",
    left: "0rem",
    padding: "0 1.8rem",
    [theme.breakpoints.down("xs")]: {
      top: "2rem",
      left: "0rem",
      padding: "0 1rem",
    },
    "& .MuiTypography-caption": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
      color: "#FFFFFF",
      fontFamily: " Playfair Display",
      fontSize: "1.3rem",
    },
  },
  imagewrapper: {
    // marginLeft: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      // marginLeft: "2.4rem",
    },
  },
  image: {
    width: "290px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  productdetails: {
    width: "52%",
    marginLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
      width: "100%",
      marginTop: "2rem",
    },
    "& .MuiTypography-h3": {
      fontFamily: " Playfair Display",
      fontSize: "2rem",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiTypography-h4": {
      fontFamily: " Playfair Display",
      fontSize: "1.25rem",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  author: {
    background: "#FFEEC9",
    display: "inline-block",
    margin: "0.7rem 0",
    padding: "0.3rem 1rem",
    borderRadius: "3px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.9rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.875rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.77rem",
      },
    },
  },
  publisher: {
    "& .MuiTypography-caption": {
      color: "#777777",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
  },
  ratings: {
    display: "flex",
    justifyContent: "space-between",
    width: "33%",
    alignItems: "center",
    marginTop: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      width: "48%",
    },
    "& .MuiRating-root.Mui-disabled": {
      opacity: "1",
    },
    "& .MuiRating-root": {
      color: "#FFCC00",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.0625rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  partof: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.3rem",
    },
    "& .MuiTypography-caption": {
      color: "#202020",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.88rem",
      },
    },
  },
  pdfvideolinkstagconatiner: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    margin: "0.5rem 0", 
    [theme.breakpoints.down("xs")]: {
      width: "88%",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  hexagonshape: {
    position: "absolute",
    left: "-15px",
    [theme.breakpoints.down("xs")]: {
      left: "-18px",
    },
  },
  pdfvideolinkstag: {
    position: "relative",
    margin: "1rem 0",
    display: "flex",
    width: "30%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0rem 1rem 0rem 0.5rem",
    [theme.breakpoints.down("xs")]: {
      width: "36%",
      padding: "0.2rem",
      margin: "0.5rem 0",
      marginRight: "1rem",
      "& svg": {
        width: "57px",
      }
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
      cursor: "default",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#313866",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.25rem",
      fontWeight: "500",
      zIndex: "10",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  validitycontainer: {
    margin: "1rem 0",
    display: "flex",
    width: "38%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0rem 0.5rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.375rem",
      fontWeight: "600",
      cursor: "default",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.3125rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  validitycontainer_mobileview: {
    margin: "1rem 0",
    display: "none",
    width: "54%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0.2rem",
    margin: "0.5rem 0",
    "& svg": {
      width: "16px"
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.375rem",
      fontWeight: "600",
      cursor: "default",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.3125rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  subjectandlanguagecont: {
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      color: "#000",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#206FE5",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      marginLeft: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
        marginLeft: "0.5rem",
      },
    },
  },
  price: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    "& .MuiTypography-h3": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.7rem",
      fontWeight: "500",
      color: "#252525",
      width: "31%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
        width: "40%",
      },
    },
    "& .MuiTypography-caption": {
      color: "#626262",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      marginRight: "0.2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
  },
  mrpcontainer: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.6rem",
    },
  },
  mrp: {
    display: "flex",
    "& .MuiTypography-h3": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "2rem",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
    "& span": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        marginRight: "0.2rem",
      },
    },
  },
  offers: {
    display: "flex",
  },
  tax: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      alignItems: "baseline",
    },
    "& .MuiTypography-caption": {
      color: "#000000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
  },
  link: {
    textDecoration: "none",
    "& .MuiTypography-h6": {
      color: "#206FE5",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      marginLeft: "0.2rem",
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.675rem",
      },
    },
  },
  mrpandoffer: {
    width: "70%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  partneroffer: {
    border: "1px solid #FFBF35",
    padding: "0.3rem 1.2rem",
    borderRadius: "5px",
    background: "#FFF9ED",
    "& .MuiTypography-caption": {
      color: "#000000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  buttonscontainer: {
    width: "80%",
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "13px 53px",
    fontSize: "1rem",
      fontWeight: "400",
    "borderRadius": "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      width: "100%",
      padding: "12px 22px",
      marginRight: "0px",
      fontSize: "0.875rem",
    },
  },
  yellowcustombtn: {
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "#20324A",
    fontFamily: "'Jost', sans-serif",
    padding: "11px 63px",
    fontSize: "1rem",
    "borderRadius": "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      fontSize: "0.875rem",
      width: "100%",
      padding: "10px 34px",
      marginRight: "0px",
    },
  },
}));
function ProductDetails() {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(false);

  const Thumbnail = [
    {
      id: 1,
      img: ProductThumnail,
    },
    {
      id: 2,
      img: ProductThumnail,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.imagegallery}>
        <div className={classes.currentimagecontainer}>
          <div className={classes.tag}>
            <Typography variant="caption">Featured</Typography>
          </div>
          <div className={classes.imagewrapper}>
            <img className={classes.image} src={TeacherImage} alt="img" />
          </div>
          <ToggleButton
            value="check"
            selected={selected}
            onChange={() => {
              setSelected(!selected);
            }}
          >
            <FavoriteIcon />
          </ToggleButton>
        </div>
      </div>
      <div className={classes.productdetails}>
        <div className={classes.title}>
          <Typography variant="h3">Indian History</Typography>
          <Typography variant="h4">
            Highlights of Indian History
          </Typography>
        </div>
        <div className={classes.author}>
          <Typography variant="caption">By B-Krishna Reddy</Typography>
        </div>
        <div className={classes.pdfvideolinkstagconatiner}>
          <div className={classes.pdfvideolinkstag}>
            <HexagonShape className={classes.hexagonshape}/>
            <Typography variant="caption">10</Typography>
            <Typography variant="h6">PDF</Typography>
          </div>
          <div className={classes.pdfvideolinkstag}>
            <HexagonShape className={classes.hexagonshape}/>
            <Typography variant="caption">10</Typography>
            <Typography variant="h6">VIDEOS</Typography>
          </div>
          <div className={classes.pdfvideolinkstag}>
            <HexagonShape className={classes.hexagonshape}/>
            <Typography variant="caption">241</Typography>
            <Typography variant="h6">LINKS</Typography>
          </div>
          <div className={classes.validitycontainer_mobileview}>
            <CheckIcon />
            <Typography variant="h6">Validity : </Typography>
            <Typography variant="caption">6 Months</Typography>
          </div>
        </div>
        <div className={classes.subjectandlanguagecont}>
          <div>
            <Typography variant="h6" component="span">Subject:</Typography>
            <Typography variant="caption" component="span">Indian History</Typography>
          </div>
          <div>
            <Typography variant="h6" component="span">Language:</Typography>
            <Typography variant="caption" component="span">English</Typography>
          </div>
        </div>
        <div className={classes.price}>
          <Typography variant="h3">Special Price :</Typography>
          <div className={classes.mrpcontainer}>
            <div className={classes.mrpandoffer}>
              <div className={classes.mrp}>
                <span>&#8377;</span>
                <Typography variant="h3">50.00</Typography>
              </div>
              <div className={classes.offerssection}>
                <div className={classes.offers}>
                  <Typography variant="caption">
                    MRP: <del>&#8377;80.00</del>
                  </Typography>
                  <Typography variant="h6">(30% off)</Typography>
                </div>
              </div>
            </div>
            <div className={classes.tax}>
              <Typography variant="caption">Inclusive of all taxes.</Typography>
              <Link to={"#"} className={classes.link}>
                <Typography variant="h6">Details</Typography>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.validitycontainer}>
          <CheckIcon />
          <Typography variant="h6">Validity : </Typography>
          <Typography variant="caption">6 Months</Typography>
        </div>
        <div className={classes.buttonscontainer}>
          <div>
            <IconButton variant="contained" className={classes.bluecustombtn}>
              <ShoppingCartOutlinedIcon/> Add to cart
            </IconButton>
          </div>
          <div>
            <Button variant="contained" className={classes.yellowcustombtn}>
              Buy Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductDetails;
