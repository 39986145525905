import { Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react'
import SSCLogo from "../../image/latestseriesvector/SSCCGLlogo.png";
import LatestTestSeriesCardFree from './LatestTestSeriesCardFree';
import LatestTestSeriesCardMock from './LatestTestSeriesCardMock';
import LatestTestSeriesCardMockNew from './LatestTestSeriesCardMockNew';

const useStyles = makeStyles((theme) => ({
    
}));

function LatestTestSeriesCard({data , bundleId}) {

    const classes = useStyles();

    return (
        <>
            <LatestTestSeriesCardFree data={data} bundleId={bundleId}/>
        </>
    );
}

export default LatestTestSeriesCard