import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import JobNotification from "./jobNotification";
import DownloadApp from "./downloadApp";
import QuiklinksNotification from "./quiklinksNotification";
import CardsectionNotification from "./cardsectionNotification"
import ImportantDates from "./importantDates";
import ApplicationFee from "./applicationFee";
import AgeLimit from "./ageLimit";
import Qualification from "./qualification";
import VacancyDetails from "./vacancyDetails";
import ImportantLinks from "./importantLinks";
import QuikLinks from "../practicecomponent/quiklinks";
import QuikLinksData from "./quicklinksdata";
import {useState} from 'react'
import { getJobNotification } from "../../service/services";


const useStyles = makeStyles((theme) => ({
  pageheadingmain: {
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      position: "unset",
      padding: 0,
    },
  },
  pageheadingmaininner: {
    margin: "0 auto",
    paddingBottom: "1.5rem",
    paddingTop: "1rem",
    // backgroundImage: `url(${PageHeadingBg})`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem 1.5rem 1rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1.5625rem",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 6,
      },
    },
    "& .MuiBreadcrumbs-ol": {
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorInherit": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "#FF8F12",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
    },
  },
  pageheading: {
    padding: "1rem 0 2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
    },
  },
  pageheadhr: {
    margin: "0",
    width: "6rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  quizzcontentcontainer: {
    maxWidth: "1250px",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "15rem",
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "3.8rem",
    fontFamily: "'Playfair Display', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      fontFamily: "'Playfair Display', serif !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  w25: {
    width: "24%",
    [theme.breakpoints.down("xs")]: {
      display:'none'
    },
  },
  w74: {
    width: "74%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: '0rem 1rem',
    },
  },
}));









const AdmitCard = ({Notification1,value,setValue,a11yProps}) => {
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 650px)");
  let [notData,setNotData]=useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getJobNotification(); 
        console.log("API Response:", response.data);
        setNotData(response.data.notify);
        console.log(notData,"notdata")
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []); 

  return (
    <>
      
        <div className={classes.quizzcontentcontainer}>
          <div className={classes.w25}>
            <JobNotification Notification={notData}
            value={value} setValue={setValue}/>
            <DownloadApp />

            {/* <QuiklinksNotification /> */}
            {/* <DownloadApp /> */}

          </div>
          <div className={classes.w74}>
            <CardsectionNotification Notification1={Notification1}/>
            <ImportantDates Notification1={Notification1} />
            {/* <ApplicationFee Notification1={Notification1}/> */}
            {/* <AgeLimit Notification1={Notification1} /> */}
            {/* <Qualification Notification1={Notification1}/> */}
            {/* <VacancyDetails Notification1={Notification1}/> */}
            <ImportantLinks Notification1={Notification1}/>

          </div>
        </div>
    </>
  );
};
export default AdmitCard;
