import React, { useState, useReducer } from "react";
import PopupReducer from "./PopupReducer";
import PopupContext from "./PopupContext";

const PopupState = (props) => {
  let initialState = {};
  const [state, dispatch] = useReducer(PopupReducer, initialState);
  const [open, setOpen] = useState(false);
  const [drawerstate, setDrawerState] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(false);
  const [login, setlogin] = useState(false);
  const [signup, setsignup] = useState(false);
  const [forgotpassword, setforgotpassword] = useState(false);
  const [createpassword, setcreatepassword] = useState(false);
  const [otp, setotp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [otpVerify, setOtpVerify] = useState(false);
  const [onetimepassword, setOneTimePassword] = useState(null);

  const toggleDrawerOpenClose = (e) => {
    setDrawerState(!drawerstate);
  };

  const handleDeliveryAddressclose = (e) => {
    setDeliveryAddress(!deliveryAddress);
    setlogin(false);
    setOpen(false);
    setsignup(false);
    setDrawerState(false);
    setforgotpassword(false);
    // e.preventDefault();
  };
  const handleloginOpenclose = (e) => {
    setlogin(!login);
    setDeliveryAddress(false);
    setOpen(false);
    setsignup(false);
    setDrawerState(false);
    setforgotpassword(false);
    // e.preventDefault();
  };
  const handlesignupOpenclose = (e) => {
    setsignup(!signup);
    setDeliveryAddress(false);
    setOpen(false);
    setDrawerState(false);
    setlogin(false);
    setforgotpassword(false);
    // e.preventDefault();
  };
  const handleForgotpasswordOpenclose = (e) => {
    setforgotpassword(!forgotpassword);
    setDeliveryAddress(false);
    setOpen(false);
    setsignup(false);
    setlogin(false);
  };
  const handleCreatepasswordOpenclose = (e) => {
    setcreatepassword(!createpassword);
    setDeliveryAddress(false);
    setOpen(false);
    setforgotpassword(false);
    setsignup(false);
    setlogin(false);
  };
  const handleOtpOpenclose = (e) => {
    setotp(!otp);
    setcreatepassword(false);
    setOpen(false);
    setforgotpassword(false);
    setDeliveryAddress(false);
    setsignup(false);
    setlogin(false);
  };

  const success = (num) => {
    setMobileNumber(num);
    setOtpVerify(false);
  };

  const successOTP = (OTP, NUM) => {
    setOneTimePassword(OTP);
    setMobileNumber(NUM);
  };

  return (
    <PopupContext.Provider
      value={{
        toggleDrawerOpenClose,
        handleDeliveryAddressclose,
        handleloginOpenclose,
        handlesignupOpenclose,
        handleForgotpasswordOpenclose,
        handleOtpOpenclose,
        handleCreatepasswordOpenclose,
        open,
        setOpen,
        success,
        successOTP,
        drawerstate,
        setDrawerState,
        deliveryAddress,
        setDeliveryAddress,
        login,
        setlogin,
        signup,
        setsignup,
        forgotpassword,
        setforgotpassword,
        createpassword,
        setcreatepassword,
        otp,
        setotp,
        onetimepassword,
        setOneTimePassword,
        mobileNumber,
        setMobileNumber,
        otpVerify,
        setOtpVerify,
      }}
    >
      {props.children}
    </PopupContext.Provider>
  );
};
export default PopupState;
