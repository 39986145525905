import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import booksboy from "../image/testseriesvector/booksboy.png";

const useStyles = makeStyles((theme) => ({
  main: {
    marginBottom: "20rem",
  },
  root: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    width: "48.9%",
    padding: "10px",
    marginBottom: "1rem",
    background: "#FBFBFB",
    border: "1px solid #CECECE",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0px",
      "& .MuiCardContent-root": {
        padding: "11px",
      }
    },
  },
  middle: {
    display: "flex",
    justifyContent: "space-between",
  },
  head1: {
    textAlign: "center",
    fontSize: "0.675rem",
    fontFamily: "Jost",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
    },
  },
  icon: {
    color: "#FFE70B",
    width: "17%",
  },
  top: {
    width: "55%",
    background: "#FF3939",
    color: "#FFF",
    borderRadius: "6px",
  },
  top1: {
    width: "137px",
    background: "#E5FFF6",
    textAlign: "center",
    padding: "2px 0",
    fontSize: "0.775rem",
    fontFamily: "Jost",
    fontWeight: "600",
    border: "1px solid #AFFFE3",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  center: {
    marginTop: "20px",
  },
  head2: {
    color: "#252525",
    fontFamily: "Playfair Display",
    fontSize: "1.2rem",
    fontWeight: "700",
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.85rem",
    },
  },
  head3: {
    color: "#252525",
    fontFamily: "Playfair Display",
    fontSize: "15px",
    fontWeight: "600",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
      marginTop: "0.3rem",
    },
  },
  contentright: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  contentleft: {
    width: "70%",
  },
  imgcontainer: {
    position: "relative",
    top: "2rem",
    right: "-1.2rem",
  },
  head4: {
    color: "#252525",
    fontFamily: "Jost",
    fontSize: "medium",
    fontWeight: "600",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  countdowncontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    "& .MuiTypography-caption": {
        fontFamily: "Jost",
        fontSize: "0.65rem",
        color: "#252525",
        fontWeight: 500,
    },
    
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  countdown: {
    textAlign: "center",
  },
  counter: {
    padding: "0.5rem 0.6rem",
    border: "1px solid #FF4343",
    borderRadius: "5px",
    background: "#FFF",
    "& .MuiTypography-h5": {
        fontFamily: "Jost",
        fontSize: "1.25rem",
        color: "#4D4D4D",
        fontWeight: 700,
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.9rem",
        },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.4rem",
    },
  },
}));

const UpcommingLiveTestCard = () => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.middle}>
            <div className={classes.contentleft}>
              <div className={classes.top}>
                <div className={classes.head1}>
                  {" "}
                  <FiberManualRecordIcon className={classes.icon} />
                  Upcoming Test
                </div>
              </div>
            </div>
            <div className={classes.contentright}>
              <div className={classes.top1}> Special Reconization</div>
            </div>
          </div>
          <div className={classes.center}>
            <Typography className={classes.head2}>
              {" "}
              All India Mock:SSC JE CBT-2 (Electricle) 25-NOV-2023
            </Typography>
            <Typography className={classes.head3}>
              (Electricle Engineer)
            </Typography>
            <div className={classes.countdowncontainer}>
              <Typography className={classes.head4}>
                Your Test Series <br />
                Wil Be available in :
              </Typography>
              <div className={classes.countdown}>
                <div className={classes.counter}>
                    <Typography variant="h5">05</Typography>
                </div>
                <Typography variant="caption">Days</Typography>
              </div>
              <div className={classes.countdown}>
                <div className={classes.counter}>
                    <Typography variant="h5">12</Typography>
                </div>
                <Typography variant="caption">Hour</Typography>
              </div>
              <div className={classes.countdown}>
                <div className={classes.counter}>
                    <Typography variant="h5">30</Typography>
                </div>
                <Typography variant="caption">Minute</Typography>
              </div>
              <div className={classes.countdown}>
                <div className={classes.counter}>
                    <Typography variant="h5">06</Typography>
                </div>
                <Typography variant="caption">Second</Typography>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default UpcommingLiveTestCard;
