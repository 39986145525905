import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    IconButton,
    Typography,
    makeStyles,
  } from "@material-ui/core";
  import React, { useState } from "react";
  import BgImage from "../../image/notesvector/teacher.png";
  import { ReactComponent as VideoIcon } from "../../image/notesvector/videovector.svg";
  import { ReactComponent as DiaryIcon } from "../../image/notesvector/diaryvector.svg";
  import { ReactComponent as DocumentIcon } from "../../image/notesvector/docvector.svg";
  import { ReactComponent as CartBagIcon } from "../../image/testseriesvector/carticon.svg";
  import FavouriteButton from "../../practicecomponent/FavouriteButton";
  import { Link, useNavigate } from "react-router-dom";
  import InfoIcon from "@material-ui/icons/InfoOutlined";
  import BuyNowButton from "../../practicecomponent/BuyNowButton";
  
  const useStyles = makeStyles((theme) => ({
    NotesCard: {
      height: "100%",
      justifyContent: "space-between",
      maxWidth: "23.5%",
      // maxWidth: "280px",
      // flexBasis: "33.3333%",
      display: "flex !important",
      margin: "1rem 5px",
      flexDirection: "column",
      // minHeight: 482,
      borderRadius: 7,
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      "&:hover": {
        boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
        cursor: "pointer",
        "& .MuiCardActions-root": {
          background:
            "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
          borderRadius: 6,
          "& .MuiButton-label": {
            fontFamily: "Jost",
            alignItems: "center",
          },
        },
      },
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
        },
      },
      "& .MuiButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& fieldset": {
        margin: "0 0 20px -3px",
        padding: 0,
      },
      "& .MuiTypography-h6": {
        fontFamily: "'Jost', sans-serif",
  
        fontSize: "0.55rem",
        fontWeight: "400",
      },
      "& .MuiBox-root": {
        border: "none",
        display: "flex",
        alignItems: "center",
      },
      "& .MuiCardContent-root": {
        padding: "0 9px",
        minHeight: "5rem",
  
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
      },
      "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
        opacity: "0.3",
      },
      "& .MuiIconButton-root": {
        padding: "0",
      },
      "& .MuiTypography-h5": {
        color: "#223852",
        fontFamily: "'Jost', sans-serif",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "500",
        margin: "0",
        marginTop: "12px",
        lineHeight: "1.2rem",
        textTransform: "capitalize",
        whiteSpace: "wrap",
        height: "38px",
        overflow: "hidden",
        textOverflow: `"..."`,
      },
      "& .MuiTypography-caption": {
        fontFamily: "'Jost', sans-serif",
        color: "#777",
        fontSize: "0.75rem",
        fontWeight: "400",
      },
      "& .MuiTypography-h4": {
        fontFamily: "'Jost', sans-serif",
        color: "#FF3939",
        fontSize: "0.9375rem",
        fontWeight: "500",
      },
      "& .MuiTypography-body1": {
        marginLeft: "1rem",
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.125rem",
        fontWeight: "600",
      },
      "& .MuiCardMedia-root": {
        margin: "1rem auto 0 auto",
        // width: "45% !important",
        borderRadius: "3%",
        position: "relative",
      },
      "&.MuiCardMedia-media": {
        width: "50% !important",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiTypography-h5": {
          fontSize: "1.2rem",
          marginBottom: 5,
        },
        "& .MuiCard-root": {
          "@media (min-width: 960px)": {
            width: "50%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "50%",
          },
          [theme.breakpoints.down("xs")]: {
            width: "80%",
          },
        },
      },
    },
    cardimgcont: {
      width: "38%",
      position: "relative",
      background: "#FFE8B5",
      margin: ".6rem .6rem 0rem .6rem",
      borderRadius: 8,
      "&:hover": {
        background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
      },
    },
    bgorange: {
      background:
        "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
      color: "#212121 !important",
    },
    textred: {
      color: "#FF3939",
      fontSize: "1.25rem",
      fontFamily: "Jost",
      fontWeight: "600",
      marginRight: "0.5rem",
    },
    offertext: {
      marginLeft: "1rem",
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    newlaunchtag: {
      width: "64%",
      marginBottom: "0.1rem",
      borderRadius: "0.125rem",
      border: "1px solid #FFA900",
      padding: "0.1rem",
      background: "#FFEEC3",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
      "& .MuiTypography-h6": {
        color: "#000",
        fontSize: "0.625rem",
        fontFamily: "'Jost', sans-serif",
        fontWeight: "400",
        textAlign: "center",
      },
    },
    quikviewcontainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      position: "absolute",
      left: "50%",
      top: "50%",
      background: "white",
      padding: "0.3rem 0.5rem !important",
      opacity: "1",
      zIndex: "1000",
      width: "133% !important",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
      borderRadius: "6px",
    },
    quikviewbtn: {
      "&:hover": {
        background: "white",
      },
      "& .MuiIconButton-label": {
        color: "#282828",
      },
      "& .MuiSvgIcon-root": {
        width: "0.9em",
      },
      "& .MuiButton-label": {
        fontFamily: "'Jost', sans-serif",
        color: "#252525",
        fontSize: "0.9rem",
        fontWeight: "500",
      },
    },
    justifyContentevenly: {
      justifyContent: "space-evenly",
    },
    tagscontainer: {
      display: "flex",
      position: "relative",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "0.55rem",
        fontFamily: "'Jost', sans-serif",
        fontWeight: "500",
      },
    },
    innertag: {
      width: "85%",
      padding: "0.3rem",
      display: "flex",
      marginBottom: "10px",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
      borderRadius: "0.4rem",
    },
    innertags: {
      width: "50%",
      display: "flex",
      alignItems: "center",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
      borderRadius: "0.4rem",
      marginBottom: "10px",
    },
    p03: {
      padding: "0.1rem 0.3rem",
    },
    innertagicons: {
      margin: "0rem 0.2rem",
    },
    cardimgandtitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    cardtitle: {
      width: "51%",
    },
    carticon: {
      marginRight: "0.6rem",
    },
    cardroot: {
      flexBasis: "33.3333%",
      // maxWidth: 280,
      maxHeight: 291,
      margin: "0 5px",
      marginBottom: "2rem",
    },
    favoritebtn: {
      // width: "14%",
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#FF3939",
      },
    },
  }));
  
  function NotesCardVideoDash({ Data }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  
    const [isHover, setIsHover] = useState(false);
  
    const handleCardHover = () => {
      setIsHover(!isHover);
    };
    return (
      <Card className={[classes.NotesCard, classes.cardroot]}>
        <CardActionArea
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          onClick={()=>{
            navigate(`/note/${Data?.id}`);
          }}
        >
          <div className={classes.cardimgandtitle}>
            <div className={classes.cardimgcont}>
              {isHover && (
                <div>
                  <div className={classes.quikviewcontainer}>
                    {/* <Link to={`/note/${data?.id}`}> */}
                      <IconButton className={classes.quikviewbtn}>
                        <InfoIcon />
                      </IconButton>
                    {/* </Link> */}
                    <div className={classes.favoritebtn}>
                      <FavouriteButton
                        isCheckedHeart={isCheckedHeart}
                        data={Data}
                        setIsCheckedHeart={setIsCheckedHeart}
                      />
                    </div>
                  </div>
                </div>
              )}
              <CardMedia
                component="img"
                image={Data?.image_url || require("../../image/notFoundData.png")}
                alt="green iguana"
              />
            </div>
            <div className={classes.cardtitle}>
              <Typography gutterBottom variant="h5" component="div">
                {Data?.video_name} ({Data?.subCategory?.sub_category_name})
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                by {Data?.addedBy?.full_name}
              </Typography>
              <div className={classes.newlaunchtag}>
                <Typography variant="h6"> {Data?.category?.category_name}</Typography>
              </div>
              <Typography
                variant="body2"
                color="text.secondary"
                // style={{ marginBottom: 20, marginTop: 7 }}
              >
                <span className={classes.textred}>
                  <span style={{ fontSize: "1rem", verticalAlign: "top" }}>
                    ₹
                  </span>
                  {Data?.selling_cost}
                </span>
              </Typography>
              <Typography variant="caption">
                MRP : <del>₹{Data?.mrp}</del>
              </Typography>
            </div>
          </div>
          <CardContent>
            <div
              className={`${classes.tagscontainer} ${classes.justifyContentevenly}`}
            >
              <div className={`${classes.innertags} ${classes.p03}`}>
                <div className={classes.innertagicons}>
                  <VideoIcon />
                </div>
                <Typography variant="h6">Test Series</Typography>
              </div>
              <div className={`${classes.innertags} ${classes.p03}`}>
                <div className={classes.innertagicons}>
                  <DiaryIcon />
                </div>
                <Typography variant="h6">Topic Wise Notes</Typography>
              </div>
            </div>
            <div className={classes.tagscontainer}>
              <div className={classes.innertag}>
                <div className={classes.innertagicons}>
                  <DocumentIcon />
                </div>
                <Typography variant="h6">10 PDF Chapter wise Notes</Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.bgorange}>
          <BuyNowButton data={Data} />
        </CardActions>
      </Card>
    );
  }
  export default NotesCardVideoDash;
  