import React from 'react'
import { Card, Typography, makeStyles } from "@material-ui/core";
import cyran from "../image/notification/cyran.png"



const useStyles = makeStyles((theme) => ({



    containerFluidTwo: {
        width: "99%",
        position: "relative",
        background: "#FFFFFF",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "95%",
          },

    },
    divtypethree: {
        width: "99%",
        position: "relative",
        background: "#FFFFFF",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",

    },

    carddiv: {
        padding: "20px",
        display: "flex",
    },

    head3: {
        color: "#4A84C4",
        fontSize: "25px",
    },
    head4: {
        color: "#000",

    },
    lastdiv: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      },
    dob:{
        fontWeight:"300",
        color:"#ABABAB"
    }


}));
const Multiple = [
    {
        id: 1,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"
    },
    {
        id: 2,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"
    },
    {
        id: 3,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"
    },
    {
        id: 4,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"

    },
    {
        id: 5,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"

    },
    {
        id: 6,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"

    },
    {
        id: 7,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"
    },
    {
        id: 8,
        subCategories: "Announcment",
        categrory: "Issac Morgan Posted An Announcment 'Review Lecture On The 22nd",
        last: "February' On Your Course Railway Clearnes Test",

        date: "20th Aug 2020"

    },






];

const MultipleCard = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.containerFluidTwo}>
                {Multiple.map((data) => (
                    <div className={classes.carddiv}>

                        <div>
                            <img src={cyran} alt="img" width="60%" />

                        </div>
                        <div className={classes.lastdiv}>
                            <div className={classes.midil}>
                                <Typography className={classes.head3}>{data.subCategories}</Typography>
                                <Typography className={classes.head4} >{data.categrory}<br />
                                    {data.last}
                                </Typography>
                            </div>
                            <div className={classes.dob}>
                                {data.date}
                            </div>
                        </div>

                    </div>

                ))}

            </div>

        </>
    )
}

export default MultipleCard
