import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Card, Typography } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import PanCardImage from "../image/seller/pan.png";
import BankCardImage from "../image/seller/bank.png";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "4rem",
    backgroundColor: "#FFF5DF",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
  },
  container: {
    margin: "0 auto",
    padding: "4rem 0",
    maxWidth: "1250px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
    },
  },
  innerContainer: {
    width: "70%",
    margin: "8rem auto 0",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    padding: "2rem 4rem",
    boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "2.1rem 1rem",
      margin: "94px auto",
      width: "88%",
    },
  },
  stepper: {
    position: "absolute",
    top: "25%",
    width: "100%",
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      top: "12%",
    },
  },
  stepIcon: {
    width: "40px",
    height: "40px",
  },
  contentContainer: {
    textAlign: "center",
  },
  heading: {
    fontSize: "45px",
    color: "rgba(37, 37, 37, 1)",
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    marginTop: "0rem",
  },
  paragraph: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    margin: "1rem",
  },
  hemisphereContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
  },
  hemisphere: {
    width: "35%",
    backgroundColor: "#FFF5DF",
    borderRadius: "50% 50% 0 0",
    margin: "1px 87px",
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "36%",
      margin: "1px 21px",
    },
  },
  hemisphereImage: {
    width: "60%",
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
  hemisphereText: {
    color: "rgba(0, 0, 0, 1)",
    fontWeight: "400",
    fontSize: "16px",
    fontFamily: "'Jost', sans-serif",
  },
  button: {
    backgroundColor: "rgba(255, 179, 71, 1)",
    color: "rgba(36, 59, 85, 1)",
    width: "247px",
    height: "64px",
    fontSize: "16px",
    padding: "10px 20px",
    borderRadius: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 179, 71, 1)",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}));

function SellBooks() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Step-wise Guide to selling Books Online",
      headingmain: "Steps to sell books online",
    },
  ];

  const steps = ["", "", "", ""];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <Stepper activeStep={0} alternativeLabel className={classes.stepper}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.stepIcon,
                  },
                }}
              ></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Card className={classes.innerContainer}>
          <div className={classes.contentContainer}>
            <Typography variant="h6" className={classes.heading}>
              Step 01
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Books are a GST-exempt category. You need only the following to
              create your book seller account on ParikshaDo
            </Typography>
            <div className={classes.hemisphereContainer}>
              <div className={classes.hemisphere}>
                <img
                  src={PanCardImage}
                  alt="PAN Card Information"
                  className={classes.hemisphereImage}
                />
                <Typography variant="body2" className={classes.hemisphereText}>
                  PAN Information
                </Typography>
              </div>
              <div className={classes.hemisphere}>
                <img
                  src={BankCardImage}
                  alt="Bank Card Information"
                  className={classes.hemisphereImage}
                />
                <Typography variant="body2" className={classes.hemisphereText}>
                  An active bank account for payments
                </Typography>
              </div>
            </div>
            <Typography variant="body1" className={classes.paragraph}>
              To get started, register on Seller Central and provide the details
              mentioned above:
            </Typography>
            <Button variant="contained" className={classes.button}>
              CREATE ACCOUNT
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SellBooks;
