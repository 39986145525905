const RectangleData = [
  {
    label: "Attempt",
    smallSquareColor: "rgba(255, 189, 100, 1)",
  },
  {
    label: "Unattempt",
    smallSquareColor: "rgba(235, 235, 235, 1)",
  },
  {
    label: "Current Q.",
    smallSquareColor: "rgba(20, 30, 48, 1)",
  },
];
export default RectangleData;
