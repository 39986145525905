import { useContext, useEffect, useReducer } from "react";
import AddCartReducer from "./AddCartReducer";
import AddCartContext from "./AddCartContext";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  FETCH_ALL_PRODUCTS,
  CART_ERROR_MESSAGE,
} from "./AddCartType";
import {
  addToCart,
  getAllCartProducts,
  removeFromCart,
  updateCart,
} from "../../service/services";
import AppContext from "../AppContext";
import { toast } from "react-toastify";

const AddCartState = (props) => {
  const userContext = useContext(AppContext);
  let initialState = {
    cart: {
      cashback_amount: 0,
      dlivery_cost_cost: 0,
      grand_total_cost: 0,
      gst: 0,
      product_count: 0,
      products: [],
      shiping_cost: 0,
      sub_total_cost: 0,
      sub_total_discount: 0,
      wallet_amount: 0,
    },
    hasError: false,
    errorMessage: "",
    currentProductId: 0,
  };
  const [state, dispatch] = useReducer(AddCartReducer, initialState);

  const clearCartError = () => {
    dispatch({
      type: CART_ERROR_MESSAGE,
      payload: { ...state, hasError: false, errorMessage: "" },
    });
  };
  const fetchCartAPI = () => {
    clearCartError();
    getAllCartProducts()
      .then((res) => {
        const { data } = res;
        // leaving count here incase require to show count, add directly to state
        if (data.success && data.cart) {
          dispatch({ type: FETCH_ALL_PRODUCTS, payload: data.cart });
        }
      })
      .catch((e) =>
        dispatch({
          type: CART_ERROR_MESSAGE,
          payload: {
            hasError: !e?.response?.data?.success,
            errorMessage:
              e?.response?.data?.message?.error || e?.response?.data?.message,
          },
        })
      );
  };

  useEffect(() => {
    if (userContext.isLoggedIn) {
      fetchCartAPI();
    }
  }, [userContext.isLoggedIn]);

  const addToCartListAPI = (data) => {
    let book_type = data.product_type;
    if (!book_type)
      book_type = data.hasOwnProperty("book_name") ? "books" : "ebooks";

    const body = {
      cart: {
        product_id: `${data.product_id || data.id}`,
        product_type: book_type,
        quantity: data.quantity || "1",
      },
    };

    addToCart(body)
      .then((res) => {
        const updatedCartList = [...state.cart.products];
        const ind = updatedCartList.findIndex(
          (_) => Number(_.product_id) === data.id
        );
        if (ind > -1) updatedCartList.splice(ind, 1, res.data.cart);
        else updatedCartList.push(res.data.cart);
        toast.success(res.data.message || "Added", { autoClose: 1000 });
        console.log("addToCart", res);
        const updatedSubTotal =
          updatedCartList?.length > 0
            ? updatedCartList.reduce(
                (sum, el) => sum + Number(el.product?.cost),
                0
              )
            : 0;
        const updatedCount = updatedCartList?.length || 0;
        return dispatch({
          type: ADD_TO_CART,
          payload: {
            ...state.cart,
            success: res.data.success,
            successMessages: res.data.message,
            products: updatedCartList,
            sub_total_cost: updatedSubTotal,
            product_count: updatedCount,
          },
        });
      })
      .catch((e) => {
        if (
          e?.response?.data?.message ===
          "Invalid or expired token. Please refresh your session."
        ) {
          localStorage.clear();
          window.location.reload();
        } else {
          toast.error(
            e?.response?.data?.message?.error ||
              e?.response?.data?.message ||
              "Error",
            { autoClose: 1000 }
          );
        }

        dispatch({
          type: CART_ERROR_MESSAGE,
          payload: {
            hasError: !e?.response?.data?.success,
            errorMessage:
              e?.response?.data?.message?.error || e?.response?.data?.message,
            currentProductId: data.id,
          },
        });
      });
  };

  const removefromCartListAPI = (id) => {
    removeFromCart(id)
      .then(() => {
        const updatedCartList = state.cart.products?.filter(
          (el) => el?.id !== id
        );
        const updatedSubTotal =
          updatedCartList?.length > 0
            ? updatedCartList.reduce(
                (sum, el) => sum + Number(el.total_cost || el.product?.cost),
                0
              )
            : 0;
        const updatedCount = updatedCartList?.length || 0;
        fetchCartAPI();
        return dispatch({
          type: REMOVE_FROM_CART,
          payload: {
            ...state.cart,
            products: updatedCartList,
            sub_total_cost: updatedSubTotal,
            product_count: updatedCount,
          },
        });
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.message?.error ||
            e?.response?.data?.message ||
            "Error",
          { autoClose: 1000 }
        );

        dispatch({
          type: CART_ERROR_MESSAGE,
          payload: {
            hasError: !e?.response?.data?.success,
            errorMessage:
              e?.response?.data?.message?.error || e?.response?.data?.message,
            currentProductId: id,
          },
        });
      });
  };

  const updateCartListAPI = (id, product_id, quantity) => {
    const body = {
      cart: {
        id,
        // product_id,
        quantity,
      },
    };
    updateCart(body)
      .then((res) => {
        // API is not updating the PRICE, it should be updated to get the final price in response as always
        const updatedCartList = state?.cart?.products?.map((el) => {
          if (el?.id === id) {
            return {
              ...el,
              ...res.data.cart,
            };
          } else return el;
        });
        console.log("upadte123", updatedCartList);
        const updatedSubTotal =
          res?.data?.cart?.total_cost ||
          updatedCartList.reduce(
            (sum, el) => sum + Number(el.total_cost || el.product?.cost),
            0
          );
        return dispatch({ type: REMOVE_FROM_CART, payload: res?.data?.cart });
      })
      .catch((e) =>
        dispatch({
          type: CART_ERROR_MESSAGE,
          payload: {
            hasError: !e?.response?.data?.success,
            errorMessage:
              e?.response?.data?.message?.error || e?.response?.data?.message,
            currentProductId: id,
          },
        })
      );
  };

  return (
    <AddCartContext.Provider
      value={{
        cartState: state,
        addToCartListAPI,
        removefromCartListAPI,
        updateCartListAPI,
        fetchCartAPI,
        clearCartError,
      }}
    >
      {props.children}
    </AddCartContext.Provider>
  );
};
export default AddCartState;
