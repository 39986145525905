import React from "react";
import PageHeading from "../pageheading/pageheading";
import WishListContent from "./wishlistcontent";

function WishList() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Add you desired Study Material in your wishlist",
      headingmain: "Wishlist",
      breadcrumb: "Whishlist",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <WishListContent />
    </div>
  );
}
export default WishList;
