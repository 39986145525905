import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExclusiveBooksSection from "../../Home/exclusivebooks/exclusivebooksection";
import MessageIcon from "../../image/singlepagedescriptionvector/msgicon.png";
import NotepadeIcon from "../../image/singlepagedescriptionvector/notepadicon.png";
import UnlockIcon from "../../image/singlepagedescriptionvector/unlockvector.png";
import LockIcon from "../../image/singlepagedescriptionvector/lockvector.png";
import PdfIcon from "../../image/singlepagedescriptionvector/pdfvector.png";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import BundleInSitesSection from "./BundleInSitesSection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3rem 2rem",
    flexGrow: 1,

    [theme.breakpoints.up("xs")]: {
      margin: "5rem 0rem",
    },

    "& .MuiBox-root": {
        padding: "0.3rem 1rem",
    },
    "& .MuiTab-root": {
      padding: "10px 12px",
    },
    "& .MuiRating-root": {
      marginRight: "1rem",
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        padding: "0rem 1rem",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 93.96%)",
      color: "white",
      borderRadius: "5px 5px 0 0",
      minHeight: "40px",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #C6C6C6",
    },
    "& .MuiTab-textColorInherit": {
      fontFamily: "Playfair Display",
      fontWeight: "700",
      color: "#676767",
    },
    "& .MuiTypography-h3": {
      color: "#252525",
      fontSize: "1.625rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
    "& .MuiTypography-h4": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "500",
      marginRight: "0.5rem",
    },
    "& .MuiTypography-h6": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      width: "86%",
    },
    "& .MuiRating-root.Mui-disabled": {
      opacity: "1",
    },
    "& .MuiAvatar-root": {
      width: "65px",
      height: "65px",
    },
    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "none",
    },
    "& .MuiToggleButton-root": {
      color: "#BFBFBF",
      background: "none",
      padding: "0.5rem",
      border: "none",
    },
    "& .MuiToggleButton-label": {
      fontSize: "1.3rem",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-multiline": {
      width: "100%",
      border: "1px solid #DBDBDB",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "0px",
    },
    "& .MuiOutlinedInput-notchedOutline ": {
      borderColor: "transparent",
      borderRadius: "6px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTabs-root": {
        overflow: "scroll",
      },
      "& .MuiTabs-fixed": {
        overflow: "scroll",
      },
      "& .MuiTabs-scroller": {
        overflow: "scroll",
      },
    },
  },
  tabpannel: {
    marginTop: "2rem",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    "& .MuiBox-root-111": {
      padding: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 1rem",
      padding: "1rem 0.5rem",
      textAlign: "justify",
      "& .MuiBox-root-111": {
        padding: "0",
      },
    },
    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#676767",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        color: "#727272",
      },
    },
  },
  tabpannel2: {
    marginTop: "2rem",
    padding: "1rem 0rem",
    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#676767",
      textTransform: "capitalize",
    },
  },
  ebookuploadingplancontent: {
    marginTop: "2rem",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  table: {
    "& .MuiTypography-h3": {
      fontSize: "1.375rem",
      fontWeight: "500",
      fontFamily: "Playfair Display",
    },
    "& .MuiTableHead-root": {
      background: "#FFFBF1",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    },
    "& .MuiTableCell-head": {
      fontSize: "1rem",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
      borderBottom: "0",
      padding: "8px 20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem !important",
      },
    },
    "& .MuiTableCell-root": {
      padding: "16px 20px",
      fontSize: "1rem",
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        padding: "16px",
        fontSize: "0.5rem !important",
      },
    },
    
    "& .MuiTooltip-tooltip": {
      backgroundColor: "rgba(255,255,0,0.4)",
    }
  },
  tableheadicon: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "60px",
    },
  },
  tablerowcontainer: {
    display: "flex",
    alignItems: "center",
    width: "78%",
  },
  unlockedrow: {
    display: "flex",
    alignItems: "center",
    width: "94%",
    "& .MuiTypography-h6": {
      color: "#154FE5",
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Jost",
    },
  },
  lockedrow: {
    "& .MuiTypography-h6": {
      color: "#B1B1B1",
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Jost",
      "& .MuiTooltip-tooltip": {
        backgroundColor: "rgba(255,255,0,0.4)",
      }
    },
  },
  tablerowicon: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "0.8rem",
      // width: "50px"
    },
  },
  borderRight: {
    borderRight: "1px solid #E0E0E0",
  },
  discriptioninner: {
    margin: "2rem 0",
    "& ul": {
      [theme.breakpoints.down("xs")]: {
        marginTop: "0.8rem",
       paddingLeft: "1.5rem",
      },
    },
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RelatedItemsHead = [
    {
      id: 1,
      headingsmall: "Start practicing free quizzes online with MCQ questions.",
      headingmain: "Products Related To This Item",
    },
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none"
          aria-label="simple tabs example"
          variant="scrollable"
        >
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Bundle In-Sites" {...a11yProps(1)} />
          <Tab label="Related Products" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel className={classes.tabpannel}>
        <div className={classes.discriptioninner}>
          <div className={classes.tabpannelhead}>
            <Typography variant="h3" gutterBottom>Product Description</Typography>
          </div>
          <Typography component="p">
            Prices are Increasing from 1st August!
          </Typography>
        </div>
        <div className={classes.discriptioninner}>
          <div className={classes.tabpannelhead}>
            <Typography variant="h3" gutterBottom>Overview:</Typography>
          </div>
          <Typography component="p">
            This Test Series is a complete package for the preparation of Delhi Police Constable. It will help you to identify strong & weak areas through Topic Tests, Subject Tests & Full-Length Mocks. It enables you to focus on the right direction & channelize your score as well.
          </Typography>
        </div>
        <div className={classes.discriptioninner}>
          <div className={classes.tabpannelhead}>
            <Typography variant="h3" gutterBottom>Package Includes:</Typography>
          </div>
          <ul>
            <li><Typography component="p">10 Full-Length Mocks Based on the Latest Pattern</Typography></li>
            <li><Typography component="p">10 Mini mock Based on the Latest Pattern</Typography></li>
            <li><Typography component="p">160+ Topic Tests: 30+ Topic Tests of Reasoning Ability | 25+ Topic Tests of Quantitative aptitude | 85+ Topic Tests of GA |</Typography></li>
            <li><Typography component="p">10 Topic Tests of Computer awareness</Typography></li>
          </ul>  
        </div>
      </TabPanel>

      <TabPanel className={classes.tabpannel2}>
        <BundleInSitesSection />
      </TabPanel>

      <TabPanel className={classes.tabpannel2}>
        <ExclusiveBooksSection Heading={RelatedItemsHead} />
      </TabPanel>
    </div>
  );
}
