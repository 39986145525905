import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../../image/singlepagedescriptionvector/checkicon1.svg";
import FavouriteButton from "../../FavouriteButton";
import makePayment from "../../../../Utils/makePayment";
import popupContext from "../../../../context/PopupContext/PopupContext";
import addCartContext from "../../../../context/AddCartContext/AddCartContext";
import appContext from "../../../../context/AppContext";
import { ReactComponent as CartBagIcon } from "../../../image/ebooksvector/cartbagicon.svg";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2.5rem 2rem",
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      background: "none",
      display: "block",
      padding: "2.5rem 1rem",
    },

    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "#FFFFFF",
    },
    "& .MuiToggleButton-root": {
      color: "#777777",
      background: "#FFFFFF",
      borderRadius: "40px",
      padding: "0.5rem",
      position: "absolute",
      right: "16px",
      top: "16px",
    },
    "& .MuiIconButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
    },
  },
  imagegallery: {
    width: "43%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      // fontSize: "0.875rem",
      width: "100%",
      margin: "0 auto",
    },
  },
  currentimagecontainer: {
    // width:"100%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 0",
      justifyContent: "space-evenly",
    },
    padding: "1.5rem 5rem",
    background: "#FFE8B5",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tag: {
    background: "#FF3939",
    position: "absolute",
    top: "2.9rem",
    left: "0rem",
    padding: "0 1.8rem",
    [theme.breakpoints.down("xs")]: {
      top: "2rem",
      left: "0rem",
      padding: "0 1rem",
    },
    "& .MuiTypography-caption": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
      color: "#FFFFFF",
      fontFamily: " Playfair Display",
      fontSize: "1.3rem",
    },
  },
  imagewrapper: {
    // marginLeft: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      // marginLeft: "2.4rem",
    },
  },

  image: {
    width: "290px",
    [theme.breakpoints.down("xs")]: {
      width: "290px",
    },
  },

  thumbnail: {
    [theme.breakpoints.down("xs")]: {
      width: "60px",
    },
  },
  productdetails: {
    width: "52%",
    marginLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
      width: "100%",
      marginTop: "2rem",
    },
    "& .MuiTypography-h3": {
      fontFamily: " Playfair Display",
      fontSize: "2rem",
      fontWeight: "600",

      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
  },
  author: {
    background: "#FFEEC9",
    display: "inline-block",
    margin: "0.7rem 0",
    padding: "0.3rem 1rem",
    borderRadius: "3px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.9rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.77rem",
      },
    },
  },
  validitycontainer: {
    margin: "1rem 0",
    display: "flex",
    maxWidth: "45%",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0rem 0.5rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "60%",
      padding: "0.5rem",
      margin: "0rem 0rem 0.5rem 0rem",
      "& svg": {
        width: "16px",
      },
    },
    "& .MuiTypography-h6": {
      fontFamily: "Jost",
      fontSize: "1.375rem",
      fontWeight: "600",
      cursor: "default",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#252525",
      fontFamily: "Jost",
      fontSize: "1.3125rem",
      fontWeight: "400",
      marginLeft: "0.3rem",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  price: {
    margin: "1.5rem 0 1rem 0",
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      margin: "0",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Jost",
      fontSize: "1.7rem",
      fontWeight: "500",
      color: "#252525",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#626262",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: "400",
      marginRight: "0.2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#252525",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
  },
  mrpcontainer: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.4rem",
    },
  },
  mrp: {
    display: "flex",
    "& .MuiTypography-h3": {
      fontFamily: "Jost",
      fontSize: "2rem",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
        display: "none",
      },
    },
    "& span": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        marginRight: "0.2rem",
      },
    },
  },
  mrpmobile: {
    "& .MuiTypography-h3": {
      marginTop: "0.3rem",
      fontFamily: "Jost",
      fontSize: "2rem",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
    "& span": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        marginRight: "0.2rem",
      },
    },
  },
  offers: {
    display: "flex",
  },
  tax: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      alignItems: "baseline",
    },
    "& .MuiTypography-caption": {
      color: "#000000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
  },
  link: {
    textDecoration: "none",
    "& .MuiTypography-h6": {
      color: "#206FE5",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: "400",
      marginLeft: "0.2rem",
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.675rem",
      },
    },
  },
  mrpandoffer: {
    width: "70%",
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  buttonscontainer: {
    width: "72%",
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  mobilebluecustombtn: {
    [theme.breakpoints.down("xs")]: {
      width: "45%",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "#fff",
    fontFamily: "Jost",
    padding: "11px 55px",
    fontSize: "1rem",
    borderRadius: "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#20324A",
      fontSize: "0.7rem",
      padding: "11px 0",
      marginRight: "0",
      width: "100%",
      "&:hover": {
        background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
        color: "#20324A",
      },
    },
  },
  mobileyellowcustombtn:{
    [theme.breakpoints.down("xs")]: {
      width: "45%",
    },
  },
  yellowcustombtn: {
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "#20324A",
    fontFamily: "Jost",
    padding: "11px 24px",
    fontSize: "1rem",
    borderRadius: "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
      color: "#fff",
      padding: "11px 0",
      fontSize: "0.7rem",
      marginRight: "0",
      width: "100%",
      marginRight: "0px",
      "&:hover": {
        background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
        color: "#fff",
      },
      "& svg": {
        fill: "white",
        width: "16px",
        height: "16px",
      },
    },
  },
  cartbagicon: {
    marginRight: "0.6rem",
  },
  whishlisticon: {
    top: "2rem",
    right: "2rem",
    position: "absolute",
    "& svg": {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  offerssection: {
    marginLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  specialpricemain: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  specialpricemainmobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));
function ProductDetails({ eBookDetail }) {
  const classes = useStyles();
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  const { addToCartListAPI, cartList } = useContext(addCartContext);
  const { isLoggedIn, token } = useContext(appContext);
  const { handleloginOpenclose } = useContext(popupContext);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const images = eBookDetail?.images || [];
  // const Thumbnail = [
  //   // {
  //   //   id: 1,
  //   //   img: eBookDetail?.images,
  //   // },
  //   // {
  //   //   id: 2,
  //   //   img: eBookDetail?.images?.front_image,
  //   // },
  //   // {
  //   //   id: 3,
  //   //   img: eBookDetail?.images?.back_image,
  //   // },
  // ];
  const handleAddToCart = () => {
    const check = cartList?.cart?.find((el) => el?.id === eBookDetail.id);

    if (check) {
      toast.error("already added in cart");
    } else {
      addToCartListAPI(eBookDetail);
    }
  };

  console.log("eBookDetail", eBookDetail);

  const handleOnlinePayment = () =>
    makePayment({
      order_type: "single",
      category: "non_physical",
      product: {
        productId: eBookDetail?.id,
        productType: eBookDetail.product_type,
      },
    });

  const handleThumbnailHover = (index) => {
    setSelectedImageIndex(index);
  };

  const handleThumbnailLeave = (index) => {
    setSelectedImageIndex(index);
  };

  const thumbnailStyle = {
    cursor: "pointer",
    border: "2px solid transparent",
    height: "80px",
    width: "80px",
    margin: "0 5px",
    overflow: "hidden",
  };

  const lastImageStyle = {
    boxShadow:
      selectedImageIndex === images.length - 1
        ? "0 0 10px rgba(255, 0, 0, 0.8)"
        : "none",
  };

  return (
    <div className={classes.container}>
      <div className={classes.imagegallery}>
        <div className={classes.currentimagecontainer}>
          <div className={classes.tag}>
            <Typography variant="caption">E-Book</Typography>
          </div>
          <div className={classes.imagewrapper}>
            <img
              className={classes.image}
              src={
                images[selectedImageIndex] ||
                require("../../../image/notFoundData.png")
              }
              alt="img"
              style={{ height: "290px" }}
            />
          </div>
          <div className={classes.whishlisticon}>
            <FavouriteButton
              isCheckedHeart={isCheckedHeart}
              setIsCheckedHeart={setIsCheckedHeart}
              data={eBookDetail}
            />
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          {images.map((imageSrc, index) => (
            <div
              key={index}
              style={{
                ...thumbnailStyle,
                ...(index === images.length - 1 ? lastImageStyle : {}),
                border:
                  selectedImageIndex === index
                    ? "2px solid red"
                    : "2px solid transparent",
              }}
              onMouseEnter={() => handleThumbnailHover(index)}
              onMouseLeave={() => handleThumbnailLeave(index)}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  display: "block",
                  objectFit: "cover",
                }}
                src={imageSrc}
                alt={`thumbnail-${index}`}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={classes.productdetails}>
        <div className={classes.title}>
          <Typography variant="h3">{eBookDetail?.ebook_name}</Typography>
        </div>
        <div className={classes.author}>
          <Typography variant="caption">By {eBookDetail?.author}</Typography>
        </div>    
        <div className={classes.validitycontainer}>
          <CheckIcon />
          <Typography variant="h6">Validity : </Typography>
          {eBookDetail?.validity === "lifetime" && (
            <Typography variant="caption"> {eBookDetail?.validity} </Typography>
          )}
          {eBookDetail?.validity  === "indays" && (
            <Typography variant="caption">
              {eBookDetail?.validity_in_days}days
            </Typography>
          )}
          {eBookDetail?.validity  === "indate" && (
            <Typography variant="caption">
              {new Date(eBookDetail?.validity_in_date).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }
              )}
            </Typography>
          )}
        </div>
        <div className={classes.price}>
          <div className={classes.specialpricemainmobile}>
            <Typography variant="h3">Special Price :</Typography>
            <div className={classes.mrpmobile}>
              <Typography variant="h3">₹{eBookDetail?.selling_cost}</Typography>
            </div>
          </div>
          <Typography className={classes.specialpricemain} variant="h3">
            Special Price :
          </Typography>
          <div className={classes.mrpcontainer}>
            <div className={classes.mrpandoffer}>
              <div className={classes.mrp}>
                <Typography variant="h3">
                  ₹{eBookDetail?.selling_cost}
                </Typography>
              </div>
              <div className={classes.offerssection}>
                <div className={classes.offers}>
                  <Typography variant="caption">
                    MRP: <del>&#8377;{eBookDetail?.mrp}</del>
                  </Typography>
                  <Typography variant="h6">
                    ({eBookDetail?.discount_percent}% off)
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.tax}>
              <Typography variant="caption">Inclusive of all taxes.</Typography>
              {/* <Link to={"#"} className={classes.link}>
                <Typography variant="h6">Details</Typography>
              </Link> */}
            </div>
          </div>
        </div>
        <div className={classes.buttonscontainer}>
        <div className={classes.mobileyellowcustombtn}>
            {isLoggedIn && token ? (
              <Button
                variant="contained"
                className={classes.yellowcustombtn}
                onClick={handleAddToCart}
                fullWidth
              >
                <CartBagIcon className={classes.cartbagicon} />
                Add To Cart
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.yellowcustombtn}
                onClick={handleloginOpenclose}
                fullWidth
              >
                <CartBagIcon className={classes.cartbagicon} />
                Add To Cart
              </Button>
            )}
          </div>
          <div className={classes.mobilebluecustombtn}>
            {isLoggedIn && token ? (
              <Button
                onClick={handleOnlinePayment}
                variant="contained"
                className={classes.bluecustombtn}
              >
                Buy Now
              </Button>
            ) : (
              <Button
                className={classes.bluecustombtn}
                onClick={handleloginOpenclose}
              >
                Buy Now
              </Button>
            )}
          </div>
        </div>
        {/* <div className={classes.buttonscontainer}>
          <div>
            {isLoggedIn && token ? (
              <Button
                variant="contained"
                className={classes.yellowcustombtn}
                onClick={handleAddToCart}
              >
                <CartBagIcon className={classes.cartbagicon} />
                Add To Cart
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.yellowcustombtn}
                onClick={handleloginOpenclose}
              >
                <CartBagIcon className={classes.cartbagicon} />
                Add To Cart
              </Button>
            )}
          </div>
          <div>
            {isLoggedIn && token ? (
              <Button
                onClick={handleOnlinePayment}
                variant="contained"
                className={classes.bluecustombtn}
              >
                Buy Now
              </Button>
            ) : (
              <Button
                className={classes.bluecustombtn}
                onClick={handleloginOpenclose}
              >
                Buy Now
              </Button>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default ProductDetails;
