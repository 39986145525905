import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import OurMission from "../image/aboutusvector/ourmission.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop:'3rem',
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    marginTop: '4rem',
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  onlineeducationcontent: {
    width: "60%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: 'block',
      padding: "0rem 1rem",
      marginTop: "4.5rem",
    },
  },
  onlineeducationimgcontainer: {
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "1rem 3rem"
    },
  },
  content:{
    marginTop: "2rem",
  },
  onlineeducationcontentinner: {
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
      fontSize: "2rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#7E7E7E",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        textAlign: "justify",
      },
    },
  },
}));
function OurMissionSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Empowering Dreams, Inspiring Growth, and Building Bright Futures.",
      headingmain: "Our Mission",
    },
  ];
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationimgcontainer}>
            <img src={OurMission} alt="img" width="100%" />
          </div>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <Typography variant="h5" gutterBottom>
                Our mission is to give voice to your brand on the internet
                through creative and results- driven solutions.
              </Typography>
             <div className={classes.content}>
             <Typography variant="body2" gutterBottom>
                We aim to define your marketing strategies, drive sales, and
                surpass your competition. Proceed to the forefront with our
                experienced team. We can help you with all your needs needs,
                that are seen and remembered for a long time. We aim to define
                your marketing strategies, drive sales, and surpass your
                competition. Proceed to the forefront with our experienced team.
                We can help you with all your needs, that are seen and
                remembered for a long time.
              </Typography>
              <Typography variant="body2" gutterBottom>
                We aim to define your marketing strategies, drive sales, and
                surpass your competition. Proceed to the forefront with our
                experienced team. We can help you with all your needs needs,
                that are seen and remembered for a long time.
              </Typography>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OurMissionSection;
