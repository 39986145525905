import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EbooksCard from "./ebooksCard";
import RecommendedEbooks from "./recommendedEbooks";
import NotesCard from "./notesCard";
import Recommendnotes from "./recommendnotes";
import UploadFile from "./uploadFile";
import { getEbookDashBoard, getNoteCardDash } from "../../../service/services";
import EbooksErrorPage from "../ErrorPage/EbooksErrorPage";
import NotesErrorPage from "../ErrorPage/NotesErrorPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: "3rem",
    flexGrow: 1,
    "& .MuiTab-root": {
      padding: "10px 18px",
    },
    "&. MuiBox-root-348 ": {
      [theme.breakpoints.down("xs")]: {
        padding: "0px !important",
      },
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
      background: "#FFF",
      width: "34%",
      [theme.breakpoints.down("xs")]: {
        padding: "0rem",
        width: "100%",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: "#FFEABE",
      color: "#262626",
      borderRadius: "5px",
      minHeight: "40px",
      fontSize: "1rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        width: "50%",
        fontSize: "0.8rem",
      },
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "none",
    },
    "& .MuiTab-textColorInherit": {
      fontFamily: "Playfair Display",
      fontWeight: "700",
      fontSize: "1rem",
      color: "#262626",
      background: "#FFF",
      borderRadius: "5px",
      [theme.breakpoints.down("xs")]: {
        width: "50%",
        fontSize: "0.8rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTabs-root": {
        overflow: "scroll",
        width: "100%",
      },
      "& .MuiTabs-fixed": {
        overflow: "scroll",
      },
      "& .MuiTabs-scroller": {
        overflow: "scroll",
      },
      margin: "0rem",
    },
  },
  tabpannel: {
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0rem",
      textAlign: "justify",
    },

    "& .MuiBox-root": {
      padding: "0",
    },

    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#676767",
      textTransform: "capitalize",
    },
  },
  bookdetailscontent: {
    marginTop: "2rem",
  },
  bookdetailscontentinner: {
    display: "flex",
    margin: "0.3rem 0",
    alignItems: "baseline",
  },
  cardcontainer: {
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "1rem",
    },
  },
  tabroot: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
}));

const StudyMaterial = (props) => {
  const classes = useStyles();
  const setOnViewClicked = props.setOnViewClicked;
  const bookDetail = props.bookDetail;
  const valuesidepannel = props.valuesidepannel;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getEbookDashBoard(token);
        console.log("API Response:", response.data);
        setData(response.data?.ebooks);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  const [note, setNote] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getNoteCardDash(token);
        console.log("API Response:", response.data);
        setNote(response.data?.notes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 4;

  const handleShowMore = () => {
    setStartIndex((prevStartIndex) => prevStartIndex + itemsPerPage);
  };

  return (
    <>
      <div className={classes.root}>
        <Tabs
          className={classes.tabroot}
          value={value}
          onChange={handleChange}
          indicatorColor="none"
          aria-label="simple tabs example"
        >
          <Tab label="E-Books" {...a11yProps(0)} />
          <Tab label="Notes" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0} className={classes.tabpannel}>
          <div className={classes.cardcontainer}>
            {data.length === 0 ? (
              <EbooksErrorPage />
            ) : (
              data.map((data, index) => (
                <EbooksCard
                  Data={data}
                  key={index}
                  setOnViewClicked={props.setOnViewClicked}
                />
              ))
            )}
          </div>
          <UploadFile />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabpannel}>
          <div className={classes.cardcontainer}>
            {note.length === 0 ? (
              <NotesErrorPage />
            ) : (
              note.map((data, index) => {
                return (
                  <NotesCard
                    Data={data}
                    key={index}
                    setOnViewClicked={props.setOnViewClicked}
                  />
                );
              })
            )}
          </div>
          <UploadFile />
        </TabPanel>
      </div>
      {value === 0 ? <RecommendedEbooks /> : <Recommendnotes />}
    </>
  );
};

export default StudyMaterial;
