const footerproductlist = [
  {
    id: 1,
    name: "Quizzes",
    to: "/quizzes",
  },
  {
    id: 2,
    name: "Previous Year Papers",
    to: "/previousyearpapers",
  },
  // {
  //   id: 3,
  //   name: "Current Affairs",
  //   to: "/currentaffairs",
  // },
  {
    id: 4,
    name: "Test Series",
    to: "/testseries",
  },
  {
    id: 5,
    name: "Notes",
    to: "/notes",
  },
  {
    id: 6,
    name: "Ebooks",
    to: "/ebooks",
  },
  {
    id: 7,
    name: "Books",
    to: "/books",
  },
  {
    id: 9,
    name: "Online Videos",
    to: "/onlinevideos",
  },
  {
    id: 8,
    name: "Live Classes",
    to: "/liveclasses",
  },

];
export default footerproductlist;
