import { Grid, Tab, Tabs, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import categoryContext from "../../context/CategoryContext/CategoryContext";
import appContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tabButton: {
    borderRadius: 20,
    fontSize: 14,
    background: "#fff",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  root: {
    flexGrow: 0,
    justifyContent: "center",
    width: "100%",
    backgroundColor: "none",
    "& .MuiTabs-root": {
      alignItems: "center",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      background:
        "linear-gradient(270deg, rgba(255, 128, 8, 0.4) 0%, rgba(255, 200, 55, 0.4) 71.55%);",
      color: "black",
      "&:hover": {
        background:
          "linear-gradient(270deg, rgba(255, 128, 8, 0.3) 0%, rgba(255, 200, 55, 0.3) 71.55%);",
        color: "black",
      },
    },
    "& .MuiAppBar-root": {
      background: "linear-gradient(90deg, #141E30 0%, #1c2f45 100%) !important",
      color: "#FFFFFF",
    },
    "& .MuiTab-textColorPrimary": {
      color: "#252525",
      background: "#ffffff",
      margin: "10px 6px",
      borderRadius: "10rem",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      fontSize: 13,
      fontWeight: 600,
      fontFamily: "Jost !important",
      textTransform: "capitalize",
      "&:hover": {
        background: "#3D5A7C",
        color: "white",
      },
    },
    "& .MuiTabScrollButton-root": {
      width: "40px",
      height: "40px",
      padding: "10px",
      background: "white",
      color: "black",
      borderRadius: "50%",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      margin: "0px 5px",
    },
    "& .Mui-disabled": {
      display: "none !important",
    },
  },
  container: {
    background: "#fff",
    borderRadius: 8,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: 20,
  },
  cardPrimary: {
    background: "#FFFAEF",
    display: "flex",
    alignItems: "center",
    padding: 10,
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    transition: "all ease 0.2s",
    borderRadius: 5,
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },
  cardWhite: {
    background: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    transition: "all ease 0.2s",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },
  imgcontainer: {
    width: "50px",
    height: "50px",
    marginRight: "10px",
    display: "flex",
    borderRadius: "50%",
  },
  // paper: {
  //   clipPath: "polygon(14% 12%, 21% 0, 27% 12%, 90% 13%, 90% 100%, 0 100%, 0 13%)",
  //   position: 'absolute',
  //   backgroundColor: "#fff",
  //   zIndex: '8',
  //   boxShadow: theme.shadows[5],
  //   borderRadius: '0px 0px 10px 10px',
  //   overflow: 'hidden',
  //   padding: theme.spacing(4),
  // },
}));

function ExamSection({ selectedTabId, handleOpenclose, selectedTabName }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState();
  const [SubcategoryName, setSubcategoryName] = React.useState("");
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [examData, setExamData] = useState([]);
  const AppContext = useContext(appContext);
  const CategoryContext = useContext(categoryContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //check if the number is even
  function isEven(number) {
    return (number & 1) === 0;
  }

  useEffect(() => {
    selectedTabId && getSubCategories();
    setSubcategoryName("");
  }, [selectedTabId]);

  useEffect(() => {
    value && getExam();
  }, [value]);
  const getSubCategories = async () => {
    let subCategories = CategoryContext?.megaMenu?.[0]?.["categories"]?.find(
      (subCategories) => subCategories?.id === selectedTabId
    )?.["subCategories"];
    setSubCategoriesData(subCategories);
    setValue(
      selectedTabId === AppContext?.coursesData?.category
        ? AppContext?.coursesData?.subcategory
          ? AppContext?.coursesData?.subcategory
          : subCategories[0]?.id
        : subCategories[0]?.id
    );
  };

  const getExam = async () => {
    let subCategories = CategoryContext?.megaMenu?.[0]?.["categories"]?.find(
      (subCategories) => subCategories?.id === selectedTabId
    )?.["subCategories"];
    let exams = subCategories?.find((exam) => exam.id === value);
    setExamData(exams?.exams);
  };
  console.log(value, "value");
  return (
    <>
      {subCategoriesData?.length === 0 ? (
        <p style={{ fontSize: 16, fontFamily: "Jost", fontWeight: 600 }}>
          No Sub Categories for this categories...
        </p>
      ) : (
        <div className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="none"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {subCategoriesData?.map((data, index) => (
              <Tab
                onClick={() => {
                  setValue(data?.id);
                  setSubcategoryName(data?.sub_category_name);
                  AppContext.setTempSubcategory(data?.sub_category_name);
                }}
                value={data?.id}
                label={data?.sub_category_name}
                style={{ minWidth: 120, minHeight: 42 }}
              />
            ))}
          </Tabs>
          <div value={value}>
            <div className={classes.container}>
              <Grid container spacing={2}>
                {examData && examData?.length === 0 ? (
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Jost",
                      fontWeight: 600,
                    }}
                  >
                    No Exam found for this Sub Categories...
                  </p>
                ) : (
                  examData?.map((i, index) => {
                    return (
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        onClick={() => {
                          console.log(
                            selectedTabId,
                            selectedTabName,
                            value,
                            SubcategoryName,
                            i.id,
                            i.exam_name
                          );
                          AppContext.setCourseData({
                            category: selectedTabId,
                            categoryName: selectedTabName,
                            subcategory: value,
                            subcategoryName: SubcategoryName,
                            exam: i?.id,
                            exam_name: i?.exam_name,
                          });
                          navigate(`/singleexamproducts/${i?.id}`);
                          handleOpenclose();
                        }}
                      >
                        <div
                          key={index}
                          className={
                            isEven(index)
                              ? classes.cardPrimary
                              : classes.cardWhite
                          }
                          style={
                            AppContext?.coursesData?.exam_name === i?.exam_name
                              ? { border: "1px solid #FFB36B" }
                              : { border: "none" }
                          }
                        >
                          <div className={classes.imgcontainer}>
                            <img
                              src={i?.image_url}
                              style={{ width: "100%", borderRadius: "50%" }}
                            />
                          </div>
                          <Typography variant="body2" style={{ fontSize: 12 }}>
                            {i?.exam_name}
                          </Typography>
                        </div>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ExamSection;
