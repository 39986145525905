import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Heading from "./categorysectiondata";
import { getCategoriesList } from "../../service/services";
import { GrFormNext } from "react-icons/gr";
import categoryContext from "../../context/CategoryContext/CategoryContext";
import appContext from "../../context/AppContext"

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiListItem-root": {
      borderBottom: "1px solid #EAEAEA",
      color: "black",
      cursor: "pointer",
      "&:hover": {
        background: 'linear-gradient(270deg, rgba(255, 128, 8, 0.3) 0%, rgba(255, 200, 55, 0.3) 71.55%) !important',
      },
    },
    "& .MuiLink-underlineHover:hover": {
      textDecoration: "none !important",
      background: "black",
    },
    "& .MuiTypography-root": {},
  },
  batchdiscription: {
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: 600,
  },
  modalicon: {
    justifyContent: "center",
  },
  tabHeading: {
    "& .MuiListItemText-primary": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: 500,
      fontSize: 16,
      textTransform: "capitalize !important",
    },
  },
}));
function CategorySection({ setSelectedTabId, selectedTabId, setSelectedTabName }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const AppContext = useContext(appContext);
  
  const CategoryContext = useContext(categoryContext);
  console.log(CategoryContext,"************hello")
  useEffect(() => {
    setSelectedTabId(
      AppContext?.coursesData?.category
        ? AppContext?.coursesData?.category
        : CategoryContext?.megaMenu[0]?.categories[0]?.id
    );
    setSelectedTabName(
      AppContext?.coursesData?.categoryName
        ? AppContext?.coursesData?.categoryName
        : CategoryContext?.megaMenu[0]?.categories[0]?.category_name
    )
  }, []);

  

  return (
    <div className={classes.paper}>
      <Typography
        className={classes.batchdiscription}
        variant="h4"
        display="block"
        gutterBottom
        style={{ margin: "20px 20px 10px 20px" }}
      >
        Categories :-
      </Typography>
      <List className={classes.footercompanysectionlist}>
        {CategoryContext?.megaMenu &&
          CategoryContext?.megaMenu[0]?.categories?.map((data) => (
            <Link
              className={classes.link}
              onClick={() => {
                setSelectedTabId(data?.id); 
                setSelectedTabName(data?.category_name);
              }}
            >
              <ListItem
                style={
                  selectedTabId === data?.id
                    ? {
                      background:
                        "linear-gradient(270deg, rgba(255, 128, 8, 0.4) 0%, rgba(255, 200, 55, 0.4) 71.55%)",
                    }
                    : { background: "#fff" }
                }
              >
                <ListItemText
                  className={classes.tabHeading}
                  primary={data.category_name}
                />
                <ListItemIcon className={classes.modalicon}>
                  <GrFormNext />
                </ListItemIcon>
              </ListItem>
            </Link>
          ))}
      </List>
    </div>
  );
}
export default CategorySection;
