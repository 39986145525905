import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  InputAdornment,
  Link,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { ReactComponent as PhoneIcon } from "../image/loginsignupvector/phoneicon.svg";
import { ReactComponent as FacebookIcon } from "../image/loginsignupvector/facebookicon.svg";
import { ReactComponent as GoogleIcon } from "../image/loginsignupvector/googleicon.svg";
import { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import { GrFormClose } from "react-icons/gr";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import { authorizeMe, signIn, signInViaOTP } from "../../service/services";
import appContext from "../../context/AppContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import AlreadyLogin from "./AlreadyLogin";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "70%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      fontWeight: "700",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: "0.88rem",
      fontFamily: "Jost",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
        marginTop: "0rem",
      },
    },
    "& .MuiDivider-root": {
      width: "11%",
      background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
      height: "0.125rem",
      marginTop: "0.4rem",
      [theme.breakpoints.down("xs")]: {
        width: "23%",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.7rem",
        },
      },
      "& .MuiLink-root": {
        color: "#154FE5",
        fontSize: "0.9375rem",
        fontWeight: "400",
        textTransform: "capitalize",
        cursor: "pointer",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline.Mui-focused:after": {
        borderBottom: "none",
      },
    },
  },
  loginform: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  paper: {
    position: "absolute",
    display: "flex",
    top: "50px",
    backgroundColor: "#FFFAEF",
    zIndex: "8",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    height: "calc(100vh - 100px)",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: "center",
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "70px",
      padding: "1rem",
    },
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  AlreadyLoginModal: {
    zIndex: "1999 !important",
  },
  facebookicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },
  googlefbiconmain: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      justifyContent: "space-evenly",
      margin: "0.5rem 0",
    },
  },
  googleicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },
  passwordheadcont: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1.5rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "300",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  formfootertext: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    color: "#5E5E5E",
    "& .MuiLink-root": {
      color: "#FF9213",
      cursor: "pointer",
    },
  },
  facebooksvg: {
    width: "18px",
    height: "30px",
  },
  googlesvg: {
    width: "30px",
    height: "30px",
  },
  closebtncontainer: {
    position: "absolute",
    top: "40px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0",
    },
    [theme.breakpoints.down("xs")]: {
      top: "60px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  inputhead: {
    marginTop: "1rem",
  },
  checkboxandforgotpass: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
      fontFamily: "Jost",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
      },
    },
  },
  loginwithotp: {
    color: "#154FE5 !important",
    fontSize: "0.9375rem",
    fontWeight: "400",
    textTransform: "capitalize",
    cursor: "pointer",
  },
}));

function SignInModal(props) {
  const {
    handlesignupOpenclose,
    handleloginOpenclose,
    handleForgotpasswordOpenclose,
    handleOtpOpenclose,
    success,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [alreadyLogin, setAlreadyLogin] = useState(false);
  const AppContext = useContext(appContext);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const [state, setState] = useState();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleAlreadyLoginClose = (event) => {
    setAlreadyLogin(false)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const classes = useStyles();

  const initialValues = {
    username: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
    getValues,
  } = useForm({ defaultValues: initialValues });
  const onSubmit = async (data) => {
    try {
      var res = await signIn({
        user: data,
      });
      AppContext.setUser(res?.data?.user);
      AppContext.setToken(res?.data?.token);
      await authorizeMe(res?.data?.token);
      localStorage.setItem("token", res?.data?.token);
      AppContext.setIsLoggedIn(true);
      handleloginOpenclose();
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "User is already logged in on another device."
      ) {
        console.log("setAlreadyLogin", err);
        setAlreadyLogin(true);
        // toast.success(err?.response?.data?.message);
      } else if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  // const handleLoginByGoogleOrFacebook = async (user) => {
  //   try {
  //     AppContext.setUser(user);
  //     AppContext.setIsLoggedIn(true);

  //     handleloginOpenclose();
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     toast.error("Login failed. Please check your credentials.");
  //     AppContext.setUser(null);
  //     AppContext.setIsLoggedIn(false);
  //   }
  // };

  const loginViaOTP = async () => {
    try {
      var res = await signInViaOTP({
        user: {
          username: getValues("username"),
        },
      });
      toast.success("OTP sent to your registered mobile number");
      success(getValues("username"));
      handleOtpOpenclose();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Please enter registered mobile number"
      );
    }
  };

  // const responseFacebook = async (response) => {
  //   try {
  //     if (response.status === "connected") {
  //       const { accessToken } = response.authResponse;

  //       // Send the Facebook access token to your server for verification
  //       const res = await FacebookAuthProvider(accessToken);

  //       // Handle the successful response from your server
  //       if (res?.data?.user && res?.data?.token) {
  //         AppContext.setUser(res.data.user);
  //         AppContext.setToken(res.data.token);
  //         localStorage.setItem("token", res.data.token);
  //         AppContext.setIsLoggedIn(true);
  //         handleloginOpenclose(); // Close the login modal
  //       }
  //     } else {
  //       console.error("Facebook authentication failed");
  //     }
  //   } catch (err) {
  //     // Handle errors, e.g., display an error message to the user
  //     console.error("Facebook authentication error:", err);
  //   }
  // };

  // const responseGoogleSuccess = async (response) => {
  //   try {
  //     // Send the Google access token to your server for verification
  //     const { tokenId } = response;
  //     const res = await authenticateWithGoogle(tokenId);

  //     // Handle the successful response from your server
  //     if (res?.data?.user && res?.data?.token) {
  //       AppContext.setUser(res.data.user);
  //       AppContext.setToken(res.data.token);
  //       localStorage.setItem("token", res.data.token);
  //       AppContext.setIsLoggedIn(true);
  //       handleloginOpenclose(); // Close the login modal
  //     }
  //   } catch (err) {
  //     // Handle errors, e.g., display an error message to the user
  //     console.error("Google authentication error:", err);
  //   }
  // };

  // const responseGoogleFailure = (error) => {
  //   console.error("Google authentication failure:", error);
  // };

  // const authenticateWithGoogle = async () => {
  //   const provider = new GoogleAuthProvider();

  //   // Add the prompt option to force account selection
  //   provider.setCustomParameters({
  //     prompt: 'select_account',
  //   });

  //   try {
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     // Check if the user object contains valid user information
  //     if (user && user.email) {
  //       // Handle successful Google authentication here
  //       console.log("Authenticated user:", user);

  //       // Log in the user in your app
  //       handleLoginByGoogleOrFacebook(user);
  //     } else {
  //       console.error("Google authentication failed");
  //     }
  //   } catch (error) {
  //     // Handle authentication failure
  //     console.error("Google authentication error:", error);
  //   }
  // };

  // const handleFacebookLogin = async () => {
  //   try {
  //     const provider = new FacebookAuthProvider();
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     // Check if the user object contains valid user information
  //     if (user && user.email) {
  //       // Handle successful Facebook authentication here
  //       console.log("Authenticated user:", user);

  //       // Log in the user in your app
  //       handleLoginByGoogleOrFacebook(user);
  //     } else {
  //       console.error("Facebook authentication failed");
  //     }
  //   } catch (error) {
  //     // Handle authentication failure
  //     console.error("Facebook authentication error:", error);
  //   }
  // };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const validStartPattern = /^[9876]/; // Check if starts with 9, 8, 7, or 6

    if (
      value === "" ||
      (validStartPattern.test(value.charAt(0)) && value.length <= 10)
    ) {
      setPhoneNumber(value); // Update phoneNumber state
      setValue("username", value);
    }

    // Error Handling
    if (value.length === 0) {
      setPhoneNumberError("Phone number is required");
    } else if (!validStartPattern.test(value.charAt(0))) {
      setPhoneNumberError("Phone number must start with 9, 8, 7, or 6");
    } else if (value.length < 10) {
      setPhoneNumberError("Phone number must be 10 digits long");
    } else {
      setPhoneNumberError("");
    }
  };

  return (
    <>
      <ToastContainer />

      <div className={classes.container}>
        <div className={classes.closebtncontainer}>
          <Button className={classes.closebtn} onClick={handleloginOpenclose}>
            <GrFormClose />
          </Button>
        </div>
        <div className={classes.paper}>
          <Typography variant="h3" gutterBottom>
            <span className={classes.orangetext}>Hello !</span> Welcome Back
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            log in with your data that you entered during your registration
          </Typography>
          <Divider />
          <div className={classes.googlefbiconmain}>
            <div className={classes.googleicon}>
              {/* <IconButton onClick={authenticateWithGoogle}>
                <GoogleIcon
                  clientId="139238460-9bg8vjmq1095h8fj3f0sv4vo3a9u5o4u.apps.googleusercontent.com"
                  buttonText="Google"
                  onSuccess={responseGoogleSuccess}
                  onFailure={responseGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </IconButton> */}

              <GoogleIcon
                className={classes.googlesvg}
                onClick={() => {
                  window.location.href =
                    "https://api.parikshado.com/v1/auth/google";
                }}
              />
            </div>
            <div className={classes.facebookicon}>
              {/* <IconButton onClick={handleFacebookLogin}>
                <FacebookIcon
                  appId="906835014040756"
                  fields="name,email,picture"
                  callback={responseFacebook}
                />
              </IconButton> */}

              <FacebookIcon
                className={classes.facebooksvg}
                onClick={() => {
                  window.location.href =
                    "https://api.parikshado.com/v1/auth/facebook";
                }}
              />
            </div>
          </div>
          <div className={classes.loginform}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ m: 1, width: "25ch" }}>
                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Mobile Number
                </Typography>
                <TextField
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                  placeholder="9876543210"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                {errors?.username && errors.username?.type === "pattern" && (
                  <p className="errorText ms-0 ">{errors.username?.message}</p>
                )}

                <div className={classes.passwordheadcont}>
                  <Typography variant="h6" gutterBottom>
                    Password
                  </Typography>

                  {!formState.errors.username && getValues("username") ? (
                    <Link
                      variant="h6"
                      gutterBottom
                      onClick={() => {
                        loginViaOTP();
                      }}
                    >
                      Login With OTP
                    </Link>
                  ) : (
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.loginwithotp}
                      onClick={() => {
                        loginViaOTP();
                      }}
                    >
                      Login With OTP
                    </Typography>
                  )}
                </div>
                <TextField
                  {...register("password", { required: true })}
                  type={showPassword ? "text" : "password"}
                  placeholder="**********"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <LockOpenOutlinedIcon />
                          ) : (
                            <LockOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && errors.password.type === "required" && (
                  <p className="errorText ms-0 mt-2">Password is required</p>
                )}
                <div className={classes.checkboxandforgotpass}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox onChange={handleChange} name="checkedG" />
                    }
                    label="Remember Me"
                  />
                  <Link
                    variant="h6"
                    gutterBottom
                    onClick={handleForgotpasswordOpenclose}
                  >
                    Forgot Password?
                  </Link>
                </div>
                <Button
                  type="submit"
                  className={classes.bluecustombtn}
                  fullWidth
                >
                  Login
                </Button>
              </FormControl>
              <Typography
                className={classes.formfootertext}
                variant="body1"
                gutterBottom
              >
                Don’t Have An Account?{" "}
                <Link onClick={handlesignupOpenclose}>Sign Up</Link>
              </Typography>
            </form>
          </div>
        </div>
        <Modal
          open={alreadyLogin}
          onClose={handleAlreadyLoginClose}
          className={classes.AlreadyLoginModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AlreadyLogin handleAlreadyLoginClose={handleAlreadyLoginClose} phoneNumber={phoneNumber}/>
        </Modal>
      </div>
    </>
  );
}
export default SignInModal;
