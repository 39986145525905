import React from "react";
import { makeStyles } from "@material-ui/core";
import SinglePageContent from "./singlepagecontent";
import PageHead from "../../../pageheading/pageheading";
import { useParams } from "react-router-dom";
import practicecomponentcontext from "../../context";
import { useContext } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  singlepagemain: {
    marginBottom: "10rem",
  },
}));

function EBooksSinglePageDescription() {
  const classes = useStyles();
  const {id} = useParams()
  const {getEBookDetails,eBookDetail} = useContext(practicecomponentcontext);
  const Heading = [
    {
      id: 1,
      // headingsmall: "Explore Your Study",
      // headingmain: "All In One General Studies 2023 ",
      breadcrumb:"E-Books / Product"
    },
  ];
  useEffect(()=>{
    getEBookDetails(id)
  },[id])
  console.log(eBookDetail,"getEBookDetails")
  return (
    <div className={classes.singlepagemain}>
      <PageHead Heading={Heading}  eBookDetail={eBookDetail}/>
      <SinglePageContent  eBookDetail={eBookDetail}  />
    </div>
  );
}
export default EBooksSinglePageDescription;
