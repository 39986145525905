import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { ReactComponent as AttemptsIcon } from "../../image/quizzesvector/attemps_icon.svg";
import { ReactComponent as QuestionMark } from "../../image/quizzesvector/question_icon.svg";
import { ReactComponent as ClockIcon } from "../../image/quizzesvector/clockicon.svg";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { useContext } from "react";
import appContext from "../../../context/AppContext";
import popupContext from "../../../context/PopupContext/PopupContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    "& .slick-slide": {
      padding: "0 1rem !important",
    },
    "& .MuiAppBar-colorPrimary": {
      width: "55rem",
      height: "4.8rem",
      backgroundColor: "#FFDBAB",
      paddingTop: "0.4rem",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    "& .MuiToolbar-regular": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
      borderRadius: "0.3rem",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "rgba(143, 143, 143, 1)",
    fontSize: "0.8rem",
    fontFamily: "Jost",
    fontWeight: "500",
    background: "#FFFFFF",
    borderRadius: "0.3rem",
    minWidth: 300,
    [theme.breakpoints.up("sm")]: {
      width: "23rem",
    },
  },
  inputInput: {
    padding: "1rem",
    width: "100%",
    boxShadow: "0px 3px 11px 0px rgba(0, 0, 0, 0.17) inset",
    borderRadius: "0.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontFamily: "Jost",
      color: "#727272",
    },
  },
  bluecustombtn: {
    width: "7rem",
    height: "2rem",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "Jost",
      fontWeight: "300",
    },
  },
  selectInput: {
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.9rem",
    color: "#727272",
    background: "white",
    border: "1px solid white",
    borderRadius: "0.3rem",
    width: "255px",
    height: "50px",
    padding: "1rem",
  },
  heading: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "0 1rem",
    },
    "& .MuiTypography-h3": {
      fontSize: "2rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
      color: "rgba(34, 56, 82, 1)",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h6": {
      fontSize: "0.9rem",
      fontFamily: "Jost",
      fontWeight: "400",
      color: "rgba(114, 114, 114, 1)",
    },
  },
  link: {
    textDecoration: "none",
    color: "rgba(21, 79, 229, 1)",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "0.7rem",
  },
  quizzecontainer: {
    width: "100%",
    marginTop: "2rem",
    paddingLeft: "0.5rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  cardroot: {
    flexBasis: "33.3333%",
    maxHeight: 291,
    maxWidth: "23.5%",
    margin: "0 5px",
    marginBottom: "2rem",
    "& .MuiTypography-h3": {
      textTransform: "capitalize",
    },
    "& .MuiButton-fullWidth": {
      width: "100%",
      padding: "0.7rem",
      margin: "0",
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    },
    "& .MuiCardActions-root": {
      padding: "0",
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiButton-root": {
        background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
      },
    },
    "& .MuiButton-label": {
      fontWeight: "500",
    },
  },
  cardcontent: {
    padding: "0.7rem 0 0 0",
  },
  cardimage: {
    background: "#FFFFFF",
    borderRadius: "100%",
    filter: "drop-shadow(2px 4px 6px #FFE8C095)",
    width: "90px",
    height: "90px",
    marginBottom: "1rem",
    "& img": {
      borderRadius: "50%",
      width: "100%",
      height: "100%",
    },
  },

  title: {
    padding: "0 0.5rem",
    color: "#223852",
    fontFamily: "'Jost', sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "1.5rem",
    textTransform: "capitalize",
    whiteSpace: "wrap",
    height: "27px",
    marginBottom: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  subtitle: {
    marginBottom: "6px",
    fontSize: "15px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  filterIcon: {
    background: "#fff",
    borderRadius: 6,
    height: 40,
    width: 45,
    padding: "10px 12px",
    marginLeft: 10,
  },
  searchIcons: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  input: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  headingQuiz: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem !important",
    },
  },
  subheadingQuiz: {
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem !important",
    },
  },
  pageheadhr: {
    margin: "0",
    marginTop: "10px",
    width: "10.9375rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
}));

export default function QuizesCard({ data }) {
  const classes = useStyles();
  const { handleloginOpenclose } = useContext(popupContext);
  const { isLoggedIn, token } = useContext(appContext);

  return (
    <Card className={classes.cardroot} key={data?.quiz_name}>
      <CardContent className={classes.cardcontent} align="center">
        <div className={classes.cardimage}>
          <img src={ data?.image_url || require("../../image/notFoundData.png")} alt="img" />
        </div>
        <Typography variant="h3" className={classes.title} gutterBottom>
          {data?.quiz_name}
        </Typography>
        <Typography className={classes.subtitle} color="textSecondary">
          <AttemptsIcon /> {data?.attempts}  Attempts
        </Typography>
        <Typography className={classes.subtitle} color="textSecondary">
          <QuestionMark /> {data?.no_of_question} Ques
        </Typography>
        <Typography className={classes.subtitle} color="textSecondary">
          <ClockIcon /> {data?.duration} Min
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => {
            if (isLoggedIn && token) {
              window.open(`/quizzes/${data.id}`, '_blank');
            } else {
              handleloginOpenclose();
            }
          }}
          fullWidth
        >
          Start Quizz
        </Button>
      </CardActions>
    </Card>
  );
}
