import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ReactComponent as SeperatePanel } from "../image/dashboard/seperatepanel.svg";
import ArrowImg from "../image/dashboard/arrow.png";
import AppContext from "../../context/AppContext";
import { getLearnerMenu } from "../../service/services";
import { ReactComponent as HelpDeskIcon } from "../image/dashboard/helpdesk.svg";
import { ReactComponent as SettingsIcon } from "../image/dashboard/setting.svg";
import IconComponent from "./IconComponent";
import { ReactComponent as MyDashboard } from "../image/dashboard/MyDashboard.svg";
import { ReactComponent as VideoCoursesIcon } from "../image/dashboard/MyVideoCourses.svg";
import { ReactComponent as PurchaseIcon } from "../image/dashboard/MyPurchase.svg";
import { ReactComponent as AchivementIcon } from "../image/dashboard/MyAchievements.svg";
import { ReactComponent as StudyMaterialIcon } from "../image/dashboard/MyStudyMaterials.svg";
import { ReactComponent as TestSeriesIcon } from "../image/dashboard/MyTestSeries.svg";
import { ReactComponent as MyBookIcon } from "../image/dashboard/MyBooks.svg";
import { ReactComponent as MyLiveClassesIcon } from "../image/dashboard/MyLiveClasses.svg";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootmain: {
    flexGrow: 1,
    backgroundColor: "#FFF",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
  },
  tabs: {
    width: "80%",
    "& .MuiTab-root": {
      color: "#141E30",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.85rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "0",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "auto",
      padding: "0.5rem 1rem",
      margin: "0.5rem 0rem",
    },
    "& .MuiTab-textColorInherit": {
      opacity: "1",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      background: `url(${ArrowImg}) no-repeat center right`,
      textTransform: "capitalize !important",
      textWrap: "nowrap",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      borderRadius: "0.375rem",
      background: "#FFF0D0",
      boxShadow: "0px 10px 24px 0px rgba(38, 51, 77, 0.08)",
      "& svg": {
        marginRight: "10px",
        marginBottom: "0px",
        padding: "0.3rem",
        background: "#fff",
        borderRadius: "5px",
      },
    },
    "& .MuiTab-labelIcon .MuiTab-wrapper": {
      "& svg": {
        marginRight: "10px",
        marginBottom: "0px",
        padding: "0.3rem",
        background: "#FFF6DB",
        borderRadius: "5px",
      },
    },
  },
}));

export default function DashboardSidePannel({ value, handleChange }) {
  const classes = useStyles();
  const [learnerMenu, setLearnerMenu] = useState([]);
  const { isLoggedIn, token, user } = useContext(AppContext);

  const userName = user?.full_name;

  useEffect(() => {
    if (isLoggedIn) {
      getLearnerMenu(token)
        .then((res) => {
          setLearnerMenu(res?.data?.menu);
        })
        .catch((e) => console.log("dalsfknlk", e));
    }
  }, [isLoggedIn]);

  return (
    <div className={classes.rootmain}>
      <Tabs
        indicatorColor="none"
        scrollButtons="none"
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue, event);
        }}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          icon={<MyDashboard />}
          label="My Dashboard"
          value={1}
          {...a11yProps(15)}
        />
        <Tab
          icon={<TestSeriesIcon />}
          label="My Test Series"
          value={2}
          {...a11yProps(15)}
        />
        <Tab
          icon={<StudyMaterialIcon />}
          label="My Study Materials"
          value={3}
          {...a11yProps(15)}
        />
        <Tab
          icon={<MyBookIcon />}
          label="My Books"
          value={4}
          {...a11yProps(15)}
        />
        <Tab
          icon={<VideoCoursesIcon />}
          label="My Video Courses"
          value={5}
          {...a11yProps(15)}
        />
        <Tab
          icon={<MyLiveClassesIcon />}
          label="My Live Classes"
          value={6}
          {...a11yProps(15)}
        />
        <Tab
          icon={<PurchaseIcon />}
          label="My Orders"
          value={8}
          {...a11yProps(15)}
        />
        <Tab
          icon={<AchivementIcon />}
          label="My Achievements"
          value={9}
          {...a11yProps(15)}
        />
        {learnerMenu?.map((data, index) => (
          <Tab
            key={index}
            icon={<IconComponent iconName={data?.icon} />}
            // value={
            //   (data?.name === "Doubt Forum" && 10) ||
            //   (data?.name === "Time Table" && 7) ||
            //   (data?.name === "Feedback" && 11)
            // }
            value={parseInt(data?.index_id)}
            label={data?.name}
            // {...a11yProps(index)}
          />
        ))}
        {/* <Tab
          icon={<ReferandEarnIcon />}
          label="refer and earn"
          {...a11yProps(14)}
        /> */}
        <Tab
          icon={<HelpDeskIcon />}
          label="help desk"
          value={15}
          {...a11yProps(15)}
        />
        <Tab
          icon={<SettingsIcon />}
          label="Settings"
          value={16}
          {...a11yProps(16)}
        />
        <Tab
          icon={<SeperatePanel />}
          label="go to seperate panel"
          value={18}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_API_STUDENT_SEPARATE_URL}/?token=${token}&userName=${userName}`,
              "_blank"
            );
          }}
        />
      </Tabs>
    </div>
  );
}
