import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardMedia,
  Box,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { Link, useNavigate } from "react-router-dom";
import testserieslogo from "../image/testseriesvector/testserieslogo.svg";
import booklogo from "../image/testseriesvector/booklogo.svg";
import withStyles from "@material-ui/core/styles/withStyles";
import vector from "../image/dashboard/logo.svg";
import walletlogo from "../image/dashboard/walletimage.svg";
import courseimage from "../image/dashboard/course.svg";

const useStyles = makeStyles((theme) => ({
 maincontainer:{
    width: '331px',
    height: '337px',
    marginBottom:"10rem",
    fill: 'var(--white-100, #FFF)',
    filter: 'drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.10))',
    "& .MuiTypograghy-h5":{
    color: '#262626',
    fontFamily: 'Jost',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 450,
    lineHeight: '22px',/* 137.5% */
    },
    "& .MuiTypograghy-h4":{
        color: '#353535',
        fontFamily: 'Jost',
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '22px',/* 137.5% */
        },
 },
 mainwallet:{
 display:'flex',
 gap:'2rem',
 },
 walletlogo:{
    marginRight:'2.5rem',
 },
 line:{
    width: '25%',
    height: '0.2rem',
    marginLeft:'4rem',
    borderRadius: '9px',
    background: 'linear-gradient(270deg, rgba(255, 128, 8, 0.60) 44.89%, rgba(255, 200, 55, 0.60) 100%)',


 },
 courses:{
    alignItems:'center',
    marginLeft:'6rem',
    marginTop:'3rem',
 }
}));

function CurrentBalance({ CardData, setOnViewClicked }) {
  const classes = useStyles();

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#FFEFCB",
      width: "84%",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#00A019",
    },
  }))(LinearProgress);

  return (
    <>
    <div className={classes.maincontainer}>
        <div className={classes.mainwallet}>
     <div className={classes.vector}  >

     <img src={walletlogo} alt="img"/>
    
     </div>
    
    <Typography variant="h6">My Wallet</Typography>
    </div>
    <div className={classes.line}></div>
    <div className={classes.courses}>
        <Typography variant="h5">Current Balance</Typography>
        <Typography variant="h4">₹36,000</Typography>
        <img src={courseimage} alt="img"/>
    </div>
    </div>
    </>
  );
}
export default CurrentBalance;
