import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import sscicon from "../image/Card/sscicon.png";
import calendericon from "../image/Card/calendericon.png";
import questionicon from "../image/Card/questionicon.png";
import clockicon from "../image/Card/clockicon.png";
import starwithman from "../image/Card/starwithman.png";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "25%",
    marginLeft: "1rem",
    marginBottom: "20rem",
    marginTop: "2rem",
   
  },
  root: {
    position: "relative",
    "& .MuiTypography-h3": {
      color: " #000",
      fontFamily: "Playfair Display",
      fontSize: "1.375rem",
      fontWeight: 600,
      lineHeight: " 1.875rem",
    },
    "& .MuiCardContent-root": {
      padding: "0px !important",
    },
    "& .MuiTypography-body2": {
      background: "#FF3939",
      color: "#FFF",
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: 500,
      position: "absolute",
      textAlign: "center",
      textTransform: "uppercase",
      dropShadow: "(0px 4px 15px rgba(0, 0, 0, 0.15))",
      padding:"0.5rem",
      zIndex: 1,
      top:"0.8rem",
    },
  },
  attemptbtn: {
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.9375rem",
    fontWeight: 400,
    textTransform: "uppercase",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 92.06%)",

    width: "100% ",
    padding: "0.8rem",
    color: " #FFF",
  },

  imagediv: {
    background:
      "linear-gradient(317deg, #FFD9B5 29.26%, rgba(255, 238, 195, 0.28) 97.42%)",
    borderRadius: "0rem 0rem 0.375rem 0.375rem",

    padding: "1rem",
    "& .MuiTypography-h1": {
      color: " #000",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "1.625rem",
      fontWeight: 500,
      lineHeight: "1.75rem",
      textTransform: "uppercase",
      padding: "0.5rem",
    },
    "& .MuiTypography-h2": {
      color: "#FF3939",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "1.125rem",
      fontWeight: 600,
      textTransform: "uppercase",
      marginTop: "0.5rem",
    },
  },
  imagecontainer: {
    background: "#FFF",
    height: "125px",
    width: "130px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: "1.5rem",
    marginTop:"1.5rem",
  },

  bottomcontainer: {
    textAlign: "center",
    background:"#FFF",

    "& .MuiTypography-h6": {
      color: " #000",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontWeight: 400,
      textTransform: "uppercase",
      marginTop: "1rem",
    },
    "& .MuiTypography-h5": {
      color: "#FF3939",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "uppercase",
      lineHeight: "normal",
      marginTop: "0px !important",
    },
    "& .MuiTypography-h4": {
      color: "#000",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "1.125rem",
      fontWeight: 500,
      textTransform: "capitalize",
      lineHeight: "1.6875rem",
      padding: "1.5rem",
    },
  },
  heading: {
    color: " #243B55",
    fontFamily: "Jost",
    fontSize: "1.5rem",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  timestyle: {
    color: " #BD4949",
    textAlign: "right",
    fontFamily: "Jost",
    fontSize: " 0.5rem",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  lighttextstyle: {
    color: "#243B55",
    fontFamily: "Jost",
    fontWeight: 400,
    textTransform: "capitalize",
  },
}));

const Card2 = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <Typography variant="body2"> Free Test series</Typography>
          <CardActionArea>
            <CardContent>
              <div className={classes.imagediv}>
                <div className={classes.imagecontainer}>
                  <img src={sscicon} alt="img" />
                </div>
                <Typography variant="h1">
                  SSC: direct & indirect speech
                </Typography>
                <Typography variant="h2">BILINGUAL</Typography>
              </div>
              <div className={classes.bottomcontainer}>
                <Typography variant="h6">clerk prelims</Typography>
                <Typography variant="h5">100+ total test</Typography>
                <Typography variant="h4">
                  Teachers' Test Mate | Unlimited Tests for SSC
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>

          <Button className={classes.attemptbtn}>&nbsp;&nbsp;Start now</Button>
        </Card>
      </div>
    </>
  );
};

export default Card2;
