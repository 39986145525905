import { Divider, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../../../styles";

function BillingAddress({ data }) {
  const classes = useStyles();
  return (
    <div
      className={`${classes.dflex} ${classes.w69}  ${classes.w100mobile} ${classes.dblockmobile} ${classes.bordergrey} ${classes.justifyspacebetween} ${classes.mt1}`}
    >
      <div className={`${classes.p1} ${classes.w100mobile} `}>
        <Typography
          className={`${classes.fontsize6} ${classes.fw700} ${classes.fontfamilyPlayfairDisplay}`}
        >
          Billing Address
        </Typography>
        <Typography
          className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorblack1} ${classes.mt1}`}
        >
          {data?.order_data?.user_details?.full_name}
        </Typography>
        <Typography
          component="p"
          className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w95} ${classes.my05}`}
        >
          {data?.order_data?.delivery_address?.address1
            ? `${data?.order_data?.delivery_address?.address1}, `
            : ""}
          {data?.order_data?.delivery_address?.address2
            ? `${data?.order_data?.delivery_address?.address2}, `
            : ""}
          {data?.order_data?.delivery_address?.address3
            ? `${data?.order_data?.delivery_address?.address3}, `
            : ""}
          {data?.order_data?.delivery_address?.landmark
            ? `${data?.order_data?.delivery_address?.landmark}, `
            : ""}
          {data?.order_data?.delivery_address?.city
            ? `${data?.order_data?.delivery_address?.city}, `
            : ""}
          {data?.order_data?.delivery_address?.state
            ? `${data?.order_data?.delivery_address?.state}, `
            : ""}
          {data?.order_data?.delivery_address?.pin_code
            ? `${data?.order_data?.delivery_address?.pin_code}`
            : ""}
        </Typography>
        <div>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorblack1} ${classes.mt1}`}
          >
            Phone Number:
          </Typography>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w95} ${classes.my05}`}
          >
            &nbsp; {data?.order_data?.user_details?.phone}
          </Typography>
        </div>
        <div>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorblack1} ${classes.mt1}`}
          >
            Email:
          </Typography>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w95} ${classes.my05}`}
          >
            &nbsp; {data?.order_data?.user_details?.email}
          </Typography>
        </div>
      </div>
      {/* <Divider
        className={`${classes.dnonemobile}`}
        orientation="vertical"
        flexItem
        style={{ margin: "1.5rem 0.5rem" }}
      /> */}
      {/* <div className={`${classes.p1} ${classes.w69} ${classes.w100mobile}`}>
        <Typography
          className={`${classes.fontsize6} ${classes.fw700} ${classes.fontfamilyPlayfairDisplay}`}
        >
          Shipping Address
        </Typography>
        <Typography
          className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorblack1} ${classes.mt1}`}
        >
          Kevin Gilbert
        </Typography>
        <Typography
          component="p"
          className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w95} ${classes.w100mobile} ${classes.my05}`}
        >
          East Tejturi Bazar, Word No. 04, Road No. 13/x, House no. 1320/C, Flat
          No. 5D, Dhaka - 1200, Bangladesh
        </Typography>
        <div>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorblack1} ${classes.mt1}`}
          >
            Phone Number:
          </Typography>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w95} ${classes.my05}`}
          >
            &nbsp; +1-202-555-0118
          </Typography>
        </div>
        <div>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw500} ${classes.fontfamiljost} ${classes.fcolorblack1} ${classes.mt1}`}
          >
            Email:
          </Typography>
          <Typography
            component="span"
            className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w95} ${classes.my05}`}
          >
            &nbsp; kevin.gilbert@gmail.com
          </Typography>
        </div>
      </div>
      <Divider
        className={`${classes.dnonemobile}`}
        orientation="vertical"
        flexItem
        style={{ margin: "1.5rem 0.5rem" }}
      />
      <div className={`${classes.p1} ${classes.w69} ${classes.w100mobile}`}>
        <Typography
          className={`${classes.fontsize6} ${classes.fw700} ${classes.fontfamilyPlayfairDisplay}`}
        >
          Order Notes
        </Typography>

        <Typography
          component="p"
          className={`${classes.fontsize3} ${classes.fw400} ${classes.fontfamiljost} ${classes.w95} ${classes.w100mobile} ${classes.my05}`}
        >
          {data?.product_data?.delivery_info}
        </Typography>
      </div> */}
    </div>
  );
}

export default BillingAddress;
