import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import PageHead from "../pageheading/pageheading";
import SecHead from "../Home/sectionHeading/sectionheading";
import { ReactComponent as YellowDot } from "../image/careersvector/yellowdot.svg";
import { ReactComponent as CurrencyIcon } from "../image/careersvector/currencyicon.svg";
import { TfiEmail } from "react-icons/tfi";
import { SlLocationPin } from "react-icons/sl";
import { ReactComponent as PhoneIcon } from "../image/loginsignupvector/phoneicon.svg";
import { RiUser3Line } from "react-icons/ri";
import { VscSymbolFile } from "react-icons/vsc";
import { careersApplicationSubmit } from "../../service/services";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
  },
  container: {
    paddingTop: "2.5rem",
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiTypography-h3": {
      fontSize: "1.6rem",
      fontFamily: "Playfair Display",
      fontWeight: "600",
      color: "#000000",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiTypography-h6": {
      marginTop: "2rem",
      fontFamily: "Jost",
      color: "#4B4B4B",
      fontSize: "1.2rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        marginTop: "3rem",
        fontSize: "0.9375rem",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
      "&::file-selector-button": {
        display: "none",
      },
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiInputBase-root ": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },

    "& .MuiTextField-root": {
      marginBottom: "1rem",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiFormControlLabel-label": {
        fontFamily: "Jost",
        fontSize: "0.9rem",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.65rem",
        },
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& input:-internal-autofill-selected": {
        backgroundColor: "#fff",
      },
      "& .MuiButton-fullWidth": {
        width: "80%",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
    },
  },
  content: {
    marginTop: "3rem",
    display: "flex",
    marginBottom: "8rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: "3rem",
      marginBottom: "13rem",
    },
  },
  secheadhr: {
    width: "171px",
    marginTop: "5px",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.6rem",
      marginBottom: "0rem",
    },
  },
  jobdescription: {
    marginBottom: "2rem",
    width: "700px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 1rem",
      marginBottom: "0",
    },
  },
  listroot: {
    width: "100%",
    marginTop: "1.6rem",
    maxWidth: 650,
    backgroundColor: theme.palette.background.paper,
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "& .MuiListItem-root": {
      alignItems: "baseline",
      paddingLeft: 0,
    },
    "& .MuiListItemText-primary": {
      fontFamily: "Jost",
      fontSize: "1.2rem",
      marginRight: "1rem",
      color: "#000000",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiListItemText-multiline": {
      display: "flex",
    },
    "& .MuiListItemText-secondary": {
      fontFamily: "Jost",
      fontSize: "1.2rem",
      color: "#4B4B4B",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
  },
  form: {
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 1rem",
    },
    "& .MuiTypography-h6": {
      [theme.breakpoints.down("xs")]: {
        marginTop: "1rem",
      },
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "2.5rem",
    padding: "0.5rem 1.2rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  writemessageinput: {
    "& .MuiInputBase-input": {
      padding: "2.5rem 0",
      fontFamily: "Jost",
      fontWeight: "500",
    },
  },
  required :{
    color: "red",
  }
}));

function ApplicationSubmission() {
  const classes = useStyles();
  const location = useLocation();
  const { careersData } = location.state;
  console.log("careersData", careersData);
  const param = useParams();

  const Heading = [
    {
      id: 1,
      headingsmall:
        "Dedicated to providing exceptional customer support at all times.",
      headingmain: param.name,
    },
  ];

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    location: "",
    phone: "",
    cv: null,
    expected_ctc: "",
    current_ctc:"",
    message: "",
 qualification:"",
 experience:""
  });

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    location: "",
    phone: "",
    cv: "",
    expected_ctc: "",
    message: "",
    current_ctc:"",
    qualification:"",
    experience:"",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, cv: e.target.files[0] });
    setErrors({ ...errors, cv: "" });
  };

  const handleSubmit = async () => {
    const validationErrors = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] === "" || (key === "cv" && !formData[key])) {
        validationErrors[key] = "This field is required";
      }
    });

    setErrors(validationErrors);

    // If there are validation errors, prevent form submission
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const formDataToSend = new FormData();

    formDataToSend.append(
      "job_seeker",
      JSON.stringify({
        career_id:param.id,
        full_name: formData.full_name,
        phone: formData.phone,
        email: formData.email,
        location: formData.location,
        expected_ctc: formData.expected_ctc,
        message: formData.message,
        current_ctc:formData.current_ctc,
        experience:formData.experience,
        qualification:formData.qualification,
      })
    );
   
    formDataToSend.append("file", formData.cv);

    try {
      const response = await careersApplicationSubmit(formDataToSend);
      console.log(response);
      if (response.success === true) {
        toast.success(response.message);
        setFormData({
          full_name: "",
          email: "",
          location: "",
          phone: "",
          cv: null,
          expected_ctc: "",
          message: "",
          current_ctc:"",
          experience:"",
          qualification:""
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    // Custom validation for phone number
    if (name === 'phone') {
      if (!/^[7-9][0-9]{9}$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Phone number must start with 7, 8, or 9 and be 10 digits long',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '', 
        }));
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.content}>
          <div className={classes.descriptionsection}>
            <div className={classes.jobdescription}>
              <Typography variant="h3" gutterBottom>
                JOB DESCRIPTION
              </Typography>
              <hr className={classes.secheadhr} />
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{ __html: careersData?.description }}
              />
            </div>
            <div className={classes.jobdescription}>
              <Typography variant="h3" gutterBottom>
                Responsibilities
              </Typography>
              <hr className={classes.secheadhr} />
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{
                  __html: careersData?.responsibilities,
                }}
              />
            </div>
            <div className={classes.jobdescription}>
              <Typography variant="h3" gutterBottom>
                Required Skills
              </Typography>
              <hr className={classes.secheadhr} />
              <Typography
                variant="h6"
                primary="Experience:"
                dangerouslySetInnerHTML={{
                  __html: careersData?.skills,
                }}
              />
            </div>
            <div className={classes.jobdescription}>
              <Typography variant="h3" gutterBottom>
                Eligibility required
              </Typography>
              <hr className={classes.secheadhr} />
              <List className={classes.listroot}>
                <ListItem>
                  <Typography
                    variant="h6"
                    dangerouslySetInnerHTML={{
                      __html: careersData?.qualification,
                    }}
                  />
                </ListItem>
                <ListItem>
                  {/* <ListItemIcon>
                    <YellowDot />
                  </ListItemIcon> */}
                  <ListItemText
                    primary="Experience:"
                    secondary={careersData?.experience}
                  />
                </ListItem>
                <ListItem>
                  {/* <ListItemIcon>
                    <YellowDot />
                  </ListItemIcon> */}
                  <ListItemText
                    primary="Location:"
                    secondary={careersData?.job_Location?.name}
                  />
                </ListItem>
                <ListItem>
                  {/* <ListItemIcon>
                    <YellowDot />
                  </ListItemIcon> */}
                  <ListItemText primary="Job Type::" secondary="Full Time" />
                </ListItem>
              </List>
            </div>
          </div>
          <div className={classes.form}>
            <div className={classes.messageform}>
              <FormControl sx={{ m: 1, width: "25ch" }}>
                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Your Name<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="name"
                  type="text"
                  placeholder="Enter your Name"
                  onChange={handleChange}
                  name="full_name"
                  value={formData.full_name}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <RiUser3Line />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.full_name}
                  helperText={errors.full_name}
                />

                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Email Address<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="email"
                  type="email"
                  placeholder="Johndeere43545@gmail.com"
                  onChange={handleChange}
                  name="email"
                  value={formData.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TfiEmail />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                />

                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Location<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="location"
                  type="text"
                  placeholder="Address"
                  onChange={handleChange}
                  name="location"
                  value={formData.location}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SlLocationPin />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.location}
                  helperText={errors.location}
                />

                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Mobile No.<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="phone"
                  type="text"
                  placeholder="+91 8965 458 888"
                  onChange={handleChange1}
                  name="phone"
                  value={formData.phone}
                  InputProps={{
                    inputProps: { pattern: "[0-9]*" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />

                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Drop your CV<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="cv"
                  type="file"
                  placeholder="Choose File"
                  onChange={handleFileChange}
                  name="cv"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VscSymbolFile />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.cv}
                  helperText={errors.cv}
                />

                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Desired salary<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="expected_ctc"
                  type="text"
                  placeholder="Type Here"
                  onChange={handleChange}
                  name="expected_ctc"
                  value={formData.expected_ctc}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CurrencyIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.expected_ctc}
                  helperText={errors.expected_ctc}
                />
                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Current salary<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="current_ctc"
                  type="text"
                  placeholder="Type Here"
                  onChange={handleChange}
                  name="current_ctc"
                  value={formData.current_ctc}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CurrencyIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.current_ctc}
                  helperText={errors.current_ctc}
                />
                 <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Qualification<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="qualification"
                  type="text"
                  placeholder="Type Here"
                  onChange={handleChange}
                  name="qualification"
                  value={formData.qualification}
                  error={!!errors.qualification}
                  helperText={errors.qualification}
                />
                 <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Experience<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="experience"
                  type="text"
                  placeholder="Type Here"
                  onChange={handleChange}
                  name="experience"
                  value={formData.experience}
                  error={!!errors.experience}
                  helperText={errors.experience}
                />
                <Typography
                  className={classes.inputhead}
                  variant="h6"
                  gutterBottom
                >
                  Write a Message<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  className={classes.writemessageinput}
                  id="message"
                  type="text"
                  onChange={handleChange}
                  name="message"
                  value={formData.message}
                  error={!!errors.message}
                  helperText={errors.message}
                />

                <Button
                  className={classes.bluecustombtn}
                  fullWidth
                  onClick={handleSubmit}
                >
                  Submit Application
                </Button>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationSubmission;
