import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import { toast } from "react-toastify";

import DontWorryVector from "../image/onlinesupportvector/dontworryvector.png";
import { ReactComponent as AttchFileIcon } from "../image/onlinesupportvector/AttchFile.svg";
import { ReactComponent as EmailIcon } from "../image/onlinesupportvector/Email.svg";
import { ReactComponent as MobileNoIcon } from "../image/onlinesupportvector/MobileNo.svg";
import { ReactComponent as YourNameIcon } from "../image/onlinesupportvector/YourName.svg";
import { PostOnlineSupport } from "../../service/services";
import AppContext from "../../context/AppContext";
import CategoryContext from "../../context/CategoryContext/CategoryContext";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
    marginBottom: "15rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "14rem",
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: "0.88rem",
      fontFamily: "Jost",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
        marginTop: "0rem",
      },
    },
    "& .MuiInputBase-root": {
      background: "white",
      padding: "1rem 1rem",
      marginTop: "0.5rem",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiFormLabel-root": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "0px",
      },
      "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: "transparent",
        borderRadius: "6px",
      },
      "& .MuiLink-root": {
        color: "#154FE5",
        fontSize: "0.9375rem",
        fontWeight: "400",
        textTransform: "capitalize",
        cursor: "pointer",
      },
    },
  },
  loginform: {
    marginTop: "1rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  dontworrymain: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2.5rem",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  dontworrycontent: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  dontworrymainheading: {
    display: "flex",
    flexDirection: "column",
  },
  secheadhr: {
    width: "175px",
    margin: "0",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
  },
  dontworryimgcontainer: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  forminner: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "0rem",
    },
  },
  inputcontainer: {
    width: "47%",
    display: "flex",
    flexDirection: "column",
    "& input[type=file]": {
      "&::file-selector-button": {
        display: "none",
      },
      opacity: "0.5",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  bluebtn: {
    width: "50%",
    background: "linear-gradient(90deg, #141E30 16.46%, #243B55 100%)",
    color: "white",
    padding: "0.7rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textareacontainer: {
    marginBottom: "2rem",
    marginTop: "1.5rem",
  },
  defaultselect: {
    opacity: "0.5",
  },
  inputRoot: {
    "& .MuiAutocomplete-input": {
      padding: "0 !important",
    },
  },
}));

function OnlineEducationSection() {
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [exams, setExams] = useState("");
  const [exam, setExam] = useState("");
  const [examId, setExamId] = useState("");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  console.log(name, "name");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [product, setProduct] = useState("");
  const [productId, setProductId] = useState("");
  const [issue, setIssue] = useState("");
  const [issueId, setIssueId] = useState("");
  const products = [
    {
      id: 1,
      name: "Book",
    },
    {
      id: 2,
      name: "Notes",
    },
    {
      id: 3,
      name: "Quizzes",
    },
    {
      id: 4,
      name: "Previous Year Paper",
    },
    {
      id: 5,
      name: "Test Series",
    },
    {
      id: 5,
      name: "Study Material",
    },
    {
      id: 6,
      name: "E-Books",
    },
  ];
  const [issues, setIssues] = useState([]);

  const handleCategoryChange = (event, value) => {
    setCategory(value);
    setCategoryId(value.id);
  };
  const handleExamChange = (event, value) => {
    setExam(value);
    setExamId(value.id);
  };
  const handleProductChange = (event, value) => {
    setProduct(value);
    setProductId(value.id);
    
  console.log("productId",value);
  };
  useEffect(() => {
    // Filter the issues based on the selected product
    if (productId !== 1) {
      setIssues([
        {
          id: 1,
          name: "Purchase Issue",
        },
        {
          id: 2,
          name: "Payment Issue",
        },
      ]);
    } else {
      setIssues([
        {
          id: 1,
          name: "Purchase Issue",
        },
        {
          id: 2,
          name: "Payment Issue",
        },
        {
          id: 3,
          name: "Delivery Issue",
        },
        {
          id: 4,
          name: "Return Issue",
        },
      ]);
    }
  },[productId]);
  const handleIssueChange = (event, value) => {
    setIssue(value);
    setIssueId(value.id);
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "We are here for you!",
      headingmain: "Don’t Worry",
    },
  ];
  const data = useContext(AppContext);
  console.log(data, "data");
  const allCategories = [];

  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [thumbnailImagePreview, setThumbnailImagePreview] = useState(null);
  const [formError, setFormErrors] = useState(false);
  const data1 = useContext(CategoryContext);
  const megaMenu = data1?.megaMenu || [];

  const [selectedCategory, setSelectedCategory] = useState(""); // Added this line

  megaMenu.forEach((menu) => {
    if (menu.categories) {
      menu.categories.forEach((category) => {
        const categoryData = {
          id: category.id,
          category_name: category.category_name,
          subCategories: [],
        };

        if (category.subCategories) {
          categoryData.subCategories = category.subCategories.map(
            (subCategory) => ({
              id: subCategory.id,
              sub_category_name: subCategory.sub_category_name,
              exams: subCategory.exams || [],
            })
          );
        }

        allCategories.push(categoryData);
      });
    }
  });

  const subCategories = allCategories.flatMap(
    (category) => category.subCategories
  );
  console.log(subCategories, "sub");
  const handleThumbnailImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setThumbnailImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setThumbnailImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    const examsArray = [];
    const selectedCategory = subCategories.find(
      (data) => data.id === categoryId
    );

    if (selectedCategory && selectedCategory.exams) {
      selectedCategory.exams.forEach((exam) => {
        examsArray.push(exam);
      });
    }

    setExams(examsArray);
  }, [categoryId]);

  const handleFormSubmit = async () => {
    try {
      const errors = {};
      if (!name) errors.name = true;
      if (!phone) errors.phone = true;
      if (!email) errors.email = true;
      if (!category) errors.category = true;
      if (!exam) errors.exam = true;
      if (!msg) errors.msg = true;
      if (!product) errors.product = true;
      if (!issue) errors.issue = true;
      if (!msg) errors.msg = true;
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      if (!name) {
        toast.error("Please type your name");
        return;
      }
      const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phoneNumber);
      };

      if (!validatePhoneNumber(phone)) {
        toast.error("Invalid phone number. Please enter exactly 10 digits.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        toast.error("Invalid email address");
        return;
      }

      if (!category) {
        toast.error("Please Choose your category");
        return;
      }
      if (!exam) {
        toast.error("Please Choose your Exam");
        return;
      }
      if (!msg) {
        toast.error("Please type MSG");
        return;
      }

      const formData = new FormData();

      const online_support = {
        full_name: name,
        phone: phone,
        email: email,
        category_id: categoryId,
        exam_id: examId,
        product: product.name,
        issue: issue.name,
        message: msg,
      };
      console.log(online_support, "data");
      console.log(thumbnailImage, "file");
      formData.append("online_support", JSON.stringify(online_support));

      formData.append("file", thumbnailImage);
      console.log(formData, "formdata");
      const response = await PostOnlineSupport(formData);
      console.log("API Response:", response);
      toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.dontworrymain}>
          <div className={classes.dontworrycontent}>
            <div className={classes.dontworrymainheading}>
              <Typography variant="h5" gutterBottom>
                Service Related
              </Typography>
              <hr className={classes.secheadhr} />
            </div>
            <div className={classes.loginform}>
              <FormControl sx={{ m: 1, width: "25ch" }}>
                <div className={classes.forminner}>
                  <div className={classes.inputcontainer}>
                    <FormLabel>
                      Your Name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <TextField
                      id="name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Your Name"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <YourNameIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!name && formError.name} // Set error to true when name length is zero
                      variant="outlined"
                    />

                    {!name && formError.name && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Please type your name
                      </span>
                    )}
                  </div>
                  <div className={classes.inputcontainer}>
                    <FormLabel>
                      {" "}
                      Mobile No. <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <TextField
                      id="mobileno"
                      type="tel" // Change type to "tel" to allow only numeric input
                      placeholder="+918965458888"
                      value={phone}
                      onChange={(e) => {
                        const inputPhoneNumber = e.target.value;
                        if (
                          /^[0-9]*$/.test(inputPhoneNumber) &&
                          inputPhoneNumber.length <= 10
                        ) {
                          // Check if the first digit is 6, 7, 8, or 9
                          if (
                            /^[6-9]/.test(inputPhoneNumber) ||
                            inputPhoneNumber.length === 0
                          ) {
                            setPhone(inputPhoneNumber);
                          }
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <MobileNoIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {phone.length > 0 && phone.length !== 10 && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Invalid phone number. Please enter at least 10 digits.
                      </span>
                    )}
                    {!phone && formError.phone && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Please type your phone number
                      </span>
                    )}
                  </div>
                </div>
                <div className={classes.forminner}>
                  <div className={classes.inputcontainer}>
                    <FormLabel>
                      Email address <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <TextField
                      id="email"
                      type="text"
                      title="abcd"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Johndeere43545@gmail.com"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!email && formError.email && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Please type your email
                      </span>
                    )}
                  </div>
                  <div className={classes.inputcontainer}>
                    <FormLabel> Attach File</FormLabel>
                    <TextField
                      id="attachfile"
                      type="file"
                      placeholder="Please select a file"
                      onChange={handleThumbnailImageChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AttchFileIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className={classes.forminner}>
                  <div className={classes.inputcontainer}>
                    <FormLabel>
                      Select Exam Category{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    
                    <Autocomplete
                      id="state-autocomplete"
                      options={subCategories || []}
                      value={category}
                      classes={{ inputRoot: classes.inputRoot }}
                      onChange={handleCategoryChange}
                      disableClearable
                      getOptionLabel={(option) =>
                        option?.sub_category_name || ""
                      }
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Here"
                          variant="outlined"
                        />
                      )}
                      selectOnFocus
                      openOnFocus
                    />
                    {!category && formError.category && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Please Choose your Category
                      </span>
                    )}
                  </div>
                  <div className={classes.inputcontainer}>
                    <FormLabel>
                      Select Exam <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Autocomplete
                      id="state-autocomplete"
                      options={exams || []}
                      value={exam}
                      classes={{ inputRoot: classes.inputRoot }}
                      onChange={handleExamChange}
                      disableClearable
                      getOptionLabel={(option) => option?.exam_name || ""}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Here"
                          variant="outlined"
                        />
                      )}
                      selectOnFocus
                      openOnFocus
                    />
                    {!exam && formError.exam && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Please Select your Exam
                      </span>
                    )}
                  </div>
                </div>
                <div className={classes.forminner}>
                  <div className={classes.inputcontainer}>
                    <FormLabel>
                      Select Product <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Autocomplete
                      id="state-autocomplete"
                      options={products || []}
                      value={product}
                      classes={{ inputRoot: classes.inputRoot }}
                      onChange={handleProductChange}
                      disableClearable
                      getOptionLabel={(option) => option?.name || ""}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Here"
                          variant="outlined"
                        />
                      )}
                      selectOnFocus
                      openOnFocus
                    />
                    {!product && formError.product && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Please Select Product
                      </span>
                    )}
                  </div>
                  <div className={classes.inputcontainer}>
                    <FormLabel>
                      Select Issue <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    
                    <Autocomplete
                      id="state-autocomplete"
                      options={issues || []}
                      value={issue}
                      classes={{ inputRoot: classes.inputRoot }}
                      onChange={handleIssueChange}
                      disableClearable
                      getOptionLabel={(option) => option?.name || ""}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Here"
                          variant="outlined"
                        />
                      )}
                      selectOnFocus
                      openOnFocus
                    />
                    {!issue && formError.issue && (
                      <span style={{ color: "red", marginTop: "5px" }}>
                        * Please Select Issue
                      </span>
                    )}
                  </div>
                </div>
                <div className={classes.textareacontainer}>
                  <FormLabel>
                    Write a Message <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="outlined-textarea"
                    placeholder="Write Here"
                    multiline
                    rows={4}
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    variant="outlined"
                  />
                  {!msg && formError.msg && (
                    <span style={{ color: "red", marginTop: "5px" }}>
                      * Please type your Msg
                    </span>
                  )}
                </div>
                <Button
                  className={classes.bluebtn}
                  onClick={handleFormSubmit}
                  variant="contained"
                >
                  Submit
                </Button>
              </FormControl>
            </div>
          </div>
          <div className={classes.dontworryimgcontainer}>
            <img src={DontWorryVector} alt="img" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default OnlineEducationSection;
