import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import ErrorImage from "../image/investor/coming.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
    marginBottom:"15rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  onlineeducationimgcontainer: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "60%",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
  },
  onlineeducationcontentinner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
      fontSize: "2rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
        marginTop: "3rem",
      },
    },
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "#7E7E7E",
      fontSize: "1rem",
      marginTop: "1rem",
      textAlign: "center",
      whiteSpace: "nowrap",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
      },
    },
  },

  InfoButton: {
    background: " linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    color: "#fff",
    width: "286px",
    height: "56px",
    fontWeight: "bold",
    fontSize: "1rem",
    padding: "0.8rem 1.2rem",
    borderRadius: "5px",
    transition: "background 0.3s ease",
    marginTop: "1rem",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
  },
}));

function ComingSoon() {
  const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationimgcontainer}>
            <img src={ErrorImage} alt="img" width="60%" />
          </div>
          <div className={classes.onlineeducationcontentinner}>
            <Typography variant="body2" gutterBottom>
              We're hard at work creating something amazing <br></br>and can't
              wait to share it with you.<br></br>
              Stay tuned for our upcoming launch
            </Typography>
            <Link to="/">
              <Button className={classes.InfoButton}>BACK To HOME</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
