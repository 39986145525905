import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  MuiTypographybody2: {
    marginTop: "0.5rem",
    fontFamily: "'Jost', sans-serif",
    color: "#7E7E7E",
    fontSize: "1rem",
    fontWeight: "400",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
  MuiTypographybody1: {
    fontFamily: "",
    color: "#000",
    fontSize: "1rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
      color: " #223852",
    },
  },
}));

function CustomTypography(props) {
  const classes = useStyles();

  return (
    <Typography
      variant={props.variant}
      gutterBottom={props.gutterBottom}
      className={props.className}
    >
      {props.children}
    </Typography>
  );
}

export default CustomTypography;
