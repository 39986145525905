import { AppBar, Button, Divider, FormControl, IconButton, InputBase, MenuItem, Select, Toolbar, alpha, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    containerfluid: {
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundSize: "cover",
        background: "#FFFBF1",
      },
      container: {
        padding: "3rem 0",
        maxWidth: "1250px",
        margin: "0 auto",
        position: "relative",
        "& .MuiAppBar-colorPrimary": {
          backgroundColor: "#FFF",
        },
        "& .MuiToolbar-regular": {
          display: 'flex',
          justifyContent: 'space-between',
        },
        "& .MuiPaper-elevation4": {
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.10)',
          borderRadius: '0.3rem',
        },
        "& .MuiIconButton-root": {
            padding: "10px 12px",
        },
        "& .MuiMenuItem-root": {
            fontSize: '0.9rem',
            fontFamily: "'Playfair Display', serif !important",
            fontWeight: '500',
        },
      },
      appbar: {
        position: "absolute",
        top: "-20px",
      },
      searchIcon: {
        position: 'absolute',
        top: '0.8rem',
        right: '0.8rem',
        color: '#252525',
        borderRadius: "5px",
      },
      search: {
        width: '28%',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
      },
      inputRoot: {
        width: "100%",
        background: '#F9F9F9',
        borderRadius: '0.3rem',
        border: "1px solid #DBDBDB",
      },
      inputInput: {
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: '500',
        fontSize: "1rem",
        color: "#646464",
        padding: '0.8rem',
        width: '100%',
        borderRadius: '0.3rem',
    
      },
      btncontainer: {
        width: "20%",
      },
      bluecustombtn: {
        width: "100%",
        background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
        color: "white",
        padding: "0.6rem 1rem",
        borderRadius: '0.4rem',
        "&:hover": {
          background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
          color: "#252525",
        },
        "& .MuiButton-label": {
          fontSize: "0.8rem",
          fontFamily: "Jost",
          fontWeight: "300",
        },
      },
      formControl: {
        width: "62%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      selectInputWrapper: {
        width: "33%",
      },
      selectInput: {
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: '500',
        fontSize: "1rem",
        textTransform: "capitalize",
        color: "#646464",
        background: "#F9F9F9",
        border: "1px solid #DBDBDB",
        borderRadius: "0.3rem",
        width: "100%",
        padding: "0.4rem 0.8rem"
      },
  }));

function FilterSection({onSearchCoaching, handleSelectExamAndCity, data}) {
  const [exam, setExam] = useState(-1);
  const [city, setCity] = useState(-1);
  const [teacher, setTeacher] = useState("");

  const handleSearchByTeacher = (e) => {
      onSearchCoaching(e.target.value);
  };
  // const handleSelectExam = (e) => {
  //   setExam(e.target.value);
  // };

  const handleSearchBtn = () => {
    handleSelectExamAndCity(exam, city);
    // console.log(exam);
    // console.log(city);
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

    const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
      <AppBar position="static"  className={classes.appbar}>
          <Toolbar>
            <div className={classes.search}>
              <InputBase
                placeholder="Search By Teacher"
                onChange={handleSearchByTeacher}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <FormControl className={classes.formControl}>
            <div className={classes.selectInputWrapper}>
              <Select value={exam} className={classes.selectInput} MenuProps={menuProps} onChange={(e)=>{setExam(e.target.value)}} disableUnderline >
                <MenuItem value={-1} >Enter Exam</MenuItem>
                {data.map((item)=>(
                  <MenuItem key={item.id} value={item.exam} >{item.exam}</MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.selectInputWrapper} >
              <Select value={city} className={classes.selectInput} MenuProps={menuProps} onChange={(e)=>{setCity(e.target.value)}} disableUnderline >
                <MenuItem value={-1} >Enter City</MenuItem>
                {data.map((item)=>(
                  <MenuItem key={item.id} value={item.city} >{item.city}</MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.btncontainer}>
                <Button className={classes.bluecustombtn} onClick={handleSearchBtn}>
                    Search
                </Button>
            </div>
            </FormControl>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  )
}

export default FilterSection