import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }, 
}));
function Content() {
  const classes = useStyles();
  return (
<div>
  <h1>
    COMMING SOON Content
  </h1>
</div>
  );
};
export default Content;
