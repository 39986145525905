import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  
}));

function CourseSectionTabPanel({ value, handleChange }) {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Tabs value={value} onChange={handleChange} aria-label="course-tabs">
        <Tab label="All" />
        <Tab label="Live Classes" />
        <Tab label="Class History" />
        <Tab label="E-books" />
        <Tab label="Books" />
        <Tab label="Videos" />
        <Tab label="Notes" />
      </Tabs>
    </AppBar>
  );
}

export default CourseSectionTabPanel;
