import { Button, IconButton, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import CustomTableView from "../../CustomComponent/TableView";
import CardForTable from "../CardForTable";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0rem 2rem 1rem 2rem ",
    minHeight: "84vh",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.5rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    // marginTop: "1rem",
    width: "47%",
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  attempted: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.5rem",
      border: "0",
      background: "#243B55",
      borderRadius: "0",
      padding: "0.6rem 1.5rem",
    },
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#323232",
      [theme.breakpoints.down("xs")]: {
        color: "#FFF",
        fontSize: "0.9375rem",
        fontWeight: 600,
      },
    },
  },
  table: {
    minWidth: 700,
  },
  completedbtn: {
    "&.MuiButton-root": {
      border: "1px solid #00A019",
      color: "#00A019",
      textTransform: "capitalize",
      padding: "0.2rem 0.6rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "600",
      fontSize: "0.875rem",
    },
  },
  startbtn: {
    "&.MuiButton-root": {
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
    "&.MuiIconButton-root": {
      background: "#243B55",
      color: "#FFF",
      padding: "0.4rem",
      marginLeft: "0.5rem",
      borderRadius: "0.375rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      color: "#000",
      textTransform: "capitalize",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "30%",
    marginTop: "0.2em",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

function MyLiveClasses(props) {
  const classes = useStyles();

  let mql = window.matchMedia("(max-width: 650px)");

  const setOnViewClicked = props.setOnViewClicked;

  const MultiLineTextCellRenderer = ({ value }) => (
    <div style={{ whiteSpace: "pre-line", lineHeight: "normal" }}>{value}</div>
  );
  const columns = [
    {
      field: "srno",
      headerName: "Sr.No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 80,
      // flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "liveclasses_name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,

      renderCell: MultiLineTextCellRenderer,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "start_time",
      headerName: "Start Time",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "end_time",
      headerName: "End Time",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },

    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,

      renderCell: (cellValues) => {
        return (
          <div>
            <Button className={classes.startbtn}>Join</Button>
          </div>
        );
      },
    },
  ];

  const dummyRows = [
    {
      id: 1,
      srno: 1,
      liveclasses_name: "Test",
      date: "24/04/2024",
      start_time: "10:00 AM",
      end_time: "11:00 AM",
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <Typography gutterBottom className={classes.heading}>Live Classes</Typography>
        </div>
      </div>

      {!mql.matches ? (
        dummyRows ? (
          <CustomTableView
            columns={columns}
            rows={dummyRows}
            section={"myLiveClassesTable"}
          />
        ) : (
          <div>Loading...</div>
        )
      ) : (
        dummyRows.map((data, index) => <CardForTable data={data} key={index} />)
      )}
    </div>
  );
}

export default MyLiveClasses;
