import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import CardImageSignup from "../image/affiliatesvector/cardimagesignup.png";
import CardImageRecommend from "../image/affiliatesvector/cardimagerecommend.png";
import CardImageEarn from "../image/affiliatesvector/cardimageearn.png";
import CardBg from "../image/affiliatesvector/cardbackground.png";
import SecHead from "../Home/sectionHeading/sectionheading";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiTypography-h5": {
      fontFamily: "Playfair Display",
      fontSize: "2rem",
      fontWeight: "700",    
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: "1.5rem",
      },
    },
    "& .MuiTypography-h6": {
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: "0.875rem",
      },
    },
    "& .slick-dots li button:before":{
      color: '#FF8008',
      fontSize: '15px', 
    },
  },
  root: {
    maxWidth: 380,
    width: "96% !important",
    backgroundImage: `url(${CardBg})`,
    backgroundPosition: "right bottom",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "center bottom",
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "300px",
    "&:hover": {
      backgroundImage: `url(${CardBg})`,
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
    },
    "& .MuiCardContent-root": {
      display: "flex",
      padding: "1rem 2rem 0 2rem",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        padding: "2rem 1rem",
      },
    },
  },
  secheadhr: {
    width: "65px",
    margin: "0 0 1.3rem 0",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto 1.3rem auto",
    },
  },
  cardscontainer: {
    // maxWidth: 370,
    margin: "4rem 0",
    justifyContent: "center",
  },
  cardtextsection: {
    width: "60%",    
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  cardimagesignup: {
    width: "40%",
    marginTop: "7rem",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem auto 0 auto",
    },
    "& img": {
      width: "110px",
    },
  },
  cardimagerecommend: {
    width: "40%",
    marginTop: "11rem",
    marginRight: "4rem",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem",
    },
    "& img": {
      width: "168px",
      [theme.breakpoints.down("xs")]: {
        width: "214px",
      },
    },
  },
  cardimageearn: {
    width: "40%",
    marginTop: "7rem",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem auto 0 auto",
    },
    "& img": {
      width: "158px",
      [theme.breakpoints.down("xs")]: {
        width: "141px",
      },
    },
  },
  
}));

function AffiliateMarketingSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "We offer you your own websites designes",
      headingmain: "Affiliate Marketing Program",
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.cardscontainer}>
        <Slider {...settings}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <div className={classes.cardtextsection}>
                <Typography variant="h5" gutterBottom>
                  Sign Up
                </Typography>
                <hr className={classes.secheadhr} />
                <Typography variant="h6">
                  Join tens of thousands of creators, publishers and bloggers
                  who are earning with the Parikshado Affiliate Program.
                </Typography>
              </div>
              <div className={classes.cardimagesignup}>
                <img src={CardImageSignup} alt="img"/>
              </div>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <div className={classes.cardtextsection}>
                <Typography variant="h5" gutterBottom>
                  Recommend
                </Typography>
                <hr className={classes.secheadhr} />
                <Typography variant="h6">
                  Share millions of products with your audience. We have customized linking tools for large publishers, individual bloggers and social media influencers.
                </Typography>
              </div>
              <div className={classes.cardimagerecommend}>
                <img src={CardImageRecommend} alt="img"/>
              </div>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <div className={classes.cardtextsection}>
                <Typography variant="h5" gutterBottom>
                  Earn
                </Typography>
                <hr className={classes.secheadhr} />
                <Typography variant="h6">
                  Earn up to 10% in associate commissions from qualifying purchases and programs. Our competitive conversion rates help maximize earnings.
                </Typography>
              </div>
              <div className={classes.cardimageearn}>
                <img src={CardImageEarn} alt="img"/>
              </div>
            </CardContent>
          </Card>
        
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default AffiliateMarketingSection;
