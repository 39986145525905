import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import ChangePassword from "./changePassword";
import MyProfile from "./myProfile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiTab-root": {
      padding: "10px 18px",
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
      background: "#FFF",
      width: "42%",
      [theme.breakpoints.down("xs")]: {
        padding: "0rem",
        width: "100%",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: "#FFEABE",
      color: "#262626",
      borderRadius: "5px",
      minHeight: "40px",
      fontSize: "1rem",
      fontWeight: "700",
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "none",
    },
    "& .MuiTab-textColorInherit": {
      fontFamily: "Playfair Display",
      fontWeight: "700",
      fontSize: "1rem",
      color: "#262626",
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTabs-root": {
        overflow: "scroll",
      },
      "& .MuiTabs-fixed": {
        overflow: "scroll",
      },
      "& .MuiTabs-scroller": {
        overflow: "scroll",
      },
      margin: "0rem",
    },
  },
  tabpannel: {
    marginTop: "2rem",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0rem",
      padding: "1rem 0.5rem",
      textAlign: "justify",
      "& .MuiBox-root": {
        padding: "11px",
      },
    },
  },
  tabpannel2: {
    "& .MuiBox-root": {
      padding: "0px !important",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0rem",
      padding: "0",
      textAlign: "justify",
      "& .MuiBox-root": {
        padding: "11px",
      },
    },
  },
}));

export default function Settings() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none"
          aria-label="simple tabs example"
        >
          <Tab label="My Profile" {...a11yProps(0)} />
          <Tab label="Change Password" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} className={classes.tabpannel2}>
        <MyProfile />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabpannel}>
        <ChangePassword />
      </TabPanel>
    </div>
  );
}
