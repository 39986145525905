import { FETCH_PROFILE_DATA, FETCH_PROFILE_ERROR_MESSAGE, UPDATE_PROFILE_DATA, UPDATE_PROFILE_PICTURE } from "./FetchProfileType";

const Reducer = (state, action) => {
  const { payload, type } = action;
  console.log("🚀 ~ file: FetchUserProfile.js:5 ~ Reducer ~ payload, type:", payload, type)

  switch (type) {
    
      case FETCH_PROFILE_DATA:
      return {
        ...state,
        user: payload,
      };
      
      case UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        image: payload,
      };

      case UPDATE_PROFILE_DATA:
      return {
        ...state,
        response: payload,
      };

      case FETCH_PROFILE_ERROR_MESSAGE:
      return {
        ...state,
        hasError: payload.hasError,
        errorMessage: payload.errorMessage,
      };

    default:
      return state;
  }
};

export default Reducer;
