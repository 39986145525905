import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import PageHead from "../pageheading/pageheading";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginBottom:"15rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding:'2rem 0',
    "& .MuiTypography-h4":{
      color:'#FA9D2B;',
     },
  },
  termsofuse: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 1rem",
    },
  },
}));
function TermsConditions() {
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore Terms Of Use",
      headingmain: "Terms Of Use",
      breadcrumb:"Terms Of Use"
    },
  ];
  const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
    <PageHead Heading={Heading}/>
      <div className={classes.container}>
        <div className={classes.termsofuse}>
     

        <Typography variant="body2" gutterBottom>
          The present Terms of Use document is being published in accordance
          with the pro- visions of the Information Technology Act, 2000 and
          other applicable Rules there- under, including but not limited to the
          Information Technology (Intermediary Guide- lines) Rules, 2011.
          <br />
          Welcome to the ParikshaDo website and ParikshaDo mobile app. The
          Website and the App are owned and provided to you by Walrus Solutions
          private Limited, having its corporate office at Walrus Solutions
          private limited -303 Krishna Tower, 2/1 New palasia Infront of
          Curewell Hospital, Janjeerwala Square, Indore, Madhya Pradesh 452001.
          For the purposes of this Terms of Use, accessing of the Platform
          together with any study material made available or uploaded therein or
          downloaded, embed- ded therefrom shall hereinafter be collectively be
          referred to as the <b>"Services"</b>.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>INTRODUCTION</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          By using or accessing the Platform in any way, including registering
          on the Plat- form, using, viewing, sharing, embedding and/or
          downloading any Services, you agree to be bound by the Terms of Use
          set forth herein. By accepting these Terms of Use, you also accept and
          agree to be bound by ParikshaDo Policies, including but not limited to
          the ParikshaDo Privacy Policy and the ParikshaDo Copyright Pol- icy.
          <br />
          If you do not understand the present Terms of Use or do not agree
          with/accept any part thereof, you should immediately discontinue using
          or accessing the Platform. Your use and/or continued use of the
          Platform, as the case may be, amounts to an express consent by you to
          the terms of this Terms of Use as well as other Parik- shaDo Policies.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>ELIGIBILITY</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          By agreeing to these Terms of Use, you represent that you are legally
          competent to enter into an agreement and provide consent to these
          Terms of Use. You further represent that
          <ol className={classes.ol}>
            <li>You are of sound mind.</li>
            <li>
              Have attained the legal age necessary under the applicable laws to
              enter into an agreement and/or access, avail the Services and.
            </li>
            <li>
              Are not prohibited from entering into a legally binding contract
              as per applica- ble laws.
            </li>
          </ol>
          In case the Platform is being accessed and the Services are being
          availed for the benefit of a minor, you expressly confirm that you are
          legally competent to provide consent on behalf of such a minor and
          that the minor’s use of the Platform and/or the Services shall be
          subject to the Terms of Use.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>YOUR ACCOUNT</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          You can become a registered user of the Platform by setting up a
          password pro- tected account with us. In order to set up such an
          account, you will be required to enter details of your registered
          email id and password or you may login using the account details of
          any partner website. By setting up the account, you agree to ac- cept
          any and all responsibility for any and all activities that occur under
          your ac- count. By setting up the account, you further agree to the
          contents of the Privacy Policy.
          <br />
          ParikshaDo reserves the right and sole discretion to refuse access to
          the Platform, terminate any account, remove or restrict any content,
          at any time, with or without notice to you in case we notice any
          illegal activity on or from account or if we have reasons to believe
          that any information provided by you is untrue, inaccurate, out- dated
          or incomplete.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>RESTRICTION AND TERMINATION OF USE</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          ParikshaDo may block, restrict, disable, suspend or terminate your
          access to all or part of the free Services offered on the Platform, at
          any time in ParikshaDo's sole discretion, without prior notice to you
          by ParikshaDo. Specifically, in case of pro- longed inactivity,
          ParikshaDo reserves the right to disable, deactivate or terminate a
          user's account. If an account has been disabled or deactivated for
          inactivity, the user name associated with that account may be given to
          another user without no- tice to you or such other party. If you
          violate the Terms of Use, ParikshaDo may at its sole discretion,
          block, restrict, disable, suspend or terminate the paid Services
          offered on the Platform.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>INTELLECTUAL PROPERTY</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          The Services, including but not limited to digital content on the
          website i.e. text, graphics, user interface, images, video interface
          and software as well as their selec- tion and arrangement, may belong
          to ParikshaDo or to its partners who have granted ParikshaDo the right
          to use their content and are protected to the fullest ex- tent
          possible by applicable laws related to copyrights, trademarks, trade
          secrets and all other intellectual property and proprietary rights.
          Any unauthorized use of the Services may violate such laws and the
          Terms of Use. ParikshaDo reserves all its legal rights to prohibit,
          stop or contain any such violations.
          <br />
          You agree not to copy, republish, frame, download, transmit, modify,
          adapt, create derivative works based on, rent, lease, loan, sell,
          assign, distribute, display, per- form, license, sublicense or reverse
          engineer the Platform and Services or any parts thereof or the
          selection and arrangement of the Platform and Services, except as
          expressly authorized herein. ParikshaDo grants you a non-exclusive,
          non-trans- ferable, limited permission to access and display the web
          pages within this Plat- form, solely on your computer or any other
          electronic viewing device for your per- sonal, non-commercial use of
          this Platform. This permission is conditional on the basis that you
          shall not modify, alter or illegally use the content displayed on this
          Platform and shall keep intact and comply with all copyright,
          trademark, and other proprietary notices of the Platform, if any. The
          rights granted to you constitute a li- cense and not a transfer of
          title in any manner.
          <br />
          Use of the Platform or Services for any other purpose other than
          expressly granted here is expressly prohibited and may result in
          severe civil and criminal penalties. In relation to any content
          submitted, uploaded or generated by you on the Platform, you hereby
          grant an unconditional, unlimited, perpetual, royalty-free, worldwide
          li- cense and permission to ParikshaDo and its affiliates, content
          sharing partners to display, store, host, publish, digitally reproduce
          or copy, transmit, communicate, modify, translate, distribute or
          otherwise make such content available on the Plat- form or in relation
          to the Services provided by ParikshaDo. You agree that such content
          may continue to be made available on ParikshaDo despite any
          termination or suspension of your account, if such content has been
          shared, stored or embed- ded by any other user(s). You agree to not
          hold ParikshaDo responsible for the aforesaid activities in relation
          to your content.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>ADVERTISEMENTS AND PROMOTIONS</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          This Platform may run advertisements and promotions from third
          parties, products and/or services in any manner and to any extent.
          Your communications, activities, relationships and business dealings
          with any third parties advertising or promoting via the Platform,
          including payment and delivery of related goods or services, and any
          other terms, conditions, warranties or representations associated with
          such dealings, shall be solely matters between you and such third
          parties.
          <br />
          You acknowledge and agree that ParikshaDo is not responsible or liable
          for any loss or damage of any kind incurred as the result of any such
          dealings or as the re- sult of the presence of such non-ParikshaDo
          advertisers on the Platform.
          <br />
          Our links with advertisers should not be construed as an endorsement,
          approval or recommendation by us of the owners or operators of those
          linked websites, or of any information, graphics, materials, products
          or services referred to or contained on those linked websites, unless
          and to the extent stipulated to the contrary.
          <br />
          Additionally, by agreeing to this terms and conditions document you
          authorize Wal- rus Solutions Private Limited and ParikshaDo to send
          messages/e-mails or call you on your registered contact number/e-mail
          id regarding our different product offer- ings and discount
          schemes.This consent will override any registration for{" "}
          <b>DND/ DNC/NDNC</b>.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>LINKS TO THIRD PARTY WEBSITES</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          The Platform may include links that redirect you to other websites.
          These third party websites are not covered by the Terms of Use. You
          agree that once you leave our servers, any third party websites that
          you go to or interact with are at your own risk. ParikshaDo shall not
          be held liable for any default, loss of function or any risk or
          consequence that you may be exposed to as a result of the same.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>SPAM E-MAIL AND POSTINGS</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          In the event your access or use of the Platform and Services is found
          to be spam, misleading, malicious, annoying or containing/promoting
          unsolicited e-mails or net- work postings, ParikshaDo reserves its
          right to obtain immediate reliefs against you or against such use by
          you, in addition to all other remedies available at law or in equity.
          ParikshaDo may also opt to block, filter or delete unsolicited e-mail,
          mes- sages or postings as per its sole discretion.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>INDEMNITY</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          You agree to indemnify, defend and hold ParikshaDo and its officers,
          directors, partners, employees, agents and representatives harmless,
          from and against any and all claims, damages, losses, liabilities,
          costs (including reasonable legal fees) or other expenses that arise
          directly or indirectly out of or from
          <ol className={classes.ol}>
            <li>
              Your user content and any other content (e.g. computer viruses)
              that you may submit, post to or trans- mit through the Platform
              (including a third party's use of such user content (e.g. re-
              liance on the accuracy, completeness or usefulness of your user
              content).
            </li>
            <li>
              Your access to or use of the Platform and Services (including any
              use by your employees, contractors or agents and all uses of your
              account numbers, user names and passwords, whether or not actually
              or expressly authorised by you, in connection with the Platform
              and Services).
            </li>
            <li>Your breach of any clause of the Terms of Use.</li>
            <li>
              Any allegation that while using any of the software made available
              on the Plat- form you infringe or otherwise violate the copyright,
              trademark, trade secret or other intellectual property or other
              rights of any third party and/or any dealings between you and any
              third parties advertising or promoting or linked via the Platform.
            </li>
            Your activities in connection with the Platform.
          </ol>
          This indemnity will be applicable without regard to the negligence of
          any party, in- cluding any indemnified person.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>DISCLAIMER</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          We do not represent or guarantee that this Platform will be free from
          errors or viruses. You acknowledge that this Website may be affected
          by outages, faults or delays. Such outages, faults or delays may be
          caused by factors, including techni- cal difficulties with the
          performance or operation of our or another person’s soft- ware,
          equipment or systems, traffic or technical difficulties with the
          Internet or infra- structure failures.
          <br />
          We do not accept responsibility for any loss or damage, however caused
          (including through negligence), that you may directly or indirectly
          suffer in connection with your use of this Platform, nor do we accept
          any responsibility for any such loss aris- ing out of your use of or
          reliance on information contained on or accessed through this
          Platform.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>PRIVACY POLICY</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          Your privacy is very important to us. Users of our Platform should
          refer to our Pri- vacy Policy, which is incorporated into this Terms
          of Use by reference, with respect to the disclosure, collection,
          storage, usage and protection of your information dur- ing the course
          your interaction with the Platform.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>COPYRIGHT POLICY</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          ParikshaDo respects and recognize the intellectual property rights of
          others and ex- pects users of ParikshaDo to do the same. Users of our
          Platform should refer to our Copyright Policy, which is incorporated
          into this Terms of Use by reference. If you believe that your content
          has been used on ParikshaDo in a way that consti- tutes an
          infringement of your copyright, please notify ParikshaDo’s designated
          agent for complaints or approach us through the procedure set out
          under the appli- cable provisions of the Information Technology Act.
          If you have a good faith belief that your copyright has been infringed
          by any of our users, you may follow the process as specified in our
          Copyright Policy.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>AMENDMENTS TO THE TERMS AND CONDITIONS</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          ParikshaDo may amend the Terms of Use and other ParikshaDo Policies
          published on the Platform from time to time at its sole discretion.
          Your continued access or use of the Platform and Services constitutes
          your acceptance of the amendments. Your access and use of the Platform
          and Services will be subject to the most cur- rent version of the
          Terms of Use, Privacy Policy and Copyright Policy posted on the
          Platform at the time of such use. Please regularly check the Platform
          to view the lat- est version of ParikshaDo Policies.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>APPLICABLE LAW AND JURISDICTION</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          Terms of Use shall be governed by and construed in accordance with the
          Laws of India. In case of any disputes the courts in Indore will have
          exclusive jurisdiction to try any such disputes to the exclusion of
          all other courts.
        </Typography>
        </div>
      </div>
    </div>
  );
}
export default TermsConditions;
