import { IconButton, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import CustomTableView from "../../CustomComponent/TableView";
import SSCBook from "../../image/dashboard/sscbookimage.png";
import OrderStatus from "./OrderStatus";
import ProductDescription from "./ProductDescription";
import BillingAddress from "./BillingAddress";
import Dot from "@material-ui/icons/FiberManualRecord";
import TotalValue from "./TotalValue";
import { getMydashboardMySingleBook } from "../../../service/services";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.5rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headname: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.25rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  attempted: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#323232",
    },
  },
  table: {
    minWidth: 700,
  },
  activebtn: {
    "&.MuiButton-root": {
      color: "#fff",
      background: "#313866",
      textTransform: "capitalize",
      padding: "0rem 1rem",
      width: "60%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      border: "1px solid #EBEBEB",
      color: "#262626",
      background: "#FFF",
      textTransform: "capitalize",
      padding: "0rem 1rem",
      width: "60%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
  totalamounthead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1.5rem 0",
    padding: "0.8rem 2rem",
    borderRadius: "6px",
    border: "1px solid #F8E898",
    background: "#FFFAEE",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem",
    },
    "& .MuiTypography-h6": {
      fontSize: "1.3rem",
      fontFamily: "Jost",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#434647",
      fontSize: "0.8rem",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
  },
  amount: {
    "& .MuiTypography-h5": {
      fontSize: "1.6rem",
      fontFamily: "Jost",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
      },
    },
    "& span": {
      fontSize: "1rem",
      position: "relative",
      top: "-6px",
    },
  },
  producttablecontainer: {
    marginTop: "1rem",
    "& .MuiTypography-h6": {
      fontFamily: "Jost",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
    },
  },
  dot: {
    fontSize: "0.3rem",
    color: "#434647",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  products: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  details: {
    [theme.breakpoints.down("xs")]: {
      width: "65%",
    },
  },
  orderplacedtime: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

function OrderDetails(props) {
  const classes = useStyles();

  const setOnViewClicked = props.setOnViewClicked;
  const id = props.data;
  const { active, completed } = props;

  let mql = window.matchMedia("(max-width: 650px)");
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState({});
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchData() {
      try {
        const response = await getMydashboardMySingleBook(token, id);
        setOrder(response?.data.mypurchases_books[0]);
        setData(response?.data?.mypurchases_books);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  const columns = [
    {
      field: "image",
      headerName: "IMAGE",
      headerClassName: "super-app-theme--header",
      cellClassName: "imagecell",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return <img src={cellValues.row.image} alt="bookimg" />;
      },
    },
    {
      field: "products",
      headerName: "Products",
      headerClassName: "super-app-theme--header",
      cellClassName: "productnamecell",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "price",
      headerName: "Price",
      headerClassName: "super-app-theme--header",
      cellClassName: "totalcostcell",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <p>
            <span>₹</span>
            {cellValues.value}
          </p>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <p>
            <span>x</span>
            {cellValues.value}
          </p>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      headerClassName: "super-app-theme--header",
      cellClassName: "totalcostcell",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <p>
            <span>₹</span>
            {cellValues.value}
          </p>
        );
      },
    },
  ];
  const columnsmobile = [
    {
      field: "image",
      headerName: "IMAGE",
      headerClassName: "super-app-theme--header",
      cellClassName: "imagecell",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return <img src={cellValues.row.image} alt="bookimg" />;
      },
    },
    {
      field: "products",
      headerName: "Products",
      headerClassName: "super-app-theme--header",
      cellClassName: "productnamecell",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "total",
      headerName: "Total",
      headerClassName: "super-app-theme--header",
      cellClassName: "totalcostcell",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <p>
            <span>₹</span>
            {cellValues.value}
          </p>
        );
      },
    },
  ];
  // const rows=data?.products?.map((item)=>{
  //   id: item?.id||null,
  //     image: item?.image_url||null,
  //     products: item?.product_name||null,
  //     price: item?.purchase_cost||null,
  //     quantity: "1",
  //     subtotal: "899",
  // })
  useEffect(() => {
    const updatedRows = data.map((item) => ({
      id: item?.id,
      image: item?.product_data?.image_url,
      products: item?.product_data?.book_name,
      price: item?.billing_amount / item?.quantity,
      quantity: item?.quantity,
      total: item?.billing_amount,
    }));
    setRows(updatedRows);
  }, [data]);
  console.log(data, "datadata");

  const createdDate = order?.created_at ? new Date(order.created_at) : null;
  const formattedDate = createdDate
    ? new Intl.DateTimeFormat("en-GB").format(createdDate)
    : "";
  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const formattedTime = timeFormatter.format(createdDate);

  const arrivalDate = order?.arrival_date ? new Date(order.arrival_date) : null;
  const formattedArrivalDate = arrivalDate
    ? new Intl.DateTimeFormat("en-GB").format(arrivalDate)
    : "";

  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <IconButton
            onClick={() => {
              setOnViewClicked("");
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h3">Orders Details</Typography>
        </div>
      </div>
      <div className={classes.totalamounthead}>
        <div className={classes.details}>
          <Typography variant="h6">#{order?.order_data?.order_id}</Typography>
          <Typography variant="caption">
            <span className={classes.products}>{order?.quantity} products</span>{" "}
            <Dot className={classes.dot} />{" "}
          </Typography>
          <div className={classes.orderplacedtime}>
            <Typography variant="caption">Order Placed in </Typography>
            <Typography variant="caption">
              &nbsp;{formattedDate} at {formattedTime}
            </Typography>
          </div>
        </div>
        <div className={classes.amount}>
          <Typography variant="h5">
            <span>₹</span>
            {order?.billing_amount}
          </Typography>
        </div>
      </div>
      <OrderStatus date={formattedArrivalDate} />

      <div className={classes.producttablecontainer}>
        <CustomTableView
          columns={!mql.matches ? columns : columnsmobile}
          rows={rows}
          section={"productTable"}
        />
      </div>
      <ProductDescription data={order} />
      {/* <BillingAddress data={order} /> */}
      <TotalValue data={order} />
    </div>
  );
}

export default OrderDetails;
