import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as LockIconFree } from "../../../image/ebooksvector/lockiconfree.svg";
import { ReactComponent as LockIconPaid } from "../../../image/ebooksvector/lockiconpaid.svg";
import playcircleicon from "../../../image/singleonlinevideo/playcircleicon.png";
import { Backdrop, Button, Modal } from "@material-ui/core";
import { GrFormClose } from "react-icons/gr";
import PopupContext from "../../../../context/PopupContext/PopupContext";
import { useContext } from "react";
import AppContext from "../../../../context/AppContext";
import CustomVideoPlayer from "../../../dashboard/videoCourse/CustomVideoPlayer";

const useStyles = makeStyles((theme) => ({
  cardcontainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    marginTop: "1rem",
    marginRight: "1rem",
    width: "23%",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0rem",
      width: "100%",
    },
    "& .MuiTypography-h5": {
      marginLeft: "0.5rem",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      color: "#000000",
    },
    "& .MuiTypography-h6": {
      marginRight: "0.5rem",
      fontFamily: "'Jost', serif !important",
      fontSize: "0.8rem",
      color: "#000000",
    },
  },
  cardhover: {
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0px 10px 10px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },
  cardcontent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgandname: {
    width: "85%",
    display: "flex",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "5px",
    padding: "1rem",
    "& .MuiTypography-h4": {
      marginLeft: "0.5rem",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2rem",
      color: "#000000",
      textTransform: "capitalize",
      marginBottom: "0.5rem",
    },
  },
  closebtncontainer: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0",
    },
    [theme.breakpoints.down("xs")]: {
      top: "60px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
}));

export default function NoteUploadPlan({ data }) {
  const classes = useStyles();
  const [openContentModal, setOpenContentModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [contentUrl, setContentUrl] = useState("");

  const { handleloginOpenclose } = useContext(PopupContext);
  const { isLoggedIn, token } = useContext(AppContext);

  const handleOpenVideo = (data, rowId) => {
    setSelectedVideo(data);
    setContentUrl(data?.lecture_url ? data.lecture_url : data.link);
    setOpenContentModal(!openContentModal);
  };

  const extractVideoId = (url) => {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  };

  const extractFileId = (url) => {
    const regExp =
      /(?:drive\.google\.com\/(?:file\/d\/|open\?id=|uc\?id=))([^\/\?&]+)/;
    const match = url.match(regExp);
    if (match && match[1]) {
      return match[1];
    } else {
      // Check if it's a direct link
      const urlParams = new URLSearchParams(new URL(url).search);
      return urlParams.get("id");
    }
  };

  const renderMediaContent = (contentUrl) => {
    if (!contentUrl) {
      return null; // or handle this case according to your requirement
    }

    if (contentUrl.includes("drive.google.com")) {
      const fileId = extractFileId(contentUrl);
      console.log(fileId, "fileId");
      return (
        <iframe
          src={`https://drive.google.com/file/d/${fileId}/preview`}
          width="100%"
          height="400px"
          title="Google Drive Video"
        ></iframe>
      );
    } else {
      return (
        <CustomVideoPlayer url={contentUrl} />
        
      );
    }
  };

  return (
    <div className={classes.cardcontainer}>
      {data?.lectures.map((data, mockIndex) => (
        <Card
          className={`${classes.root} ${
            data?.payment_status === "free" && classes.cardhover
          }`}
          onClick={() => {
            if (isLoggedIn && token) {
              if (data?.payment_status === "free") {
                handleOpenVideo(data, mockIndex);
              }
            } else {
              handleloginOpenclose();
            }
          }}
        >
          <CardContent className={classes.cardcontent}>
            <div className={classes.imgandname}>
              {data?.payment_status === "paid" ? (
                <LockIconPaid />
              ) : (
                <LockIconFree />
              )}
              <Typography variant="h5" component="h2">
                {data?.lecture_name}
              </Typography>
            </div>
            <Typography variant="h6" component="h2">
              {data?.duration}min
            </Typography>
            {data?.payment_status === "free" && (
              <img src={playcircleicon} alt="play" />
            )}
          </CardContent>
        </Card>
      ))}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openContentModal}
        // onClose={handleOpenVideo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <div className={classes.closebtncontainer}>
            <Button className={classes.closebtn} onClick={handleOpenVideo}>
              <GrFormClose />
            </Button>
          </div>
          <div>
            {console.log("onlinevideoDetail12356556546", selectedVideo)}
            <Typography variant="h4" component="h2">
              {selectedVideo?.lecture_name}
            </Typography>
            {renderMediaContent(contentUrl)}
          </div>
        </div>
      </Modal>
    </div>
  );
}
