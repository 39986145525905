import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Paper,
  Popover,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    // height: "100vh",
    borderRadius: "10px",
    border: "1px solid #B8B8B8",
    marginTop: "1rem",
    padding: "0.5rem",
    "& .MuiTypography-h3": {
      textAlign: "center",
      color: "#000",
      fontFamily: "Jost",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: ".5rem",
      width: "21%",
    },
    "& .MuiTypography-h4": {
      color: "#626262",
      fontFamily: "Jost",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: ".5rem",
      width: "17%",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h2": {
      color: "#00740C",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 600,
      marginTop: ".5rem",
      width: "17%",
    },
    "& .MuiTypography-h5": {
      color: "#000",
      fontFamily: "Jost",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: ".5rem",
      width: "7%",
    },
  },
  bookimg: {
    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.25)",
  },
  bookpluscontent: {
    display: "flex",
    margin: "0.5rem",
    flexDirection: "row",
    justifyContent: " space-between",
    alignItems: "center",
  },
  bookandtext: {
    display: "flex",
  },
  mrp: {
    display: "flex",
  },
  actionbtn: {
    borderRadius: "6px",
    background: "#9EEA8B",
    borderRadius: "6px",
    minWidth: 0,
    width: "17% !important",
    "&:hover": {
      background: "#9EEA8B",
    },

    padding: "0.3rem 1.5rem",
    textTransform: "capitalize",
    fontFamily: "Jost",
    fontWeight: "500",
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
  },
  downicon: {
    marginLeft: "0.3rem",
    fontSize: "large",
  },
  dropdown: {
    display: "flex",
    margin: "0.5rem",
    flexDirection: "column",
    "& .MuiButton-root": {
      textTransform: "capitalize",
      justifyContent: "normal",
    },
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
  },
  w12: {
    width: "12% !important",
  },
  w21: {
    width: "21% !important",
  },
  w17: {
    width: "17% !important",
  },
  w7: {
    width: "7% !important",
  },
}));

function ProductDiscription({ orders, fetchData }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState();

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const popoveropen = Boolean(anchorEl);
  const id = popoveropen ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);
  return (
    <>
      <div className={classes.main}>
        <div className={classes.heading}>
          <Typography variant="h4" className={classes.w12}>
            #
          </Typography>
          <Typography variant="h4" className={classes.w21}>
            Name
          </Typography>
          <Typography variant="h4" className={classes.w17}>
            Type
          </Typography>
          <Typography variant="h4" className={classes.w7}>
            Quantity
          </Typography>
          <Typography variant="h4" className={classes.w7}>
            Price
          </Typography>
          <Typography variant="h4" className={classes.w17}>
            Status
          </Typography>
        </div>
        {orders?.products?.map((data) => (
          <div className={classes.bookpluscontent}>
            <img
              src={data?.image_url}
              className={classes.bookimg}
              alt="bookimg"
              width="12%"
            />
            <Typography variant="h3">
              {data?.product_name} {data?.sub_heading}
            </Typography>

            <Typography variant="h4">
              {data?.product_type === "books"
                ? "Book"
                : data?.product_type === "ebooks"
                ? "E-Book"
                : data?.product_type === "onlinevideos"
                ? "Online Video"
                : data?.product_type === "testseriesbundle"
                ? "Test Series"
                : data?.product_type}
            </Typography>

            <Typography variant="h5">{data?.quantity}</Typography>
            <Typography variant="h5">{data?.selling_cost}</Typography>

            <Typography variant="h5" className={classes.actionbtn}>
              {data?.product_type === "books"
                ? data?.delivery_status
                : "success"}
            </Typography>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProductDiscription;
