import { makeStyles } from "@material-ui/core";
import PageHead from "../pageheading/pageheading";
import CheckoutPageContent from "./CheckoutPageContent";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: '100%',
    fontFamily: "'Jost', sans-serif",
    marginBottom:'13rem',
    [theme.breakpoints.down("xs")]: {
    marginBottom:'13rem',
    },
  },
}));
function CheckoutPage() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore Privacy Policy",
      headingmain: "Checkout",
      breadcrumb:"Checkout"
    },
  ];
  
  return (
    <div className={classes.containerfluid}>
      <PageHead Heading={Heading}/>
      <CheckoutPageContent />
    </div>
  );
}

export default CheckoutPage;
