import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import DownloadOurAppVector from "../../image/downloadourappimage/downloadourappvector.png";
import DownloadOurAppGif from "../../Videos/DownloadOurApp.webm";
import AppleVector from "../../image/downloadourappimage/Apple.png";
import { useState } from "react";
import { ReactComponent as AndroidVector } from "../../image/downloadourappimage/playstorevector.svg";
import { PhoneInput } from "react-contact-number-input";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  downloadappmain: {
    background: "transperant",
    margin: "2.2rem 0 11rem 0",
    // margin: "4.5rem 0rem 0rem 0",
    padding: "0.5rem 0rem",
    "& .MuiTypography-h4 ": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "3rem",
      color: "#252525",
      fontWeight: "700",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "50px",
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px",
        lineHeight: " 40px",
        textAlign: "center",
        padding: "0px 1rem",
        fontFamily: "'Playfair Display', serif !important",
      },
    },
    "& .MuiTypography-h5 ": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.5rem",
      margin: "0",
      color: "#252525",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.rem",
        margin: "0",
        color: "#252525",
        fontWeight: "400",
      },
    },
    "& .MuiTypography-h6 ": {
      color: "#212121",
      fontSize: "1.5rem",
      fontWeight: "500",
      fontFamily: "'Playfair Display', serif !important",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "4rem",
      marginBottom: "12rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
      marginBottom: "11rem",
      "& .MuiTypography-h4 ": {
        fontSize: "1.4rem",
      },
      "& .MuiTypography-h5 ": {
        fontSize: "0.9rem",
      },
      "& .MuiTypography-h6 ": {
        fontSize: "0.75rem",
      },
      "& .MuiTypography-body1": {
        display: "none",
      },
    },
  },
  secheadhr: {
    width: "175px",
    margin: "0",
    marginTop: "15px",
    marginBottom: "2rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "9px",
      marginBottom: "22px",
    },
  },
  phoneinput: {
    marginTop: "2rem",
    display: "flex",
    background: "#fff",
    width: "78%",
    padding: "0.4rem",
    borderRadius: "0.3rem",
    justifyContent: "space-between",
    boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17)",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
    "& .flag-dropdown": {
      backgroundColor: "transparent",
    },
    "& .react-tel-input": {
      "& .form-control": {
        background: "transparent",
        boxShadow: "none",
        width: "100%",
      },
    },
    "& .MuiButtonBase-root": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
      color: "white",
      padding: "0 2rem",
      boxShadow:
        "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    },
    "& .form-control  ": {
      border: "none",
      height: "45px",
      boxShadow:
        "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    },
    "& .flag-dropdown ": {
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "95%",
      "& .MuiButtonBase-root": {
        display: "none",
      },
      "& .form-control": {
        width: "100% !important",
      },
    },
  },
  downloadtext: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },
  bluegetlinkbtn: {
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
  },
  mobbluegetlinkbtn: {
    width: "100%",
    marginTop: "1rem",
    background:
      "linear-gradient(90deg, rgba(255,128,8, 0.3199999928474426) 0%, rgba(255,200,55, 0.3199999928474426) 100%)",
    color: "black",
    padding: "0.4rem 0",
    borderRadius: "6px",
    "& .MuiButton-label": {
      fontFamily: "Jost",
      fontWeight: 400,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    fill: "#FEFEFC",
    marginLeft: "10px",
    padding: "12px 36px",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
      fill: "#252525 !important",
    },
    "& img": {
      marginRight: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      marginTop: "1rem",
    },
  },
  downloadbtn: {
    marginTop: "3rem",
    display: "flex",
    width: "72%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  downloadbtnmobile: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  orangecustombtn: {
    background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
    color: "black",
    marginLeft: "10px",
    padding: "12px 36px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#252525",
    },
    "& img": {
      marginRight: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      marginTop: "1rem",
      marginBottom: "2rem",
    },
  },
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  downloadappinner: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0rem 0rem 3.5rem 0rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "1.6rem",
    },
  },
  downloadappcontent: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },
  downloadappimgcont: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
      width: "70%",
    },
    "& video::-webkit-media-controls": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-enclosure": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-panel": {
      display: "none !important",
    },
  },
  disabledinput: {
    width: "26px",
    backgroundColor: "white",
    boxhadow: "none",
    outline: "none",
    borderWidth: "1px",
    borderBottom: "1px solid #999",
    marginRight: "-4px",
    padding: "1px 0",
  },

  enabledtextbox: {
    border: "1px solid #999",
    borderLeftWidth: "0",
    "&:focus": {
      outlineOffset: "0",
      outline: "none",
    },
  },
  androidvector: {
    width: "15%",
    marginRight: "0.5rem",
  },
  androidvectormobile: {
    width: "21px",
    marginRight: "0.5rem",
  },
}));

function DownloadAppSection() {
  const classes = useStyles();
  const videoRef = useRef(null);

  const [iphoneDetect, setIphoneDetect] = useState(false);

  useEffect(() => {
    var userAgent = navigator.userAgent;

    if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setIphoneDetect(true);
    }
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    };

    const playVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.play().catch((error) => {
          console.error("Error playing video:", error.message);
        });
      }
    };

    const pauseVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.pause();
      }
    };

    const videoObserver = new IntersectionObserver(handleIntersection, options);

    const video = videoRef.current;
    if (video) {
      videoObserver.observe(video);
    }

    return () => {
      if (video) {
        videoObserver.unobserve(video);
      }
    };
  }, []);

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleGetLink = async () => {
    try {
      // Your API endpoint URL
      const apiUrl = "https://api.parikshado.com/v1/sms/applink";

      // Make a POST request with the entered phone number
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: phoneNumber,
        }),
      });

      // Handle the response (you can customize this part based on your API response)
      if (response.ok) {
        toast.success("Link sent successfully!");
      } else {
        const errorData = await response.json();

        toast.error(`Failed to get link: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error getting link:", error);
      toast.error("Failed to get link for now ");
    }
  };

  return (
    <>
      <ToastContainer />

      <div className={classes.downloadappmain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <div className={classes.downloadappinner}>
              <div className={classes.downloadappcontent}>
                <Typography
                  className={classes.batchdiscription}
                  variant="h4"
                  display="block"
                  gutterBottom
                >
                  Download Our App
                </Typography>
                <Typography
                  className={classes.batchdiscription}
                  variant="h5"
                  display="block"
                  gutterBottom
                >
                  Learn the fun way
                </Typography>
                <hr className={classes.secheadhr} />
                <Typography
                  className={classes.downloadtext}
                  variant="h6"
                  display="block"
                  gutterBottom
                >
                  Download our app & discover Pariksha DO Revolutionary way of
                  learning!
                </Typography>
                <Typography
                  className={classes.batchdiscription}
                  variant="body1"
                  display="block"
                  gutterBottom
                >
                  It is a long established fact that a reader will be
                  distracted.
                </Typography>
                <div className={classes.phoneinput}>
                  <div>
                    <PhoneInput
                      countryCode="in"
                      containerClass={classes.containerClass}
                      onChange={(value) => setPhoneNumber(value)}
                    />
                  </div>
                  <Button
                    className={classes.bluegetlinkbtn}
                    onClick={handleGetLink}
                  >
                    Get Link
                  </Button>
                </div>
                <div className={classes.mobbluegetlinkbtn}>
                  <Button
                    className={classes.bluegetlinkbtn}
                    onClick={handleGetLink}
                  >
                    Get Link
                  </Button>
                </div>
                <div className={classes.downloadbtn}>
                  <Button variant="contained" className={classes.bluecustombtn}>
                    <AndroidVector className={classes.androidvector} /> Download
                    Now
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.orangecustombtn}
                  >
                    <img src={AppleVector} alt="img" width="15%" /> Download Now
                  </Button>
                </div>
              </div>
              <div className={classes.downloadappimgcont}>
                {iphoneDetect ? (
                  <img
                    src={DownloadOurAppVector}
                    alt="img"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <video
                    ref={videoRef}
                    className={classes.bannervectoranimation}
                    autoPlay={true}
                    loop
                    muted
                    playsInline
                    controls={false}
                    width="100%"
                    height="auto"
                  >
                    <source src={DownloadOurAppGif} type="video/webm" />
                  </video>
                )}
                {/* <video
                  className={classes.bannervectoranimation}
                  autoPlay={false}
                  loop
                  muted
                  controls="false"
                  width="100%"
                  height="100%"
                >
                  <source src={DownloadOurAppGif} type="video/webm" />
                </video> */}
              </div>
              <div className={classes.downloadbtnmobile}>
                <Button variant="contained" className={classes.bluecustombtn}>
                  <AndroidVector className={classes.androidvectormobile} />{" "}
                  Download Now
                </Button>
                <Button variant="contained" className={classes.orangecustombtn}>
                  <img src={AppleVector} alt="img" width="13%" /> Download Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DownloadAppSection;
