import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Slider from "react-slick";
import React, { useState } from "react";
// import ExclusiveBookData from "./BookCarData";
import { Rating } from "@material-ui/lab";
import Carousel from "react-grid-carousel";
import { ReactComponent as FavoriteChecked } from "../image/ebooksvector/favoritechecked.svg";
import { ReactComponent as FavoriteUnchecked } from "../image/ebooksvector/favoriteunchecked.svg";
import { ReactComponent as CartBagIcon } from "../image/ebooksvector/cartbagicon.svg";
import FavouriteButton from "../practicecomponent/FavouriteButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  BookCard: {
    justifyContent: "space-between",
    width: "23%",
    display: "flex !important",
    margin: "1rem 5px",
    flexDirection: "column",
    maxHeight: "397px",
    borderRadius: 7,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
      opacity: "0.3",
    },
    "& .MuiCardActionArea-root": {
      position: "relative",
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& fieldset": {
      margin: "0 0 0 -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiCardContent-root": {
      padding: "0 10px",
      minHeight: "5rem",

      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      lineHeight: "1.5rem",
      textTransform: "capitalize",
      whiteSpace: "wrap",
      height: "50px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      width: "50% !important",
      padding: "5px",
      borderRadius: "3%",
      background: "white",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h5": {
        fontSize: "1.2rem",
        marginBottom: 5,
      },
      "& .MuiCard-root": {
        "@media (min-width: 960px)": {
          width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
    },
  },
  cardimgcont: {
    position: "relative",
    background: "#FFE8B5",
    margin: ".5rem",
    padding: "10px",
    borderRadius: 8,
    "&:hover": {
      background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    },
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.25rem",
    fontFamily: "Jost",
    fontWeight: "600",
  },
  offertext: {
    marginLeft: "1rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  cartbagicon: {
    marginRight: "0.6rem",
  },
  quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "40% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
    "&:hover": {
      background: "white",
    },
  },
  quikviewbtn: {
    "&:hover": {
      background: "white",
    },
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  favoritebtn: {
    // width: "29%",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
  },
  writerandprice: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tag: {
    background: "#00A911",
    color: "#FFF",
    display: "inline",
    padding: "0.2rem 0.4rem",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.5rem",
    borderRadius: "6px",
    position: "absolute",
    top: "5px",
  },
  pricecontainer: {
    color: "#4E5566",
    fontSize: "0.75rem",
    fontamily: "Jost",
    fontWeight: 500,
  },
}));
function BookCard(props) {
  const { data } = props;
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Card className={classes.BookCard}>
        <CardActionArea
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        >
          {isHover && (
            <div className={classes.quikviewcontainer}>
              {/* <Link to={`/book/${data?.id}`}>*/}
              <IconButton className={classes.quikviewbtn}>
                <InfoIcon />
              </IconButton>
              {/* </Link> */}
              <div className={classes.favoritebtn}>
                <FavouriteButton
                  isCheckedHeart={isCheckedHeart}
                  data={data}
                  setIsCheckedHeart={setIsCheckedHeart}
                />
              </div>
            </div>
          )}
          <div className={classes.cardimgcont}>
            <CardMedia
              component="img"
              image={data?.image_url}
              alt="green iguana"
            />
            <div className={classes.tag}>{data?.subCategory?.sub_category_name}</div>
          </div>
          <CardContent>
            <Typography variant="h5" component="div">
              {data?.book_name}
            </Typography>
            <div className={classes.writerandprice}>
              <Typography variant="caption" display="block">
                {data?.author_name}
              </Typography>
            </div>
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ marginBottom: 5 }}
            >
              <span className={classes.textred}>{data?.purchase_cost}</span>
              <div className={classes.pricecontainer}>
                MRP:<del>  {data.selling_cost}</del> 
              {data?.discount_percent}%off
              </div>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.bgorange}>
          <Button fullWidth>SELL Now</Button>
        </CardActions>
      </Card>
    </>
  );
}
export default BookCard;
