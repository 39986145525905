import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import SecHead from "../../Home/sectionHeading/sectionheading";
import EBookCard from "../../practicecomponent/cards/eBookCard";
import EBookCardMobile from "../../practicecomponent/cards/eBookCardMobile";
import sscbooks from "../../image/mytestseries/ebooks/sscbooks.png";
import { getRecomndadNotes } from "../../../service/services";
import EBookCardDash from "./EbookCardDash";
import EBookCardMobileDash from "./EbookCardMobileDash";
import ExclusiveNoteCard from "../../Home/exclusivebooks/exclusivenotecard";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3rem",
    "& .slick-slide": {
      display: "flex",
      // width: "100% !important",
      height: "440px",
      justifyContent: "center",
      paddingTop: "0.5rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      "& div": {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "2rem 1.5rem 2rem 1.5rem !important",
        height: "506px",
      },
    },
    "& .slick-dots": {
      [theme.breakpoints.down("xs")]: {
        bottom: "17px",
      },
    },
    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
  cardContainer: {
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

const Recommendnotes = () => {
  const classes = useStyles();

  let mql = window.matchMedia("(max-width: 650px)");

  const Heading = [
    {
      id: 1,
      headingsmall: "Explore More",
      headingmain: "Recommended Notes Pdf",
    },
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getRecomndadNotes(token);
        console.log("API Response:", response.data?.notes);
        setData(response.data?.notes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const initialSlidesToShow = window.innerWidth < 480 ? 1 : 4;

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: initialSlidesToShow,
    slidesToScroll: initialSlidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow: newSlidesToShow,
        slidesToScroll: newSlidesToShow,
      }));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.cardContainer}>
          {/* {data.map((data) => (
            !mql.matches ? (<EBookCardDash Data={data} />) : (<EBookCardMobileDash  Data={data}/>)
          ))} */}
          {/* {data.map((data) => (
            !mql.matches ? (<ExclusiveEbookCard data={data} />) : (<EBookCardMobileDash  Data={data}/>)
          ))} */}
          <Slider {...sliderSettings} className={classes.sliderstyle}>
            {data.map((data) => (
              <ExclusiveNoteCard data={data} />
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Recommendnotes;
