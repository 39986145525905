import { useEffect, useReducer } from 'react';
import AppReducer from './AppReducer';
import AppContext from "./AppContext";
import { SET_USER, SET_IS_LOGGED_In, SET_TOKEN, SET_LOADER, SET_COURSE_DATA, SET_TEMP_SUBCATEGORY_DATA } from './AppType';
import constant from './../Utils/constant';
import { authorizeMe, me, } from './../service/services';

const AppState = (props) => {

    let initialState = {
        user: undefined,
        isLoggedIn: false,
        token: "",
        loader: false,
        coursesData: null,
        tempSubcategoryName: null
    };

    useEffect(() => {
        handleRefresh();
    }, [])

    const handleRefresh = async () => {
        if (localStorage.getItem(constant.PARIKSHADOLOGGEDIN)) {
            setIsLoggedIn(true);
            const value = localStorage.getItem('token');
            authorizeMe(value);
            // var res2 = await me({})
            setUser(JSON.parse(localStorage.getItem(constant.PARIKSHADOCURRENTUSER)));
        }
        setCourseData(JSON.parse(sessionStorage.getItem(constant.COURSES)))
    }

    const [state, dispatch] = useReducer(AppReducer, initialState);

    const setUser = async (user) => {
        localStorage.setItem(constant.PARIKSHADOCURRENTUSER, JSON.stringify(user));
        const value = localStorage.getItem('token');
        setToken(value);
        dispatch({ type: SET_USER, payload: user });
    };

    const setCourseData = async (coursesData) => {
        sessionStorage.setItem(constant.COURSES, JSON.stringify(coursesData));
        dispatch({ type: SET_COURSE_DATA, payload: coursesData });
    };

    const setTempSubcategory = async (value) => {
        dispatch({ type: SET_TEMP_SUBCATEGORY_DATA, payload: value });
    };


    const setIsLoggedIn = (status) => {
        localStorage.setItem(constant.PARIKSHADOLOGGEDIN, status);
        dispatch({ type: SET_IS_LOGGED_In, payload: status });
    }

    const setToken = async (token) => {
        authorizeMe(token);
        dispatch({ type: SET_TOKEN, payload: token });
    };

    const setLoader = async (status) => {
        dispatch({ type: SET_LOADER, payload: status });
    };

    return (
        <AppContext.Provider
            value={{
                user: state.user,
                isLoggedIn: state.isLoggedIn,
                token: state.token,
                loader: state.loader,
                coursesData: state.coursesData,
                tempSubcategoryName: state.tempSubcategoryName,
                handleRefresh,
                setIsLoggedIn,
                setUser,
                setToken,
                setLoader,
                setCourseData,
                setTempSubcategory
            }}
        >
            {props.children}
        </AppContext.Provider>
    )
}
export default AppState;
