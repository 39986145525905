import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #808080",
    margin: " 2rem 8rem 20rem 8rem",
    padding: "1rem",
    "& .MuiTypography-h1": {
      fontSize: "1rem",
      fontWeight: "600",
    },
  },
  tablecontainer: {
    marginTop: "2rem",
    marginLeft: "2rem",
    "& .MuiTypography-h1": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "58%",
      border: "1px solid #808080",
      textAlign: "center",
      background: "#2D5987",
      color: "#FFF",
      padding: "0.2rem",
    },
    "& .MuiTypography-h2": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "40%",
      border: "1px solid #808080",
      textAlign: "center",
      background: "#2D5987",
      color: "#FFF",
      padding: "0.2rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      fontWeight: "400",
      width: "90%",
      padding: "0.2rem",
      marginTop:"1rem"
    },
  },
  innertablecontainer: {
    display: "flex",
    width: "100%",
    "& .MuiTypography-h3": {
      fontSize: "1rem",
      fontWeight: "400",
      width: "58%",
      border: "1px solid #808080",
      padding: "0.2rem",
    },
    "& .MuiTypography-h4": {
      fontSize: "1rem",
      fontWeight: "400",
      width: "40%",
      border: "1px solid #808080",
      padding: "0.2rem",
    },
  },
}));

const MarkingSchemeNeet = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h1">
          Q: What is the marking scheme of NEET?
        </Typography>
        <Typography>
          The marking scheme for NEET has been stated by the National Testing
          Agency (NTA). It is expected that the marking scheme will remain the
          same for the next year's NEET as well. Four marks is awarded for each
          correct answer and one mark is deducted for each wrong answer. The
          complete marking scheme is given in the table below.
        </Typography>
        <div className={classes.tablecontainer}>
          <div className={classes.innertablecontainer}>
            <Typography variant="h1"> Response Type</Typography>
            <Typography variant="h2"> Marks awarded</Typography>
          </div>
          <div className={classes.innertablecontainer}>
            <Typography variant="h3"> Correct answer</Typography>
            <Typography variant="h4"> +4</Typography>
          </div>
          <div className={classes.innertablecontainer}>
            <Typography variant="h3"> Incorrect answer</Typography>
            <Typography variant="h4"> -1</Typography>
          </div>
          <div className={classes.innertablecontainer}>
            <Typography variant="h3">
              {" "}
              Question with more than one response
            </Typography>
            <Typography variant="h4"> -1</Typography>
          </div>
          <div className={classes.innertablecontainer}>
            <Typography variant="h3">Unanswered question</Typography>
            <Typography variant="h4"> 0</Typography>
          </div>
          <div className={classes.innertablecontainer}>
            <Typography variant="h3">Extra attempted question</Typography>
            <Typography variant="h4"> 0</Typography>
          </div>
          <Typography variant="body1">
            Since the total number of questions in NEET question paper is 200,
            out of which 180 questions have to be attempted, the total marks of
            the exam is 720.
          </Typography>
        </div>
      </div>
    </>
  );
};

export default MarkingSchemeNeet;
