import React, { useContext, useEffect, useRef } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import downloadicon from "../../image/dashboard/connection/downloadicon.png";
import profileicon1 from "../../image/dashboard/connection/profileicon1.png";
import DefaultProfile from "../../image/dashboard/setting/profile.png";
import questionimages from "../../image/dashboard/connection/questionimages.png";
import likeicon from "../../image/dashboard/connection/likeicon.png";
import profilegirl from "../../image/dashboard/connection/profilegirl.png";
import { useState } from "react";
import {
  DeleteDoubtForumAnswer,
  DeleteDoubtForumQuestions,
  DoubtForme,
  DoubtForumAnswer,
  getDoubtForumAnswer,
  getDoubtForumQuestions,
} from "../../../service/services";
import { json } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FetchProfileContext from "../../../context/FetchUserProfile/FetchProfileContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0rem 2rem 1rem 2rem ",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      margin: "0px !important",
    },
    "& .ql-toolbar": {
      borderRadius: "10px 10px 0 0",
    },
    "& .ql-container": {
      height: "160px",
      borderRadius: "0 0 10px 10px",
    },
    "& .MuiTypography-h6": {
      color: "#353535",
      fontFamily: "Playfair Display",
      fontSize: "1.375rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.75rem",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "1rem",
      },
    },
    "& .MuiTypography-h5": {
      color: " #000",
      fontFamily: "Jost",
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    "& .MuiTypography-h4": {
      color: "#7D7D7D",
      fontFamily: "Jost",
      fontSize: "0.625rem",
      fontWeight: 400,
    },
    "& .MuiTypography-caption": {
      display: "flex",
      alignItems: "center",
      color: "#353535",
      fontFamily: "Playfair Display",
      fontSize: "1.375rem",
      fontWeight: 700,
    },
    "& .MuiTypography-body1": {
      color: "#575757",
      fontFamily: "Jost",
      fontSize: "1.25rem",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
  },
  textfiled: {
    padding: "0.6rem",
    borderRadius: "0.375rem",
    border: "1px solid #BDBDBD",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem",
    },
  },
  quillcontainer: {
    marginTop: "1rem",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  commentquillcontainer: {
    marginTop: "1rem",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  profileicon: {
    display: "flex",
    alignItems: "center",
  },
  placeholder: {
    color: "#A4A4A4",
    fontFamily: "Jost",
    fontSize: "1.375rem",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  profilecontainer: {
    marginTop: "3rem",
  },
  postcontainer: {
    display: "flex",
    marginLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
  },
  postimage: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  images: {
    marginLeft: "5rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  like: {
    color: "#A4A4A4",
    fontFamily: "Jost",
    fontSize: "1.375rem",
    fontWeight: 400,
  },
  textfiledinnerdiv: {
    padding: "0.5rem 1rem",
    borderRadius: "0.375rem",
    border: "1px solid #BDBDBD",
    alignItems: "center",
    justifyContent: "space-between",

    display: "flex",
    width: "88%",
    marginLeft: "1.5rem",
  },
  profilecontainerinner: {
    padding: "1rem",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
      justifyContent: "center",
      marginBottom: "1.5rem",
    },
  },
  A: {
    color: "#7D7D7D",
    fontfamily: " Playfair Display",
    fontsize: "1.5625rem",
    fontweight: 700,
  },
  textfiled2: {
    display: "flex",
    marginLeft: "5rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.1rem",
    },
  },
  answerwithpostdiv: {
    display: "flex",
    padding: "2rem",
    marginLeft: "2.8rem",
    [theme.breakpoints.down("xs")]: {
      display: "inline-flex",
      padding: "0.1rem",
      margin: "0.5rem",
    },
  },
  answercontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  postcontainer1: {
    borderRadius: "0.375rem",
    background: "#FFFBEF",
    padding: "1rem 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0.5rem",
    },
  },
  viewbtn: {
    "&.MuiButton-root": {
      width: "21%",
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative", // Position relative to contain the absolutely positioned button
    marginTop: "1rem", // Adjust this margin as needed
  },

  // New styles for the button
  sendButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: "100px", // Align to the left-hand side
  },
  downloadcontainer: {
    borderRadius: "0.375rem",
    padding: "0rem 0rem",
    paddingTop: "0.2rem",
    display: "flex",
    alignItems: "center",
    background: "#FFFAEF",
  },
  viewOneAnswerButton: {
    backgroundColor: "green", // Button background color
    color: "white", // Button text color
    "&:hover": {
      backgroundColor: "#ffc107", // Button background color on hover
    },
    margin: "auto", // Center the button horizontally
    display: "block", // Ensure the button takes up the full width available
  },
  viewAllAnswerButton: {
    backgroundColor: "green", // Button background color
    color: "white", // Button text color
    "&:hover": {
      backgroundColor: "#ffc107", // Button background color on hover
    },
    margin: "auto", // Center the button horizontally
    // display: 'block', // Ensure the button takes up the full width available
  },
  heading: {
    color: "#243B55 !important",
    fontFamily: "Playfair Display !important",
    fontSize: "1.625rem !important",
    fontWeight: '700 !important',
    borderBottom: "1px solid #CACACA !important",
    width: "30% !important",
    marginTop: "0.2em !important",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

const DoubtForm = () => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");

  const [image, setImage] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [displayedAnswersCount, setDisplayedAnswersCount] = useState(2);
  const { profileState } = useContext(FetchProfileContext);
  console.log(profileState, "profileState");
  const quillRef = useRef(null);
  const quillRef1 = useRef(null);

  const handleQuestionChange = (content, delta, source, editor) => {
    if (source === "user") {
      const imageDelta = delta.ops.find((op) => op.insert && op.insert.image);
      if (imageDelta) {
        const imageBase64 = imageDelta.insert.image;
        const base64WithoutPrefix = imageBase64.split(";base64,")[1];
        const binaryString = atob(base64WithoutPrefix);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/jpeg" });
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        console.log(file, "okokok");
        setImage(file);

        // Append the image tag to the newQuestion variable
        const imageTag = `<img src="${imageBase64}" alt="Inserted Image" />`;
        setNewQuestion((prevQuestion) => prevQuestion + imageTag);
        return;
      }
      // If no image is inserted, set the new question to the editor's text
      setNewQuestion(editor.getHTML()); // Use getHTML to get the HTML content
      console.log(newQuestion, "okokok");
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", URL.createObjectURL(file));
    }
  };

  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        { script: "sub" },
        { script: "super" },
        { color: [] },
        { background: [] },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "list",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "link",
    "blockquote",
    "code-block",
    "align",
    "image",
  ];

  const handleSubmitQuestion = async () => {
    try {
      if (!newQuestion) {
        toast.error("Please enter your questions");
        return;
      }
      const token = localStorage.getItem("token");
      const user = {
        doubtForum: {
          question: newQuestion,
        },
      };

      // formData.append("doubtForum", JSON.stringify({ question: newQuestion }));

      const response = await DoubtForme(user, token);
      toast.success("Question submitted successfully!");
      console.log("API Response:", response);
      fetchData();
      setImage("");
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("Failed to submit question.");
    }
    if (newQuestion.trim() !== "") {
      const questionObject = {
        question: newQuestion,
        image: image,
        answers: [],
      };
      setQuestions([...questions, questionObject]);
      setNewQuestion("");
      setImage(null);
    }
  };

  const [answer, setAnswer] = useState({});
  const [id, setId] = useState([]);
  const [questionId, setQuestionId] = useState([]);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await getDoubtForumQuestions(token);
      console.log("API Response:", response.data);
      setQuestions(response?.data?.doubtForum);

      const questionIds = response?.data?.doubtForum.map(
        (question) => question.id
      );
      setQuestionId(questionIds);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (content, delta, source, editor) => {
    if (source === "user") {
      const imageDelta = delta.ops.find((op) => op.insert && op.insert.image);
      if (imageDelta) {
        const imageBase64 = imageDelta.insert.image;
        const base64WithoutPrefix = imageBase64.split(";base64,")[1];
        const binaryString = atob(base64WithoutPrefix);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/jpeg" });
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        console.log(file, "okokok");
        setImage(file);

        const imageTag = `<img src="${imageBase64}" alt="Inserted Image" />`;
        setAnswer((prevQuestion) => prevQuestion + imageTag);
        return;
      }
      setAnswer(editor.getHTML()); // Use getHTML to get the HTML content
      console.log(answer, "okokok");
    }
  };

  const handleAnswerFieldClick = (questionId) => {
    setCurrentQuestionId(questionId);
  };

  console.log(questionId, "questionId");
  const handleAnswerSubmit = async (id) => {
    try {
      const token = localStorage.getItem("token");

      const user = {
        doubtForumAnswer: {
          answer: answer,
          question_id: id,
        },
      };

      const response = await DoubtForumAnswer(user, token);
      console.log("API Response:", response);
      toast.success("answer submit succesfully");
      setAnswers((prevAnswers) => [...prevAnswers, response.data]);
      localStorage.setItem(
        "answers",
        JSON.stringify([...answers, response.data])
      );
      const fetchedResponse = await getDoubtForumAnswer(token, id);
      console.log("API Response:", fetchedResponse.data?.doubtForumAnswer);

      setAnswers((prevAnswers) => [
        ...prevAnswers,
        ...fetchedResponse.data?.doubtForumAnswer,
        response.data,
      ]);

      setAnswer((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(" value too long for type character varying(255)");
    }
  };

  const [answers, setAnswers] = useState([]);

  const data = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const fetchedResponse = await getDoubtForumAnswer(token, id);
      console.log("API Response:", fetchedResponse.data?.doubtForumAnswer);

      setAnswers((prevAnswers) => [
        ...prevAnswers,
        ...(fetchedResponse.data?.doubtForumAnswer || []),
      ]);

      setAnswer((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    } catch (error) {
      console.error("An error occurred while fetching answers:", error);
    }
  };

  useEffect(() => {
    const storedAnswers = JSON.parse(localStorage.getItem("answers"));
    if (storedAnswers) {
      setAnswers(storedAnswers);
    }
  }, []);

  useEffect(() => {
    const fetchData = async (questionId) => {
      const token = localStorage.getItem("token");
      try {
        const fetchedResponse = await getDoubtForumAnswer(token, questionId);
        console.log("API Response:", fetchedResponse.data?.doubtForumAnswer);

        setAnswers((prevAnswers) => [
          ...prevAnswers,
          ...(fetchedResponse.data?.doubtForumAnswer || []),
        ]);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    questionId.forEach((id) => {
      fetchData(id);
    });
  }, [questionId]);
  const [uniqueAnswers, setUniqueAnswers] = useState([]);

  useEffect(() => {
    const uniqueAnswersSet = new Set();
    const uniqueAnswersArray = [];

    answers.forEach((answer) => {
      if (!uniqueAnswersSet.has(answer.id)) {
        uniqueAnswersSet.add(answer.id);
        uniqueAnswersArray.push(answer);
      }
    });

    setUniqueAnswers(uniqueAnswersArray);
  }, [answers]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.root}>
        <Typography gutterBottom className={classes.heading}>Doubt Forum</Typography>

        <div className={classes.quillcontainer}>
          <div className={classes.profileicon}>
            <img
              src={
                profileState?.user?.image_url
                  ? profileState?.user?.image_url
                  : DefaultProfile
              }
              alt="img"
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "93%" }}
          >
            <ReactQuill
              ref={quillRef}
              value={newQuestion}
              onChange={handleQuestionChange}
              modules={modules}
              formats={formats}
              className={classes.placeholder}
              placeholder="Start asking questions"
              style={{ width: "100%" }}
            />
            <Button
              onClick={handleSubmitQuestion}
              className={classes.viewbtn}
              style={{ alignSelf: "end", marginTop: "10px" }}
            >
              Send
            </Button>
          </div>
        </div>

        <div className={classes.profilecontainer}>
          {questions &&
            questions?.map((question, index) => (
              <div key={index}>
                <div className={classes.profilecontainerinner}>
                  <div>
                    <img
                      src={
                        question?.addedBy?.image_url
                          ? question?.addedBy?.image_url
                          : DefaultProfile
                      }
                      alt="img"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div>
                    <Typography variant="h6">
                      {question?.addedBy?.full_name}
                    </Typography>
                    {question?.addedBy && question.addedBy?.category && (
                      <Typography variant="h5">
                        {question?.addedBy?.category} {question?.addedBy?.role}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className={classes.postcontainer}>
                  <div>
                    {/* <Typography variant="caption">
                     
                       {question?.question}
                    </Typography> */}
                    <Typography
                      variant="caption"
                      dangerouslySetInnerHTML={{
                        __html: `<p>Q.</p> ${question?.question}`,
                      }}
                    ></Typography>

                    {question.image_url && (
                      <img
                        src={question?.image_url}
                        alt="question-icon"
                        className={classes.postimage}
                        width="100%"
                        height="200px"
                      />
                    )}
                  </div>
                </div>

                {/* <div className={classes.profilecontainerinner}>
                  <div className={classes.images}>
                    <Button>
                      <img src={likeicon} alt="img" />
                    </Button>
                  </div>
                  <div>
                    <span className={classes.like}> Like </span>
                  </div>
                </div> */}

                <div className={classes.commentquillcontainer}>
                  <div className={classes.profileicon}>
                    <img
                      src={
                        profileState?.user?.image_url
                          ? profileState?.user?.image_url
                          : DefaultProfile
                      }
                      alt="img"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "93%",
                    }}
                  >
                    <ReactQuill
                      ref={quillRef1}
                      value={answer}
                      onChange={handleChange}
                      modules={modules}
                      formats={formats}
                      className={classes.placeholder}
                      placeholder="Start asking answer"
                      style={{ width: "100%" }}
                    />
                    <Button
                      onClick={() => handleAnswerSubmit(question.id)}
                      className={classes.viewbtn}
                      style={{ alignSelf: "end", marginTop: "10px" }}
                    >
                      Answer
                    </Button>{" "}
                  </div>
                </div>

                {uniqueAnswers
                  .filter(
                    (ans) =>
                      ans.question_id ===
                      (question.id ? question.id.toString() : null)
                  )

                  .slice(0, displayedAnswersCount)
                  .map((answer, ansIndex) => (
                    <div key={ansIndex} className={classes.answerwithpostdiv}>
                      <div className={classes.answerWithPostContainer}>
                        <div className={classes.imageContainer}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <img
                                src={answer?.addedBy?.image_url ? answer?.addedBy?.image_url : DefaultProfile}
                                alt="img"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div>
                              <Typography variant="h6">
                                {answer?.addedBy?.full_name}
                              </Typography>
                              {answer?.addedBy && answer.addedBy && (
                                <Typography variant="h5">
                                  {answer?.addedBy?.role}
                                </Typography>
                              )}
                            </div>
                          </div>
                        </div>
                        {answer.image_url && (
                          <img
                            src={answer.image_url}
                            alt="img"
                            style={{ width: "100%", height: "200px" }}
                          />
                        )}

                        <div className={classes.postContainer}>
                          <Typography variant="body1">
                            {/* {answer.answer} */}
                            <Typography
                              variant="caption"
                              dangerouslySetInnerHTML={{
                                __html: `Ans. ${answer?.answer}`,
                              }}
                            ></Typography>
                          </Typography>
                          <Typography variant="body1">
                            {formatDate(answer.created_at)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          {/* {displayedAnswersCount < uniqueAnswers.length && (
                <Button
                  onClick={() =>
                    setDisplayedAnswersCount(displayedAnswersCount + 2)
                  }
                  style={{ background: "blue", color: "white" }}
                >
                  View More
                </Button>
              )}
            */}
        </div>
      </div>
    </>
  );
};

export default DoubtForm;
