import React from "react";
import { IconButton, Typography, makeStyles } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { ReactComponent as DeleteSvg } from "../image/shoppingcartvector/deletesvg.svg";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "1.5rem 0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content_course: {
    display: "flex",
    alignItems: "center",
    width: "58%",
  },
  description: {
    marginLeft: "1rem",
  },
  image: {
    background: "#FFE8B5",
    padding: "1.3rem 2rem 0.3rem 2rem",
    borderRadius: "0.375rem",
    "& img": {
      maxWidth: "100px",
      width: "100%",
      objectFit: "cover",
    },
  },
  ratings: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "46%",
    "& .MuiTypography-h6": {
      fontSize: "1.2rem",
      fontWeight: "500",
      fontFamily: "'Jost', sans-serif",
    },
    "& .MuiTypography-caption": {
      fontSize: "1.1rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "var(--gray-500, #8C94A3)",
    },
  },
  title: {
    marginTop: "0.6rem",
    "& .MuiTypography-h3": {
      fontSize: "1.38rem",
      fontWeight: "600",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  courseby: {
    display: "flex",
    justifyContent: "space-between",
    width: "57%",
    marginTop: "1.5rem",
    "& .MuiTypography-caption": {
      fontSize: "1.1rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "var(--gray-500, #8C94A3)",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "var(--gray-500, #4E5566)",
    },
  },
  content_quantitycontainer: {
    width: "11%",
  },
  content_quantity: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0rem 0.5rem",
    "& .MuiTypography-h6": {
      fontFamily: "Jost",
      fontSize: "1.375rem",
      fontWeight: "600",
      cursor: "default",
    },
    "& .MuiIconButton-root": {
      padding: "0.2rem",
      background: "#FFFFFF",
      color: "#424141",
      margin: "0",
      borderRadius: "0",
    },
  },
  content_price: {
    width: "11%",
    "& .MuiTypography-h3": {
      color: "#FF3939",
      fontSize: "1.625rem",
      textAlign: "center",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
    },
  },
  content_delete: {
    width: "10%",
    "& .MuiIconButton-root": {
      background: "#FFEEE8",
      borderRadius: "2px",
      color: "#FF3939",
    },
  },
  head: {
    // margin: "2rem 4rem",
    color: "#4E5566",
    padding: "0.9rem 1.5rem",
    background: "#F9F9F9",
    border: "1px solid var(--gray-100, #E9EAF0)",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.8rem",
      textTransform: "uppercase",
      fontWeight: "500",
    },
  },
  head_course: {
    width: "60%",
  },
  head_quantity: {
    width: "13.3%",
    textAlign: "center",
  },
  head_price: {
    width: "13.3%",
    textAlign: "center",
  },
  head_delete: {
    width: "13.3%",
    textAlign: "center",
  },
}));
function ShoppingCartRow({ data, removefromCartListAPI, updateCartListAPI }) {
  const classes = useStyles();
  const hasBooks = data?.product_type === "books";

  const handleIncrementDecrement = (action, id, quantity) => {
    if (quantity === 0) return;
    updateCartListAPI(id, data.product_id, quantity);
  };
  return (
    <div key={data.id}>
      <div className={classes.content}>
        <div className={classes.content_course}>
          <div>
            <div className={classes.image}>
              <img
                src={data?.product?.image_url || data?.image_url}
                alt={data?.product?.ebook_name || data?.product?.book_name}
              />
            </div>
          </div>
          <div className={classes.description}>
            {/* <div className={classes.ratings}>
              <StarIcon style={{ color: "#FD8E1F" }} />
              <Typography variant="h6">{data.rating}</Typography>
              <Typography variant="caption">
                ({data.totalreviews} Review)
              </Typography>
            </div> */}
            <div className={classes.title}>
              <Typography variant="h3">
                {data?.product?.ebook_name ||
                  data?.product?.book_name ||
                  data?.product?.product_name || data?.product_name}
              </Typography>
            </div>
            <div className={classes.courseby}>
              {/* <Typography variant="caption">Course by: </Typography> */}
              <Typography>{data?.product?.author}</Typography>
            </div>
          </div>
        </div>

        <div className={classes.content_quantitycontainer}>
          {hasBooks && (
            <div className={classes.content_quantity}>
              <IconButton
                onClick={() => {
                  handleIncrementDecrement(
                    "remove",
                    data.id,
                    data.quantity - 1
                  );
                }}
              >
                <AiOutlineMinus />
              </IconButton>
              <Typography variant="h6">{data.quantity}</Typography>
              <IconButton
                onClick={() => {
                  handleIncrementDecrement("add", data.id, data.quantity + 1);
                }}
              >
                <AiOutlinePlus />
              </IconButton>
            </div>
          )}
        </div>

        <div className={classes.content_price}>
          <Typography variant="h3">
            ₹{data.total_cost || data?.product?.cost}
          </Typography>
        </div>
        <div className={classes.content_delete}>
          <div className={classes.btn2}>
            <IconButton onClick={() => removefromCartListAPI(data.id)}>
              <DeleteSvg />
            </IconButton>
          </div>
        </div>
      </div>
      <hr className={classes.hr} />
    </div>
  );
}

export default ShoppingCartRow;
