import React from 'react'
import HeaderNotification from './headerNotification';
import { Card, CardContent, makeStyles } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: "275",
        padding: "auto",
        marginBottom: "20rem",
    },
    icon: {
        color: "orange",
        width: "20%"
    }

}));
const FeeApplication = [
    {
        id: 1,
        subCategories: "Minimum Age: 20 years"
    },
    {
        id: 2,
        subCategories: "Miximum Age: 25 years"
    },
    {
        id: 3,
        subCategories: "Candiate Must Have Been Born Not Earliar Than 02.08.1998 And   Not Later  Than 01.08.2023 To Be  Eligible To Apply"


    },
    {
        id: 4,
        subCategories: "Age Relation is Application As per rules."

    },

];


const AgeLimit = ({Notification1}) => {
    const classes = useStyles();
    // const heading=Notification1.requirements[2].heading;
    // const description=Notification1.requirements[2].description;
    const description = Notification1?.requirements?.[2]?.description;
const heading = Notification1?.requirements?.[2]?.heading;

    return (
        <>
            <Card classname={classes.card}>
                <HeaderNotification data={heading} />
                <CardContent>
                    <div className={classes.examslist}>
                        <List component="nav" aria-label="secondary mailbox folders">
                            {/* {

                                FeeApplication.map((data, index) => {

                                    return (
                                        <ListItem key={index} button selected={""}>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon className={classes.icon} />

                                            </ListItemIcon>
                                            <ListItemText primary={data.subCategories} />

                                        </ListItem>
                                    );
                                })
                            } */}


        <ListItem button selected={""}>
            <ListItemIcon>
                <FiberManualRecordIcon className={classes.icon} />

            </ListItemIcon>
            <ListItemText  >
            <div dangerouslySetInnerHTML={{ __html: description }} />

            </ListItemText  >


        </ListItem>
  


                        </List>
                    </div>


                </CardContent>

            </Card>

        </>


    )
}

export default AgeLimit
