import { Button, Typography, createTheme, makeStyles } from "@material-ui/core";
import React from "react";
import BannerLogo from "../image/testseriesvector/bannerlogo.png";
import { ReactComponent as CartIcon } from "../image/testseriesvector/carticon.svg";
import SecHead from "../Home/sectionHeading/sectionheading";
import TestSeriesCard from "../practicecomponent/cards/testSeriesCard";
import Slider from "react-slick";
import RecommendedLiveTestSeriesCard from "./RecommendedLiveTestSeriesCard";

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 362,
      xs: 395,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3rem",
  },
  cardContainer: {
    // display: "flex",
    // flexWrap: "wrap",
    marginTop: "2rem",
    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },

    "& .slick-prev:before, .slick-next:before": {
      fontSize: "55px",
      borderRadius: "40px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      bottom: 0,
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      padding: "0 !important",
      width: "516px !important",
      [theme.breakpoints.down("xs")]: {
        width: "311px !important",
      },
      // [theme.breakpoints.down("xxs")]: {
      //   // width: "364px !important",
      //   width: "310px !important",
      // },
    },

    "& .slick-dots li button:before": {
      color: "#141E30",
      fontSize: "15px",
    },
  },
  ContentWrapper: {
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.7rem",
    },
  },
}));

function RecommendedLiveTestSeries() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Mock Test as per your Preference",
      headingmain: "Recommended Live Test series",
    },
  ];
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.container}>
      <SecHead Heading={Heading} className={classes.mainHeading} />
      <div className={classes.cardContainer}>
        <Slider {...settings} className={classes.ContentWrapper}>
          <RecommendedLiveTestSeriesCard />
          <RecommendedLiveTestSeriesCard />
          <RecommendedLiveTestSeriesCard />
          <RecommendedLiveTestSeriesCard />
          <RecommendedLiveTestSeriesCard />
          <RecommendedLiveTestSeriesCard />
        </Slider>
      </div>
    </div>
  );
}

export default RecommendedLiveTestSeries;
