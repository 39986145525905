import React from 'react'
import HeaderNotification from './headerNotification';
import { Box, Button, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: "275",
        padding: "auto",
        marginBottom: "20rem",
    },
    icon: {
        color: "orange",
        width: "20%"
    },
    tabhead: {
        background: "#243B55",
        color: "#FFFFFF",

    },
    tabsell: {
        color: "#FFFFFF"
    },
    ca: {
        minWidth: 275,
        padding: "20px",
        backgroundColor: "#FFE8B5",
        fontFamily: "Playfair Display",
        fontStyle: "normal",
        fontFamily: 600,
        LineWeight: "28px",
    },
    box: {
        display: "flex",
        columnGap:"126px"
      
    },
    but: {
        color: "white",
        padding: "auto",
        backgroundColor:"#141E30 ",
        padding:"10px"

    },
    buttonbox:{
        flex:"end"

    }


}));

function createData(PostData, Vacancy) {
    return { PostData, Vacancy };
}

const rows = [
    createData('Fireman', 826),
    createData('Fireman Driver', 115),
    createData('Fireman', 826),

];

const FeeApplication = [
    {
        id: 1,
        subCategories: "Interest Candiate Can Read The Full Notification Before Apply online"
    },



];

const VacancyDetails = ({Notification1}) => {
    const classes = useStyles();
    const description = Notification1?.requirements?.[3]?.description;
    const heading = Notification1?.requirements?.[3]?.heading;
    
    return (
        <>
            <Card classname={classes.card}>
                <HeaderNotification data={heading} />
                <CardContent>
                    <div className={classes.examslist}>
                        <List component="nav" aria-label="secondary mailbox folders">
                            {/* {

                                FeeApplication.map((data, index) => {

                                    return (
                                        <ListItem key={index} button selected={""}>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon className={classes.icon} />

                                            </ListItemIcon>
                                            <ListItemText primary={data.subCategories} />

                                        </ListItem>
                                    );
                                })
                            } */}


        <ListItem  button selected={""}>
            <ListItemIcon>
                <FiberManualRecordIcon className={classes.icon} />

            </ListItemIcon>
            <ListItemText  >
            <div dangerouslySetInnerHTML={{ __html: description }} />

                </ListItemText>

        </ListItem>
  

                        </List>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tabhead}>
                                <TableRow>
                                    <TableCell className={classes.tabsell}>PostData</TableCell>
                                    <TableCell className={classes.tabsell} align="right">Vacancy</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.PostData}>
                                        <TableCell component="th" scope="row">
                                            {row.PostData}
                                        </TableCell>
                                        <TableCell align="right">{row.Vacancy}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </CardContent>

            </Card>
            <Card className={classes.ca}

            >
                <CardContent>
                    <Box className={classes.box} >
                        <Box>
                            <Typography style={{ fontWeight: "bold" }}>
                                Full Pdf for Outside Admit Card
                            </Typography>
                            <Typography>
                                Download All The Information Details for Odisha Admit Card
                            </Typography>
                        </Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Box className={classes.buttonbox}>
                            <Button className={classes.but} >Download Now</Button>
                        </Box>

                    </Box>

                </CardContent>
            </Card>
        </>

    )
}

export default VacancyDetails;
