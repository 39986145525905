import React from 'react'
import { Button, Card, Typography, makeStyles } from "@material-ui/core";
import downloadapplogo from "../../component/image/notification/downloadapplogo.png";
import playstorelogo from "../../component/image/notification/playstorelogo.png"
import DeleteIcon from '@material-ui/icons/Delete';
import applelogo from "../../component/image/notification/applelogo.png"




const useStyles = makeStyles((theme) => ({
    quiklinkscontainer: {
        width: "100%",
        height: "335px",
        marginTop: "5rem",
        marginBottom: "7rem"
    },

    quiklinkslist: {
        background: "#E0E0E030",
        display: "inline-block",
        width: "inherit",
        height: "inherit",
        overflow: "auto",
        borderRadius: "0.3rem",
        padding: "2rem 0",
        textAlign: "left",
        padding:"11px",
        // lineHeight: "50px",
        "& .MuiListItemIcon-root": {
            // paddingLeft: "2.5rem",
            minWidth: "0",
        },
        "& .MuiTypography-body1": {
            textTransform: "capitalize",
        },
        "& .Mui-selected": {
            background: "rgba(255, 255, 255, 1)",
            boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
        },
        "& .MuiList-padding": {
            padding: "0.5rem 1rem",
        },
        "& a": {
            color: "rgba(0, 0, 0, 0.87)",
            textDecoration: "none",
        },
    },
    heading1: {
        fontSize: "15px",
    },
    heading2:{
        fontSize: "30px",
    fontWeight: 700,
        
    },
    heading3:{
        fontWeight:"700px"

    },
    img:{
        textAlign: "center",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    },
    button1:{
        background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
   
    fontSize:"40px",
    display:"flex",
    width:"97%",
   
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 3rem",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },

    },
    button2:{
        background: "#FFB228",
    color: "white",
   
    fontSize:"40px",
    display:"flex",
    width:"97%",
   
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 3rem",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },

    },
}));

const DownloadApp = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.quiklinkscontainer}>
                <Card>
                    <div className={classes.quiklinkslist}>
                        <Typography className={classes.heading1}>Learn the fun way</Typography>
                        <Typography className={classes.heading2}>Download Our App</Typography>
                        <Typography className={classes.heading3}>Download our app & Discover Pariksha Do Revolutionary Way Of Learnings!</Typography>
                        <img className={classes.img} src={downloadapplogo} alt="img" />
                        <Button className={classes.button1}  variant="contained"   >
                            <img src={playstorelogo} alt='img' width="12%"/>&nbsp;&nbsp;
                            <label>Download Now</label>
                            </Button><br/>
                        <Button className={classes.button2}  startIcon={<playstorelogo />}>
                            <img src={applelogo} alt="img" width="12%"/>&nbsp;&nbsp;
                            <label> Download Now</label>
                           </Button>




                    </div>

                </Card>


            </div>

        </>
    )
}

export default DownloadApp;
