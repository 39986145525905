import React from "react";
import {
  Card,
  CardActionArea,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SSCLogo from "../../image/singlepagedescriptionvector/ssclogo.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    background: "#FFFCF4",
  },
  container: {
    maxWidth: "1250px",
    margin: "1rem auto",
    padding: "3rem 0",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
    "& .MuiCardActionArea-root": {
      padding: "1rem 1.5rem",
      [theme.breakpoints.down("xs")]: {
        padding: "1rem",
      },
    },
    "& .MuiTypography-h3": {
      fontSize: "2.1875rem",
      fontWeight: "700",
      color: "#252525",
      fontFamily: "Playfair Display",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      fontWeight: "400",
      color: "#727272",
      fontFamily: "Jost",
      textAlign: "center",
    },
  },
  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexWrap: "wrap",
      padding: "0.4rem"
    },

  },
  card: {
    background: "white",
    boxShadow: "0px 0px 20px rgba(58, 58, 58, 0.10)",
    borderRadius: "8px",
    transition: "all ease 0.2s",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
    },
  },
}));
function CoveredExamSection() {
  const classes = useStyles();

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <Typography variant="h3" gutterBottom>
          Covered Exams
        </Typography>
        <div className={classes.cardcontainer}>
          <Card className={classes.card}>
            <CardActionArea>
              <div>
                <img src={SSCLogo} alt="logo" />
              </div>
              <Typography variant="body1">CPO</Typography>
            </CardActionArea>
          </Card>
          <Card className={classes.card}>
            <CardActionArea>
              <div>
                <img src={SSCLogo} alt="logo" />
              </div>
              <Typography variant="body1">CPO</Typography>
            </CardActionArea>
          </Card>
          <Card className={classes.card}>
            <CardActionArea>
              <div>
                <img src={SSCLogo} alt="logo" />
              </div>
              <Typography variant="body1">CPO</Typography>
            </CardActionArea>
          </Card>
        </div>
      </div>
    </div>
  );
}
export default CoveredExamSection;
