const FacultyBioData = [
    {
      id: "1",
      university: "The University of California",
      degree: "Masters Degree, Foreign Language",
      date: "Sep 2017 - July 2022",
    },
    {
      id: "2",
      university: "The University of California",
      degree: "Masters Degree, Foreign Language",
      date: "Sep 2017 - July 2022",
    },
  ];

  export default FacultyBioData;