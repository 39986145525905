import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import QuestionBank from "./questionbank";
import QuizResult from "./quizresult";

const useStyles = makeStyles((theme) => ({
  maincontainer: {
   background: "#FFFBF1",
  },
}));

function SscQuiz() {
  const classes = useStyles();
  const [selectedLanguage, setSelectedLanguage] = useState("english");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    // You can perform additional actions based on the selected language if needed
  };
  return (
    <>
      <div className={classes.maincontainer}>
        <QuestionBank selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} handleLanguageChange={handleLanguageChange}/>
        <QuizResult selectedLanguage={selectedLanguage}/>
      </div>
    </>
  );
}
export default SscQuiz;
