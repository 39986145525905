import React from "react";
import PageHeading from "../pageheading/pageheading";
import DescriptionSection from "./descriptionsection";
import AffiliateMarketingSection from "./affiliatemarketingsection";
import RegistrationSection from "./registrationsection";
import WhatWeOffer from "./WhatWeOffer";
import AffiliatesBannerCard from "./AffiliatesBannerCard";
import FAQSection from "./FAQSection";

function Affiliates() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Create Your Own Space ",
      headingmain: "Affiliates",
      breadcrumb: "Collaborate / Affiliates",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <DescriptionSection />
      <WhatWeOffer />
      <AffiliatesBannerCard />
      <AffiliateMarketingSection />
      <RegistrationSection />
      {/* <FAQSection /> */}
    </div>
  );
};
export default Affiliates;