import React, { useEffect } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ShoppingCartRow from "./ShoppingCartRow";
import addCartContext from "../../context/AddCartContext/AddCartContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import makePayment from "../../Utils/makePayment";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginBottom: "10rem",
    background: "#FFFBF1",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiFormControlLabel-root": {
      marginRight: "5px",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#0E891A",
    },
  },
  containerinner1: {
    background: "#FFF",
    padding: "2rem 4rem",
  },

  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    margin: "2rem 0 2rem 0.5rem",
    padding: "0.8rem 2rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
  },
  proceedtobuybtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "0.8rem 2rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 3rem",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
  },
  backbtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    margin: "2rem 0 2rem 0.5rem",
    padding: "0.8rem 6rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 3rem",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
  },
  hr: {
    border: "1px solid #E9EAF0",
  },
  checkbox_and_deselect: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      color: "#0E891A",
      fontWeight: 500,
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      color: "#000",
      fontWeight: 500,
      marginRight: "5px",
    },
    "& a": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      color: "#2958FF",
      fontWeight: 500,
      textDecoration: "none",
    },
  },

  deselectbtn: {
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      color: "#FF3939",
      fontWeight: 500,
      textTransform: "capitalize",
    },
  },
  subtotalsection: {
    margin: "2rem 0",
    padding: "0.8rem 4rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background:
      "linear-gradient(274deg, rgba(255, 128, 8, 0.20) 16.11%, rgba(255, 200, 55, 0.20) 82.19%)",
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.5rem",
      fontWeight: "600",
      textTransform: "uppercase",
    },
    "& .MuiTypography-caption": {
      fontSize: "1rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "#000",
    },
    "& .MuiTypography-h3": {
      color: "#FF3939",
      fontSize: "1.625rem",
      textAlign: "center",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
    },
  },
  subtotal_heading: {
    width: "65%",
  },
  subtotal_total_and_proceed: {
    width: "35%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  notFoundData: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginTop: "10px",
    "& img": {
      width: "30%",
    },
  },
  btn: {
    display: "flex",
    justifyContent: "center",
  },
  head: {
    // margin: "2rem 4rem",
    color: "#4E5566",
    padding: "0.9rem 1.5rem",
    background: "#F9F9F9",
    border: "1px solid var(--gray-100, #E9EAF0)",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.8rem",
      textTransform: "uppercase",
      fontWeight: "500",
    },
  },
  head_course: {
    width: "60%",
  },
  head_quantity: {
    width: "13.3%",
    textAlign: "center",
  },
  head_price: {
    width: "13.3%",
    textAlign: "center",
  },
  head_delete: {
    width: "13.3%",
    textAlign: "center",
  },
}));
function ShoppingCartContent() {
  const classes = useStyles();
  const navigate = useNavigate();

  const location = useLocation();
  const { cartState, removefromCartListAPI, updateCartListAPI, fetchCartAPI } = useContext(addCartContext);
  const hasBooks = cartState?.cart?.products?.some((_) => _.product_type === 'books');
  useEffect(() => fetchCartAPI(), [])
console.log('cartState' , cartState)
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.containerinner1}>
         
          {cartState?.cart?.products?.length > 0 ? (
            <div>
            <div className={classes.head}>
            <div className={classes.head_course}>
              <Typography variant="caption">Course</Typography>
            </div>
            <div className={classes.head_quantity}>
              <Typography variant="caption">Quantity</Typography>
            </div>
            <div className={classes.head_price}>
              <Typography variant="caption">Prices</Typography>
            </div>
            <div className={classes.head_delete}>
              <Typography variant="caption">Delete</Typography>
            </div>
          </div>
            {cartState?.cart?.products?.map((data) => (
                <div>
                  <ShoppingCartRow
                    key={data?.id}
                    data={data}
                    removefromCartListAPI={removefromCartListAPI}
                    updateCartListAPI={updateCartListAPI}
                  />
                </div>
              ))}
              <div className={classes.checkbox_and_deselect}>
                {/* <div>
                  <Button className={classes.deselectbtn}>
                    Deselect All Items
                  </Button>
                </div> */}
              </div>
              <div className={classes.subtotalsection}>
                <div className={classes.subtotal_heading}>
                  <Typography variant="h6">Sub total</Typography>
                  <Typography variant="caption">{cartState?.cart?.product_count} item{cartState?.cart?.product_count > 1 && 's'}</Typography>
                </div>
                <div className={classes.subtotal_total_and_proceed}>
                  <div className={classes.total_price}>
                  <Typography variant="h3">₹{cartState?.cart?.total_cost}</Typography>
                    <Typography variant="caption">You Save: ₹{cartState?.cart?.total_discount}</Typography>
                  </div>
                  <div className={classes.btn}>
                    <Button
                    onClick={() => {
                        if (hasBooks) navigate("/checkoutpage");
                        else makePayment({order_type: "cart" , category:'non_physical' , products:cartState.cart?.products?.map(_=>({productId: _?.product_id, productType: _?.product_type}))});
                        // else makePayment({order_type: "single" , category:'non_physical' , products:cartState.cart?.products?.map(_=>({productId: _?.product_id, productType: _?.product_type}))});
                      }}
                      className={classes.proceedtobuybtn}
                    >
                      Proceed to {hasBooks ? 'Cart' : 'Buy'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.notFoundData}>
              <img src={require("../image/notFoundCart.png")} alt="img"/>
              <Typography variant="h5">
                Your Shopping cart is waiting to be filled
              </Typography>
              <Typography variant="body1">
                Browse to the books page to get more products on your Shopping
                Cart
              </Typography>
               <Link to={cartState?.cart?.products?.length > 0 ? location?.state?.prevPath : '/'}>
              <Button className={classes.backbtn}>Explore</Button>
            </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ShoppingCartContent;
