import React from "react";
import { makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import FeatureBenefitsCard from "./FeatureBenefitsCard";
import FeatureBenefitsListData from "./FeatureBenefitsListData";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "2rem",
    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  featuresbenefitssection: {
    marginTop: "2rem",
  },
  featuresbenefitsmain: {
    marginTop: '2rem',
  },
}));
function FeaturesBenefits() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "What’s in it for you",
      headingmain: "Features & Benefits",
    },
  ];
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.featuresbenefitssection}>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <SecHead Heading={Heading} />
          <div className={classes.featuresbenefitsmain}>
            <Slider {...settings}>
            {FeatureBenefitsListData.map((data, index) => (
              <div>
                <FeatureBenefitsCard data={data}/>
              </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FeaturesBenefits;