import {
  AppBar,
  Avatar,
  Backdrop,
  Badge,
  Button,
  ClickAwayListener,
  CssBaseline,
  Divider,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import parikshaDoLlogo from "../image/pariksha_Do_logo.svg";
import AllModal from "../allcoursesmodal/allcoursesmodal";
import Heading from "./headerdata";
import SignInModal from "../allpopups/signin";
import SignUPModal from "../allpopups/signup";
import ForgotPasswordModal from "../allpopups/forgotpassword";
import CreatePasswordModal from "../allpopups/createpassword";
import OtpModal from "../allpopups/otp";
import Dropdown from "./dropdown";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ProfileAvatar from "../image/header/profile_avatar.png";
import appContext from "../../context/AppContext";
import popupContext from "../../context/PopupContext/PopupContext";
import wishlistContext from "../../context/WishlistContext/WishlistContext";
import addCartContext from "../../context/AddCartContext/AddCartContext";
import BlankNotification from "../image/header/dropdownicon/notificationimg.jpg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LogOut } from "../../service/services";
import FetchProfileContext from "../../context/FetchUserProfile/FetchProfileContext";
import IconComponent from "../dashboard/IconComponent";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    fontFamily: "'Jost', sans-serif",
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: " #000",
      fontSize: "1.375rem",
      fontStyle: "normal",
      fontWeight: "600",
      textTransform: "capitalize",
      marginBottom: "1.5rem",
      lineHeight: "1",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
    },
  },
  resposivebackground: {
    background: "linear-gradient(90deg, #141E30 50%, #FFB36B 50%)",
    top: "0",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  appBar: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    width: "85%",
  },
  toolbar: {
    display: "flex",
    width: "100%",
    padding: "0 0",
  },
  logo: {
    width: "24%",
    marginTop: "0.4rem",
  },
  navlinks: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  headbtnsignin: {
    color: "black",
    textTransform: "capitalize",
    "&:hover": {
      background: "linear-gradient(90deg, #141E30 16.46%, #243B55 100%)",
      color: "#FFF",
    },
  },
  headbtnlogin: {
    color: "black",
    background: "white",
    textTransform: "capitalize",
    padding: "0 0.8rem",
    "&:hover": {
      background: "linear-gradient(90deg, #141E30 16.46%, #243B55 100%)",
      color: "#FFF",
    },
  },
  headlogbtncont: {
    background: "linear-gradient(270deg,  #FFB36B 0%, #FFD57A 100%)",
    zIndex: "1500",
    width: "15%",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    marginRight: "10px",
    fontSize: "0.8rem",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  headname: {
    paddingRight: "2px",
    "&:hover": {
      color: "#FFC736 !important",
    },
  },
  headlogbtninner: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  allcoursebtn: {
    margin: "0 30px",
    padding: "20px 10px 20px 10px",
    background: "rgb(255, 255, 255, 0.15)",
    color: "#fff",
    display: "flex",
    fontSize: "0.8rem",
    alignItems: "center",
    textTransform: "uppercase",
    borderRadius: "0",
    "&:hover": {
      background: "rgb(255, 255, 255, 0.15)",
      color: "#FFC736 !important",
    },
  },
  allcoursebtnactive: {
    margin: "0 30px",
    padding: "20px 10px 20px 10px",
    background: "#FFFAEF",
    color: "#212121",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    "&:hover": {
      background: "#FFFAEF",
      color: "#212121 !important",
    },
  },
  headnameallcourse: {
    paddingRight: "10px",
  },
  Loginsigninmodal: {
    zIndex: "1600 !important",
  },
  badge: {
    fontSize: 8,
    height: 12,
    minWidth: 6,
    width: 2,
    top: 6,
    right: 5,
  },
  headlogbtninnerLogin: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
    // alignItems: "center",
  },
  menuheadname: {
    fontSize: "0.875rem",
    fontFamily: "Jost",
    color: "#303030",
    textTransform: "capitalize",
    fontWeight: 500,
  },
  menuheadicon: {
    marginRight: "1rem",
  },
  notificationlist: {
    width: "420px",
    display: "flex",
    padding: "1rem 2rem",
    background: "#FFFAEF",
    "& .MuiList-root": {
      height: "340px",
      "& svg": {
        marginRight: "10px",
      },
    },
    "& a": {
      textDecoration: "none",
    },
  },
  list: {
    width: "500px",
    display: "flex",
    background: "#FFFFFF",
    "& .MuiList-root": {
      width: "250px",
      height: "400px",
      overflow: "auto",
      "& svg": {
        marginRight: "10px",
        marginBottom: "0px",
        padding: "0.3rem",
        background: "#FFF6DB",
        borderRadius: "5px",
      },
    },
    "& a": {
      textDecoration: "none",
    },
  },
  dropdowncard: {
    transform: "translate3d(-250px, 54px, 0px) !important",
  },
  dropdowncardNotification: {
    transform: "translate3d(-350px, 54px, 0px) !important",
  },
  divider: {
    margin: "1rem 0",
  },
  BlankNotification: {
    marginTop: "2rem",
    ".MuiDivider-root": {
      borderTop: "1px dashed #D0D0D0",
      borderBottom: "0",
      height: "0px",
      margin: "0",
      flexShrink: "0",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "dotted",
    },
  },
}));

function Header({ setDashBoardId, learnerMenu }) {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const anchorRef = useRef(null);
  const anchorRef1 = useRef(null);
  const [forgot, setforgot] = useState(false);
  const { wishList } = useContext(wishlistContext);
  const { cartState } = useContext(addCartContext);
  const AppContext = useContext(appContext);
  const {
    handleloginOpenclose,
    handlesignupOpenclose,
    handleForgotpasswordOpenclose,
    handleOtpOpenclose,
    handleCreatepasswordOpenclose,
    success,
    successOTP,
    open,
    setOpen,
    login,
    setlogin,
    signup,
    setsignup,
    forgotpassword,
    setforgotpassword,
    createpassword,
    otp,
    onetimepassword,
    mobileNumber,
    otpVerify,
    setOtpVerify,
  } = useContext(popupContext);
  const location = useLocation();
  const navigate = useNavigate();
  console.log(AppContext?.user, AppContext?.token);

  const { profileState } = useContext(FetchProfileContext);
  const handleToggleNotification = () => {
    setOpenNotification((prevOpen) => !prevOpen);
    setOpenProfileMenu(false);
  };

  const menu2 = [
    {
      id: 1,
      name: "My Dashboard",
      icon: "MyDashboard",
      path: "/admin/dashboard",
    },
    {
      id: 2,
      name: "My Test Series",
      icon: "MyTestSeries",
      path: "/admin/testseries",
    },
    {
      id: 3,
      name: "My Study Materials",
      icon: "MyStudyMaterials",
      path: "/admin/mystudymaterials",
    },
    {
      id: 4,
      name: "My Books",
      icon: "MyBooks",
      path: "/admin/mybooks",
    },
    {
      id: 5,
      name: "My Video Courses",
      icon: "MyVideoCourses",
      path: "/admin/myvideocourses",
    },
    {
      id: 6,
      name: "My Live Classes",
      icon: "MyLiveClasses",
      path: "/admin/myliveclasses",
    },
    {
      id: 8,
      name: "My Orders",
      icon: "MyPurchase",
      path: "/admin/myorder",
    },
    {
      id: 9,
      name: "My Achievements",
      icon: "MyAchievements",
      path: "/admin/myachievements",
    },
  ];

  const menu3 = [
    {
      id: 15,
      index_id: 15,
      name: "Help Desk",
      icon: "helpdesk",
      path: "/dashboard",
    },
    {
      id: 16,
      index_id: 16,
      name: "Settings",
      icon: "setting",
      path: "/dashboard",
    },
    {
      id: 18,
      index_id: 18,
      name: "Logout",
      icon: "logout",
    },
  ];

  const DynamicMenu = learnerMenu || [];

  const menu3WithLearnerMenu = Array.isArray(DynamicMenu)
    ? [...DynamicMenu, ...menu3]
    : [...menu3];

  const onLogout = async (data) => {
    try {
      var res = await LogOut({
        mobile: data,
      });
      localStorage.clear();
      window.location.reload();
      navigate("/");
      // AppContext.setUser(res?.data?.user);
      // AppContext.setToken(res?.data?.token);
      // await authorizeMe(res?.data?.token);
      // localStorage.setItem("token", res?.data?.token);
      // AppContext.setIsLoggedIn(true);
      // handleloginOpenclose();
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "User is already logged in on another device."
      ) {
        console.log("setAlreadyLogin", err);
        toast.success(err?.response?.data?.message);
      } else if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const handleToggle = () => {
    setOpenProfileMenu((prevOpen) => !prevOpen);
    setOpenNotification(false);
  };
  const handleClose = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event?.target)) {
      return;
    }
    setOpenProfileMenu(false);
    setOpenNotification(false);
  };
  const handleCloseNotification = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event?.target)) {
      return;
    }
    setOpenProfileMenu(false);
    setOpenNotification(false);
  };
  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };
  const handleOpenclose = () => {
    setOpen(!open);
    setlogin(false);
    setsignup(false);
    setforgotpassword(false);
    if (open) AppContext.setTempSubcategory(null);
  };

  const classes = useStyles();

  const getParams = (url) => {
    const params = new URLSearchParams(url.split("?")[1]);
    const data = {};
    for (const [key, value] of params) {
      data[key] = value;
    }
    return data;
  };

  const handleLogin = () => {
    const queryParams = getParams(location.search);

    if (queryParams && queryParams.token) {
      const user = {
        id: queryParams?.id,
        full_name: queryParams?.full_name,
        phone: queryParams?.phone,
        email: queryParams?.email,
      };

      AppContext.setUser(user);
      AppContext.setToken(queryParams.token);
      localStorage.setItem("token", queryParams.token);
      AppContext.setIsLoggedIn(true);

      window.history.replaceState({}, document.title, window.location.pathname);

      toast.success("Login successful");

      window.location.href = "/dashboard";
    } else if (queryParams && queryParams.message) {
      toast.error(queryParams.message);
    }
  };

  window.onload = () => {
    handleLogin();
  };

  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <AppBar position="static" className={classes.appBar}>
            <CssBaseline />
            <Toolbar className={classes.toolbar}>
              <Typography variant="h4" className={classes.logo}>
                <Link
                  to="/"
                  className={classes.logo}
                  onClick={() => {
                    setOpen(false);
                    window.scroll(0, 0);
                    AppContext.setCourseData({});
                  }}
                >
                  <img src={parikshaDoLlogo} alt="img" width="100%" />
                </Link>
              </Typography>
              <div className={classes.navlinks}>
                <div className={classes.cusmodal}>
                  <Button
                    className={`${classes.allcoursebtn} ${
                      open === true && classes.allcoursebtnactive
                    }`}
                    type="button"
                    onClick={handleOpenclose}
                  >
                    <span className={classes.headnameallcourse}>
                      {open && AppContext?.tempSubcategoryName
                        ? AppContext?.tempSubcategoryName
                        : AppContext?.coursesData?.exam_name
                        ? AppContext?.coursesData?.exam_name
                        : "All Courses"}
                      {/* {AppContext?.coursesData?.exam_name ? AppContext?.coursesData?.exam_name : 'All Courses'} */}
                    </span>
                    <RxHamburgerMenu />
                  </Button>
                </div>
              </div>
              {/* {Heading.map((head) => (
                  <Link to={head.to} className={classes[head.style]}>
                  <span className={classes.headname}>{head.name}</span>
                      {head.icon}
                  </Link>
                ))}
                <Dropdown/>
                <Dropdown/> */}
              <div className={classes.navlinks}>
                {Heading.map((head, index) => {
                  if (head.type === "dropdown") {
                    return <Dropdown dropdownitem={head} setOpens={setOpen} />;
                  }
                  return (
                    <Link
                      to={head.to}
                      className={classes[head.style]}
                      onClick={() => {
                        setOpen(false);
                        window.scroll(0, 0);
                      }}
                    >
                      <span className={classes.headname}>{head.name}</span>
                    </Link>
                  );
                })}
              </div>
            </Toolbar>
          </AppBar>
          <div className={classes.headlogbtncont}>
            {!AppContext.isLoggedIn ? (
              <div className={classes.headlogbtninner}>
                <Button
                  href="#"
                  color="primary"
                  type="button"
                  onClick={handlesignupOpenclose}
                  className={classes.headbtnsignin}
                >
                  Sign Up
                </Button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.Loginsigninmodal}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  open={signup}
                  onClose={handlesignupOpenclose}
                >
                  <SignUPModal
                    handlesignupOpenclose={handlesignupOpenclose}
                    handleloginOpenclose={handleloginOpenclose}
                    handleOtpOpenclose={handleOtpOpenclose}
                    success={success}
                    setOtpVerify={setOtpVerify}
                  />
                </Modal>
                <Button
                  href="#"
                  color="primary"
                  type="button"
                  onClick={handleloginOpenclose}
                  className={classes.headbtnlogin}
                >
                  Log in
                </Button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.Loginsigninmodal}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  open={login}
                  onClose={handleloginOpenclose}
                >
                  <SignInModal
                    handleloginOpenclose={handleloginOpenclose}
                    handlesignupOpenclose={handlesignupOpenclose}
                    handleForgotpasswordOpenclose={
                      handleForgotpasswordOpenclose
                    }
                    handleOtpOpenclose={handleOtpOpenclose}
                    success={success}
                  />
                </Modal>
              </div>
            ) : (
              <div className={classes.headlogbtninnerLogin}>
                <IconButton
                  aria-label="notification"
                  className={classes.profilebutton}
                  ref={anchorRef1}
                  aria-controls={
                    openNotification ? "menu-list-grow" : undefined
                  }
                  aria-haspopup="true"
                  onClick={handleToggleNotification}
                >
                  <Badge
                    // badgeContent={4}
                    color="error"
                    classes={{ badge: classes.badge }}
                  >
                    <NotificationsIcon style={{ color: "#243B55" }} />
                  </Badge>
                </IconButton>
                <Popper
                  style={{ marginTop: 10 }}
                  open={openNotification}
                  anchorEl={anchorRef1.current}
                  role={undefined}
                  transition
                  className={classes.dropdowncardNotification}
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transform:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper className={classes.notificationlist}>
                        <ClickAwayListener
                          onClickAway={handleCloseNotification}
                        >
                          <MenuList
                            autoFocusItem={openNotification}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <Typography variant="h6">Notifications</Typography>
                            <Divider />
                            <div className={classes.BlankNotification}>
                              <img
                                src={BlankNotification}
                                width="100%"
                                alt="img"
                              />
                            </div>
                            {/* <Button onClick={() => navigate("/notification")}>
                              View All
                            </Button> */}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <IconButton
                  aria-label="delete"
                  onClick={() => navigate("/shoppingcart")}
                >
                  <Badge
                    badgeContent={cartState?.cart?.product_count}
                    color="error"
                    classes={{ badge: classes.badge }}
                  >
                    <ShoppingCartIcon style={{ color: "#243B55" }} />
                  </Badge>
                </IconButton>
                <IconButton
                  aria-label="notification"
                  onClick={() => navigate("/wishlist")}
                >
                  <Badge
                    badgeContent={wishList?.length}
                    color="error"
                    classes={{ badge: classes.badge }}
                  >
                    <FavoriteIcon style={{ color: "#243B55" }} />
                  </Badge>
                </IconButton>
                <div>
                  <Button
                    className={classes.profilebutton}
                    ref={anchorRef}
                    aria-controls={
                      openProfileMenu ? "menu-list-grow" : undefined
                    }
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <div className={classes.avatar}>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          profileState.image
                            ? profileState.image
                            : profileState.user.image_url
                            ? profileState.user.image_url
                            : ProfileAvatar
                        }
                        style={{ width: 35, height: 35 }}
                      />
                    </div>
                  </Button>
                  <Popper
                    style={{ marginTop: 10 }}
                    open={openProfileMenu}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    className={classes.dropdowncard}
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transform:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper className={classes.list}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={openProfileMenu}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              {menu2?.map((i, index) => {
                                return (
                                  <Link
                                    to={i.target !== "blank" && "/dashboard"}
                                  >
                                    {" "}
                                    <MenuItem
                                      onClick={() => {
                                        if (i?.name === "Logout") {
                                          localStorage.clear();
                                          window.location.reload();
                                          handleClose();
                                          navigate("/");
                                        } else {
                                          handleClose();
                                        }
                                        setDashBoardId(i?.id);
                                      }}
                                      style={{ padding: "10px 30px 10px 30px" }}
                                    >
                                      <IconComponent iconName={i?.icon} />
                                      <span className={classes.menuheadname}>
                                        {i?.name}
                                      </span>
                                    </MenuItem>
                                  </Link>
                                );
                              })}
                            </MenuList>
                          </ClickAwayListener>
                          <Divider
                            className={classes.divider}
                            orientation="vertical"
                            flexItem
                          />
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={openProfileMenu}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              {menu3WithLearnerMenu?.map((i) => {
                                return (
                                  <Link
                                    to={i.target !== "blank" && "/dashboard"}
                                    target={i.target === "blank" && "_blank"}
                                  >
                                    {console.log("data12334", i)}
                                    <MenuItem
                                      onClick={() => {
                                        if (i?.name === "Logout") {
                                          if (i?.name === "Logout") {
                                            onLogout(profileState.user.phone);
                                            handleClose();
                                          }
                                        } else {
                                          handleClose();
                                        }
                                        setDashBoardId(parseInt(i?.index_id));
                                      }}
                                      style={{ padding: "10px 30px 10px 30px" }}
                                    >
                                      <IconComponent iconName={i?.icon} />
                                      <span className={classes.menuheadname}>
                                        {i?.name}
                                      </span>
                                    </MenuItem>
                                  </Link>
                                );
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.resposivebackground}>
          <Modal
            open={open}
            onClose={handleOpenclose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <AllModal handleOpenclose={handleOpenclose} />
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.Loginsigninmodal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            open={forgotpassword}
            onClose={handleForgotpasswordOpenclose}
          >
            <ForgotPasswordModal
              handleloginOpenclose={handleloginOpenclose}
              handlesignupOpenclose={handlesignupOpenclose}
              handleForgotpasswordOpenclose={handleForgotpasswordOpenclose}
              handleOtpOpenclose={handleOtpOpenclose}
              success={success}
              setforgot={() => setforgot(true)}
            />
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.Loginsigninmodal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            open={createpassword}
            onClose={handleCreatepasswordOpenclose}
          >
            <CreatePasswordModal
              onetimepassword={onetimepassword}
              mobileNumber={mobileNumber}
              handleCreatepasswordOpenclose={handleCreatepasswordOpenclose}
              forgot={forgot}
            />
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.Loginsigninmodal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            open={otp}
            onClose={handleOtpOpenclose}
          >
            <OtpModal
              mobileNumber={mobileNumber}
              handleOtpOpenclose={handleOtpOpenclose}
              handleCreatepasswordOpenclose={handleCreatepasswordOpenclose}
              otpverify={otpVerify}
              forget={forgot}
              success={successOTP}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
export default Header;
