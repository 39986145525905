import React from "react";
import { Divider, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import testseriesicon from "../image/Card/testseriesicon.png";
import writeicon from "../image/Card/writeicon.png";
import redrupeesvector from "../image/Card/redrupeesvector.png";
import cardvector from "../image/Card/cardvector.png";
import Card2 from "./Card2";
import ssccglbluebook from "../image/Card/ssccglbluebook.png";
import redbackground from "../image/Card/redbackground.png";
import { ReactComponent as FolderVector } from "../image/Card/foldervector.svg";
import { ReactComponent as WriteVector } from "../image/Card/writevector.svg";
import { ReactComponent as RupeesVector } from "../image/Card/rupeesvector.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "25%",
    marginLeft: "1rem",
  },
  root: {
    "& .MuiTypography-h3": {
      color: " #000",
      fontFamily: "Playfair Display",
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: " 1.5625rem",
      width: "45%",
      // padding: "0.5rem",
    },
    "& .MuiDivider-vertical": {
      alignSelf: "center",
      height: "50px",
      width: "2px",
    },
  },
  btn: {
    color: "var(--text-color, #212121)",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.9375rem",
    fontWeight: 400,
    textTransform: "uppercase",
    background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
    width: "100% ",
    padding: "0.8rem",
  },
  centercontend: {
    display: "flex",
    marginTop: "1rem",
    alignItems: "center",
    marginLeft: "0.5rem",
  },
  testseriesdiv: {
    display: "flex",
    alignItems: "center",
    color: "#252525",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: " 0.625rem",
    fontWeight: 500,
    padding: "0.5rem",
  },
  redtext: {
    color: " #FF3939",
    fontFamily: "Jost",
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "1.875rem",
  },
  centercontend1: {
    marginTop: "0.4 rem",
    marginLeft: "0.8rem",
    marginBottom: "0px !important",
  },
  lighttext: {
    color: "#626262",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: " 1.875rem",
  },
  darktext: {
    color: " #252525",
    fontFamily: "Jost",
    fontSize: " 0.875rem",
    fontWeight: 400,
  },

  ssctextdiv: {
    padding: "1rem",
    position: "relative",
  },
  ssctextdiv1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"0.2rem 1rem",
  },
  newlaunch: {
    transform: "skew(10deg,0deg)",
    width: "100px",
    height: " 20px",
    background: "#FF3939",
    // padding: "0.5rem",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.75rem",
    fontWeight: 700,
    position: "absolute",
    top: "35px",
    right: "-17px",
    boxShadow: "-5px 5px 5px 0px rgba(255, 177, 59, 1)",
  },
}));

const SscCard = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              // height="140"
              image={ssccglbluebook}
              title="Contemplative Reptile"
            />
            <CardContent>
              <div className={classes.ssctextdiv}>
                <Typography variant="h3">SSC Maha Pack ( SSC CGL) </Typography>
                <div className={classes.newlaunch}>NEW LAUNCH</div>
              </div>
              <div className={classes.ssctextdiv1}>
                <div>
                  <div className={classes.testseriesdiv}>
                    <FolderVector />
                    &nbsp;&nbsp;
                    <span> 2k+ Test Series</span>
                  </div>
                  <div className={classes.testseriesdiv}>
                    <WriteVector />
                    &nbsp;&nbsp;
                    <span>Validity: 6 Months</span>
                  </div>
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  <img src={redrupeesvector} alt="img" />
                  <span className={classes.redtext}>50.00</span>
                  <br />
                  <span className={classes.lighttext}>
                    MRP: <RupeesVector /> <del>80.00</del>
                  </span>
                </div>
              </div>
            </CardContent>
          </CardActionArea>

          <Button className={classes.btn}>
            <img src={cardvector} alt="img" />
            &nbsp;&nbsp; Add to cart
          </Button>
        </Card>
      </div>
      <Card2 />
    </>
  );
};

export default SscCard;
