import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useContext } from "react";
import addCartContext from "../../context/AddCartContext/AddCartContext";
import MobileShoppingCartRow from "./MobileShoppingCartRow";
import { useNavigate } from "react-router-dom";
import makePayment from "../../Utils/makePayment";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    background: "#FFFBF1",
    marginBottom: "9rem",
    paddingBottom: "3rem",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "0 1rem",
    marginBottom: "10rem",
  },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiFormControlLabel-root": {
      marginRight: "5px",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#0E891A",
    },
  },
  containerinner1: {
    background: "#FFF",
    padding: "2rem 4rem",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0rem",
    },
  },
  head: {
    color: "#4E5566",
    background: "#F9F9F9",
    border: "0",
    padding: "0.3rem 0rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.75rem",
      fontWeight: "600",
      justifyContent: "space-between",
      textTransform: "uppercase",
    },
  },
  head_course: {
    width: "40%",
    textAlign: "center",
  },
  deselectbtn: {
    width: "40%",
    textAlign: "center",
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.875rem",
      color: "#FF3939",
      fontWeight: 500,
      textTransform: "capitalize",
    },
  },
  notFoundData: {
    display: "flex",
    justifyContent: "center",
    padding:"1rem",
    flexDirection:"column",
    alignItems:"center",gap:"10px",marginTop:"10px",
    "& img":{
      width : "30%"
    },
    '& .MuiTypography-h5':{
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: '1rem',
    },
    '& .MuiTypography-body1': {
      fontSize: '0.8rem',
      textAlign:'center',
      fontFamily: "'Jost', sans-serif",
      fontWeight: '400',
  }
  },
  backbtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "0.8rem 6rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem 3rem",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    margin: "2rem 0 2rem 0.5rem",
    padding: "0.8rem 2rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  btn: {
    margin: "0 1rem",
  },
  proceedtobuybtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    padding: "0.8rem 2rem",
    borderRadius: "0.4rem",
    width: "100%",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
    },
  },
  iconbutton: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    display: "none",
    padding: "0.5rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
  },

  checkbox_and_deselect: {
    marginTop: "1rem",
    padding: "0 0.5rem",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.95rem",
      color: "#0E891A",
      fontWeight: 500,
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.95rem",
      color: "#000",
      fontWeight: 500,
      marginRight: "5px",
    },
    "& a": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.95rem",
      color: "#2958FF",
      fontWeight: 500,
      textDecoration: "none",
    },
  },

  subtotalsection: {
    margin: "2rem 0",
    padding: "0.8rem 1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background:
      "linear-gradient(274deg, rgba(255, 128, 8, 0.20) 16.11%, rgba(255, 200, 55, 0.20) 82.19%)",
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
      textTransform: "uppercase",
    },
  
    "& .MuiTypography-caption": {
      fontSize: "0.85rem",
      fontWeight: "500",
      fontFamily: "'Jost', sans-serif",
      color: "#000",
    },
    "& .MuiTypography-h3": {
      color: "#FF3939",
      fontSize: "1rem",
      textAlign: "center",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
    },
  },
  subtotal_heading: {
    // width: "65%",
  },
  subtotal_total: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
function ShoppingCartContentMobile() {
  const classes = useStyles();
  const navigate = useNavigate();

  const location = useLocation();
  const { cartState, removefromCartListAPI, updateCartListAPI, fetchCartAPI } = useContext(addCartContext);
  const hasBooks = cartState?.cart?.products?.some((_) => _.product_type === 'books');
  useEffect(() => fetchCartAPI(), [])

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.containerinner1}>    
          {cartState?.cart?.products?.length > 0 ? (
          <div>
            <div className={classes.head}>
              <div className={classes.head_course}>
                <Typography variant="caption">My Course</Typography>
              </div>
              {/* <div className={classes.deselectbtn}>
              <Button>Deselect All Items</Button>
            </div> */}
            </div>
            {cartState?.cart?.products?.map((data) => (
              <MobileShoppingCartRow
                key={data?.id}
                data={data}
                removefromCartListAPI={removefromCartListAPI}
                updateCartListAPI={updateCartListAPI}
              />
            ))}
            {/* <div className={classes.checkbox_and_deselect}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                    checkedIcon={<CheckBoxIcon fontSize="medium" />}
                    name="checkedA"
                    checked={state.checkedA}
                    onChange={handleChange}
                  />
                }
                label="Your order is eligible for FREE Delivery."
              />
              <Typography variant="caption">
                Select this option at checkout.
              </Typography>
              <Link>Details</Link>
            </div>
          </div> */}

            <div className={classes.subtotalsection}>
              <div className={classes.subtotal_heading}>
                <Typography variant="h6">Sub total</Typography>
                <Typography variant="caption">
                  {cartState?.cart?.product_count} item
                  {cartState?.cart?.product_count > 1 && "s"}
                </Typography>
              </div>
              <div className={classes.subtotal_total}>
                <div className={classes.total_price}>
                  <Typography variant="h3">
                    ₹{cartState?.cart?.total_cost}
                  </Typography>
                  <Typography variant="caption">
                    You Save: ₹{cartState?.cart?.total_discount}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.btn}>
              <Button
                onClick={() => {
                  if (hasBooks) navigate("/checkoutpage");
                  else
                    makePayment({
                      order_type: "cart",
                      category: "non_physical",
                      products: cartState.cart?.products?.map((_) => ({
                        productId: _?.product_id,
                        productType: _?.product_type,
                      })),
                    });
                  // else makePayment({order_type: "single" , category:'non_physical' , products:cartState.cart?.products?.map(_=>({productId: _?.product_id, productType: _?.product_type}))});
                }}
                className={classes.proceedtobuybtn}
              >
                Proceed to {hasBooks ? "Cart" : "Buy"}
              </Button>
            </div>
          </div>
          ) : (
          <div className={classes.notFoundData}>
              <img src={require("../image/notFoundCart.png")} alt="img"/>
              <Typography variant="h5">
                Your Shopping cart is waiting to be filled
              </Typography>
              <Typography variant="body1">
                Browse to the books page to get more products on your Shopping
                Cart
              </Typography>
               <Link to={cartState?.cart?.products?.length > 0 ? location?.state?.prevPath : '/'}>
              <Button className={classes.backbtn}>Explore</Button>
            </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ShoppingCartContentMobile;
