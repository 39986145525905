import { SET_USER, SET_IS_LOGGED_In, SET_TOKEN, SET_LOADER, SET_COURSE_DATA, SET_TEMP_SUBCATEGORY_DATA } from "./AppType";
const Reducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {

        case SET_USER:
            return {
                ...state,
                user: payload
            };
        case SET_COURSE_DATA:
            return {
                ...state,
                coursesData: payload
            };

        case SET_IS_LOGGED_In:
            return {
                ...state,
                isLoggedIn: payload
            };

        case SET_TOKEN:
            return {
                ...state,
                token: payload
            };

        case SET_LOADER:
            return {
                ...state,
                loader: payload
            };

        case SET_TEMP_SUBCATEGORY_DATA:
            return {
                ...state,
                tempSubcategoryName: payload
            };

        default:
            return state;
    }
};

export default Reducer;