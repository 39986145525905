import React from "react";
import { makeStyles } from "@material-ui/core";
import EBooksSinglePageDescription from "../ebooks/ebookssinglepagedescription/EBooksSinglePageDescription";
import EbooksCard from "../ebooks/ebookscard";
import EbooksCardMobile from "../ebooks/ebookscardmobile";
import PreviousYearPaperCardMobile from "../practicecomponent/cards/previousYearPaperCardMobile";
import TestSeriesCardMobile from "../testseries/testseriescardmobile";
import TestSeriesCard from "../testseries/testseriescard";
import NotesSinglePageDescription from "../notes/notessinglepagedescription/NotesSinglePageDescription";
import TestSeriesSinglePageDescription from "../testseries/testseriessinglepagedescription/TestSeriesSinglePageDescription";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }, 
}));
function CurrentAffairs() {
  const classes = useStyles();
  return (
<div>
  {/* <h1>
    COMMING SOON Current Affairs
  </h1> */}
  {/* <EBooksSinglePageDescription /> */}
  {/* <EbooksCard /> */}
  {/* <EbooksCardMobile /> */}
  {/* <PreviousYearPaperCardMobile /> */}
  {/* <TestSeriesCardMobile /> */}
  {/* <TestSeriesCard /> */}
  <NotesSinglePageDescription />
  {/* <TestSeriesSinglePageDescription /> */}
</div>
  );
};
export default CurrentAffairs;
