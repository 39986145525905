import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import WelcomeSection from "./welcomesection";
import CoachingBannerCard from "../coachingspartner/CoachingBannerCard";
import MentorSlider from "./mentorslider";
import RegistrationSection from "./registrationsection";
import FeaturesBenefits from "../coachingspartner/FeaturesBenefits";
import FollowProcess from "../coachingspartner/FollowProcess";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
function Mentor() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Create Your Own Space ",
      headingmain: "Mentor",
      breadcrumb: "Collaborate / Mentor",
    },
  ];
  return (
    <>
      <PageHeading Heading={Heading} />
      <WelcomeSection />
      <CoachingBannerCard />
      <MentorSlider />
      <RegistrationSection />
      <FeaturesBenefits/>
      <FollowProcess/>
    </>
  );
}
export default Mentor;
