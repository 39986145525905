import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import SinglePageContent from "./singlepagecontent";
import PageHead from "../../../pageheading/pageheading";
import { useParams } from "react-router-dom";
import practicecomponentcontext from "../../context";

const useStyles = makeStyles((theme) => ({
  singlepagemain: {
    marginBottom: "10rem",
  },
}));
function SinglePageDescription() {
  const classes = useStyles();
  const { id } = useParams();
  const { getBookDetails, bookDetail } = useContext(practicecomponentcontext);
  const Heading = [
    {
      id: 1,
      // headingsmall: "Explore Your Study",
      // headingmain: "Indian Polity & Governance",
      breadcrumb: "Books / Product",
    },
  ];
  console.log("getBookDetails", bookDetail);
  useEffect(() => {
    getBookDetails(id);
  }, [id]);

  return (
    <div className={classes.singlepagemain}>
      <PageHead Heading={Heading} bookDetail={bookDetail} />
      <SinglePageContent bookDetail={bookDetail} />
    </div>
  );
}
export default SinglePageDescription;
