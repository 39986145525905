const partnerlist = [
  // {
  //   id: 1,
  //   name: "Institutes",
  //   to: "/coachingspartner",
  // },
  // {
  //   id: 2,
  //   name: "Investor",
  //   to: "/invester",
  // },
  // {
  //   id: 3,
  //   name: "Mentor",
  //   to: "/mentor",
  // },
  {
    id: 4,
    name: "Seller",
    to: "/seller",
  },
  {
    id: 5,
    name: "Affiliates",
    to: "/affiliates",
  },
];
export default partnerlist;
