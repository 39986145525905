import React from "react";
import {
  Card,
  CardActionArea,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    background: "#FFFCF4",
  },
  container: {
    maxWidth: "1250px",
    margin: "1rem auto",
    padding: "3rem 0",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
    "& .MuiCardActionArea-root": {
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "1rem",
      },
    },
    "& .MuiTypography-h3": {
      fontSize: "2.1875rem",
      fontWeight: "700",
      color: "#252525",
      fontFamily: "Playfair Display",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      fontWeight: "400",
      marginTop: "0.7rem",
      color: "#727272",
      fontFamily: "Jost",
      textAlign: "center",
    },
  },
  cardcontainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0.4rem",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
  card: {
    background: "white",
    margin: ".5rem",
    width: "210px",
    height: "170px",
    boxShadow: "0px 0px 20px rgba(58, 58, 58, 0.10)",
    borderRadius: "8px",
    transition: "all ease 0.2s",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
      width: "140px",
      height: "140px",
    },
  },
  examcoveredimg: {
    width: "75px",
    height: "75px",
  },
}));
function CoveredExamSection({ testseriesDetail }) {
  const classes = useStyles();
  console.log(testseriesDetail, "testseriesDetail");
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <Typography variant="h3" gutterBottom>
          Covered Exams
        </Typography>
        <div className={classes.cardcontainer}>
          {testseriesDetail?.map((data) => {
            return (
              <Card className={classes.card}>
                <CardActionArea>
                  <img
                    className={classes.examcoveredimg}
                    src={data?.image_url}
                    alt="logo"
                  />
                  <Typography variant="body1">{data?.exam_name}</Typography>
                </CardActionArea>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default CoveredExamSection;
