import React from "react";
import { Button, Typography, makeStyles, Divider } from "@material-ui/core";
import videoicon from "../../../image/singleliveclasses.image/videoicon.png";
import lockicon from "../../../image/singleonlinevideo/lockicon.png";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import playcircleicon from "../../../image/singleonlinevideo/playcircleicon.png";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    "& .MuiTypography-h2": {
      color: "#252525",
      fontFamily: "Playfair Display",
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    "& .MuiTypography-h6": {
      color: "#525252",
      fontFamily: "Jost",
      fontSize: "0.9375rem",
      fontWeight: 400,
      textTransform: "lowercase",
    },
    "& .MuiTypography-caption": {
      color: "#252525",
      fontFamily: "Quicksand",
      fontSize: "0.9375rem",
      fontWeight: 500,
    },
    "& .MuiTypography-body1": {
      color: "var(--Gray-900, #1D2026)",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  lecturediv1: {
    width: "100%",
    background: "#FFF",
    padding: "1rem 4rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    display: "flex",
    justifyContent: "space-between",
  },
  imagediv: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    color: "#303030",
    fontFamily: "Jost",
    fontSize: "1rem",
    fontWeight: 400,
    textTransform: "uppercase",
    borderRadius: "0.375rem",
    background: " linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    padding: "0.8rem 1.8rem",
    margin:"0.5rem",
  },
  container1: {
    marginTop: "1.5rem",
    background: "#FFF",
    "& .MuiTypography-h2": {
      color: "#252525",
      fontFamily: "Playfair Display",
      fontSize: "1.5rem",
      fontWeight: 600,
    },
  },
  headercontainer: {
    padding: "1rem 2rem",
  },
  line: {
    background: "#EFBF5A",
    border: "2px",
  },
  line1: {
    display: "flex",
    marginTop: "1rem",
    background: "#FFFAEE",
    padding: "1.5rem 2rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  keyicon: {
    color: "rgba(255, 128, 8, 1)",
    fontFamily: "Jost",
    fontSize: "1.2rem",
    fontWeight: 500,
    lineHeight: "1.375rem",
    display: "flex",
    alignItems: "center",
  },
  lecturediv: {
    display: "flex",
    alignItems: "center",
  },
  line2: {
    display: "flex",
    marginTop: "1rem",
    background: "#FFEBC1",
    padding: "1.5rem 2rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  hrline1: {
    marginTop: "0.5rem",
    background: "#E9EAF0",
  },
  line3: {
    display: "flex",
    marginTop: "1rem",
    padding: "1.5rem 2rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  morecontainer: {
    width: "100%",
    border: "1px solid #B7A0A0",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    marginTop:"1.5rem",
    textAlign:"center",
  },
  
}));

const LiveClassesSchedule = ({liveclassesDetail}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        {/* <div className={classes.lecturediv1}>
          <div className={classes.imagediv}>
            <div>
              <img src={videoicon} alt="img" />
            </div>
            <div>
              <Typography variant="body1">SSC Start to end</Typography>
              <Typography variant="h6">12.6 MB</Typography>
            </div>
          </div>
          <div>
            <Button className={classes.btn}>245 Leactures</Button>
          </div>
        </div> */}
        <div className={classes.container1}>
          <div className={classes.headercontainer}>
            <Typography variant="h2">Live Classes Schedule</Typography>
            <Divider className={classes.line} />
          </div>

          {/* <div className={classes.line1}>
            <div className={classes.keyicon}>
              <KeyboardArrowUpIcon />
              &nbsp;&nbsp;Getting Started
            </div>
            <div>
              <img src={playcircleicon} alt="img" />
              {console.log('liveclassLectures' , liveclassesDetail?.liveclassLectures)}
              &nbsp;&nbsp;{liveclassesDetail?.class_count} lectures
            </div>
          </div> */}

          {liveclassesDetail?.liveclassLectures?.map((data, index) => (
            <div>
          <div className={classes.line3}>
            <Typography variant="caption"> {index+1} {data?.topic_name}</Typography>

            <div className={classes.lecturediv}>
              <img src={lockicon} alt="img" /> &nbsp;&nbsp; 
              {new Date(data?.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })}
            </div>
          </div>
          <Divider className={classes.hrline1} />
          </div>
        ))}
         
         
         
        </div>
        {/* <div className={classes.morecontainer}>
          <Button className={classes.btn}>More</Button>
        </div> */}
      </div>
    </>
  );
};

export default LiveClassesSchedule;
