import React, { useContext, useState } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as CartBagIcon } from "../../../image/ebooksvector/cartbagicon.svg";
import { Link } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../../image/singlepagedescriptionvector/checkicon1.svg";
import makePayment from "../../../../Utils/makePayment";
import popupContext from "../../../../context/PopupContext/PopupContext";
import addCartContext from "../../../../context/AddCartContext/AddCartContext";
import appContext from "../../../../context/AppContext";
import { toast } from "react-toastify";
import FavouriteButton from "../../FavouriteButton";
import { ReactComponent as HexagonShape } from "../../../image/singlepagedescriptionvector/hexagonalblankshape.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2.5rem 2rem",
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down("xs")]: {
      background: "none",
      display: "block",
      padding: "2.5rem 1rem",
    },

    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "#FFFFFF",
    },
    "& .MuiToggleButton-root": {
      color: "#777777",
      background: "#FFFFFF",
      borderRadius: "40px",
      padding: "0.5rem",
      position: "absolute",
      right: "16px",
      top: "16px",
    },
    "& .MuiIconButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
    },
  },
  imagegallery: {
    width: "43%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      // fontSize: "0.875rem",
      width: "100%",
      margin: "0 auto",
    },
  },
  currentimagecontainer: {
    // width:"100%",
    position: "relative",
    padding: "2rem 5rem 0rem 5rem",
    background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 0",
      justifyContent: "space-evenly",
    },
  },
  tag: {
    background: "#FF3939",
    position: "absolute",
    top: "2.9rem",
    left: "0rem",
    padding: "0 1.8rem",
    [theme.breakpoints.down("xs")]: {
      top: "2rem",
      left: "0rem",
      padding: "0 1rem",
    },
    "& .MuiTypography-caption": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
      color: "#FFFFFF",
      fontFamily: " Playfair Display",
      fontSize: "1.3rem",
    },
  },
  imagewrapper: {
    // marginLeft: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      // marginLeft: "2.4rem",
    },
  },
  image: {
    width: "310px",
    objectFit: "contain",
    height: "310px",
    [theme.breakpoints.down("xs")]: {
      width: "310px",
      height: "310px",
    },
  },
  productdetails: {
    width: "52%",
    marginLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
      width: "100%",
      marginTop: "2rem",
    },
    "& .MuiTypography-h3": {
      fontFamily: " Playfair Display",
      fontSize: "2rem",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
  },
  author: {
    background: "#FFEEC9",
    display: "inline-block",
    margin: "0.7rem 0",
    padding: "0.3rem 1rem",
    borderRadius: "3px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.9rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "Jost",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.77rem",
      },
    },
  },
  testandvaliditycontainer: {
    display: "flex",
    width: "75%",
    margin: "1rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "0rem 0rem 0rem 0rem",
    },
  },
  hexagonshape: {
    position: "absolute",
    left: "-18px",
    [theme.breakpoints.down("xs")]: {
      left: "-15px",
    },
  },
  testseriestag: {
    position: "relative",
    margin: "1rem 0",
    display: "flex",
    width: "50%",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0.3rem 1rem 0.3rem 0.3rem",
    [theme.breakpoints.down("xs")]: {
      width: "42%",
      padding: "0.4rem",
      marginRight: "1rem",
      "& svg": {
        width: "56px",
      },
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
      cursor: "default",
      marginLeft: "3.5rem",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "2.5rem",
        fontSize: "0.625rem",
      },
    },
    "& .MuiTypography-caption": {
      clipPath: "polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%)",
      background: "white",
      color: "#313866",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.25rem",
      fontWeight: "500",
      position: "absolute",
      left: "10px",
      zIndex: "10",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  price: {
    margin: "1.5rem 0 1rem 0",
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      margin: "0",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Jost",
      fontSize: "1.7rem",
      fontWeight: "500",
      color: "#252525",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#626262",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: "400",
      marginRight: "0.2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#252525",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
  },
  mrpcontainer: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.4rem",
    },
  },
  mrp: {
    display: "flex",
    "& .MuiTypography-h3": {
      fontFamily: "Jost",
      fontSize: "2rem",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
        display: "none",
      },
    },
    "& span": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        marginRight: "0.2rem",
      },
    },
  },
  mrpmobile: {
    "& .MuiTypography-h3": {
      marginTop: "0.3rem",
      fontFamily: "Jost",
      fontSize: "2rem",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
    "& span": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "600",
      color: "#FF3939",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
        marginRight: "0.2rem",
      },
    },
  },
  offers: {
    display: "flex",
  },
  tax: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      alignItems: "baseline",
    },
    "& .MuiTypography-caption": {
      color: "#000000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.625rem",
      },
    },
  },
  link: {
    textDecoration: "none",
    "& .MuiTypography-h6": {
      color: "#206FE5",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: "400",
      marginLeft: "0.2rem",
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.675rem",
      },
    },
  },
  mrpandoffer: {
    width: "70%",
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  buttonscontainer: {
    width: "72%",
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  mobilebluecustombtn: {
    [theme.breakpoints.down("xs")]: {
      width: "45%",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "#fff",
    fontFamily: "Jost",
    padding: "11px 55px",
    fontSize: "1rem",
    borderRadius: "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#20324A",
      fontSize: "0.7rem",
      padding: "11px 0",
      marginRight: "0",
      width: "100%",
      "&:hover": {
        background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
        color: "#20324A",
      },
    },
  },
  mobileyellowcustombtn: {
    [theme.breakpoints.down("xs")]: {
      width: "45%",
    },
  },
  yellowcustombtn: {
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    color: "#20324A",
    fontFamily: "Jost",
    padding: "11px 24px",
    fontSize: "1rem",
    borderRadius: "5px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
      color: "#fff",
      padding: "11px 0",
      fontSize: "0.7rem",
      width: "100%",
      marginRight: "0px",
      "&:hover": {
        background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
        color: "#fff",
      },
      "& svg": {
        fill: "white",
        width: "16px",
        height: "16px",
      },
    },
  },
  cartbagicon: {
    marginRight: "0.6rem",
  },
  whishlisticon: {
    top: "2rem",
    right: "2rem",
    position: "absolute",
    "& svg": {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  offerssection: {
    marginLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  specialpricemain: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  specialpricemainmobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));
function ProductDetails({ onlinevideoDetail }) {
  const classes = useStyles();
  const { addToCartListAPI, cartList } = useContext(addCartContext);
  const { isLoggedIn, token } = useContext(appContext);
  const { handleloginOpenclose } = useContext(popupContext);
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);

  const handleAddToCart = () => {
    const check = cartList?.cart?.find((el) => el?.id === onlinevideoDetail.id);

    if (check) {
      toast.error("already added in cart");
    } else {
      addToCartListAPI(onlinevideoDetail);
    }
  };

  const handleOnlinePayment = () =>
    makePayment({
      order_type: "single",
      category: "non_physical",
      product: {
        productId: onlinevideoDetail?.id,
        productType: onlinevideoDetail?.product_type,
      },
    });

  // const handleOnlinePayment = () => makePayment({ products:[{productId:bookDetail?.id,  productType:bookDetail.product_type}]});

  return (
    <div className={classes.container}>
      <div className={classes.imagegallery}>
        <div className={classes.currentimagecontainer}>
          <img
            className={classes.image}
            src={
              onlinevideoDetail?.image_url ||
              require("../../../image/notFoundData.png")
            }
            alt="img"
          />
        </div>
        <div className={classes.whishlisticon}>
          <FavouriteButton
            isCheckedHeart={isCheckedHeart}
            setIsCheckedHeart={setIsCheckedHeart}
            data={onlinevideoDetail}
          />
        </div>
      </div>
      <div className={classes.productdetails}>
        <div className={classes.title}>
          <Typography variant="h3">{onlinevideoDetail?.video_name}</Typography>
        </div>
        <div className={classes.author}>
          <Typography variant="caption">
            By {onlinevideoDetail?.addedBy?.full_name}
          </Typography>
        </div>
        <div className={classes.testandvaliditycontainer}>
          <div className={classes.testseriestag}>
            <HexagonShape className={classes.hexagonshape} />
            <Typography variant="caption">
              {onlinevideoDetail?.no_of_lectures}
            </Typography>
            <Typography variant="h6">Video Lecture</Typography>
          </div>
        </div>
        <div className={classes.price}>
          <div className={classes.specialpricemainmobile}>
            <Typography variant="h3">Special Price :</Typography>
            <div className={classes.mrpmobile}>
              <Typography variant="h3">₹{onlinevideoDetail?.selling_cost}</Typography>
            </div>
          </div>
          <Typography className={classes.specialpricemain} variant="h3">
            Special Price :
          </Typography>
          <div className={classes.mrpcontainer}>
            <div className={classes.mrpandoffer}>
              <div className={classes.mrp}>
                <Typography variant="h3">
                  ₹{onlinevideoDetail?.selling_cost}
                </Typography>
              </div>
              <div className={classes.offerssection}>
                <div className={classes.offers}>
                  <Typography variant="caption">
                    MRP: <del>&#8377;{onlinevideoDetail?.mrp}</del>
                  </Typography>
                  <Typography variant="h6">
                    ({onlinevideoDetail?.discount_percent}% off)
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.tax}>
              <Typography variant="caption">Inclusive of all taxes.</Typography>
              {/* <Link to={"#"} className={classes.link}>
                <Typography variant="h6">Details</Typography>
              </Link> */}
            </div>
          </div>
        </div>

        <div className={classes.buttonscontainer}>
          <div className={classes.mobileyellowcustombtn}>
            {isLoggedIn && token ? (
              <Button
                variant="contained"
                className={classes.yellowcustombtn}
                onClick={handleAddToCart}
              >
                <CartBagIcon className={classes.cartbagicon} />
                Add To Cart
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.yellowcustombtn}
                onClick={handleloginOpenclose}
              >
                <CartBagIcon className={classes.cartbagicon} />
                Add To Cart
              </Button>
            )}
          </div>
          <div className={classes.mobilebluecustombtn}>
            {isLoggedIn && token ? (
              <Button
                onClick={handleOnlinePayment}
                variant="contained"
                className={classes.bluecustombtn}
              >
                Buy Now
              </Button>
            ) : (
              <Button
                className={classes.bluecustombtn}
                onClick={handleloginOpenclose}
              >
                Buy Now
              </Button>
            )}
            {/* <Button
              onClick={() => {
                navigate("/checkoutpage");
              }}
              variant="contained"
              className={classes.bluecustombtn}
            >
              Buy Now
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductDetails;
