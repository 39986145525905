import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import RectangleSection from "./rectanglesection";
import ManageSectionData from "./managesectiondata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "4.5rem",
    padding: "4rem 0",
    backgroundColor: "rgba(255, 249, 236, 1)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.5rem",
      padding: "2rem 0",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  headings: {
    display: "none",
    textAlign: "center",
    marginBottom: "2rem",
    fontFamily: "'Playfair Display', serif",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "3rem",
    },
  },
  subheadings: {
    fontFamily: "'Playfair Display', serif",
    fontWeight: "600",
  },
  rectanglesContainer: {
    width: "100%",
    marginTop: "3rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

function ManageSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Managing What Matters Most",
      headingmain: "What We Manage",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <SecHead Heading={Heading} className={classes.mainHeading} />
      <div className={classes.rectanglesContainer}>
        {ManageSectionData.map((item, index) => (
          <RectangleSection
            key={index}
            text={item.text}
            icon={<img src={item.image} alt={`Rectangle ${index + 1}`} />}
          />
        ))}
      </div>
    </div>
  );
}

export default ManageSection;
