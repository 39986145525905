import React from "react";
import { makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import FollowProcessCard from "./FollowProcessCard";
import FollowProcessData from "./FollowProcessData";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "2rem",
    marginBottom:"15rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "14rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  followprocesssection: {
    marginTop: "2rem",
    paddingTop: "2rem",
    background: "#FFF5DF",
  },
  followprocessmain: {
    padding: "2rem 0",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column',
      margin: '0 1rem',
    },
  },
}));
function FollowProcess() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Find about the process here",
      headingmain: "Follow This Process",
    },
  ];

  return (
    <div className={classes.followprocesssection}>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <SecHead Heading={Heading} />
          <div className={classes.followprocessmain}>
          {FollowProcessData.map((data, index) => (
            <FollowProcessCard data={data}/>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default FollowProcess;
