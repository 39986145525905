import {
  Button,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import gift from "../../image/dashboard/mywallet/gift.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "45%",
    height: "5.5rem",
    maxHeight: "10%",
    background: "#FFF",
    margin: "0.3rem",
    [theme.breakpoints.down("xs")]: {
      width: "99%",
      margin: "0.1rem",
    },
    "&.MuiCardContent-root": {
      padding: "0px !important",
    },
  },
  maindiv: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  head1: {
    color: "#242422",
    fontFamily: " Playfair Display",
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  head2: {
    color: "#6A6A6A",
    fontFamily: "Jost",
    fontSize: "0.525rem",
    fontWeight: 400,
  },
  btn: {
    color: "#215AEB",
    fontFamily: "Jost",
    fontSize: "0.55rem",
    fontWeight: 500,
  },
}));

const MyWalletCard = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.maindiv}>
            <div className={classes.sidediv1}>
              <img src={gift} alt="img" width="100%" />
            </div>
            <div className={classes.sidediv2}>
              <Typography className={classes.head1}>
                Buy now 2 books (vouchers)
              </Typography>
              <Typography className={classes.head2}>
                Ends on 31 Dec 2022
              </Typography>
              <Button className={classes.btn}>Claim Voucher</Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default MyWalletCard;
