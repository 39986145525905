import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  circularIcon: {
    color: "rgba(255, 252, 244, 1)",
    width: "40px",
    height: "35px",
    backgroundColor: "rgba(255, 57, 57, 1)",
    borderRadius: "100%",
  },
  horizontalLine: {
    borderTop: "1px solid rgba(234, 234, 234, 1)",
    margin: "20px 0",
  },
  textInRed: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    color: "rgba(255, 57, 57, 1)",
    marginLeft: "1rem",
  },
  solutionText: {
    display: "flex",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    color: "rgba(73, 73, 73, 1)",
    marginLeft: "1rem",
  },
  paragraphText: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "300",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 1)",
    padding: "0 13px",
    margin: "10px 0px",
    lineHeight: "24px",
  },
  topicContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
  },
  topicText: {
    padding: "0 13px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 1)",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
  },
  spanText: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "500",
    fontSize: "20px",
    color: "rgba(0, 0, 0, 1)",
  },
  wrongicon: {
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },
}));

function WrongAnswer({ question, selectedLanguage }) {
  const classes = useStyles();
  let correctOption = question?.options?.find((i) => i?.correct_option);

  // Array of alphabets
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
  ];
  const alphabetsHindi = [
    "अ",
    "ब",
    "सी",
    "डी",
    "इ",
    "ए",
    "एफ",
    "जी",
    "एच",
    "आई",
    "जे",
    "के",
  ];

  // Find the index of the correct option in the options array
  const correctOptionIndex = question?.options?.indexOf(correctOption);

  return (
    <Box>
      <div className={classes.circularBox}>
        <div className={classes.wrongicon}>
          <ClearIcon className={classes.circularIcon} />
          {selectedLanguage === "english" ? (
            <Typography variant="body1" className={classes.textInRed}>
              Oops! Wrong answer!
            </Typography>
          ) : (
            <Typography variant="body1" className={classes.textInRed}>
              आपका जवाब गलत है!
            </Typography>
          )}
        </div>
      </div>

      <Typography
        variant="body1"
        display="inline"
        className={classes.solutionText}
      >
        {/* <span className={classes.spanText}>Solution:</span> The correct option is ({alphabets[correctOptionIndex]}) */}
        {selectedLanguage === "english" ? (
          <Typography variant="body1" className={classes.solutionText}>
            The correct option is ({alphabets[correctOptionIndex]})
          </Typography>
        ) : (
          <Typography variant="body1" className={classes.solutionText}>
            सही विकल्प है ({alphabetsHindi[correctOptionIndex]})
          </Typography>
        )}
      </Typography>
      <Typography
        variant="body1"
        className={classes.paragraphText}
        dangerouslySetInnerHTML={{
          __html:
            selectedLanguage === "hindi"
              ? correctOption?.solution_hi
              : correctOption?.solution_en,
        }}
      ></Typography>
      {/* <Divider className={classes.horizontalLine} /> */}
      {/* <div className={classes.topicContainer}>
        <Typography variant="body1" className={classes.topicText}>
          Topic: -
        </Typography>
        <Typography variant="body1" className={classes.topicText}>
          Difficulty Level: {question?.hardness_level}
        </Typography>
      </div> */}
    </Box>
  );
}

export default WrongAnswer;
