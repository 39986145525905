import React, { useContext, useState } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./component/header/header";
import MobileHeader from "./component/header/mobileheader";
import SubHeader from "./component/subheader/subheader";
import Footer from "./component/footer/footer";
import SubHeaderMobile from "./component/subheader/subheadermobile";
import PracticeComponent from "./component/practicecomponent";
import Home from "./component/Home/home";
import CurrentAffairs from "./component/currentaffairs/currentaffairs";
import CancellationRefunds from "./component/cancellationrefunds/cancellationrefunds";
import ShippingPolicy from "./component/shippingpolicy/ShippingPolicy";
import TermsConditions from "./component/termsconditions/termsconditions";
import PrivacyPolicy from "./component/privacypolicy/privacypolicy";
import FAQ from "./component/FAQ/FAQ.js";
import AboutUs from "./component/aboutus/aboutus";
import ContactUs from "./component/contactus/contactus";
import Careers from "./component/careers/careers";
import ApplicationSubmission from "./component/careers/applicationsubmission";
import Coachings from "./component/coachings/coachings";
import Invester from "./component/invester/invester";
import Affiliates from "./component/affilates/affiliates";
import Mentor from "./component/mentor/mentor";
import Content from "./component/content/content";
import Biometric from "./component/biometric/biometric";
import OnlineAssessment from "./component/onlineassessment/onlineassessment";
import DigitalEvaluation from "./component/digitalevaluation/digitalevaluation";
import RPS from "./component/rps/rps";
import CoachingsPartner from "./component/coachingspartner/coachingspartner";
import OnlineSupport from "./component/onlinesupport/onlinesupport";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppBar, makeStyles } from "@material-ui/core";
import ScrollToTop from "./scrolltop";
import Seller from "./component/seller/seller";
import SinglePageNote from "./component/singlepagenote/singlepagenote";
import WishList from "./component/wishlist/wishlist";
import ShoppingCart from "./component/shoppingcart/ShoppingCart";
import ErrorPage from "./component/error/errorpage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpertTeachers from "./component/expertteachers/ExpertTeachers";
import FacultyProfile from "./component/facultyprofile/facultyprofile";
import ConstantPracticeData from "./component/practicecomponent/constant";
import Dashboard from "./component/dashboard/dashboard";
import NotificationSinglePage from "./component/notification/notiicationsinglepage";
import ExamSingleProductPage from "./component/singleexamproduct/examsingleproductpage.js";
import Notification from "./component/notification/notiication";

import Quizexam from "./component/practicecomponent/quizExam";
import SscQuiz from "./component/practicecomponent/quizResult";

import PreviousYearPapersExam from "./component/practicecomponent/previousyearpapersExam";
import PreviousYearPapersQuiz from "./component/practicecomponent/previousyearpapersResult";

import FreeMockSeries from "./component/practicecomponent/FreeMockExam";
import FreeMockSeriesResult from "./component/practicecomponent//FreeMockResult";

import SinglePageDescription from "./component/practicecomponent/singlepagedescription/book";
import CheckoutPage from "./component/CheckoutPage/CheckoutPage";
import SingleCheckoutPage from "./component/SingleCheckoutPage/SingleCheckoutPage.js";
import EBooksSinglePageDescription from "./component/practicecomponent/singlepagedescription/ebook";
import NotesSinglePageDescription from "./component/practicecomponent/singlepagedescription/notes";
import TestSeriesSinglePageDescription from "./component/practicecomponent/singlepagedescription/testseries";
import OnlineVideoSinglePageDescription from "./component/practicecomponent/singlepagedescription/onlinevideo";
import LiveClassesSinglePageDescription from "./component/practicecomponent/singlepagedescription/liveclasses";
import { useLocation } from "react-router-dom";
import MyPurchaseMoblieViewData from "./component/dashboard/MyPurchaseMobileViewData";
import CurrentBalance from "./component/dashboard/CurrentBalance";
import addCartContext from "./context/AddCartContext/AddCartContext";
import ApplicationForm from "./component/ApplicationForm/ApplicationForm";
import ApplyForExam from "./component/ApplicationForm/ApplyForExam";
import ApplicationFormStep from "./component/ApplicationForm/ApplicationFormStep";
import PersonalDetailPage from "./component/ApplicationForm/PersonalDetailPage";
import AdharDetails from "./component/ApplicationForm/AdharDetails";
import ConfirmationPage from "./component/ApplicationForm/ConfirmationPage";
import ForgotPassword from "./component/ApplicationForm/ForgotPassword";
import NeetAdmitCard from "./component/ApplicationForm/NeetAdmitCard";
import RegistrationPage from "./component/ApplicationForm/RegistrationPage";
import MarkingSchemeNeet from "./component/ApplicationForm/MarkingSchemeNeet";
import DocumentUpload from "./component/ApplicationForm/DocumentUpload";
import FullName from "./component/allpopups/FullName.js";
import PickCategory from "./component/allpopups/PickCategory.js";
import SscCard from "./component/CardForssc/SscCard.js";
import VideoPlayer from "./videoplayer.js";
import VideoPlayerNew from "./videoplayernew.js";
import CustomVideoPlayer from "./component/dashboard/videoCourse/CustomVideoPlayer.js";
import { getLearnerMenu } from "./service/services.js";
import { useEffect } from "react";
import appContext from "./context/AppContext";
import NotificationEmpy from "./component/notificationuser/notificationEmpy.js";
import { useNavigate } from "react-router-dom";
import OrderPlaced from "./component/allpopups/OrderPlaced.js";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1300",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1300",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const App = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { cartState, bookDetail } = useContext(addCartContext);
  const storedValue = localStorage.getItem("activeTab");
  const [learnerMenu, setLearnerMenu] = useState([]);
  const AppContext = useContext(appContext);
  const [dashboardId, setDashBoardId] = useState(
    storedValue ? parseInt(storedValue, 10) : 1
  );

  useEffect(() => {
    if (AppContext.isLoggedIn) {
      getLearnerMenu(AppContext.token)
        .then((res) => {
          setLearnerMenu(res?.data?.menu);
        })
        .catch((err) => {
          if (
            err?.response?.data?.message ===
            "Invalid or expired token. Please refresh your session."
          ) {
            navigate("/");
            window.location.reload();
            localStorage.clear();
          } else if (err?.response?.data?.message === "You are logged out. Please log in again.") {
                 console.log('object' , err?.response?.data?.message)
          } else if (err?.response?.data?.message) {
            console.log('object123' , err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
          }
        });
    }
  }, [AppContext.isLoggedIn, AppContext.token]);

  return (
    <>
      <AppBar
        className={classes.header}
        position="sticky"
        elevation={0}
        color="transparent"
      >
        {!location.pathname?.includes("quizzes/") &&
          !location.pathname?.includes("previousyearpapers/") &&
          !location.pathname?.includes("freemock/") && (
            <Header setDashBoardId={setDashBoardId} learnerMenu={learnerMenu} />
          )}
      </AppBar>
      {!location.pathname?.includes("quizzes/") &&
        !location.pathname?.includes("previousyearpapers/") &&
        !location.pathname?.includes("freemock/") && (
          <AppBar
            className={classes.mobileheader}
            position="sticky"
            elevation={0}
            color="transparent"
          >
            <MobileHeader setDashBoardId={setDashBoardId} />
          </AppBar>
        )}
      {!location.pathname?.includes("quizzes/") &&
        !location.pathname?.includes("previousyearpapers/") &&
        !location.pathname?.includes("freemock/") && <SubHeader/>}
      {!location.pathname?.includes("quizzes/") &&
        !location.pathname?.includes("previousyearpapers/") &&
        !location.pathname?.includes("freemock/") && <SubHeaderMobile />}
      <FullName />
      <PickCategory />
      <OrderPlaced />
      <Routes>
        <Route path="/" exact element={<Home />} />

        <Route
          path="/dashboard"
          element={
            <Dashboard
              learnerMenu={learnerMenu}
              value={dashboardId}
              setValue={setDashBoardId}
            />
          }
        />
        <Route
          path="/quizzes"
          element={
            <PracticeComponent
              practicepathname={ConstantPracticeData.quizzes.pathname}
            />
          }
        />
        <Route path="/drmvideo" element={<VideoPlayer />} />
        <Route path="/drmvideonew" element={<VideoPlayerNew />} />
        <Route
          path="/previousyearpapers"
          element={
            <PracticeComponent
              practicepathname={
                ConstantPracticeData.previousyearpapers.pathname
              }
            />
          }
        />
        <Route path="/currentaffairs" element={<CurrentAffairs />} />
        <Route
          path="/testseries"
          element={
            <PracticeComponent
              practicepathname={ConstantPracticeData.testseries.pathname}
            />
          }
        />
        <Route
          path="/ebooks"
          element={
            <PracticeComponent
              practicepathname={ConstantPracticeData.ebooks.pathname}
            />
          }
        />
        <Route
          path="/notes"
          element={
            <PracticeComponent
              practicepathname={ConstantPracticeData.notes.pathname}
            />
          }
        />
        <Route
          path="/books"
          element={
            <PracticeComponent
              practicepathname={ConstantPracticeData.books.pathname}
            />
          }
        />
        <Route
          path="/liveclasses"
          element={
            <PracticeComponent
              practicepathname={ConstantPracticeData.liveclasses.pathname}
            />
          }
        />
        <Route
          path="/onlinevideos"
          element={
            <PracticeComponent
              practicepathname={ConstantPracticeData.onlinevideos.pathname}
            />
          }
        />
        <Route path="/quizzes/:id" element={<Quizexam />} />
        <Route path="/quizzes/:id/result" element={<SscQuiz />} />
        <Route
          path="/previousyearpapers/:id"
          element={<PreviousYearPapersExam />}
        />
        <Route
          path="/previousyearpapers/:id/result"
          element={<PreviousYearPapersQuiz />}
        />
        <Route path="/freemock/:id" element={<FreeMockSeries />} />
        <Route path="/freemock/:id/result" element={<FreeMockSeriesResult />} />

        <Route path="/book/:id" element={<SinglePageDescription />} />
        <Route path="/ebook/:id" element={<EBooksSinglePageDescription />} />
        <Route path="/note/:id" element={<NotesSinglePageDescription />} />
        <Route
          path="/testseries/:id"
          element={<TestSeriesSinglePageDescription />}
        />
        <Route
          path="/onlinevideos/:id"
          element={<OnlineVideoSinglePageDescription />}
        />
        <Route
          path="/liveclasses/:id"
          element={<LiveClassesSinglePageDescription />}
        />
        <Route
          path="/singleexamproducts/:id"
          element={<ExamSingleProductPage />}
        />
        <Route
          path="/checkoutpage"
          element={
            cartState?.cart?.products.length > 0 ? (
              <CheckoutPage />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/singlecheckoutpage/:id/:type/:quantity"
          element={<SingleCheckoutPage />}
        />
        <Route path="/cancellationrefunds" element={<CancellationRefunds />} />
        <Route path="/shippingpolicy" element={<ShippingPolicy />} />
        <Route path="/termsconditions" element={<TermsConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/faq/:type/:id" element={<FAQ />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/careers" element={<Careers />} />
        <Route
          path="/applicationsubmission/:name/:id"
          element={<ApplicationSubmission />}
        />
        <Route path="/onlinesupport" element={<OnlineSupport />} />
        <Route path="/coachings" element={<Coachings />} />
        <Route path="/invester" element={<Invester />} />
        <Route path="/mentor" element={<Mentor />} />
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/content" element={<Content />} />
        <Route path="/seller" element={<Seller />} />
        <Route path="/biometric" element={<Biometric />} />
        <Route path="/onlineassessment" element={<OnlineAssessment />} />
        <Route path="/digitalevaluation" element={<DigitalEvaluation />} />
        <Route path="/rps" element={<RPS />} />
        <Route path="/coachingspartner" element={<CoachingsPartner />} />
        <Route path="/wishlist" element={<WishList />} />
        <Route path="/singlepagenote" element={<SinglePageNote />} />
        <Route path="/shoppingcart" element={<ShoppingCart />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/teachers" element={<ExpertTeachers />} />
        <Route path="/applicationform" element={<ApplicationForm />} />
        <Route path="/applyforexam" element={<ApplyForExam />} />
        <Route path="/applicationformstep" element={<ApplicationFormStep />} />
        <Route path="/personaldetailpage" element={<PersonalDetailPage />} />
        <Route path="/adhardetails" element={<AdharDetails />} />
        <Route path="/confirmationpage" element={<ConfirmationPage />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/neetadmincard" element={<NeetAdmitCard />} />
        <Route path="/registrationpage" element={<RegistrationPage />} />
        <Route path="/marklingscheme" element={<MarkingSchemeNeet />} />
        <Route path="/documentupload" element={<DocumentUpload />} />
        <Route path="/facultyprofile" element={<FacultyProfile />} />
        <Route path="/ssc" element={<SscCard />} />
        <Route path="/notification" element={<NotificationEmpy />} />
        <Route
          path="/notificationsinglepage/:a/:index"
          element={<NotificationSinglePage />}
        />
        <Route path="/notification/:a" element={<Notification />} />
        <Route
          path="/mypurchasemobiledata"
          element={<MyPurchaseMoblieViewData />}
        />
        <Route path="/currentbalance" element={<CurrentBalance />} />
        <Route path="/customvideo" element={<CustomVideoPlayer />} />
      </Routes>
      {!location.pathname?.includes("quizzes/") &&
        !location.pathname?.includes("previousyearpapers/") &&
        !location.pathname?.includes("freemock/") && <Footer />}
      <ScrollToTop />
    </>
  );
};
export default App;
