import BlockImage1 from "../image/rpsvector/block1.png";
import BlockImage2 from "../image/rpsvector/block2.png";
import BlockImage3 from "../image/rpsvector/block3.png";
import BlockImage4 from "../image/rpsvector/block4.png";
import BlockImage5 from "../image/rpsvector/block5.png";
import BlockImage6 from "../image/rpsvector/block6.png";
import BlockImage7 from "../image/rpsvector/block7.png";
import BlockImage8 from "../image/rpsvector/block8.png";
import BlockImage9 from "../image/rpsvector/block9.png";

const KeyFunctionSectionData = [
    {
      id: 1,
      iconName: BlockImage1,
      heading: "Incorporating Ordinances for Result",
      subheading:
        "Implementing Effective Ordinances and Protocols to Ensure Accurate and Timely Result Processing ",
    },
    {
      id: 2,
      iconName: BlockImage2,
      heading: "Gracing Management with Panel",
      subheading:
        "Elevating Management Strategies with the Expertise of a Distinguished Advisory panel. ",
    },
    {
      id: 3,
      iconName: BlockImage3,
      heading: "Upload / Enter Subject Marks",
      subheading:
        "Seamlessly Record and Manage Subject Marks. Simplifying the Upload and Data Entry Process ",
    },
    {
      id: 4,
      iconName: BlockImage2,
      heading: "With-Held Cases Management",
      subheading:
        "Efficiently Handling and Resolving of With-Held Result with Our Comprehensive Management System ",
    },
    {
      id: 5,
      iconName: BlockImage4,
      heading: "Ranker Analysis (Class/Subject)",
      subheading:
        "Insightful Analysis of Class and Ranking: Unlocking Data-Driven strategies and Management",
    },
    {
      id: 6,
      iconName: BlockImage5,
      heading: "Managing Unfair Means cases",
      subheading:
        "Effectively Addressing and Resolving Cases of Academic Dishonesty: Our Comprehensive Unfair Means Management system ",
    },
    {
      id: 7,
      iconName: BlockImage6,
      heading: "Re-Assessment Management",
      subheading:
        "Streamlining and Enhancing the Re-Assessment Process: Your Solution for Effective & Transparent Academic Evaluation. ",
    },
    {
      id: 8,
      iconName: BlockImage7,
      heading: "Mark-sheet/Bulletin Printing",
      subheading:
        "Precision Printing: Crafting Flawless Mark-sheets and Bulletins for Academic Excellence",
    },
    {
      id: 9,
      iconName: BlockImage8,
      heading: "Online Provisional Mark-sheet",
      subheading:
        "Instant Access: Secure Your Online Provisional Mark-sheet Anytime,Anywhere, Effortlessly ",
    },
    {
      id: 10,
      iconName: BlockImage1,
      heading: "Degree Certificate Management",
      subheading:
        "Efficiently Handling Degree Certificate Management for Seamless Academic records and Graduation Processes.",
    },
    {
      id: 11,
      iconName: BlockImage8,
      heading: "Secure and confidential result handling.",
      subheading:
        "Ensuring Security and Confidentiality in the Handling of Examination Results.",
    },
    {
      id: 12,
      iconName: BlockImage9,
      heading: "Real-time analytics & insights on exam performance",
      subheading:
        "Instant Insights: Real-time Analytics for In-Depth Examination Performance Assessment and Improvement.",
    },
  ];

  export default KeyFunctionSectionData;