const CardSectionData = [
  {
    type: "LIVE TEST",
    title: "All India Mock:",
    subtitle: "SBI Apprentice Pre 17-Sep- 2023",
    category: "Banking",
    endsIn: "Ends In:",
    timer: ["05", "12", "30", "10"],
  },
];

export default CardSectionData;
