import {
  Button,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { TfiEmail } from "react-icons/tfi";
import React from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Subscriber } from "../../service/services";
const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    position: "absolute",
    top: "-159px",
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      width: "100%",
      marginBottom: "1rem",
      fontSize: "1.3rem",
    },
    "& .MuiFormControl-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiTypography-h3": {
      fontSize: "2.75rem",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "700",
      color: "#252525",
      "& span": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h3": {
        fontSize: "2rem",
      },
      "& .MuiTypography-h5": {
        fontSize: "1rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      top: "-165px",
      padding: "0rem 1rem",
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiTypography-h3": {
        fontSize: "1.3rem",
      },
      "& .MuiTypography-h5": {
        fontSize: "0.8rem",
      },
    },
    "& .MuiButton-text": {
      padding: "10px 14px",
    },
  },
  container: {
    maxWidth: "1250px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    background: "#FFE8B5",
    color: "#212121",
    borderRadius: "8px",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  footercardmain: {
    display: "flex",
    justifyContent: "space-between",
    padding: "3.3rem 2rem",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "1rem 0rem",
      alignItems: "center",
      textAlign: "center",
    },
  },
  footercardcontent: {
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  footercardbutton: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginTop: "1rem",
      alignItems: "center",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  bluecustombtnmobile: {
    width: "100%",
    marginTop: "1rem",
    padding: "1rem 2rem",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  msgicon: {
    marginBottom: "0.2rem",
  },
}));

function FooterCardMain() {
  const location = useLocation();
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const handleSubscribe = async () => {
    try {
      const formData = {
        subscriber: {
          email: email,
        },
      };

      const response = await Subscriber(formData);
      console.log("API Response:", response);
      toast.success("Subscription successful!");
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  let handlechange = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
    console.log(email);
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          {location.pathname !== "/dashboard" &&
          !location.pathname?.includes("quizzes/") ? (
            <div className={classes.footercardmain}>
              <div className={classes.footercardcontent}>
                <Typography variant="h3">
                  <span>Subscribe</span> to get upcoming notifications
                </Typography>
              </div>
              <div className={classes.footercardbutton}>
                <Typography variant="h5">
                  Stay Up To Date With Our News And Upcoming Exams Notification
                </Typography>

                <FormControl sx={{ m: 1, width: "25ch" }}>
                  <TextField
                    id="email"
                    type="text"
                    placeholder="Enter Your Mail"
                    name={email}
                    onChange={(e) => handlechange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          className={classes.msgicon}
                          position="start"
                        >
                          <TfiEmail />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={handleSubscribe}
                            className={classes.bluecustombtn}
                          >
                            Subscribe
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button
                  onClick={handleSubscribe}
                  className={classes.bluecustombtnmobile}
                >
                  Subscribe
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
export default FooterCardMain;
