import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import signatures from "../image/applicationform/signatures.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20rem",
    padding: "2rem",
    "& .MuiTypography-h1": {
      fontSize: "1.4rem",
      fontWeight: 400,
    },
  },
  headercontainer: {
    margin: "2rem",

    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  centerhead: {
    fontSize: "1.2rem",
    fontWeight: "600",
    width: "30%",
    textAlign: "center",
  },
  formcontainer: {
    display: "flex",
    "& .MuiTypography-h1": {
      fontSize: "1rem",
      fontWeight: "600",
      textAlign: "center",
      width: "100%",
      border: "1px solid  #000000",
      background: "#c0c0c0",
      padding: "0.2rem",
    },
  },
  formfield: {
    border: "1px solid  #000000",
    width: "19%",
    fontSize: "1rem",
    fontWeight: "600",
  },
  formfield1: {
    border: "1px solid  #000000",
    width: "81%",
    fontSize: "1rem",
    fontWeight: "600",
  },
  innercontainer: {
    border: "0.2rem solid #959595",
    marginTop: "2rem",
    "& .MuiTypography-h5": {
      fontSize: "1rem",
      fontWeight: "600",
      textAlign:"center",
      color:"#ff0000",
      marginBottom:"1rem",
    },
  },
  mainfromcontainer: {
    border: "1px solid  #000000",
    width: "95%",
    margin: "auto",
  },
  centerformcontainer: {
    width: "38%",
    border: "1px solid  #000000",
    padding: "3rem 0rem",
    fontSize: "1rem",
    fontWeight: "600",
  },
  imagestyle: {
    marginLeft: "32rem",
    padding: "1rem",
  },
}));

const NeetAdmitCard = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Typography variant="h1">
          Here is a template of the previous year NEET UG Admit Card:
        </Typography>
        <div className={classes.innercontainer}>
          <div className={classes.headercontainer}>
            <div></div>
            <div className={classes.centerhead}>
              National Eligibility Cum Entrance Test (UG)-2022 Admit
              Card-Provisional
            </div>
            <div></div>
          </div>
          <div className={classes.mainfromcontainer}>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}> Roll No.</div>
              <div className={classes.formfield}></div>
              <div className={classes.formfield}>Application No</div>
              <div className={classes.formfield}></div>
              <div>Photograph</div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>Candidate's Name</div>
              <div className={classes.formfield}></div>
              <div className={classes.formfield}>Father's Name</div>
              <div className={classes.formfield}></div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>Gender</div>
              <div className={classes.formfield}>Female</div>
              <div className={classes.formfield}>Date of Birth</div>
              <div className={classes.formfield}></div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>Category</div>
              <div className={classes.formfield}>SC</div>
              <div className={classes.formfield}>State of Bligibility</div>
              <div className={classes.formfield}>DELH (NCT)</div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>
                Person with Disability (PWD)
              </div>
              <div className={classes.formfield}>No</div>
              <div className={classes.formfield}>Scribe required</div>
              <div className={classes.formfield}></div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.centerformcontainer}></div>
              <div className={classes.centerformcontainer}>
                Candidate's Signature
              </div>
            </div>
            <div className={classes.formcontainer}>
              <Typography variant="h1">Test Details</Typography>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>Question Paper Medium</div>
              <div className={classes.formfield1}>English</div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>Date of Examination</div>
              <div className={classes.formfield1}>17.07.2022 (SUNDAY)</div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>
                Reporting/Entry Time at Centre
              </div>
              <div className={classes.formfield1}>
                12.20PM (NDIAN STANDARD TIME)
              </div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>
                Gate Closing Time of Centre
              </div>
              <div className={classes.formfield1}>
                01.30PM (NDAN STANDARD TIME)
              </div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>Test Centre No</div>
              <div className={classes.formfield1}>200130</div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>Test Centre Name</div>
              <div className={classes.formfield1}>MOUNT ABU PUBLIC SCHOOL</div>
            </div>
            <div className={classes.formcontainer}>
              <div className={classes.formfield}>
                Test Centre Address (Venue of Test)
              </div>
              <div className={classes.formfield1}></div>
            </div>
            <div className={classes.formcontainer}>
              <img src={signatures} alt="img" className={classes.imagestyle} />
            </div>
          </div>
          <Typography variant="h5">SELF DECLARATION (UNDERTAKING)</Typography>
        </div>
      </div>
    </>
  );
};

export default NeetAdmitCard;
