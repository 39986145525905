import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import footerlistIcon from "../image/footerimages/footerlistIcon.svg";
import companyList from "./footercompanylist";
import footerproductlist from "./footerproductlist";
import footerpartnerlist from "./footerpartnerslist";

import QuickLinkList from "./quicklinklist";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  footerlistsectionmain: {
    display: "flex",
    justifyContent: "space-around",
    width: "65%",
    "& .MuiTypography-body1": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      "& hr": {
        display: "none",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  footerheadhr: {
    margin: "0",
    marginTop: "10px",
    width: "38px",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "2px",
  },
  footercompanysectionlist: {
    cursor: "pointer",
    "& :hover": {
      color: "#FFC736",
      textDecoration: "none",
    },
  },
  footerMocksectionlist: {
    cursor: "pointer",
    "& :hover": {
      color: "#FFC736",
      textDecoration: "none",
    },
  },
  footerQuickLinkssectionlist: {
    cursor: "pointer",
    "& :hover": {
      color: "#FFC736",
      textDecoration: "none",
    },
  },
  footerproductsectionlist: {
    cursor: "pointer",
    "& :hover": {
      color: "#FFC736",
      textDecoration: "none",
    },
  },
  link: {
    color: "white",
    textDecoration:'none',
  },
}));

const FooterListSection = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerlistsectionmain}>
      <div className={classes.footercompanysection}>
        <h2>
          Company :- <hr className={classes.footerheadhr} />
        </h2>
        <List className={classes.footercompanysectionlist}>
          {companyList.map((data) => (
            <Link to={data.to} className={classes.link}  animate={true} >
              <ListItem>
                <ListItemIcon>
                  <img src={footerlistIcon} alt="img" />
                </ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
      <div className={classes.footerproductsection}>
        <h2>
          Products :- <hr className={classes.footerheadhr} />
        </h2>
        <List className={classes.footerproductsectionlist}>
          {footerproductlist.map((data) => (
            <Link to={data.to} className={classes.link}>
              <ListItem align="center">
                <ListItemIcon>
                  <img src={footerlistIcon} alt="img" />
                </ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
      <div className={classes.footerQuickLinkssection}>
        <h2>
          Quick Links :-
          <hr className={classes.footerheadhr} />
        </h2>
        <List className={classes.footerQuickLinkssectionlist}>
          {QuickLinkList.map((data) => (
            <Link to={data.to}  className={classes.link}>
              <ListItem>
                <ListItemIcon>
                  <img src={footerlistIcon} alt="img" />
                </ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
      <div className={classes.footerMocksection}>
        <h2>
        Collaborate :-<hr className={classes.footerheadhr} />
        </h2>
        <List className={classes.footerMocksectionlist}>
          {footerpartnerlist.map((data) => (
            <Link to={data.to} className={classes.link}>
              <ListItem>
                <ListItemIcon>
                  <img src={footerlistIcon} alt="img" />
                </ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    </div>
  );
};
export default FooterListSection;
