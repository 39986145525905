import { useEffect, useReducer, useState } from 'react';
import CategoryReducer from './CategoryReducer';
import CategoryContext from "./CategoryContext";
import { SET_MEGA_MENU } from './CategoryType';
import { getMegaMenuData } from '../../service/services';

const CategoryState = (props) => {

    let initialState = {
        categoryList: null,
        megaMenu: null
    };
    const [state, dispatch] = useReducer(CategoryReducer, initialState);

    useEffect(() => {
        getMegaMenuDataAPI();
    }, [])

    const getMegaMenuDataAPI = async () => {
        let resp = await getMegaMenuData();
        if (resp?.status === 200) dispatch({ type: SET_MEGA_MENU, payload: [{ categories: resp?.data?.categories }] });
    };
    const [storedId, setStoredId] = useState(null);

  const storeId = (id) => {
    setStoredId(id);
  };


    return (
        <CategoryContext.Provider
            value={{
                categoryList: state.categoryList,
                megaMenu: state.megaMenu,
                getMegaMenuDataAPI,
                storedId, storeId
            }}
        >
            {props.children}
        </CategoryContext.Provider>
    )
}
export default CategoryState;
