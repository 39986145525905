import { makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import SecHead from "../sectionHeading/sectionheading";
import PopularSectionContent from "./popularexamcontent";
import SearchInput from "./SearchInput";
import categoryContext from "../../../context/CategoryContext/CategoryContext";

const useStyles = makeStyles((theme) => ({
  popularexammain: {
    // background: '#FFF7E4',
    margin: "8rem 0  0rem 0rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0rem",
    },
  },
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding: "2rem 0 3rem 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 0.9rem 0px",
    },
  },
}));
function PopularExamSection() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore By Exams",
      headingmain: "Most Popular Exams",
    },
  ];
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");
  const CategoryContext = useContext(categoryContext);
  const allExams = CategoryContext?.megaMenu?.[0]?.["categories"]?.reduce(
    (exams, category) => {
      category.subCategories.forEach((subCategory) => {
        subCategory.exams.forEach((exam) => {
          exams.push({
            category_id: category.id,
            category_name: category.category_name,
            subCategory_id: subCategory.id,
            subCategory_name: subCategory.sub_category_name,
            ...exam,
          });
        });
      });
      return exams;
    },
    []
  );
  const [searchQuery, setSearchQuery] = useState("");
  // const filteredExams = allExams.filter((exam) =>
  //   exam.exam_name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  return (
    <>
      <div className={classes.popularexammain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <SecHead Heading={Heading} media={mql.matches} />
            <SearchInput allExams={allExams} searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
            <PopularSectionContent searchQuery={searchQuery}/>
          </div>
        </div>
      </div>
    </>
  );
}
export default PopularExamSection;
