import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import circle from "../image/notification/circle.png";
import cyran from "../image/notification/cyran.png";
import mask from "../image/notification/mask.png";

const useStyles = makeStyles((theme) => ({
  containerFluid: {
    width: "99%",
    position: "relative",
    background: "#141E30",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
        maxWidth: "95%",
      },
  },
  containerFluidTwo: {
    width: "99%",
    position: "relative",
    background: "#FFFFFF",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  divtypethree: {
    width: "99%",
    position: "relative",
    background: "#FFFFFF",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  cardTypo: {
    color: "white",
  },
  carddiv: {
    padding: "20px",
    display: "flex",
  },
  carddivThree: {
    display: "flex",
    gap: "233px",
    padding: "30px",
  },
  head2: {
    fontSize: "13px",
    fontWeight: "lighter",
  },
  head3: {
    color: "#4A84C4",
    fontSize: "25px",
  },
  head4: {
    color: "#000",
  },
  head6: {
    color: "#FF8008",
    fontSize: "25px",
  },
  dob: {
    fontWeight: "300",
    color: "#ABABAB",
  },
  lastdiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
}));

const CardNotification = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.containerFluid}>
        <div className={classes.carddiv}>
          <div>
            <img src={circle} alt="img" width="60%" />
          </div>
          <div className={classes.cardTypo}>
            <Typography>We Released Some New Feature</Typography>
            <Typography className={classes.head2}>Check Them Out</Typography>
          </div>
        </div>
      </div>
      <br />
      <div className={classes.containerFluidTwo}>
        <div className={classes.carddiv}>
          <div>
            <img src={cyran} alt="img" width="60%" />
          </div>
          <div className={classes.lastdiv}>
            <div>
              <Typography className={classes.head3}>Announcment</Typography>
              <Typography className={classes.head4}>
                Issac Morgan Posted An Announcment "Review Lecture On The 22nd{" "}
                <br />
                February" On Your Course Railway Clearnes Test
              </Typography>
            </div>
            <div className={classes.dob}>19th Aug 2020</div>
          </div>
        </div>
      </div>
      <br />
      <div className={classes.divtypethree}>
        <div className={classes.carddivThree}>
          <div>
            <Typography className={classes.head6}>
              Buy More To
              <br /> Earn More
            </Typography>
            <Typography className={classes.head4}>
              Get Access To Unlimited Test Services With Our Basic
              <br /> LMS Plan
            </Typography>
          </div>
          <div>
            <img src={mask} alt="img" width="60%" />
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default CardNotification;
