import React from "react";
import { ReactComponent as SidebarHeadIcon } from "../image/quizzesvector/sidebarheadicon.svg";
import { Checkbox, makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  examscontainer: {
    width: "100%",
    // height: "421px",
    height: "auto",
    '& .MuiTypography-body2': {
      textTransform: 'capitalize',
  },
  },
  examshead: {
    display: "flex",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    padding: "0.4rem 1rem",
    borderRadius: "0.3rem",
    color: "white",
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display",
      color: "#FFFFFF",
      fontSize: "1.125rem",
      marginLeft: "0.5rem",
    },
  },
  examslist: {
    background: "linear-gradient(270deg, #FFF4D7 0%, #FEE5CD 100%)",
    display: "inline-block",
    width: "inherit",
    height: "inherit",
    overflow: "auto",
    borderRadius: "0.3rem",
    padding: "0.5rem 0",
    // lineHeight: "50px",
    "& .MuiListItemIcon-root": {
      // paddingLeft: "2.5rem",
      minWidth: "0",
    },
    "& .MuiTypography-body1": {
      textTransform: "capitalize",
      fontSize: "0.875rem",
    },
    "& .Mui-selected": {
      background: "rgba(255, 255, 255, 1)",
      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
    },
    "& .MuiList-padding": {
      padding: "0rem 1rem",
    },
    "& .MuiListItem-root": {},
  },
}));

const ProductList = ({ setChecked, checked = [] , checkedinitial }) => {
  const classes = useStyles();
  const handleToggle = (value) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <div className={classes.examscontainer}>
        <div className={classes.examshead}>
          <div>
            <SidebarHeadIcon />
          </div>
          <Typography variant="h6">Product Category</Typography>
        </div>
        <div className={classes.examslist}>
          <List className={classes.root}>
            {checkedinitial?.map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked?.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    </>
  );
};

export default ProductList;
