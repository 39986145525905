import { Typography, IconButton, Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import downloadicon from "../../image/dashboard/videocourse/downloadicon.png";
import SeiesForCard from "./seiesForCard";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { getOnlineVideoSingalCourse } from "../../../service/services";
import "video-react/dist/video-react.css";
import CustomVideoPlayer from "./CustomVideoPlayer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFF",
    padding: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  heading: {
    display: "flex",
    "& .MuiTypography-h6": {
      color: "#262626",
      fontFamily: "Playfair Display",
      fontSize: "1.6875rem",
      fontStyle: "normal",
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  heading2: {
    color: "#979797",
    fontFamily: "Jost",
    fontSize: "1.1875rem",
    fontWeight: 400,
    marginLeft: "1.1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      marginLeft: "0",
    },
  },
  headdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headdiv1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  attempted: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.4rem 0.8rem",
    },
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#323232",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
  },
  centercontainer: {
    display: "flex",
    marginTop: "1rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    "& .MuiTypography-h6": {
      color: "#000",
      fontFamily: "Plus Jakarta Sans",
      fontSize: " 1rem",
      fontWeight: 700,
    },
    "& .MuiTypography-h5": {
      color: "#A9A9A9",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "0.8125rem",
      fontWeight: 700,
    },
  },
  centerfirstdiv: {
    width: "67%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  centerseconddiv: {
    width: "30%",
    maxHeight: "63vh",
    overflowY: "scroll",
    padding: "1rem",
    border: "1px solid lightgrey",
    borderRadius: "10px",
    boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06) !important",

    "&::-webkit-scrollbar": {
      width: "5px",
      display: "block !important",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "5px",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "rgb(36, 59, 85)",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#39476B",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  head3: {
    color: "#A9A9A9",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "0.8125rem",
    fontWeight: 400,
    // width: "75%",
  },
  lecture: {
    color: " #1D2026",
    fontFamily: "Playfair Display",
    fontSize: "1.75rem",
    fontWeight: 600,
  },
  longhead: {
    color: " var(--Gray-700, #4E5566)",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.375rem",
    letterSpacing: " -0.00875rem",
    marginTop: "0.5rem",
  },
  longhead: {
    color: "var(--Gray-900, #1D2026)",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "-0.00875rem",
    marginTop: "0.5rem",
  },
  longhead2: {
    color: "var(--Gray-900, #1D2026)",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.25rem",
    letterSpacing: "-0.00875rem",
    marginTop: "0.7rem",
  },
  viewbtn: {
    border: "1px solid #A5A5A5",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0.4rem 0.8rem",
    },
    "& .MuiButton-label": {
      fontFamily: "Playfair Display !important",
      fontSize: "0.875rem",
      fontWeight: 300,
      color: "#A5A5A5",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
  },
}));

const SeriesForUpsc = (props) => {
  const classes = useStyles();
  const id = props.data.id;
  const type = props.data.type;

  const [data, setData] = useState([]);
  const [videoData, setVideoData] = useState([]);

  const [detail, setDetails] = useState({});
  const [url, setUrl] = useState("");

  console.log("url>>", url);
  console.log("videoData", videoData);

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getOnlineVideoSingalCourse(token, id, type);
        console.log("API Response:", response.data);
        const fetchedData = response.data?.online_Videos?.online_video_lectures;
        setVideoData(fetchedData);
        const firstVideoUrl = fetchedData[0]?.lecture_url
          ? fetchedData[0]?.lecture_url
          : fetchedData[0]?.link;
        setUrl(firstVideoUrl);
        setDetails(response.data?.online_Videos);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const setOnViewClicked = props.setOnViewClicked;

  const handleVideoClick = (videoUrl) => {
    setUrl(videoUrl);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headdiv}>
          <div className={classes.heading}>
            <IconButton
              onClick={() => {
                setOnViewClicked("");
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <div>
              <Typography variant="h6">{detail?.video_name}</Typography>
            </div>
          </div>
          <div className={classes.attempted}>
            <Typography variant="h6">
              Completed : (0/{videoData.length})
            </Typography>
          </div>
        </div>
        <div className={classes.centercontainer}>
          <div className={classes.centerfirstdiv}>
            <CustomVideoPlayer url={url} />
          </div>
          <div className={classes.centerseconddiv}>
            <SeiesForCard
              handleVideoClick={handleVideoClick}
              Data={videoData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SeriesForUpsc;
