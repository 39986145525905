import React from "react";
import { Typography, makeStyles, Container, Box } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import SolutionImage from "../image/onlineassessments/solutions.png";
import StarIcon from "@material-ui/icons/Star";
import { verticalLines, verticalLinesRight } from "./solutionsectiondata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      marginTop: "1rem",
    },
  },
  onlineeducationcontent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 1rem",
      textAlign: "center",
    },
  },
  onlineeducationimgcontainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "2rem 1rem",
    },
  },
  onlineeducationcontentinner: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
  },
  verticalLinesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "37px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  verticalLine: {
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: "22px",
    color: "rgba(0, 0, 0, 1)",
    lineHeight: "31.79px",
    marginBottom: "1.5rem",
  },
  word: {
    fontFamily: "jost",
    fontSize: "22px",
    fontWeight: "400",
    color: "rgba(0,0,1)",
  },
  verticalLinesContainerRight: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "19rem",
    marginTop: "-21.5rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
      marginTop: "0",
    },
  },
  verticalLineRight: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.5rem",
    whiteSpace: "nowrap",
  },
  star: {
    fontSize: "1rem",
    marginRight: "0.2rem",
    color: "rgba(255, 179, 45, 1)",
  },
  MuiTypographybody2: {
    marginTop: "0.5rem",
    fontFamily: "Jost",
    color: "rgba(112, 112, 112, 1)",
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: "400",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
  MuiTypographybody1: {
    fontFamily: "Playfair Display",
    color: "rgba(34, 56, 82, 1)",
    lineHeight: "47.99px",
    fontSize: "36px",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "26px",
      color: "rgba(34, 56, 82, 1)",
    },
  },
  MuiTypographybody3: {
    fontFamily: "Playfair Display",
    color: "#000",
    fontSize: "1.5rem",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "23px",
      color: "rgba(34, 56, 82, 1)",
    },
  },
}));

const SolutionSection = () => {
  const classes = useStyles();

  const Heading = [
    {
      id: 1,
      headingsmall: "From Start to Finish, Everything You need Included",
      headingmain: "End to End Total Solutions Includes",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <Container className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.MuiTypographybody1}
              >
                Total Solutions Encompassing Every Aspect You Require, Start to
                Finish
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.MuiTypographybody2}
              >
                From initial consultation to ongoing support, our Comprehensive
                solution covers every aspect of your need
              </Typography>
              <div className={classes.verticalLinesContainer}>
                {verticalLines.map((line, index) => (
                  <Box key={index} className={classes.verticalLine}>
                     <StarIcon className={classes.star} />
                    <Typography variant="body1" className={classes.word}>
                      {line}
                    </Typography>
                  </Box>
                ))}
              </div>
              <div className={classes.verticalLinesContainerRight}>
                {verticalLinesRight.map((line, index) => (
                  <Box key={index} className={classes.verticalLineRight}>
                    <StarIcon className={classes.star} />
                    <Typography variant="body1" className={classes.word}>
                      {line}
                    </Typography>
                  </Box>
                ))}
              </div>
            </div>
          </div>

          <div className={classes.onlineeducationimgcontainer}>
            <img src={SolutionImage} alt="img" width="60%" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SolutionSection;
