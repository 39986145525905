import { FaAngleDown } from "react-icons/fa";
import CurrentAffairsIcon from "../image/header/CurrentAffairs.gif";
import EbooksIcon from "../image/header/Ebooks.gif";
import NotesIcon from "../image/header/Notes.gif";
import PreviousYearPaperIcon from "../image/header/PreviousYearPaper.gif";
import QuizzesIcon from "../image/header/Quizzes.gif";
import CurrentAffairsStaticIcon from "../image/header/CurrentAffairs.png";
import EbooksStaticIcon from "../image/header/Ebooks.png";
import NotesStaticIcon from "../image/header/Notes.png";
import PreviousYearPaperStaticIcon from "../image/header/PreviousYearPaper.png";
import QuizzesStaticIcon from "../image/header/Quizzes.png";

const Heading = [
  {
    id: 1,
    name: "Practice",
    type: "dropdown",
    icon: <FaAngleDown />,
    style: "link",
    to: "/testseries",
    menu: [
      {
        name: "Quizzes",
        to: "/quizzes",
        icon: QuizzesIcon,
        staticicon: QuizzesStaticIcon,
      },
      {
        name: "Previous Year Papers",
        to: "/previousyearpapers",
        icon: PreviousYearPaperIcon,
        staticicon: PreviousYearPaperStaticIcon,
      },
      // {
      //   name: "Current Affairs",
      //   to: "/currentaffairs",
      //   icon: CurrentAffairsIcon,
      //   staticicon: CurrentAffairsStaticIcon,
      // },
    ],
  },
  {
    id: 2,
    name: "Test Series",
    type: "link",
    style: "link",
    to: "/testseries",
    // to: "/wishlist",
    // to: "/singlepagedescription",
    // to: "/shoppingcart",
  },
  {
    id: 3,
    name: "Study Materials",
    type: "dropdown",
    icon: <FaAngleDown />,
    style: "link",
    menu: [
      {
        name: "E-Books",
        to: "/ebooks",
        icon: NotesIcon,
        staticicon: NotesStaticIcon,
      },
      {
        name: "Notes",
        to: "/notes",
        icon: EbooksIcon,
        staticicon: EbooksStaticIcon,
      },
    ],
  },
  {
    id: 4,
    name: "Books",
    type: "link",
    style: "link",
    to: "/books",
  },
  // {
  //   id: 6,
  //   name: "Online videos",
  //   type: "link",
  //   style: "link",
  //   to: "/onlinevideos",
  // },
  {
    id: 5,
    name: "Classes",
    type: "dropdown",
    icon: <FaAngleDown />,
    style: "link",
    menu: [
      {
        name: "Live Classes",
        to: "/liveclasses",
        icon: NotesIcon,
        staticicon: NotesStaticIcon,
      },
      {
        icon: EbooksIcon,
        staticicon: EbooksStaticIcon,
        name: "Online videos",
        type: "link",
        style: "link",
        to: "/onlinevideos",
      },
    ],
  },
];
export default Heading;
