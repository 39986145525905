import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Person from "../../image/bannercardvector/person.png";
import CaracterGif from "../../Videos/Character.webm";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    position: "absolute",
    bottom: "-120px",

    "& .MuiTypography-h4": {
      fontFamily: "'Playfair Display', serif !important",
      marginBottom: "1rem",
      fontSize: "28px !important",
      fontWeight: "700 !important",
    },
    "& .MuiTypography-body2": {
      color: "#9E9E9E",
      fontSize: "0.8rem",
    },
    "& .MuiTypography-subtitle2": {
      color: "#9E9E9E",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "-250px",
      "& .MuiTypography-h4": {
        width: "50%",
        margin: "0 auto",
      },
      "& .MuiTypography-body2": {
        width: "65%",
        margin: "0 auto",
        marginTop: "0.5rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      bottom: "-250px",
      padding: "0rem 1rem",
      "& .MuiTypography-h4": {
        width: "80%",
        margin: "0 auto",
        fontSize: "1.25rem",
      },
      "& .MuiTypography-body2": {
        width: "100%",
        margin: "0 auto",
        marginTop: "0.5rem",
        fontSize: "1",
      },
      "& .MuiTypography-subtitle2": {
        marginTop: "1rem",
      },
    },
  },
  container: {
    borderRadius: "8px",
    maxWidth: "1250px",
    margin: "0 auto",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
  },
  bannercardmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  bannercardimg: {
    width: "28%",
    scale: "1.3",
    display: "flex",
    bottom: "7px",
    position: "relative",
    justifyContent: "center",
    transform: "translate(0px, -17px)",
    "& video::-webkit-media-controls": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-enclosure": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-panel": {
      display: "none !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  bannercardcontent: {
    width: "45%",
    "& MuiTypography-h4": {
      fontSize: "24px !important",
      fontFamily: "Playfair Display !important",
      fontWeight: "700 !important",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      textAlign: "center",
      marginTop: "1rem",
    },
  },
  bannercardbutton: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "80%",
      marginTop: "1rem",
      marginBottom: "2rem",
    },
  },
  orangecustombtn: {
    background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    color: "black",
    padding: "13px 50px",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#252525",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  bannercardtextfordesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  bannercardtextformobile: {
    fontFamily: "Jost !important",
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },

  MuiSvgIcon_root: {
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
}));
function BannerCard() {
  const navigate = useNavigate();
  const classes = useStyles();
  const videoRef = useRef(null);

  const [iphoneDetect, setIphoneDetect] = useState(false);

  useEffect(() => {
    var userAgent = navigator.userAgent;

    if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setIphoneDetect(true);
    }
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    };

    const playVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.play().catch((error) => {
          console.error("Error playing video:", error.message);
        });
      }
    };

    const pauseVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.pause();
      }
    };

    const videoObserver = new IntersectionObserver(handleIntersection, options);

    const video = videoRef.current;
    if (video) {
      videoObserver.observe(video);
    }

    return () => {
      if (video) {
        videoObserver.unobserve(video);
      }
    };
  }, []);
  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div className={classes.bannercardmain}>
            <div className={classes.bannercardimg}>
              {iphoneDetect ? (
                <img src={Person} alt="img" width="50%" />
              ) : (
                <video
                  ref={videoRef}
                  className={classes.MuiSvgIcon_root}
                  autoPlay={true}
                  loop
                  muted
                  playsInline
                  controls={false}
                >
                  <source src={CaracterGif} type="video/webm" />
                </video>
              )}
            </div>
            <div className={classes.bannercardcontent}>
              <Typography variant="h4">
                Be Exam Ready with ParikshaDo.....
              </Typography>

              <Typography
                className={classes.bannercardtextfordesktop}
                variant="body2"
              >
                ParikshaDo is a cutting-edge online platform dedicated to
                providing students with comprehensive mock tests and practice
                materials to enhance their academic performance and exam
                readiness.
                {/* Our platform is designed to empower students by
                offering a wide range of mock tests tailored to various
                educational levels and subjects. With user-friendly interfaces,
                detailed performance analytics. */}
              </Typography>
              <Typography
                className={classes.bannercardtextformobile}
                variant="body2"
                style={{ padding: ".5rem 2rem" }}
              >
                when an unknown printer took a galley of it to make a type
                specimen book.
              </Typography>
            </div>
            <div className={classes.bannercardbutton}>
              <Button
                onClick={() => navigate("/aboutus")}
                variant="contained"
                className={[
                  classes.orangecustombtn,
                  classes.bannercardtextformobile,
                ]}
              >
                know more
              </Button>
              <Button
                onClick={() => navigate("/aboutus")}
                variant="contained"
                className={[
                  classes.orangecustombtn,
                  classes.bannercardtextfordesktop,
                ]}
              >
                know more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BannerCard;
