import React, { useEffect, useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { getHelpDeskData, getHelpDeskDate } from "../../../service/services";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    width: "32%",
    border: "1px solid #D9D9D9",
    padding: "0.6rem",
    margin: "0.3rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-subtitle1": {
      color: "#444",
      fontFamily: "Jost",
      fontsize: "14px",
      fontweight: "600 !important",
    },
    "& .MuiTypography-subtitle2": {
      color: " #8B8B8B",
      leadingtrim: "both",
      textedge: "cap",
      fontfamily: "Inter",
      fontsize: "12px",
      fontstyle: "normal",
      fontweight: "400",
      lineheight: "normal",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pendingcardbg: {
    background: "#F5F7FA",
  },
  completedbutton: {
    color: "#00A019",
    border: "1px solid #00A019",
    textEdge: "cap",
    fontFamily: "Jost",
    fontSize: "0.9rem",
    fontStyle: "normal",
    fontWeight: "600",
    padding: "0.3rem",
    justifyContent: "center",
    textAlign: "center",
    width: "38%",
    borderRadius: "6px",
  },
  pendingbutton: {
    color: "#FF3939",
    justifyContent: "center",
    textAlign: "center",
    width: "38%",
    padding: "0.3rem",
    fontFamily: "Jost",
    fontSize: "0.9rem",
    fontWeight: "600",
    borderRadius: "0.375rem",
    border: "1px solid #FF3939",
  },
  container2: {
    marginTop: "1rem",
    color: "#606060",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    margin: "2rem 0",
  },
}));
const Data = [
  {
    id: 1,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Completed",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 2,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Pending",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 3,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Pending",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 4,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Pending",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 5,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Completed",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 6,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Completed",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 7,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Completed",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 8,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Pending",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
  {
    id: 9,
    issue_head: "ISSUE HEADING",
    date: "13 May 2023",
    status: "Completed",
    message:
      " Google Analytics gives you tools free of charge to understand the customer journey and improve your marketing ROI. Create an account free of charge Built with Google's AI Have AI predict user behavior and deliver actionable …",
  },
];

const HelpDeskCard = ({helpData}) => {
  const classes = useStyles();
  
  return (
    <>
      {helpData?.map((data) => (
        <div
          className={`${classes.root} ${
            data.status === "Pending" && classes.pendingcardbg
          }`}
        >
          <div className={classes.container}>
            <div>
              <Typography variant="subtitle1">{data?.issue}</Typography>
              <Typography variant="subtitle2">
                {new Date(data?.created_at).toLocaleDateString()}
              </Typography>
            </div>
            {data.status === "completed" ? (
              <div className={classes.completedbutton}>{data?.status}</div>
            ) : (
              <div className={classes.pendingbutton}>{data?.status}</div>
            )}

          </div>
          <div className={classes.container2}>{data?.message}</div>
        </div>
      ))}
    </>
  );
};

export default HelpDeskCard;
