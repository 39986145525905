import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import appContext from "../../context/AppContext"
import ConstantPracticeData from "./constant";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    justifyContent: "center",
    width: "100%",
    position: "absolute",
    // bottom: '-48px',
    top: "15rem",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
    },
    '& .MuiTab-root ': {
      minWidth: '120px !important',
      minHeight: '30px !important',
    },
    "& .MuiTabs-root": {
      alignItems: "center",

    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      background: "linear-gradient(90deg, rgba(255,128,8,0.6) 0%, rgba(255,200,55,0.6) 100%)",
      color: "black",
      padding: "1rem",
      lineHeight: "0",
      fontWeight: '600',
      fontSize: '0.8rem',
      [theme.breakpoints.down("sm")]: {
        padding: "0 1rem", fontSize: 14,
        fontFamily: "Jost"
      }
    },
    "& .MuiAppBar-root": {
      background: "#FFFFFF",
      color: "#FFFFFF",
      boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.10)",
      [theme.breakpoints.down("sm")]: {
        boxShadow: 'none',
        margin: ".6rem 0"
      }
    },
    "& .MuiTab-textColorPrimary": {
      color: "#212121",
      background: "#FFF8E8",
      margin: "5px",
      fontWeight: '600',
      fontSize: '0.8rem',
      lineHeight: "0 !important",
      borderRadius: "10rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0 1rem", fontSize: 14,
        fontFamily: "Jost"
      }
    },
    "& .MuiTabScrollButton-root": {
      width: "30px",
      height: "30px",
      padding: "10px",
      margin: '0.4rem',
      // boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.6)',
      background: "white",
      color: "black",
      borderRadius: "50%",
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '2.25rem',
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 ",
    }
  },
}));

// Subcategory for web and exams for mobile
export default function Subcategory(props) {
  const { ExamData, quiz, setQuiz, media, practicepathname } = props;
  const classes = useStyles();
  const AppContext = useContext(appContext);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <AppBar position="static" color="default">
          {media.matches ?
            <Tabs
              value={quiz?.categories != -1 && quiz?.categoryName + quiz?.categories + quiz?.subCategories + quiz?.exams}
              indicatorColor="none"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {
                ExamData?.map((row, index) => {
                  return (
                    row?.categories?.map((row2) => (
                      (quiz?.categories === -1 || quiz?.categories === row2?.id) && row2?.subCategories?.map((data, index) => {
                        return (!quiz?.subCategories || quiz?.subCategories === data?.id) && data?.[ConstantPracticeData?.[practicepathname]?.level3]?.map((d, index) => {
                          return (
                            <Tab value={row2?.category_name + row2?.id + data?.id + d?.id} label={d?.[ConstantPracticeData?.[practicepathname]?.level3Name]} onClick={() => {
                              // setQuiz({ ...quiz, categoryName: row2?.category_name, categories: row2?.id, subCategories: data?.id, exams: d?.id, examName: d?.[ConstantPracticeData?.[practicepathname]?.level3Name] });
                              setQuiz({
                                ...quiz,
                                categoryName: row2?.category_name,
                                 categories: row2?.id, 
                                 subCategories: data?.id, 
                                 exams: d?.id,
                                 examName:
                                 d?.[
                                   ConstantPracticeData?.[practicepathname]
                                     ?.level3Name
                                 ],
                              });
                              // AppContext.setCourseData({
                              //   category: row2?.id,
                              //   categoryName: row2?.category_name,
                              //   subcategory: data?.id,
                              //   exam: d?.id,
                              //   exam_name: d?.[ConstantPracticeData?.[practicepathname]?.level3Name]
                              // });
                              // AppContext.setTempSubcategory(null);
                            }} />
                          )
                        })
                      })
                    ))
                  )
                })
              }
            </Tabs>
            :
            <Tabs
              value={quiz?.categories != -1 && quiz?.categoryName + quiz?.categories + quiz?.subCategories}
              indicatorColor="none"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {
                ExamData?.map((row, index) => {
                  return (
                    row?.categories?.map((row2) => (
                      (quiz?.categories === -1 || quiz?.categories === row2?.id) && row2?.subCategories?.map((data, index) => {
                        return <Tab value={row2?.category_name + row2?.id + data?.id} label={data.sub_category_name} onClick={() => {
                          setQuiz({ ...quiz, categoryName: row2?.category_name, categories: row2?.id, subCategories: data?.id, examName: "" });
                          AppContext.setCourseData({
                            category: row2?.id,
                            categoryName: row2?.category_name,
                            subcategoryName: data?.sub_category_name,
                            subcategory: data?.id,
                            exam: "",
                            exam_name: ""
                          })
                        }} />
                      })
                    ))
                  )
                })
              }
            </Tabs>
          }
        </AppBar>
      </div>
    </div>
  );
}
