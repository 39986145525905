const locations = [
  "Delhi NCR",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Punjab",
  "Gujarat",
  "Maharashtra",
  "Madhya Pradesh",
  "Bihar",
  "Jharkhand",
];

const locationsRight = [
  "Arunachal Pradesh",
  "Manipur",
  "Sikkim",
  "Andhra Pradesh",
  "Karnataka",
  "Telangana",
  "Kerala",
  "Tamil Nadu",
  "West Bengal",
  "Puducherry",
];

export { locations, locationsRight };
