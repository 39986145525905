import React from "react";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import WhatWeofferVector from "../image/coachingsvector/whatweoffervector.png";
import { ReactComponent as WhatWeOfferCardvector } from "../image/coachingsvector/whatweoffercardvector.svg";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "2rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  whatweoffersection: {
    marginTop: "2rem",
  },
  whatweoffermain: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
    margin:'0 1rem',
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  whatweoffercontent: {
    width: "55%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  whatweofferimgcontainer: {
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  whatweoffercontentinner: {
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h3": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.9rem",
      fontStyle: "normal",
      fontWeight: "600",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
        lineHeight: '1.625rem',
        textAlign:'center',
      },
    },
    "& .MuiTypography-body2": {
      color: "#565656",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
        lineHeight: '1.625rem',
        textAlign:'center',
      },
    },
  },
  whatweoffercard: {
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    "& .MuiTypography-h6": {
      color: "#222",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.4rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "1.39438rem",
    },
    "& .MuiTypography-caption": {
      color: "#696969",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "1.5625rem",
      textTransform: "lowercase",
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "0",
    },
    "& .MuiCardContent-root": {
      padding: "0",
    },
    "& .MuiCard-root": {
      display: "flex",
      width: "48%",
      padding: "0.6rem",
      alignItems: "center",
      "& svg": {
        marginRight: "1rem",
      },
      "&:hover": {
        background: "#FFF6DF",
        "& svg": {
          fill: "white",
        },
      },
    },
  },
}));
function WhatWeOffer() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Wanna Know",
      headingmain: "What We Offer",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.whatweoffersection}>
          <SecHead Heading={Heading} />
          <div className={classes.whatweoffermain}>
            <div className={classes.whatweoffercontent}>
              <div className={classes.whatweoffercontentinner}>
                <Typography variant="h3" gutterBottom>
                  "Empower Your Institute, Transform Education Online!"
                </Typography>
                <Typography variant="body2" gutterBottom>
                  These features collectively enable organizations, educational
                  institutions, and training providers to deliver effective and
                  engaging online learning experiences. When selecting an LMS,
                  it's important to consider which features align with your
                  specific educational or training objectives and the needs of
                  your learners.
                </Typography>
              </div>
              <div>
                <div className={classes.whatweoffercard}>
                  <Card className={classes.root}>
                    <WhatWeOfferCardvector />
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Ease of Business
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        complete and you get full access to the partner
                        dashboard
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card className={classes.root}>
                    <WhatWeOfferCardvector />
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Growth & Revenue
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        complete and you get full access to the partner
                        dashboard
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className={classes.whatweoffercard}>
                  <Card className={classes.root}>
                    <WhatWeOfferCardvector />
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Better Control
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        complete and you get full access to the partner
                        dashboard
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card className={classes.root}>
                    <WhatWeOfferCardvector />
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Online Bookshop
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        complete and you get full access to the partner
                        dashboard
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
            <div className={classes.whatweofferimgcontainer}>
              <img src={WhatWeofferVector} alt="img" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhatWeOffer;
