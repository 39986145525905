import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  withStyles,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    marginBottom: "20rem",

    "& .MuiTableContainer-root": {
      marginTop: "2rem",
      "& .MuiTableHead-root": {
        "& tr": {
          height: "1rem",
        },
      },
      "& tr": {
        height: "3rem",
        border: "none",
      },
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 12px 6px 13px",
      border: "1px solid #243B55",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiPaper-rounded": {
      borderRadius: 0,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 1rem",
      padding: "1rem 0.5rem",
      textAlign: "justify",
      "& .MuiTableContainer-root": {
        marginTop: "1rem",
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "6px 6px 6px 6px",
        border: "1px solid #243B55",
        textAlign: "left",
      },
    },
  },

  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.625rem",
      fontWeight: 700,
      color: "#262626",
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  table: {
    minWidth: "100%",
  },
  completedbtn: {
    "&.MuiButton-root": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#303030",
      textTransform: "capitalize",
      padding: "0.2rem 0.6rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.75rem",
      textTransform: "uppercase",
    },
  },

  disabledbtn: {
    "&.MuiButton-root": {
      width: '100%',
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
      color: "#000",
      textTransform: "capitalize",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.7rem",
    },
  },
  postdate: {
    width: "15%",
    [theme.breakpoints.down("xs")]: {
      width: "33%",
    },
  },
  postname: {
    width: "33%",
    [theme.breakpoints.down("xs")]: {
      width: "33%",
    },
  },
  recruitment: {
    width: "33%",
  },
  moreinfo: {
    width: "15%",
    [theme.breakpoints.down("xs")]: {
      width: "33%",
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243B55",
    color: "#FFF",
    fontFamily: "Jost",
    fontSize: "0.6875rem",
    fontWeight: "500",
  },
  body: {
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: "400",
    borderBottom: "none",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFBF5",
    },
  },
}))(TableRow);
function createData(date, board, name, info) {
  return { date, board, name, info };
}

const AdmitCardMain = ({ getInnerClicked, data, index,value,setValue }) => {
  const theme = createTheme();
  const classes = useStyles();
  let navigate = useNavigate();
  const heading = data?.category_name;
  const notifications = data?.notifications || [];
  console.log(index, "index");
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headname}>
          <Typography variant="h3">{heading}</Typography>
        </div>

        {notifications.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              size="small"
              className={classes.table}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.postdate}>
                    &nbsp;POST DATE
                  </StyledTableCell>
                  {!isMobile && (
                    <StyledTableCell
                      className={classes.recruitment}
                      align="center"
                    >
                      RECRUITMENT BOARD
                    </StyledTableCell>
                  )}
                  <StyledTableCell className={classes.postname} align="center">
                    POST NAME
                  </StyledTableCell>
                  <StyledTableCell className={classes.moreinfo} align="center">
                    MORE INFO
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell>
                      {formatDateString(row.updated_at)}
                    </StyledTableCell>
                    {!isMobile && (
                      <StyledTableCell align="center">
                        {row.requirement_board}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        className={classes.disabledbtn}
                        onClick={() => {
                          setValue(value);
                          navigate(`/notificationsinglepage/${row.id}/${value}`);
                        }}
                        
                      >
                        GET DETAILS
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default AdmitCardMain;
