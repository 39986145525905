import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import WelcomeSeller from "./welcomeseller";
import BannerCard from "./bannercard";
import SellerRegistration from "./sellerregistration";
import AdvantageSection from "./advantagesection";
import SellBooks from "./sellbooks";
import FAQSection from "../affilates/FAQSection";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
function Seller() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Create Your Own Space",
      headingmain: "Seller",
      breadcrumb: "Collaborate / Seller",
    },
  ];
  return (
    <>
      <PageHeading Heading={Heading} />
      <WelcomeSeller />
      <BannerCard />
      <SellerRegistration />
      <AdvantageSection />
      <SellBooks />
      <FAQSection />
    </>
  );
}
export default Seller;
