import React, { useEffect, useState, useContext } from "react";
import { Typography, makeStyles, Divider } from "@material-ui/core";
import QuizWinner from "../../image/quizreult/quizwinner.png";
import WhatsappIcon from "../../image/quizreult/whatsapp.png";
import FacebookIcon from "../../image/quizreult/facebook.png";
import LinkedinIcon from "../../image/quizreult/linkedin.png";
import InstagramIcon from "../../image/quizreult/instagram.png";
import ShareIcon from "../../image/quizreult/sharelink.png";
import QuizHeading from "./quizHeading";
import practiceContext from "../context/PypPracticeContext";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    paddingTop: "3rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .MuiDivider-flexItem": {
      height: "92px",
      alignSelf: "center",
      background: "#727272",
      [theme.breakpoints.down("xs")]: {
        height: "50px",
        background: "#C7C7C7",
      },
    },
  },
  onlineeducationmain: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width:'100%',
      marginTop: "1rem",
    },
  },
  onlineeducationcontent: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width:'100%',
      marginTop: "0",
    },
  },
  onlineeducationimgcontainer: {
    width: "10%",
    [theme.breakpoints.down("xs")]: {
      width: "34%",
      margin: "0 auto",
    },
  },
  section: {
    flex: 1,
    padding: "2rem",
    whiteSpace: "nowrap",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width:'32%',
      padding: "0",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', sans-serif",
      color: "rgba(34, 56, 82, 1)",
      fontSize: "23px",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiTypography-body2": {
      fontFamily: "'Jost', sans-serif",
      color: "rgba(34, 56, 82, 1)",
      fontSize: "32px",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8125rem",
      },
    },
  },

  socialIcons: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: "1rem",
    "& img": {
      width: "2rem",
      margin: "0 1rem",
      cursor: "pointer",
      transition: "transform 0.2s ease-in-out",
      "&:hover": {
        transform: "scale(1.3)",
      },
    },
  },

  iconText: {
    alignItems: "center",
    marginRight: "0rem",
    marginTop: "0rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.6rem",
      marginRight: "0",
    },
    "& .MuiTypography-body2": {
      marginRight: "0.5rem",
      fontWeight: "400",
      fontSize: "20px",
      color: "rgba(34, 56, 82, 1)",
      fontFamily: "'Jost', sans-serif",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0",
      },
    },
  },
  sectionContainerMobile: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      alignItems: 'center',
      marginTop: "0",
      width:'100%',
    },
  },
}));

function QuestionBank({selectedLanguage, handleLanguageChange}) {
  const classes = useStyles();
  const { quizQuestionObj, timeTaken } = useContext(practiceContext);
  const [calculation, setCalculation] = useState({ correct: 0, incorrect: 0 });
  const Heading = [
    {
      id: 1,
      headingsmall: "Continue to Practice, keep Getting Better.",
      headingmain: "Good Try!",
    },
  ];
  useEffect(() => {
    let correct = 0;
    let incorrect = 0;
    quizQuestionObj?.sections?.forEach((section) => {
      section?.questions?.forEach((question) => {
        question?.options?.forEach((option) => {
          if (option?.user_answer) {
            if (option?.correct_option && option?.user_answer) {
              correct = correct + 1;
            } else {
              incorrect = incorrect + 1;
            }
          }
        });
      });
    });
    setCalculation({ correct, incorrect });
  }, []);

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <QuizHeading Heading={Heading} selectedLanguage={selectedLanguage} quizQuestionObj={quizQuestionObj} handleLanguageChange={handleLanguageChange}/>
        <div className={classes.onlineeducationmain}>
          {/* <div className={classes.onlineeducationimgcontainer}>
            <img src={QuizWinner} alt="img" width="100%" />
          </div> */}
          <div className={classes.onlineeducationcontent}>
            {/* <div className={classes.section}>
              <Typography variant="h6">Your Rank</Typography>
              <Typography variant="body2">430 / 987</Typography>
            </div> */}
            <div className={classes.sectionContainerMobile}>
              <div className={classes.section}>
                <Typography variant="h6">INCORRECT</Typography>
                <Typography variant="body2">
                  {calculation?.incorrect}
                </Typography>
              </div>
              <Divider
                className={classes.divider1}
                orientation="vertical"
                flexItem
              />
              <div className={classes.section}>
                <Typography variant="h6">CORRECT</Typography>
                <Typography variant="body2">{calculation?.correct}</Typography>
              </div>
              <Divider
                className={classes.divider2}
                orientation="vertical"
                flexItem
              />
              <div className={classes.section}>
                <Typography variant="h6">TIME TAKEN</Typography>
                <Typography variant="body2">
                  {timeTaken?.minute}.{timeTaken?.second} secs
                </Typography>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.iconText}>
          <Typography variant="body2">
            Lets your friend know about the quiz
          </Typography>
        </div>
        <div className={classes.socialIcons}>
          {[
            { icon: WhatsappIcon, alt: "Whatsapp Icon" },
            { icon: FacebookIcon, alt: "Facebook Icon" },
            { icon: LinkedinIcon, alt: "Linkedin Icon" },
            { icon: InstagramIcon, alt: "Instagram Icon" },
            { icon: ShareIcon, alt: "Share Icon" },
          ].map((item, index) => (
            <img key={index} src={item.icon} alt={item.alt} />
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default QuestionBank;
