import React, { useState } from 'react'
import { Typography, makeStyles } from "@material-ui/core";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  personaldetails: {
    width: "80%",
    marginTop: "1rem",
    margin: "1rem 5px",
    borderRadius: 5,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    marginBottom:"20rem",
  "& .MuiTypography-h6":{
    color:'blue',
    marginTop:'1rem',
  },
  "& .MuiTypography-h5":{
    color:'#808080',
    fontSize:'16px',
    fontWeight:"500",

  },
  },
  personalname:{
    width:"100%",
    marginTop:'1.5rem',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiTextField-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'border-color: rgba(255,255,255,0) rgba(255,255,255,1) rgba(255,255,255,0) rgba(255,255,255,0)',
    },
  },
  TypeHere:{
    width:"100%",
    marginTop:'.5rem',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiTextField-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'red',
    },
  },
  selectinput:{
    width:"100%",
    marginTop:'.5rem',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiInputBase-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'red',
    },
  },
  securitypin:{
    display:'flex',
    marginTop:'1.5rem',
    justifyContent: 'center',
    alignItems:' baseline',
    "& .MuiFormLabel-root":{
      width:'20%',
    },
    "& .MuiOutlinedInput-root":{
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    }
  },
  submitbtn:{
    display:'flex',
    justifyContent: 'flex-end',
  },
  btn:{
    background:'#ff8c00',
    width:'10%',
    justifyContent:'space-around',
  }
}));

function ApplicationForm() {
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const [selectedGender, setSelectedGender] = useState("");
  const handleChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const classes = useStyles();
  return (
   <div className={classes.personaldetails}>
     <Typography variant='h6'>व्यक्तिगत विवरण (Personal Details)</Typography>
   <div className={classes.personalname}>
                <FormLabel>
                  
           अभ्यर्थी का नाम (candidate's Name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
            पिता का नाम (father's name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                अभ्यर्थी की जन्म तिथि (Candidate's Date of Birth)
                </FormLabel>
                
                     <TextField
                  type="date"
                  variant="outlined"
                  required
                  placeholder=""
                />
                   

                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                  लिंग (Gender)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                 राष्ट्रीयता (Nationality)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>india</MenuItem>
                  <MenuItem value={"female"}>japan</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                 पहचान के प्रकार (Identity Type)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>india</MenuItem>
                  <MenuItem value={"female"}>japan</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                पहचान संख्या (Identification Number)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <Typography variant='h6'>वर्तमान पता (Present Address)</Typography>
   <div className={classes.personalname}>
                <FormLabel>
                  
                पता (Address)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>  
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                स्थानीयता (Locality) (Optional)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>  
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                शहर/ कस्बा/ गाँव (City/Town/Village)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>  
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
              देश (Country)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                 राज्य (State)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                 जिला (District)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                पिन कोड (Pin Code)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                मोबाइल नंबर (Mobile Number)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                ईमेल पता (Email Address)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                वैकल्पिक संपर्क सूत्र (Alternate Contact No.) (Optional)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <Typography variant='h6'>स्थाई पता (Permanent Address)</Typography>
                <Typography variant='h5'>Same As Present Address</Typography>
   <div className={classes.personalname}>
                <FormLabel>
                  
                पता (Address)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div> 
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                स्थानीयता (Locality)(Optional):
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                शहर/ कस्वा / गाँव (City/Town/Village)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                 देश (Country)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
               
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                राज्य (State)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                जिला (District)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                पिन कोड (Pin Code)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <Typography variant='h6'>Choose Password</Typography>
               
   <div className={classes.personalname}>
                <FormLabel>
                  
                कुंजिका (Password)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                कुंजिका की पुष्टि कीजिये (Confirm Password)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
               सुरक्षा प्रश्न (Security Question)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                सुरक्षा जवाब (Security Answer)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <Typography variant='h6'>Security Pin</Typography>
               
   <div className={classes.personalname}>
                <FormLabel>
                  
                Enter security pin (case sensitive)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.securitypin}>
                <FormLabel>
                  
                Security Pin
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div> 
              <div className={classes.submitbtn}> <Button  
               className={classes.btn}
            >
              Submit
            </Button></div>
              </div>
  
  )
}

export default ApplicationForm;