import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  react_player: {
    height: "370px !important",
  },
}));

const CustomVideoPlayer = ({ url }) => {
  const classes = useStyles();

  const apiUrl =
    "https://api-dev.parikshado.com/uploads/online_video/lecture/lecture_115_1710415422509.mp4";
  const youtubeUrl = "https://youtu.be/cK9h5PVzRpk?si=kB3NZuJqi5iSF6-w";

  const extractFileId = (url) => {
    const regExp =
      /(?:drive\.google\.com\/(?:file\/d\/|open\?id=|uc\?id=))([^\/\?&]+)/;
    const match = url?.match(regExp);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  };
  const fileId = extractFileId(url);

  const [lastPlayedTime, setLastPlayedTime] = useState(0);

  // Function to update the last played time
  const handleProgress = (progress) => {
    setLastPlayedTime(progress.playedSeconds);
  };

  useEffect(() => {
    // Fetch last played time from storage (e.g., localStorage) on component mount
    const lastPlayedTimeFromStorage = parseFloat(
      localStorage.getItem("lastPlayedTime")
    );
    if (!isNaN(lastPlayedTimeFromStorage)) {
      setLastPlayedTime(lastPlayedTimeFromStorage);
    }
  }, []);

  // Function to handle when video ends
  const handleVideoEnd = () => {
    // Clear last played time when video ends
    setLastPlayedTime(0);
    // You might want to save the last played time in storage here for persistence
  };

  // const driveUrl = "https://drive.google.com/file/d/1m-JTzS6SwHGaGHnKpdHi9t_fpv-zTtup/view?usp=drive_link";
  // const directUrl = `https://drive.google.com/file/d/${fileId}/preview`;

  return (
    <div>
      {url?.includes("drive.google.com") ? (
        <iframe
          src={`https://drive.google.com/file/d/${fileId}/preview`}
          width="100%"
          height="370px"
          title="Google Drive Video"
        ></iframe>
      ) : (
        <ReactPlayer
          url={url}
          controls
          width="100%"
          className={classes.react_player}
          style={{
            borderRadius: "10px",
            boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06)",
            height: "auto",
          }}
          // onPause={() => {
          //   // Save last played time to storage when video is paused
          //   localStorage.setItem("lastPlayedTime", lastPlayedTime);
          // }}
          // onProgress={handleProgress}
          // onEnded={handleVideoEnd}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", // Prevent download in HTML5 video element
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default CustomVideoPlayer;
