import React, { useState } from "react";
import { Card, Typography, makeStyles } from "@material-ui/core";
import teacheraged from "../../image/dashboard/videocourse/teacheraged.png";
import teacherwithbooks from "../../image/dashboard/videocourse/teacherwithbooks.png";
import boardwithteacher from "../../image/dashboard/videocourse/boardwithteacher.png";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/LockOpen";
import PlayCircleFilledOutlinedIcon from "@material-ui/icons/PlayCircleFilledOutlined";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #A5A5A5",
    padding: "0.5rem",
    margin: "0.3rem",
    borderRadius: "6px",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
  },
  sidediv1: {
    width: "28%",
  },
  sidediv2: {
    width: "73%",
    "& .MuiTypography-h4": {
      color: "#262626 !important",
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Jost",
      fontSize: " 0.875rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.3125rem",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h5": {
      color: "#A9A9A9",
      leadingTrim: "both",
      fontFamily: " Jost",
      fontSize: "0.4375rem !important",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "0.75rem",
    },
    "& .MuiTypography-h6": {
      color: "#A9A9A9 !important",
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Jost",
      fontSize: " 0.825rem",
      fontStyle: "normal",
      fontWeight: 500,
    },
  },
}));

const SeiesForCard = ({ handleVideoClick, Data }) => {
  const classes = useStyles();
  const [selectedVideo, setSelectedVideo] = useState(null);
  return (
    <>
      {Array.isArray(Data) &&
        Data?.map((data) => (
          <Card
            className={classes.root}
            key={data.id}
            onClick={() => {
              handleVideoClick(data?.link ? data?.link : data?.lecture_url);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <div className={classes.sidediv1}>
              <PlayCircleFilledOutlinedIcon
                style={{ fontSize: "xxx-large", color: "#243B55 " }}
              />
            </div>
            <div className={classes.sidediv2}>
              <Typography variant="h4">{data?.lecture_name}</Typography>
              <Typography variant="h5">{data.sub_heading}</Typography>
              <Typography variant="h6">{data?.duration} min</Typography>
            </div>
          </Card>
        ))}
    </>
  );
};

export default SeiesForCard;
