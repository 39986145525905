import { GET_QUIZ_QUESTIONS, SET_CURRENT_QUESTION, SET_QUESTION_ANSWER, SET_TIME_TAKEN } from "./type";

const Reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_QUIZ_QUESTIONS: {
      return {
        ...state,
        quizQuestionObj: payload,
      };
    }

    case SET_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: payload
      };
    }

    case SET_QUESTION_ANSWER: {
      state?.quizQuestionObj?.questions?.[state?.currentQuestion - 1]?.options?.forEach((element, index) => {
        if (index === payload) {
          element.user_answer = true;
        } else {
          element.user_answer = false;
        }
      });
      return {
        ...state,
        quizQuestionObj: state?.quizQuestionObj
      };
    }
    case SET_TIME_TAKEN: {
      return {
        ...state,
        timeTaken: payload
      };
    }


    default:
      return state;
  }
};

export default Reducer;
