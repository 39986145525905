import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import FacultyBio from "./facultybio";
import CourseSection from "./coursessection";
import CardSection from "./cardsection";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginBottom: "15rem",
    background: "#FFFBF1",
  },
}));

function FacultyProfile() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Study",
      headingmain: "Faculty Profile",
      breadcrumb: "  faculty / Profile Page",
    },
  ];

  return (
    <div className={classes.mainContainer}>
      <PageHeading Heading={Heading} />
      <FacultyBio />
      <CourseSection/>
      <CardSection/>
    </div>
  );
}

export default FacultyProfile;
