import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import Carousel from "react-grid-carousel"; 
import ExclusiveBookCard from "./exclusivebookcard";
import { getExclusiveBook } from "../../../service/services";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  ExclusiveBookCardContainer: {
    // marginTop: "1rem",
    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },
    "& .slick-prev:before, .slick-next:before": {
      fontSize: "55px",
      borderRadius: "40px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      bottom: 0,
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
      display: "flex",
      height: '450px',
      justifyContent: "center",
      paddingTop: "0.5rem",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      // '& div':{
      //   width:'100%',
      // },
      [theme.breakpoints.down("xs")]: {
        padding: '2rem 1.5rem 2rem 1.5rem !important',
      height: '506px',
    },
    },
    '& .slick-dots': {
      [theme.breakpoints.down("xs")]: {
        bottom: '17px',
    },
   
  },
    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },



}));
function ExclusiveBookContent() {
  const initialSlidesToShow = window.innerWidth < 480 ? 1 : 4;

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: initialSlidesToShow,
    slidesToScroll: initialSlidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  const location = useLocation();
  const isNoteDetailPage = location.pathname.includes("/note/");

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow: newSlidesToShow,
        slidesToScroll: newSlidesToShow,
      }));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const classes = useStyles();
  const [exclusivebook,setExclusivebook]=useState([]);

  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "15px" : "15px",
        width: isActive ? "15px" : "15px",
        background: isActive ? "#FF8008" : "#EEE7D3",
        borderRadius: "100%",
      }}
    ></span>
  );
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getExclusiveBook();
        console.log("API Response:", response.data.books);
        setExclusivebook(response?.data?.books);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <>
      <div className={classes.ExclusiveBookCardContainer}>
        <Slider {...sliderSettings}>
          {exclusivebook?.map((data, indx) => (
                <ExclusiveBookCard data={data}key={indx} />
              ))}
        </Slider>
      </div>
    </>
  );
}
export default ExclusiveBookContent;
