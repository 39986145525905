import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import TeacherImage from "../image/expertteachersvector/teacherimage1.png";
import StarRoundedIcon from "@material-ui/icons/StarRounded";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 297,
    marginBottom: "2rem",
    "& .MuiTypography-h6": {
      fontSize: "1.2rem",
      fontFamily: "'Playfair Display', serif !important",
      color: "#444",
      fontWeight: "600",
      textAlign: "center",
      marginTop: "0.6rem",
    },
  },
  cardroot: {
    height: "280px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    padding: "1.5rem 0",
    background: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  
}));

function CoachingCard(props) {
  const { cardData } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Card className={classes.cardroot}>
          <img className={classes.cardimage} src={cardData.image}  alt="img" />
        </Card>
        <Typography variant="h6">{cardData.name}</Typography>
      </div>
    </>
  );
}

export default CoachingCard;
