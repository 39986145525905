import { Button, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import BooksErrorImage from "../../image/dashboard/bookserror.png";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
    width: "100%",
    background: "#FFFFFF",
    padding: "10px",
    margin: "auto",
  },
  maindiv: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  midil:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  head1: {
    color: "#F8982E !important",
    textAlign: "center",
    fontFamily: " Playfair Display !important",
    fontSize: "64px",
    fontWeight: "700 !important",
  },
  head2: {
    color: "#243B55",
    textAlign: "center",
    fontFamily: "Playfair Display",
    fontSize: "22px",
    fontWeight: "600",
    marginTop: "1rem",
  },
  head3: {
    color: "#243B55",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "0rem",
  },
  btn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "15px",
    fontWeight: "500",
    color: "white",
    padding: "0.6rem",
    borderRadius: "0.4rem",
  },
  midl: {
    align: "center",
  },
}));

const BooksErrorPage = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.maindiv}>
            <div>
              <img src={BooksErrorImage} alt="img" width="100%" />
            </div>
            <div className={classes.midil}>
              <Typography className={classes.head1}>OOPS!</Typography>
              <Typography className={classes.head2}>
                {" "}
                You Haven't Purchased Any Book Now.
              </Typography>
              <Typography className={classes.head3}>
                {" "}
                Pick What's Right For You
              </Typography>
              <Button className={classes.btn}>Explore More</Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default BooksErrorPage;
