import { useContext, useEffect, useState } from "react";
import addCartContext from "../../context/AddCartContext/AddCartContext";
import { ReactComponent as CartBagIcon } from "../image/ebooksvector/cartbagicon.svg";
import { Button, Snackbar, makeStyles } from "@material-ui/core";
import appContext from "../../context/AppContext";
import popupContext from "../../context/PopupContext/PopupContext";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  cartbagicon: {
    marginRight: "0.6rem",
  },
}));
function AddCartButton({ data }) {
  const classes = useStyles();
  const { addToCartListAPI, cartList, cartState, clearCartError } =
    useContext(addCartContext);
  const { isLoggedIn, token } = useContext(appContext);
  const { handleloginOpenclose } = useContext(popupContext);

const [openToaster , setOpenToaster] = useState(false)

  // const hasAlreadyAdded = cartState?.cart?.products?.findIndex((pro) => Number(pro.product_id)) > -1;
  const hasAlreadyAdded = cartState?.cart?.products?.findIndex((pro) => Number(pro.product_id) === data.id) > -1;


  const handleAddToCart = () => {
    const check = cartList?.cart?.find((el) => el?.id === data.id);

    if (check) {
      toast.error("already added in cart");
    } else {
      addToCartListAPI(data);
    }
  };
  
  // useEffect(() => {
  //   if (cartState.cart.success && hasAlreadyAdded) {
  //     toast.success(cartState.cart.successMessages || "Added");
  //     setOpenToaster(false);
  //   }
  // }, [hasAlreadyAdded]);

  // useEffect(() => {
  //   if (cartState.hasError && cartState.currentProductId === data.id)
  //     toast.error(cartState.errorMessage || "Error");
  // }, [cartState.hasError]);

  // console.log("cartState", cartState, hasAlreadyAdded, data);

  return (
    <>
      {isLoggedIn && token ? (
        <Button fullWidth onClick={handleAddToCart}>
          <CartBagIcon className={classes.cartbagicon} />
          Add To Cart
        </Button>
      ) : (
        <Button fullWidth onClick={handleloginOpenclose}>
          <CartBagIcon className={classes.cartbagicon} />
          Buy Now
        </Button>
      )}
      {/* Display alert for errors */}
      {/* {cartState.hasError && cartState.currentProductId === data.id && (
        <Snackbar
          open={cartState.hasError}
          autoHideDuration={2000}
          onClose={() => clearCartError()}
        >
          <Alert onClose={() => clearCartError()} severity="error">
            {cartState.errorMessage}
          </Alert>
        </Snackbar>
      )} */}
    </>
  );
}

export default AddCartButton;