import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Bannerimg from "../../image/popularexamvector/banner1.jpg";
import Bannerimgmob from "../../image/popularexamvector/banner1mob.png";
import { Grid, Paper } from "@material-ui/core";
import Slider from "react-slick";
import categoryContext from "../../../context/CategoryContext/CategoryContext";
import Carousel from "react-grid-carousel";
import { useMediaQuery } from "@material-ui/core";
import appContext from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    "& .bVoXXg": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  root: {
    flexGrow: 0,
    marginTop: "1rem",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "none",

    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },

    "& .slick-prev:before, .slick-next:before": {
      fontSize: "55px",
      borderRadius: "40px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      bottom: 0,
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      padding: "2rem 1.5rem !important",
    },
    "& .slick-dots": {
      bottom: "0px",
    },
    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
    "& .MuiTabs-root": {
      alignItems: "center",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
      color: "black",
      fontFamily: "Jost",
      fontSize: "1rem",
      textTransform: "capitalize",
      "&:hover": {
        background:
          "linear-gradient(90deg, rgba(255,200,55,0.9) 0%, rgba(255,128,8,0.9) 100%)",
        color: "black",
      },
    },
    "& .MuiAppBar-root": {
      background: "transparent ",
      color: "#FFFFFF",
      boxShadow: "none",
      [theme.breakpoints.up("sm")]: {
        background: "linear-gradient(90deg, #141E30 0%, #1c2f45 100%)",
        padding: "1rem",
      },
    },
    "& .MuiTab-textColorPrimary": {
      color: "#FFFFFF",
      background: "#243951",
      margin: "10px",
      borderRadius: "10rem",
      fontFamily: "Jost",
      fontSize: "14px",
      textTransform: "capitalize",
      minHeight: "42px",
      "&:hover": {
        background: "#3D5A7C",
        color: "white",
      },
    },
    "& .MuiBox-root": {
      padding: "8px 0",
    },
    "& .MuiTabScrollButton-root": {
      width: "40px",
      height: "40px",
      padding: "10px",
      background: "white",
      color: "black",
      borderRadius: "50%",
    },
    "& .MuiBox-root-141": {
      padding: 0,
    },
    "& .MuiGrid-root": {
      "& .fYKcpE": {
        margin: "0 0px 15px 20px",
      },
    },
  },
  examsection: {
    display: "flex",
    marginTop: "1rem",
    // "& .MuiGrid-item": {
    //   paddingRight: "10px",
    // },
  },
  examname: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    "& .MuiPaper-elevation1": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiPaper-root": {
      padding: "1rem",
      "&:hover": {
        boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
        cursor: "pointer",
      },
    },
    "& .MuiTypography-h6": {
      marginTop: "0.5rem",
    },
  },
  exafullWidth: {
    maxWidth: "100%",
  },
  exambanner: {
    display: "flex",
    justifyContent: "center",
    // padding: "0 20px 0 ",
  },
  // exampaper: {
  //   width: "100%",
  //   padding: "5%",
  //   textAlign: "center",
  //   margin: "5%",
  //   boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  //   transition: "all ease 0.2s",
  //   "&:hover": {
  //     transform: "translateY(-3px)",
  //     boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.1)",
  //     cursor: "pointer",
  //   },
  // },
  exampapersmall: {
    width: "95%",
    height: "170px",
    padding: "5%",
    margin: "5% 0%",
    minWidth: 150,
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "8% 0%",
      width: "150px !important",
      height: "130px !important",
      display: "flex !important",
    },
  },
  paperinner: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  selectedImage: {
    borderColor: "#FF8008",
    borderWidth: "2px",
    borderStyle: "solid",
  },
}));

export default function PopularSectionContent({searchQuery}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:900px)");
  const CategoryContext = useContext(categoryContext);
  const AppContext = useContext(appContext);
  const [selectedExam, setSelectedExam] = useState(null);
  const [cateId, setCateId] = useState("");
  const [cateName, setCateName] = useState("");
  const [subCateId, setSubCateId] = useState("");
  const [subCateName, setSubCateName] = useState("");
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    initialSlide: 0,
    dots: true,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesPerRow: 3,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "15px" : "15px",
        width: isActive ? "15px" : "15px",
        background: isActive ? "#FF8008" : "#EEE7D3",
        borderRadius: "100%",
        marginTop: 40,
      }}
    ></span>
  );
  const megaMenu = CategoryContext?.megaMenu || [];
  const allCategories = [];
  const [selectedCategory, setSelectedCategory] = useState("");
  const data = useContext(appContext);

  megaMenu.forEach((menu) => {
    if (menu.categories) {
      menu.categories.forEach((category) => {
        const categoryData = {
          id: category.id,
          category_name: category.category_name,
          subCategories: [],
        };

        if (category.subCategories) {
          categoryData.subCategories = category.subCategories.map(
            (subCategory) => ({
              category_id: categoryData.id,
              category_name: categoryData.category_name,
              id: subCategory.id,
              sub_category_name: subCategory.sub_category_name,
              exams: subCategory.exams || [],
            })
          );
        }

        allCategories.push(categoryData);
      });
    }
  });

  const subCategories = allCategories.flatMap(
    (category) => category.subCategories
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCategoryClick = (
    category_id,
    category_name,
    sub_category_id,
    sub_category_name
  ) => {
    setCateId(category_id);
    setCateName(category_name);
    setSubCateId(sub_category_id);
    setSubCateName(sub_category_name);
  };

  const handleExamClick = (examId, examName) => {
    if (subCateId && subCateName.length > 0) {
      setSelectedExam(examId);

      AppContext.setCourseData({
        category: cateId,
        categoryName: cateName,
        subcategoryName: subCateName,
        subcategory: subCateId,
        exam: examId,
        exam_name: examName,
      });
    } else {
      console.log("Condition not met, not handling the exam click.");
    }
  };

  useEffect(() => {
    if (AppContext.coursesData) {
      const {
        category,
        categoryName,
        subcategory,
        exam,
        subcategoryName,
        value,
      } = AppContext.coursesData;
      setSelectedCategory(subcategory);
      const selectedIndex = subCategories.findIndex(
        (subCategory) => subCategory.id === subcategory
      );
      setValue(selectedIndex !== -1 ? selectedIndex : 0);
      setCateId(category);
      setCateName(categoryName);
      setSubCateName(subcategoryName);
      setSubCateId(subcategory);
      setSelectedExam(exam);
    }
  }, [
    AppContext.coursesData,
    setSubCateName,
    setSubCateId,
    setSelectedExam,
    selectedCategory,
  ]);

  const filteredSubCategories = subCategories.filter((subCategory) =>
    subCategory.exams.some((exam) =>
      exam.exam_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="none"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {/* {subCategories.map((subCategoryData, index) => (
              <Tab
                key={subCategoryData.id}
                label={subCategoryData.sub_category_name}
                className={
                  subCategoryData.id === selectedCategory
                    ? classes.examsection
                    : ""
                }
                {...a11yProps(index)}
                onClick={() => {
                  AppContext.setTempSubcategory(
                    subCategoryData?.sub_category_name
                  );
                  // setValue(subCategoryData?.id)
                  setCateId(subCategoryData.category_id);
                  setCateName(subCategoryData.category_name);
                  setSubCateId(subCategoryData?.id);
                  setSubCateName(subCategoryData?.sub_category_name);
                }}
              />
            ))} */}
              {filteredSubCategories.map((subCategoryData, index) => (
      <Tab
        key={subCategoryData.id}
        label={subCategoryData.sub_category_name}
        className={
          subCategoryData.id === selectedCategory ? classes.examsection : ""
        }
        {...a11yProps(index)}
        onClick={() => {
          AppContext.setTempSubcategory(
            subCategoryData?.sub_category_name
          );
          setCateId(subCategoryData.category_id);
          setCateName(subCategoryData.category_name);
          setSubCateId(subCategoryData?.id);
          setSubCateName(subCategoryData?.sub_category_name);
        }}
      />
    ))}
          </Tabs>
        </AppBar>

        {filteredSubCategories.map((subCategoryData, index) => (
          <TabPanel value={value} index={index} key={subCategoryData.id}>
            <div
              className={classes.examsection}
              value={subCateId}
              onClick={() =>
                handleCategoryClick(
                  subCategoryData.category_id,
                  subCategoryData.category_name,
                  subCategoryData.id,
                  subCategoryData.sub_category_name
                )
              }
            >
              {/* Your tab content */}

              <Grid container spacing={0}>
                {/* Banner Section */}
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div
                    className={classes.exambanner}
                    style={isMobile ? { height: "auto" } : { height: "96%" }}
                  >
                    {isMobile ? (
                      <img
                        src={Bannerimgmob}
                        alt="img"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img src={Bannerimg} alt="img" width="100%" />
                    )}
                  </div>
                </Grid>

                {/* Exam List Section */}
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  {isMobile ? (
                    <Slider {...settings}>
                      {subCategoryData.exams.map((exam) => (
                        <Paper
                          key={exam.id}
                          onClick={() => {
                            handleExamClick(exam.id, exam.exam_name);
                            navigate(`/singleexamproducts/${exam?.id}`);
                          }}
                          className={`${classes.exampapersmall} ${
                            selectedExam === exam.id || selectedExam === value
                              ? classes.selectedImage
                              : ""
                          }`}
                          data-exam-id={exam.id}
                          data-exam-name={exam.exam_name}
                        >
                          <div className={classes.paperinner}>
                            <img
                              src={exam.image_url}
                              alt={exam.exam_name}
                              width="50%"
                            />
                            <Typography
                              variant="h6"
                              display="block"
                              gutterBottom
                              style={{
                                fontFamily: "Jost",
                                margin: "6px 0px",
                                fontSize: 16,
                              }}
                              value={value}
                            >
                              {exam.exam_name}
                            </Typography>
                          </div>
                        </Paper>
                      ))}
                    </Slider>
                  ) : (
                    <Carousel
                      dot={MyDot}
                      scrollSnap={true}
                      mobileBreakpoint={900}
                      cols={4}
                      hideArrow={true}
                      showDots={true}
                      rows={3}
                      gap={10}
                      loop
                    >
                    {subCategoryData.exams
  .filter((exam) =>
    exam.exam_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  )
  .map((exam) => (
    <Carousel.Item key={exam.id}>
      <Paper
        className={`${classes.exampapersmall} ${
          selectedExam === exam.id || selectedExam === value
            ? classes.selectedImage
            : ""
        }`}
        onClick={() => {
          handleExamClick(exam.id, exam.exam_name);
          navigate(`/singleexamproducts/${exam?.id}`);
        }}
      >
        <img
          src={exam.image_url}
          alt={exam.exam_name}
          // height="50%"
          width="50%"
        />
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          style={{
            fontFamily: "Jost",
            margin: "6px 0px",
            fontSize: 16,
          }}
        >
          {exam.exam_name}
        </Typography>
      </Paper>
    </Carousel.Item>
  ))}

                    </Carousel>
                  )}
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        ))}
      </div>
    </div>
  );
}
