import React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import sscbookimage from "../image/dashboard/sscbookimage.png";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.10)",
    margin: "1rem 0",
    borderRadius: "4px",
    background: "#FFF",
    "& .MuiTypography-h5": {
      color: "#262626",
      fontFamily: "Jost",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      marginTop: "1rem",
      width: "74%",
      textAlign: "left",
    },
    "& .MuiTypography-h6": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "23px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px" /* 133.333% */,
      textTransform: "capitalize",
      width: "63%",
    },
    "& .MuiTypography-h4": {
      color: "#777",
      fontFamily: "Jost",
      fontSize: "0.5rem",
      fontWeight: 400,
      lineHeight: "1.875rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.6rem",
      fontWeight: "400",
    },
  },
  bookpluscontent: {
    display: "flex",
    width: "100%",
  },
  bookbox: {
    "& img": {
      marginTop: "0.5rem",
    },
  },
  offertext: {
    fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.6rem",
      fontWeight: "400",
  },
  viewbutton: {
    color: "var(--white-100, #FFF)",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "0.625rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    textTransform: "capitalize",
    width: "36%",
    padding: "0.25rem",
    borderRadius: "0.375rem",
    background: "#243B55",
    marginRight: "0.3rem",
    "& :hover": {
      background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
      color: "#000",
    },
  },
  content: {
    width: "70%",
  },
  innertitle1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const BookCardMobile = ({ data,Data }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.main}>
        <div className={classes.bookpluscontent}>
          <div className={classes.bookbox}>
            <img src={Data?.image_url||sscbookimage} alt="img" />
          </div>
          <div className={classes.content}>
            <div className={classes.innertitle}>
              <Typography variant="h5">
                Quantitative Aptitude for {Data?.category?.category_name||"SSC CGL 2023"}
              </Typography>
            </div>
            <Typography variant="h4"> by {Data?.author_name}</Typography>
            <div className={classes.innertitle1}>
            <div>
              <Typography variant="h6">{data?.dropedprice||Data?.selling_cost}</Typography>
              <Typography variant="caption">
                MRP : <del>{data?.orignalprice||Data?.mrp}</del>
              </Typography>
              <span className={classes.offertext}>{data?.offerpercent||Data?.discount_percent}</span>
              </div>

              <Button className={classes.viewbutton}>SELL NOW</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookCardMobile;
