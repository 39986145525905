import {
  Backdrop,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import { useState } from "react";
import { checkLearnerData } from "../../service/services";
import PickCategoryModal from "./PickCategory";
import { ReactComponent as NameIcon } from "../image/allpopups/fullnameicon.svg";
import girlImage from "../image/allpopups/girlimage.png";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "50%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      margin: "0",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.58rem",
      },
    },
    "& .MuiTypography-body2": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.69rem",
      },
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
        marginTop: "0rem",
      },
    },
    "& .MuiDivider-root": {
      width: "44%",
      background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
      height: "0.125rem",
      marginTop: "1rem",
      [theme.breakpoints.down("xs")]: {
        width: "23%",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiFormControlLabel-label": {
        fontFamily: "Jost",
        fontSize: "0.9rem",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.65rem",
        },
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& input:-internal-autofill-selected": {
        backgroundColor: "#fff",
      },
    },
  },
  loginform: {
    width: "100%",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  paper: {
    top: "200px",
    left: "50%",
    width: "100%",
    height: "calc(100vh - 40vh)",
    display: "flex",
    zIndex: "8",
    overflow: "scroll",
    position: "absolute",
    backgroundColor: "#FFFAEF",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: "center",
    transform: "translateX(-50%)",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Jost",
      color: "#676767",
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "100px",
      padding: "1rem",
    },
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  googlefbiconmain: {
    display: "flex",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      justifyContent: "space-evenly",
      margin: "0.5rem 0",
    },
  },
  facebookicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },

  googleicon: {
    background: "#FFFFFF",
    borderRadius: "50rem",
    width: "3rem",
    height: "3rem",
    margin: "1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "inset 0px 6px 10px 0px rgba(0, 0, 0, 0.14)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0.5rem 0",
      width: "3rem",
      height: "3rem",
    },
  },
  passwordheadcont: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  formfootertext: {
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#5E5E5E",
    "& .MuiLink-root": {
      color: "#FF9213",
      cursor: "pointer",
    },
  },
  inputhead: {
    marginTop: ".5rem",
  },
  closebtncontainer: {
    position: "absolute",
    top: "15px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px ",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  facebooksvg: {
    width: "18px",
    height: "30px",
  },
  googlesvg: {
    width: "30px",
    height: "30px",
  },
  imgbox: {
    marginRight: "3rem",
  },
}));

function FullNameModal({ signUpSuccess }) {
  const classes = useStyles();

  const [learnerName, setLearnerName] = useState("");
  const [isCatModalOpen, setIsCatModalOpen] = useState(false);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const openCategoryModal = () => {
    setIsCatModalOpen(true);
  };

  const closeCategoryModal = () => {
    setIsCategoryModalOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!learnerName) {
      toast.warning("Please fill in your full name.");
      return;
    }
    setIsNameModalOpen(false);
    setIsCategoryModalOpen(true);
  };

  useEffect(() => {
    if (signUpSuccess) {
      setIsNameModalOpen(true);
    }
  }, [signUpSuccess]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const token = localStorage.getItem("token");
      const currentStaffLoggedIn = localStorage.getItem(
        "current_staff_logged_in"
      );

      if (token && currentStaffLoggedIn === "true") {
        clearInterval(intervalId); // Clear interval when the condition is met
        checkLearnerData()
          .then((response) => {
            console.log("response", response?.data?.full_name);
            if (response?.data?.full_name === false) {
              console.log("Opening the modal", response.data);
              setIsNameModalOpen(true);
            } else if (response?.data?.category === false) {
              console.log("Opening the modal", response.data);
              setIsNameModalOpen(false);
              setIsCategoryModalOpen(true);
            } else {
              console.log("Condition not met", response.data);
              setIsNameModalOpen(false);
            }
          })
          .catch((error) => {
            console.error("API request failed:", error);
          });
      }
    }, 1000); // Checking every 1 second

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  console.log("isNameModalOpen", isNameModalOpen, signUpSuccess);

  return (
    <>
      <ToastContainer />

      {isNameModalOpen && (
        <Modal
          open={isNameModalOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.container}>
            <div className={classes.closebtncontainer}>
              {/* <Button
              className={classes.closebtn}
              onClick={handlesignupOpenclose}
            >
              <GrFormClose />
            </Button> */}
            </div>
            <div className={classes.paper}>
              <div className={classes.imgbox}>
                <img src={girlImage} alt="img" width="80%" />
              </div>
              <div className={classes.main}>
                <Typography
                  variant="h3"
                  gutterBottom
                  className={classes.orangetext}
                >
                  Tell us About You
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  We’ve sent the code to the email on your Device
                </Typography>
                <Divider />

                <div className={classes.loginform}>
                  <form>
                    <FormControl sx={{ m: 1, width: "25ch" }}>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Your Full Name ?"
                        onChange={(e) => setLearnerName(e.target.value)}
                        value={learnerName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <NameIcon />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Button
                        className={classes.bluecustombtn}
                        fullWidth
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Next
                      </Button>
                    </FormControl>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isCategoryModalOpen && (
        <PickCategoryModal
          learnerName={learnerName}
          isCategoryModalOpen={openCategoryModal}
          closeModal={closeCategoryModal}
        />
      )}
    </>
  );
}

export default FullNameModal;
