import React from "react";
import { Rating, ToggleButton } from "@material-ui/lab";
import AdsPng from "../../image/singlepagedescriptionvector/adsimage.png";
import { Avatar, Button, FormControl, TextField } from "@material-ui/core";
import { ReactComponent as VerifiedBuyerSvg } from "../../image/singlepagedescriptionvector/verifiedbuyer.svg";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import UserReviewData from "./userreviewdata";

const useStyles = makeStyles((theme) => ({
  reviewcontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  reviewcontentmain: {
    width: "65%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  reviewcontent: {
    // width: "66%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  adspng: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& img": {
      width: "360px",
    },
  },
  individualreview: {
    width: "62%",
    border: "1px solid #DBDBDB",
    borderRadius: "5px",
    padding: "0 1.5rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    "& .MuiTypography-h4": {
      color: "#252525",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "600",
    },
    [theme.breakpoints.down("xs")]: {
      border: "none",
      borderRadius: "0",
      boxShadow: "none",
      padding: "0",
      width: "100%",
    },
  },
  individualinner: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    margin: "1.3rem",
    
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1.3rem 0",
    },
    "& .MuiTypography-h6": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      width: "86%",
    },
  },
  overallreview: {
    width: "36%",
    border: "1px solid #DBDBDB",
    borderRadius: "5px",
    padding: "1.5rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "2rem auto"
    },
    "& .MuiTypography-h4": {
      color: "#252525",
      fontSize: "1.5rem !important",
      fontFamily: "Playfair Display !important",
      fontWeight: "700",
    },
  },
  overallreviewinner1: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
    [theme.breakpoints.down("xs")]: {
      // flexDirection: "row",
    },
    "& .MuiTypography-h6": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      width: "100%",
    },
  },
  overallreviewinner2: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
    "& .MuiTypography-h6": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      width: "100%",
    },
  },
  ratings: {
    display: "flex",
  },
  customerreviewmain: {
    padding: "1.5rem 1rem",
    margin: "2rem 0",
    border: "1px solid #DBDBDB",
    borderRadius: "0.375rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  customerreview_bg_grey: {
    background: "#F9F9F9",
  },
  customerreview_bg_white: {
    background: "#FFFFFF",
  },
  customerreviewinner1: {
    width: "56%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-caption": {
      color: "#FFFFFF",
      background: "#61A72A",
      padding: "0rem 0.5rem",
      borderRadius: "0.1875rem",
      fontFamily: "Jost",
      fontSize: "0.9rem",
      marginRight: "0.6rem",
    },
  },
  buyeravtar: {
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      width: "35%",
    },
  },
  buyerreview: {
    width: "75%",
    [theme.breakpoints.down("xs")]: {
      width: "65%",
    },
  },
  comments: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-h6": {
      color: "#212121",
      fontSize: "1.125rem",
      fontFamily: "Jost",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  comments_mobileview: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0.7rem 0",
      "& .MuiTypography-h6": {
        color: "#212121 !important",
        fontSize: "1.125rem",
        fontFamily: "Jost",
        fontWeight: "600",
      // width: "86%",
    },
    
    },
  },
  customerreviewinner2: {
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      width: "62%",
    },
    "& .MuiTypography-caption": {
      color: "#6A6A6A",
      fontFamily: "Jost",
      fontSize: "0.875rem",
    },
    "& .MuiTypography-h6": {
      color: "#BFBFBF",
      fontSize: "1.125rem",
      fontFamily: "Jost",
      fontWeight: "500",
    },
    "& .MuiTypography-h3": {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },  
      color: "#FFFFFF !important",
      background: "#61A72A",
      padding: "0rem 0.5rem",
      borderRadius: "0.1875rem",
      fontFamily: "Jost",
      fontSize: "0.9rem",
      marginRight: "0.6rem",
    },
  },
  unverifiedbuyer: {
    display: "none",
  },
  verifiedbuyer: {
    position: "relative",
    width: "53%",
    background: "#FFFFFF",
    borderRadius: "0.8rem",
    padding: "0.2rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "62%",
      margin: "1rem 0",
    },
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10)",
    "& .MuiTypography-h4": {
      color: "#252525",
      fontSize: "0.875rem !important",
      fontFamily: "Jost",
      fontWeight: "400",
      marginRight: "0",
      textAlign: "center",
    },
  },
  verifiedsvg: {
    position: "absolute",
    left: "-13px",
    top: "-2px",
    [theme.breakpoints.down("xs")]: {
      left: "-18px",
    },
  },
  username: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-h5": {
      color: "#252525",
      fontSize: "1.125rem",
      fontFamily: "Jost",
      fontWeight: "400",
      width: "60%",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
  username_mobileview: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      fontSize: "1.125rem",
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#252525",
    },
  },
  like_dislike_container: {
    marginTop: "2rem",
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  likedislikebtn: {
    display: "flex",
    alignItems: "center",
  },
  bluebtn: {
    width: "20%",
    background: "linear-gradient(90deg, #141E30 16.46%, #243B55 100%)",
    color: "white",
    padding: "0.5rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textareacontainer: {
    marginBottom: "2rem",
  },
  writeandreview: {
    marginTop: "3rem",
    "& .MuiRating-root": {
      margin: "1.5rem 0",
      [theme.breakpoints.down("xs")]: {
        margin: "1.5rem auto",
        width: "100%",
        justifyContent: "center",
      },
    },
  },
}));

function ReviewSection() {
  const classes = useStyles();

  const [like, setLike] = React.useState(false);
  const [dislike, setDisike] = React.useState(false);

  return (
    <div className={classes.reviewcontainer}>
      <div className={classes.reviewcontentmain}>
        <div className={classes.reviewcontent}>
          <div className={classes.individualreview}>
            <div className={classes.individualinner}>
              <Typography variant="h4">Quality</Typography>
              <div className={classes.ratings}>
                <Rating name="disabled" value={4.5} precision={0.5} disabled />
                <Typography variant="h6">4.3 / 5</Typography>
              </div>
            </div>
            <div className={classes.individualinner}>
              <Typography variant="h4">Price</Typography>
              <div className={classes.ratings}>
                <Rating name="disabled" value={4.5} precision={0.5} disabled />
                <Typography variant="h6">4.3 / 5</Typography>
              </div>
            </div>
            <div className={classes.individualinner}>
              <Typography variant="h4">Service</Typography>
              <div className={classes.ratings}>
                <Rating name="disabled" value={4.5} precision={0.5} disabled />
                <Typography variant="h6">4.3 / 5</Typography>
              </div>
            </div>
          </div>
          <div className={classes.overallreview}>
            <div className={classes.overallreviewinner1}>
              <Typography variant="h4">4.7</Typography>
              <Typography variant="h4">Overall</Typography>
            </div>
            <div className={classes.overallreviewinner2}>
              <Rating name="disabled" value={4.5} precision={0.5} disabled />
              <Typography variant="h6">Review By 5K students</Typography>
            </div>
          </div>
        </div>
        <div>
          {UserReviewData.map((data, index)=>(
          <div className={`${classes.customerreviewmain} ${index % 2 === 0 ? classes.customerreview_bg_grey : classes.customerreview_bg_white}`}>
            <div className={classes.customerreviewinner1}>
              <div className={classes.buyeravtar}>
                <Avatar alt="user" src={data.userlogo} />
              </div>
              <div className={classes.buyerreview}>
                <div className={classes.username}>
                  <Typography variant="h5">{data.name}</Typography>
                  <div className={data.verified ? classes.verifiedbuyer : classes.unverifiedbuyer}>
                    <VerifiedBuyerSvg className={classes.verifiedsvg} />
                    <Typography variant="h4">Verified Buyer</Typography>
                  </div>
                </div>
                <Rating name="disabled" value={data.rating} precision={0.5} disabled />
                <div className={classes.comments}>
                  <Typography variant="caption">{data.rating}</Typography>
                  <Typography variant="h6">{data.comment}</Typography>
                </div>
              </div>
            </div>
            <div className={classes.customerreviewinner2}>
                <Typography className={classes.username_mobileview} variant="h5">{data.name}</Typography>
                <div className={classes.comments_mobileview}>
                  <Typography variant="h3">{data.rating}</Typography>
                  <Typography variant="h6">{data.comment}</Typography>
                </div>
              <Typography variant="caption">Review on 2 July 2023</Typography>
              <div className={classes.like_dislike_container}>
                <div className={classes.likedislikebtn}>
                  <ToggleButton
                    value="check"
                    selected={like}
                    onChange={() => {
                      setLike(!like);
                      setDisike(false);
                    }}
                  >
                    <AiOutlineLike />
                  </ToggleButton>
                  <Typography variant="h6">30</Typography>
                </div>
                <div className={classes.likedislikebtn}>
                  <ToggleButton
                    value="check"
                    selected={dislike}
                    onChange={() => {
                      setDisike(!dislike);
                      setLike(false);
                    }}
                  >
                    <AiOutlineDislike />
                  </ToggleButton>
                  <Typography variant="h6">6</Typography>
                </div>
              </div>
            </div>
          </div>
          ))}
        </div>
        <div className={classes.writeandreview}>
          <Typography variant="h3">Review This Product</Typography>
          <Rating name="half-rating" defaultValue={0} precision={0.5} />
          <FormControl>
            <div className={classes.textareacontainer}>
              <TextField
                id="outlined-textarea"
                placeholder="Type Here"
                multiline
                rows={6}
                variant="outlined"
              />
            </div>
            <Button className={classes.bluebtn} variant="contained">
              Submit
            </Button>
          </FormControl>
        </div>
      </div>
      <div className={classes.adspng}>
        <img src={AdsPng} alt="ads" />
      </div>
    </div>
  );
}

export default ReviewSection;
