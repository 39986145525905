import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ReviewSection from "./reviewsection";
import RelatedBooksSection from "./relatedbookssection";
import ExclusiveBooksSection from "../../Home/exclusivebooks/exclusivebooksection";
// import {ReactComponent as MessageIcon} from "../../image/singlepagedescriptionvector/msgicon.svg";
// import {ReactComponent as NotepadeIcon} from "../../image/singlepagedescriptionvector/notepadicon.svg";
import MessageIcon from "../../image/singlepagedescriptionvector/msgicon.png";
import NotepadeIcon from "../../image/singlepagedescriptionvector/notepadicon.png";
import UnlockIcon from "../../image/singlepagedescriptionvector/unlockvector.png";
import LockIcon from "../../image/singlepagedescriptionvector/lockvector.png";
import PdfIcon from "../../image/singlepagedescriptionvector/pdfvector.png";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import NotesInSite from "./NotesInSite";
import NotesInSiteSection from "./NotesInSite";
import NotesInSiteSectionMobile from "./NotesInSiteMobile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3rem 2rem",
    flexGrow: 1,

    [theme.breakpoints.up("xs")]: {
      margin: "2rem 0rem",
    },

    "& .MuiTab-root": {
      padding: "10px 12px",
    },
    "& .MuiRating-root": {
      marginRight: "1rem",
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        padding: "0rem 1rem",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 93.96%)",
      color: "white",
      borderRadius: "5px 5px 0 0",
      minHeight: "40px",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #C6C6C6",
    },
    "& .MuiTab-textColorInherit": {
      fontFamily: "Playfair Display",
      fontWeight: "700",
      color: "#676767",
    },
    "& .MuiTypography-h3": {
      color: "#252525",
      fontSize: "1.625rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: "1.125rem",
      },
    },
    "& .MuiTypography-h4": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "500",
      marginRight: "0.5rem",
    },
    "& .MuiTypography-h6": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      width: "86%",
    },
    "& .MuiRating-root.Mui-disabled": {
      opacity: "1",
    },
    "& .MuiAvatar-root": {
      width: "65px",
      height: "65px",
    },
    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "none",
    },
    "& .MuiToggleButton-root": {
      color: "#BFBFBF",
      background: "none",
      padding: "0.5rem",
      border: "none",
    },
    "& .MuiToggleButton-label": {
      fontSize: "1.3rem",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-multiline": {
      width: "100%",
      border: "1px solid #DBDBDB",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "0px",
    },
    "& .MuiOutlinedInput-notchedOutline ": {
      borderColor: "transparent",
      borderRadius: "6px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTabs-root": {
        overflow: "scroll",
      },
      "& .MuiTabs-fixed": {
        overflow: "scroll",
      },
      "& .MuiTabs-scroller": {
        overflow: "scroll",
      },
    },
  },
  tabpannel: {
    marginTop: "2rem",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    "& .MuiBox-root-111": {
      padding: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 1rem",
      padding: "1rem 0.5rem",
      textAlign: "justify",
      "& .MuiBox-root-111": {
        padding: "0",
      },
    },
    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#676767",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
  tabpannel2: {
    marginTop: "2rem",
    padding: "1rem 2rem",
    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#676767",
      textTransform: "capitalize",
    },
  },
  ebookuploadingplancontent: {
    marginTop: "2rem",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RelatedItemsHead = [
    {
      id: 1,
      headingsmall: "Start practicing free quizzes online with MCQ questions.",
      headingmain: "Products Related To This Item",
    },
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none"
          aria-label="simple tabs example"
          variant="scrollable"
        >
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Notes In-Sites" {...a11yProps(1)} />
          <Tab label="Related Products" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel className={classes.tabpannel}>
        Certain words are printed in bold to help you locate them while
        answering some of these.Boredom indicates a mind at peace with itself; a
        blank canvas that can spark creativity and contemplation. It is likely
        to lead to considered action or engaging activity that the subject finds
        meaningful (as opposed to the meaningless activity used to “fill” empty
        time).Conversely, the intense restlessness mistaken for boredom in the
        social media age indicates a deficiency of mental wellness. It is linked
        to a higher incidence of depression and anxiety, as well as behaviours
        that we might consider maladaptive, says Dr. X, a professor at the
        University of Canada, and who has co-authored a book on the psychology
        of boredom.“The boredom-prone demonstrate higher levels of aggression
        and sensation-seeking, which can lead to unwanted outcomes,” he says. If
        we can recognise and try to avoid succumbing to the agitation and
        restlessness that so often accompanies feelings of boredom today, then
        we give ourselves a chance to choose more positive reactions to the
        experience.The response to boredom has changed over time. It used to be:
        let me find something fun to do. Now it’s a desperate grab for empty
        mental calories. “Today, when we feel bored we also feel threatened in
        some sense.
      </TabPanel>

      <TabPanel className={classes.tabpannel}>
        <div className={classes.tabpannelhead}>
          <Typography variant="h3">Notes In-Sites</Typography>
        </div>
        <div className={classes.ebookuploadingplancontent}>
          <NotesInSiteSection />       {/* for desktop view */}
          {/*<NotesInSiteSectionMobile />*/}  {/*for mobile view*/}
        </div>
      </TabPanel>

      <TabPanel className={classes.tabpannel2}>
        <ExclusiveBooksSection Heading={RelatedItemsHead} />
      </TabPanel>
    </div>
  );
}
