import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Grid, InputBase, useMediaQuery } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TrophyIcon from "../../image/latestseriesvector/trophyicon.png";
import TimerIcon from "../../image/latestseriesvector/TimerIcon.png";
import SSCCGLlogo from "../../image/catergoryVectoe/Group1351.svg";
import TestSeriesanimation from "../../Videos/LatestTestSeries.webm";
import Slider from "react-slick";
import LatestTestSeriesCard from "./LatestTestSeriesCard";
import LatestMockCard from "./LatestMockCard";
import LatestTestSeriesCardMobile from "./LatestTestSeriesCardMobile";
import BookImage from "../../image/booksvector/book1.png";
import {
  getMockTestseries,
  getTestSeriesData,
} from "../../../service/services";
import { useState } from "react";
import SearchInput from "./SearchInput";
import categoryContext from "../../../context/CategoryContext/CategoryContext";
import appContext from "../../../context/AppContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "none",
    marginTop: "2rem",

    "& .MuiBox-root": {
      // marginTop: "1rem",
      padding: "0px !important",
    },
    "& .MuiAppBar-root": {
      zIndex: "0",
      background: "transparent ",
      color: "#FFFFFF",
      padding: "0rem",
      display: "flex",
      boxShadow: "none",
      position: "relative",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        background: "linear-gradient(90deg, #141E30 0%, #1c2f45 100%)",
        padding: "1rem",
      },
      "& .MuiTab-wrapper": {
        textTransform: "capitalize",
        padding: "0rem 1rem !important",
      },
    },
    "& .MuiTab-textColorPrimary": {
      color: "white",
      borderRadius: "10rem",
      background: "#243951",
      margin: "0 10px",
      fontFamily: "Jost",
      "&:hover": {
        background: "#3D5A7C",
        color: "white",
      },
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      background:
        "linear-gradient(90deg, rgba(255,200,55,1) 0%, rgba(255,128,8,1) 100%)",
      color: "black",
      fontFamily: "Jost",
      "&:hover": {
        background:
          "linear-gradient(90deg, rgba(255,200,55,0.9) 0%, rgba(255,128,8,0.9) 100%)",
        color: "black",
      },
    },
    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },

    "& .slick-prev:before, .slick-next:before": {
      fontSize: "55px",
      borderRadius: "40px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      bottom: 0,
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0.5rem",
      display: "flex",
      height: "440px",
      justifyContent: "center",
      paddingTop: "0.5rem",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
    "& .slick-dots": {
      bottom: "-30px",
    },
  },

  search: {
    position: "relative",
    border: "1px white solid",
    marginRight: "1rem",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "30%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchIcon: {
    "& .MuiSvgIcon-root": {
      height: "100%",
      position: "absolute",
      top: 0,
      right: 15,
      pointerEvents: "none",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(2, 1),
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "38ch",
    },
  },
  imgaeSec: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchmobile: {
    background: "#FFF",
    width: "100%",
    marginBottom: "1rem",
    padding: "1rem 0rem",
    marginTop: -13,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%) !important",
    margin: "0 1rem",
    borderRadius: 7,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.60) !important",
    flex: 1,
    fontFamily: "Jost",
    textTransform: "capitalize",
  },
  iconButton: {
    color: "#C3C3C3",
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  // testSeriescardcont: {
  //   padding: "1rem",
  // },
  // testseriescardsection: {
  //   fontFamily: "'Jost', sans-serif",

  //   "& .MuiButton-fullWidth": {
  //     width: "100%",
  //     borderRadius: "0",
  //     padding: "1rem",
  //     background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
  //   },
  //   "& .MuiTypography-h6": {
  //     fontSize: "1.375rem",
  //     fontFamily: "'Jost', sans-serif",
  //     fontWeight: "500",
  //     lineHeight: "2.0625rem",
  //     marginBottom: "1.60rem",
  //   },
  //   "& .MuiTypography-subtitle2": {
  //     fontSize: "1rem",
  //     fontFamily: "'Jost', sans-serif",
  //     fontWeight: "500",
  //     display: "flex",
  //     alignItems: "center",
  //     marginBottom: "1.60rem",
  //     "& img": {
  //       marginRight: "0.63rem",
  //     },
  //   },
  //   "& .MuiTypography-body1": {
  //     fontSize: "1.125rem",
  //     background: "#FFF5DE",
  //     fontFamily: "'Jost', sans-serif",
  //     fontWeight: "400",
  //     display: "flex",
  //     width: "30%",
  //     padding: "0.3rem",
  //     borderRadius: "0.3rem",
  //     alignItems: "center",
  //     "& img": {
  //       marginRight: "0.63rem",
  //     },
  //   },
  // },
  // testSerieshead: {
  //   width: "70%",
  //   fontSize: "1rem",
  //   display: "flex",
  //   flexDirection: "row",
  //   padding: "0.5rem",
  //   borderRadius: "0.3rem",
  //   marginBottom: "1.60rem",
  //   background:
  //     "linear-gradient(90deg, rgba(255,128,8,0.2) 0%, rgba(255,200,55,0.2) 100%)",
  //   alignItems: "center",
  //   "& img": {
  //     marginRight: "0.63rem",
  //   },
  // },
  // paper: {
  //   margin: "5%",
  //   width: '18rem',
  //   "&:hover": {
  //     boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
  //     cursor: "pointer",
  //     "& .MuiButton-root": {
  //       background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
  //     },
  //   },
  // },
  searchpersonvector: {
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& video::-webkit-media-controls": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-enclosure": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-panel": {
      display: "none !important",
    },
  },
  searchanimation: {
    position: "absolute",
    width: "20%",
    right: "10rem",
    bottom: "0.5rem",
  },
  marginTop: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
    },
  },
}));

export default function LatestSeriesSectionContent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const videoRef = useRef(null);
  const CategoryContext = useContext(categoryContext);
  const AppContext = useContext(appContext);
  const [selectedExam, setSelectedExam] = useState("");
  const [search, setSearch] = useState({});
  const allExams = CategoryContext?.megaMenu?.[0]?.["categories"]?.reduce(
    (exams, category) => {
      category.subCategories.forEach((subCategory) => {
        subCategory.exams.forEach((exam) => {
          exams.push({
            category_id: category.id,
            category_name: category.category_name,
            subCategory_id: subCategory.id,
            subCategory_name: subCategory.sub_category_name,
            ...exam,
          });
        });
      });
      return exams;
    },
    []
  );

  const handleExamClick = (e , value) => {
    if (value && value.id) {
      setSearch(value);
      setSelectedExam(value.id);
    } else {
      setSearch({});
      setSelectedExam("");
    }
  };

console.log('setSelectedExam' , selectedExam)
  const initialSlidesToShow = window.innerWidth < 480 ? 1 : 4;

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: initialSlidesToShow,
    slidesToScroll: initialSlidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow: newSlidesToShow,
        slidesToScroll: newSlidesToShow,
      }));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    };

    const playVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.play().catch((error) => {
          console.error("Error playing video:", error.message);
        });
      }
    };

    const pauseVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.pause();
      }
    };

    const videoObserver = new IntersectionObserver(handleIntersection, options);

    const video = videoRef.current;
    if (video) {
      videoObserver.observe(video);
    }

    return () => {
      if (video) {
        videoObserver.unobserve(video);
      }
    };
  }, []);

  const testSeriesData = [
    { id: 0, name: "Mock Tests" },
    { id: 1, name: "Free Mocks" },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "15px" : "15px",
        width: isActive ? "15px" : "15px",
        backgroundColor: isActive ? "#FFA046" : "#FFDF7A",
        borderRadius: "100%",
      }}
    ></span>
  );
  const [testSeries, setTestSeries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTestSeriesData(selectedExam);
        setTestSeries(response.data?.testseries);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [selectedExam]);

  const [mockTest, setMockTest] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getMockTestseries(selectedExam);
        setMockTest(response.data?.testseries);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [selectedExam]);
  return (
    <div className={classes.marginTop}>
      <div className={classes.imgaeSec}>
        <img src={SSCCGLlogo} />
      </div>

      <div className={classes.searchmobile}>
        {/* <Paper component="form" className={classes.root2}>
          <InputBase
            className={classes.input}
            placeholder="Search Your Exams"
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper> */}
        <SearchInput
        allExams={allExams}
        search={search}
        handleExamClick={handleExamClick}
      />
      </div>
    
      <div className={classes.root}>
        <AppBar position="static">
          <div className={classes.searchpersonvector}>
            {/* <img src={searchpersonGif} alt="img"  width="100%"/> */}
            <video
              ref={videoRef}
              className={classes.searchanimation}
              autoPlay={true}
              loop
              muted
              playsInline
              controls={false}
              width="100%"
              height="auto"
            >
              <source src={TestSeriesanimation} type="video/webm" />
            </video>
            {/* <video className={classes.searchanimation} autoPlay={false} loop muted controls="false">
              <source src={TestSeriesanimation} type="video/webm" width="100%" />
            </video> */}
          </div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="none"
            textColor="primary"
            aria-label="simple tabs example"
          >
            {testSeriesData.map((item, key) => (
              <Tab label={item?.name} {...a11yProps(item.id)} />
            ))}
          </Tabs>
          <div className={classes.search}>
            {/* <InputBase
              placeholder="SEARCH YOUR EXAMS"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{ position: "relative" }}
              inputProps={{ "aria-label": "search" }}
            />
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div> */}
            <SearchInput
        allExams={allExams}
        search={search}
        handleExamClick={handleExamClick}
      />
          </div>
        </AppBar>
        {/* <Typography variant="h6" style={{ color: "#FF3939", fontFamily: "Jost", fontSize: 14, textAlign: "right", margin: "2rem  .5rem 0 0 " }}>View All</Typography> */}

        <TabPanel value={value} index={0}>
          <Slider {...sliderSettings}>
            {testSeries?.map((data, indx) => (
              <div className={classes.testseriescardsection} key={indx}>
                <LatestTestSeriesCard data={data} />
              </div>
            ))}
          </Slider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Slider {...sliderSettings}>
            {mockTest.map((data, indx) => (
              <div className={classes.testseriescardsection}>
                <LatestMockCard
                  data={data}
                  bundleId={data.test_series_bundle_id}
                />
              </div>
            ))}
          </Slider>
        </TabPanel>
      </div>
    </div>
  );
}
