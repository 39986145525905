import React, { useEffect, useState } from "react";
import ShoppingCartContentMobile from "../shoppingcart/ShoppingCartContentMobile";
import PageHeading from "../pageheading/pageheading";
import ShoppingCartContent from "../shoppingcart/ShoppingCartContent";
import Subcategory from "../practicecomponent/subcategory";

import appContext from "../../context/AppContext";
import { useContext } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import SubCategoryNotification from "./subCategoryNotification";
import JobNotification from "./jobNotification";
import DownloadApp from "./downloadApp";
import QuiklinksNotification from "./quiklinksNotification";
import CardsectionNotification from "./cardsectionNotification";
import HeaderNotification from "./headerNotification";
import ImportantDates from "./importantDates";
import ApplicationFee from "./applicationFee";
import AgeLimit from "./ageLimit";
import Qualification from "./qualification";
import VacancyDetails from "./vacancyDetails";
import ImportantLinks from "./importantLinks";
import AdmitCard from "./AdmitCard";
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { getSingalPageNotifications } from "../../service/services";

const useStyles = makeStyles((theme) => ({
  pageheadingmain: {
    width: "100%",
    marginBottom: "15rem",
    marginTop: "2rem",

    [theme.breakpoints.down("sm")]: {
      position: "unset",
      padding: 0,
    },
  },
  pageheadingmaininner: {
    margin: "0 auto",
    paddingBottom: "1.5rem",
    paddingTop: "1rem",
    // backgroundImage: `url(${PageHeadingBg})`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem 1.5rem 1rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1.5625rem",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 6,
      },
    },
    "& .MuiBreadcrumbs-ol": {
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorInherit": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "#FF8F12",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
    },
  },
  pageheading: {
    padding: "1rem 0 2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
    },
  },
  pageheadhr: {
    margin: "0",
    width: "6rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  quizzcontentcontainer: {
    maxWidth: "1250px",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "15rem",
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "3.8rem",
    fontFamily: "'Playfair Display', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      fontFamily: "'Playfair Display', serif !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  w25: {
    width: "24%",
    [theme.breakpoints.down("xs")]: {
      display:'none'
    },
  },
  w74: {
    width: "74%",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
 


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Notification = () => {
  const classes = useStyles();
  
  let mql = window.matchMedia("(max-width: 650px)");
  const param=useParams();
  const [notData,setNotData]=useState([]);
  const id = param.a;
  const index = param && param.index;
  console.log(index);
  // const [value, setValue] = useState(
  //   param.index !== undefined && param.index !== "0" ? parseInt(param.index, 10) : 0
  // );
  // useEffect(() => {
  //   setValue(param.index !== undefined ? parseInt(param.index, 10) : 0);
  // }, [param.index]);
  const [value,setValue]=useState(param.index);
  const [isFirstTime, setIsFirstTime] = useState(true);
  useEffect(()=>{
setValue(param.index);
  },[param.index])
  
  // useEffect(() => {
  //   if (isFirstTime) {
  //     setValue(param.a !== undefined ? parseInt(param.index, 10) : 0);
  //     setIsFirstTime(false);
  //   } else {
  //     setValue(value);
  //   }
  // }, [param.index, value, isFirstTime]);
  
  const [innerClicked, setInnerClicked] = useState("");
  
  const getInnerClicked = (innerValue) => {
    setInnerClicked(innerValue);
  };
  
 
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSingalPageNotifications(id); // Use the function from the service
        setNotData(response.data.notification);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [id]); 

  const Heading = [
    {
      id: notData?.id,
      headingsmall: notData?.requirement_board,
      headingmain: notData?.name,
      breadcrumb: `${notData?.category?.category_name}`,
    },
  ];
  const Notification = [
    {
        id: 1,
        subCategories: "Latest Job"
    },
    {
        id: 2,
        subCategories: "Result"
    },
    {
        id: 3,
        subCategories: "Admit Card"
    },
    {
        id: 4,
        subCategories: "Admit Card"
    },
    {
        id: 5,
        subCategories: "Admit Card"
    },
    {
        id: 6,
        subCategories: "Admit Card"
    },
    {
        id: 7,
        subCategories: "Admit Card"
    },  
];
   
  return (
    <>
      <PageHeading Heading={Heading} notData={notData}/>
      <div className={!mql.matches && classes.pageheadingmain}>
          <AdmitCard Notification={Notification}
          Notification1={notData}
          value={ value}
          setValue={setValue}
          a11yProps={a11yProps}
          />
      </div>
    </>
  );
};
export default Notification;
