import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import SSCLogo from "../../image/latestseriesvector/SSCCGLlogo.png";
import { Rating } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  card_container: {
    padding: "1rem 1rem 0.3rem 1rem",
    width: "300px",
    height: "397px",
    background: "#FFF",
    margin: "10px 0",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    transition: "all ease 0.2s",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
    // height: "450px",
    "& .MuiTypography-h4": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      textTransform: "uppercase",
    },
    "& .MuiTypography-h3": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
      color: "#FF3939",
      textTransform: "uppercase",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "uppercase",
    },
    "& .MuiTypography-caption": {
        fontFamily: "'Jost', sans-serif",
        fontSize: "0.6875rem",
        fontWeight: "500",
        color: "#FF3939",
        textTransform: "uppercase",
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "72%",
      marginBottom: 0,
    },
  },
  containerinner1: {
    background: "#FFF7E4",
    borderRadius: "7px",
    paddingBottom: "1rem",
  },
  imageandtitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
  },
  cardicon: {
    background: "#FFF",
    boxShadow: "0px 2px 35px rgba(255, 232, 192, 0.80)",
    borderRadius: "65px",
    padding: "1.2rem 1rem",
    position: "absolute",
    left: "1.5rem",
    top: "8px",
  },
  examtitle: {
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    padding: "0.5rem 1.3rem",
    margin: "1.5rem 0 0 4rem",
    textAlign: "right",
    width: "61%"
  },
  cardsubtitle: {
    display: "flex",
    marginTop: "0.8rem",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "0 1.5rem",
  },
  cardtitle: {
    marginTop: "0.3rem",
    padding: "0 1.5rem",
    textAlign: "right",
  },
  totaltests: {
    marginTop: "1.5rem",
    padding: "0 1.5rem",
    textAlign: "center",
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.8rem",
      fontWeight: "500",
      color: "#000",
      textTransform: "uppercase",
    },
    "& .MuiTypography-h3": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontWeight: "600",
      color: "#FF3939",
      textTransform: "uppercase",
      marginTop: "0.28rem",
    },
  },
  unlockexamcontainer: {
    display: "flex",
    marginTop: "1.2rem",
    "& .MuiTypography-h5": {
      width: "60%",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "capitalize",
    },
  },
  ratingandprice: {
    marginTop: "0.8rem",
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontSize: "1.125rem",
      fontWeight: "600",
      color: "#212121",
      textTransform: "uppercase",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontWeight: "400",
      color: "#626262",
    },
  },
  greencustombtn: {
    background: "#5ABF64",
    color: "white",
    padding: "0rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
  },
  textred: {
    color: "#FF3939",
    fontSize: "2rem",
    fontFamily: "Jost",
    fontWeight: "600",
    marginRight: "0.5rem",
  },
  offertext: {
    marginLeft: "0.3rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.875rem",
    fontWeight: "400",
  },
}));

function LatestTestSeriesCardMock() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.card_container}>
        <div className={classes.containerinner1}>
          <div className={classes.imageandtitle}>
            <div className={classes.cardicon}>
              <img src={SSCLogo} alt="cardimg" />
            </div>
            <div className={classes.examtitle}>
              <Typography variant="h5">SSC: direct & indirect speech</Typography>
            </div>
          </div>
          <div className={classes.cardsubtitle}>
            <Typography variant="h3">BILINGUAL</Typography>
            <Typography variant="caption">VIDEO SOLUTIONS</Typography>
          </div>
          <div className={classes.cardtitle}>
              <Typography variant="h4">TEST SERIES</Typography>
          </div>
          <div className={classes.totaltests}>
            <Typography variant="caption">clerk prelims</Typography>
            <Typography variant="h3">100+ total test</Typography>
          </div>
        </div>
        <div className={classes.containerinner2}>
          <div className={classes.unlockexamcontainer}>
            <Typography variant="h5">
              Unlock SSC: Direct & Indirect Speech
            </Typography>
            <Button className={classes.greencustombtn}>buy now</Button>
          </div>
          <div className={classes.ratingandprice}>
            <Box component="fieldset" mb={3} borderColor="transparent">
              <Rating name="simple-controlled" value={4} readOnly />
              <Typography variant="h6">4.3</Typography>
              <Typography variant="caption">(634)</Typography>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              <span className={classes.textred}>
                <span style={{ fontSize: "1.2rem", verticalAlign: "top" }}>
                  ₹
                </span>
                50.00
              </span>
              <Typography variant="caption">
                MRP:<del>₹80.00</del>
              </Typography>
              <span className={classes.offertext}>(30% off)</span>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestTestSeriesCardMock;
