import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import SellerImage from "../image/seller/sellerimage.png";
import Divider from "@material-ui/core/Divider";
import {
  Button,
  FormControl,
  TextField,
  Typography,
  Card,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "4rem",
    backgroundColor: "#FFF5DF",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
  },
  container: {
    margin: "0 auto",
    padding: "4rem 0",
    maxWidth: "1250px",
  },
  innerContainer: {
    display: "flex",
    backgroundColor: "#FFF",
    marginTop: "3rem",
    padding: "0 4rem",
    boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "2.1rem 1rem",
      margin: "1rem",
      marginTop: "3rem",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "569px",
    },
    "& .MuiTypography-h2": {
      color: "#16283F",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.875rem",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "2.25rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#333",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "1.5rem",
      marginLeft: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiTypography-caption": {
      color: "#333",
      fontFamily: "'Jost', serif !important",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "1.5rem",
      margin: "1.5rem 0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      transition: "none",
      border: "none",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline": {
      border: "2px solid #E5E5E5",
      borderRadius: "1.875rem",
      padding: "0.6rem 2rem",
      fontFamily: "'Jost', sans-serif",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
  form: {
    width: "50%",
    margin: "2rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
    },
  },
  input_sm: {
    width: "100%",
    marginBottom: "1rem",
  },
  email: {
    width: "100%",
    marginBottom: "1rem",
  },
  textBelowButton: {
    textAlign: "center",
    fontWeight: "400",
    fontSize: "16px",
    fontFamily: "Jost",
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  createAccountBtn: {
    padding: "0.6rem",
    fontWeight: "500",
    fontSize: "18px",
    fontFamily: "Inter",
    borderRadius: "5rem",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(20, 30, 48, 1)",
    "&:hover": {
      backgroundColor: "rgba(20, 30, 48, 1)",
      boxShadow: "none",
    },
  },
  signInBtn: {
    padding: "0.6rem",
    borderRadius: "5rem",
    fontWeight: "500",
    fontFamily: "Inter",
    fontSize: "18px",
    backgroundColor: "rgba(255, 179, 71, 1)",
    "&:hover": {
      backgroundColor: "rgba(255, 179, 71, 1)",
      boxShadow: "none",
    },
  },
  imagesection: {
    width: "48%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& img": {
      width: "80%",
      transform: "scale(1.2)",
      marginBottom: "47px",
    },
  },
  divider: {
    margin: "5rem 4rem 5rem 3rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  privacyLink: {
    color: "orange",
    textDecoration: "none",
  },
  bluebtn: {
    "& .MuiButton-fullWidth": {
      padding: "0.6rem",
      borderRadius: "5rem",
      boxShadow: "none",
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
      color: "white"
    },
  },
  orangebtn: {
    "& .MuiButton-fullWidth": {
      padding: "0.6rem",
      borderRadius: "5rem",
      boxShadow: "none",
      background: "#FFB347",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "'Jost', sans-serif",
    },
  },
}));

function SellerRegistration() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Join us and make a difference.",
      headingmain: "Register As a Seller",
    },
  ];

  const [openSignIn, setOpenSignIn] = React.useState(false);

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <Card className={classes.innerContainer}>
        {openSignIn ?
          <div className={classes.form}>
            <FormControl sx={{ m: 1, width: "25ch" }}>
              <Typography variant="h2" display="block" gutterBottom>
                * Your Basic Information
              </Typography>
              <div className={classes.firstandlastname}>
                <div className={classes.input_sm}>
                  <Typography variant="h6" gutterBottom>
                    Full Name
                  </Typography>
                  <TextField
                    id="fulltname"
                    type="text"
                    placeholder="Andrew"
                  />
                </div>
                <div className={classes.input_sm}>
                  <Typography variant="h6" gutterBottom>
                    Phone Number 
                  </Typography>
                  <TextField
                    id="phonenumber"
                    type="text"
                    placeholder="(+91) 8985-550- 114"
                  />
                </div>
              </div>
              <div className={classes.email}>
                <Typography variant="h6" gutterBottom>
                  E-Mail Address 
                </Typography>
                <TextField
                  id="email"
                  type="email"
                  placeholder="Andrewfate00231@gmail.com"
                />
              </div>
              <div className={classes.bluebtn}>
                <Button variant="contained" size="medium" fullWidth >Create Account</Button>
              </div>
              <Typography variant="caption">Already have an account, then Sign In</Typography>
              <div className={classes.orangebtn}>
                <Button onClick={()=>{setOpenSignIn(!openSignIn)}} variant="contained" size="medium" fullWidth >SIGN IN</Button>
              </div>
            </FormControl>
          </div>
          :
          <div className={classes.form}>
            <FormControl sx={{ m: 1, width: "25ch" }}>
              <Typography variant="h2" display="block" gutterBottom>
                * Go into you Account
              </Typography>
              <div className={classes.email}>
                <Typography variant="h6" gutterBottom>
                  E-Mail Address 
                </Typography>
                <TextField
                  id="email"
                  type="email"
                  placeholder="Andrewfate00231@gmail.com"
                />
              </div>
              <div className={classes.email}>
                <Typography variant="h6" gutterBottom>
                  Enter Password
                </Typography>
                <TextField
                  id="password"
                  type="password"
                  placeholder="***********"
                />
              </div>
              <div className={classes.orangebtn}>
                <Button variant="contained" size="medium" fullWidth >SIGN IN</Button>
              </div>
              <Typography variant="caption">By signing-in, you agree to the ParikshaDo.com <Link to="/privacypolicy" style={{color: '#FFA900'}}>Privacy Policy</Link> and <Link to="/termsconditions" style={{color: '#FFA900'}}>Terms & conditions.</Link></Typography>
              <div className={classes.bluebtn}>
                <Button onClick={()=>{setOpenSignIn(!openSignIn)}}  variant="contained" size="medium" fullWidth >Create Account</Button>
              </div>
            </FormControl>
          </div>
          }
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <div className={classes.imagesection}>
            <img src={SellerImage} alt="img" />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SellerRegistration;
