import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTableView from "../../CustomComponent/TableView";
import {
  getMydashboardMyPurchase,
  getMypurchase,
} from "../../../service/services";
import OrdersErrorPage from "../ErrorPage/OrdersErrorPage";
import NoDataFound from "../ErrorPage/NoDataFound";
import FeedbackTableMobile from "./FeedbackTableMobile";
import { getFeedBack } from "../../../service/services";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0rem 2rem 1rem 2rem ",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    minHeight: "85vh",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.8rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headname: {
    display: "flex",
    alignItems: "center",
    // marginTop: "1rem",
    width: "47%",
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "30%",
    marginTop: "0.2em",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  attempted: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#323232",
    },
  },
  activebtn: {
    "&.MuiButton-root": {
      color: "#fff",
      background: "#243B55",
      textTransform: "capitalize",
      padding: "0.3rem 1rem",
      width: "45%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
  activebtn1: {
    "&.MuiButton-root": {
      color: "#fff",
      background: "lightblue" /* Change background color to light blue */,
      textTransform: "capitalize",
      padding: "0.3rem 1rem",
      width: "45%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
}));

function Feedback(props) {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const setOnViewClicked = props.setOnViewClicked;
  let mql = window.matchMedia("(max-width: 650px)");
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData(token) {
      try {
        const response = await getFeedBack(token);
        console.log("API Response:", response.data);
        setData(response.data.feedback);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData(token);
  }, [token]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    });
  };
  const rows = data?.map((value, index) => ({
    id: value.id,
    srno: index + 1,
    data: value,
    name: value.title ? value.title : "N/A" ,
    subject: value.subject?.subject_name ? value.subject?.subject_name : "N/A",
    teacher: value.teacher?.full_name ? value.teacher?.full_name : "N/A",
    schedule_date: value.schedule_date
      ? formatDate(value.schedule_date)
      : "N/A",
    status: value.status ? value.status : "N/A",
  }));

  const columns = [
    {
      field: "srno",
      headerName: "S.No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 70,
      // flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 70,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "subject",
      headerName: "Subject",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "teacher",
      headerName: "Teacher",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "schedule_date",
      headerName: "Schedule Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return cellValues.row.status === "given" ? (
          <Button disabled className={classes.activebtn1}>
            Given
          </Button>
        ) : (
          <Button
            onClick={() => {
              setOnViewClicked("feedbackinner", cellValues.row.data);
            }}
            className={classes.activebtn}
          >
            Start
          </Button>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <Typography gutterBottom className={classes.heading}>Feedback</Typography>
        </div>
      </div>
      {!mql.matches ? (
        rows.length !== 0 ? (
        <CustomTableView
          columns={columns}
          rows={rows}
          section={"feedbacktable"}
          setOnViewClicked={setOnViewClicked}
        />
         ) : (
          <NoDataFound />
        )
      ) : rows.length !== 0 ? (
        rows.map((data, index) => (
          <FeedbackTableMobile
            data={data}
            setOnViewClicked={setOnViewClicked}
          />
        ))
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}

export default Feedback;
