import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as LockIconFree } from "../../../image/ebooksvector/lockiconfree.svg";
import { ReactComponent as LockIconPaid } from "../../../image/ebooksvector/lockiconpaid.svg";
import { ReactComponent as LinkIcon } from "../../../image/notesvector/linkicon.svg";
import { ReactComponent as PDFIcon } from "../../../image/notesvector/pdficon.svg";
import { ReactComponent as DocumentIcon } from "../../../image/notesvector/documenticon.svg";
import { ReactComponent as ImageIcon } from "../../../image/notesvector/imageicon.svg";
import { ReactComponent as VideoIcon } from "../../../image/notesvector/videoicon.svg";
import { ReactComponent as AudioIcon } from "../../../image/notesvector/audioicon.svg";
import { Backdrop, Modal } from "@material-ui/core";
import PdfViewer from "../../../dashboard/myStudyMaterial/PdfViewer";
import PopupContext from "../../../../context/PopupContext/PopupContext";
import AppContext from "../../../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  cardcontainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    marginTop: "1rem",
    marginRight: "1rem",
    width: "23%",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0rem",
      width: "100%",
    },
    "& .MuiTypography-h5": {
      marginLeft: "0.5rem",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      color: "#000000",
    },
  },
  cardhover: {
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0px 10px 10px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },
  cardcontent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgandname: {
    width: "85%",
    display: "flex",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function NoteUploadPlan({ data }) {
  const classes = useStyles();
  console.log("notedata", data.contents);

  const { handleloginOpenclose } = useContext(PopupContext);
  const { isLoggedIn, token } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [size, setSize] = useState("smallscreen");

  const handleOpenclose = (url, rowId) => {
    setOpen(!open);
    setPdfUrl(url);
    setSelectedRowId(rowId);
    setSize("smallscreen");
  };
  return (
    <div className={classes.cardcontainer}>
      {data?.contents.map((data, mockIndex) => (
        <Card
          className={`${classes.root} ${
            data?.payment_status === "free" && classes.cardhover
          }`}
          onClick={() => {
            if (isLoggedIn && token) {
              if (data?.payment_status === "free") {
                if (data?.file_format === "pdf") {
                  handleOpenclose(data?.content_path, data?.id);
                }
              }
            } else {
              handleloginOpenclose();
            }
          }}
        >
          <CardContent className={classes.cardcontent}>
            <div className={classes.imgandname}>
              {data?.payment_status === "paid" ? (
                <LockIconPaid />
              ) : (
                <LockIconFree />
              )}
              <Typography variant="h5" component="h2">
                {data?.content_heading}
              </Typography>
            </div>
            {data?.payment_status === "free" &&
              data?.file_format === "link" && <LinkIcon />}
            {data?.payment_status === "free" && data?.file_format === "pdf" && (
              <PDFIcon />
            )}
            {data?.payment_status === "free" && data?.file_format === "doc" && (
              <DocumentIcon />
            )}
            {data?.payment_status === "free" &&
              data?.file_format === "image" && <ImageIcon />}
            {data?.payment_status === "free" &&
              data?.file_format === "video" && <VideoIcon />}
            {data?.payment_status === "free" &&
              data?.file_format === "audio" && <AudioIcon />}
          </CardContent>
        </Card>
      ))}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleOpenclose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <PdfViewer
          selectedRowId={selectedRowId}
          pdfUrl={pdfUrl}
          handleOpenclose={handleOpenclose}
          noteId={data?.id}
          size={size}
          setSize={setSize}
        />
      </Modal>
    </div>
  );
}
