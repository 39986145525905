import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Fade, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import "react-toastify/dist/ReactToastify.css";
import ProductDiscription from "./productdiscription";
import CustomerDetail from "./customerdetail";
import BillingDetails from "./BillingDetails";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { getMydashboardMySingalPurchase } from "../../../service/services";
import ProductDescripMobile from "./ProductDescripMobile";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    "& .MuiTypography-h6": {
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    "& .MuiTypography-h5": {
      color: "#000",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    "& .MuiTypography-h4": {
      color: "#545454",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  itemdetail: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
    "& .MuiTypography-h2": {
      color: "#313866",

      fontFamily: "Inter",
      fontSize: "1.4rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
  },
  dashedborder: {
    borderRadius: "5px dashed",
    border: "1px solid #D4D4D4",
    borderStyle: "dashed",
    background: "#FFF",
    boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.08)",
  },
  closebtn: {
    position: "absolute",
    top: "-16px",
    right: "-18px",
    padding: "0px !important",
    backgroundColor: "#313866",
    height: "35px",
    minWidth: "35px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#313866",
    },
  },
  closeicon: {
    fill: "#D9D9D9",
    width: "25px",
    height: "25px",
  },
  dateandtime: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
    },
  },
  productionplusbothbox: {
    display: "flex",
    width: "100%",
    marginTop: "2rem",
    height: "78vh",
    overflow: "scroll",
    "&::-webkit-scrollbar ": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  CustomerDetailplusbilling: {
    width: "43%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  dflex: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  btn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "10px",
    fontWeight: "500",
    color: "white",
    borderRadius: "0.4rem",
    "&:hover": {
      transition: "200ms ease-in",
      background: "linear-gradient(270deg, #FFB36B 0%, #FFD57A 100%);",
      color: "#000",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.6rem",
    },
  },
  productiondescriptionmobile: {
    padding: "0 0.5rem",
    paddingBottom: "1rem",
    borderRadius: "10px",
    border: "1px solid #B8B8B8",
    margin: "1rem 0",
  },
}));

function OrderDiscriptionPopup({ data, setOnViewClicked }) {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);

  let mql = window.matchMedia("(max-width: 650px)");

  //fetch orders by id
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchData() {
      try {
        const response = await getMydashboardMySingalPurchase(token, data);
        setOrders(response?.data?.orders);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <div className={classes.paper}>
        <div className={classes.itemdetail}>
          <IconButton
            onClick={() => {
              setOnViewClicked("");
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h2"> Order Summary</Typography>
        </div>
        <div className={classes.dashedborder}></div>

        <div className={classes.productionplusbothbox}>
          <div className={classes.dflex}>
            <div className={classes.dateandtime}>
              <Typography variant="h6">#{orders?.order_id}</Typography>
              <Typography variant="h5">
                {orders?.created_at
                  ? new Date(orders.created_at)
                      .toLocaleString("en-US", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .split("/")
                      .join("-")
                  : ""}
              </Typography>
              <Typography variant="h4">
                {orders?.products?.length || 0} items
              </Typography>
              <Button className={classes.btn}>Download Invoice</Button>
            </div>
            {!mql.matches ? (
              <ProductDiscription orders={orders} />
            ) : (
              <div className={classes.productiondescriptionmobile}>
                {orders?.products?.map((data) => (
                  <ProductDescripMobile data={data} />
                ))}
              </div>
            )}
          </div>
          <div className={classes.CustomerDetailplusbilling}>
            <CustomerDetail orders={orders} />
            <BillingDetails orders={orders} />
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDiscriptionPopup;
