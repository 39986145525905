import React, { useState } from "react";
import {
  Button,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import downloadicon from "../../image/mytestseries/notes/downloadicon.png";
import { Help_desk } from "../../../service/services";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "0.375rem",
    background: " #FFFBF0",
    padding: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      margin: "auto",
      background: "#FFF",
    },
  },
  main: {
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    "& .MuiTypography-h6": {
      color: "#2E2E2E",
      fontFamily: " Playfair Display",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: "700",
    },
    "& .MuiTypography-subtitle1": {
      marginTop: "0.5rem",
    },
    "& .MuiTypography-h5": {
      color: "#FF3939",
      fontFamily: "Jost",
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .MuiTypography-body1": {
      color: "#353535",
      fontFamily: "Jost",
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .MuiTypography-subtitle2": {
      color: "#444",
      fontFamily: "Jost",
      fontSize: "10px",
      fontWeight: "400",
    },
  },
  container: {
    width: "49%",
    margin: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0",
    },
  },
  form: {
    marginBottom: "1rem",
  },
  textfield: {
    width: "100%",
    marginTop: "1rem",
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  container2: {
    width: "49%",
    margin: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1rem auto",
    },
  },
  innerdiv: {
    display: "flex",
    gap: "1rem",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  innercontainer2: {
    margin: "1rem 0",
    border: "1px solid #FFC855",
    borderRadius: "0.6rem",
    textAlign: "center",
    padding: "4rem 3rem",
    background: "#FFF",

    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
      width: "90%",
      margin: "1rem auto",
      "& img": {
        width: "36px",
      },
    },
  },
  btncontainer: {
    marginBottom: "1rem",
    marginLeft: "1rem",
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  btn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "15px",
    fontWeight: "500",
    color: "white",
    padding: "0.6rem",
    borderRadius: "0.4rem",
    width: "25%",
    marginRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      textAlign: "center",
      fontSize: "11px",
      margin: "0 auto",
    },
  },

  innercontainer3: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      margin: "0",
    },
  },
}));

const InputForm = ({fetchData}) => {
  const classes = useStyles();
  const [issueHead, setIssueHead] = useState("");
  const [msg, setMsg] = useState("");
  const [image, setImage] = useState(null);
  const imagepreview = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    console.log(selectedImage); // Log the selected image
  };
  // {
  //   "issue":"mukltiple",
  //       "message":"asdfghjkl gs fh"
  //      }
  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      const user = {
        issue: issueHead,
        message: msg,
      };
      console.log(user, "new");
      formData.append("helpdesk", JSON.stringify(user));
      formData.append("file", image);

      const response = await Help_desk(formData, token);
      console.log("API Response:", response);
      toast.success("Send Issue successfully");
      fetchData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.container}>
            <form className={classes.form} noValidate autoComplete="off">
              <Typography variant="h6">Get In Touch*</Typography>
              <TextField
                className={classes.textfield}
                id="outlined-basic "
                placeholder="Issue Heading"
                variant="outlined"
                onChange={(e) => setIssueHead(e.target.value)}
                value={issueHead}
              />
              <TextField
                className={classes.textfield}
                id="outlined-basic "
                placeholder="Type You message ar attach a file and press Enter a send...."
                variant="outlined"
                multiline
                rows={4}
                onChange={(e) => setMsg(e.target.value)}
                value={msg}
              />
            </form>
          </div>
      
          <div className={classes.container2}>
            <div className={classes.innerdiv}>
              <Typography variant="h6">Attach File</Typography>
              <Typography variant="subtitle1">(optional)</Typography>
            </div>
            <label role="button" datacy="uploadImage">
              <div className={classes.innercontainer2}>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={imagepreview}
                  hidden
                />
                {
                  image ? (
                  <div className={classes.innercontainer3}>
                  <img
                    src={image ? URL.createObjectURL(image) : downloadicon}
                    alt="Preview"
                    width="30%"
                  />
                  </div>
                ) : (
                  <>
                    <div className={classes.innercontainer3}>
                      <Typography variant="h5">Drag & Drop</Typography>
                      <Typography variant="body1">Your Files Here</Typography>
                    </div>
                    <Typography variant="subtitle2">
                      Supports: Pdf, JPEG, Png, Word Document
                    </Typography>
                  </>
                )}
              </div>
            </label>
          </div>
        </div>
        <div className={classes.btncontainer}>
          <Button className={classes.btn} onClick={handleSubmit}>
            Send
          </Button>
        </div>
      </div>
    </>
  );
};

export default InputForm;
