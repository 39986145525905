import React from "react";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import WhatWeofferVector from "../image/coachingsvector/whatweoffervector.png";
import { ReactComponent as WhatWeOfferCardvector } from "../image/coachingsvector/whatweoffercardvector.svg";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  whatweoffermain: {
    marginTop: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  whatweoffercontent: {
    width: "55%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "block",
      padding: "0rem 1rem",
      marginTop: "4.5rem",
    },
  },
  whatweofferimgcontainer: {
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "1rem 3rem",
    },
  },
  whatweoffercontentinner: {
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h3": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.9rem",
      fontStyle: "normal",
      fontWeight: "600",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiTypography-body2": {
      color: "#565656",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "capitalize",
    },
  },
  whatweoffercard: {
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    "& .MuiTypography-h6": {
      color: "#222",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.4rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "1.39438rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.83rem",
        textAlign: "center",
      },
    },
    "& .MuiTypography-caption": {
      color: "#696969",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "1.5625rem",
      textTransform: "lowercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.63rem",
        textAlign: "center",
        lineHeight: "1.2625rem",
      },
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "0",
    },
    "& .MuiCardContent-root": {
      padding: "0",
    },
    "& .MuiCard-root": {
      display: "flex",
      width: "48%",
      padding: "0.6rem",
      alignItems: "center",
      "& svg": {
        marginRight: "1rem",
      },
      "&:hover": {
        background: "#FFF6DF",
        "& svg": {
          fill: "white",
        },
      },
    },
  },
  dflex: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  dflexxs: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  alignItems: {
    alignItems: "center",
  },
  alignItemsxs: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  justifyxs: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  w80xs: {
    width:"80%",
    [theme.breakpoints.down("xs")]: {
      width: "60% !important",
    },
  },
}));
function WhatWeOffer() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Wanna Know",
      headingmain: "What We Offer",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.whatweoffermain}>
          <div className={classes.whatweoffercontent}>
            <div className={classes.whatweoffercontentinner}>
              <Typography variant="h3" gutterBottom>
                "Unlock the Future of Education with Our Online Platform"
              </Typography>
              <Typography variant="body2" gutterBottom>
                These features collectively enable organizations, educational
                institutions, and training providers to deliver effective and
                engaging online learning experiences. When selecting an LMS,
                it's important to consider which features align with your
                specific educational or training objectives and the needs of
                your learners.
              </Typography>
            </div>
            <div>
              <div className={classes.whatweoffercard}>
                <Card className={classes.root}>
                  <CardContent>
                    <div className={`${classes.dflex} ${classes.alignItems}`}>
                      <div className={`${classes.dflexxs} ${classes.alignItemsxs} ${classes.justifyxs}`}>
                        <WhatWeOfferCardvector className={classes.w80xs} />
                      </div>

                      <div>
                        <Typography variant="h6" gutterBottom>
                          Ease of Business
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          complete and you get full access to the partner
                          dashboard
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.root}>
                  <CardContent>
                    <div className={`${classes.dflex} ${classes.alignItems}`}>
                    <div className={`${classes.dflexxs} ${classes.alignItemsxs} ${classes.justifyxs}`}>
                        <WhatWeOfferCardvector className={classes.w80xs} />
                      </div>
                      <div>
                        <Typography variant="h6" gutterBottom>
                          Growth & Revenue
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          complete and you get full access to the partner
                          dashboard
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className={classes.whatweoffercard}>
                <Card className={classes.root}>
                  <CardContent>
                    <div className={`${classes.dflex} ${classes.alignItems}`}>
                    <div className={`${classes.dflexxs} ${classes.alignItemsxs} ${classes.justifyxs}`}>
                        <WhatWeOfferCardvector className={classes.w80xs} />
                      </div>
                      <div>
                        <Typography variant="h6" gutterBottom>
                          Better Control
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          complete and you get full access to the partner
                          dashboard
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.root}>
                  <CardContent>
                    <div className={`${classes.dflex} ${classes.alignItems}`}>
                    <div className={`${classes.dflexxs} ${classes.alignItemsxs} ${classes.justifyxs}`}>
                        <WhatWeOfferCardvector className={classes.w80xs} />
                      </div>
                      <div>
                        <Typography variant="h6" gutterBottom>
                          Online Bookshop
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          complete and you get full access to the partner
                          dashboard
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div className={classes.whatweofferimgcontainer}>
            <img src={WhatWeofferVector} alt="img" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhatWeOffer;
