import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  squareBlock: {
    position: "relative",
    width: "381px",
    height: "496px",
    background: "linear-gradient(305.53deg, #FFE6CE 48.03%, #FFF4D7 53.6%)",
    borderRadius: "7px",
    display: "flex",
    margin: "1px auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
    },
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
  },
  rectangleStrip: {
    position: "absolute",
    top: "404.3px",
    left: "0.3px",
    width: "380.5px",
    backdropFilter: "blur(20px)",
    opacity: "95%",
    height: "91px",
    zIndex: "500",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "white",
    borderRadius: "5px 5px 0 0",
    [theme.breakpoints.down("sm")]: {
      top: "412px",
      left: "0.3px",
      width: "333.5px",
    },
  },
  heading: {
    fontWeight: "700",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "23px",
    lineHeight: "33px",
    color: "rgba(37, 37, 37, 1)",
  },
  subheading: {
    fontSize: "14px",
    fontFamily: "QuickSand",
    fontWeight: "600",
    color: "rgba(37, 37, 37, 1)",
  },
  content: {
    position: "relative",
    zIndex: "2",
  },
  facultyName: {
    background: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    padding: "8px 0",
    zIndex: "3",
    textAlign: "center",
    display: "none",
  },
  socialIcons: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    display: "none",
  },
  socialIconsHovered: {
    "& $socialIcons": {
      display: "flex",
    },
  },
  icon: {
    width: "auto",
    height: "auto",
    margin: "33px",
    marginTop: "7px",
    zIndex: "500",
  },

  roundedTop: {
    position: "absolute",
    top: "388px",
    left: "107px",
    width: "44%",
    height: "33px",
    zIndex: "500",
    background: "rgba(255, 180, 96, 1)",
    borderRadius: "13px 13px 13px",
    [theme.breakpoints.down("sm")]: {
      left: "86px",
      top: "395px",
    },
  },
  experienceText: {
    fontSize: "16px",
    position: "absolute",
    top: "379px",
    fontFamily: "QuickSand",
    fontWeight: "700",
    lineHeight: "16px",
    zIndex: "500",
    color: "rgba(255, 255, 255, 1)",
    [theme.breakpoints.down("sm")]: {
      left: "112px",
      top: "388px",
    },
  },
}));

function SliderSquare({ iconName, heading, subheading, experienceText }) {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <Card
      className={`${classes.squareBlock} ${
        isHovered ? classes.squareBlockHovered : ""
      }`}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <Box className={classes.rectangleStrip}>
        <div className={classes.heading}>{heading}</div>
        <div className={classes.subheading}>{subheading}</div>
      </Box>

      <Box className={classes.roundedTop}></Box>
      <p className={classes.experienceText}>{experienceText}</p>
      <div>
        <img src={iconName} alt="" className={classes.icon} />
      </div>
    </Card>
  );
}

export default SliderSquare;
