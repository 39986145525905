import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20rem",
  },
  maintablecontainer: {
    margin: "2rem 8rem 0rem 8rem",
  },
  tablecontainer: {
    display: "flex",
    width: "100%",

    "& .MuiTypography-h1": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "5%",
      border: "1px solid #DFE8F1",
      background: "#e7f5fe ",
    },
    "& .MuiTypography-h2": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "25%",
      border: "1px solid #DFE8F1",
      background: "#e7f5fe ",
    },
    "& .MuiTypography-h3": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "20%",
      border: "1px solid #DFE8F1",
      background: "#e7f5fe ",
    },

    "& .MuiTypography-h4": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "5%",
      border: "1px solid #DFE8F1",
    },
    "& .MuiTypography-h5": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "25%",
      border: "1px solid #DFE8F1",
    },
    "& .MuiTypography-h6": {
      fontSize: "1rem",
      fontWeight: "600",
      width: "20%",
      border: "1px solid #DFE8F1",
    },
  },
  uploadinput: {
    border: "1px solid #DFE8F1",
    marginTop: "0.3rem",
    marginLeft: "0.3rem",
    padding: "0.5rem",
  },
}));

const DocumentUpload = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.maintablecontainer}>
          <div className={classes.tablecontainer}>
            <Typography variant="h1">S.No</Typography>
            <Typography variant="h2">. Required Document</Typography>
            <Typography variant="h3">Document Specifications</Typography>
            <Typography variant="h2">Upload</Typography>
            <Typography variant="h3">Action</Typography>
            <Typography variant="h1">View</Typography>
          </div>
          <div className={classes.tablecontainer}>
            <Typography variant="h4">1</Typography>
            <Typography variant="h5">
              पसपर्ट आकार की फोटो (बिना मास्क  के 80% चेहरे के ताप) (Passport Size
              Photograph (with 80% Choose the Iva file chosen face without
              mask))
            </Typography>
            <Typography variant="h6">
              Document Format: <br />
              Min Size (KB): &nbsp;&nbsp;10
              <br />
              Max Size (KB) &nbsp;&nbsp;200
            </Typography>
            <Typography variant="h5">
              <input type="file" className={classes.uploadinput} />
            </Typography>
            <Typography variant="h6"></Typography>
            <Typography variant="h4"></Typography>
          </div>
          <div className={classes.tablecontainer}>
            <Typography variant="h4">2</Typography>
            <Typography variant="h5">
              चाएं हाथ के अंगूठे की छवि (Left Hand Thumb Impression)
            </Typography>
            <Typography variant="h6">
              Document Format: <br />
              Min Size (KB): &nbsp;&nbsp;10
              <br />
              Max Size (KB) &nbsp;&nbsp;200
            </Typography>
            <Typography variant="h5">
              <input type="file" className={classes.uploadinput} />
            </Typography>
            <Typography variant="h6"></Typography>
            <Typography variant="h4"></Typography>
          </div>
          <div className={classes.tablecontainer}>
            <Typography variant="h4">3</Typography>
            <Typography variant="h5">हस्ताक्षर (Signature)</Typography>
            <Typography variant="h6">
              Document Format: <br />
              Min Size (KB):&nbsp;&nbsp; 4.
              <br />
              Max Size (KB) &nbsp;&nbsp;30
            </Typography>
            <Typography variant="h5">
              <input type="file" className={classes.uploadinput} />
            </Typography>
            <Typography variant="h6"></Typography>
            <Typography variant="h4"></Typography>
          </div>
          <div className={classes.tablecontainer}>
            <Typography variant="h4">4</Typography>
            <Typography variant="h5">
              पोस्टकार्ड साइज फोटो (Postcard Size Photo)
            </Typography>
            <Typography variant="h6">
              Document Format: <br />
              Min Size (KB): &nbsp;&nbsp;10.
              <br />
              Max Size (KB)&nbsp;&nbsp; 200
            </Typography>
            <Typography variant="h5">
              <input type="file" className={classes.uploadinput} />
            </Typography>
            <Typography variant="h6"></Typography>
            <Typography variant="h4"></Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentUpload;
