import { Box, Card, CardContent, CircularProgress, IconButton, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "48%",
        margin: "1rem 0",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none !important',
          },
        "& .MuiTypography-h6": {
            fontSize: "1.375rem",
            fontWeight: "700",
            fontFamily: "Playfair Display",
        },
        "& .MuiTypography-subtitle1": {
            fontSize: "0.625rem",
            fontWeight: "400",
            fontFamily: "Jost",
        },
        "& .MuiTypography-colorTextSecondary": {
            fontSize: "0.9375rem",
            fontWeight: "500",
            fontFamily: "Jost",
            color: "#383838",
        },
        "& .MuiIconButton-root": {
            marginTop: "1.5rem",
          color: "#262626",
          padding: "2px",
          background: "#FFEBC2",
          boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06)",
          "& svg": {
            width: "1.5rem",
            height: "1.5rem",
          }
        },
        "& .MuiCardContent-root": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        "& .MuiCircularProgress-colorPrimary": {
            color: "#FFCD9D",
            transform: "rotate(90deg) !important",
        },
    },
    cardShadow: {
        boxShadow: '0px 6px 40px rgba(0, 0, 0, 0.06) !important',
      },
}));
function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress size={75} thickness={7} variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
function ProgressCard({data, setOnViewClicked}) {
    const classes = useStyles();
  return (
    <Card className={`${classes.root} ${classes.cardShadow}`} elevation={0}>
        <CardContent>
            <div className={classes.titlecontainer}>
                <Typography variant='h6'>{data?.title}</Typography>
                {/* <Typography variant='subtitle1'>{data?.subtitle}</Typography> */}
                <IconButton onClick={()=>{setOnViewClicked("dashboardinnercard")}}><KeyboardArrowRightIcon/></IconButton>
            </div>
            <div className={classes.progresscontainer}>
                <CircularProgressWithLabel value={data?.value} />
            </div>
        </CardContent>
    </Card>
  )
}

export default ProgressCard