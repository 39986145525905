import { makeStyles } from "@material-ui/core";
import React from "react";
import SecHead from "../sectionHeading/sectionheading";
import ExclusiveBookContent from "./exclusivebookcontent";



const useStyles = makeStyles((theme) => ({
  ExclusiveBooksmain: {
    background: '#FFF7E4',
    margin: "0rem 0rem 0rem 0rem",
    padding: '2.2rem 0rem 3rem 0',

    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
      marginBottom: "0rem",
      padding: '3rem 0rem 0rem 0rem',
    },
  },
  containerfluid: {

    width: "100%",

    "& .slick-prev, .slick-next": {
      background: 'black',
      padding: '15px',
      borderRadius: '40px',

    },
    "& .slick-arrow": {
      opacity: '0.45',
      "&:hover": {
        opacity: '1',
      },
    },
    "& .slick-prev:before, .slick-next:before": {
      position: 'absolute',
      fontSize: '55px',
      borderRadius: '40px',
      top: '20px',
      left: '15px',
      transform: 'translate(-50%, -50%)',
      content: "→",
      color: 'white',
      opacity: '1',
      boxShadow: '0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    "& .slick-slide": {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '0.5rem',
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
    },

    "& .slick-dots li button:before": {
      color: '#FF8008',
      fontSize: '15px',
    },

  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
}));
function ExclusiveBooksSection({noteDetail,data}) {

  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Latest Books",
      headingmain: "Exclusive Books",
    },
  ];
  return (
    <>
      <div className={classes.ExclusiveBooksmain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <SecHead Heading={Heading} media={mql.matches}/>
            <ExclusiveBookContent noteDetail={noteDetail}data={data} />
          </div>
        </div>
      </div>
    </>
  );
}
export default ExclusiveBooksSection;
