import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActionArea,
  Fade,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "../../image/singlepagedescriptionvector/mockspopuplogo.png";
import { ReactComponent as QuestionIcon } from "../../image/singlepagedescriptionvector/questionicon.svg";
import { ReactComponent as TimerIcon } from "../../image/singlepagedescriptionvector/timericon.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "5px",
    
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "75%",
      margin: "0 1rem",
      padding: "0.5rem 0",
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar ": {
        display: "none",
      },
      position: "relative",
    top: "25px",
    },
    "& .MuiTypography-h6": {
        fontSize: "0.9375rem",
        fontWeight: "600",
        color: "#1656D3",
        fontFamily: "'Jost', serif !important",
        textTransform: "capitalize",
        // marginBottom: "0.6rem",
      },
    "& .MuiCardActionArea-root": {
      padding: "0.6rem 1rem",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiTypography-h4": {
        fontSize: "0.625rem",
        fontWeight: "600",
        color: "#000",
        fontFamily: "'Playfair Display', serif !important",
        marginBottom: "0.6rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.625rem",
        },
      },
      "& .MuiTypography-caption": {
        fontSize: "0.5rem",
        fontWeight: "400",
        color: "#9A9A9A",
        fontFamily: "'Jost', serif !important",
        marginLeft: "0.3rem",
      },
    },
  },
  closebtn: {
    position: "relative",
    top: "0",
    right: "-975px",
    padding: "0px !important",
    [theme.breakpoints.down("xs")]: {
      right: "-178px",
    },
  },
  innercontainer: {
    padding: "2rem",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },

  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  card: {
    background: "white",
    boxShadow: "0px 0px 20px rgba(58, 58, 58, 0.10)",
    borderRadius: "8px",
    width: "32%",
    transition: "all ease 0.2s",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1rem 0",
    },
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },
  cardimage: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "37%",
    },
  },
  carddescription: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "63%",
    },
  },
  iconandcaption: {
    marginRight: "1rem",
  },
  hardnesslevel: {
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "3px",
    padding: "0.2rem 1rem",
    fontFamily: " Jost",
    fontSize: "0.4375rem",
    fontWeight: "400",
    marginBottom: "0.3rem",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.4375rem",
    },
  },
  closeicon: {
    color: "#1656D3",
  }
}));

function MocksPopup(props) {
  const { open, handleOpenClose } = props;
  const classes = useStyles();

  const handleCancel = () => {
    handleOpenClose();
  };

  return (
    <>
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.innercontainer}>
          <Button onClick={handleOpenClose} className={classes.closebtn}>
             <Typography variant="h6">Back to Mocks</Typography>
            <CloseIcon className={classes.closeicon} />
          </Button>
            <div className={classes.cardcontainer}>
              <Card className={classes.card}>
                <CardActionArea>
                  <div className={classes.cardimage}>
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className={classes.carddescription}>
                    <Typography
                      component="div"
                      className={classes.hardnesslevel}
                    >
                      DIFFICULT
                    </Typography>
                    <Typography variant="h4">
                      Basic Computer knowledge subject test 01
                    </Typography>

                    <span className={classes.iconandcaption}>
                      <QuestionIcon />
                      <Typography variant="caption">100 Ques</Typography>
                    </span>
                    <span className={classes.iconandcaption}>
                      <TimerIcon />
                      <Typography variant="caption">60 Min</Typography>
                    </span>
                  </div>
                </CardActionArea>
              </Card>
              <Card className={classes.card}>
                <CardActionArea>
                  <div className={classes.cardimage}>
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className={classes.carddescription}>
                    <Typography
                      component="div"
                      className={classes.hardnesslevel}
                    >
                      DIFFICULT
                    </Typography>
                    <Typography variant="h4">
                      Basic Computer knowledge subject test 01
                    </Typography>

                    <span className={classes.iconandcaption}>
                      <QuestionIcon />
                      <Typography variant="caption">100 Ques</Typography>
                    </span>
                    <span className={classes.iconandcaption}>
                      <TimerIcon />
                      <Typography variant="caption">60 Min</Typography>
                    </span>
                  </div>
                </CardActionArea>
              </Card>
              <Card className={classes.card}>
                <CardActionArea>
                  <div className={classes.cardimage}>
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className={classes.carddescription}>
                    <Typography
                      component="div"
                      className={classes.hardnesslevel}
                    >
                      DIFFICULT
                    </Typography>
                    <Typography variant="h4">
                      Basic Computer knowledge subject test 01
                    </Typography>

                    <span className={classes.iconandcaption}>
                      <QuestionIcon />
                      <Typography variant="caption">100 Ques</Typography>
                    </span>
                    <span className={classes.iconandcaption}>
                      <TimerIcon />
                      <Typography variant="caption">60 Min</Typography>
                    </span>
                  </div>
                </CardActionArea>
              </Card>
            </div>
            <div className={classes.cardcontainer}>
              <Card className={classes.card}>
                <CardActionArea>
                  <div className={classes.cardimage}>
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className={classes.carddescription}>
                    <Typography
                      component="div"
                      className={classes.hardnesslevel}
                    >
                      DIFFICULT
                    </Typography>
                    <Typography variant="h4">
                      Basic Computer knowledge subject test 01
                    </Typography>

                    <span className={classes.iconandcaption}>
                      <QuestionIcon />
                      <Typography variant="caption">100 Ques</Typography>
                    </span>
                    <span className={classes.iconandcaption}>
                      <TimerIcon />
                      <Typography variant="caption">60 Min</Typography>
                    </span>
                  </div>
                </CardActionArea>
              </Card>
              <Card className={classes.card}>
                <CardActionArea>
                  <div className={classes.cardimage}>
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className={classes.carddescription}>
                    <Typography
                      component="div"
                      className={classes.hardnesslevel}
                    >
                      DIFFICULT
                    </Typography>
                    <Typography variant="h4">
                      Basic Computer knowledge subject test 01
                    </Typography>

                    <span className={classes.iconandcaption}>
                      <QuestionIcon />
                      <Typography variant="caption">100 Ques</Typography>
                    </span>
                    <span className={classes.iconandcaption}>
                      <TimerIcon />
                      <Typography variant="caption">60 Min</Typography>
                    </span>
                  </div>
                </CardActionArea>
              </Card>
              <Card className={classes.card}>
                <CardActionArea>
                  <div className={classes.cardimage}>
                    <img src={Logo} alt="logo" />
                  </div>
                  <div className={classes.carddescription}>
                    <Typography
                      component="div"
                      className={classes.hardnesslevel}
                    >
                      DIFFICULT
                    </Typography>
                    <Typography variant="h4">
                      Basic Computer knowledge subject test 01
                    </Typography>

                    <span className={classes.iconandcaption}>
                      <QuestionIcon />
                      <Typography variant="caption">100 Ques</Typography>
                    </span>
                    <span className={classes.iconandcaption}>
                      <TimerIcon />
                      <Typography variant="caption">60 Min</Typography>
                    </span>
                  </div>
                </CardActionArea>
              </Card>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}

export default MocksPopup;
