import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import BgSvg from "../../image/preprationvector/bgsvg.svg";
import Data from "./prepractionsectioncdata";
import cardgif from "../../image/preprationvector/arrow-Recovered.gif";
import cardpng from "../../image/preprationvector/arrow-Recovered.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  preprationsectionCard: {
    marginTop: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  bg: {
    backgroundImage: `url(${BgSvg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    filter: "drop-shadow(0px 6px 20px rgba(99, 99, 99, 0.2))",
    height: "330px",
    width: "100%",
    position: "relative",
    transition: "all ease 0.2s",
    "&:hover": {
      transform: "translateY(-3px)",
      filter: "drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25))",
      cursor: "pointer",
    },
  },
  padding: {
    padding: "1rem",
    width: "33.33%",
    color: "black",
  },
  cardimagecontainer: {
    width: "131.33px",
    height: "131.33px",
    position: "absolute",
    right: "7.9rem",
    top: "-0.8rem",
    background: "#FFFFFF",
    borderRadius: "100%",
    filter: "drop-shadow(2px 4px 6px #FFE8C095)",
    "& video::-webkit-media-controls": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-enclosure": {
      display: "none !important",
    },

    "& video::-webkit-media-controls-panel": {
      display: "none !important",
    },
  },

  bannervectoranimation: {
    width: "120.66px",
    height: "133.21px",
  },
  cardcontentcontainer: {
    position: "absolute",
    top: "8rem",
  },
  cardheading: {
    marginTop: "0rem",
    fontFamily: "Playfair Display",
    padding: "0",
    margin: "0",
  },
  carddiscription: {
    fontFamily: "Jost",
    fontSize: "1rem",
    // margin: "-1rem 2rem",
    padding: "0",
    margin: "0",
    width: "85%",
    color: "#727272",
  },
  cardgif: {
    width: "20%",
  },
}));

function PreprationSectionContentDesktop() {
  const classes = useStyles();
  const [selectedCard, setSelectedCard] = useState(null);
  const videoRef = useRef(null);

  const handleOptionHover = (option) => {
    setSelectedCard(option);
  };

  const handleOptionLeave = () => {
    setSelectedCard(null);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    };

    const playVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.play().catch((error) => {
          console.error("Error playing video:", error.message);
        });
      }
    };

    const pauseVideo = () => {
      const video = videoRef.current;
      if (video) {
        video.pause();
      }
    };

    const videoObserver = new IntersectionObserver(handleIntersection, options);

    const video = videoRef.current;
    if (video) {
      videoObserver.observe(video);
    }

    return () => {
      if (video) {
        videoObserver.unobserve(video);
      }
    };
  }, []);

  return (
    <>
      <div className={classes.preprationsectionCard}>
        <div className={classes.main}>
          {Data.map((row, index) => (
            <Link to={row.to} className={classes.padding}>
              <div
                align="center"
                onMouseEnter={() => handleOptionHover(row)}
                onMouseLeave={handleOptionLeave}
              >
                <div className={classes.bg}>
                  <div className={classes.cardimagecontainer}>
                    {/* <img
                    className={classes.cardimage}
                    src={row.cardimage}
                    alt="img"
                  /> */}
                    <video
                      ref={videoRef}
                      className={classes.bannervectoranimation}
                      autoPlay={true}
                      loop
                      muted
                      playsInline
                      controls={false}
                      width="100%"
                      height="auto"
                    >
                      <source src={row.cardimage} type="video/webm" />
                    </video>
                    {/* <video className={classes.bannervectoranimation} autoPlay={false} loop muted width="100%" height="auto">
                    <source src={row.cardimage} type="video/webm" />
                  </video> */}
                  </div>
                  <div className={classes.cardcontentcontainer}>
                    <h1 className={classes.cardheading}>{row.cardheading}</h1>
                    <p className={classes.carddiscription}>
                      {row.carddiscription}
                    </p>
                    <img
                      className={classes.cardgif}
                      src={selectedCard === row ? cardgif : cardpng}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
export default PreprationSectionContentDesktop;
