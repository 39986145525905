import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import SquareTrust from "./squaretrust";
import TrustSectionData from "./trustsectiondata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "4.5rem",
    padding: "4rem 0",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-3.5rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4rem",
  },
  mainHeading: {
    marginTop: "2rem",
    width: "100%",
    textAlign: "center",
  },
  blockContainer: {
    width: "50%",
    flex: "1",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  blockContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  centeringContainer: {
    display: "flex",
    justifyContent: "center",

    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

function TrustSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Your Trusted Source for Biometric Attendance Solutions.",
      headingmain: "Trust view : The Variants ",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.centeringContainer}>
            {TrustSectionData.map((block) => (
              <div key={block.id} className={classes.blockContainer}>
                <SquareTrust
                  iconName={block.iconName}
                  heading={block.heading}
                  subheading={block.subheading}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrustSection;
