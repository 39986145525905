import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  circularIcon: {
    color: "rgba(255, 252, 244, 1)",
    width: "40px",
    height: "35px",
    backgroundColor: "#00a911",
    borderRadius: "100%",
  },
  horizontalLine: {
    borderTop: "1px solid rgba(234, 234, 234, 1)",
    margin: "20px 0",
  },
  textInRed: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    color: "#00a911",
    marginLeft: "1rem",
  },
  solutionText: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    margin: "17px 0px 0px 14px",
    color: "rgba(73, 73, 73, 1)",
  },
  paragraphText: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "300",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 1)",
    padding: "0 13px",
    margin: "10px 0px",
    lineHeight: "24px",
  },
  topicContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
  },
  topicText: {
    padding: "0 13px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 1)",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
  },
  spanText: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "500",
    fontSize: "20px",
    color: "rgba(0, 0, 0, 1)",
  },
  wrongicon: {
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },
}));

function RightAnswer({ question, selectedLanguage }) {
  const classes = useStyles();
  let correctOption = question?.options?.find((i) => i?.correct_option);
  console.log("correctOption12", correctOption.option);
  return (
    <Box>
      <div className={classes.circularBox}>
        <div className={classes.wrongicon}>
          <CheckIcon className={classes.circularIcon} />
          {selectedLanguage === "english" ? (
            <Typography variant="body1" className={classes.textInRed}>
              Your Answer is Correct!
            </Typography>
          ) : (
            <Typography variant="body1" className={classes.textInRed}>
              आपका जवाब सही है!
            </Typography>
          )}
        </div>
      </div>

      {/* <Typography variant="body1" className={classes.solutionText}>
        <span className={classes.spanText}>Solution:</span> The correct option
        is ({correctOption?.option})
      </Typography>
      <Typography
        variant="body1"
        className={classes.paragraphText}
        dangerouslySetInnerHTML={{
          __html:
            selectedLanguage === "Hindi"
              ? correctOption?.solution_hi
              : correctOption?.solution_en,
        }}
      ></Typography> */}
      {/* <Divider className={classes.horizontalLine} /> */}
      {/* <div className={classes.topicContainer}>
        <Typography variant="body1" className={classes.topicText}>
          Topic: -
        </Typography>
        <Typography variant="body1" className={classes.topicText}>
          Difficulty Level: {question?.hardness_level}
        </Typography>
      </div> */}
    </Box>
  );
}

export default RightAnswer;
