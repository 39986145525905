import React, { useState } from "react";
import { Divider, Typography, makeStyles } from "@material-ui/core";
import profile from "../image/applicationform/profile.png";
import thumbicon from "../image/applicationform/thumbicon.jpg";
import sinatureimage from "../image/applicationform/sinatureimage.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFF",
    marginBottom: "20rem",
    padding: "1rem 4rem",
  },
  headercontainer: {
    display: "flex",
    textAlign: "center",
    "& .MuiTypography-h5": {
      fontSize: "1rem",
      fontWeight: 600,
    },
    "& .MuiTypography-h6": {
      color: "rgba(39,130,187,1)",
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
  centerhead: {
    justifyContent: "center",
    alignItems: "center",
    width: "58%",
  },
  lastendicon: {
    width: "20%",
  },
  firsticoncontainer: {
    width: "20%",
  },
  application: {
    border: "1px solid #DCDCDC",
    marginTop: "2rem",
    display: "flex",
    "& .MuiTypography-h5": {
      fontSize: "1rem",
      fontWeight: 600,
      border: "1px solid  #DCDCDC",
      padding: "0rem 8rem 2rem 8rem",
    },
  },
  centerheading: {
    textAlign: "center",
    fontSize: "0.8rem",
    fontWeight: 600,
    border: "1px solid  #DCDCDC",
  },
  centerheading1: {
    fontSize: "0.8rem",
    fontWeight: 500,
    border: "1px solid  #DCDCDC",
    textAlign: "left",
  },
  personaldetails: {
    marginTop: "1rem",
    width: "100%",
    "& .MuiTypography-h6": {
      fontSize: "0.9rem",
      fontWeight: 500,
      textAlign: "left",
      color: "rgba(39,130,187,1)",
    },
    "& .MuiTypography-h4": {
      fontSize: "0.8rem",
      fontWeight: 600,
      border: "1px solid  #DCDCDC",
      padding: "0rem 0rem 1.5rem 0rem",
      width: "25%",
    },
    "& .MuiTypography-h3": {
      fontSize: "0.8rem",
      fontWeight: 400,
      border: "1px solid  #DCDCDC",
      padding: "0rem 0rem 1.5rem 0rem",
      width: "25%",
    },
  },
  details1: {
    display: "flex",
    justifyContent: "space-betwen",
  },
  details2: {
    display: "flex",
    justifyContent: "space-betwen",

    "& .MuiTypography-h1": {
      fontSize: "0.9rem",
      fontWeight: 600,
      border: "1px solid  #DCDCDC",
      //   padding: "0rem 8rem 0rem 1.1rem",
      width: "25%",
    },
    "& .MuiTypography-h2": {
      fontSize: "0.9rem",
      fontWeight: 400,
      border: "1px solid  #DCDCDC",
      //   padding: "0rem 10rem 0rem 1.1rem",
      width: "25%",
    },
  },
  details3: {
    display: "flex",
    justifyContent: "space-betwen",

    "& .MuiTypography-h1": {
      fontSize: "0.9rem",
      fontWeight: 600,
      border: "1px solid  #DCDCDC",

      width: "50%",
    },
    "& .MuiTypography-h2": {
      fontSize: "0.9rem",
      fontWeight: 400,
      border: "1px solid  #DCDCDC",
      width: "50%",
    },
  },
  personaldetails1: {
    marginTop: "1rem",
    width: "100%",
    "& .MuiTypography-h6": {
      fontSize: "0.9rem",
      fontWeight: 500,
      textAlign: "left",
      color: "rgba(39,130,187,1)",
    },
  },
  imagecontainer: {
    width: "33%",
    border: "1px solid  #DCDCDC",
    textAlign: "center",

    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
      fontWeight: 600,
      background: "#ADD8E6",
    },
  },
  mainimagecontainer: {
    display: "flex",
    width: "100%",
  },
  contentcontainer: {
    width: "14.1%",
    border: "1px solid  #DCDCDC",
    textAlign: "center",
    "& .MuiTypography-body2": {
      fontSize: "0.9rem",
      fontWeight: 600,
      background: "#ADD8E6",
      border: "1px solid  #DCDCDC",
    },
  },
  lastcontainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    padding: "4rem",
  },
}));

const ConfirmationPage = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.headercontainer}>
          <div className={classes.firsticoncontainer}></div>
          <div className={classes.centerhead}>
            <Typography variant="h5">
              NATIONAL ELIGIBILITY CUM ENTRANCE TEST (UG) 2021
            </Typography>
            <Typography variant="h6">Confirmation Page</Typography>
          </div>
          <div className={classes.lastendicon}></div>
        </div>
        <div className={classes.application}>
          <Typography variant="h5">Application No.</Typography>
          <Typography variant="h5"> 210410000276</Typography>
          <Typography></Typography>
        </div>
        <Typography className={classes.centerheading}>
          DO NOT SEND THIS PAGE TO NATIONAL TESTING AGENCY (NTA).
        </Typography>
        <Typography className={classes.centerheading1}>
          CANDIDATE IS REQUESTED TO RETAIN THE PRINTOUT OF CONFIRMATION PAGE FOR
          FUTURE REFERENCE
        </Typography>

        <div className={classes.personaldetails}>
          <Typography variant="h6">
            व्यक्तिगत विवरण (Personal Details)
          </Typography>
          <div className={classes.details1}>
            <Typography variant="h4">
              अभ्यर्थी का नाम (Candidate's Name):
            </Typography>
            <Typography variant="h3"> SAMPLE CANDIDATE NAME</Typography>
            <Typography variant="h4">
              अभ्यर्थी की जन्म तिथि (Candidate's Date of Birth:
            </Typography>
            <Typography variant="h3"> Date of Birth: 15-01-1991</Typography>
          </div>
          <div className={classes.details2}>
            <Typography variant="h1">माता का नाम (Mother's Name):</Typography>
            <Typography variant="h2"> SAMPLE MOTHER NAME</Typography>
            <Typography variant="h1">सिंग (Gender):</Typography>
            <Typography variant="h2"> Male</Typography>
          </div>
          <div className={classes.details2}>
            <Typography variant="h1">पिता का नाम (Father's Name):</Typography>
            <Typography variant="h2"> SAMPLE FATHER NAME</Typography>
            <Typography variant="h1">राष्ट्रीयता (Nationality):</Typography>
            <Typography variant="h2"> Indian</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">श्रेणी (Category)</Typography>
            <Typography variant="h2"> General</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              यदि आप एक Pwd उम्मीदवार हैं, तो क्या आपके पास बेंचमार्क विकलांगता
              है (40% या अधिक। "गंभीर जहां प्रतिशत परिभाषित नहीं है)? (If you
              are a Pwd Candidate, do you have benchmark disability (40% or
              more/"Severe where percentage is not defined)?)
            </Typography>
            <Typography variant="h2"> yes</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              विकलांगता का प्रकार (Type of Disability)
            </Typography>
            <Typography variant="h2">
              {" "}
              Visual impairment as specified in the Schedule of RPWD Act, 2016
            </Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              क्या आपको स्क्राइब की जरूरत है (सूबना बुलेटिन में दिए गए मानदंड के
              रूप में)? (Do you need scribe (as the criteria given in the
              Information Bulletin) 7)
            </Typography>
            <Typography variant="h2"> No-I do not need scribe</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              विकलांगता का प्रतिशत (Percentage of disability)
            </Typography>
            <Typography variant="h2">48</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              राज्य । केन्द्र शासित प्रदेश जहां से 12 वी। समकक्ष उत्तीर्ण
              उपस्थिति (State/ UT from where 12th Equivalent Passed/ Appearing)
            </Typography>
            <Typography variant="h2">ASSAM</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              नियम के अनुसार स्व घोषणा पत्र अपलोड (Self Declaration upload as
              per rule)
            </Typography>
            <Typography variant="h2">NA</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              निवास का देश (Country of Residence)
            </Typography>
            <Typography variant="h2">India</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              क्या आपको मधुमेह हैं? Are You Diabetic?
            </Typography>
            <Typography variant="h2">Yes</Typography>
          </div>
        </div>
        <div className={classes.personaldetails1}>
          <Typography variant="h6"> के लिए आवेदन करना (Apply For)</Typography>
          <div className={classes.details3}>
            <Typography variant="h1">के लिए आवेदन करना (Apply For)</Typography>
            <Typography variant="h2">
              National Eligibility Cum Entrance Test(UG)
            </Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              प्रत्र पत्र माध्यम (Question Paper Medium)
            </Typography>
            <Typography variant="h2">English</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              परीक्षा राज्य। शहर-पहला विकल्प (Exam State City-1st choice)
            </Typography>
            <Typography variant="h2">ANDHRA PRADESH-GUNTUR</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              परीक्षा राज्य । शहर -दूसरा विकल्प (Exam State/ City-2nd choice)
            </Typography>
            <Typography variant="h2">ANDHRA PRADESH-KURNOOL</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              परीक्षा राज्य। शहर तीसरा विकल्प (Exam Statel City-3rd choice)
            </Typography>
            <Typography variant="h2">ANDHRA PRADESH MACHILIPATNAM</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              परीक्षा राज्य। शहर-चौथा विकल्प (Exam State City-4th choice)
            </Typography>
            <Typography variant="h2">ANDHRA PRADESH-MANGALAGIRI</Typography>
          </div>
        </div>
        <div className={classes.personaldetails1}>
          <Typography variant="h6"> वर्तमान पता (Present Address)</Typography>
          <div className={classes.details3}>
            <Typography variant="h1">पता (Address)</Typography>
            <Typography variant="h2">
              SAMPLE ADDRESS, SAMPLE LOCALITY, SAMPLE CITY, GUNTUR, ANDHRA
              PRADESH, INDIA, 123457
            </Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">देश (Country)</Typography>
            <Typography variant="h2">India</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">ईमेल पता (Email Address)</Typography>
            <Typography variant="h2">jay@gmail.com</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">मोबाइल नंबर (Mobile Number)</Typography>
            <Typography variant="h2">91-897123</Typography>
          </div>
          <div className={classes.details3}>
            <Typography variant="h1">
              वैकल्पिक संपर्क सूत्र (Alternate Contact No.) (Optional)
            </Typography>
            <Typography variant="h2">NA</Typography>
          </div>
        </div>
        <div className={classes.personaldetails1}>
          <Typography variant="h6">
            Images Uploaded by Candidate (उम्मीदवार द्वारा अपलोड की गई तस्वीर)
          </Typography>
          <div className={classes.mainimagecontainer}>
            <div className={classes.imagecontainer}>
              <Typography variant="body1"> Photo</Typography>
              <img src={profile} alt="img" width="50%" />
            </div>
            <div className={classes.imagecontainer}>
              <Typography variant="body1">
                Left Hand Thumb Impression
              </Typography>
              <img src={thumbicon} alt="img" width="50%" />
            </div>
            <div className={classes.imagecontainer}>
              <Typography variant="body1"> Signature</Typography>
              <img src={sinatureimage} alt="img" width="50%" />
            </div>
          </div>
        </div>
        <div className={classes.personaldetails1}>
          <Typography variant="h6">
            Fee Payment Details (शुल्क भुगतान विवरण){" "}
          </Typography>
          <div className={classes.mainimagecontainer}>
            <div className={classes.contentcontainer}>
              <Typography variant="body2">Payment</Typography>
              <Typography>Application fee</Typography>
            </div>
            <div className={classes.contentcontainer}>
              <Typography variant="body2"> Application No</Typography>
              <Typography>210410000276</Typography>
            </div>
            <div className={classes.contentcontainer}>
              <Typography variant="body2">Reference No</Typography>
              <Typography> 13532370623</Typography>
            </div>
            <div className={classes.contentcontainer}>
              <Typography variant="body2"> Transaction Date</Typography>
              <Typography> 13/07/2021 04:55:54</Typography>
            </div>
            <div className={classes.contentcontainer}>
              <Typography variant="body2"> Amount</Typography>
              <Typography>1</Typography>
            </div>
            <div className={classes.contentcontainer}>
              <Typography variant="body2"> Customer ID</Typography>
              <Typography> 21041000027601903</Typography>
            </div>
            <div className={classes.contentcontainer}>
              <Typography variant="body2"> Payment Mode</Typography>
              <Typography>EPG</Typography>
            </div>
          </div>
        </div>
        <div className={classes.lastcontainer}>
          <div>Application Number: 210410000276 IP Address</div>
          <div> Date and Time: 13/07/2021 05:000:07 PM</div>
          <div> (Page 1 of 1]</div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPage;
