import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rectangle: {
    width: "300px",
    height: "125px",
    backgroundColor: "white",
    borderRadius: "5px",
    border: "2px solid #E1D9D1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    fontSize: "1.2rem",
    fontWeight: "bold",
    gap: "20px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",

    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
  },
  icon: {
    marginRight: "-8px",
  },
}));

const SupportsectionImpressive = ({ text, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.rectangle}>
      <div className={classes.icon}>{icon}</div>
      <Typography variant="body1">{text}</Typography>
    </div>
  );
};

export default SupportsectionImpressive;
