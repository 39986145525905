import React from "react";
import { makeStyles,Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  squareBlock: {
    width: "285px",
    height: "250px",
    backgroundColor: "rgba(255, 252, 245, 1)",
    margin: "10px",
    borderRadius: "5px",
    display: "flex",
    border: "2px solid #E1D9D1",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: "20px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      background: "linear-gradient(270deg, #FF8008 0%, #FFC837 74.71%)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "11rem", 
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    width: "auto",
    height: "auto",
    marginTop: "23px",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
      marginBottom: "5px",
    },
  },
  heading: {
    fontSize: "26px",
    marginTop:"2px",
    lineHeight: "34px",
    fontFamily: "'Playfair Display', serif !important",
    color: "rgba(36, 59, 85, 1)",
    textAlign: "center",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      whiteSpace:"nowrap",
      fontSize:"20px",
      
    },
  },
  subheading: {
    fontSize: "15px",
    marginTop:"-16px",
    fontFamily: "Jost, sans-serif",
    color: "rgba(0, 0, 0, 1)",
    fontWeight: "400",
    lineHeight: "22px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight:"16px",
    },
  },
}));

function SquareCard({ iconName, heading, subheading }) {
  const classes = useStyles();

  return (
    <Card className={classes.squareBlock}>
      <div>
        <img src={iconName} alt="" className={classes.icon} />
      </div>
      <div className={classes.content}>
        <h3 className={classes.heading}>{heading}</h3>
        <p className={classes.subheading}>{subheading}</p>
      </div>
    </Card>
  );
}

export default SquareCard;
