export const SET_QUIZ_LIST = "SET_QUIZ_LIST";
export const SET_PREVIOUS_YEAR_PAPER_LIST = "SET_PREVIOUS_YEAR_PAPER_LIST";
export const SET_EBOOK_LIST = "SET_EBOOK_LIST";
export const SET_BOOK_LIST = "SET_BOOK_LIST";
export const SET_TEST_SERIES_LIST = "SET_TEST_SERIES_LIST";
export const SET_NOTES_LIST = "SET_NOTES_LIST";
export const SET_ONLINE_VIDEO_LIST = "SET_ONLINE_VIDEO_LIST";
export const SET_LIVE_CLASSES_LIST = "SET_LIVE_CLASSES_LIST";

export const SET_EBOOK_DETAILS = "SET_EBOOK_DETAILS";
export const SET_BOOK_DETAILS = "SET_BOOK_DETAILS";
export const SET_NOTE_DETAILS = "SET_NOTE_DETAILS";
export const SET_TEST_SERIES_DETAILS = "SET_TEST_SERIES_DETAILS";
export const SET_ONLINE_VIDEO_DETAILS = "SET_ONLINE_VIDEO_DETAILS";
export const SET_LIVE_CLASSES_DETAILS = "SET_LIVE_CLASSES_DETAILS";