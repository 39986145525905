import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { GrFormClose } from "react-icons/gr";
import { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updatePassword } from "../../service/services";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "70%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "& .MuiDivider-root": {
      width: "11%",
      background: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
      height: "0.125rem",
      marginTop: "1rem",
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    '& .MuiInputBase-input': {
      padding: '0',
      fontFamily: "Jost",
      fontWeight: "500",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
      },
      "& .MuiLink-root": {
        color: "#154FE5",
        fontSize: "0.9375rem",
        fontWeight: "400",
        textTransform: "capitalize",
      },
    },
  },
  loginform: {
    width: "40%",
    marginTop: "0.6rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  paper: {
    position: "absolute",
    display: "flex",
    top: "68px",
    backgroundColor: "#FFFAEF",
    zIndex: "8",
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    height: "calc(100vh - 80px)",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    justifyContent: 'center',
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      fontFamily: "Jost",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.6rem !important",
        marginTop: "0rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "100px",
      padding: "1rem",
    },
  },
  orangetext: {
    color: "#FF9213",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "2.6rem",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  passwordheadcont: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1.5rem",
    padding: "0.5rem 1rem",
    borderRadius: '0.4rem',
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "300",
    },
  },
  formfootertext: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1rem',
    color: '#5E5E5E',
    "& .MuiLink-root": {
      color: '#FF9213',
    },
  },
  closebtncontainer: {
    position: "absolute",
    top: '60px',
    right: '-10px',
    '& .MuiButton-root': {
      minWidth: '0px',
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: '30 ',
    height: "30 px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  passwordinstruction: {
    "& .MuiTypography-body1": {
      fontSize: "15px",
      fontFamily: "Jost",
      color: "#5E5E5E",

    },
  },
}));
function CreatePasswordModal(props) {

  const [showPassword, setShowPassword] = useState(false);

  const { handleCreatepasswordOpenclose, mobileNumber, onetimepassword } = props;


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const classes = useStyles();

  const initialValues = {
    password: "",
    confirmPassword: ""
  }

  const { register, handleSubmit, formState: { errors }, formState, getValues, watch } = useForm({ defaultValues: initialValues });
  const password = watch('password');

  const onSubmit = async (data) => {
    try {
      var res = await updatePassword({
        user: {
          phone: mobileNumber,
          otp: onetimepassword,
          password: data?.password
        }
      });
      toast(res?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        type: "success"
      });
      handleCreatepasswordOpenclose()
    } catch (err) {
      toast(err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        type: "error"
      });
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.closebtncontainer}>
        <Button className={classes.closebtn} onClick={handleCreatepasswordOpenclose}>
          <GrFormClose />
        </Button>
      </div>
      <div className={classes.paper}>
        <Typography variant="h3" gutterBottom>
          <span className={classes.orangetext}>Create</span> Your Password
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          We’ve sent the code to the email on your Device
        </Typography>
        <Divider />
        <div className={classes.loginform}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ m: 1, width: "25ch" }}>
              <div className={classes.passwordheadcont}>
                <Typography variant="h6" gutterBottom>
                  Create Password
                </Typography>
              </div>
              <TextField
                {...register("password", { required: true, minLength: 6 })}
                type={showPassword ? "text" : "password"}
                placeholder="**********"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <LockOpenOutlinedIcon />
                        ) : (
                          <LockOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && errors.password.type === "required" && <p className='errorText ms-0 '>Password is required</p>}
              {errors.password && errors.password.type === "minLength" && <p className='errorText ms-0 '>  Must be at least 6 characters</p>}

              <div className={classes.passwordheadcont}>
                <Typography variant="h6" gutterBottom>
                  Confirm Password
                </Typography>
              </div>
              <TextField
                {...register("confirmPassword", {
                  required: true,
                  minLength: 6,
                  validate: (value) => value === password || 'Passwords do not match',
                })}
                type={showPassword ? "text" : "password"}
                placeholder="**********"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <LockOpenOutlinedIcon />
                        ) : (
                          <LockOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.confirmPassword && errors.confirmPassword.type === "required" && <p className='errorText ms-0 '>Confirm Password is required</p>}
              {errors.confirmPassword && errors.confirmPassword.type === "minLength" && <p className='errorText ms-0 '>    Must be at least 6 characters</p>}
              {errors.confirmPassword && errors.confirmPassword.type === "validate" && <p className='errorText ms-0 '> Both Passwords must match</p>}

              <Button type="submit" className={classes.bluecustombtn} fullWidth>
                Create Password
              </Button>
            </FormControl>
          </form>
        </div>
      </div>
    </div>
  );
}
export default CreatePasswordModal;
