const footercoachinglist = [
  {
    id: 1,
    name: "Biometric",
    to: "/biometric",
  },
  {
    id: 2,
    name: "Online Assessment",
    to: "/onlineassessment",
  },
  {
    id: 3,
    name: "Digital Evaluation",
    to: "/digitalevaluation",
  },
  {
    id: 4,
    name: "RPS",
    to: "/rps",
  },
  {
    id: 4,
    name: "LMS",
    to: "/lms",
  },
  // {
  //   id: 5,
  //   name: "Coachings",
  //   to: "/coachings",
  // },
  // {
  //   id: 6,
  //   name: "Teachers ",
  //   to: "/teachers",
  // },
];
export default footercoachinglist;
