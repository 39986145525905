import React from "react";
import HeaderNotification from "./headerNotification";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: "1.5rem",
    padding: "auto",
      width:'100%',
    textAlign: "center",
    padding: '0',
    [theme.breakpoints.down("xs")]: {
        "& .MuiListItem-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
          },
      },
   
  },
  icon: {
    color: "orange",
    width: "20%",
  },
}));

const ImpDates = [
  {
    id: 1,
    subCategories: "Date OF Publication Of Notification : 21-07-2023",
  },
  {
    id: 2,
    subCategories: "Starting Date To Apply Online 22-07-2023",
  },
  {
    id: 3,
    subCategories:
      "Last Date To Apply Online & Submission Of Application 15-08-2022",
  },
  {
    id: 4,
    subCategories:
      "Date Of 'Window For Application Form Correction' And Online Payment Of Correction Charges: 16-08-2023 to 17-08-2023",
  },

  {
    id: 5,
    subCategories: "Date Of Cbt Exam : October 2023",
  },
  {
    id: 6,
    subCategories: "Date Of PET/PST 14-11-2023 To 20-11-2023",
  },
];

const ImportantDates = ({ Notification1 }) => {
  const classes = useStyles();
  // let description=Notification1?.requirements[1];
  // console.log(description,"description");
  const description = Notification1?.requirements?.[1]?.description;
  const heading = Notification1?.requirements?.[1]?.heading;

  console.log("Notification1", Notification1);

  return (
    <>
      {/* <Card classname={classes.card}>
                {
                    Notification1?.requirements.map((data,index)=>{
                        return(
                            <>
                            <HeaderNotification data={data.heading} />
                            <CardContent>
                                <div className={classes.examslist}>
                                    <List component="nav" aria-label="secondary mailbox folders">
                                       
                                                    <ListItem  button selected={""}>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon className={classes.icon}/>
                                                          
                                                        </ListItemIcon>
                                                        <ListItemText >
                                                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                                        </ListItemText >
            
                                                    </ListItem>
                                            
            
                                    </List>
                                    {/* <List component="nav" aria-label="secondary mailbox folders">
                                        {
            
                                            Notification1?.requirements?.map((data, index) => {
            
                                                return (
                                                    <ListItem key={index} button selected={""}>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon className={classes.icon}/>
                                                          
                                                        </ListItemIcon>
                                                        <ListItemText primary={data.subCategories} />
            
                                                    </ListItem>
                                                );
                                            })
                                        }
            
                                    </List> */}
      {/* </div> */}

      {/* </CardContent> */}
      {/* </>
                        )
                    })
                }
              

            </Card> */}

      {Notification1?.requirements?.map((data, index) => {
        return (
          <>
            <Card className={classes.card}>
              <HeaderNotification data={data.heading} />

              <CardContent>
                <div className={classes.examslist}>
                  <List component="nav" aria-label="secondary mailbox folders">
                    <ListItem button selected={""}>
                      <ListItemText>
                        <div
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                      </ListItemText>
                    </ListItem>
                  </List>
                </div>
              </CardContent>
            </Card>
          </>
        );
      })}
    </>
  );
};

export default ImportantDates;
