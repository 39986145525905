import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import notificationdatalogo from "../../component/image/notification/notificationdatalogo.png";

import React, { useEffect, useState } from "react";
import { getJobNotification } from "../../service/services";

const useStyles = makeStyles((theme) => ({
  head1: {
    align: "left",
    fontWeight: "bold",
    color: "#223852",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "1.5625rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "2.5rem",
  },
  head2: {
    fontWeight: "normal",
  },
  cardnoti: {
    minWidth: 275,
    padding: "auto",
    "& .MuiCardActions-root": {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    "& .MuiCardActions-spacing > :not(:first-child)": {
      marginLeft: "2rem",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0rem",
        marginTop: "1rem",
      },
    },
   
  },
  notihead3: {
    textDecorationLine: "underline",
    textDecorationColor: "orange",
    fontSize: "18px",
    fontWeight: "bold",
  },
  notihead4: {
    fontSize: "15px",
    fontWeight: "bold",
  },

  thumbnailscontainer: {
    background: "#FFFFFF",
    padding: "0.8rem 0.8rem",
    borderRadius: "0.375rem",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.10)",
    "& img": {
      marginRight: "0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
    width:'90%',
    },
  },
}));

const DateComponent = ({ Notification1 }) => {
  const classes = useStyles();

  const formatDateString = (dateString) => {
    // Check if the date string is not empty and is a valid date
    if (!dateString || isNaN(new Date(dateString).getTime())) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <>
      <div className={classes.root}>
        <Card className={classes.cardnoti}>
          <CardContent>
            <Typography className={classes.notihead3}>
              Name Of The Post
            </Typography>
            <Typography className={classes.notihead4}>
              {Notification1.name}
            </Typography>
            <br />
            <Typography className={classes.notihead3}>
              Brief Information
            </Typography>
            <Typography className={classes.notihead4}>
              {Notification1.description}
            </Typography>
          </CardContent>
          <CardActions>
            <div className={classes.thumbnailscontainer}>
              <img src={notificationdatalogo} alt="logo" />
              {formatDateString(Notification1?.updated_at)} Latest
            </div>
            <div className={classes.thumbnailscontainer}>
              <img src={notificationdatalogo} alt="logo" />
              {formatDateString(Notification1?.created_at)} Created
            </div>
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default DateComponent;
