import React, { useState } from "react";
import {
    Backdrop,
  Card,
  CardActionArea,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SSCLogo from "../../image/singlepagedescriptionvector/ssclogo2.png";
import { ReactComponent as DocIcon } from "../../image/singlepagedescriptionvector/docicon.svg";
import MocksPopup from "./MocksPopup";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
  },
  container: {
    margin: "1rem auto",
    padding: "3rem 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      margin: "0",
    },

    "& .MuiCardActionArea-root": {
      padding: "1rem",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiTypography-h4": {
        fontSize: "0.875rem ",
        fontWeight: "600",
        color: "#000",
        fontFamily: "'Playfair Display', serif !important",
        marginBottom: "0.6rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.625rem",
        },
      },
      "& .MuiTypography-caption": {
        fontSize: "0.75rem",
        fontWeight: "400",
        color: "#9A9A9A",
        fontFamily: "'Jost', serif !important",
        marginLeft: "0.3rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.5rem",
        },
      },
    },
  },
  secheadhr: {
    width: "175px",
    marginTop: "15px",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.6rem",
      marginBottom: "0rem",
    },
  },
  headingsection: {
    marginBottom: "2rem",
    "& .MuiTypography-h3": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "3rem",
      color: "#252525",
      fontWeight: "700",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
        lineHeight: " 40px",
        fontFamily: "'Playfair Display', serif !important",
      },
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.5rem",
      margin: "0",
      color: "#252525",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        margin: "0",
        color: "#252525",
        fontWeight: "400",
      },
    },
  },
  innercontainer: {
    background: "white",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.11)",
    padding: "2rem",
    marginBottom: "2rem",
    "& .MuiTypography-h6": {
      fontSize: "1.25rem !important",
      fontWeight: "700",
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem !important",
      },
    },
  },
  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  card: {
    background: "white",
    boxShadow: "0px 0px 20px rgba(58, 58, 58, 0.10)",
    borderRadius: "8px",
    width: "32%",
    margin: "1rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    transition: "all ease 0.2s",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },
  cardimage: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "37%",
    },
  },
  carddescription: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "63%",
    },
  },
  // modalcontainer: {
  //   position: "absolute",
  // },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconandcaption: {
    display: "flex",
    alignItems: "center",
    "& svg": {
    [theme.breakpoints.down("xs")]: {
      width: "7px",
    },
    },
  },
}));
function BundleInSitesSection() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.headingsection}>
          <Typography variant="h3" gutterBottom>
            Bundle In-Sites ( 241 Test Series )
          </Typography>
          <Typography variant="h5" gutterBottom>
            Test Series Included in this Bundle
          </Typography>
          <hr align="left" className={classes.secheadhr} />
        </div>

        <div className={classes.innercontainer}>
          <Typography variant="h6" gutterBottom>
            Full Length Mocks
          </Typography>
          <div className={classes.cardcontainer}>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
          </div>
        </div>
        <div className={classes.innercontainer}>
          <Typography variant="h6" gutterBottom>
            Sectional Mocks
          </Typography>
          <div className={classes.cardcontainer}>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
          </div>
        </div>
        <div className={classes.innercontainer}>
          <Typography variant="h6" gutterBottom>
            Topic-wise Mocks
          </Typography>
          <div className={classes.cardcontainer}>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
            <Card className={classes.card}>
              <CardActionArea onClick={handleOpenClose}>
                <div className={classes.cardimage}>
                  <img src={SSCLogo} alt="logo" />
                </div>
                <div className={classes.carddescription}>
                  <Typography variant="h4">
                    Basic Computer Knowledge Subject test (Bilingual ) By
                    ParikshaDo
                  </Typography>

                  <div className={classes.iconandcaption}>
                    <DocIcon />
                    <Typography variant="caption">5 Mock Tests</Typography>
                  </div>
                </div>
              </CardActionArea>
            </Card>
          </div>
        </div>
        <div className={classes.modalcontainer}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleOpenClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <MocksPopup handleOpenClose={handleOpenClose} open={open} />
          </Modal>
        </div>
      </div>
    </div>
  );
}
export default BundleInSitesSection;
