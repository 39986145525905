import React from "react";
import { makeStyles } from "@material-ui/core";
import SinglePageContent from "./singlepagecontent";
import PageHead from "../../pageheading/pageheading";

const useStyles = makeStyles((theme) => ({}));

function TestSeriesSinglePageDescription() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Study",
      headingmain: "All In One General Studies 2023 ",
      breadcrumb:"Test Series / Product_Description"
    },
  ];
  return (
    <div>
      <PageHead Heading={Heading}/>
      <SinglePageContent />
    </div>
  );
}
export default TestSeriesSinglePageDescription;
