import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import IdentificationSection from "./identificationsection";
import TrustSection from "./trustsection";
import FeatureSection from "./featuressection";
import ImpressiveSection from "./impressivesction";
import MetricCard from "./metriccard";
import ElevatingSection from "./elevatingsection";
import GetInTouch from "../biometric/contactsection"

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
function Biometric() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Biometric ",
      headingmain: "Biometric",
      breadcrumb: "Biometric",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <IdentificationSection />
      <TrustSection />
      <FeatureSection />
      <ImpressiveSection />
      <MetricCard />
      <ElevatingSection />
      <GetInTouch />
    </div>
  );
}
export default Biometric;
