import { ReactComponent as RegistrationVector } from "../image/coachingsvector/registration.svg";
import { ReactComponent as ApprovalVector } from "../image/coachingsvector/approval.svg";
import { ReactComponent as AuthorizationVector } from "../image/coachingsvector/authorization.svg";
import { ReactComponent as MakeRequiRedpaymentVector } from "../image/coachingsvector/makerequiredpayment.svg";
import { ReactComponent as OnlineVisibilityEnquiriesVector } from "../image/coachingsvector/onlinevisibilityenquiries.svg";
import { ReactComponent as AddstudyMaterialVector } from "../image/coachingsvector/addstudymaterial.svg";

const FeatureBenefitsListData = [
    {
      id: 1,
      vector: <RegistrationVector/>,
      bgimg: <RegistrationVector/>,
      heading: "Registration",
      content:"Simply fill in a few details and submit a request to register with us as a verified ParikshaDo Partner.",
    },
    {
        id: 2,
        vector: <ApprovalVector/>,
        bgimg: <RegistrationVector/>,
        heading: "Approval",
        content:"Simply fill in a few details and submit a request to register with us as a verified ParikshaDo Partner.",
      },
      {
        id: 3,
        vector: <MakeRequiRedpaymentVector/>,
        bgimg: <RegistrationVector/>,
        heading: "Make Required Payment",
        content:"Simply fill in a few details and submit a request to register with us as a verified ParikshaDo Partner.",
      },
      {
        id: 4,
        vector: <AuthorizationVector/>,
        bgimg: <RegistrationVector/>,
        heading: "Authorization",
        content:"Simply fill in a few details and submit a request to register with us as a verified ParikshaDo Partner.",
      },
      {
        id: 5,
        vector: <OnlineVisibilityEnquiriesVector/>,
        bgimg: <RegistrationVector/>,
        heading: "Online Visibility & Enquiries",
        content:"Simply fill in a few details and submit a request to register with us as a verified ParikshaDo Partner.",
      },
      {
        id: 6,
        vector: <AddstudyMaterialVector/>,
        bgimg: <RegistrationVector/>,
        heading: "Add Study Material",
        content:"Simply fill in a few details and submit a request to register with us as a verified ParikshaDo Partner.",
      },
  ];
  export default FeatureBenefitsListData;
  