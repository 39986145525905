import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardMedia,
  Link,
  IconButton,
  Divider,
} from "@material-ui/core";
import FavouriteButton from "../FavouriteButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  TestSeriesCard: {
    justifyContent: "space-between",
    maxWidth: "23.5%",
    display: "flex !important",
    flexDirection: "column",
    borderRadius: 7,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        "& .MuiButton-label": {
          fontFamily: "Jost",
          alignItems: "center",
        },
      },
    },
    "& .MuiCardActions-root": {
      background:
        "linear-gradient(90deg, rgba(255,217,181,1) 0%, rgba(255,238,195,1) 100%)",
      borderRadius: 6,
      "& .MuiButton-label": {
        fontFamily: "Jost",
      },
    },
    "& .MuiTypography-body2": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiCardActionArea-root": {
      padding: "0px 10px 12px 10px",
      position: "relative",
    },
    "& .MuiCardContent-root": {
      padding: "0px",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
    "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
      opacity: "0.3",
    },
    "& .MuiTypography-h5": {
      color: "#223852",
      fontFamily: "'Jost', sans-serif",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "500",
      margin: "0",
      lineHeight: "1rem",
      textTransform: "capitalize",
      whiteSpace: "wrap",
      height: "32px",
      overflow: "hidden",
      textOverflow: `"..."`,
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    "& .MuiTypography-h4": {
      fontFamily: "'Jost', sans-serif",
      color: "#FF3939",
      fontSize: "0.9375rem",
      fontWeight: "500",
    },
    "& .MuiTypography-body1": {
      marginLeft: "1rem",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    "& .MuiCardMedia-root": {
      margin: "0 auto",
      // width: "45% !important",
      borderRadius: "3%",
    },
    "&.MuiCardMedia-media": {
      width: "50% !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTypography-h5": {
        fontSize: "1.2rem",
        marginBottom: 5,
      },
      "& .MuiCard-root": {
        "@media (min-width: 960px)": {
          width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "80%",
        },
      },
    },
  },
  cardimgcont: {
    width: "180px",
    height: "180px",
    background: "#FFE8B5",
    // margin: "0rem  0.7rem 0rem 0.7rem",
    borderRadius: 8,
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.3rem",
    fontFamily: "Jost",
    fontWeight: "600",
    marginRight: "0.5rem",
  },
  offertext: {
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  quikviewcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
    background: "white",
    padding: "0.3rem 0.5rem !important",
    opacity: "1",
    zIndex: "1000",
    width: "50% !important",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "6px",
  },
  quikviewbtn: {
    "&:hover": {
      background: "white",
    },
    "& .MuiIconButton-label": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      width: "0.9em",
    },
    "& .MuiButton-label": {
      fontFamily: "'Jost', sans-serif",
      color: "#252525",
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  favoritebtn: {
    // width: "29%",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
  },
  testserandvaliditytagcont: {
    display: "flex",
    margin: "0.2rem 0 0.34rem 0",
    position: "relative",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      color: "#252525",
      fontSize: "0.625rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "500",
    },
  },
  innertags: {
    // background: "#FFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    padding: "0.1rem 0.4rem",
    borderRadius: "0.4rem",
  },
  hexagoncontainer: {
    position: "absolute",
    left: "-25px",
    bottom: "-21px",
  },
  cardimgandtitle: {
    display: "flex",
    justifyContent: "center",
  },
  cardtitle: {
    width: "100%",
    paddingTop: "0.5rem",
  },
  carticon: {
    marginRight: "0.6rem",
  },
  cardroot: {
    flexBasis: "33.3333%",
    // maxWidth: 280,
    maxHeight: 291,
    margin: "0 5px",
    marginBottom: "2rem",
  },
}));

function TestSeriesCard({ data }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);

  const handleCardHover = () => {
    setIsHover(!isHover);
  };

  return (
    <Card className={[classes.TestSeriesCard, classes.cardroot]}>
      <CardActionArea
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={() => {
          navigate(`/testseries/${data?.id}`);
        }}
      >
        {isHover && (
          <div>
            <div className={classes.quikviewcontainer}>
              <IconButton
                onClick={() => navigate(`/testseries/${data?.id}`)}
                className={classes.quikviewbtn}
              >
                <InfoIcon />
              </IconButton>
              <div className={classes.favoritebtn}>
                <FavouriteButton
                  isCheckedHeart={isCheckedHeart}
                  data={data}
                  setIsCheckedHeart={setIsCheckedHeart}
                />
              </div>
            </div>
          </div>
        )}
        <div className={classes.cardimgandtitle}>
          <div className={classes.cardimgcont}>
            <CardMedia
              component="img"
              image={data?.image_url || require("../../image/notFoundData.png")}
              alt="green iguana"
              width="180px"
              height="180px"
            />
          </div>
        </div>
        <div className={classes.cardtitle}>
          <Typography gutterBottom variant="h5" component="div">
            {data?.bundle_name}
          </Typography>
          {data?.sub_heading && (
            <Typography gutterBottom variant="caption" component="div">
              ({data?.sub_heading})
            </Typography>
          )}
        </div>
        <div className={classes.testserandvaliditytagcont}>
          <div className={classes.innertags}>
            <Typography variant="h6">
              {data?.test_series_mock_count} Test Series
            </Typography>
          </div>
          <div className={classes.innertags}>
            <Typography variant="h6">Validity: {data?.validity}</Typography>
          </div>
        </div>
        <Divider />
        <CardContent>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ marginBottom: 0 }}
          >
            <span className={classes.textred}>
              <span style={{ fontSize: "1.3rem", verticalAlign: "top" }}>
                ₹</span>{data?.selling_cost}</span>
            <Typography variant="caption">
              MRP:₹<del>{data?.mrp}</del>
              <span className={classes.offertext}>
                ({data?.discount_percent}% off)
              </span>
            </Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
export default TestSeriesCard;
