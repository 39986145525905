import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RelatedBooksSection from "./relatedbookssection";
import SecHead from "./sectionheading";
import EbookUploadPlan from "./ebookuploadplan";
// import Slider from "react-slick";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3rem 2rem",
    flexGrow: 1,

    [theme.breakpoints.up("xs")]: {
      margin: "2rem 0rem",
    },

    "& .MuiTab-root": {
      padding: "10px 12px",
      [theme.breakpoints.down("xs")]: {
        padding: "5px 10px",
      },
    },
    "& .MuiRating-root": {
      marginRight: "1rem",
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        padding: "0rem 1rem",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 93.96%)",
      color: "white",
      borderRadius: "5px 5px 0 0",
      minHeight: "40px",
      [theme.breakpoints.down("xs")]: {
        fontFamily: "'Playfair Display', serif !important",
        minHeight: "10px",
        fontSize: "0.8rem",
        fontWeight: "400",
      },
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #C6C6C6",
    },
    "& .MuiTab-textColorInherit": {
      fontFamily: "Playfair Display",
      fontWeight: "700",
      color: "#676767",
      [theme.breakpoints.down("xs")]: {
        fontFamily: "'Playfair Display', serif !important",
        color: "#313131",
        minHeight: "10px",
        fontSize: "0.8rem",
        fontWeight: "400",
      },
    },
    "& .MuiBox-root": {
      [theme.breakpoints.down("xs")]: {
        padding: "0.8rem",
      },
    },
    "& .MuiTypography-h3": {
      color: "#252525",
      fontSize: "1.625rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: "1.2rem",
      },
    },
    "& .MuiTypography-h4": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "500",
      marginRight: "0.5rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#3E3E3E",
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9rem",
      },
    },
    "& .MuiRating-root.Mui-disabled": {
      opacity: "1",
    },
    "& .MuiAvatar-root": {
      width: "65px",
      height: "65px",
    },
    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FF3939",
      background: "none",
    },
    "& .MuiToggleButton-root": {
      color: "#BFBFBF",
      background: "none",
      padding: "0.5rem",
      border: "none",
    },
    "& .MuiToggleButton-label": {
      fontSize: "1.3rem",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-multiline": {
      width: "100%",
      border: "1px solid #DBDBDB",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "0px",
    },
    "& .MuiOutlinedInput-notchedOutline ": {
      borderColor: "transparent",
      borderRadius: "6px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTabs-flexContainer": {
        overflow: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  },
  tabpannel: {
    marginTop: "2rem",
    padding: "1rem 2rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#676767",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 1rem",
      padding: "0rem",
      textAlign: "justify",
    },
  },
  tabpannel2: {
    marginTop: "2rem",
    padding: "1rem 2rem",
    "& .MuiTypography-body1": {
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#676767",
      textTransform: "capitalize",
    },
  },
  table: {
    "& .MuiTableHead-root": {
      background: "#FFFBF1",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    },
    "& .MuiTableCell-head": {
      fontSize: "1rem",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem !important",
      },
    },
    "& .MuiTableCell-root": {
      padding: "16px 20px",
      fontSize: "1rem",
      [theme.breakpoints.down("xs")]: {
        padding: "16px",
        fontSize: "0.5rem !important",
      },
    },
  },
}));

export default function SimpleTabs({ eBookDetail }) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  console.log("ebook", eBookDetail?.related_items);
  const descriptionRef = useRef(null);
  const bookDetailsRef = useRef(null);
  const reviewRef = useRef(null);
  const relatedProductsRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    let targetRef;
    switch (newValue) {
      case 0:
        targetRef = descriptionRef.current
          ? descriptionRef
          : bookDetailsRef.current
          ? bookDetailsRef
          : relatedProductsRef;
        break;
      case 1:
        targetRef = bookDetailsRef.current
          ? bookDetailsRef
          : relatedProductsRef;
        break;

      case 2:
        targetRef = relatedProductsRef;
        break;
      default:
        return;
    }

    const element = targetRef.current;
    const boundingBox = element.getBoundingClientRect();
    const offset = boundingBox.top + window.scrollY - 100;

    window.scrollTo({ top: offset, left: 0, behavior: "smooth" });
  };

  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "15px" : "15px",
        width: isActive ? "15px" : "15px",
        background: isActive ? "#FF8008" : "#EEE7D3",
        borderRadius: "100%",
      }}
    ></span>
  );

  const RelatedItemsHead = [
    {
      id: 1,
      // headingsmall: "Start practicing free quizzes online with MCQ questions.",
      headingmain: "Products Related To This Item",
    },
  ];
  function formatDate(isoDate) {
    const date = new Date(isoDate);
    return date.toLocaleDateString();
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none"
          aria-label="simple tabs example"
          variant="scrollable"
        >
          {eBookDetail?.description && (
            <Tab label="Description" {...a11yProps(0)} />
          )}

          {eBookDetail?.upload_plans.length > 0 && (
            <Tab label="E-Book Uploading Plan" {...a11yProps(1)} />
          )}

          {eBookDetail?.related_items.length > 0 && (
            <Tab label="Related Products" {...a11yProps(2)} />
          )}
        </Tabs>
      </AppBar>

      {eBookDetail?.description && (
        <div ref={descriptionRef}>
          <TabPanel className={classes.tabpannel}>
            {eBookDetail?.description}
          </TabPanel>
        </div>
      )}

      {eBookDetail?.upload_plans.length > 0 && (
        <div ref={bookDetailsRef}>
          <TabPanel className={classes.tabpannel}>
            <div className={classes.tabpannelhead}>
              <Typography variant="h3">E-book Uploading Plan</Typography>
            </div>
            <div className={classes.ebookuploadingplancontent}>
              {/* <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Topic</TableCell>
                    <TableCell align="center">status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eBookDetail?.upload_plans?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{row.name}</TableCell>      
                      <TableCell align="center"><img src={row?.payment_status === "paid" ? LockIcon : UnlockIcon} alt="img" /></TableCell>
                    </TableRow>
                  ))}


                 
                </TableBody>
              </Table>
            </TableContainer> */}
              <EbookUploadPlan data={eBookDetail} />
            </div>
          </TabPanel>
        </div>
      )}

      {eBookDetail?.related_items.length > 0 && (
        <div ref={relatedProductsRef}>
          <TabPanel className={classes.tabpannel2}>
            <SecHead Heading={RelatedItemsHead} />
            <RelatedBooksSection eBookDetail={eBookDetail?.related_items} />
          </TabPanel>
        </div>
      )}
    </div>
  );
}
