import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Button, Typography, Box, Modal } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import RectangleData from "./rectangledata";
import Rectangle from "./rectangle";
import practiceContext from "../context/PracticeContext";
import useCountdown from "./useCountdown";
import { Link, useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  quizBox: {
    // width: "400px",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    // height: "677px",
    textTransform: "none",
    borderRadius: "6px",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    marginTop: "1rem",
    padding: "1rem",
  },
  buttonOnTop: {
    height: "45px",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
    margin: "6px 0px 0px 0px",
    color: "white",
    borderRadius: "6px",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "'Jost', sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // position: "absolute",
    // top: "-15%",
  },
  whiteBox: {
    // width: "360px",
    height: "350px",
    // margin: "23px auto",
    borderRadius: "6px",
    backgroundColor: "white",
  },
  extraRectangle: {
    display: "flex",
    height: "45px",
    borderRadius: "6px 6px 0 0",
    alignItems: "center",
    padding: "0rem 0.8rem",
    background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    backgroundColor: "blue",
  },
  text: {
    fontSize: "18px",
    fontFamily: "'Playfair Display', serif !important",
    fontWeight: "700",
    color: "rgba(33, 33, 33, 1)",
  },
  square: {
    width: "35px",
    height: "35px",
    fontSize: "25px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "700",
    backgroundColor: "rgba(235, 235, 235, 1)",
    margin: "11px",
    display: "inline-flex",
    borderRadius: "6px",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(255, 255, 255, 1)",
    cursor: "pointer",
  },

  horizontalRectangles: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-43rem",
  },
  buttonBelowWhiteBox: {
    width: "100%",
    height: "45px",
    background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
    marginTop: "1.5rem",
    padding: "0.8rem 0",
  },
  smallSquare: {
    width: "10px",
    height: "10px",
    margin: "15px 0px 0px 4px",
  },
  redSquare: {
    backgroundColor: "rgba(255, 189, 100, 1)",
  },
  blueSquare: {
    backgroundColor: "rgba(20, 30, 48, 1)",
  },
  rectangleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  timerleftcont: {
    display: "flex",
  },
  quizmain: {
    width: "100%",
  },
  pallatebtncontainer: {
    overflow: "scroll",
    height: "86%",
    "&::-webkit-scrollbar ": {
      display: "none",
    },
  },
}));

const RenderEndQuizModal = ({ id }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [countDown, timerEnd, setTimerEnd] = useCountdown(10000);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (timerEnd) {
      handleClose();
      navigate(`/quizzes/${id}/result`);
    }
  }, [timerEnd]);
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography component={"span"}>
            end quiz time - {Math.floor(countDown / 1000)}
          </Typography>
          <Button
            onClick={() => {
              setTimerEnd(true);
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

function QuizContainer({ setTimeTaken, min, sec, timerEnd, countDown }) {
  const classes = useStyles();
  const { id } = useParams();
  const { quizQuestionObj, currentQuestion, setCurrentQuestion } =
    useContext(practiceContext);

  return (
    <>
      {!countDown && <RenderEndQuizModal id={id} />}
      <div className={classes.quizmain}>
        <div className={classes.rectangleContainer}>
          {RectangleData.map((rectangle, index) => (
            <Rectangle
              key={index}
              label={rectangle.label}
              smallSquareColor={rectangle.smallSquareColor}
            />
          ))}
        </div>
        <div className={classes.buttonOnTop}>
          <div className={classes.timerleftcont}>
            <AccessTimeIcon style={{ marginRight: "8px" }} />
            <div style={{ width: "100%" }}>
              Time Left - {min}:{sec} Mins
            </div>
          </div>
        </div>
        <Box className={classes.quizBox}>
          <Box className={classes.whiteBox}>
            {/* Title */}
            <div className={classes.extraRectangle}>
              <Typography className={classes.text}>
                {/* {quizQuestionObj?.quiz_name} */}
                Question Pallate:
              </Typography>
            </div>
            <div className={classes.pallatebtncontainer}>
              {quizQuestionObj?.questions?.map((question, index) => (
                <div
                  onClick={() => setCurrentQuestion(index + 1)}
                  key={index}
                  className={`${classes.square} ${
                    currentQuestion === index + 1
                      ? classes.blueSquare
                      : question?.options?.find((i) => i.user_answer)
                      ? classes.redSquare
                      : ""
                  }`}
                >
                  {index + 1}
                </div>
              ))}
            </div>

            {/* Attempted, Unattempted, currentQuestion */}
            <div className={classes.horizontalRectangles}>
              {RectangleData.map((rectangle, index) => (
                <Rectangle
                  key={index}
                  label={rectangle.label}
                  smallSquareColor={rectangle.smallSquareColor}
                />
              ))}
            </div>
          </Box>
          <Link to={`/quizzes/${id}/result`}>
            <Button
              className={classes.buttonBelowWhiteBox}
              onClick={() => {
                let msUsed =
                  60000 * quizQuestionObj?.duration -
                  (60000 * min + 1000 * sec);
                let min2 = Math.floor((msUsed / 1000 / 60) << 0);
                let sec2 = Math.floor((msUsed / 1000) % 60);
                setTimeTaken(min2, sec2);
              }}
            >
              Submit Quizz
            </Button>
          </Link>
        </Box>
      </div>
    </>
  );
}

export default QuizContainer;
