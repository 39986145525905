import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import SecHead from "../../Home/sectionHeading/sectionheading";
import EBookCard from "../../practicecomponent/cards/eBookCard";
import EBookCardMobile from "../../practicecomponent/cards/eBookCardMobile";
import sscbooks from "../../image/mytestseries/ebooks/sscbooks.png";
import { getRecomndadEbooks } from "../../../service/services";
import EBookCardDash from "./EbookCardDash";
import EBookCardMobileDash from "./EbookCardMobileDash";
import ExclusiveEbookCard from "../../Home/exclusivebooks/exclusiveebookcard";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3rem",
    "& .slick-slide": {
      display: "flex",
      // width: "14.2% !important",
      height: "440px",
      justifyContent: "center",
      paddingTop: "0.5rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      "& div": {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "2rem 1.5rem 2rem 1.5rem !important",
        height: "506px",
      },
    },
    "& .slick-dots": {
      [theme.breakpoints.down("xs")]: {
        bottom: "17px",
      },
    },
    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },
  },
  cardContainer: {
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

const Data = [
  {
    id: 1,
    images: sscbooks,
    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
  {
    id: 2,
    images: sscbooks,

    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
  {
    id: 3,
    images: sscbooks,

    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
  {
    id: 4,
    images: sscbooks,

    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
  {
    id: 5,
    images: sscbooks,

    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
  {
    id: 6,
    images: sscbooks,

    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
  {
    id: 7,
    images: sscbooks,

    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
  {
    id: 8,
    images: sscbooks,

    ebook_name: "SBI Cleark",
    sub_heading: "personalizsed mentorship",
    publisher: "Mr. kumar",
    rating: "0",
    review_count: "0",
    total_cost: "0",
    mrp: "0",
    discount: "0",
  },
];

const RecommendedEbooks = () => {
  const classes = useStyles();

  let mql = window.matchMedia("(max-width: 650px)");

  const Heading = [
    {
      id: 1,
      headingsmall: "Explore More",
      headingmain: "Recommended E-Books",
    },
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await getRecomndadEbooks(token);
        console.log("API Response:", response.data?.eBooks);
        setData(response.data?.eBooks);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const initialSlidesToShow = window.innerWidth < 480 ? 1 : 4;

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: initialSlidesToShow,
    slidesToScroll: initialSlidesToShow,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth < 480 ? 1 : 3;
      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow: newSlidesToShow,
        slidesToScroll: newSlidesToShow,
      }));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("data>>>>>>",data);

  return (
    <>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.cardContainer}>
          {/* {data.map((data) => (
            !mql.matches ? (<EBookCardDash Data={data} />) : (<EBookCardMobileDash  Data={data}/>)
          ))} */}
          {/* {data.map((data) => (
            !mql.matches ? (<ExclusiveEbookCard data={data} />) : (<EBookCardMobileDash  Data={data}/>)
          ))} */}
          <Slider {...sliderSettings} className={classes.sliderstyle}>
            {data?.map((data) => (
              <ExclusiveEbookCard data={data} />
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RecommendedEbooks;
