import React, { useEffect, useState, useContext } from "react";
import { Breadcrumbs, Link, Typography, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Categories from "./categories";
import Subcategory from "./subcategory";
import Exams from "./exams";
import CardsSection from "./cards";
import QuikLinks from "./quiklinks";
import ConstantPracticeData from "./constant";
import practicecomponentcontext from "./context";
import appContext from "../../context/AppContext";
import PageHeadingBg from "../image/pageheadbg/pageheadingbg.jpg";
import FAQSection from "../affilates/FAQSection";
import DownloadApp from "../notification/downloadApp";
import { getFAQ } from "../../service/services";

const useStyles = makeStyles((theme) => ({
  pageheadingmain: {
    width: "100%",
    marginBottom: "13rem",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      padding: 0,
    },
  
  },
  pageheadingmaininner: {
    margin: "0 auto",
    paddingBottom: "1.5rem",
    paddingTop: "1rem",
    backgroundImage: `url(${PageHeadingBg})`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem 1.5rem 1rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1.5625rem",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 6,
      },
    },
    "& .MuiBreadcrumbs-ol": {
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorInherit": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "#FF8F12",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
    },
  },
  pageheading: {
    padding: "1rem 0 2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
    },
  },
  pageheadhr: {
    margin: "0",
    width: "6rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  quizzcontentcontainer: {
    maxWidth: "1250px",
    margin: "0 auto",
    marginTop: "4.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "3.8rem",
    fontFamily: "'Playfair Display', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      fontFamily: "'Playfair Display', serif !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  w25: {
    width: "24%",
  },
  w74: {
    width: "74%",
  },
}));

function PracticeComponent(props) {
  const { practicepathname } = props;
  const classes = useStyles();
  const AppContext = useContext(appContext);
  const [quiz, setQuiz] = useState({
    categoryName: "",
    categories: -1,
    subCategories: null,
    exams: null,
    examName: "",
  });

  const { pathname } = useLocation();
  const PracticeComponentContext = useContext(practicecomponentcontext);
  let mql = window.matchMedia("(max-width: 650px)");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const FAQTypeId = {
    type: AppContext?.coursesData?.exam ? "by_examId" : "by_subcategoryId",
    id: AppContext?.coursesData?.exam ? AppContext?.coursesData?.exam : AppContext?.coursesData?.subcategory || quiz?.subCategories
  };
  


  useEffect(() => {
    const { category, categoryName, subcategory, exam, exam_name } =
  
      AppContext?.coursesData || {};
  
    if (ConstantPracticeData?.[practicepathname]?.level3Name === "section_name") {
      setQuiz({
        categoryName: categoryName || "",
        categories: category || -1,
        subCategories: subcategory || null,
        exams: null,
        examName: "", 
      });
    } else if (ConstantPracticeData?.[practicepathname]?.level3Name === "exam_name") {
      
      setQuiz({
        categoryName: categoryName || "",
        categories: category || -1,
        subCategories: subcategory || null,
        exams: exam || null,
        examName: exam_name || "",
      });
    }
  }, [AppContext?.coursesData, ConstantPracticeData, practicepathname]);
 
  const getQuizSelectedTitle = () => {
    return (
      PracticeComponentContext?.[
        ConstantPracticeData?.[practicepathname]?.list
      ]?.[0]?.["categories"]
        ?.find((categories) => categories.id === quiz?.categories)
        ?.["subCategories"]?.find(
          (subCategories) => subCategories.id === quiz?.subCategories
        )?.["sub_category_name"] +
      " " +
      ConstantPracticeData?.[practicepathname]?.title
    );
  };

  const getCategory = () => {
    return PracticeComponentContext?.[
      ConstantPracticeData?.[practicepathname]?.list
    ];
  };
  const getMainTitle = (isAll) => {
    return isAll
      ? `All ${ConstantPracticeData?.[practicepathname]?.title}`
      : ConstantPracticeData?.[practicepathname]?.title;
  };

  const generateHeading = () =>
    quiz?.subCategories
      ? quiz?.examName
        ? quiz?.examName?.charAt(0)?.toUpperCase() +
        quiz?.examName?.slice(1) +
        " Exams " +
        getMainTitle(false)
        : getQuizSelectedTitle()
      : getMainTitle(true);
    let hello = getCategory();
    const [faq,setFaq]=useState([]);

    useEffect(() => {
      async function fetchData(id, type) {
        try {
          const response = await getFAQ(id, type);
          console.log("API Response:", response.data);
          setFaq(response.data.faqs);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    
      fetchData(FAQTypeId.id, FAQTypeId.type);
    }, [FAQTypeId.id, FAQTypeId.type]);
    
   
  return (
    <>
      <div className={!mql.matches && classes.pageheadingmain}>
        <div className={classes.pageheadingmaininner}>
          <div className={classes.container}>
            <div className={[classes.pageheading]}>
              {!mql.matches && (
                <Typography variant="h6" component="h2">
                  {/* Explore Practice Sets */}
                </Typography>
              )}
              <Typography
                variant="h2"
                component="h2"
                className={classes.heading}
              >
                {generateHeading()}
                {!mql.matches && <hr className={classes.pageheadhr} />}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumb}
              >
                {ConstantPracticeData?.[practicepathname]?.breadcrumbs?.map((b, index) => index !== ConstantPracticeData?.[practicepathname]?.breadcrumbs?.length - 1 && <Link color="inherit" href="#"> {b?.title} </Link>)}
                <Typography color="textPrimary">{getMainTitle()}</Typography>
              </Breadcrumbs>
            </div>
            {!mql.matches && (
              <div>
                <Categories
                  quiz={quiz}
                  setQuiz={setQuiz}
                  ExamData={getCategory()}
                />
              </div>
            )}
          </div>
        </div>
        <Subcategory
          quiz={quiz}
          setQuiz={setQuiz}
          ExamData={getCategory()}
          media={mql}
          practicepathname={practicepathname}
        />
        {/* <Banner media={mql} /> */}
        {mql.matches ? (
          <div
            style={{ background: "#FFF7E4", padding: "1rem 0 11rem 0", marginTop: 15 }}
          >
            <CardsSection
              quiz={quiz}
              setQuiz={setQuiz}
              ExamData={getCategory()}
              media={mql}
              pageLocation={practicepathname}
              practicepathname={practicepathname}
            />
            {!practicepathname.includes("/") && FAQTypeId.id && <FAQSection Faq={faq?.questions} id={FAQTypeId.id} type={ FAQTypeId.type}/>}
          </div>
        ) : (
         <>
         <div className={classes.quizzcontentcontainer}>
            {!practicepathname.split("").find((el) => el === "/") && <div className={classes.w25}>
              <Exams quiz={quiz} setQuiz={setQuiz} ExamData={getCategory()} practicepathname={practicepathname} />
              <QuikLinks />
              {AppContext?.user?.app_status === "false" && <DownloadApp />}
            </div>}
            <div className={classes.w74}>
              <CardsSection
                quiz={quiz}
                setQuiz={setQuiz}
                ExamData={getCategory()}
                media={mql}
                pageLocation={practicepathname}
                practicepathname={practicepathname}
              />
            </div>
          </div>
          {!practicepathname.includes("/") && FAQTypeId.id && faq?.questions && <FAQSection Faq={faq?.questions} id={FAQTypeId.id} type={ FAQTypeId.type}/>}
         </>
        )}
        <div>
  {/* {FAQTypeId.id && <FAQSection Faq={faq?.questions} ExamId={FAQTypeId.id} />} */}
</div>
      </div>
         
    </>
  );
}
export default PracticeComponent;
