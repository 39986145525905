import React, { useRef, useState } from "react";
import { Button, Divider, TextField, Typography, makeStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as EmojiIcon } from "../../image/dashboard/emojiicon.svg";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DoubtForme } from "../../../service/services";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    background: "var(--white-100, #FFF)",
    filter: "drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.06))",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "1rem",
      marginTop: "1.5rem",
    },
    "& .MuiTypography-h6": {
      color: "#252525",
      fontFamily: "Playfair Display",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 700,
      marginLeft: "1rem",
      lineHeight: "28px" /* 127.273% */,
    },
    "& .MuiTypography-h5": {
      color: "#5B5B5B",
      fontFamily: "Jost",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px" /* 127.273% */,
      marginLeft: "1.5rem",
      marginTop: "1.5rem",
    },
  },

  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: "0.8rem",
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "55%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  headicon: {
    marginRight: "0.8rem",
  },

  viewbtn1: {
    "&.MuiButton-root": {
      color: "#303030",
      textTransform: "capitalize",
      padding: "0.3rem 1.2rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
  
  line: {
    width: "27%",
    marginLeft: "16%",
    borderRadius: "0.5625rem",
    background:
      "linear-gradient(270deg, rgba(255, 128, 8, 0.60) 44.89%, rgba(255, 200, 55, 0.60) 100%)",
  },
  yellowbox: {
    marginTop: "2rem",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    '& .ql-toolbar':{
      borderRadius: '10px 10px 0 0',
    },
    "& .ql-container":{
    height: '160px',
    borderRadius: '0 0 10px 10px',
    },
    "& .MuiFilledInput-root": {
      background: "#FFF8ED",
      border: "1px dashed #FFC261",
    },
  },
  sendbutton: {
    height: "3rem",
    width: "16%",
    borderRadius: "20px",
    background: "#243B55",
    marginTop: "2rem",
  },
  Sendbtn: {
    color: " var(--white-100, #FFF)",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    marginLeft: "2rem",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    },
  },
  btncontainer: {
    marginTop: "1rem",
    display: 'flex',
    justifyContent: 'flex-end',
  },
  viewbtn: {
    "&.MuiButton-root": {
        width: "21%",
        background: "#243B55",
        color: "#FFF",
        textTransform: "capitalize",
        padding: "0.3rem 1.2rem",
        borderRadius: "0.375rem",
        fontFamily: "Jost",
        fontWeight: "400",
        fontSize: "0.875rem",
    },
  },
}));

function DoubtSolved({ CardData, setOnViewClicked , learnerMenu , handleChange }) {
  const classes = useStyles();
  const quillRef = useRef(null);
  const [newQuestion, setNewQuestion] = useState("");
const [image,setImage]=useState("")
  const handleQuestionChange = (content, delta, source, editor) => {
    if (source === "user") {
      const imageDelta = delta.ops.find((op) => op.insert && op.insert.image);
      if (imageDelta) {
        const imageBase64 = imageDelta.insert.image;
        const base64WithoutPrefix = imageBase64.split(";base64,")[1];
        const binaryString = atob(base64WithoutPrefix);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/jpeg" });
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        console.log(file, "okokok");
        setImage(file);
  
        // Append the image tag to the newQuestion variable
        const imageTag = `<img src="${imageBase64}" alt="Inserted Image" />`;
        setNewQuestion(prevQuestion => prevQuestion + imageTag);
        return;
      }
      // If no image is inserted, set the new question to the editor's text
      setNewQuestion(editor.getHTML()); // Use getHTML to get the HTML content
      console.log(newQuestion, "okokok");
    }
  };
  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        { script: "sub" },
        { script: "super" },
        { color: [] },
        { background: [] },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "list",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "link",
    "blockquote",
    "code-block",
    "align",
    "image",
  ];

  const handleSubmitQuestion = async () => {
    try {
      if (!newQuestion) {
        toast.error("Please enter your questions");
        return;
      }
      const token = localStorage.getItem("token");
      const user = {
        doubtForum: {
          question: newQuestion
        }
      };
      
      // formData.append("doubtForum", JSON.stringify({ question: newQuestion }));

      
      const response = await DoubtForme(user, token);
      toast.success("Question submitted successfully!");
      console.log("API Response:", response);
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("Failed to submit question.");
    }
  
      setNewQuestion("");

    
  };


  return (
    <>
      <div className={classes.MainContainer}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <EmojiIcon className={classes.headicon} />
          <Typography variant="h3">Doubt Forum</Typography>
        </div>
        <div>
          <Button className={classes.viewbtn1} onClick={() => {handleChange(10)}}>
            View All <KeyboardArrowRightIcon style={{ fontSize: "medium" }} />
          </Button>
        </div>
      </div>
      <Divider className={classes.line} />

        <div className={classes.yellowbox}>
          {/* <TextField
          id="filled-multiline-static"
          multiline
          rows={7}
          disableUnderline
          variant="filled"
          InputProps={{ classes }}
          placeholder="Type Your Doubt Here..."
        /> */}
         <ReactQuill
            ref={quillRef}
            value={newQuestion}
            onChange={handleQuestionChange}
            modules={modules}
            formats={formats}
            className={classes.placeholder}
            placeholder="Start asking questions"
            style={{ width: "100%" }}
          />
        </div>
        <div className={classes.btncontainer}>
        <Button className={classes.viewbtn}onClick={handleSubmitQuestion}>SEND</Button>
      </div>
      </div>
    </>
  );
}
export default DoubtSolved;
