/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { alpha, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { InputAdornment } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  grow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0.3rem 1rem",
    borderRadius: 7,
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "90%",
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%) !important",
    },
    // background:
    //   "linear-gradient(90deg, rgba(255,128,8,0.3) 0%, rgba(255,200,55,0.3) 100%);",
    // "&:hover": {
    //   background:
    //     "linear-gradient(90deg, rgba(255,128,8,0.5) 0%, rgba(255,200,55,0.5) 100%);",
    // },
    // "& .MuiSvgIcon-root": {
    //   width: "1.5em",
    //   height: "1.5em",
    // },
    "& .MuiSvgIcon-root": {
      width: "1.5em",
      height: "1.5em",
      top: 0,
      right: 15,
      pointerEvents: "none",
      [theme.breakpoints.down("xs")]: {
        color: "#C3C3C3",
      },
    },
    "& .MuiInputBase-root": {
      paddingRight: '20px !important',
  },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.87) !important",
      borderWidth: "1px !important",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: "1.5px 4px",
        color: "white",
      },
    "& .MuiIconButton-root": {
      color: "rgba(255, 255, 255, 0.54)",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

export default function SearchInput({ allExams, search, handleExamClick }) {
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      <Autocomplete
        id="combo-box-demo"
        options={allExams || []}
        value={search}
        getOptionLabel={(option) => option.exam_name || []}
        style={{ width: "100%" }}
        onChange={handleExamClick}
        popupIcon
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              placeholder="SEARCH YOUR TEST SERIES"
              variant="outlined"
            />
          </>
        )}
      />
      <SearchIcon />
    </div>
  );
}
