import React from "react";
import { IconButton, Typography, makeStyles } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { ReactComponent as DeleteSvg } from "../image/shoppingcartvector/deletesvg.svg";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "1.5rem 0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content_course: {
    display: "flex",
    width: "80%",
  },
  description: {
    marginLeft: "1rem",
    width: "47%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  image: {
    background: "#FFE8B5",
    padding: "0.8rem 0.2rem 0.3rem 0.2rem",
    borderRadius: "0.375rem",
    "& img": {
      width: "80px",
    },
  },
  ratings: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "75%",
    "& .MuiTypography-h6": {
      fontSize: "0.78rem",
      fontWeight: "500",
      fontFamily: "'Jost', sans-serif",
    },
    "& .MuiTypography-caption": {
      fontSize: "0.78rem",
      fontWeight: "400",
      fontFamily: "'Jost', sans-serif",
      color: "var(--gray-500, #8C94A3)",
    },
  },
  title: {
    "& .MuiTypography-h3": {
      fontSize: "0.8rem",
      marginTop: "0rem",
      fontWeight: "700",
      fontFamily: "'Playfair Display', serif !important",
      whiteSpace: "wrap",
      height: "45px",
      overflow: "hidden",
      textOverflow: `"..."`,
    },
  },
  content_quantity: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #FFBF35",
    background: "#FFF9ED",
    borderRadius: "5px",
    padding: "0rem 0.5rem",
    "& .MuiTypography-h6": {
      fontFamily: "Jost",
      fontSize: "1.375rem",
      fontWeight: "600",
      cursor: "default",
    },
    "& .MuiIconButton-root": {
      padding: "0.1rem",
      background: "#FFFFFF",
      color: "#424141",
      fontSize: "1.2rem",
      fontWeight: "600",
      margin: "0.3rem 0",
      borderRadius: "0",
    },
  },
  content_action: {
    width: "20%",
  },
  content_price: {
    "& .MuiTypography-h3": {
      color: "#FF3939",
      fontSize: "1rem",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
    },
  },
  content_delete: {
    marginTop: "1rem",
    "& .MuiIconButton-root": {
      background: "#FFEEE8",
      borderRadius: "2px",
      color: "#FF3939",
    },
    "& .MuiIconButton-label": {
      width: "83%",
    },
  },
  hr: {
    border: "1px solid #E9EAF0",
    margin: "0 0.5rem",
  },
}));

function MobileShoppingCartRow({
  data,
  removefromCartListAPI,
  updateCartListAPI,
}) {
  const [quantity, setQuantity] = React.useState(1);
  const classes = useStyles();

  const hasBooks = data?.product_type === "books";

  const handleIncrementDecrement = (action, id, quantity) => {
    if (quantity === 0) return;
    updateCartListAPI(id, data.product_id, quantity);
  };
  return (
    <div key={data.id}>
      <div className={classes.content}>
        <div className={classes.content_course}>
          <div>
            <div className={classes.image}>
              <img
                src={data?.product?.image_url || data?.image_url}
                alt={data?.product?.ebook_name || data?.product?.book_name}
              />
            </div>
          </div>
          <div className={classes.description}>
            <div className={classes.title}>
              <Typography variant="h3">
                  {data?.product?.ebook_name ||
                  data?.product?.book_name ||
                  data?.product?.product_name || data?.product_name}
              </Typography>
            </div>
            <div className={classes.content_quantitycontainer}>
              {hasBooks && (
                <div className={classes.content_quantity}>
                  <IconButton
                    onClick={() => {
                      handleIncrementDecrement(
                        "remove",
                        data.id,
                        data.quantity - 1
                      );
                    }}
                  >
                    <AiOutlineMinus />
                  </IconButton>
                  <Typography variant="h6">{data.quantity}</Typography>
                  <IconButton
                    onClick={() => {
                      handleIncrementDecrement(
                        "add",
                        data.id,
                        data.quantity + 1
                      );
                    }}
                  >
                    <AiOutlinePlus />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.content_action}>
          <div className={classes.content_price}>
            <Typography variant="h3">
              ₹{data.total_cost || data?.product?.cost}
            </Typography>
          </div>
          <div className={classes.content_delete}>
            <div className={classes.btn2}>
              <IconButton onClick={() => removefromCartListAPI(data.id)}>
                {console.log("data123", data.id)}
                <DeleteSvg />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <hr className={classes.hr} />
    </div>
  );
}

export default MobileShoppingCartRow;
