import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import footerlistIcon from "../image/footerimages/footerlistIcon.svg";
import QuickLinks from "./footercompanylist";
import footerproductlist from "./footerproductlist";
import footerpartnerlist from "./footerpartnerslist";
import QuickLinkList from "./quicklinklist";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  footerlistsectionmain: {
    "& .MuiAccordionSummary-content": {
      margin: '0',
      display: "flex",
      flexGrow: "0",
      justifyContent: "center",
      alignItems: "flex-start",
      '& .MuiTypography-body1':{
        color: '#FFF',
        fontFamily: "'Playfair Display', serif !important",
        fontSize: '0.875rem',
        fontWeight: '600',
        textTransform: 'capitalize',
        letterSpacing: '0.1rem',
        lineHeight: '2rem',
      },
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: "#fff",
    },
    "& .MuiIconButton-root": {
      color: "white",
      padding: '0',
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  footerheadhr: {
    margin: "0",
    marginTop: "10px",
    width: "38px",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "2px",
  },
  link: {
    color: "white",
    textDecoration: 'none',
    "&:hover":{
    textDecoration: 'none',
    },
  },
}));

const FooterListSectionMobile = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerlistsectionmain}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Company
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.footercompanysectionlist}>
            {QuickLinks.map((data) => (
              <Link to={data.to} className={classes.link}>
                <ListItem>
                  <ListItemIcon>
                    <img src={footerlistIcon} alt="img" />
                  </ListItemIcon>

                  <ListItemText primary={data.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Products
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.footerproductsectionlist}>
            {footerproductlist.map((data) => (
              <Link to={data.to} className={classes.link}>
                <ListItem align="center">
                  <ListItemIcon>
                    <img src={footerlistIcon} alt="img" />
                  </ListItemIcon>

                  <ListItemText primary={data.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Quick Links
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.footerQuickLinkssectionlist}>
            {QuickLinkList.map((data) => (
              <Link to={data.to} className={classes.link}>
                <ListItem>
                  <ListItemIcon>
                    <img src={footerlistIcon} alt="img" />
                  </ListItemIcon>
                  <ListItemText primary={data.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
          Collaborate
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.footerMocksectionlist}>
            {footerpartnerlist.map((data) => (
              <Link to={data.to} className={classes.link}>
                <ListItem>
                  <ListItemIcon>
                    <img src={footerlistIcon} alt="img" />
                  </ListItemIcon>
                  <ListItemText primary={data.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default FooterListSectionMobile;