import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import SinglePageContent from "./singlepagecontent";
import PageHead from "../../../pageheading/pageheading";
import practicecomponentcontext from "../../context";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

function NotesSinglePageDescription() {
  const classes = useStyles();
  const {id} = useParams()
  const {getNoteDetails, noteDetail} = useContext(practicecomponentcontext);

  useEffect(()=>{
    getNoteDetails(id)
  },[id])
  const Heading = [
    {
      id: 1,
      // headingsmall: "Explore Your Study",
      // headingmain: "Highlights of Indian History",
      breadcrumb:"Notes / Product"
    },
  ];
  console.log("getNoteDetails",noteDetail)
  return (
    <div>
      <PageHead Heading={Heading} noteDetail={noteDetail}/>
      <SinglePageContent  noteDetail={noteDetail}/>
    </div>
  );
}
export default NotesSinglePageDescription;
