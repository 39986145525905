import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";

function IconComponent({ iconName }) {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const importIcon = async () => {
      try {
        let importedIcon;
        if (window.innerWidth <= 768) {
          importedIcon = await import(
            `../image/mobilehambergervector/${iconName}.svg`
            
          );
        } else {
          importedIcon = await import(`../image/dashboard/${iconName}.svg`);
        }
        setIcon(importedIcon.default);
      } catch (error) {
        console.error(`Error loading icon ${iconName}:`, error);
      }
    };
    importIcon();
  }, [iconName]);

  return icon ? <SVG src={icon} /> : null;
}

export default IconComponent;