import { useContext } from "react";
import { useEffect, useState } from "react";
import practiceContext from "../context/PracticeContext";

const useCountdown = (millisecond) => {
  const [countDown, setCountDown] = useState(millisecond);
  const [timerEnd, setTimerEnd] = useState(false);
  const { quizQuestionObj } = useContext(practiceContext);

  useEffect(() => {
    if (countDown <= 0) {
      setTimerEnd(true);
    }
    if (quizQuestionObj && quizQuestionObj.duration) {
      const timerDuration = quizQuestionObj.duration * 60 * 1000;
      setCountDown(timerDuration);
      const interval = setInterval(() => {
        setCountDown((prevCountDown) => {
          if (prevCountDown > 0) {
            return prevCountDown - 1000;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [quizQuestionObj]);

  return [countDown, timerEnd, setTimerEnd];
};

export default useCountdown;
