import React from "react";
import PageHeading from "../pageheading/pageheading";
import ShoppingCartContent from "./ShoppingCartContent";
import ShoppingCartContentMobile from "./ShoppingCartContentMobile";

function ShoppingCart() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Your Study",
      headingmain: "Shopping Cart",
      breadcrumb: "Add to cart",
    },
  ];
  return (
    <div>
      <PageHeading Heading={Heading} />
      <ShoppingCartContent />
      <ShoppingCartContentMobile />
    </div>
  );
}
export default ShoppingCart;
