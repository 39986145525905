import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecHead from "../Home/sectionHeading/sectionheading";
import BlockOnscreen from "./blockonscreen";
import OnScreenMarkingData from "./onscreenmarkingdata";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "4.5rem",
    padding: "4rem 0",
    backgroundColor: "rgba(255, 249, 236, 1)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4rem",
  },
  mainHeading: {
    marginTop: "2rem",
    width: "100%",
    textAlign: "center",
  },
  blockContainer: {
    width: "50%",
    flex: "1",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  blockContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));

function OnscreenMarking() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Empowering Growth Through Cutting-Edge Digital Assessment Solutions.",
      headingmain: "Features Of Onscreen Marking System",
    },
  ];

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} className={classes.mainHeading} />
        <div className={classes.onlineeducationmain}>
          {OnScreenMarkingData.map((block) => (
            <div key={block.id} className={classes.blockContainer}>
              <BlockOnscreen
                iconName={block.iconName}
                heading={block.heading}
                subheading={block.subheading}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OnscreenMarking;
