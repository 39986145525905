import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SavedAddress from "./SavedAddress";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  getAllAddress,
  getCities,
  getStates,
  saveAddress,
  deleteAddress,
} from "../../service/services";
import makePayment from "../../Utils/makePayment";
import AddCartContext from "../../context/AddCartContext/AddCartContext";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 auto",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1250px",
    marginTop: "1rem",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "2rem 0.6rem",
    },
  },
  addresssection: {
    width: "55%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiFormLabel-root": {
      color: "#1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.5625rem",
      fontStyle: "normal",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
  },
  savedaddresscheckbox: {
    marginTop: "1rem",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#243B55",
    },
  },
  paymentcheckbox: {
    border: "1px solid #E6E6E6",
    margin: "1rem 0 0rem 0",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#243B55",
    },
  },
  addaddress: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    justifyContent: "space-between",
    "& .MuiDivider-root": {
      width: "32%",
      [theme.breakpoints.down("xs")]: {
        width: "20%",
      },
    },
  },
  addaddresstext: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    borderRadius: "2px",
    padding: "0.5rem",
    color: "#FFF",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  inputcontainermain: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexWrap: 'wrap',
    },
  },
  inputcontainer: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormLabel-root": {
      color: "#1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: "600",
      margin: "1rem 0 0.5rem 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "13px 14px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.87) !important",
      borderWidth: "1px !important",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: "4.5px 4px",
      },
  },
  w100: {
    width: "100%",
  },
  w32: {
    width: "32%",
    [theme.breakpoints.down("xs")]: {
      width:'100% !important'
    },
  },
  dflex: {
    display: "flex",
  },
  bluebtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    width: "20%",
    margin: "1rem 0",
    alignSelf: "end",
  },
  bluebtn100: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    width: "100%",
    margin: "1rem 0",
    alignSelf: "end",
  },
  checkoutpreviewsectionmain: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  previewsection: {
    border: "1px solid #E6E6E6",
    padding: "1rem 1rem",
    "& .MuiTypography-h5": {
      color: "#1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: "500",
    },
    "& .MuiDivider-root": {
      margin: "1rem 0",
    },
  },
  previewsectioncardinner: {
    display: "flex",
  },
  previewsectioncard: {
    marginTop: "1rem",
    justifyContent: "space-between",
    display: "flex",
    "& .MuiTypography-h3": {
      color: "#A1A5B3",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: "400",
    },
    "& .MuiTypography-h4": {
      color: "#4E5566",
      fontFamily: "'Jost', sans-serif",
      fontSize: " 0.75rem",
      fontStyle: "normal",
      fontWeight: "400",
    },
    "& .MuiTypography-h5": {
      color: " #1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      fontWeight: "500",
    },
    "& .MuiTypography-h6": {
      color: "#FF3939",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
    },
  },
  imgcontainer: {
    background:
      "linear-gradient(0deg, #FFE8B5 0%, #FFE8B5 100%), url(<path-to-image>), lightgray 50% / cover no-repeat",
    padding: "1rem 1rem",
    marginRight: "1rem",
    "& img": {
      maxWidth: "100px",
      width: "100%",
      objectFit: "cover",
    },
  },
  orderitem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-h5": {
      color: "#1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
    },
    "& .MuiTypography-h6": {
      color: "#FF3939",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: "600",
    },
  },
  ordercost: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0.5rem",
    "& .MuiTypography-h4": {
      color: "#000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
    },
    "& .MuiTypography-h5": {
      color: "#6E7485",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      fontWeight: "400",
    },
    "& .MuiTypography-h6": {
      color: "#D4D4D4",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      fontWeight: "400",
    },
  },
  billamount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-h5": {
      color: "#1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
    },
    "& .MuiTypography-h6": {
      color: "#FF3939",
      fontFamily: "'Jost', sans-serif",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "600",
    },
  },
  ordersummaryheading: {
    "& .MuiTypography-h5": {
      color: "#1D2026",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: "600",
      marginBottom: "0.5rem",
    },
  },
  order: {
    marginTop: "1rem",
  },
}));

function CheckoutPageContent() {
  const classes = useStyles();
  const { cartState, removefromCartListAPI } = useContext(AddCartContext);
  const [value, setValue] = useState("Online");
  const [address, setAddress] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateObject, setStateObject] = useState([]);
  const [cityObject, setCityObject] = useState([]);
  const [selected, setSelected] = useState({});
  const [newAddress, setNewAddress] = useState({
    address2: "",
    address3: "",
    landmark: "",
    city: "",
    state: "",
    pin_code: "",
  });

  const User = useContext(AppContext);
  const bookCartList =
    cartState.cart?.products?.filter((_) => _.product_type === "books") || [];
  const eBookCartList =
    cartState.cart?.products?.filter((_) => _.product_type !== "books") || [];
  console.log(
    "🚀 ~ file: CheckoutPageContent.js:261 ~ CheckoutPageContent ~ address:",
    cartState,
    eBookCartList
  );
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const selectAddress = (event) => {
    const selectedAdress =
      address.find(
        (_) => `${_?.id} ${_.address2} ${_.address3}` === event.target.value
      ) || {};
    setSelected(selectedAdress);
  };

  const handleDeleteAddress = (id) => {
    deleteAddress(id)
      .then((res) => {
        console.log("delete123", res);
        const data = address?.filter((el) => el?.id !== id);
        setAddress(data);
        toast.success(res.data.message || "Address Deleted");
      })
      .catch((e) => console.log("delete1234", e));
  };
  useEffect(() => {
    getAllAddress()
      .then((res) => {
        setAddress(res.data.address);
        setSelected(res.data.address?.[0]);
        console.log("res.data.address123", res.data);
      })
      .catch((e) => console.log("dalsfknlk", e));

    getStates()
      .then((res) => {
        setStates(res.data.states);
        getCities(res.data.states?.[0]?.id)
          .then((res) => {
            setCities(res.data.cities);
          })
          .catch((e) => console.log("cities error", e));
      })
      .catch((e) => console.log("state error", e));
  }, [User.isLoggedIn]);

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  const checkoutHandler = () => {
    // If no errors, proceed with the payment
    if (value === "Online") handleOnlinePayment(cartState.sub_total_cost);
    else handleOfflinePayment();
  };

  const handleNewAddress = (e) => {
    if (e.target.name === "pin_code") {
      // Limit the pin code to 6 digits
      const pinCode = e.target.value.slice(0, 6);
      setNewAddress({ ...newAddress, [e.target.name]: pinCode });
    } else {
      setNewAddress({ ...newAddress, [e.target.name]: e.target.value });
    }
  };

  const saveNewAddress = () => {
    const errors = {
      address2: newAddress.address2
        ? ""
        : "House No., Building Name is required",
      address3: newAddress.address3
        ? ""
        : "Road name, Area, Colony is required",
      landmark: newAddress.landmark ? "" : "Landmark is required",
      city: newAddress.city ? "" : "City is required",
      state: newAddress.state ? "" : "State is required",
      pin_code: validatePinCode(newAddress.pin_code),
    };

    setValidationErrors(errors);

    // Check if there are any errors
    if (Object.values(errors).some((error) => error !== "")) {
      toast.error("Please fill all required fields correctly.");
      return;
    }

    const isValid = Object.entries(newAddress)
      .map(([key, value]) => {
        if (key === "landmark") return true;
        return key !== "landmark" && value !== "";
      })
      .every((_) => _);

    if (!isValid) return null;
    saveAddress({ address: newAddress })
      .then((res) => {
        const updatedList = [...address];
        console.log("object123", res);
        updatedList.push(res.data.address);
        console.log("object1234", updatedList);
        if (updatedList.length === 1) setSelected(updatedList?.[0]);
        setAddress(updatedList);
        setNewAddress({
          address2: "",
          address3: "",
          landmark: "",
          city: "",
          state: "",
          pin_code: "",
        });
        setStateObject([]);
        setCityObject([]);
      })
      .catch((e) => console.log("error >>", e));
  };

  const [validationErrors, setValidationErrors] = useState({
    address2: "",
    address3: "",
    landmark: "",
    city: "",
    state: "",
    pin_code: "",
    // other fields if necessary
  });

  const validatePinCode = (pinCode) => {
    if (pinCode.length !== 6) {
      return "Pin code must be exactly 6 digits";
    }
    return "";
  };

  const handleState = (e , value) => {
    setNewAddress({ ...newAddress, state: e.target.textContent });
    setStateObject(value);
    setCityObject();
    const findId = states.find((_) => _.name === e.target.textContent)?.id;
    if (findId > -1) {
      getCities(findId)
        .then((res) => {
          setCities(res.data.cities);
        })
        .catch((e) => console.log("cities error", e));
    }
  };

  const handleCity = (e , value) => {
    setNewAddress({ ...newAddress, city: e.target.textContent });
    setCityObject(value);
  };

  const handleOfflinePayment = () => {};
  console.log(
    "cartState.cart?.products?.product_id",
    cartState.cart?.products?.map((_) => ({
      productId: _?.product_id,
      productType: _?.product_type,
    }))
  );
  const handleOnlinePayment = () =>
    makePayment({
      order_type: "cart",
      category: "physical",
      address: selected,
      // productId: cartState.cart?.products?.map((_) => (_?.product_id )),
      // productType: cartState.cart?.products?.map((_) => (_?.product_type )),
      // products: cartState.cart?.products?.map((_) => ({
      //   productId: _?.product_id,
      //   productType: _?.product_type,
      // })),
    });

  return (
    <div className={classes.container}>
      <FormControl className={classes.addresssection} component="fieldset">
        <FormLabel component="legend">My Address</FormLabel>
        {address?.length > 0 && (
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={`${selected?.id} ${selected?.address2} ${selected?.address3}`}
            onChange={selectAddress}
          >
            {address.map((data) => {
              return (
                <div className={classes.savedaddresscheckbox}>
                  <FormControlLabel
                    key={data?.id}
                    value={`${data?.id} ${data?.address2} ${data?.address3}`}
                    control={<Radio />}
                    label={<SavedAddress data={data} />}
                  />
                  <IconButton
                    onClick={() => handleDeleteAddress(data?.id)}
                    aria-label="delete"
                    className={classes.margin}
                  >
                    <DeleteIcon fontSize="medium" />
                  </IconButton>
                </div>
              );
            })}
          </RadioGroup>
        )}

        <div className={classes.addaddress}>
          <Divider variant="middle" />
          <div className={classes.addaddresstext}>+ Add ADDRESS</div>
          <Divider variant="middle" />
        </div>
        <div className={`${classes.inputcontainer} ${classes.w100}`}>
          <FormLabel>House No., Building Name *</FormLabel>
          <TextField
            name="address2"
            type="text"
            variant="outlined"
            required
            value={newAddress["address2"]}
            onChange={handleNewAddress}
            placeholder="Type Here"
            error={!!validationErrors.address2}
            helperText={validationErrors.address2}
          />
        </div>
        <div className={`${classes.inputcontainer} ${classes.w100}`}>
          <FormLabel>Road name, Area, Colony*</FormLabel>
          <TextField
            name="address3"
            type="text"
            value={newAddress["address3"]}
            onChange={handleNewAddress}
            variant="outlined"
            required
            placeholder="Type Here"
            error={!!validationErrors.address3}
            helperText={validationErrors.address3}
          />
        </div>
        <div className={`${classes.inputcontainer} ${classes.w100}`}>
          <FormLabel>Landmark</FormLabel>
          <TextField
            name="landmark"
            type="text"
            variant="outlined"
            value={newAddress["landmark"]}
            onChange={handleNewAddress}
            error={!!validationErrors.landmark}
            helperText={validationErrors.landmark}
            placeholder="Type Here"
          />
        </div>
        <div className={`${classes.inputcontainermain} ${classes.w100}`}>
          <div className={`${classes.inputcontainer} ${classes.w32}`}>
            <FormLabel>Pin Code</FormLabel>
            <TextField
              name="pin_code"
              type="number"
              value={newAddress["pin_code"]}
              onChange={handleNewAddress}
              variant="outlined"
              required
              placeholder="Type Here"
              error={!!validationErrors.pin_code}
              helperText={validationErrors.pin_code}
            />
          </div>
          <div className={`${classes.inputcontainer} ${classes.w32}`}>
            <FormLabel>State</FormLabel>
            <Autocomplete
              id="state-autocomplete"
              options={states || []}
              value={stateObject}
              classes={{ inputRoot: classes.inputRoot }}
              onChange={handleState}
              disableClearable
              getOptionLabel={(option) => option?.name || ""}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Here"
                  variant="outlined"
                />
              )}
              selectOnFocus
              openOnFocus
            />

            {/* <Select
              labelId="category-label"
              id="state"
              displayEmpty
              className={classes.selectEmpty}
              MenuProps={menuProps}
              variant="outlined"
              value={newAddress["state"]}
              onChange={handleState}
              error={!!validationErrors.state}
            >
              <MenuItem disabled value="">
                <em className={classes.defaultselect}>Select Here</em>
              </MenuItem>
              {states?.length > 0 &&
                states.map((state) => (
                  <MenuItem key={state?.id} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))}
            </Select> */}
            <Typography color="error" className={classes.errorMessage}>
              {validationErrors.state}
            </Typography>
          </div>
          <div className={`${classes.inputcontainer} ${classes.w32}`}>
            <FormLabel>City</FormLabel>
            <Autocomplete
              id="state-autocomplete"
              options={cities || []}
              value={cityObject}
              classes={{ inputRoot: classes.inputRoot }}
              onChange={handleCity}
              disableClearable
              getOptionLabel={(option) => option?.name || ""}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Here"
                  variant="outlined"
                />
              )}
              selectOnFocus
              openOnFocus
            />
            {/* <Select
              labelId="category-label"
              id="city"
              displayEmpty
              className={classes.selectEmpty}
              MenuProps={menuProps}
              onChange={handleCity}
              value={newAddress["city"]}
              variant="outlined"
              error={!!validationErrors.city}
            >
              <MenuItem disabled value="">
                <em className={classes.defaultselect}>Select Here</em>
              </MenuItem>
              {cities?.length > 0 &&
                cities.map((city) => (
                  <MenuItem key={city?.id} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
            </Select> */}
            <Typography color="error" className={classes.errorMessage}>
              {validationErrors.city}
            </Typography>
          </div>
        </div>
        <Button className={classes.bluebtn} onClick={saveNewAddress}>
          SAVE
        </Button>
        {/* <FormLabel component="legend">Payment Method</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            className={classes.paymentcheckbox}
            value="Online"
            control={<Radio />}
            label="Online"
          />
          <FormControlLabel
            className={classes.paymentcheckbox}
            value="Cash On Delivery"
            control={<Radio />}
            label="Cash On Delivery"
          />
        </RadioGroup> */}
      </FormControl>

      <div className={classes.checkoutpreviewsectionmain}>
        <div className={classes.previewsection}>
          {bookCartList?.length > 0 && (
            <>
              <Typography variant="h5">Physical</Typography>
              {bookCartList?.map((cart) => (
                <div className={classes.previewsectioncard}>
                  <div className={classes.previewsectioncardinner}>
                    <div className={classes.imgcontainer}>
                      <img
                        src={cart?.product?.image_url || cart?.image_url}
                        alt="img"
                      />
                    </div>
                    <div className={classes.text}>
                      <div className={classes.dflex}>
                        {/* <Typography variant="h3">Course by:</Typography> */}
                        <Typography variant="h4">
                          {cart?.product?.author}
                        </Typography>
                      </div>
                      <Typography variant="h5">
                        {cart?.product?.product_name || cart?.product_name}
                      </Typography>
                      <br />
                      <Typography variant="h3">
                        Quantity: {cart?.quantity} * ₹
                        {cart?.product?.mrp || cart?.mrp}
                      </Typography>
                      <Typography variant="h6">₹{cart.total_cost}</Typography>
                    </div>
                  </div>
                  {/* <div className={classes.content_delete}>
                    <div className={classes.btn2}>
                      <IconButton
                        onClick={() => removefromCartListAPI(cart?.id)}
                      >
                        <DeleteSvg />
                      </IconButton>
                    </div>
                  </div> */}
                </div>
              ))}
              <Divider />
            </>
          )}
          {eBookCartList?.length > 0 && (
            <>
              <Typography variant="h5">Non-Physical</Typography>
              {eBookCartList?.map((cart) => (
                <div className={classes.previewsectioncard}>
                  <div className={classes.previewsectioncardinner}>
                    <div className={classes.imgcontainer}>
                      <img
                        src={cart?.product?.image_url || cart?.image_url}
                        alt="img"
                      />
                    </div>
                    <div className={classes.text}>
                      <div className={classes.dflex}>
                        {/* <Typography variant="h3">Course by:</Typography> */}
                        <Typography variant="h4">
                          {cart?.product?.author}
                        </Typography>
                      </div>
                      <Typography variant="h5">
                        {cart?.product?.product_name || cart?.product_name}
                      </Typography>
                      <br />
                      <Typography variant="h3">
                        Quantity: {cart?.quantity} * ₹
                        {cart?.product?.mrp || cart?.mrp}
                      </Typography>
                      <Typography variant="h6">₹{cart.total_cost}</Typography>
                    </div>
                  </div>
                  {/* <div className={classes.content_delete}>
                    <div className={classes.btn2}>
                      <IconButton
                        onClick={() => removefromCartListAPI(cart?.id)}
                      >
                        <DeleteSvg />
                      </IconButton>
                    </div>
                  </div> */}
                </div>
              ))}
              <Divider />
            </>
          )}
          <div className={classes.order}>
            <div className={classes.ordersummaryheading}>
              <Typography variant="h5">Order Summary</Typography>
            </div>
            <div className={classes.ordersummary}>
              <div className={classes.orderitem}>
                <Typography variant="h5">Items</Typography>
                <Typography variant="h6">
                  ₹{cartState.cart.total_mrp}
                </Typography>
              </div>
              <div className={classes.ordercost}>
                <Typography variant="h5">Total Discount</Typography>
                <Typography variant="h6">
                  {console.log("cartState111", cartState)}- ₹
                  {cartState.cart.total_discount}
                </Typography>
              </div>
              <div className={classes.ordercost}>
                <Typography variant="h5">Shipping Cost</Typography>
                <Typography variant="h6">
                  {console.log("cartState111", cartState)}+ ₹
                  {cartState.cart.shipping_cost}
                </Typography>
              </div>
              <div className={classes.ordercost}>
                <Typography variant="h5">Delivery Cost</Typography>
                <Typography variant="h6">
                  + ₹{cartState.cart.delivery_cost}
                </Typography>
              </div>
              <div className={classes.ordercost}>
                <Typography variant="h5">Wallet Amount</Typography>
                <Typography variant="h6">
                  - ₹{cartState.cart.wallet_amount}
                </Typography>
              </div>
              <div className={classes.ordercost}>
                <Typography variant="h5">Cash Back Amount</Typography>
                <Typography variant="h6">
                  - ₹{cartState.cart.cashback_amount}
                </Typography>
              </div>
              <div className={classes.orderitem}>
                <Typography variant="h5">Total</Typography>
                <Typography variant="h6">
                  ₹{cartState.cart.sub_total_cost}
                </Typography>
              </div>
              <div className={classes.ordercost}>
                <Typography variant="h5">GST</Typography>
                <Typography variant="h4">₹{cartState.cart.gst}</Typography>
              </div>
            </div>
          </div>
          <Divider />
          <div>
            <div className={classes.billamount}>
              <Typography variant="h5">Billing Amount :</Typography>
              <Typography variant="h6">
                ₹{cartState.cart.grand_total_cost}
              </Typography>
            </div>
            <Button className={classes.bluebtn100} onClick={checkoutHandler}>
              COMPLETE PAYMENT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPageContent;
