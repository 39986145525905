import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTableView from "../../CustomComponent/TableView";
import PurchaseTableMobile from "./PurchaseTableMobile";
import {
  getMydashboardMyPurchase,
  getMypurchase,
} from "../../../service/services";
import OrdersErrorPage from "../ErrorPage/OrdersErrorPage";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0rem 2rem 1rem 2rem ",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    minHeight: "85vh",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
      padding: "1rem 0.8rem",
      textAlign: "justify",
    },
  },
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headname: {
    display: "flex",
    alignItems: "center",
    // marginTop: "1rem",
    width: "47%",
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  attempted: {
    border: "1px solid #4E4E4E",
    borderRadius: "0.4375rem",
    padding: "0.4rem 1.5rem",
    "& .MuiTypography-h6": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#323232",
    },
  },
  table: {
    minWidth: 700,
  },
  activebtn: {
    "&.MuiButton-root": {
      color: "#fff",
      background: "#313866",
      textTransform: "capitalize",
      padding: "0rem 1rem",
      width: "60%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "0.875rem",
    },
  },
  disabledbtn: {
    "&.MuiButton-root": {
      border: "1px solid #EBEBEB",
      color: "#262626",
      background: "#FFF",
      textTransform: "capitalize",
      padding: "0rem 1rem",
      width: "60%",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "30%",
    marginTop: "0.2em",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

function MyPurchase(props) {
  const classes = useStyles();

  const setOnViewClicked = props.setOnViewClicked;
  const [rows, setRows] = useState([]);
  let mql = window.matchMedia("(max-width: 650px)");
  const [data, setData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchData() {
      try {
        const response = await getMydashboardMyPurchase(token);
        console.log(response?.data?.my_order, "mypurchase");
        setData(response?.data?.my_order);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const updatedRows = data.flatMap((purchase, index) => {
        const deliveryDate = new Date(purchase.delivery_date);
        const formattedDeliveryDate = `${deliveryDate.getDate()}/${
          deliveryDate.getMonth() + 1
        }/${deliveryDate.getFullYear()}`;

        return {
          id: purchase?.id || null,
          srno: index + 1,
          orderid: purchase?.order_id || null,
          billingamount: purchase?.grand_total_cost || null,
          status: purchase?.razorpay_payment_status || null,
          date: purchase?.created_at
            ? new Date(purchase?.created_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })
            : "",
        };
      });
      setRows(updatedRows);
    } else {
      setRows([]);
    }
    console.log("Data after processing:", data);
  }, [data]);

  const columns = [
    {
      field: "srno",
      headerName: "S.No.",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "orderid",
      headerName: "Order Id",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
    },
    {
      field: "billingamount",
      headerName: "Billing Amount",
      headerClassName: "super-app-theme--header",
      cellClassName: "totalcostcell",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <p>
            <span>₹</span>
            {cellValues.value}
          </p>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{cellValues.value}</div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      autoPageSize: false,
      renderCell: (cellValues) => {
        return (
          <Button
            onClick={() => {
              setOnViewClicked("ordersummary", cellValues.row.id);
            }}
            className={classes.disabledbtn}
          >
            View
          </Button>
        );
      },
    },
  ];

  const dummyRows = [];

  return (
    <div className={classes.container}>
      <div className={classes.headcontainer}>
        <div className={classes.headname}>
          <Typography gutterBottom className={classes.heading}>My Orders</Typography>
        </div>
      </div>
      {!mql.matches ? (
        rows.length !== 0 ? (
        <CustomTableView
          columns={columns}
          rows={rows}
          section={"myPurchaseTable"}
        />
         ) : (
          <OrdersErrorPage />
        )
      ) : rows.length !== 0 ? (
        rows.map((data, index) => (
          <PurchaseTableMobile
            data={data}
            setOnViewClicked={setOnViewClicked}
          />
        ))
      ) : (
        <OrdersErrorPage />
      )}
    </div>
  );
}

export default MyPurchase;
