import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHeading from "../pageheading/pageheading";
import SendYourMessageSection from "./sendyourmessagesection";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }, 
}));
function ContactUs() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore Contact us",
      headingmain: "Contact us",
      breadcrumb:"Contact us"
    },
  ];
  return (
<div>
  <PageHeading Heading={Heading}/>
  <SendYourMessageSection />
</div>
  );
};
export default ContactUs;
