import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import { ReactComponent as PhoneIcon } from "../image/loginsignupvector/phoneicon.svg";
import { ReactComponent as LocationIcon } from "../image/contactusvector/location_icon.svg";
import { ReactComponent as TelephoneIcon } from "../image/contactusvector/phoneicon.svg";
import { ReactComponent as EmailIcon } from "../image/contactusvector/email_icon.svg";
import { TfiEmail } from "react-icons/tfi";
import { RiUser3Line } from "react-icons/ri";
import { postEnquiry } from "../../service/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    background: "#FFFBF1",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "14rem",
    },
  },
  container: {
    paddingTop: "2.5rem",
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
      marginBottom: "1rem",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiFormControlLabel-label": {
        fontFamily: "Jost",
        fontSize: "0.9rem",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.65rem",
        },
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& input:-internal-autofill-selected": {
        backgroundColor: "#fff",
      },
      "& .MuiButton-fullWidth": {
        width: "75%",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
    },
  },

  content: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  messageform: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "2rem 1rem",
    },
  },
  inputhead: {
    marginTop: "1rem",
  },
  descriptioninput: {
    "& .MuiInputBase-input": {
      padding: "2.5rem 0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        padding: "1.5rem 0",
      },
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "2.5rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  informationsection: {
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
      background: "#FFFFFF",
      marginTop: "2rem",
    },
  },
  infocard: {
    background: "linear-gradient(119.64deg, #FFEEC3 29.99%, #FFD9B5 44.05%)",
    padding: "2rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    "& .MuiTypography-h3": {
      fontSize: " 1.5rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.25rem",
      },
    },
    "& .MuiTypography-h6": {
      fontSize: " 1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      color: "rgba(38, 50, 56, 0.70)",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
  },
  locationicon: {
    border: "2.4px solid #20354D",
    borderRadius: "45px",
    padding: "0.7rem 1rem",
    background: "#FFFFFF4D",
  },
  phoneicon: {
    border: "2.4px solid #20354D",
    borderRadius: "50px",
    padding: "0.7rem 0.8rem",
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
  },
  emailicon: {
    border: "2.4px solid #20354D",
    borderRadius: "45px",
    padding: "0.9rem 0.7rem",
    background: "#FFFFFF4D",
  },
  infodata: {
    width: "237.467px",
    padding: "0.2rem 1.5rem",
  },
  map: {
    marginTop: "3rem",
    border: "0",
    marginBottom: "15rem",
    [theme.breakpoints.down("xs")]: {
      height: "250px",
      margin: "2rem 0",
      background: "#FFFFFF",
    },
  },
}));

function SendYourMessageSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Deliver Your Message with Precision and Impact",
      headingmain: "Send Your Message",
    },
  ];

  //
  const [formData, setFormData] = useState({
    full_name: "",
    phone: "",
    email: "",
    message: "",
    type: "contactus",
  });

  // handleChange function definition
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Define the handleSubmit function
  const handleSubmit = async () => {
    try {
      const response = await postEnquiry({ enquiry: formData });
      setFormData({
        full_name: "",
        phone: "",
        email: "",
        message: "",
        type: "contactus",
      });

      toast.success(
        response.data.message
          ? response.data.message
          : "Enquiry submitted successfully!"
      );
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "An error occurred. Please try again later.";
      toast.error(errorMessage);
    }
  };

 
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.content}>
          <div className={classes.messageform}>
            <FormControl sx={{ m: 1, width: "25ch" }}>
              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Your Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="name"
                type="text"
                placeholder="Enter your Name"
                onChange={handleChange}
                name="full_name"
                value={formData.full_name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RiUser3Line />
                    </InputAdornment>
                  ),
                }}
              />

              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Mobile No. <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="mobilenumber"
                type="mobilenumber"
                placeholder="+91 8965 458 888"
                onChange={handleChange}
                name="phone"
                value={formData.phone}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Email Address <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="email"
                type="email"
                placeholder="Johndeere43545@gmail.com"
                onChange={handleChange}
                name="email"
                value={formData.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TfiEmail />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Write a Message <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className={classes.descriptioninput}
                id="message"
                type="text"
                onChange={handleChange}
                name="message"
                value={formData.message}
              />

              <Button
                className={classes.bluecustombtn}
                fullWidth
                onClick={handleSubmit}
              >
                Send Message
              </Button>
            </FormControl>
          </div>
          <div className={classes.informationsection}>
            <div>
              <div className={classes.infocard}>
                <div className={classes.locationicon}>
                  <LocationIcon />
                </div>
                <div className={classes.infodata}>
                  <Typography variant="h3">Our Address</Typography>
                  <Typography variant="h6">
                    infront of, 2/1, Curewell Hospital Rd, New Palasia, Indore,
                    Madhya Pradesh 452001
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.infocard}>
                <div className={classes.phoneicon}>
                  <TelephoneIcon />
                </div>
                <div className={classes.infodata}>
                  <Typography variant="h3">Our Phone No.</Typography>
                  <Typography variant="h6">
                    0731-4983870 <br /> ( 91796-19315 )
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.infocard}>
                <div className={classes.emailicon}>
                  <EmailIcon />
                </div>
                <div className={classes.infodata}>
                  <Typography variant="h3">Email</Typography>
                  <Typography variant="h6">support@parikshado.org</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <iframe
          className={classes.map}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14720.131072651719!2d75.881803!3d22.7270234!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd40b7e9ce97%3A0xb72fa3c84ad420aa!2sPariksha%20Do!5e0!3m2!1sen!2sin!4v1704190533853!5m2!1sen!2sin"
          width="100%"
          height="500"
          title="myFrame"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
export default SendYourMessageSection;
