import { Paper, makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import React from "react";
import Card from "./institutionalcarddata";

const useStyles = makeStyles((theme) => ({
  InstitutionalPartnersContent: {
    marginTop: "1rem",
    "& .slick-slide": {
      display: "flex",
      height: '200px',
      justifyContent: "center",
      paddingTop: "0.5rem",
    },
  },
  institutionallogosection: {
    width: "100%",
    borderRadius: "10px",
    padding: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 2px 30px rgba(58, 58, 58, 0.15)",
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  img: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: 90,
      height: 50,
    },
  },
}));
function InstitutionalPartnersContent() {
  var settings = {
    cssEase: "linear",
    infinite: true,
    speed: 500,
    arrows: false,
    // autoplay: true,
    // speed: 8000,
    // autoplaySpeed: 8000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    dots: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.InstitutionalPartnersContent}>
        <Slider {...settings}>
          {Card.map((data) => (
            <Paper className={classes.institutionallogosection} elevation={3}>
              <img src={data.cardimage} alt="img" className={classes.img} />
            </Paper>
          ))}
        </Slider>
      </div>
    </>
  );
}
export default InstitutionalPartnersContent;
