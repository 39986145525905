const QuickLinks = [
  {
    id: 1,
    name: "About Us",
    to: "/aboutus",
  },
  {
    id: 2,
    name: "Contact Us",
    to: "/contactus",
  },
  {
    id: 3,
    name: "Careers",
    to: "/careers",
  },
  {
    id: 4,
    name: "Online Support",
    to: "/onlinesupport",
  },
];
export default QuickLinks;
