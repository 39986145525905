import React, { useState, memo } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FavouriteButton from "../FavouriteButton";
import { useNavigate } from "react-router-dom";
import BuyNowButton from "../BuyNowButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.5rem",
    margin: "0.6rem 0",
    display: "flex",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "0",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.725rem",
      whiteSpace: "wrap",
      height: "32px",
      overflow: "hidden",
      textOverflow: `"..."`,
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.6rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body2": {
      margin: "0.2rem 0",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.6rem",
    },
    "& .MuiRating-root": {
      fontSize: "1rem",
    },
    "& .MuiCardActions-root": {
      borderRadius: 6,
      padding: "0.2rem",
      "& .MuiButton-label": {
        fontFamily: "Jost",
        fontSize: "0.7rem",
        alignItems: "flex-start",
      },
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "63%",
      padding: "0",
      margin: "0",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0.6rem",
      // padding: '1rem 1rem',
    },
  },
  cardimagecontainer: {
    borderRadius: "6px",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  cardcontent: {
    width: "65%",
    padding: "0rem 0rem 0rem 0.5rem !important",
  },
  favoritebtn: {
    // width: "14%",
    position: "absolute",
    top: "3px",
    left: "3px",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
    "& .MuiIconButton-root": {
      width: "70%",
      padding: "0 2px",
    },
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.125rem",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "600",
    marginRight: "0.3rem",
    // marginTop: "0.8rem",
  },
  offertext: {
    marginLeft: "0.5rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.6rem",
    fontWeight: "400",
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  cartbagicon: {
    marginRight: "0.4rem",
  },
  testserandvaliditytagcont: {
    display: "flex",
    marginLeft: "1rem",
    position: "relative",
    width: "70%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      marginLeft: "0rem",
      width: "85%",
    },
    "& .MuiTypography-h6": {
      color: "#252525",
      fontSize: "0.475rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "500",
    },
  },
  innertags: {
    // background: "#FFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    padding: "0.1rem 0.6rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  innertags1: {
    // background: "#FFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    padding: "0.1rem 0.6rem",
    borderRadius: "0.4rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.5rem",
    },
  },
}));
const TestseriesCardMobile = memo((props) => {
  const { data } = props;
  const classes = useStyles();
  const [isCheckedHeart, setIsCheckedHeart] = useState(false);
  const navigate = useNavigate();
  return (
    <Card
      className={classes.root}
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/testseries/${data?.id}`);
      }}
    >
      <div className={classes.cardimagecontainer}>
        <div className={classes.favoritebtn}>
          <FavouriteButton
            isCheckedHeart={isCheckedHeart}
            data={data}
            setIsCheckedHeart={setIsCheckedHeart}
          />
        </div>
        <img
          src={data?.image_url || require("../../image/notFoundData.png")}
          alt="img"
          width="120px"
          height="120px"
        />
      </div>
      <CardContent className={classes.cardcontent}>
        <Typography gutterBottom variant="h5" component="div">
          {data?.bundle_name}
        </Typography>
        <div className={classes.testserandvaliditytagcont}>
          <div className={classes.innertags}>
            <Typography variant="h6">
              {data?.test_series_mock_count} Test Series
            </Typography>
          </div>
          <div className={classes.innertags1}>
            <Typography variant="h6">Validity: {data?.validity}</Typography>
          </div>
        </div>
        <Typography variant="body2" color="text.secondary">
          <span className={classes.textred}>₹{data?.selling_cost}</span>
          <Typography variant="caption">
            MRP : <del>₹{data?.mrp}</del>
          </Typography>
          <span className={classes.offertext}>
            ({data?.discount_percent}% off)
          </span>
        </Typography>
        <CardActions className={classes.bgorange}>
          <BuyNowButton data={data} />
        </CardActions>
      </CardContent>
    </Card>
  );
});

export default TestseriesCardMobile;
