import React, { useEffect, useState } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import ProductList from "./ProductList";
import DownloadApp from "./downloadApp";
import { ReactComponent as Arrow } from "../image/catergoryVectoe/arrow.svg";
import { HiArrowSmRight } from "react-icons/hi";
import BookCard from "./cards/bookCard";
import EBookCard from "./cards/eBookCard";
import OnlineVideoCard from "./cards/onlineVideo";
import LiveClassesCard from "./cards/liveClasses";
import NotesCard from "./cards/notesCard";
import PreviousYearPaperCard from "./cards/previousyearpapercard";
import QuizCard from "./cards/quizCard";
import TestSeriesCard from "./cards/testSeriesCard";

const useStyles = makeStyles((theme) => ({
  pageheadingmain: {
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      position: "unset",
      padding: 0,
    },
  },
  pageheadingmaininner: {
    margin: "0 auto",
    paddingBottom: "1.5rem",
    paddingTop: "1rem",
    // backgroundImage: `url(${PageHeadingBg})`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem 1.5rem 1rem",
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#212121",
      fontSize: "1.5625rem",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 6,
      },
    },
    "& .MuiBreadcrumbs-ol": {
      fontFamily: "'Playfair Display', serif !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorInherit": {
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
        fontSize: 16,
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: " 400 !important",
      },
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "#FF8F12",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.75rem",
    },
  },
  pageheading: {
    padding: "1rem 0 2rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
    },
  },
  pageheadhr: {
    margin: "0",
    width: "6rem",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: " 0.1875rem",
  },
  quizzcontentcontainer: {
    maxWidth: "1250px",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "15rem",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-h3": {
      fontSize: "1.5625rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
      color: "rgba(34, 56, 82, 1)",
      textTransform: "capitalize",
    },
  },
  link: {
    textDecoration: "none",
    color: "rgba(21, 79, 229, 1)",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "0.625rem",
  },
  heading: {
    fontSize: "3.8rem",
    fontFamily: "'Playfair Display', serif !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      fontFamily: "'Playfair Display', serif !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  w25: {
    width: "24%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  w74: {
    width: "74%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0rem 1rem",
    },
  },
  cardcontainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  cardcontainerheadingmain: {
    marginBottom: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const SingleProductPageMain = ({ singleExamProduct, checkedinitial }) => {
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 650px)");

  const [checked, setChecked] = useState(checkedinitial);
  const [showAllCards, setShowAllCards] = useState(false);
  useEffect(() => {
    setChecked(checkedinitial);
  }, [checkedinitial]);
  const handleViewAll = (data) => {
    console.log(data,"datadatadata");
    setShowAllCards(data);
  };
  return (
    <>
      <div className={classes.quizzcontentcontainer}>
        <div className={classes.w25}>
          <ProductList
            setChecked={setChecked}
            checked={checked}
            checkedinitial={checkedinitial}
          />
          <DownloadApp />
        </div>
        <div className={classes.w74}>
          <div className={classes.w74}></div>
          {checked?.map((data) => (
            <div>
              <div className={classes.cardcontainerheadingmain}>
                <Typography variant="h3" className={classes.headingQuiz}>
                  {singleExamProduct?.exam} {data} (
                  {data === "books" &&
                    singleExamProduct?.products?.books?.length}
                  {data === "ebooks" &&
                    singleExamProduct?.products?.ebooks?.length}{" "}
                  {data === "online videos" &&
                    singleExamProduct?.products?.online_videos?.length}{" "}
                  {data === "live classes" &&
                    singleExamProduct?.products?.live_classes?.length}{" "}
                  {data === "notes" &&
                    singleExamProduct?.products?.notes?.length}{" "}
                  {data === "previous year papers" &&
                    singleExamProduct?.products?.previous_year_papers?.length}{" "}
                  {data === "quizess" &&
                    singleExamProduct?.products?.quizess?.length}{" "}
                  {data === "test series" &&
                    singleExamProduct?.products?.test_series?.length}
                  )
                </Typography>
                {mql.matches ? (
                  <div
                    className={classes.Arrow}
                    // onClick={() => {
                    //   setVa();
                    // }}
                  >
                    <Arrow />
                  </div>
                ) : (
                  <div>
                    {/* <Button
                      className={classes.link}
                      // onClick={() => {
                      //   setVa();
                      // }}
                    >
                      view all
                      <HiArrowSmRight />
                    </Button> */}
                    {showAllCards !== data && (
                      <Button className={classes.link} onClick={()=>{handleViewAll(data)}}>
                        view all <HiArrowSmRight />
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className={classes.cardcontainer}>
                {/* {data === "books" &&
                  singleExamProduct?.products?.books?.map((data) => (
                    <BookCard data={data} />
                  ))} */}
                {data === "books" &&
                  (showAllCards === "books"
                    ? singleExamProduct?.products?.books?.map((data) => (
                        <BookCard key={data.id} data={data} />
                      ))
                    : singleExamProduct?.products?.books
                        ?.slice(0, 4)
                        .map((data) => <BookCard key={data.id} data={data} />))}

                {data === "ebooks" &&
                  (showAllCards === "ebooks"
                    ? singleExamProduct?.products?.ebooks?.map((data) => (
                        <EBookCard key={data.id} data={data} />
                      ))
                    : singleExamProduct?.products?.ebooks
                        ?.slice(0, 4)
                        .map((data) => (
                          <EBookCard key={data.id} data={data} />
                        )))}
                {data === "online videos" &&
                  (showAllCards === "online videos"
                    ? singleExamProduct?.products?.online_videos?.map(
                        (data) => <OnlineVideoCard key={data.id} data={data} />
                      )
                    : singleExamProduct?.products?.online_videos
                        ?.slice(0, 4)
                        .map((data) => (
                          <OnlineVideoCard key={data.id} data={data} />
                        )))}
                {data === "live classes" &&
                  (showAllCards === "live classes"
                    ? singleExamProduct?.products?.live_classes?.map((data) => (
                        <LiveClassesCard key={data.id} data={data} />
                      ))
                    : singleExamProduct?.products?.live_classes
                        ?.slice(0, 4)
                        .map((data) => (
                          <LiveClassesCard key={data.id} data={data} />
                        )))}
                {data === "notes" &&
                  (showAllCards === "notes"
                    ? singleExamProduct?.products?.notes?.map((data) => (
                        <NotesCard key={data.id} data={data} />
                      ))
                    : singleExamProduct?.products?.notes
                        ?.slice(0, 4)
                        .map((data) => (
                          <NotesCard key={data.id} data={data} />
                        )))}
                {data === "previous year papers" &&
                  (showAllCards === "previous year papers"
                    ? singleExamProduct?.products?.previous_year_papers?.map(
                        (data) => (
                          <PreviousYearPaperCard key={data.id} data={data} />
                        )
                      )
                    : singleExamProduct?.products?.previous_year_papers
                        ?.slice(0, 4)
                        .map((data) => (
                          <PreviousYearPaperCard key={data.id} data={data} />
                        )))}
                {data === "quizess" &&
                  (showAllCards === "quizess"
                    ? singleExamProduct?.products?.quizess?.map((data) => (
                        <QuizCard key={data.id} data={data} />
                      ))
                    : singleExamProduct?.products?.quizess
                        ?.slice(0, 4)
                        .map((data) => <QuizCard key={data.id} data={data} />))}
                {data === "test series" &&
                  (showAllCards === "test series"
                    ? singleExamProduct?.products?.test_series?.map((data) => (
                        <TestSeriesCard key={data.id} data={data} />
                      ))
                    : singleExamProduct?.products?.test_series
                        ?.slice(0, 4)
                        .map((data) => (
                          <TestSeriesCard key={data.id} data={data} />
                        )))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default SingleProductPageMain;
