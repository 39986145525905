import {
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import { GrFormClose } from "react-icons/gr";
import {LogOut} from "../../service/services";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    maxWidth: '50%',
    top: '20%',
    margin: 'auto',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  paper: {
    width: "100%",
    height: "calc(100vh - 60vh)",
    display: "flex",
    zIndex: "8",
    overflow: "scroll",
    backgroundColor: "#FFFAEF",
    position: 'relative',
    width: "100%",
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 10px 10px",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiTypography-h3 ": {
      color: "#252525",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "2.6rem",
      fontWeight: "700",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.375rem",
      },
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },   
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      top: "100px",
      padding: "1rem",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "1rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 1rem",
    },
  },
  closebtncontainer: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    "& .MuiButton-root": {
      minWidth: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    },
  },
  closebtn: {
    zIndex: "9",
    background: "#FFFFFF",
    width: "30px ",
    height: "30px",
    borderRadius: "100%",
    boxShadow: "0 6px 20px #00000015",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
}));

function AlreadyLogin({ handleAlreadyLoginClose , phoneNumber  }) {
  const classes = useStyles();
console.log('phoneNumber' , phoneNumber)
  const onLogout = async (data) => {
    try {
      var res = await LogOut({
        mobile: data,
      });
      localStorage.clear();
      handleAlreadyLoginClose()
      // AppContext.setUser(res?.data?.user);
      // AppContext.setToken(res?.data?.token);
      // await authorizeMe(res?.data?.token);
      // localStorage.setItem("token", res?.data?.token);
      // AppContext.setIsLoggedIn(true);
      // handleloginOpenclose();
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "User is already logged in on another device."
      ) {
        console.log("setAlreadyLogin", err);
        toast.success(err?.response?.data?.message);
      } else if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
      <div className={classes.closebtncontainer}>
          <Button className={classes.closebtn} onClick={handleAlreadyLoginClose}>
            <GrFormClose />
          </Button>
        </div>
        <div className={classes.paper}>
        <Typography variant="h3" gutterBottom>
            Already LogIn In Another Device
          </Typography>
          <Button className={classes.bluecustombtn} onClick={()=>{onLogout(phoneNumber)}}>Log Out</Button>
        </div>
      </div>
    </>
  );
}

export default AlreadyLogin;
