import React from "react";
import { makeStyles } from "@material-ui/core";
import PageHead from "../pageheading/pageheading";
import JoinUsSection from "./joinussection";
import WhyYouChooseSection from "./whyyouchoosesection";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "1500",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileheader: {
    zIndex: "1500",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }, 
}));
function Careers() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore Careers",
      headingmain: "Careers",
      breadcrumb:"Careers"
    },
  ];
  return (
<div>
  <PageHead Heading={Heading}/>
  <JoinUsSection/>
  <WhyYouChooseSection/>
</div>
  );
};
export default Careers;
