import React from "react";
import { Button, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import girlteacher from "../../image/dashboard/girlteacher.png";
import videoicon from "../../image/dashboard/videoicon.png";

const useStyles = makeStyles((theme) => ({
  cardcontiner: {
    display: "flex",
    "& .MuiTypography-h4": {
      color: "#262626",
      fontFamily: "Playfair Display",
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "1.125rem",
    },
    "& .MuiTypography-h6": {
      color: "#818181",
      fontFamily: "Jost",
      fontSize: "0.625rem",
      fontWeight: 400,
      textTransform: "capitalize",
      lineHeight: "1.8125rem",
    },
  },
  card: {
    width: "32%",
    marginBottom: "1rem",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiCardContent-root": {
      padding: "9px",
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  cardShadow: {
    boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.10) !important',
  },
  contantcontainer: {
    width: "58%",
    marginLeft: "0.8rem",
  },
  imagecontainer: {
    width: "26%",
  },
  cardcontiner1: {
    alignItems: "center",
    display: "flex",
    "& .MuiTypography-body1": {
      color: "var(--white-100, #FFF)",
      textAlign: "center",
      fontFamily: "Jost",
      fontSize: "0.5rem",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "capitalize",
      background: "#11B90E",
      padding: "0.2rem 0.5rem",
      borderRadius: "0.35rem",
    },
    "& .MuiTypography-body2": {
      color: "#505050",
      fontFamily: "Jost",
      fontSize: "0.625rem",
      fontWeight: 500,
      lineHeight: "1rem",
    },
  },
  cardcontiner2: {
    display: "flex",
    marginTop: "0.8rem",
    alignItems: "center",
    "& .MuiButton-root": {
      padding: "6px",
      "& .MuiButton-label": {
        color: "#1E80F4",
        fontFamily: "Jost",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "1.25rem",
      },
    },
    
  },
}));

const StartOverCard = ({data}) => {
  const classes = useStyles();

  return (
    <>
      <Card className={`${classes.card} ${classes.cardShadow}`}>
        <CardContent>
          <div className={classes.cardcontiner}>
            <div className={classes.imagecontainer}>
              <img src={data.image} alt="img" width="100%" />
            </div>
            <div className={classes.contantcontainer}>
              <Typography variant="h4">
                {data.title}
              </Typography>
              <Typography variant="h6">by- {data.by}</Typography>
              <div className={classes.cardcontiner1}>
                <Typography variant="body1">{data.tag}</Typography>
                &nbsp;&nbsp;&nbsp;
                <Typography variant="body2">({data.lectures} Lectures) </Typography>
              </div>
              <div className={classes.cardcontiner2}>
                <img src={videoicon} alt="img" />
                &nbsp;&nbsp;&nbsp;{" "}
                <Button >Start Again</Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default StartOverCard;
