import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import SecHead from "../sectionHeading/sectionheading";
import PreprationSectionContentDesktop from "./PreprationSectionContentDesktop";
import PreprationSectionContentMobile from "./prepractionsectioncontentmobile";
import Vector3 from "../../image/bodyvector/vector3.png";
import PreprationSectionNewMobile from "./preprationsectionnewmobile";

const useStyles = makeStyles((theme) => ({
  Banner: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    fontFamily: "'Playfair Display', serif !important",
  },
  containerfluid: {
    marginTop: "2rem",
      marginBottom: '3rem',
      width: "100%",
    [theme.breakpoints.down('sm')]: {
      marginTop: "35rem",
      marginBottom: "3rem"
    },

    "& .slick-prev, .slick-next": {
      background: "black",
      padding: "15px",
      borderRadius: "40px",
      "&:hover": {
        color: "#FF8008",
      },
    },

    "& .slick-prev:before, .slick-next:before": {
      fontSize: "55px",
      borderRadius: "40px",
      transform: "translate(-50%, -50%)",
      content: "→",
      color: "white",
      opacity: "1",
      bottom: 0,
      boxShadow:
        "0px 1px 7px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0.5rem",
    },

    "& .slick-dots li button:before": {
      color: "#FF8008",
      fontSize: "15px",
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: "3rem",
      marginBottom: '4rem',
    },
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  secheadmain: {
    display: "flex",
    justifyContent: 'space-evenly !important',
  },
  headvector: {
    width: "20%",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
function PreprationSectionMain() {
  const Heading = [
    {
      id: 1,
      headingsmall: "Explore Courses",
      headingmain: "Start Your Preparation",
    },
  ];
  const classes = useStyles();
  let mql = window.matchMedia("(max-width: 600px)");

  return (
    <Grid>
      <div className={classes.PreprationSectionMain}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <div className={classes.secheadmain}>
              <div className={classes.headvector}>
                {/* <img src={Vector3} alt="img" /> */}
              </div>
              <SecHead Heading={Heading} media={mql.matches} />
              <div className={classes.headvector}></div>
            </div>
            <PreprationSectionContentDesktop />
            {/* <PreprationSectionContentMobile /> */}
            <PreprationSectionNewMobile />
          </div>
        </div>
      </div>
    </Grid>
  );
}
export default PreprationSectionMain;
