import React, { useState } from 'react'
import { Typography, makeStyles } from "@material-ui/core";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  personaldetails: {
    width: "80%",
    marginTop: "1rem",
    margin: "1rem 5px",
    borderRadius: 5,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    marginBottom:"20rem",
  "& .MuiTypography-h6":{
    color:'blue',
    marginTop:'1rem',
  },
  "& .MuiTypography-h5":{
    color:'#808080',
    fontSize:'16px',
    fontWeight:"500",

  },
  },
  personalname:{
    width:"100%",
    marginTop:'1.5rem',
    display:'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiTextField-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'border-color: rgba(255,255,255,0) rgba(255,255,255,1) rgba(255,255,255,0) rgba(255,255,255,0)',
    },
  },
  TypeHere:{
    width:"100%",
    marginTop:'.5rem',
    display:'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiTextField-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'red',
    },
  },
  selectinput:{
    width:"100%",
    marginTop:'.5rem',
    display:'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    '& .MuiFormLabel-root':{
      width:"25%",
    },
    '& .MuiInputBase-root':{
      width:"40%",
      
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 7,
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderColor:'red',
    },
  },
  securitypin:{
    display:'flex',
    marginTop:'1.5rem',
    justifyContent: 'center',
    alignItems:' baseline',
    "& .MuiFormLabel-root":{
      width:'20%',
    },
    "& .MuiOutlinedInput-root":{
      height:'3rem',
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    }
  },
  submitbtn:{
    display:'flex',
    justifyContent: 'flex-end',
  },
  btn:{
    background:'#ff8c00',
    width:'10%',
    justifyContent:'space-around',
  }
}));

function PersonalDetailPage() {
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const [selectedGender, setSelectedGender] = useState("");
  const handleChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const classes = useStyles();
  return (
   <div className={classes.personaldetails}>
     <Typography variant='h6'>व्यक्तिगत विवरण (Personal Details)</Typography>
   <div className={classes.personalname}>
                <FormLabel>
                  
                Details
अभ्यर्थी का नाम (Candidate's Name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
               
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                अभ्यर्थी की जन्म विधि (Candidate's Date of Birth)
                </FormLabel>
                
                     <TextField
                  type="date"
                  variant="outlined"
                  required
                  placeholder=""
                />
                   

                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
             लिंग (Gender)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                 अगर अभ्यर्थी रुड़की है तो क्या आप अपने माता पिता की केवल बालिका है? (In case of Girl candidate, Are you only girl child of your parents)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>india</MenuItem>
                  <MenuItem value={"female"}>japan</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
             
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                पिता का नाम (Father's Name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                माता का नाम (Mother's Name)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >राष्ट्रीपता (Nationality)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>india</MenuItem>
                  <MenuItem value={"female"}>japan</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
               राज्य/केन्द्र शासित प्रदेश नहीं से 12वी UT from where 12th Equivalent Passed/ Appearing
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>india</MenuItem>
                  <MenuItem value={"female"}>japan</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.selectinput}>      
                <FormLabel
                 
                >
                 श्रेणी (Category)
                </FormLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={selectedGender}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={menuProps}
                  variant="outlined"
                >
                  <MenuItem disabled value="">
                    <em className={classes.defaultselect}>--Select--</em>
                  </MenuItem>
                  <MenuItem value={"male"}>india</MenuItem>
                  <MenuItem value={"female"}>japan</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              
                </div>
                <div className={classes.TypeHere}>
                <FormLabel>
                  
                पदि आप एक Pwd उम्मीदवार है, तो क्या आपके पास बैंचमार्क विकलांगता है (40% या अधिक। "गंभीर नहीं प्रति परिभाषित नहीं है? (if you are a Pwd Candidate, do you have benchmark disability (40% or more/ "Severe where percentage is not defined) 7)
                </FormLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  required
                  placeholder=""
                />
                </div>
               
              </div>
  
  )
}

export default PersonalDetailPage;