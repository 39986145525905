import React, { useEffect, useState } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import winner from "../../image/dashboard/videocourse/winner.png";
import FacebookIcon from '@material-ui/icons/Facebook';
import { ReactComponent as LinkedInIcon } from "../../image/dashboard/achivmentsfolder/linkdin.svg";
import InstagramIcon from '@material-ui/icons/Instagram';
import Popover from "@material-ui/core/Popover";
import MyAchievementsErrorPage from "../ErrorPage/MyAchievementsErrorPage";
import { getTestSeriesAchivement } from "../../../service/services";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "84vh",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    padding: "0rem 2rem 1rem 2rem ",
    [theme.breakpoints.down("xs")]: {
      margin: "0.1rem",
      padding: "0",
      boxShadow: "none",
      background: "transparent",
    },
    "& .MuiTypography-h6": {
      color: "#243B55",
      fontFamily: "Playfair Display",
      fontSize: "1.625rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "2.625rem",
      borderBottom: "1px solid #CACACA",
      width: "23%",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
      },
    },
    "& .MuiTypography-h5": {
      color: "#243B55",
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Playfair Display",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.9375rem",
      textTransform: "capitalize",
      marginTop: "2rem",
    },
    "& .MuiTypography-caption": {
      color: "#5E5E5E",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 400,
      textTransform: "capitalize",
    },
  },
  main: {
    marginTop: "1.5rem",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    padding: "0.5rem 1rem",
    margin: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      margin: "1.2rem 0 !important",
      padding: "10px !important",
    },
  },
  centerdivcontainer: {
    width: "80%",

    "& .MuiTypography-h1": {
      color: "#373737",
      fontFamily: "Playfair Display",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.85rem",
      },
    },
    "& .MuiTypography-subtitle1": {
      color: "#5E5E5E",
      fontFamily: "Jost",
      fontSize: "0.8rem",
      fontWeight: 400,
      textTransform: "capitalize",
    },
    "& .MuiTypography-subtitle2": {
      color: "#5E5E5E",
      fontFamily: "Jost",
      fontSize: "1rem",
      fontWeight: 400,
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
  },
  image: {
    background: "#FFF3CF",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  imagecontainer: {
    width: "15%",
    background: "#FFF3CF",
    textAlign: "center",
    padding: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
  },
  buttoncontainer: {
    // padding: "0rem 2rem",
    width: "12%",
    [theme.breakpoints.down("xs")]: {
      width: "35%",
      textAlign: "center",
      padding: "0px !important",
    },
  },
  btn: {
    padding: "0.5rem 0",
    background: "#243B55",
    color: "#FFF",
    fontSize: "0.8rem",
    fontFamily: "Jost",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#000",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem",
      padding: "0.3rem 0",
      fontSize: "0.7rem",
    },
  },
  heading: {
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    borderBottom: "1px solid #CACACA",
    width: "30%",
    marginTop: "0.2em",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

const Data1 = [
  {
    id: 11,
    images: winner,
    heading: "SSC CHSL Full reasoning (start to End) Exam",
    subsubheading: " Grade Achieved: 91.11%",
    sub_heading: "ParikshaDo",
  },
  {
    id: 12,
    images: winner,
    heading: "SSC CHSL Full reasoning (start to End) Exam",
    subsubheading: " Grade Achieved: 91.11%",
    sub_heading: "ParikshaDo",
  },
  {
    id: 13,
    images: winner,
    heading: "SSC CHSL Full reasoning (start to End) Exam",
    subsubheading: " Grade Achieved: 91.11%",
    sub_heading: "ParikshaDo",
  },
];

const Achievements = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowId, setSelectedRowId] = useState();
  const [data, setData] = useState();

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShareInsta = () => {
    handleClose();
    window.open("https://www.instagram.com/");
};

  const handleShareFacebook = () => {
    handleClose();
    const url = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
};

  const handleShareLinkedin = () => {
    handleClose();
    const url = encodeURIComponent("https://www.parikshado.com/s/508/lukefe25");
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //fetch all test series achivements
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchData() {
      try {
        const response = await getTestSeriesAchivement(token);
        setData(response?.data?.achivement);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Typography gutterBottom className={classes.heading}>My Achievements</Typography>
        {data?.length === 0 ? (
          <MyAchievementsErrorPage />
        ) : (
          data?.map((data) => (
            <div className={classes.main}>
              <div className={classes.container}>
                <div className={classes.imagecontainer}>
                  <img
                    className={classes.image}
                    src={winner}
                    alt="img"
                    width="60%"
                  />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div className={classes.centerdivcontainer}>
                  <Typography variant="h1">
                    {data?.exam?.test_series_name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {data?.addedBy?.full_name}
                  </Typography>
                  <Typography variant="subtitle2">
                    Grade Achieved: {data?.percentage}%
                  </Typography>
                </div>
              </div>

              {/* this share button is commented for now */}
              <div className={classes.buttoncontainer}>
                <Button
                  aria-describedby={id}
                  className={classes.btn}
                  fullWidth
                  onClick={(e) => {
                    handleClick(e, data.id);
                  }}
                >
                  share
                </Button>
                <Popover
                  id={id}
                  open={open && data.id === selectedRowId}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography className={classes.typography}>
                    <Button onClick={handleShareFacebook}>
                      <FacebookIcon />
                    </Button>
                    <Button onClick={handleShareLinkedin}>
                      <LinkedInIcon />
                    </Button>
                    <Button onClick={handleShareInsta}>
                      <InstagramIcon />
                    </Button>
                  </Typography>
                </Popover>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Achievements;
