import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rectangle: {
    width: "25%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.06)",
    padding: "0.3rem 0.6rem",
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "'Jost', sans-serif",
    textAlign: "center",
    color: "rgba(114, 114, 114, 1)",
  },
  smallSquare: {
    width: "10px",
    height: "10px",
  },
}));

function Rectangle({ label, smallSquareColor }) {
  const classes = useStyles();

  return (
    
    <Box className={classes.rectangle}>
      <div
        className={classes.smallSquare}
        style={{ backgroundColor: smallSquareColor }}
      ></div>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
}

export default Rectangle;
