import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import OnlineEducationVector from "../image/aboutusvector/onlineeducationvector.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop:'3rem',
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  onlineeducationmain: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  onlineeducationcontent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: 'block',
      padding: "0 1rem"
    },
  },
  onlineeducationimgcontainer: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "2rem 1rem"
    },
  },
  onlineeducationcontentinner: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      color: "#000",
      fontSize: "2rem",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.25rem",
        color:" #223852",
      },
    },
    "& .MuiTypography-body2":{
      marginTop:'2rem',
      fontFamily: "'Jost', sans-serif",
      color: "#7E7E7E",
      fontSize: "1rem",
      fontWeight: "400",
      textAlign: "justify",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },
}));
function OnlineEducationSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Personalized Online Learning: Education Tailored Just for You",
      headingmain: "Online Education Tailored to You",
    },
  ];
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.onlineeducationmain}>
          <div className={classes.onlineeducationcontent}>
            <div className={classes.onlineeducationcontentinner}>
              <Typography variant="h5" gutterBottom>
                Establishing a Community that Encourages Lifelong Learning
              </Typography>
              <Typography variant="body2" gutterBottom>
                Creating a lifelong learning community is crucial in today's
                dynamic world. 
                Such a community promotes ongoing education
                beyond formal institutions, emphasizing continuous personal and
                professional growth. This involves providing various learning
                avenues like workshops, seminars, and online courses. Peer
                mentoring and knowledge sharing are essential components,
                fostering a culture that celebrates curiosity and adapts to
                change. By nurturing a commitment to learning, communities
                empower individuals to stay relevant in their careers and lead
                fulfilling lives as lifelong learners.
              </Typography>
            </div>
          </div>
          <div className={classes.onlineeducationimgcontainer}>
            <img src={OnlineEducationVector} alt="img" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default OnlineEducationSection;
