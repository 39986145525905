import React from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";
import PdfIcon from "../image/singlepagenote/pdficon.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding: "2rem 0",
    "& .MuiTypography-h5": {
      marginTop: "2rem",
      color: "rgba(31, 51, 75, 1);",
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "700",
      fontSize: "30px",
    },
    "& .MuiTypography-h4": {
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      fontSize: "18px",
      color: "rgba(62, 62, 62, 1)",
      lineHeight: "30px",
    },
  },
  singlePage: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 1rem",
    },
  },
  ul: {
    listStyleType: "lower-alpha",
  },
  "&.a": {
    elementStyleColor: "#FA9D2B",
  },
  list: {
    padding: "0 1rem",
  },
  listHeading: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: "500",
    fontSize: "20px",
    color: "rgba(73, 73, 73, 1)",
    lineHeight: "30px",
  },
  button: {
    width: "200px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "17px",
    height: "50px",
    borderRadius: "5px",
    color: "rgba(35, 57, 82, 1)",
    border: "2px solid rgba(0, 0, 0, 1)",
    textTransform: "none",
    float: "right",
  },
  icon: {
    width: "auto",
    height: "auto",
    marginLeft: "9px",
  },
  lowerButton: {
    width: "200px",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "400",
    fontSize: "17px",
    height: "50px",
    borderRadius: "5px",
    color: "rgba(35, 57, 82, 1)",
    border: "2px solid rgba(0, 0, 0, 1)",
    textTransform: "none",
    marginTop: "1.5rem",
  },
}));

function NoteContent() {
  const classes = useStyles();

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <div className={classes.singlePage}>
          <Typography variant="h5" gutterBottom>
            <b>What is Analogy Reasoning?</b>
            <Button className={classes.button}>
              Download PDF
              <img src={PdfIcon} alt="PDF" className={classes.icon} />
            </Button>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Analogies turn out to be a common topic in the reasoning ability
            section for all major Government exams, especially Banks, Insurance,
            SSC and Railways. The weightage of questions asked for reasoning
            analogy may vary from 1-4 marks in the various competitive
            examinations and candidates can easily score marks in these not so
            complex questions.In this article, we shall discuss more the
            reasoning analogies and tips to solve it. Also, some solved examples
            have been given in the article, to make the concept even more
            understandable to the candidates.Analogies turn out to be a common
            topic in the reasoning ability section for all major Government
            exams, especially Banks, Insurance, SSC and Railways. he weightage
            of questions asked for reasoning analogy may vary from 1-4 marks in
            the various competitive examinations and candidates can easily score
            marks in these not so complex questions.In this article, we shall
            discuss more the reasoning analogies and tips to solve it. Also,
            some solved examples have been given in the article, to make the
            concept even more understandable to the candidates.Analogies turn
            out to section for all major Government exams, especially Banks,
            <br></br> The weightage of questions asked for reasoning analogy may
            vary from 1-4 marks in the various competitive examinations and
            candidates can easily score marks in these not so complex
            questions.In this article, we shall discuss more the reasoning
            analogies and tips to solve it. Also, some solved examples have been
            given in the article, to make the concept even more understandable
            to the candidates.
          </Typography>
          <Typography variant="h5" gutterBottom>
            <b>Reasoning Analogy – Concept & Basics</b>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Before solving the questions, an aspirant must understand the
            concept of analogy. An analogy is a word used in terms of comparing
            two things or finding a relation between two things. Similarly, in
            questions related to Analogy in reasoning, options are given, which
            may or may not be associated with each other and candidates need to
            find the odd option out.The analogy questions may be asked in
            various forms. Given below are the type of questions asked for
            analogies:
          </Typography>
          <Typography variant="h4" gutterBottom>
            The analogy questions may be asked in various forms. Given below are
            the type of questions asked for analogies:
          </Typography>
          <Typography variant="h4" gutterBottom>
            <ul>
              <li>
                <span className={classes.listHeading}>
                  Numerical Analogy (Odd One Out)–
                </span>{" "}
                A set of options may be given based on a certain pattern, and
                one of them may be unlikely to follow the pattern and candidates
                need to choose the odd one out
              </li>

              <li>
                <span className={classes.listHeading}>
                  Alphabetical / Word Analogy (Choose a similar pair) –
                </span>{" "}
                An analogy may be given based on a set pattern and candidates
                may have to find the word analogy pair from the given options,
                which follows the exact same pattern
              </li>

              <li>
                <span className={classes.listHeading}>
                  Numerical Analogy (Choose a similar pair) –
                </span>{" "}
                An analogy may be given in the question and candidates may have
                to find a similar analogy, based on the same pattern from the
                given options
              </li>
              <li>
                <span className={classes.listHeading}>
                  Alphabetical / Word Analogy (Choose a similar pair) –{" "}
                </span>
                An analogy may be given based on a set pattern and candidates
                may have to find the word analogy pair from the given options,
                which follows the exact same pattern
              </li>
            </ul>
            <Button className={classes.lowerButton}>
              Download PDF
              <img src={PdfIcon} alt="PDF" className={classes.icon} />
            </Button>
          </Typography>
          <Typography variant="h5" gutterBottom>
            <b>Understanding Analogies </b>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Understanding Analogies<br></br> An analogy is a comparison between
            two pairs of terms or objects. It establishes a relationship based
            on certain attributes or characteristics shared by the terms in each
            pair. The objective is to identify this relationship and use it to
            make deductions about a new pair of terms or objects. 
          </Typography>
          <Typography variant="h5" gutterBottom>
            <b>Use of Analogy Reasoning</b>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Analogy reasoning can be used in various fields, including
            problem-solving, decision-making, and learning. It helps us make
            connections between familiar and unfamiliar situations, transfer
            knowledge from one context to another, and generate new insights or
            solutions.
          </Typography>
          <Typography variant="h5" gutterBottom>
            <b>Forms of Analogies</b>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Analogies can take various forms, depending on the context and the
            type of relationship being established. Here are some common forms
            of analogies:{" "}
            <a
              href="https://www.parikshado.com/teaching-jobs-exam/analogy-reasoning/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
}
export default NoteContent;
