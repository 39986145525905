import { makeStyles } from "@material-ui/core";

import React from "react";
const useStyles = makeStyles((theme) => ({
  secHead: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  secHeadmain: {
    textAlign: "center",
  },
  secheading: {
    fontFamily: "'Playfair Display', serif !important",
    fontSize: '1.5rem',
    margin: "0",
    color: "#252525",
    fontWeight: '500',
    [theme.breakpoints.down("xs")]: {
      fontSize: '1rem',
      margin: "0",
      color: "#252525",
      fontWeight: '400',
    },
  },
  secheadingmain: {
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "3rem",
    color: '#252525',
    fontWeight: '700',
    margin: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: " 40px",
      textAlign: "center",
      padding: "0px 1rem",
      fontFamily: "'Playfair Display', serif !important",
    },
  },
  secheadhr: {
    width: "175px",
    marginTop: "15px",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
    [theme.breakpoints.down("xs")]: {
    width: "130px",
    marginTop: "0.6rem",
      marginBottom: "0rem",
    },
  },
}));
function SecHead(props) {
  const { Heading, media } = props;
  const classes = useStyles();
  return (
    <>
      {Heading.map((data) => (
        <div className={classes.secHead}>
          <div className={classes.secHeadmain}>
            {media ?
              <>
                <h1 className={classes.secheadingmain}>{data.headingmain}</h1>
                <h2 className={classes.secheading}>{data.headingsmall}</h2>
              </>
              :
              <>
                <h1 className={classes.secheadingmain}>{data.headingmain}</h1>
                <h2 className={classes.secheading}>{data.headingsmall}</h2>
              </>
            }
            <hr className={classes.secheadhr} />
          </div>
        </div>
      ))}
    </>
  );
}
export default SecHead;
