import React, { useState, useEffect, useRef, useContext } from "react";
import { Avatar, Typography, makeStyles, Box } from "@material-ui/core";
import ProfileAvatar from "../image/header/profile_avatar.png";
import DashboardSidePannel from "./dashboardsidepannel";
import MyTestSeries from "./MyTestSeries";
import TestSeriesInner from "./TestSeriesInner";
import StudyMaterial from "./myStudyMaterial/studyMaterial";
import MyPurchase from "./MyPurchase/MyPurchase";
import OrderDetails from "./MyBooks/OrderDetails";
import OrderSummary from "./MyPurchase/OrderSummary";
import MyBookList from "./MyBookList";
import AddResellBooks from "./AddResellBooks";
import Help from "./helpDesk/help";
import ReferAndEarn from "./referAndEarn/referAndEarn";
import EbooksInner from "./myStudyMaterial/EbooksInner";
import NotesInner from "./myStudyMaterial/NotesInner";
import MyLiveClasses from "./MyLiveClasses/MyLiveClasses";
import DoubtForm from "./connection/doubtForm";
import Achievements from "./achivements/Achievements";
import MyWallet from "./myWallet/myWallet";
import VideoCourse from "./videoCourse/videoCourse";
import SeriesForUpsc from "./videoCourse/seriesForUpsc";
import DashboardMain from "./Dashboard Main/DashboardMain";
import Settings from "./Settings/Settings";
import DashboardCardInner from "./Dashboard Main/DashboardCardInner";
import { useNavigate } from "react-router-dom";
import MyBooks from "./MyBooks/MyBooks";
import AppContext from "../../context/AppContext";
import { getProfileData, getLearnerMenu } from "../../service/services";
import FetchProfileContext from "../../context/FetchUserProfile/FetchProfileContext";
import Timetable from "./TimeTable/titmeTable";
import Feedback from "./Feedback/Feedback";
import FeedbackInner from "./Feedback/FeedbackInner";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  containerfluid: {
    display: "flex",
    alignItems: "flex-start",
    "& ::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
    "& -ms-overflow-style": "none" /* Internet Explorer 10+ */,
    "& scrollbarWidth": "none" /* Firefox */,
  },
  root: {
    marginTop: "2rem",
    position: "sticky",
    top: "0px",
    zIndex: "100",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFF",
  },
  profileName: {
    color: "#243B55",
    fontFamily: "'Playfair Display', serif !important",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "2.5rem",
  },
  fontSize1: {
    fontSize: "1.25rem",
    transition: "100ms",
  },
  fontSize2: {
    fontSize: "1rem",
    transition: "100ms",
  },
  scrollingShadow: {
    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.09)",
    transition: "100ms",
  },
  sidemenu: {
    width: "20%",
    height: "90vh",
    overflow: "auto",
    background: "#FFF",
    boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.06)",
    position: "relative",
    // display: "flex",
    // justifyContent: "center",
    // flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    transition: "100ms",
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    transition: "100ms",
  },
  maincontent: {
    width: "80%",
    height: "90vh",
    overflow: "auto",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& .MuiBox-root": {
        // padding: "0",
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function Dashboard({ value, setValue, learnerMenu }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [onViewClicked, setOnViewClicked] = useState("");
  const [data, setData] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const storedValue = localStorage.getItem("activeTab");
  const { isLoggedIn, token } = useContext(AppContext);
  const { profileState } = useContext(FetchProfileContext);
  const handleData = (name, data) => {
    setOnViewClicked(name);
    setData(data);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem("activeTab", value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    setOnViewClicked("");
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      setValue(value);
      setOnViewClicked("");
    }
    window.scrollTo(0, 0);
  }, [value]);

  const myRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (myRef.current && myRef.current.scrollTop !== 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (myRef.current) {
      myRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (myRef.current) {
        myRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     getLearnerMenu(token)
  //       .then((res) => {
  //         setLearnerMenu(res?.data?.menu);
  //       })
  //       .catch((e) => console.log("dalsfknlk", e));
  //   }
  // }, [isLoggedIn]);

  return (
    <div className={classes.containerfluid}>
      <div ref={myRef} className={classes.sidemenu}>
        <div
          className={`${classes.root} ${
            isScrolled ? classes.scrollingShadow : ""
          }`}
        >
          <Avatar
            alt="Remy Sharp"
            src={
              profileState.image
                ? profileState.image
                : profileState.user.image_url
                ? profileState.user.image_url
                : ProfileAvatar
            }
            className={`${isScrolled ? classes.small : classes.large}`}
          />
          <Typography
            variant="h4"
            gutterBottom
            className={`${classes.profileName} ${
              isScrolled ? classes.fontSize2 : classes.fontSize1
            }`}
          >
            Hello.! {profileState?.user?.full_name}
          </Typography>
        </div>
        <div>
          <DashboardSidePannel
            learnerMenulength={learnerMenu}
            value={value}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div id="main-content" className={classes.maincontent}>
        <TabPanel value={value} index={1}>
          {onViewClicked === "dashboardinnercard" ? (
            <DashboardCardInner setOnViewClicked={setOnViewClicked} />
          ) : onViewClicked === "ordersummary" ? (
            <OrderSummary setOnViewClicked={setOnViewClicked} data={data} />
          ) : (
            <DashboardMain
              setOnViewClicked={handleData}
              learnerMenu={learnerMenu}
              handleChange={handleChange}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {onViewClicked !== "testseriesinner" ? (
            <MyTestSeries setOnViewClicked={handleData} />
          ) : (
            <TestSeriesInner setOnViewClicked={setOnViewClicked} data={data} />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {onViewClicked === "" ? (
            <StudyMaterial
              valuesidepannel={value}
              setOnViewClicked={handleData}
              data={data}
            />
          ) : onViewClicked !== "notesinner" ? (
            <EbooksInner setOnViewClicked={setOnViewClicked} data={data} />
          ) : (
            <NotesInner setOnViewClicked={setOnViewClicked} data={data} />
          )}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {onViewClicked !== "orderdetails" ? (
            <MyBooks setOnViewClicked={handleData} data={data} />
          ) : (
            <OrderDetails setOnViewClicked={setOnViewClicked} data={data} />
          )}
        </TabPanel>

        <TabPanel value={value} index={5}>
          {onViewClicked !== "testseriesinner" ? (
            <VideoCourse setOnViewClicked={handleData} data={data} />
          ) : (
            <SeriesForUpsc setOnViewClicked={setOnViewClicked} data={data} />
          )}
        </TabPanel>
        <TabPanel value={value} index={6}>
          <MyLiveClasses setOnViewClicked={setOnViewClicked} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Timetable />
        </TabPanel>
        <TabPanel value={value} index={8}>
          {onViewClicked !== "ordersummary" ? (
            <MyPurchase setOnViewClicked={handleData} data={data} />
          ) : (
            <OrderSummary setOnViewClicked={setOnViewClicked} data={data} />
          )}
        </TabPanel>
        <TabPanel value={value} index={9}>
          <Achievements />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <DoubtForm />
        </TabPanel>
        <TabPanel value={value} index={11}>
          {onViewClicked !== "feedbackinner" ? (
            <Feedback setOnViewClicked={handleData} />
          ) : (
            <FeedbackInner setOnViewClicked={setOnViewClicked} data={data} />
          )}
        </TabPanel>
        <TabPanel value={value} index={12}>
          <MyWallet />
        </TabPanel>
        <TabPanel value={value} index={13}>
          {onViewClicked !== "addbook" ? (
            <MyBookList setOnViewClicked={setOnViewClicked} />
          ) : (
            <AddResellBooks setOnViewClicked={setOnViewClicked} />
          )}
        </TabPanel>
        <TabPanel value={value} index={14}>
          <ReferAndEarn />
        </TabPanel>
        <TabPanel value={value} index={15}>
          <Help />
        </TabPanel>
        <TabPanel value={value} index={16}>
          <Settings />
        </TabPanel>
      </div>
    </div>
  );
}
export default Dashboard;
