import React from 'react'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import booksboy from "../image/testseriesvector/booksboy.png";
import vector from "../image/dashboard/vector.png";

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: "20rem",

    },
    root: {
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
        width: "48.9%",
        padding: "10px",
        marginBottom: "1rem",
        background: "#FBFBFB",
        border: "1px solid #CECECE",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: "0px",
            "& .MuiCardContent-root": {
              padding: "11px",
            }
          },
    },
    middle: {
        display: "flex",
        justifyContent: "space-between",
    },
    head1: {
        textAlign: "center",
        fontSize: "0.675rem",
        fontFamily: "Jost",
        fontWeight: "700",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.5rem",
          },
    },
    icon: {
        color: "#FFE70B",
        width: "17%",
    },
    top: {
        width: "55%",
        background: "#11B90E",
        color: "#FFF",
        borderRadius: "6px",
        padding: "0.3rem"
    },
    top1: {
        width: "137px",
        background: "#E5FFF6",
        textAlign: "center",
        padding: "2px 0",
        fontSize: "0.775rem",
        fontFamily: "Jost",
        fontWeight: "600",
        border: "1px solid #AFFFE3",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.7rem",
        },
    },
    center: {
        marginTop: "20px",
    },
    head2: {
        color: "#252525",
        fontFamily: "Playfair Display",
        fontSize: "1.2rem",
        fontWeight: "700",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.85rem",
        },
    },
    head3: {
        color: "#252525",
        fontFamily: "Playfair Display",
        fontSize: "15px",
        fontWeight: "600",
        marginTop: "10px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.7rem",
          marginTop: "0.2rem",
        },
    },
    btn: {
        marginTop: "30px",
        width: "147px",
        height: "30px",
        background: "linear-gradient(90deg, #141E30 0%, #243B55 96.17%)",
        textAlign: "center",
        fontFamily: "Jost",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: "white",
        padding: "1.3rem 0.6rem",
        borderRadius: "0.4rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.7rem",
          marginTop: "0.3rem",
          width: "108px",
          padding: "1rem 0.4rem",
        },
    },
    contentright: {
        width: "30%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    contentleft: {
        width: "70%",
    },
    imgcontainer: {
        position: "relative",
        top: "2rem",
        right: "-1.2rem",
    },
    head4: {
        color: "#252525",
        fontFamily: "Jost",
        fontSize: "medium",
        fontWeight: "600",
        marginTop: "1rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.8rem",
            marginTop: "1rem",
        },
    },
    head5: {
        color: "#252525",
        fontFamily: "Jost",
        fontSize: "1rem",
        fontWeight: "400",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.8rem",
        },
    }
}));

const AvailableLiveTestCard = () => {
    const classes = useStyles();
    return (
        <>
            <Card className={classes.root}>
                <CardContent className={classes.middle}>
                    <div className={classes.contentleft}>
                        <div className={classes.top}>
                            <div className={classes.head1}>
                                {/* {" "}
                                <FiberManualRecordIcon className={classes.icon} /> */}
                                AVAILABLE NOW
                            </div>
                        </div>
                        <div className={classes.center}>
                            <Typography className={classes.head2}>
                                {" "}
                                All India Mock:SSC JE CBT-2 (Electricle) 25-NOV-2023
                            </Typography>
                            <Typography className={classes.head3}>
                                (Electricle Engineer)
                            </Typography>
                            <Typography className={classes.head4}>Starts At: 10:00 AM</Typography>
                            <Typography className={classes.head5}>25Th November 2023</Typography>
                        </div>

                    </div>
                    <div className={classes.contentright}>
                        <div className={classes.top1}> Special Reconization</div>
                        <Button className={classes.btn}>Start Now  &nbsp;<img src={vector} alt="img"/></Button>

                    </div>
                </CardContent>
            </Card>

        </>

    )
}

export default AvailableLiveTestCard
