import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ExclusiveBooksSection from "../../Home/exclusivebooks/exclusivebooksection";
import { ReactComponent as MessageIcon } from "../../image/singlepagedescriptionvector/msgicon.svg";
import { ReactComponent as DownloadIcon } from "../../image/singlepagedescriptionvector/downloadicon.svg";
import { ReactComponent as LockSvg } from "../../image/singlepagedescriptionvector/lockicon.svg";
import {
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1rem",
    display: "flex",
    justifyContent: "space-between",

    "& .MuiTypography-h4": {
      fontSize: "0.625rem !important",
      fontWeight: "500",
      fontFamily: "Playfair Display !important",
    },
  },

  head: {
    background: "#FFF6E1",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    "& svg": {
      width: "35px",
      height: "35px",
    },
    "& .MuiTypography-h3": {
      fontSize: "0.875rem",
      fontWeight: "700",
      fontFamily: "Playfair Display",
    },
  },
  unlocked: {
    width: "48%",
    boxShadow: "0px 6px 10px rgba(228, 228, 228, 0.65)",
    borderRadius: "8px",
    border: "1px solid #EDEDED",
    margin: "1rem 0",
    "& .MuiTypography-h6": {
      marginTop: "1rem",
      width: "100%",
      fontSize: "0.625rem !important",
      fontWeight: "500",
      fontFamily: "Playfair Display !important",
      color: "#154FE5 !important",
    },
    "& .MuiIconButton-label": {
      color: "#20324A",
      fontSize: "0.625rem",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
    },
    "& .MuiIconButton-root": {
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    },
  },
  locked: {
    width: "48%",
    boxShadow: "0px 6px 10px rgba(228, 228, 228, 0.65)",
    borderRadius: "8px",
    border: "1px solid #EDEDED",
    margin: "1rem 0",
    "& .MuiTypography-h6": {
      marginTop: "1rem",
      width: "100%",
      fontSize: "0.625rem !important",
      fontWeight: "500",
      fontFamily: "Playfair Display !important",
      color: "#B1B1B1 !important",
    },
    "& .MuiIconButton-label": {
      color: "#B1B1B1",
      fontSize: "0.625rem",
      fontWeight: "600",
      fontFamily: "'Jost', sans-serif",
      textTransform: "uppercase",
    },
    "& .MuiIconButton-root": {
      background: "#EFECEC",
    },
  },
  content: {
    padding: "0.4rem 0.5rem",
  },
  buttonscontainer: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  yellowcustombtn: {
    borderRadius: "5px",
    "& svg": {
      marginRight: "0.3rem",
    },
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
  },
}));

export default function NotesInSiteSectionMobile() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.unlocked}>
        <div className={classes.head}>
          {/* <img src={MessageIcon} alt="img" /> */}
          <MessageIcon />
          <Typography variant="h3">Topics</Typography>
        </div>
        <div className={classes.content}>
          <Typography variant="h4">Reasoning Analogy</Typography>
          <Typography variant="h6">
            Explanation, Instructions and Questions on Reasoning Analogy
          </Typography>
          <div className={classes.buttonscontainer}>
            <IconButton variant="contained" className={classes.yellowcustombtn}>
              <DownloadIcon />
              Download PDF
            </IconButton>
          </div>
        </div>
      </div>

      <div className={classes.locked}>
        <div className={classes.head}>
          <MessageIcon />
          <Typography variant="h3">Topics</Typography>
        </div>
        <div className={classes.content}>
          <Typography variant="h4">Reasoning Analogy</Typography>
          <Typography variant="h6">
            Explanation, Instructions and Questions on Reasoning Analogy
          </Typography>
          <div className={classes.buttonscontainer}>
            <IconButton variant="contained" className={classes.yellowcustombtn}>
              <LockSvg />
              Unlock
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}
