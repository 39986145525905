import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
    makeStyles,
  } from "@material-ui/core";
  import React, { useState } from "react";
  import Image from "../../image/testseriesvector/testseriescardimage.png";
  import { ReactComponent as HexagoneTag } from "../../image/testseriesvector/hexagonimagemobile.svg";
  const useStyles = makeStyles((theme) => ({
    TestSeriesCardMobile: {
      position: "relative",
      height: "100%",
      justifyContent: "space-between",
      width: "32%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
  
      display: "flex !important",
      margin: "1rem 5px",
      flexDirection: "column",
      // minHeight: 482,
      borderRadius: 7,
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      "&:hover": {
        boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
        cursor: "pointer",
        "& .MuiCardActions-root": {
          background:
            "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
          borderRadius: 6,
          "& .MuiButton-label": {
            fontFamily: "Jost",
            alignItems: "center",
          },
        },
      },
      "& .MuiCardActionArea-root": {
        display: "flex",
        justifyContent: "space-between",
      },
      "& .MuiCardActions-root": {
        background:
          "linear-gradient(90deg, rgba(255,128,8,0.6000000238418579) 0%, rgba(255,200,55,0.6000000238418579) 100%)",
        borderRadius: 6,
        padding: "4px 8px",
        "& .MuiButton-label": {
          fontFamily: "Jost",
        },
      },
      "& fieldset": {
        margin: "0 0 20px -3px",
        padding: 0,
      },
      "& .MuiBox-root": {
        border: "none",
        display: "flex",
        alignItems: "center",
      },
      "& .MuiCardContent-root": {
        padding: "8px 5px",
        minHeight: "5rem",
        width: "70%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
      },
      "& .MuiTypography-h5": {
        fontFamily: "'Playfair Display', serif !important",
        fontWeight: "600",
        fontSize: "0.725rem",
      },
      "& .MuiTypography-caption": {
        fontFamily: "'Jost', sans-serif",
        color: "#777",
        fontSize: "0.5rem",
        fontWeight: "400",
      },
      "& .MuiTypography-h4": {
        fontFamily: "'Jost', sans-serif",
        color: "#FF3939",
        fontSize: "0.9375rem",
        fontWeight: "500",
      },
      "& .MuiTypography-body1": {
        marginLeft: "1rem",
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.125rem",
        fontWeight: "600",
      },
      "& .MuiCardMedia-root": {
        margin: "0 auto",
        width: "75% !important",
        borderRadius: "3%",
      },
      "&.MuiCardMedia-media": {
        width: "50% !important",
      },
    },
    cardimgcont: {
      background: "#FFE8B5",
      margin: "0 0.6rem",
      borderRadius: 8,
      padding: "0.8rem 0",
      width: "30%",
      "&:hover": {
        background: "linear-gradient(136deg, #FFEEC3 38.84%, #FFD9B5 57.03%)",
      },
    },
    bgorange: {
      background:
        "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
      color: "#212121 !important",
    },
    textred: {
      color: "#FF3939",
      fontSize: "1.0625rem",
      fontFamily: "Jost",
      fontWeight: "600",
      marginRight: "0.5rem",
    },
    offertext: {
      marginLeft: "1rem",
      color: "#252525",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.5rem",
      fontWeight: "400",
    },
    newlaunchtag: {
      position: "absolute",
      top: '3px',
      right: "3px",
      padding: "0.2rem 1rem",
      background: "#FF3939",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
      borderRadius: "8px",
      "& .MuiTypography-h6": {
        color: "#FFF",
        fontSize: "0.5rem",
        fontFamily: "'Jost', sans-serif",
        fontWeight: "400",
      },
    },
    quikviewbtn: {
      position: "absolute",
      left: "54px",
      top: "60px",
      background: "white",
      padding: "0.6rem 1.5rem",
      opacity: "0.95",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
      "&:hover": {
        background: "white",
      },
      "& .MuiButton-label": {
        fontFamily: "'Jost', sans-serif",
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
      },
    },
    testserandvaliditytagcont: {
      display: "flex",
      margin: "0.6rem 0 0.6rem 1rem",
      position: "relative",
      width: "70%",
      justifyContent: "space-between",
  
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "0.475rem",
        fontFamily: "'Jost', sans-serif",
        fontWeight: "500",
      },
    },
    innertags: {
      // background: "#FFF",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
      padding: "0.1rem 0.6rem",
      borderRadius: "0.4rem",
    },
    hexagoncontainer: {
      position: "absolute",
      left: "-25px",
      top: "-10px",
    },
  }));
  function TestSeriesCardMobileDash({Data}) {
  
    const classes = useStyles();
    return (
      <>
        <Card className={classes.TestSeriesCardMobile}>
          <div className={classes.newlaunchtag}>
            <Typography variant="h6">NEW LAUNCH</Typography>
          </div>
          <CardActionArea>
            <div className={classes.cardimgcont}>
              <CardMedia component="img" image={Data?.image_url||Image} alt="green iguana" />
            </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              {Data?.category?.category_name||"SSC" }  Maha Pack
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                ( SSC CGL, CHSL, CPO, MTS, GD, JHT)
              </Typography>
              <div className={classes.testserandvaliditytagcont}>
                <div className={classes.hexagoncontainer}>
                  <HexagoneTag />
                </div>
                <div className={classes.innertags}>
                  <Typography variant="h6">Test Series</Typography>
                </div>
                <div className={classes.innertags}>
                  <Typography variant="h6">Validity:{Data?.validity||"6 Months"} </Typography>
                </div>
              </div>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                <span className={classes.textred}>
                  <span style={{ fontSize: "1rem", verticalAlign: "top" }}>
                    ₹
                  </span>
                {Data?.selling_cost||"550"}.00
                </span>
                <Typography variant="caption">
                  MRP : <del>{Data?.mrp||"80"}.00</del>
                </Typography>
                <span className={classes.offertext}>({Data?.discount_percent||"30"}% off)</span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </>
    );
  }
  export default TestSeriesCardMobileDash;
  