import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ProfileCover from "../../image/dashboard/setting/profilecover.png";
import Profile from "../../image/dashboard/setting/profile.png";
import { FaPen } from "react-icons/fa";
import {
  UpdateProfile,
  getCities,
  getStates,
  UpdateProfilePicture,
  getProfileData,
} from "../../../service/services";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import FetchProfileContext from "../../../context/FetchUserProfile/FetchProfileContext";
import PickCategoryModal from "../../allpopups/PickCategory";
import { Autocomplete } from "@material-ui/lab";
import CategoryContext from "../../../context/CategoryContext/CategoryContext";

const useStyles = makeStyles((theme) => ({
  profileheader: {
    height: "148px",
    marginTop: "1.5rem",
    background: `url(${ProfileCover})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% ",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "100% 100%",
    },
  },
  profile: {
    position: "absolute",
    bottom: "-35px",
    left: "65px",
    [theme.breakpoints.down("xs")]: {
      bottom: "-6px",
      left: "100px",
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    background: "#FFF",
    padding: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  root1: {
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.06)",
    marginTop: "2rem",
    background: "#FFF",
    padding: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      padding: "1rem 1.5rem",
    },
  },
  personaldetailscontainer: {
    width: "70%",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  personalinfo: {
    width: "30%",
  },
  label: {
    color: "#333",
    fontFamily: "Jost",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
  },
  textfiled: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },

    borderRadius: " 0.375rem",
    border: "1px solid #C8C8C8",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textfiled1: {
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },

    borderRadius: " 0.375rem",
    border: "1px solid #C8C8C8",
  },
  field: {
    margin: "1rem",
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem auto",
      width: "100%",
    },
  },
  field1: {
    margin: "1rem 0rem",
    width: "46%",
    [theme.breakpoints.down("xs")]: {
      margin: "0.5rem 0rem",
      width: "100%",
    },
  },
  address: {
    color: " #16283F",
    fontFamily: "Jost",
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: "2.25rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: "30%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  addresscontainer: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  inputcontainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  btncontainer: {
    width: "100%",
    margin: "1rem auto",
    textAlign: "right",
  },
  viewbtn: {
    "&.MuiButton-root": {
      background: "#243B55",
      color: "#FFF",
      textTransform: "capitalize",
      padding: "0.3rem 2.5rem",
      borderRadius: "0.375rem",
      fontFamily: "Jost",
      fontWeight: "400",
      fontSize: "0.875rem",
    },
  },
  w100: {
    width: "100%",
  },
  inputRoot: {
    "& .MuiAutocomplete-input": {
      padding: "0 !important",
    },
    borderRadius: " 0.375rem",
    border: "1px solid #C8C8C8",
  },
  mr0_5: {
    marginRight: "0.5rem",
  },
}));

const initialData = {
  name: "",
  email: "",
  phone: "",
  guardianName: "",
  guardianEmail: "",
  guardianPhone: "",
  dob: null,
  gender: "select",
  preferredCourse: "",
  about: "",
  facebookLink: "",
  instaLink: "",
  linkedinLink: "",
  address: "",
  pincode: null,
};

const MyProfile = () => {
  const classes = useStyles();
  const { isLoggedIn, token, user } = useContext(AppContext);

  const {
    fetchProfileDataAPI,
    updateProfilePictureAPI,
    updateFormDataAPI,
    profileState,
  } = useContext(FetchProfileContext);
  const categoryContext = useContext(CategoryContext);

  useEffect(() => {
    fetchProfileDataAPI(token);
  }, [token]);

  const [state, setState] = useState("");
  const [stateId, setStateId] = useState(null);
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState(null);

  const [formDetails, setFormDetails] = useState(initialData);

  const navigate = useNavigate();
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [thumbnailImagePreview, setThumbnailImagePreview] = useState(null);
  const [formError, setFormErrors] = useState(false);

  const [isCatModalOpen, setIsCatModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const [isEdit, setIsEdit] = useState(true);
  const [leanerName, setLeanerName] = useState("");
  const [categoryData, setCategoryData] = useState("");

  useEffect(() => {
    categoryContext?.megaMenu?.map((data) => {
      setCategoryData(data?.categories);
    });
  }, []);

  const openCategoryModal = () => {
    setIsCatModalOpen(true);
  };

  const closeCategoryModal = () => {
    setIsCategoryModalOpen(false);
    const preferredCourse = localStorage.getItem("selectedCategory");
    const category = Array.isArray(categoryData)
      ? categoryData.find(
          (data) => String(data?.id) === String(preferredCourse)
        )
      : null;

    setFormDetails((prevDetails) => ({
      ...prevDetails,
      ["preferredCourse"]: category?.category_name,
    }));
  };

  const handlePreferredCourse = () => {
    setIsCategoryModalOpen(isEdit);
  };

  function convertDateFormat(inputDate) {
    // Split the date string into day, month, and year
    const [year, month, day] = inputDate.split("-");

    // Rearrange the parts to the desired format
    const formattedDate = `${year}/${month}/${day}`;

    return formattedDate;
  }
  function convertDateFormatView(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const handleFormChange = (field, value) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };
  const handleStateChange = (event, value) => {
    setState(value);
    setStateId(value.id);
  };
  const handleDistrictChange = (event, value) => {
    setCity(value);
    setCityId(value.id);
  };
  const handleThumbnailImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setThumbnailImage(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        setThumbnailImagePreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };
  const [stateData, setStateData] = useState([]);
  async function fetchStateData() {
    try {
      const response = await getStates();
      setStateData(response?.data?.states);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchStateData();
  }, [profileState]);

  const [cityData, setCityData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        if (state) {
          const response = await getCities(stateId);
          setCityData(response?.data?.cities);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [stateId]);

  const fetchProfileData = () => {
    const user = profileState?.user;

    const categoryId = user?.user_data?.category;
    // const category = Array.isArray(categoryData)
    //   ? categoryData.find((data) => String(data?.id) === String(categoryId))
    //   : null;

    const formData = {
      name: user?.full_name,
      email: user?.email,
      phone: user?.phone,
      address: user?.user_data?.address,
      pincode: user?.user_data?.pincode,
      about: user?.user_data?.about,
      dob: user?.user_data?.dob
        ? convertDateFormatView(user?.user_data?.dob)
        : null,
      gender: user?.user_data?.gender ? user?.user_data?.gender : "select",
      guardianName: user?.user_data?.guardian_name,
      guardianEmail: user?.user_data?.guardian_email,
      guardianPhone: user?.user_data?.guardian_phone,
      facebookLink: user?.user_data?.facebook_link,
      instaLink: user?.user_data?.instagram_link,
      linkedinLink: user?.user_data?.linkedin_link,
      preferredCourse: user?.user_data?.category,
    };
    setFormDetails(formData);
    setThumbnailImagePreview(user?.image_url);

    setStateId(user?.user_data?.state_id);
    setCityId(user?.user_data?.district_id);
    setIsEdit(formDetails.dob === null ? true : false);
    setState(user?.user_data?.state);
    setCity(user?.user_data?.city);
    setLeanerName(user?.full_name);
  };
  useEffect(() => {
    fetchProfileData();
  }, [profileState]);

  const updateProfilePicture = () => {
    const formData = new FormData();
    formData.append("file", thumbnailImage);

    updateProfilePictureAPI(formData, token);
    // console.log("API Response:", response);
    // toast.success("profile picture updated successfully");
    // fetchProfileData();
  };

  useEffect(() => {
    if (thumbnailImage) {
      updateProfilePicture();
    }
  }, [thumbnailImage]);

  const handleFormSubmit = async () => {
    const errors = {};
    if (!formDetails.name) errors.name = true;
    if (!formDetails.phone) errors.phone = true;
    if (!formDetails.email) errors.email = true;
    if (!formDetails.dob) errors.dob = true;
    if (formDetails.gender === "select") errors.gender = true;
    if (!formDetails.preferredCourse) errors.preferredCourse = true;
    if (!formDetails.address) errors.address = true;
    if (!stateId) errors.state = true;
    if (!cityId) errors.city = true;
    if (!formDetails.pincode) errors.pincode = true;
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (!formDetails.name) {
      toast.error("Please type your name");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formDetails.email)) {
      toast.error("Invalid email address");
      return;
    }
    const validatePhoneNumber = (phoneNumber) => {
      const phoneRegex = /^[0-9]{10}$/;
      return phoneRegex.test(phoneNumber);
    };

    if (!validatePhoneNumber(formDetails.phone)) {
      toast.error("Invalid phone number. Please enter exactly 10 digits.");
      return;
    }

    // const formattedDate = convertDateFormat(formDetails.dob);
    const formattedUser = {
      user: {
        full_name: formDetails.name,
        email: formDetails.email,
        phone: formDetails.phone,
        address: formDetails.address,
        state_id: stateId,
        district_id: cityId,
        pincode: formDetails.pincode,
        about: formDetails.about,
        dob: formDetails.dob ? convertDateFormat(formDetails.dob) : null,
        gender: formDetails.gender === "select" ? null : formDetails.gender,
        guardian_name: formDetails.guardianName,
        guardian_email: formDetails.guardianEmail,
        guardian_phone: formDetails.guardianPhone,
        facebook_link: formDetails.facebookLink,
        instagram_link: formDetails.instaLink,
        linkedin_link: formDetails.linkedinLink,
        category: formDetails.preferredCourse,
      },
    };

    updateFormDataAPI(formattedUser, token);

    fetchProfileDataAPI(token);

    setIsEdit(false);
  };

  return (
    <>
      <div className={classes.profileheader}>
        <div className={classes.profile}>
          <input
            accept="image/*"
            className={classes.input}
            id="faceImage"
            type="file"
            hidden
            onChange={handleThumbnailImageChange}
          />
          <Tooltip title="Select Image">
            <label htmlFor="faceImage">
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={profileState?.image || thumbnailImagePreview || Profile}
                  alt="img"
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                <FaPen
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                    fontSize: "20px",
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: "50%",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </label>
          </Tooltip>
        </div>
      </div>
      <div className={classes.root}>
        <div className={classes.personalinfo}></div>
        <div className={classes.personaldetailscontainer}>
          <div className={classes.inputcontainer}>
            <div className={classes.field}>
              <label className={classes.label}>Full Name</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.name}
                onChange={(e) => handleFormChange("name", e.target.value)}
                error={!formDetails.name && formError.name}
                variant="outlined"
              />
              <br />
              {!formDetails.name && formError.name && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your name
                </Typography>
              )}
            </div>

            <div className={classes.field}>
              <label className={classes.label}>E-Mail</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <TextField
                InputProps={{
                  disabled: true,
                }}
                variant="outlined"
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.email}
                onChange={(e) => handleFormChange("email", e.target.value)}
                error={!formDetails.email && formError.email}
              />
              <br />
              {!formDetails.email && formError.email && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your email
                </Typography>
              )}
            </div>

            <div className={classes.field}>
              <label className={classes.label}> Contact No.</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <TextField
                InputProps={{
                  disabled: true,
                }}
                variant="outlined"
                className={classes.textfiled}
                placeholder="+91"
                value={formDetails.phone}
                onChange={(e) => {
                  const inputPhoneNumber = e.target.value;
                  if (
                    /^[0-9]*$/.test(inputPhoneNumber) &&
                    inputPhoneNumber.length <= 10
                  ) {
                    if (
                      /^[6-9]/.test(inputPhoneNumber) ||
                      inputPhoneNumber.length === 0
                    ) {
                      handleFormChange("phone", inputPhoneNumber);
                    }
                  }
                }}
                error={!formDetails.phone && formError.phone}
              />
              <br />
              {formDetails.phone?.length > 0 &&
                formDetails.phone?.length !== 10 && (
                  <Typography
                    variant="caption"
                    style={{ color: "red", marginTop: "5px" }}
                  >
                    Invalid phone number. Please enter at least 10 digits.
                  </Typography>
                )}
              {!formDetails.phone && formError.phone && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your phone number
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.inputcontainer}>
            <div className={classes.field}>
              <label className={classes.label}>Guardian Name</label>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.guardianName}
                onChange={(e) =>
                  handleFormChange("guardianName", e.target.value)
                }
                variant="outlined"
              />
            </div>

            <div className={classes.field}>
              <label className={classes.label}>Guardian E-Mail</label>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                variant="outlined"
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.guardianEmail}
                onChange={(e) =>
                  handleFormChange("guardianEmail", e.target.value)
                }
              />
            </div>

            <div className={classes.field}>
              <label className={classes.label}>Guardian Contact No.</label>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                variant="outlined"
                className={classes.textfiled}
                placeholder="+91"
                value={formDetails.guardianPhone}
                onChange={(e) =>
                  handleFormChange("guardianPhone", e.target.value)
                }
              />
            </div>
          </div>
          <div className={classes.inputcontainer}>
            <div className={classes.field}>
              <label className={classes.label}>Date of Birth</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                type="date"
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.dob}
                onChange={(e) => handleFormChange("dob", e.target.value)}
                variant="outlined"
                error={!formDetails.dob && formError.dob}
              />
              <br />
              {!formDetails.dob && formError.dob && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your date of birth
                </Typography>
              )}
            </div>

            <div className={classes.field}>
              <label className={classes.label}>Gender</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <Select
                disabled={!isEdit}
                className={classes.textfiled}
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                disableUnderline
                MenuProps={menuProps}
                value={formDetails.gender}
                onChange={(e) => handleFormChange("gender", e.target.value)}
                variant="outlined"
                error={formDetails.gender === "select" && formError.gender}
              >
                <MenuItem disabled value="select">
                  <em className={classes.defaultselect}>Select Here</em>
                </MenuItem>

                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
              <br />
              {formDetails.gender === "select" && formError.gender && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your gender
                </Typography>
              )}
            </div>

            <div className={classes.field}>
              <label className={classes.label}>Preferred Course</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                disabled={!isEdit}
                className={classes.textfiled}
                placeholder="select here"
                value={formDetails.preferredCourse}
                onClick={handlePreferredCourse}
                variant="outlined"
                error={
                  !formDetails.preferredCourse && formError.preferredCourse
                }
              />
              <br />
              {!formDetails.preferredCourse && formError.preferredCourse && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your preferred course
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.inputcontainer}>
            <div className={`${classes.field} ${classes.w100}`}>
              <label className={classes.label}>About</label>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                className={classes.textfiled}
                multiline
                minRows="3"
                placeholder="Type Here"
                value={formDetails.about}
                onChange={(e) => handleFormChange("about", e.target.value)}
                variant="outlined"
              />
            </div>
          </div>

          <div className={classes.inputcontainer}>
            <div className={classes.field}>
              <label className={classes.label}>Facebook Link</label>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.facebookLink}
                onChange={(e) =>
                  handleFormChange("facebookLink", e.target.value)
                }
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <label className={classes.label}>Instagram Link</label>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.instaLink}
                onChange={(e) => handleFormChange("instaLink", e.target.value)}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <label className={classes.label}>Linkedin Link</label>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.linkedinLink}
                onChange={(e) =>
                  handleFormChange("linkedinLink", e.target.value)
                }
                variant="outlined"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.root1}>
        <div className={classes.address}>*Address</div>
        <div className={classes.addresscontainer}>
          <div className={classes.inputcontainer}>
            <div className={`${classes.field} ${classes.w100}`}>
              <label className={classes.label}>Address</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                className={classes.textfiled1}
                placeholder="Type Here"
                fullWidth
                value={formDetails.address}
                onChange={(e) => handleFormChange("address", e.target.value)}
                variant="outlined"
                multiline
                minRows="3"
                error={!formDetails.address && formError.address}
              />
              <br />
              {!formDetails.address && formError.address && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your address
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.inputcontainer}>
            <div className={classes.field}>
              <label className={classes.label}>State</label>
              <span style={{ color: "red" }}>*</span>
              <Autocomplete
                disabled={!isEdit}
                id="state-autocomplete"
                options={stateData || []}
                value={state}
                classes={{ inputRoot: classes.inputRoot }}
                onChange={handleStateChange}
                disableClearable
                getOptionLabel={(option) => option?.name || ""}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Here"
                    variant="outlined"
                  />
                )}
                selectOnFocus
                openOnFocus
                error={!stateId && formError.state}
              />
              {!stateId && formError.state && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your state
                </Typography>
              )}
            </div>

            <div className={classes.field}>
              <label className={classes.label}>City</label>
              <span style={{ color: "red" }}>*</span>

              <InputLabel id="demo-simple-select-outlined-label"></InputLabel>

              <Autocomplete
                disabled={!isEdit}
                id="state-autocomplete"
                options={cityData || []}
                value={city}
                classes={{ inputRoot: classes.inputRoot }}
                onChange={handleDistrictChange}
                disableClearable
                getOptionLabel={(option) => option?.name || ""}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Here"
                    variant="outlined"
                  />
                )}
                selectOnFocus
                openOnFocus
                error={!cityId && formError.city}
              />
              {!cityId && formError.city && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your city
                </Typography>
              )}
            </div>

            <div className={classes.field}>
              <label className={classes.label}>Pincode</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <TextField
                InputProps={{
                  disabled: !isEdit,
                }}
                type="number"
                className={classes.textfiled}
                placeholder="Type Here"
                value={formDetails.pincode}
                onChange={(e) => handleFormChange("pincode", e.target.value)}
                variant="outlined"
                error={!formDetails.pincode && formError.pincode}
              />
              <br />
              {!formDetails.pincode && formError.pincode && (
                <Typography
                  variant="caption"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  Please type your pincode
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.btncontainer}>
        {formDetails.dob && (
          <Button
            className={`${classes.viewbtn} ${classes.mr0_5}`}
            onClick={() => setIsEdit(!isEdit)}
          >
            Edit
          </Button>
        )}
        <Button className={classes.viewbtn} onClick={handleFormSubmit}>
          Save
        </Button>
      </div>

      {isCategoryModalOpen && (
        <PickCategoryModal
          learnerName={leanerName}
          isCategoryModalOpen={openCategoryModal}
          closeModal={closeCategoryModal}
        />
      )}
    </>
  );
};

export default MyProfile;
