import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import PageHead from "../pageheading/pageheading";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginBottom:"15rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
    padding:'2rem 0',
    "& .MuiTypography-h4": {
      color: "#FA9D2B;",
    },
  },
  CancellationRefunds: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 1rem",
    },
  },
}));
function CancellationRefunds() {
  const Heading = [
    {
      id: 1,
      headingsmall:
        "Explore Cancellations & Refund Policy",
      headingmain: "Cancellations & Refund Policy",
      breadcrumb:"Cancellations & Refund Policy"
    },
  ];
  const classes = useStyles();
  return (
    <div className={classes.containerfluid}>
       <PageHead Heading={Heading}/>
      <div className={classes.container}>
        <div className={classes.CancellationRefunds}>
        <Typography variant="h5" gutterBottom>
          <b>Cancellations</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          As a general rule, all sales made on Parikshado are final and you
          shall not be enti- tled to cancel your order once you have received
          confirmation of the same. Parik- shado reserves the sole right to
          cancel any order as per our discretion in case (i) we are unable to
          deliver the order in a satisfactory manner and/ or (ii) the user tries
          to take advantage of the system which violates the Terms of Use.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>Refunds</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          We do not provide refund for any product once the order is
          confirmed.There is no obligation to provide refund. Each provider
          reserves the right to offer refunds, dis- counts or other
          consideration in select circumstances at its sole discretion.
        </Typography>
        </div>
      </div>
    </div>
  );
}
export default CancellationRefunds;
