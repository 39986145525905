import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";
import GetInTouchImage from "../image/rpsvector/getintouch.png";
import { ReactComponent as EmailIcon } from "../image/onlinesupportvector/Email.svg";
import { ReactComponent as YourNameIcon } from "../image/onlinesupportvector/YourName.svg";
import { ReactComponent as PhoneIcon } from "../image/loginsignupvector/phoneicon.svg";
import { postEnquiry } from '../../service/services';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "3rem",
    marginBottom: "15rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "14rem",
    },
    "& .MuiTypography-caption": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: "0.88rem",
      fontFamily: "Jost",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem !important",
        marginTop: "0rem",
      },
    },
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
      marginTop: "0.5rem",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiFormLabel-root": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "0px",
      },
      "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: "transparent",
        borderRadius: "6px",
      },
      "& .MuiLink-root": {
        color: "#154FE5",
        fontSize: "0.9375rem",
        fontWeight: "400",
        textTransform: "capitalize",
        cursor: "pointer",
      },
    },
  },
  loginform: {
    marginTop: "2.5rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  dontworrymain: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2.5rem",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  dontworrycontent: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  dontworrymainheading: {
    display: "flex",
    flexDirection: "column",
  },
  secheadhr: {
    width: "175px",
    margin: "0",
    backgroundImage: "linear-gradient(90deg, #FF8008 0%, #FFC837 100%)",
    border: "0",
    height: "3px",
  },
  dontworryimgcontainer: {
    width: "40%",
    marginRight: "2%",
    [theme.breakpoints.down("xs")]: {
      width: "81%",
      marginRight: "0",
    },
  },
  forminner: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "0rem",
    },
  },
  inputcontainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "1rem",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  bluebtn: {
    width: "50%",
    background: "linear-gradient(90deg, #141E30 16.46%, #243B55 100%)",
    color: "white",
    padding: "0.7rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textareacontainer: {
    marginBottom: "2rem",
  },
}));

function ContactSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Deliver Your Message with Precision and Impact ",
      headingmain: "Get In Touch",
    },
  ];

  // 
  const [formData, setFormData] = useState({
    full_name: "",
    phone: "",
    email: "",
    message: "",
    type: window.location.pathname.substring(1),
  });

  // handleChange function definition
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // Define the handleSubmit function
  const handleSubmit = async () => {
    try {
      const pathWithoutSlash = window.location.pathname.substring(1);

      setFormData((prevData) => ({
        ...prevData,
        type: pathWithoutSlash,
      }));

      const response = await postEnquiry({ enquiry: formData });
      setFormData({
        full_name: "",
        phone: "",
        email: "",
        message: "",
      });

      toast.success(response.data.message ? response.data.message : "Enquiry submitted successfully!");
    } catch (error) {
      // Display error toast with the API error message
      const errorMessage = error.response ? error.response.data.message : "An error occurred. Please try again later.";
      toast.error(errorMessage);
    }
  };

  // 

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <div className={classes.dontworrymain}>
          <div className={classes.dontworrycontent}>
            <div className={classes.dontworrymainheading}></div>
            <div className={classes.loginform}>
              <FormControl sx={{ m: 1, width: "25ch" }}>
                <div className={classes.forminner}>
                  <div className={classes.inputcontainer}>
                    <FormLabel>Your Name *</FormLabel>
                    <TextField
                      id="name"
                      type="text"
                      placeholder="Enter Your Name"
                      onChange={handleChange}
                      name="full_name"
                      value={formData.full_name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <YourNameIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className={classes.forminner}>
                  <div className={classes.inputcontainer}>
                    <FormLabel>Mobile No. *</FormLabel>
                    <TextField
                      id="mobile"
                      type="tel"
                      title="abcd"
                      placeholder="8965 458 888"
                      onChange={handleChange}
                      name="phone"
                      value={formData.phone}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className={classes.forminner}>
                  <div className={classes.inputcontainer}>
                    <FormLabel>Email address *</FormLabel>
                    <TextField
                      id="email"
                      type="text"
                      title="abcd"
                      placeholder="Johndeere43545@gmail.com"
                      onChange={handleChange}
                      name="email"
                      value={formData.email}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className={classes.textareacontainer}>
                  <FormLabel>Write a Message *</FormLabel>
                  <TextField
                    id="outlined-textarea"
                    placeholder="Write Here"
                    onChange={handleChange}
                    name="message"
                    value={formData.message}
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </div>
                <Button className={classes.bluebtn} variant="contained" onClick={handleSubmit}>
                  SEND MESSAGE
                </Button>
              </FormControl>
            </div>
          </div>
          <div className={classes.dontworryimgcontainer}>
            <img src={GetInTouchImage} alt="img" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactSection;
