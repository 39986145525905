import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Rating } from "@material-ui/lab";
import BookImg from "../image/ebooksvector/bookimagemobile.png";
import { ReactComponent as StarIcon } from "../image/ebooksvector/starmobilesvg.svg";
import { ReactComponent as FavoriteChecked } from "../image/ebooksvector/favoritechecked.svg";
import { ReactComponent as FavoriteUnchecked } from "../image/ebooksvector/favoriteunchecked.svg";
import { ReactComponent as CartBagIcon } from "../image/ebooksvector/cartbagicon.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    padding: "0.4rem",
    display: "flex",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    "& .MuiCardContent-root:last-child": {
      padding: "0 0.8rem",
    },
    "& .MuiTypography-h5": {
      fontFamily: "'Playfair Display', serif !important",
      fontWeight: "600",
      fontSize: "0.725rem",
    },
    "& .MuiTypography-caption": {
      fontFamily: "'Jost', sans-serif",
      color: "#777",
      fontSize: "0.6rem",
      fontWeight: "400",
    },
    "& .MuiTypography-body2": {
      margin: "0",
    },
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.6rem",
    },
    "& .MuiTypography-h2": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.5rem",
      textTransform: "uppercase",
      background: "#00A911",
      opacity: "0.6",
      color: "white",
      padding: "0.2rem 1rem",
      borderRadius: "15px",
    },
    "& .MuiRating-root": {
      fontSize: "1rem",
    },
    "& .MuiCardActions-root": {
      borderRadius: 6,
      padding: "0.2rem",
      "& .MuiButton-label": {
        fontFamily: "Jost",
        fontSize: "0.7rem",
        alignItems: "flex-start",
      },
    },
    "& fieldset": {
      margin: "0 0 20px -3px",
      padding: 0,
    },
    "& .MuiBox-root": {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0",
      margin: "0",
    },
  },
  cardimagecontainer: {
    background: "#FFE8B5",
    borderRadius: "6px",
    border: "1px solid #E0E0E0",
    position: "relative",
    "& img": {
      // marginTop: "0.5rem",
      position: "relative",
      top: "10px",
    },
  },
  ratingtag: {
    display: "flex",
    position: "absolute",
    left: "0",
    top: "2px",
    padding: "0.1rem 0.4rem",
    background: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
    borderRadius: "0 8px 8px 0",
    "& .MuiTypography-h6": {
      fontFamily: "'Jost', serif !important",
      fontWeight: "600",
      fontSize: "0.5625rem",
      marginLeft: "0.3rem",
    },
  },
  favoritebtn: {
    position: "absolute",
    top: "-14px",
    left: "84px",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#FF3939",
    },
    "& .MuiIconButton-root": {
      width: "66%",
    },
  },
  textred: {
    color: "#FF3939",
    fontSize: "1.125rem",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "600",
    marginRight: "0.3rem",
    // marginTop: "0.8rem",
  },
  offertext: {
    marginLeft: "0.5rem",
    color: "#252525",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.6rem",
    fontWeight: "400",
  },
  bgorange: {
    background:
      "linear-gradient(90deg, rgba(255,128,8,0.30000001192092896) 0%, rgba(255,200,55,0.30000001192092896) 100%)",
    color: "#212121 !important",
  },
  cartbagicon: {
    marginRight: "0.4rem",
  },
}));
function EbooksCardMobile() {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <div className={classes.cardimagecontainer}>
          <div className={classes.ratingtag}>
            <StarIcon />
            <Typography variant="h6">4.3</Typography>
          </div>
          <div className={classes.favoritebtn}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<FavoriteUnchecked />}
                  checkedIcon={<FavoriteChecked />}
                  name="checkedH"
                />
              }
            />
          </div>
          <div>
            <img src={BookImg} />
          </div>
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            All In One General Studies 2023 All Competitive Exams (English
            Edition)
          </Typography>
          <Typography variant="caption" display="block" >
            by B-Krishna Reddy
          </Typography>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <Rating name="read-only" value={4} readOnly />
            <Typography variant="h6">4.3</Typography>
            <Typography variant="caption">(634)</Typography>
            <Typography variant="h2">Latest</Typography>
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
          >
            <span className={classes.textred}>
              <span style={{ fontSize: "0.7rem", verticalAlign: "top" }}>
                ₹
              </span>
              50.00
            </span>
            <Typography variant="caption">
              MRP : <del>80.00</del>
            </Typography>
            <span className={classes.offertext}>(30% off)</span>
          </Typography>
          <CardActions className={classes.bgorange}>
            <Button fullWidth><CartBagIcon  className={classes.cartbagicon} style={{width: 14}}/>Add to cart</Button>
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
}
export default EbooksCardMobile;
