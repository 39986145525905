import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  TextField,
  Typography,
  makeStyles,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";
import SecHead from "../Home/sectionHeading/sectionheading";

import { getDistricts, getStates, cochingCreate } from "../../service/services";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    width: "100%",
    marginTop: "2rem",
  },
  container: {
    maxWidth: "1250px",
    margin: "0 auto",
  },
  registercoachingsection: {
    marginTop: "5rem",
    padding: "2rem",
    background: "#FFF5DF",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      paddingBottom: "0.2rem",
    },
  },
  registercoachingmain: {
    background: "#fff",
    filter: "drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.06))",
    borderRadius: "6px",
    padding: "2rem",
    margin: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
    "& .MuiTypography-h2": {
      color: "#16283F",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.875rem",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "2.25rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9375rem",
      },
    },
    "& .MuiTypography-h6": {
      color: "#333",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "1.5rem",
      marginLeft: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      transition: "none",
      border: "none",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline": {
      border: "2px solid #E5E5E5",
      borderRadius: "1.875rem",
      padding: "0.6rem 1rem",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
  registercoachinginputscontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  registercoachinginputs: {
    margin: "1rem 0",
    "& .MuiOutlinedInput-multiline": {
      borderRadius: "1.5625rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E5E5E5",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E5E5E5",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E5E5E5",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "1.625rem",
      width: "100%",
    },
  },
  w20: {
    width: "16%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  w25: {
    width: "32%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  w48: {
    width: "48%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  w33: {
    width: "33%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  w100: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  submitbtn: {
    marginTop: "1rem",
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiButton-root": {
      background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
      color: "white",
      borderRadius: "1.875rem",
    },
  },
  divider: {
    margin: "2rem 0",
  },
}));

function RegisterCoachingSection() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "join us and make a difference",
      headingmain: "Register Your Coaching",
    },
  ];
  const initialData = {
    full_name: "",
    phone: "",
    email: "",
    institute_name: "",
    district: "",
    state: "",
    address: "",
    about: "",
  };
  const [formDetails, setFormDetails] = useState(initialData);

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const handleFormChange = (fieldName, value) => {
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      [fieldName]: value,
    }));
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setSelectedState(stateId);

    // Fetch districts for the new state
    getDistricts(stateId)
      .then((res) => {
        setDistricts(res.data.cities);
      })
      .catch((error) => {
        console.log("Error fetching districts:", error);
      });
  };

  // Updated useEffect to remove the call to getDistricts
  useEffect(() => {
    getStates()
      .then((res) => {
        setStates(res.data.states);
      })
      .catch((e) => console.log("state error", e));
  }, []);

  // Validation function
  const validateForm = () => {
    // Check for empty fields
    if (
      !formDetails.full_name ||
      !formDetails.phone ||
      !formDetails.email ||
      !formDetails.institute_name ||
      !selectedDistrict ||
      !selectedState ||
      !formDetails.address ||
      !formDetails.about
    ) {
      toast.error("Please fill out all fields");
      return false;
    }

    // Email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formDetails.email)) {
      toast.error("Please enter a valid email address");
      return false;
    }

    // Phone number validation
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(formDetails.phone)) {
      toast.error("Phone number must be 10 digits long");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    // Call the validate function
    if (!validateForm()) {
      return; // Stop the submission if validation fails
    }

    try {
      const instituteData = {
        institute: {
          full_name: formDetails.full_name,
          phone: formDetails.phone,
          email: formDetails.email,
          institute_name: formDetails.institute_name,
          district: selectedDistrict,
          state: selectedState,
          address: formDetails.address,
          about: formDetails.about,
        },
      };

      const response = await cochingCreate(instituteData);
      if (response.status === 200) {
        toast.success("Coaching registered successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);

        const responseData = response.data;

        const token = responseData.token;
        const userName = responseData.user.full_name;

        const newTab = window.open(
          `${process.env.REACT_APP_Client_Pannel_Base_Path}/?token=${token}&userName=${userName}`,
          "_blank"
        );

        if (newTab) {
          setTimeout(() => {
            // Avoid passing the `navigate` function or any other circular references
            newTab.location.href = `https://coaching.parikshado.com/?token=${token}&userName=${userName}`;
          }, 1000);
        }
      } else {
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      toast.error(
        `Error: ${error.response ? error.response.data.message : error.message}`
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.registercoachingsection}>
        <div className={classes.containerfluid}>
          <div className={classes.container}>
            <SecHead Heading={Heading} />
            <div className={classes.registercoachingmain}>
              <FormControl sx={{ m: 1, width: "25ch" }}>
                <div>
                  <Typography variant="h2" display="block" gutterBottom>
                    * Your Basic Information
                  </Typography>

                  <div className={classes.registercoachinginputscontainer}>
                    <div
                      className={`${classes.registercoachinginputs} ${classes.w33}`}
                    >
                      <Typography variant="h6" gutterBottom>
                        Full Name
                      </Typography>
                      <TextField
                        id="email"
                        type="email"
                        placeholder="Blacknight"
                        value={formDetails.full_name}
                        onChange={(e) =>
                          handleFormChange("full_name", e.target.value)
                        }
                      />
                    </div>
                    <div
                      className={`${classes.registercoachinginputs} ${classes.w33}`}
                    >
                      <Typography variant="h6" gutterBottom>
                        Email
                      </Typography>
                      <TextField
                        id="email"
                        type="email"
                        placeholder="annette.black@example.com"
                        value={formDetails.email}
                        onChange={(e) =>
                          handleFormChange("email", e.target.value)
                        }
                      />
                    </div>
                    <div
                      className={`${classes.registercoachinginputs} ${classes.w33}`}
                    >
                      <Typography variant="h6" gutterBottom>
                        Phone Number
                      </Typography>
                      <TextField
                        id="phone"
                        type="tel"
                        placeholder="(+91)  8985-550- 114"
                        value={formDetails.phone}
                        onChange={(e) =>
                          handleFormChange("phone", e.target.value)
                        }
                        inputProps={{ maxLength: 10 }}
                      />
                    </div>
                  </div>
                </div>
                <Divider className={classes.divider} />
                <div>
                  <Typography variant="h2" display="block" gutterBottom>
                    * Institute Details
                  </Typography>
                  <div className={classes.registercoachinginputscontainer}>
                    <div
                      className={`${classes.registercoachinginputs} ${classes.w100}`}
                    >
                      <Typography variant="h6" gutterBottom>
                        Institute Name
                      </Typography>
                      <TextField
                        id="email"
                        type="email"
                        placeholder="Khan Academy"
                        value={formDetails.institute_name}
                        onChange={(e) =>
                          handleFormChange("institute_name", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.registercoachinginputscontainer}>
                    <div
                      className={`${classes.registercoachinginputs} ${classes.w48}`}
                    >
                      <Typography variant="h6" gutterBottom>
                        State
                      </Typography>
                      <Select
                        labelId="category-label"
                        id="state"
                        value={selectedState}
                        onChange={handleStateChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        MenuProps={menuProps}
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          <em className={classes.defaultselect}>Select Here</em>
                        </MenuItem>

                        {states &&
                          states.map((st) => (
                            <MenuItem key={st.id} value={st.id}>
                              {st.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                    <div
                      className={`${classes.registercoachinginputs} ${classes.w48}`}
                    >
                      <Typography variant="h6" gutterBottom>
                        District
                      </Typography>
                      <Select
                        labelId="category-label"
                        id="district"
                        value={selectedDistrict}
                        onChange={handleDistrictChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        MenuProps={menuProps}
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          <em className={classes.defaultselect}>Select Here</em>
                        </MenuItem>

                        {districts &&
                          districts.map((dist) => (
                            <MenuItem key={dist.id} value={dist.id}>
                              {dist.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div
                    className={`${classes.registercoachinginputs} ${classes.w100}`}
                  >
                    <Typography variant="h6" gutterBottom>
                      Institute Address
                    </Typography>
                    <TextField
                      id="email"
                      type="email"
                      placeholder="Dilli Haat Pitampura, Near Netaji Subhash Place Metro Station"
                      value={formDetails.address}
                      onChange={(e) =>
                        handleFormChange("address", e.target.value)
                      }
                    />
                  </div>
                  <div
                    className={`${classes.registercoachinginputs} ${classes.w100}`}
                  >
                    <Typography variant="h6" gutterBottom>
                      About
                    </Typography>
                    <TextField
                      type="text"
                      variant="outlined"
                      multiline
                      placeholder="Type Here"
                      rows={6}
                      value={formDetails.about}
                      onChange={(e) =>
                        handleFormChange("about", e.target.value)
                      }
                    />
                  </div>
                </div>
                <span className={classes.submitbtn}>
                  <Button
                    variant="contained"
                    size="medium"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </span>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RegisterCoachingSection;
