import React from "react";
import { makeStyles } from "@material-ui/core";
import ReferAndEarnCard1 from "./referAndEarnCard1";

const useStyles = makeStyles((theme) => ({}));

const ReferAndEarn = () => {
  const classes = useStyles();

  return (
    <>
      <ReferAndEarnCard1 />
    </>
  );
};

export default ReferAndEarn;
