import React from "react";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 22,
    borderRadius: "22px",
    width: 200,
    [theme.breakpoints.down("xs")]: {
      height: 15,
      width: 235,
    },
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: "1.25rem",
    background:
      "linear-gradient(132deg, rgba(2,29,61,1) 0%, rgba(68,140,224,1) 78%)",
    boxShadow: "0px 4px 17px 0px rgba(0, 0, 0, 0.13) inset",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFF",
    padding: "1rem",
    "& .MuiCircularProgress-colorPrimary": {
      color: "#FFCD9D",
      transform: "rotate(90deg) !important",
      // width: "220px !important",
      // height: "220px !important",
      [theme.breakpoints.down("xs")]: {
        width: "140px !important",
        height: "140px !important",
      },
    },
    "& .MuiTypography-caption": {
      color: "#383838",
      fontFamily: "Jost",
      fontSize: "2.125rem",
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
  },
  headname: {
    display: "flex",
    alignItems: "center",
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
    "& .MuiTypography-h3": {
      fontFamily: "Playfair Display !important",
      fontSize: "1.375rem",
      fontWeight: 700,
      color: "#262626",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiIconButton-root": {
      color: "#262626",
      padding: "6px",
    },
  },
  headercontainer: {
    display: "flex",
    justifyContent: "space-between",
    color: "#243B55",
    fontFamily: "Playfair Display",
    fontSize: "1.625rem",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      alignItems: "baseline",
    },
  },
  textfiled: {
    paddingRight: "10px",
    color: "#000",
    fontFamily: "Playfair Display",
    fontSize: "0.9375rem",
    fontWeight: 700,
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 22px",
      [theme.breakpoints.down("xs")]: {
        padding: "5.5px 16px",
      },
    },
  },
  progresscontainer: {
    display: "flex",
    marginTop: "3rem",
    padding: "3rem",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
      padding: "1rem",
      display: "block",
    },
  },
  linearcontainer: {
    display: "flex",
    width: "60%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1rem auto",
      // justifyContent: "center",
    },
  },
  innercirculer: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "55%",
      margin: "0 auto",
    },
  },
  status: {
    color: " #000",
    fontFamily: " Playfair Display",
    fontSize: "0.9375rem",
    fontWeight: 600,
  },
  progressvalue: {
    color: " #000",
    fontFamily: " Playfair Display",
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.3125rem",
    },
  },
  seprateprogresscontainer: {
    margin: "0.5rem",
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        size={250}
        thickness={7}
        variant="determinate"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        background="linear-gradient(306deg, #FFCD9D 31.69%, #FFF4D7 74.11%)"
      >
        <Typography variant="caption" component="div">{`${Math.round(
          60
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const DashboardCardInner = ({ setOnViewClicked }) => {
  const classes = useStyles();
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headercontainer}>
          <div className={classes.headname}>
            <IconButton
              onClick={() => {
                setOnViewClicked("");
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography variant="h3">Test Series</Typography>
          </div>
          <div>
            <FormControl variant="outlined" className={classes.field1}>
              <Select
                className={classes.textfiled}
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                disableUnderline
                MenuProps={menuProps}
                value={-1}
              >
                <MenuItem value={-1}>Start Date:</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.progresscontainer}>
          <div className={classes.innercirculer}>
            <CircularProgressWithLabel value="60" />
          </div>
          <div className={classes.linearcontainer}>
            <div className={classes.seprateprogresscontainer}>
              <BorderLinearProgress variant="determinate" value="88.2" />
              <Typography className={classes.status}>Progress</Typography>
              <Typography className={classes.progressvalue}>88.2%</Typography>
            </div>
            <div className={classes.seprateprogresscontainer}>
              <BorderLinearProgress variant="determinate" value="23" />
              <Typography className={classes.status}>Completed</Typography>
              <Typography className={classes.progressvalue}>23</Typography>
            </div>
            <div className={classes.seprateprogresscontainer}>
              <BorderLinearProgress variant="determinate" value="30" />
              <Typography className={classes.status}>In Progress</Typography>
              <Typography className={classes.progressvalue}>11</Typography>
            </div>
            <div className={classes.seprateprogresscontainer}>
              <BorderLinearProgress variant="determinate" value="45" />
              <Typography className={classes.status}>Not Started</Typography>
              <Typography className={classes.progressvalue}>45</Typography>
            </div>
            <div className={classes.seprateprogresscontainer}>
              <BorderLinearProgress variant="determinate" value="10" />
              <Typography className={classes.status}>Overdue</Typography>
              <Typography className={classes.progressvalue}>0</Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCardInner;
