import React from "react";
import SecHead from "../Home/sectionHeading/sectionheading";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import JobData from "./careersjobdata";
import { useNavigate } from "react-router-dom";
import { careersGetData } from "../../service/services";
import NoDataImage from "../image/careersvector/NoDataImage.png";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    background: "#FFFBF1",
  },
  container: {
    padding: "3rem 0",
    maxWidth: "1250px",
    margin: "0 auto",
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: "4rem",
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      "& .MuiOutlinedInput-root": {
        ".MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          marginBottom: "1rem",
        },
      },
      "& .MuiSelect-outlined": {
        background: "white",
        borderRadius: "6px",
        boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
        border: 0,
      },
      "& .MuiSelect-select": {
        width: "400px",
        padding: "0.8rem 1rem",
        color: "#21212199",
        fontFamily: "Jost",
      },
    },
  },
  filtersection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "0 1rem",
    },
  },
  btn: {
    display: "flex",
    justifyContent: "center",
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    margin: "2rem 0 2rem 0.5rem",
    padding: "0.8rem 2rem",
    borderRadius: "0.4rem",
    "&:hover": {
      background: "linear-gradient(90deg, #FFD669 0%, #FFA046 100%)",
      color: "#252525",
    },
    "& .MuiButton-label": {
      fontSize: "0.8rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.7rem 2rem",
      marginTop: "1rem",
    },
  },
  jobssection: {
    marginTop: "5rem",
    display: "flex",
    flexWrap: "wrap",
    gap: "4rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: "1rem",
    },
  },
  cardroot: {
    flexBasis: "33.3333%",
    maxWidth: 280,
    maxHeight: 390,
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem auto",
      maxWidth: 340,
    },
    "& .MuiTypography-h3": {
      textTransform: "capitalize",
      fontFamily: "Playfair Display",
      fontWeight: "700",
    },
    "& .MuiButton-fullWidth": {
      width: "100%",
      borderRadius: "0",
      padding: "1rem",
      margin: "0",
      background: "linear-gradient(90deg, #FFD9B5 0%, #FFEEC3 100%)",
    },
    "& .MuiCardActions-root": {
      padding: "0",
    },
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0,0,0, 0.6)",
      cursor: "pointer",
      "& .MuiButton-root": {
        background: "linear-gradient(90deg, #FFDE87 0%, #FFB36B 100%)",
      },
    },
    "& .MuiButton-label": {
      fontWeight: "500",
    },
  },
  title: {
    fontSize: "1.375rem",
    fontFamily: "'Jost', sans-serif",
    fontWeight: "600",
    lineHeight: "2.0625rem",
    marginBottom: "0.7rem",
  },
  subtitle: {
    marginBottom: 10,
    fontSize: "0.9rem",
    fontFamily: "Jost",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  subtitle2: {
    marginBottom: 10,
    fontSize: "0.9rem",
    fontFamily: "Jost",
    fontWeight: "500",
    textTransform: "capitalize",
    height: "141px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  nodataimage: {
    marginTop: "5rem",
    display: "flex",
    justifyContent: "center",
  },
}));

function JoinUsSection() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [careersData, setCareersData] = useState([]);
  const [filteredCareersData, setFilteredCareersData] = useState([]);
  const [originalCareersData, setOriginalCareersData] = useState([]);
  const [showAllCards, setShowAllCards] = useState(false);

  const toggleShowAllCards = () => {
    setShowAllCards(!showAllCards);
  };

  useEffect(() => {
    careersFetchApi();
  }, []);

  const Heading = [
    {
      id: 1,
      headingsmall: "Be Part of Our Team: Join Us Today!",
      headingmain: "Join us",
    },
  ];

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disablePortal: true,
  };

  const careersFetchApi = async () => {
    try {
      const response = await careersGetData();
      setCareersData(response.careers);
      setOriginalCareersData(response.careers);
    } catch (error) {
      console.log("joinus error", error);
    }
  };

  useEffect(() => {
    careersFetchApi();
  }, []);

  const handleSubmit = () => {
    const filteredResults = originalCareersData.filter((item) => {
      return (
        (department === "" || item.department_id == department) &&
        (location === "" || item.location_id == location)
      );
    });
    setCareersData(filteredResults);
  };
  const uniqueDepartmentIds = new Set();
  const uniqueDepartmentIds1 = new Set();
  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        <SecHead Heading={Heading} />
        <FormControl className={classes.filtersection}>
          <div className={classes.filterinner}>
            <Typography variant="h6" gutterBottom>
              Filter by department
            </Typography>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={department}
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
              displayEmpty
              className={classes.selectEmpty}
              MenuProps={menuProps}
              variant="outlined"
            >
              <MenuItem disabled value="">
                <em className={classes.defaultselect}>All Departments</em>
              </MenuItem>
              {originalCareersData.map((item) => {
                if (!uniqueDepartmentIds.has(item?.job_Department?.id)) {
                  uniqueDepartmentIds.add(item?.job_Department?.id);
                  return (
                    <MenuItem key={item.id} value={item?.job_Department?.id}>
                      {item?.job_Department?.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </Select>
          </div>
          <div className={classes.filterinner}>
            <Typography variant="h6" gutterBottom>
              Filter by location
            </Typography>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              displayEmpty
              className={classes.selectEmpty}
              MenuProps={menuProps}
              variant="outlined"
            >
              <MenuItem disabled value="">
                <em className={classes.defaultselect}>All Locations</em>
              </MenuItem>
            
              {originalCareersData.map((item) => {
                if (!uniqueDepartmentIds.has(item?.job_Location?.id)) {
                  uniqueDepartmentIds.add(item?.job_Location?.id);
                  return (
                    <MenuItem key={item.id} value={item?.job_Location?.id}>
                      {item?.job_Location?.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </Select>
          </div>
          <div className={classes.btn}>
            <Button className={classes.bluecustombtn} onClick={handleSubmit}>
              Apply Filters
            </Button>
          </div>
        </FormControl>

        {careersData.length > 0 ? (
          <>
            <div className={classes.jobssection}>
              {careersData.map((data) => (
                <Card key={data.id} className={classes.cardroot}>
                  <CardContent className={classes.cardcontent}>
                    <Typography
                      variant="h3"
                      className={classes.title}
                      gutterBottom
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={classes.subtitle}
                      color="textSecondary"
                    >
                      {data.experience}
                    </Typography>
                    <Typography
                      className={classes.subtitle2}
                      color="textSecondary"
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      onClick={() =>
                        navigate(
                          `/applicationsubmission/${data.title}/${data?.id}`,
                          {
                            state: { careersData: data },
                          }
                        )
                      }
                    >
                      Apply Now
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <div className={classes.nodataimage}>
            <img src={NoDataImage} alt="img" width="40%" />
          </div>
        )}
      </div>
    </div>
  );
}

export default JoinUsSection;
