import LatestJobsVector from "../../image/latestnotificationvector/jobvector.png";
import ResultVector from "../../image/latestnotificationvector/resultvector.png";
import AdmitCardVector from "../../image/latestnotificationvector/admitcardvector.png";
const Card = [
  {
    id: 1,
    head: "Latest Jobs",
    cardimage: LatestJobsVector,
    link: [
      "Bihar BPSC School Teacher Online Form 2023",
      "Maha DES Recruitment 2023 Notification PDF Out For 260 Posts",
      " Rajasthan High Court JPA Online Form 2023",
      " RSMSSB Agriculture Supervisor Online Form 2023",

    ],
  },
  {
    id: 2,
    head: "Result",
    cardimage: ResultVector,
    link: [
      'MPPSC Pre 2022 Score Card / OMR Sheet',
      'OFSS Bihar Second Merit List 2023',
      'RBI Officer Grade B Phase I Result 2023',
      'NTA CUET UG 2023 Result with Score Card',

    ],
  },
  {
    id: 3,
    head: "Admit Card",
    cardimage: AdmitCardVector,
    link: [
      'UPSSSC Junior Assistant 2022 Exam Date',
      'MHA IB Junior Intelligence Officer JIO Admit Card 2023',
      'MPESB Various Agriculture Post Admit Card 2023',

    ],
  },
  {
    id: 3,
    head: "Admit Card",
    cardimage: AdmitCardVector,
    link: [
      'UPSSSC Junior Assistant 2022 Exam Date',
      'MHA IB Junior Intelligence Officer JIO Admit Card 2023',
      'MPESB Various Agriculture Post Admit Card 2023',
      'UPSC CPF AC 2023 Admit Card',

    ],
  },
  {
    id: 3,
    head: "Admit Card",
    cardimage: AdmitCardVector,
    link: [
      'UPSSSC Junior Assistant 2022 Exam Date',
      'MHA IB Junior Intelligence Officer JIO Admit Card 2023',
      'MPESB Various Agriculture Post Admit Card 2023',
      'UPSC CPF AC 2023 Admit Card',

    ],
  },
  {
    id: 3,
    head: "Admit Card",
    cardimage: AdmitCardVector,
    link: [
      'UPSSSC Junior Assistant 2022 Exam Date',
      'MHA IB Junior Intelligence Officer JIO Admit Card 2023',
      'MPESB Various Agriculture Post Admit Card 2023',
      'UPSC CPF AC 2023 Admit Card',

    ],
  },
];
export default Card;
