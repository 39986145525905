const FeatureBenefitsListData = [
  {
    id: 1,
    cardheading: "Our Associates",
    listheading:
      "Partners will be associated with Adda247 which is trusted by more than 2 Crore students in India through:",
    listcontent: [
      "Parikshado App (#1 Education App)",
      "Parikshado YouTube Channel",
      "Parikshado.com (#1 Education Website in India)",
    ],
  },
  {
    id: 2,
    cardheading: "Our Co-Branding",
      listcontent: [
        "Partners can mention Parikshado as their Associate Partners.",
        "Get listed on various Parikshado portals.",
        "Institutes receive customized course books with name of the institute on them.",
      ],
  },
  {
    id: 3,
    cardheading: "Enquiry Generation",
    listcontent: [
      "Student can find Partner Institutes on Parikshado Websites.",
      "Students can register for Partner institutes.",
      "Get details of registered enquiries on your Email id and Personal Dashboard. ",
    ],
  },
];
export default FeatureBenefitsListData;
